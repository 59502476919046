import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-artigos',
  templateUrl: './escola-artigos.component.html',
  styleUrls: ['./escola-artigos.component.css'],
})
export class EscolaArtigosComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}
}
