<div class="rating" *ngIf="this.showTotal" (click)="toggleExpanded()">
    <div *ngIf="true || !this.small">
        <span class="score-span" [matTooltip]="this.getTooltip()">
            Afinidade com seu perfil: <strong style="font-size: 12px;">{{this.getFinalScore() | number:'1.1-1':'pt-BR'}}%</strong>
        </span>
    </div>
    <div *ngIf="false && this.small">
        <span class="score-small" [matTooltip]="this.getTooltip()">
            <strong>{{this.getFinalScore() | number:'1.1-1':'pt-BR'}}%</strong>
        </span>
    </div>


    <ngb-rating  *ngIf="false" [max]="this.maxIcons" [(rate)]="rate * this.maxIcons" [readonly]="true"
                [matTooltip]="this.getTooltip()">
        <!--        <ng-template let-fill="fill" let-index="index">-->
        <!--            <span class="star">&#9733;</span>-->
        <!--        </ng-template>-->
        <ng-template let-fill="fill">
            <mat-icon class="star" [class.full]="fill === 100" [inline]="true">
                <mat-icon class="half" color="primary" [style.width.%]="fill" [inline]="true">check_circle</mat-icon>
                check_circle
            </mat-icon>
        </ng-template>
    </ngb-rating>

    <div *ngIf="this.small">
        <div *ngFor="let item of this.score.score_info"
             style="transition: height 1s ease-in-out; overflow: hidden;"
             [style.height]="expanded? '1rem': '0px'"
             [style.max-width]="expanded? '100%': '100%'"
             fxLayout="row" fxLayoutAlign="space-between center"
        >
            <span>
                {{item.text}}:
            </span>
            <span>
                {{item.score !== null? (item.score | number:'1.2-2':'pt-BR') : '-'}}/{{item.total}}
            </span>
        </div>
    </div>

</div>
