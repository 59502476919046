import firebase from 'firebase/app';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {UrlFixerPipe} from '../../../pipe/url-fixer.pipe';
import {UserService} from '../../../service/user.service';
import {Escola, EscolaBeneficio} from '../../../service/Escola';
import {AnalyticsService} from '../../../service/analytics.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoginDialogComponent} from '../../../navigation/auth/login-dialog/login-dialog.component';
import {Component, 
        ElementRef, 
        Input, 
        OnInit,
        AfterViewChecked,
        ViewChild,
        QueryList,
        ViewChildren,
        ChangeDetectorRef,
      } from '@angular/core';

@Component({
    selector: 'school-detail-more-info',
    templateUrl: 'school-detail-more-info.html',
    styleUrls: ['./school-detail-more-info.scss'],
  })
  export class SchoolDetailMoreInfoComponent implements OnInit, AfterViewChecked {
  
    @Input() escola: Escola;
    @Input() editing: boolean;
    @Input() escolaBeneficio: EscolaBeneficio;
    @Input() requestInfo: (fieldName: string, responseDialog: boolean) => void;

    addressInputInit = false;
    socialFormControl: any = {};
    escolaBeneficioSented= false;
    escolaBeneficioSaving = false;
    schoolContactSending = false;
    schoolContactSented = false;
    schoolSendTableLoading = false;
    shoolSendTableSented = false;
    requestTuitionLoading = false;
    requestTuitionSented = false;
    urlValidatePattern = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    //@ViewChild('addressInput') addressInput: ElementRef<HTMLInputElement>;
    @ViewChildren('addressInput') addressInputs: QueryList<ElementRef>;

    emailFormControl = new FormControl(null, Validators.email);
    siteFormControl = new FormControl(null, Validators.pattern(this.urlValidatePattern));

    mensalidadeControl = new FormControl()
    mensalidadeAnoControl = new FormControl()

    mensalidadeGroup = new FormGroup({
      'mensalidade': this.mensalidadeControl,
      'mensalidade_ano': this.mensalidadeAnoControl
    });

    redeSocialArray: { value: string, title: string, pattern?: string, useMask?: string, placeholder?: string }[] = [
      {
        value: 'facebook',
        title: 'Facebook',
        pattern: '(https://www\.facebook\.com/)',
        placeholder: 'https://www.facebook.com/<nome da escola>'
      },
      {
        value: 'youtube',
        title: 'YouTube',
        pattern: '(https://www\.youtube\.com/channel/)',
        placeholder: 'https://www.youtube.com/channel/<nome da escola>'
      },
      {
        value: 'twitter',
        title: 'Twitter',
        pattern: '(https://twitter\.com/)',
        placeholder: 'https://twitter.com/<nome da escola>'
      },
      {
        value: 'instagram',
        title: 'Instagram',
        pattern: '(https://www\.instagram\.com/)',
        placeholder: 'https://www.instagram.com/<nome da escola>'
      },
      {
        value: 'whatsapp',
        title: 'WhatsApp',
        useMask: '+00 (00) 00000-0000||+00 (00) 0000-0000',
        placeholder: 'ex. +55 (11) 91234-1234'
      },
      {
        value: 'linkedin',
        title: 'LinkedIn',
        pattern: '(https://www\.linkedin\.com/company/)',
        placeholder: 'https://www.linkedin.com/company/<nome da escola>'
      },
    ];

    constructor(
        private matDialog: MatDialog,
        private urlFixer: UrlFixerPipe,
        public userService: UserService,
        private analytics: AnalyticsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
      for (const item of this.redeSocialArray) {
        if (item.useMask == null) {
          this.socialFormControl[item.value] = new FormControl(null, Validators.pattern(this.urlValidatePattern));
        } else {
          this.socialFormControl[item.value] = new FormControl(null, Validators.pattern('.*'));
        }
      }

      this.mensalidadeControl.valueChanges.subscribe(value => {
        if (value) {
          this.mensalidadeAnoControl.setValidators(Validators.required);
        }
        else {
          this.mensalidadeAnoControl.clearValidators();  
        }

        this.mensalidadeAnoControl.updateValueAndValidity();
      });
    }
  
    ngOnInit(): void {
        
    }

    ngAfterViewChecked() {
      if (!this.addressInputInit
          && this.addressInputs 
          && this.addressInputs.length > 0) {
        this.initPlaceAutocomplete();
      }
    }

    initPlaceAutocomplete(): void {
        if (!this.addressInputInit) {
          const addressInput = this.addressInputs.first.nativeElement;

          const autocomplete = new google.maps.places.Autocomplete(addressInput,
          {
            componentRestrictions: {country: 'BR'},
            types: []  // 'establishment' / 'address' / 'geocode'
          });

          autocomplete.setFields(['geometry', 'formatted_address']);
          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.placeSelected(autocomplete.getPlace());
          });
          this.addressInputInit = true;
        }
    }

    detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    placeSelected(event: google.maps.places.PlaceResult): void {
        // console.log(`event: ${JSON.stringify(event)}`);
        if (event) {
          // console.log('Event: ' + JSON.stringify(event));
          if (event.geometry) {
            // console.log('Location: ' + JSON.stringify(event.geometry.location));
            this.escola.endereco = event.formatted_address;
            this.escola.latitude = event.geometry.location.lat();
            this.escola.longitude = event.geometry.location.lng();
          } else {
            this.escola.endereco = event.name;
            this.escola.latitude = null;
            this.escola.longitude = null;
          }
        } else {
          console.log('invalid address');
        }
      }

      goToSite(): void {
        const user = this.userService.getCurrentUser();
    
        if (user == null) {
          this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
          return;
        }

        this.analytics.viewEscolaField(this.escola, 'site', this.userService.getCurrentUser());
        const url = this.urlFixer.transform(this.escola.site) + '?utm_source=schooladvisor';
        window.open(url, '_blank');
        // href="{{escola.site | urlFixer}}?utm_source=schooladvisor" title="Site" target="_blank"
      }

      decimalParser(event: any): number {
        if (event !== null) {
          const c = event.toString().replace(/[^\d,]/g, '').replace(',', '.');
          if (c.length > 0) {
            return c;
          }
        }
        return null;
      }

      calcMesalidadeMedia(): void {
        let c = 0;
        let sum = 0;
        if (this.escola.bercario_mensalidade_media) {
          c++;
          sum += +this.escola.bercario_mensalidade_media;
        }
        if (this.escola.infantil_mensalidade_media) {
          c++;
          sum += +this.escola.infantil_mensalidade_media;
        }
        if (this.escola.fundamental1_mensalidade_media) {
          c++;
          sum += +this.escola.fundamental1_mensalidade_media;
        }
        if (this.escola.fundamental2_mensalidade_media) {
          c++;
          sum += +this.escola.fundamental2_mensalidade_media;
        }
        if (this.escola.ensino_medio_mensalidade_media) {
          c++;
          sum += +this.escola.ensino_medio_mensalidade_media;
        }
        this.escola.mensalidade = Math.round(sum * 100 / c) / 100;
      }

      async sendBenefits(): Promise<void> {
    
        const user = this.userService.getCurrentUser();
    
        if (user == null) {
          this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
          return;
        }
    
    
        this.escolaBeneficioSaving = true;
    
        const currentUser = firebase.auth().currentUser;
        const data = {
          uid: currentUser.uid,
          nome: currentUser.displayName,
          emailUser: currentUser.email,
          schoolName: this.escola.nome,
          emailTo: this.escola.email,
          tipo: this.escolaBeneficio.tipo,
          premium: this.escola.premium
        }
    
        try
        {
          await firebase.functions().httpsCallable('escola_send_beneficios')(data);
    
          switch (this.escolaBeneficio.tipo) {
            case 0:
              this.analytics.interesseEscolaField(this.escola, 'escola', this.userService.getCurrentUser());
              break;
            case 1:
              this.analytics.interesseEscolaField(this.escola, 'bolsa', this.userService.getCurrentUser());
              break;
    
            case 2:
              this.analytics.interesseEscolaField(this.escola, 'benefício', this.userService.getCurrentUser());
              break;
          }
    
          this.escolaBeneficioSaving = false;
          this.escolaBeneficioSented = true;
        }
        catch (error)
        {
          this.escolaBeneficioSaving = false;
          this.escolaBeneficioSented = false;
        }
      }
    
      async sendContact(): Promise<void> {
        const user = this.userService.getCurrentUser();
    
        if (user == null) {
          this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
          return;
        }
    
        this.schoolContactSending = true;
    
        const currentUser = firebase.auth().currentUser;
        const data = {
          nome: currentUser.displayName,
          emailUser: currentUser.email,
          schoolName: this.escola.nome,
          emailTo: this.escola.email,
          tipo: 0,
          premium: this.escola.premium
        }
    
        try
        {
          await firebase.functions().httpsCallable('escola_send_beneficios')(data);
          this.analytics.interesseEscolaField(this.escola, 'escola', this.userService.getCurrentUser());
    
          this.schoolContactSending = false;
          this.schoolContactSented = true;
        }
        catch (error)
        {
          this.schoolContactSending = false;
          this.schoolContactSented = false;
        }
      }
  
      async sendSchoolTable(): Promise<void> {
        const user = this.userService.getCurrentUser();

        if (user == null) {
          this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
          return;
        }

        this.schoolSendTableLoading = true;

        const currentUser = firebase.auth().currentUser;

        const data = {
          nome: currentUser.displayName,
          emailUser: currentUser.email,
          schoolName: this.escola.nome,
          emailTo: this.escola.email,
          premium: this.escola.premium
        }

        try
        {
          await firebase.functions().httpsCallable('escola_send_table')(data);
          this.analytics.interesseEscolaField(this.escola, 'mensalidade', this.userService.getCurrentUser());
    
          this.schoolSendTableLoading = false;
          this.shoolSendTableSented = true;
        }
        catch (error)
        {
          this.schoolSendTableLoading = false;
          this.shoolSendTableSented = false;
        }
      }

      async sendRequestTuition(): Promise<void> {
        const user = this.userService.getCurrentUser();

        if (user == null) {
          this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
          return;
        }

        this.requestTuitionLoading = true;

        const currentUser = firebase.auth().currentUser;

        const data = {
          nome: currentUser.displayName,
          emailUser: currentUser.email,
          schoolName: this.escola.nome,
          emailTo: this.escola.email,
          premium: this.escola.premium
        }

        try
        {
          await firebase.functions().httpsCallable('escola_request_tuition')(data);
          this.requestInfo('mensalidade', false)
    
          this.requestTuitionLoading = false;
          this.requestTuitionSented = true;
        }
        catch (error)
        {
          this.requestTuitionLoading = false;
          this.requestTuitionSented = false;
        }
      }
  }