import {Component, OnInit, ViewChild} from '@angular/core';
import firebase from 'firebase';
import {ActivatedRoute} from '@angular/router';
import {Colecao} from '../../service/Colecao';
import {ColecaoComponent} from '../../layout/colecao/colecao.component';

@Component({
  selector: 'app-page-colecao',
  templateUrl: './page-colecao.component.html',
  styleUrls: ['./page-colecao.component.css']
})
export class PageColecaoComponent implements OnInit {

  @ViewChild('colecao', {static: true}) colecaoComponent: ColecaoComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(routeParams => {
      const codColecao = this.activatedRoute.snapshot.paramMap.get('codigo');
      const nomeColecao = this.activatedRoute.snapshot.paramMap.get('nome');

      if (this.colecaoComponent == null) {
        firebase.functions().httpsCallable('colecao')({codigo: codColecao})
          .then(value => {
            this.colecaoComponent.colecao = value.data[0];
            this.colecaoComponent.loadDiretorios(+codColecao);
          });
      } else {
        this.colecaoComponent.loadDiretorios(+codColecao);
      }

      window.scrollTo({top: 0, behavior: 'smooth'});
    });
  }

  ngOnInit(): void {
  }

}
