import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from} from 'rxjs';
import {environment} from '../../environments/environment';

/**
 * Para associar um lead a uma lista, é necessário fazer uma solicitação POST ao URL
 * https://member.mailingboss.com/integration/index.php/lists/subscribers/create/<TOKEN>
 *   passando o email dos campos, list_uid e intervalos de tag:
 *
 * ATENÇÃO! Para evitar possíveis erros, NÃO adicione todos os parâmetros na URL.
 * A URL deve conter APENAS o TOKEN, os demais parâmetros devem ser configurados no local onde coletou a informação.
 *
 * Params:
 *  email: STRING Ex: teste@teste.com
 *  list_uid: STRING Ex: vh485p76so057
 *  taginternal: STRING Ex: testetag, tag2
 */
export const URL_ADD_LEAD = ''; //https://member.mailingboss.com/integration/index.php/lists/subscribers/create/';
export const URL_LISTAS = '';// https://member.mailingboss.com/integration/index.php/lists/';

export const ID_LISTA_TESTE = ''; //'rs994tg5eb941';
export const ID_LISTA_LOGIN = ''; //'ex46234mbo64b';
export const ID_LISTA_CALCULADORA = ''; //'af1239tyt9ed8';

@Injectable({
  providedIn: 'root'
})
export class MailingBuilderallService {

  constructor(private http: HttpClient) {
  }

  getLista(): void {
    this.http.get(URL_LISTAS + environment.BUILDERALL_TOKEN).subscribe(value => {
        console.log('getLista value: ' + value);
      }
    );
  }

  sendMail(email: string, list_uid: string, taginternal: string, params?: object): void {
    return;
    from(
      fetch(URL_ADD_LEAD + environment.BUILDERALL_TOKEN, {
          body: JSON.stringify({email, list_uid, taginternal, ... params}),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          mode: 'no-cors'
        }
      ));

    // Angular HTTP POST from (HttpClient) will not work, because cors is not implemented
    // this.http.post(URL_ADD_LEAD + environment.BUILDERALL_TOKEN, {
    //   email, list_uid, taginternal
    // }).subscribe(value => {
    //     console.log('sendmail value: ' + value);
    //   }
    // );
  }
}
