<div class="w-100 align-items-center justify-content-center" fxFlex fxLayout="row wrap">
  <div style="width: 100%; height: 100px"></div>
  <div class="w-100 align-items-center justify-content-center" fxFlex="100" fxLayout="row">
    <button mat-raised-button (click)="addColecao()" [disabled]="isAddingColecao">
      <mat-icon>add</mat-icon>
      Nova Coleção
    </button>
  </div>
  <mat-progress-bar *ngIf="isLoadingColecao" mode="indeterminate"></mat-progress-bar>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" fxFlex="80">
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding fxLayout="column">
      <div class="w-100" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="node.item.type !== 'Summary' else summary">
        <button mat-icon-button matTreeNodeToggle [disabled]="node.status">
          <mat-icon>
            {{node.expandable ? (treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right') : 'school'}}
          </mat-icon>
        </button>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="100" fxLayoutGap="3px" [class.editing-panel]="node.status == 'editing'">
          <div fxFlex="100" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <div fxFlex="100" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
              <div *ngIf="node.status != 'editing' else editingNome" [ngSwitch]="node.item.type">
                <a *ngSwitchCase="'Coleção'" target="_blank" [routerLink]="['/colecao', node.item.codigo, node.item.nome | urlNormalizer]">{{node.item.nome}}</a>
                <a *ngSwitchCase="'Diretório'" target="_blank" [routerLink]="['/diretorio', node.item.codigo, node.item.nome | urlNormalizer]">{{node.item.nome}}</a>
                <a *ngSwitchCase="'Escola'" target="_blank" [routerLink]="['/escolas', node.item.codigo, node.item.nome | urlNormalizer]">{{node.item.nome}}</a>
              </div>
              <ng-template #editingNome>
                <mat-form-field appearance="fill">
                  <mat-label>{{node.item.type}}</mat-label>
                  <input matInput [(ngModel)]="node.item.nome">
                </mat-form-field>
              </ng-template>
            </div>
            <mat-form-field *ngIf="node.item.type === 'Coleção'" appearance="fill">
              <mat-label>Descrição</mat-label>
              <input matInput [(ngModel)]="node.item.descricao" [disabled]="node.status !== 'editing'">
            </mat-form-field>
            <div class="colecao-thumb" fxFlex="10" *ngIf="node.item.type === 'Coleção'">
              <a target="_blank" *ngIf="node.hideImage !== true" style="overflow: hidden"
                 [href]="'https://firebasestorage.googleapis.com/v0/b/firesample-7bd56.appspot.com/o/colecao%2Fthumb_'+node.item.codigo+'_600x600.webp?alt=media'">
                <img class="m-auto swiper-lazy"
                     src="{{'https://firebasestorage.googleapis.com/v0/b/firesample-7bd56.appspot.com/o/colecao%2Fthumb_'+node.item.codigo+'_600x600.webp?alt=media&timer='+node.hideImage}}"
                     (error)="imageError(node)"
                />
              </a>
            </div>
            <mat-form-field fxFlex="80px" appearance="fill" *ngIf="(node.item.type != 'Coleção')">
              <mat-label>Prioridade</mat-label>
              <input matInput [(ngModel)]="node.item.prioridade" [disabled]="node.status !== 'editing'">
            </mat-form-field>
            <mat-slide-toggle *ngIf="(node.item.type == 'Coleção')" [(ngModel)]="node.item.destaque" [disabled]="node.status !== 'editing'">
              Destaque
            </mat-slide-toggle>
            <mat-slide-toggle *ngIf="(node.item.type != 'Escola')" [(ngModel)]="node.item.randomico" [disabled]="node.status !== 'editing'">
              Randômico
            </mat-slide-toggle>
            <span fxFlex="10" *ngIf="node.item.inep">INEP: {{node.item.inep}}</span>
            <button mat-raised-button matTooltip="Editar" (click)="edit(node)" *ngIf="(node.status === null)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around start" fxLayoutGap="3px">
            <mat-button-toggle-group>
              <button mat-raised-button (click)="remove(node)" *ngIf="node.status == 'editing'"
                      [matTooltip]="removeTooltip(node)"
                      [disabled]="(node.status == 'removing') || (node.item.type === 'Coleção')">
                <mat-icon>remove_circle</mat-icon>
                Retirar
              </button>
              <button mat-raised-button [matMenuTriggerFor]="removeMenu" *ngIf="node.status == 'editing'"
                      [disabled]="(node.item.status == 'saving') || (node.item.type == 'Escola')">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
            </mat-button-toggle-group>
            <input class="file-input" type="file" accept="image/*" *ngIf="(node.status == 'editing') && (node.item.type == 'Coleção')"
                   (change)="imageUpload(node, $event.target)">
            <mat-menu #removeMenu="matMenu" class="big-menu">
              <button mat-menu-item (click)="delete(node)">
                <mat-icon>delete</mat-icon>
                Remover <strong>PERMANENTEMENTE</strong>?
              </button>
            </mat-menu>
            <button mat-raised-button matTooltip="Salvar" (click)="save(node)" *ngIf="node.status == 'editing'" [disabled]="node.item.status == 'saving'">
              <mat-icon>save</mat-icon>
              Salvar
            </button>
            <button mat-raised-button matTooltip="Cancelar" (click)="cancel(node)" *ngIf="node.status == 'editing'" [disabled]="node.item.status == 'saving'">
              <mat-icon>cancel</mat-icon>
              Cancelar
            </button>
          </div>
        </div>
      </div>

      <ng-template #summary>
        <div *ngIf="node.item.group === 'Coleção'" class="w-100 summary-line" fxFlex fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="5px">
          <!--Total: {{node.item.count}}-->
          <button mat-raised-button matTooltip="Adicionar Diretório" #matMenuTriggerDiretorio="matMenuTrigger"
                  (click)="inputDiretorio.value=null;"
                  [matMenuTriggerFor]="menuAddDiretorio">
            <mat-icon>add</mat-icon>
            Incluir Diretório Existente
          </button>
          <mat-menu #menuAddDiretorio="matMenu" class="big-menu">
            <mat-form-field class="w-100" floatLabel="always" (click)="$event.stopPropagation(); inputDiretorio.value = null;">
              <mat-label class="custom-label">Diretório</mat-label>
              <input class="w-100" matInput #inputDiretorio
                     [formControl]="diretorioControl"
                     [matAutocomplete]="autocompleteDiretorio"
                     [placeholder]="diretorioControl? 'Pesquisar diretórios' : ''"
              >
            </mat-form-field>
            <mat-autocomplete
              class="diretorio-autocomplete"
              #autocompleteDiretorio="matAutocomplete"
              [displayWith]="displayDiretorio"
              (optionSelected)="addDiretorio(node, $event.option.value); matMenuTriggerDiretorio.closeMenu()"
            >
              <mat-option *ngIf="isLoadingDiretorio" [disabled]="true">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <span>{{loadingDiretorioMessage}}</span>
              </mat-option>
              <ng-container *ngIf="!isLoadingDiretorio" #diretorioResult>
                <mat-option class="position-relative" *ngFor="let diretorio of diretorios" [value]="diretorio">
                  <span>{{diretorio.nome}}</span>
                  <span class="select-codigo position-absolute top-0 end-0">Cód: {{diretorio.codigo}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-menu>
          <button mat-raised-button matTooltip="Adicionar um Diretório Novo"
                  (click)="addDiretorio(node, null)">
            <mat-icon>add</mat-icon>
            Criar um novo Diretório
          </button>
        </div>
        <div *ngIf="node.item.group === 'Diretório'" class="w-100 summary-line" fxFlex fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="5px">
          <!--Total: {{node.item.count}}-->
          <button mat-raised-button matTooltip="Adicionar Escolas"
                  (click)="inputEscola.value=null" #matMenuTriggerEscola="matMenuTrigger"
                  [matMenuTriggerFor]="menuAddEscola">
            <mat-icon>add</mat-icon>
            Incluir Escola
          </button>
          <mat-menu #menuAddEscola="matMenu" class="big-menu">
            <mat-form-field class="w-100" floatLabel="always" (click)="$event.stopPropagation(); inputEscola.value = null;">
              <mat-label class="custom-label">Escola</mat-label>
              <input class="w-100" matInput #inputEscola
                     [formControl]="escolaControl"
                     [matAutocomplete]="autocompleteEscola"
                     [placeholder]="escolaControl? 'Pesquisar escolas' : ''"
              >
            </mat-form-field>
            <mat-autocomplete
              class="escola-autocomplete"
              #autocompleteEscola="matAutocomplete"
              [displayWith]="displayEscola"
              (optionSelected)="addEscola(node, $event.option.value); matMenuTriggerEscola.closeMenu()"
            >
              <mat-option *ngIf="isLoadingEscola" [disabled]="true">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <span>{{loadingEscolaMessage}}</span>
              </mat-option>
              <ng-container *ngIf="!isLoadingEscola" #escolaResult>
                <mat-option class="position-relative" *ngFor="let escola of escolas" [value]="escola">
                  <span>{{escola.nome}}</span>
                  <span class="select-codigo position-absolute top-0 end-0">INEP: {{escola.codigo}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-menu>
        </div>
      </ng-template>
      <mat-progress-bar *ngIf="node.status === 'loading'" mode="indeterminate" class="w-100 tree-progress-bar"></mat-progress-bar>
    </mat-tree-node>
  </mat-tree>
</div>
