import {Component, Input, OnInit} from '@angular/core';
import {Escola} from '../../service/Escola';

@Component({
  selector: 'app-button-rating',
  templateUrl: './button-rating.component.html',
  styleUrls: ['./button-rating.component.css']
})
export class ButtonRatingComponent implements OnInit {

  @Input() escola: Escola;

  constructor() { }

  ngOnInit(): void {
  }

}
