<mat-tab-group fxFlexFill fxFill style="position: relative; top: 80px;">
  <mat-tab label="Favoritos" fxFlexFill>
    <app-escola-list-vertical
      fxFlexFill
      [escolaCount]="this.escolaCountFavorito" [filterMessage]="this.filterMessageFavorito"
      [pageSize]="this.pageSizeFavorito" [escolaList]="this.escolaListFavorito"
      (paginatorPage)="this.updatePaginatorFavorito()"
      (afterViewInit)="this.updatePaginatorFavorito()"
      #escolaListVerticalFavorito
    ></app-escola-list-vertical>
  </mat-tab>
  <mat-tab label="Perfil Adquirido">
    <app-escola-list-vertical
      fxFlexFill
      [escolaCount]="this.escolaCountPerfil" [filterMessage]="this.filterMessagePerfil"
      [pageSize]="this.pageSizePerfil" [escolaList]="this.escolaListPerfil"
      (paginatorPage)="this.updatePaginatorPerfil()"
      (afterViewInit)="this.updatePaginatorPerfil()"
      #escolaListVerticalPerfil
    ></app-escola-list-vertical>
  </mat-tab>
</mat-tab-group>
