<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="15px" fxLayoutGap.xs="5px" [ngClass.xs]="showSearchButton || isHomeTop() ? 'header-collaped' : 'header-expanded'">
  <a href="/" style="position: relative; left: 5px; top: 0px; background: white; border-radius: 20px; z-index: 500">
    <div fxHide fxShow.gt-sm style="background: url('/images/logo-col.svg') no-repeat center; background-size: contain; margin: 5px; min-width: 100px; min-height: 50px; width: 20%; max-width: 100px"></div>
    <div fxShow fxHide.gt-sm style="background: url('/images/logo-small.svg') no-repeat center; background-size: contain; margin: 5px; min-width: 50px; min-height: 50px; width: 20%"></div>
  </a>
  <div [fxHide.xs]="!showSearchButton" fxHide fxLayout="space-around center" fxFlex="grow">
    <button mat-mini-fab style="background-color: transparent; border: 1px solid grey; position: absolute; right: 70px; top: 18px" class="mat-elevation-z0" (click)="this.toggleSearch()">
      <mat-icon color="primary" style="top: -3px; position: relative">search</mat-icon>
    </button>
  </div>
  <app-escola-filter-header style="position: absolute; width: 70vw; z-index: 100" [fxHide.xs]="showSearchButton" (searchButtonEvent)="this.toggleSearch()" [ngStyle.gt-xs]="isHomeTop() ? 'width: 70vw; padding-top: 130px; z-index: 100;' : ''" ngStyle.xs="width: 90vw; padding-top: 110px; z-index: 100;"> </app-escola-filter-header>
  <!--    [ngStyle.gt-xs]="isHomeTop()? 'width: 70vw; padding-top: 80px;': 'width: 70vw; padding-top: 0;'"-->
  <div fxFlex="nogrow" style="position: relative; right: 20px; top: 0; height: 75px; padding-left: 10px" fxFlex fxLayoutAlign="end center">
    <button mat-mini-fab title="avatar" style="cursor: pointer; background-size: cover" [style.background-image]="'url(' + photoURL + ')'" [matMenuTriggerFor]="posXMenu" [matTooltip]="displayName" *ngIf="userService.getCurrentUser(); else showLogin">
      <span *ngIf="!photoURL">{{ displayNameInitials || "" }}</span>
    </button>
    <mat-menu #posXMenu="matMenu" class="before ngx-auth-firebaseui-avatar-menu" xPosition="before">
      <div fxLayout="row" fxLayout.xs="column" style="padding-left: 10px; padding-right: 10px">
        <button [style.background-image]="'url(' + photoURL + ')'" mat-fab style="background-size: cover">
          <span *ngIf="!photoURL">{{ displayNameInitials || "" }}</span>
        </button>
        <div fxLayout="column" style="padding-left: 10px; padding-right: 10px">
          <strong mat-card-title>{{ displayName }}</strong>
          <em mat-card-subtitle style="font-style: italic">{{ email }}</em>
        </div>
      </div>

      <div fxFlex="100" fxLayout="column">
        <div *ngFor="let menuItem of links" class="links-menu">
          <button (click)="menuItem?.callback()" mat-menu-item>
            <mat-icon>{{ menuItem?.icon }}</mat-icon>
            {{ menuItem?.text }}
          </button>
        </div>
        <!--                      <button (click)="openProfile()" color="primary" fxLayoutAlign="center" mat-raised-button>Configurações</button>-->
        <button (click)="signOut()" style="margin: 0 10px" color="primary" fxLayoutAlign="center" mat-raised-button>Sair</button>
      </div>
    </mat-menu>
    <ng-template #showLogin>
      <button fxHide.gt-xs mat-mini-fab (click)="login()" style="background-color: transparent; border: 1px solid grey" class="mat-elevation-z0">
        <mat-icon color="primary" style="top: -3px; position: relative">person_outline</mat-icon>
      </button>
      <button fxHide.xs mat-stroked-button (click)="login()" color="primary" style="background: transparent">
        <mat-icon color="primary">person_outline</mat-icon>
        Login
      </button>
      <!--            <a title="Login" (click)="login();" style="cursor: pointer">-->
      <!--                <img src="images/icon-login.svg" alt="Login"/>Login-->
      <!--            </a>-->
    </ng-template>
  </div>
</div>
<!--<div #spacer class="w-100" style="background-color: white; min-height: 80px;}">-->
<!--    <div [ngStyle.xs]="{'height':'270px'}" style="background-color: white;" *ngIf="!this.showSearchButton"></div>-->
<!--</div>-->
