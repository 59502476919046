<div class="row newCompetitorRow">
  <div class="col-11">
    <input matInput class="newCompetitors" type="number" [(ngModel)]="newCompetitor">
  </div>
  <div class="col-1">
    <button mat-mini-fab
      tooltip="Novo concorrente"
      (click)="addCompetitor()"
      >
      <mat-icon>add</mat-icon>
  </button>
  </div>
</div>
<div class="row mb-3" *ngFor="let competitor of competitorsList">
  <div class="line-concorrentes col-12 d-flex justify-content-between">
    {{competitor}}
    <button mat-mini-fab
        tooltip="Remover concorrente"
        (click)="removeCompetitor(competitor)">
        <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
