<h2 mat-dialog-title>Avaliação</h2>
<div mat-dialog-content style="min-width: 50vh;">
    <mat-stepper linear #stepper>
        <mat-step completed="false" #rankingStep title="">
            <div>
                <!--<ng-template matStepLabel>Nota</ng-template>-->
<!--                <h2>Nota</h2>-->
                <app-review-ranking #reviewRankingComponent [review]="this.review"
                                    [readonly]="false"></app-review-ranking>
            </div>
            <!--    </mat-step>-->
            <!--    <mat-step>-->
            <div>
                <!--<ng-template matStepLabel>Atributos</ng-template>-->
                <h2>Atributos</h2>
                <mat-form-field class="w-100" style="margin-bottom: 10px" floatLabel="always" appearance="outline">
                    <mat-label>Atributos</mat-label>
                    <mat-chip-list #chipList aria-label="Atributos" multiple>
                        <mat-chip
                                *ngFor="let item of this.tags"
                                selectable removable="false"
                                (click)="toggleSelect(item)"
                                [selected]="this.tagsFormControl.value.indexOf(item) >= 0">
                            {{item}}
                        </mat-chip>
                    </mat-chip-list>
                    <mat-hint #tagsHint *ngIf="maxTags > 0" [class.warn]="warnMax">Escolha até {{maxTags}} Atributos
                    </mat-hint>
                    <!--      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">-->
                    <!--        <mat-option *ngFor="let item of filteredTags | async" [value]="item">-->
                    <!--          {{item}}-->
                    <!--        </mat-option>-->
                    <!--      </mat-autocomplete>-->
                </mat-form-field>
            </div>
            <!--    </mat-step>-->
            <!--    <mat-step>-->
            <div>
                <!--        <ng-template matStepLabel>Avaliação</ng-template>-->
                <h2>Experiência</h2>
                <div>
<!--                    <div fxFlex fxHide="{{anonimoFormControl.value}}" fxShow fxLayout="row" fxLayout.lt-sm="column"-->
<!--                         fxLayoutGap="5px">-->
<!--                        <mat-form-field fxFlexFill fxFlex.lt-sm="100" fxFlex="50" appearance="outline" required>-->
<!--                            <mat-label>Nome</mat-label>-->
<!--                            <input matInput-->
<!--                                   [formControl]="nomeDeclaradoFormControl"-->
<!--                                   [disabled]="anonimoFormControl.value"-->
<!--                                   [readOnly]="anonimoFormControl.value">-->
<!--                            <mat-error *ngIf="nomeDeclaradoFormControl.invalid"></mat-error>-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field fxFlexFill fxFlex.lt-sm="100" fxFlex="50" appearance="outline" required>-->
<!--                            <mat-label>e-mail</mat-label>-->
<!--                            <input matInput-->
<!--                                   [formControl]="emailDeclaradoFormControl"-->
<!--                                   [disabled]="anonimoFormControl.value"-->
<!--                                   [readOnly]="anonimoFormControl.value">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
                    <mat-form-field appearance="outline" fxFlex="100" fxFill required style="height: 40vh">
                        <mat-label>Descreva a sua experiência</mat-label>
                        <textarea matInput rows="8" #descricao
                                  [formControl]="descricaoFormControl" [maxLength]="maxLength"
                                  [disabled]="this.saving"
                        ></textarea>
                        <mat-hint align="start">Máximo: {{this.maxLength}} caracteres</mat-hint>
                        <mat-hint align="end">{{descricao.value.length}} / 1000</mat-hint>
                        <mat-error *ngIf="descricaoFormControl.invalid">Campo Obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</div>
<div mat-dialog-actions fxFlexFill fxLayout="row">
    <!--  <mat-checkbox [formControl]="anonimoFormControl" fxFlex>Avaliação Anônima</mat-checkbox>-->
    <button mat-button mat-dialog-close *ngIf="stepper.selectedIndex > 0">Cancelar</button>
    <button mat-button *ngIf="stepper.selectedIndex < stepper.steps.length-1" (click)="nextStep()" fxFlex="nogrow">
        Continuar
        <mat-icon>navigate_next</mat-icon>
    </button>
    <button mat-button *ngIf="stepper.selectedIndex == stepper.steps.length-1" [disabled]="this.saving"
            (click)="saveReview()" fxFlex="nogrow">
        <mat-icon>done_outline</mat-icon>
        Confirmar
        <mat-progress-bar mode="indeterminate" [style.display]="saving? '' : 'none'"></mat-progress-bar>
    </button>
</div>
