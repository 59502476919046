import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UserService} from '../../service/user.service';
import {Parceiro} from '../../service/Parceiro';
import {tap} from 'rxjs/operators';
import firebase from 'firebase/app';
import {UploadFileData} from '../../navigation/upload-task/upload-task.component';

@Component({
  selector: 'app-lista-parceiro',
  templateUrl: './lista-parceiro.component.html',
  styleUrls: ['./lista-parceiro.component.scss']
})
export class ListaParceiroComponent implements OnInit, AfterViewInit {

  parceiroArray: Array<Parceiro> = [];
  canEdit = false;
  saving = false;
  editing = false;
  @Input() itemCount = 0;
  @Input() pageSize = 20;
  @Input() filterMessage: string;
  @Output() paginatorPage = new EventEmitter<PageEvent>();
  @Output() afterViewInit = new EventEmitter<void>();
  @ViewChild('matPaginator', {static: true}) matPaginator: MatPaginator;
  @ViewChild('matPaginatorFooter', {static: true}) matPaginatorFooter: MatPaginator;

  constructor(public userService: UserService) {
    this.canEdit = userService.master;
    userService.userDataEvent.subscribe(value => {
      this.canEdit = (userService.master === true);
    });
  }

  ngOnInit(): void {
    this.matPaginator._intl.itemsPerPageLabel = 'Parceiros por página:';
  }

  ngAfterViewInit(): void {
    firebase.functions().httpsCallable('parceiro')({}).then(value => {
      if (value == null) {
        this.parceiroArray = null;
      } else {
        this.parceiroArray = value.data;
      }
    });
  }

  updatePaginator(pageEvent: PageEvent): void {
    this.matPaginator.pageIndex = pageEvent.pageIndex;
    this.matPaginatorFooter.pageIndex = pageEvent.pageIndex;
    // console.log(`pageEvent.pageIndex: ${pageEvent.pageIndex}`);
    this.paginatorPage.emit(pageEvent);
  }

  addParceiro(): void {
    // console.log('addParceiro');
    const parceiro: Parceiro = {
      codigo: null,
      nome: null,
      logo_path: null,
      editing: true
    };
    this.parceiroArray.unshift(parceiro);
    // console.log(`lista: ${JSON.stringify(this.parceiroArray)}`);
  }

  parceiroRemove(parceiro: Parceiro): void {
    if (parceiro == null || parceiro.codigo == null) {
      const i = this.parceiroArray.indexOf(parceiro);
      this.parceiroArray.splice(i, 1);
    }
    firebase.functions().httpsCallable('parceiro_remove')(parceiro).then(value => {
      this.saving = false;
      if (value.data != null) {
        if (parceiro.codigo === value.data[0].codigo) {
          const i = this.parceiroArray.indexOf(parceiro);
          this.parceiroArray.splice(i, 1);
        }
      }
    }).catch(reason => {
      this.saving = false;
    });
  }

  parceiroImage(parceiro: Parceiro): void {

  }

  editParceiro(parceiro: Parceiro): void {
    parceiro.editing = true;
  }

  saveParceiro(parceiro: Parceiro): void {
    parceiro.editing = false;
    this.saving = true;
    firebase.functions().httpsCallable('parceiro_set')(parceiro).then(value => {
      if (value.data != null) {
        if (parceiro.codigo === value.data[0].codigo) {
          this.saving = false;
          const par = value.data[0];
          const i = this.parceiroArray.indexOf(parceiro);
          this.parceiroArray[i] = par;
        }
      }
    }).catch(reason => {
      console.error('Error: ' + JSON.stringify(reason));
      this.saving = false;
    });
  }

  uploadDone(parceiro: Parceiro, $event: UploadFileData): void {
    parceiro.logo_path = $event.thumb;
  }
}
