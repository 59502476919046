import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Gallery, GalleryRef, ImageItemData, ImageSize, ThumbnailsPosition} from 'ng-gallery';
import firebase from 'firebase/app';
import {Observable, of} from 'rxjs';
import {Lightbox} from 'ng-gallery/lightbox';
import {Escola} from '../../service/Escola';
import {UserService} from '../../service/user.service';
import {AnalyticsService} from '../../service/analytics.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-escola-gallery',
  templateUrl: './escola-gallery.component.html',
  styleUrls: ['./escola-gallery.component.css']
})
export class EscolaGalleryComponent implements OnInit, AfterViewInit {

  _escola: Escola;
  @Input() editing = false;
  galleryId = 'image-gallery';
  hasImage = true;
  imageCount = -1;
  warning: string = null;
  items: Observable<ImageItemData[]>;
  imagesData: ImageItemData[];
  @Output() clickMessageLink = new EventEmitter<void>();
  @ViewChild('itemTemplate') itemTemplate;

  constructor(
    public gallery: Gallery,
    // public lightbox: Lightbox,
    public userService: UserService,
    private analytics: AnalyticsService) {

    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    galleryRef.indexChanged.pipe(
      tap((galleryState) => {
        const user = userService.getCurrentUser();
        this.analytics.clickEscolaMidia(this.escola, 'image', galleryState.currIndex, user);
      })
    ).subscribe();
    const lightboxRef = this.gallery.ref('lightbox');

    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      itemTemplate: this.itemTemplate
    })
  }

  ngOnInit(): void {
  }

  get escola(): Escola {
    return this._escola;
  }

  @Input() set escola(value: Escola) {
    this._escola = value;
    this.loadImages();
  }

  ngAfterViewInit(): void {
  }

  public loadImages(): void {
    this.imageCount = -1;
    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    galleryRef.reset();
    firebase.functions().httpsCallable('escola_gallery')({cod_escola: this.escola.codigo}).then(value => {
      this.imageCount = (value.data?.images?.length);
      this.warning = value.data?.message;
      this.imagesData = [];
      value.data.images.map(fileItem => {
        const imageItemData: ImageItemData = {
          codigo: fileItem.codigo,
          title: fileItem.title,
          src: fileItem.src, 
          thumb: fileItem.thumb
        };
        this.imagesData.push(imageItemData);
      });

      const first = this.imagesData[0]

      this.imagesData.forEach((item, index) => {
        console.log(item.title)
        if (!item.title) {
          item.title = `${String(index + 1).padStart(3, '0')}`
        }
        console.log(item.title)
      })

      first.title = '000'

      this.imagesData.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      this.imagesData.map((fileItem) => {
        galleryRef.addImage(fileItem);
      });

      this.items = of(this.imagesData);
    });
  }

  removeImage(src: string, index: number): void {
    firebase.functions().httpsCallable('escola_gallery_remove')({cod_escola: this.escola.codigo, src}).then(() => {
      this.loadImages();
    });
  }

  setMainImage(src: string, index: number): void {
    firebase.functions().httpsCallable('escola_gallery_main')({cod_escola: this.escola.codigo, src}).then(() => {
      this.loadImages();
    });
  }

  moveLeft(currentIndex: number): void {
    const leftImage = this.imagesData[currentIndex - 1]
    const currentImage = this.imagesData[currentIndex]

    const leftTitle = leftImage.title
    leftImage.title = currentImage.title
    currentImage.title = leftTitle

    /*const body = {
      cod_escola: this.escola.codigo,
      items:[
        {
          codigo: leftImage.codigo,
          title: leftImage.title
        },
        {
          codigo: currentImage.codigo,
          title: currentImage.title
        }
      ]
    }*/

    const body = {
      cod_escola: this.escola.codigo,
      items:[]
    }

    this.imagesData.forEach((item) => {
      body.items.push({
        codigo: item.codigo,
        title: item.title
      })
    })

    firebase.functions().httpsCallable('escola_gallery_change_order')(body).then(() => {
      this.loadImages();
    });
  }

  moveRight(currentIndex: number): void {
    const rightImage = this.imagesData[currentIndex + 1]
    const currentImage = this.imagesData[currentIndex]

    const rightTitle = rightImage.title
    rightImage.title = currentImage.title
    currentImage.title = rightTitle

    const body = {
      cod_escola: this.escola.codigo,
      items:[
        {
          codigo: rightImage.codigo,
          title: rightImage.title
        },
        {
          codigo: currentImage.codigo,
          title: currentImage.title
        }
      ]
    }

    firebase.functions().httpsCallable('escola_gallery_change_order')(body).then(() => {
      this.loadImages();
    });
  }

  // moveThumb(index: number, count: number) {
  //   //TODO: move it
  // }
}
