export const ICON_DEFAULT = './images/sa_icon_black.png';
export const ICON_OVER = './images/sa_icon_magenta.png';
export const ICON_SELECTED = './images/sa_icon.png';
export const checkSvg = 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z';
export const pinSvg = 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z';
export const pinOutlineSvg = 'M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602';
export const pinSchool = {
  path: pinSvg,
  fillColor: '#ff435a',
  // fillColor: '#1a9896',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1.5,
  anchor: new google.maps.Point(15, 30),
};
export const pinSchoolSelectd = {
  path: pinSvg,
  // fillColor: '#ff435a',
  fillColor: '#1a9896',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
  anchor: new google.maps.Point(15, 30),
};
export const pinSchoolCellep = {
  path: pinSvg,
  fillColor: '#862a77',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1.5,
  anchor: new google.maps.Point(15, 30),
};
export const pinCellep = {
  path: pinSvg,
  fillColor: '#2a2a86',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1.5,
  anchor: new google.maps.Point(15, 30),
};
export const pinDisabled = {
  path: checkSvg,
  fillColor: '#666666',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1.5,
  anchor: new google.maps.Point(15, 30),
};
