<div mat-dialog-title style="min-width: 350px; width: 50vh">
  <label>Listas e Diretórios</label>
</div>

<mat-dialog-content class="mat-typography">
  <mat-form-field style="width: 100%">
    <mat-label>Listas/Diretórios</mat-label>
<!--    <mat-chip-list #chipList aria-label="Listas/Diretórios de Escolas" [formControl]="escolaService.listas">-->
<!--      <mat-chip *ngFor="let item of escolaService.listas.value" (removed)="remove(item)" removable>-->
<!--        {{item}}-->
<!--        <mat-icon matChipRemove>cancel</mat-icon>-->
<!--      </mat-chip>-->
<!--      <input #listaInput-->
<!--             placeholder="Nova Lista..."-->
<!--             [formControl]="listControl"-->
<!--             [matAutocomplete]="auto"-->
<!--             [matChipInputFor]="chipList"-->
<!--             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--      >-->
<!--    </mat-chip-list>-->
<!--    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
<!--      <mat-option *ngFor="let item of filteredListas | async" [value]="item">-->
<!--        {{item}}-->
<!--      </mat-option>-->
<!--    </mat-autocomplete>-->
    <mat-select multiple [formControl]="escolaService.listas">
      <mat-select-trigger>
        {{escolaService.listas.value ? escolaService.listas.value[0] : ''}}
        <span *ngIf="escolaService.listas.value?.length > 1">
        (+{{escolaService.listas.value.length - 1}} {{escolaService.listas.value?.length === 2 ? 'outra' : 'outras'}})
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let item of allListas" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxFill>
    <button mat-button mat-dialog-close mat-flat-button color="primary" (click)="resetFilters()">Limpar Filtros</button>
    <button mat-button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Filtrar</button>
  </div>
</mat-dialog-actions>
