<div *ngIf="!escola.premium && escolasRecomendadas.length > 0" class="mb-5 more-school-info" [class.blocked]="blocked">
  <div class="container">
    <!-- TITLE -->
    <div class="col-12 mb-4">
      <h2 class="text-black font-size-biggest">Desbloqueie outras escolas recomendadas para você</h2>
    </div>
    <!-- END TITLE -->

    <div id="recipeMoreSchools" class="carousel slide slider moreSchools content-school" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div *ngFor="let e of escolasRecomendadas; let i = index" [class.active]="i === 0" class="carousel-item">
          <article class="card-school col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 p-2">
            <div class="content-image-card mb-4">
              <a [href]="'/escolas/' + e.codigo + '/' + e.nome" target="_blank" title="veja mais" class="veja-mais">
                <div class="image">
                  <img [src]="e.logo_escola" alt="Escola" />
                </div>
              </a>
            </div>
            <div class="content-text-card">
              <a [href]="'/escolas/' + e.codigo + '/' + e.nome" target="_blank" title="veja mais" class="veja-mais">
                <h3 class="text-black font-size-biggest">
                  <img *ngIf="e.premium === true" src="images/new/icon-award.svg" alt="Premium" />
                  {{ e.nome }}
                </h3>
                <p *ngIf="userService.master && escola.lista_concorrentes && escola.lista_concorrentes.indexOf(e.codigo) >= 0" class="font-size-small text-pink mb-0">Concorrente</p>
                <p class="font-size-regular text-lightgray mb-0">{{ e.endereco }}</p>
              </a>
            </div>
          </article>
        </div>
      </div>
      <a *ngIf="!this.blocked && escolasRecomendadas.length > 4" class="control carousel-control-prev" href="#recipeMoreSchools" (click)="preventDefault($event)" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a *ngIf="!this.blocked && escolasRecomendadas.length > 4" class="control carousel-control-next" href="#recipeMoreSchools" (click)="preventDefault($event)" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <!-- div class="row mb-4">
      <article *ngFor="let e of escolasRecomendadas; let i = index" class="card-school col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="content-image-card mb-4">
          <a [href]="'/escolas/' + e.codigo + '/' + e.nome" target="_blank" title="veja mais" class="veja-mais">
            <div class="image">
              <img [src]="e.logo_escola" alt="Escola" />
            </div>
          </a>
        </div>
        <div class="content-text-card">
          <a href="#" title="veja mais" class="veja-mais">
            <h3 class="text-black font-size-biggest"><img src="images/new/icon-award.svg" alt="Premium" />{{ e.nome }}</h3>
            <p class="font-size-regular text-lightgray mb-0">{{ e.endereco }}</p>
          </a>
        </div>
      </article>
    </div-->

    <!-- ACTION -->
    <div class="action align-items-center justify-content-center flex-column">
      <a (click)="unlockInformation(); $event.preventDefault()" href="#" class="btn background-pink text-white font-size-regular semibold">
        {{ this.blocked ? "Desbloquear escolas recomendadas para mim" : "bloquear escolas recomendadas para mim" }}
      </a>
    </div>
    <!-- END ACTION -->
  </div>
</div>
