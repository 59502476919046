<!--  wrapper  -->
<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <!--section -->
    <section class="gray-section" id="sec1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 display-page nosidebar">
            <div class="list-single-main-wrapper fl-wrap" id="sec2">


              <article id="post-7248" class="post-7248 page type-page status-publish hentry">
                <div class="list-single-main-item-title fl-wrap">
                  <h3 class="entry-title">Regras de Conduta</h3></div><!-- .list-single-main-item-title-->
                <div class="entry-content clearfix">
                  <div data-elementor-type="wp-page" data-elementor-id="7248" class="elementor elementor-7248" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          class="elementor-element elementor-element-215bd0e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="215bd0e1" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-7438cdc7 elementor-column elementor-col-100 elementor-top-column" data-id="7438cdc7"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-551cba2 elementor-widget elementor-widget-text-editor" data-id="551cba2"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><p><span style="font-weight: 400;">Os serviços da <strong>SchoolAdvisor</strong> permitem a interação entre os usuários da plataforma e as instituições de ensino. Para garantirmos um ambiente respeitoso e de colaboração, você aceita respeitar  as regras de conduta abaixo descritas. Nossas políticas de conteúdo são alteradas regularmente. Por isso, consulte-as </span><span
                                          style="font-weight: 400;">periodicamente.<br/><br/></span></p>
                                          <p><b>Discurso de ódio:</b><span style="font-weight: 400;"> não toleramos discursos de ódio que incitem a violência contra indivíduos, grupos ou instituições com base em raça ou origem étnica, religião, deficiência, idade, nacionalidade, orientação sexual, identidade de gênero ou qualquer outra característica. Ao avaliar uma instituição de ensino, por favor, atente-se para descrever sua opinião de forma construtiva, baseando-se em uma experiência geral e não apenas em fatos isolados.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Assédio ou ameaças:</b><span style="font-weight: 400;"> não utilize nossa plataforma para assediar ou ameaçar pessoas, grupos e instituições. Caso você desrespeite essa regra, seu conteúdo será excluído e seu perfil banido permanentemente da SchoolAdvisor.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Dados pessoais e confidenciais:</b><span style="font-weight: 400;"> não concordamos com a publicação de dados pessoais e confidenciais de outras pessoas, como telefones não listados, números e informações de documentos de identificação pessoal (CPF, RG, carteira de motorista, etc), cartão de crédito ou imagens e vídeos. </span>
                                          </p>
                                          <p></p>
                                          <p><b>Imagens e informações pessoais de menores de idade:</b><span style="font-weight: 400;"> não divulgue informações pessoais, imagens e vídeos de menores de idade sem a autorização prévia dos seus representantes legais. Salvo exceção para informações públicas e que já estejam disponíveis em outros canais na internet ou registros públicos.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Declarações falsas:</b><span style="font-weight: 400;"> não utilize nossa plataforma para realizar declarações falsas sobre você, sobre outras pessoas ou sobre qualquer instituição, empresa ou organização. Também não forneça informações enganosas sobre sua identidade, instituição ou preferências.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Conteúdo enganoso: </b><span
                                            style="font-weight: 400;">não é permitida a produção e divulgação de </span><span
                                            style="font-weight: 400;">conteúdos enganosos, que não refletem a verdade sobre a organização que representa ou sobre que está avaliando. </span>
                                          </p>
                                          <p></p>
                                          <p><b>Spam e divulgação:</b><span style="font-weight: 400;"> não utilize a SchoolAdvisor para divulgar de forma mal intencionada produtos, serviços, conteúdos e avaliações em prol de uma pessoa ou organização. Por exemplo, não utilize o perfil de outras escolas para falar sobre sua própria instituição. Caso esse comportamento seja observado, suas interações serão excluídas e seu perfil deletado permanentemente.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Descontos e propagandas: </b><span style="font-weight: 400;">n</span><span style="font-weight: 400;">ão é autorizado qualquer oferecimento de descontos, nem publicidade de escolas ou organizações sem autorização prévia da SchoolAdvisor nos comentários sobre as instituições. Caso queira divulgar sua escola, por favor, conheça nossos </span><span
                                            style="font-weight: 400;">Planos e Valores</span><span
                                            style="font-weight: 400;">. </span></p>
                                          <p></p>
                                          <p><b>Avaliações anônimas: </b><span style="font-weight: 400;">caso você opte por avaliar anonimamente uma instituição, sua identidade será respeitada. no entanto, encorajamos você que se identifique: saber quem é você dará mais credibilidade aos seus comentários.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Avaliações anônimas vindas da Comunidade SchoolAdvisor no Facebook: </b><span style="font-weight: 400;">assim como previsto nas regras da comunidade (que obrigatoriamente precisam ser aceitas ao entrar na comunidade), prevemos o direito de replicar na plataforma </span>
                                            <a href="https://www.facebook.com/schooladvisorbr/">
                                              <span style="font-weight: 400;">https://www.facebook.com/schooladvisorbr/</span></a><span style="font-weight: 400;">, todo e qualquer comentário lá postado. Entretanto, isso será feito sem identificação do usuário, ou seja, de maneira anônima, mesmo que o usuário tenha se identificado na rede social Facebook.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Uso e distribuição de conteúdo</b><span style="font-weight: 400;">:</span><span style="font-weight: 400;"> você tem acesso a informações produzidas e fornecidas por outras pessoas e organizações, como por exemplo nos descritivos e dados contidos nas páginas da escolas. Não utilize ou distribua este conteúdo sem prévia autorização do detentor da propriedade intelectual deste conteúdo.</span>
                                          </p>
                                          <p></p>
                                          <p><b>Sobre as opiniões contidas na SchoolAdvisor</b><span style="font-weight: 400;">: as opiniões, avaliações, reviews e conteúdos expressos na plataforma não refletem, necessariamente, a opinião da SchoolAdvisor. A plataforma é apenas um canal de hospedagem, reprodução, distribuição e publicação de informações e conteúdos fornecidos por nossos usuários &#8211; pessoas ou instituições.</span>
                                          </p>
                                          <p></p>
                                          <p><span
                                            style="font-weight: 400;">Caso você observe usuários ou instituições que não estão respeitando essas regras, por favor, envie um e-mail para </span><a
                                            href="mailto:contato@schooladvisor.com.br"><span
                                            style="font-weight: 400;">contato@schooladvisor.com.br</span></a><span style="font-weight: 400;">,. O time da SchoolAdvisor fará a avaliação da referida denúncia e tomará a decisão de agir se estiver de acordo com os valores e políticas adotadas pela plataforma.</span>
                                          </p>
                                          <h2></h2></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div><!-- .entry-content -->
              </article><!-- #post-## -->

            </div>
            <!-- end list-single-main-wrapper -->
          </div>
          <!-- end display-posts col-md-8 -->


        </div>
        <!-- end row -->
      </div>
      <!-- end container -->

    </section>
    <!-- section end -->


  </div>
  <!-- Content end -->
</div>
