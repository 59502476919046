<div class="infos-adicionais col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="escola">
  <ng-template [ngIf]="!editing">
    <div class="row align-items-baseline border-top mb-0">
      <div class="infos col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="subtitle">
          <h3>Contato</h3>
        </div>
        <p class="mb-3">{{escola.endereco}}</p>
        <p>
            <button style="line-height: inherit;" matTooltip="Ir para site" (click)="goToSite()"
                    *ngIf="escola.site else showMessagePremium"
                    mat-stroked-button>
              <span>Ir para site</span>
            </button>
            <ng-template #showMessagePremium>
              <a href="javascript:" class="class-premium" (click)="requestInfo('site')" (click)="analytics.linkEscolaField(this.escola, 'site', userService.getCurrentUser())">Solicitar site</a>
            </ng-template>
        </p>
      </div>
      <div class="infos col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 align-self-center">
        <div class="item">
          <ng-template [ngIf]="escolaBeneficio.tipo !== 0 && escola.premium">
            <div class="row beneficio-div">                     
              <h3>
                <img *ngIf="escolaBeneficio.tipo !== 0" src="/images/icon-sale.svg" alt="beneficio">
                <span *ngIf="escolaBeneficio.tipo === 1">Esta escola oferece bolsa</span>
                <span *ngIf="escolaBeneficio.tipo === 2">Esta escola oferece benefício</span>
              </h3>
              <app-blur-display blurText="###########" 
                                fieldName="escolaBeneficio_beneficio"
                                sendEventMail="true"
                                analyticsFieldName="bolsa"
                                [buttonCaption]="'Ver bolsa'" 
                                [escola]="this.escola" 
                                *ngIf="escolaBeneficio.tipo === 1">
                  <strong>Até {{escolaBeneficio.desconto}}%</strong>
                  <button class="mat-focus-indicator mat-stroked-button mat-button-base ng-star-inserted ml-3"
                          *ngIf="!escolaBeneficioSented && !escolaBeneficioSaving"
                          (click)="sendBenefits()">Tenho interesse</button>

                  <mat-progress-bar mode="indeterminate" 
                                    style="display: none"
                                    [style.display]="escolaBeneficioSaving ? '' : 'none'">
                  </mat-progress-bar>
                      
                  <div class="w100 mt-2" *ngIf="escolaBeneficioSented">
                    <small style="font-size: small; font-family: 'Poppins Regular'; color: #639849;">
                      <em>Aguarde o contato da escola</em>
                    </small>
                  </div>
                  <div class="w100 mt-2">
                    <small style="font-size: small; font-family: 'Poppins Regular';">Clique em "Tenho interesse" para receber, da escola, os pré-requisitos da bolsa</small>
                  </div>
                  
              </app-blur-display>
              <app-blur-display blurText="###########" 
                                fieldName="escolaBeneficio_desconto" 
                                sendEventMail="true"
                                analyticsFieldName="benefício"
                                [buttonCaption]="'Ver benefício'" 
                                [escola]="this.escola" 
                                *ngIf="escolaBeneficio.tipo === 2">
                  <strong>{{escolaBeneficio.beneficio}}</strong>
                  <button class="mat-focus-indicator mat-stroked-button mat-button-base ng-star-inserted ml-3"
                          *ngIf="!escolaBeneficioSented && !escolaBeneficioSaving"
                          (click)="sendBenefits()">Tenho interesse</button>

                  <mat-progress-bar mode="indeterminate" 
                      style="display: none"
                      [style.display]="escolaBeneficioSaving ? '' : 'none'">
                  </mat-progress-bar>

                  <div class="w100 mt-2" *ngIf="escolaBeneficioSented">
                    <small style="font-size: small; font-family: 'Poppins Regular'; color: #639849;">
                      <em>Aguarde o contato da escola</em>
                    </small>
                  </div>

                  <div class="w100 mt-2">
                    <small style="font-size: small; font-family: 'Poppins Regular';">Clique em "Tenho interesse" para receber, da escola, os pré-requisitos do benefício</small>
                  </div>
              </app-blur-display>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row align-items-baseline">
      <div class="infos col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 align-self-center">
        <div class="row">
          <div class="item col-12 col-md-6">
            <h3>Mensalidade Média</h3>
            <p>
              <app-blur-display blurText="R$ 00.000,00" 
                                fieldName="mensalidade" 
                                [buttonCaption]="'Ver mensalidade'" 
                                [escola]="this.escola" 
                                *ngIf="escola.mensalidade else showRequestTuition">
                <div>
                  {{escola.mensalidade | currency:'BRL':'symbol':'1.2-2': 'pt-BR'}}
                  <small style="font-size: small; font-family: 'Poppins Regular'">{{escola.data_mensalidade_ano ? ' (referente a ' + escola.data_mensalidade_ano + ')' : ''}}</small>
                </div>
                <div class="sendTable">
                  <button 
                    class="mat-focus-indicator mat-stroked-button mat-button-base ng-star-insertedml-3"
                    *ngIf="!shoolSendTableSented && !schoolSendTableLoading"
                    (click)="sendSchoolTable()">Receber tabela</button>
                  <br *ngIf="!shoolSendTableSented && !schoolSendTableLoading" />
                  <small *ngIf="!shoolSendTableSented && !schoolSendTableLoading">Clique em "Receber tabela" para receber, da escola, a tabela completa de valores</small>
                  <mat-progress-bar mode="indeterminate" 
                    style="display: none"
                    [style.display]="schoolSendTableLoading ? '' : 'none'"></mat-progress-bar>
                  <span *ngIf="shoolSendTableSented && !schoolSendTableLoading && escola.premium"><em>Aguarde o contato da escola</em></span>
                  <span *ngIf="shoolSendTableSented && !schoolSendTableLoading && !escola.premium"><em>Esta escola não disponibiliza a tabela de valores</em></span>
                </div>
              </app-blur-display>
              <ng-template #showRequestTuition>
                <a href="javascript:" class="class-premium" (click)="sendRequestTuition()">Solicitar Mensalidade</a>
                <mat-progress-bar mode="indeterminate" 
                    style="display: none"
                    [style.display]="requestTuitionLoading ? '' : 'none'"></mat-progress-bar>
                <p class="info-response" *ngIf="requestTuitionSented && !requestTuitionLoading">
                  <em *ngIf="escola.premium">Aguarde o contato da escola</em>
                  <em *ngIf="!escola.premium">Já solicitamos à escola que atualize esta informação</em>
                </p>
              </ng-template>
            </p>
          </div>

          <div class="item socials col-12 col-md-6" id="redes_sociais_form">
            <div *ngIf="escola.social">
              <h3>Redes Sociais</h3>
                <a href="{{escola.social['facebook']}}" target="_blank" title="Facebook"
                   (click)="this.analytics.linkEscolaField(this.escola, 'facebook', userService.getCurrentUser())"
                   *ngIf="escola.social['facebook']">
                  <img src="images/social-facebook.svg" alt="Facebook"/>
                </a>
                <a href="{{escola.social['youtube']}}" target="_blank" title="YouTube"
                   (click)="this.analytics.linkEscolaField(this.escola, 'youtube', userService.getCurrentUser())"
                   *ngIf="escola.social['youtube']">
                  <img src="images/social-youtube.svg" alt="YouTube"/>
                </a>
                <a href="{{escola.social['twitter']}}" target="_blank" title="Twitter"
                   (click)="this.analytics.linkEscolaField(this.escola, 'twitter', userService.getCurrentUser())"
                   *ngIf="escola.social['twitter']">
                  <img src="images/social-twitter.svg" alt="Twitter"/>
                </a>
                <a href="{{escola.social['instagram']}}" target="_blank" title="Instagram"
                   (click)="this.analytics.linkEscolaField(this.escola, 'instagram', userService.getCurrentUser())"
                   *ngIf="escola.social['instagram']">
                  <img src="images/social-instagram.svg" alt="Instagram"/>
                </a>
                <a href="{{escola.social['linkedin']}}" target="_blank" title="Linkedin"
                   (click)="this.analytics.linkEscolaField(this.escola, 'linkedin', userService.getCurrentUser())"
                   *ngIf="escola.social['linkedin']">
                  <img src="images/social-linkedin.svg" alt="Linkedin"/>
                </a>
            </div>
          </div>
        </div>
        

        
      </div>
      <div class="infos col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="item"> 
          <div class="item school-contact" *ngIf="!schoolContactSented">
            <button 
              class="mat-focus-indicator mat-stroked-button mat-button-base ng-star-insertedml-3"
              *ngIf="!schoolContactSending"
              (click)="sendContact()">Entrar em contato com esta escola</button>
            
            <mat-progress-bar mode="indeterminate" 
              style="display: none"
              [style.display]="schoolContactSending ? '' : 'none'">
            </mat-progress-bar>
            <div class="w100 mt-2">
              <small style="font-size: small; font-family: 'Poppins Regular';">Clique para receber, da escola, mais informações</small>
            </div>
          </div>
          <div class="item school-contact" *ngIf="schoolContactSented">
            <p *ngIf="!escola.premium">Entre em contato com a escola:</p>
            <p *ngIf="escola.premium">Aguarde o contato da escola ou, se preferir entre em contato:</p>
            <p>
              <a href="tel:{{escola.fone}}"
                *ngIf="escola.fone"
                title="Telefone" 
                target="_blank" 
                (click)="analytics.linkEscolaField(this.escola, 'fone', userService.getCurrentUser())">
                {{(escola.fone?.length > 10)? (escola.fone | mask: '(00) 00000-0000') : (escola.fone | mask: '(00) 0000-0000')}}
              </a>
            </p>
            <p>
              <a href="mailto:{{escola.email}}" 
                 *ngIf="escola.email"
                 title="E-mail" 
                 target="_blank" 
                 (click)="analytics.linkEscolaField(this.escola, 'email', userService.getCurrentUser())">
                 {{escola.email}}
              </a>
            </p>
            <div *ngIf="escola.premium" class="item socials">
              <a
                href="https://api.whatsapp.com/send?phone={{escola.social['whatsapp']}}&text=Oi,%20encontrei%20{{escola.nome}}%20no%20site%20da%20SchoolAdvisor"
                (click)="analytics.linkEscolaField(this.escola, 'whatsapp', userService.getCurrentUser())"
                target="_blank" title="WhatsApp"
                *ngIf="escola.social['whatsapp']">
                <img src="images/social-whats.svg" alt="WhatsApp"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </ng-template>

  <ng-template [ngIf]="editing">
    <div class="row align-items-baseline editing">
      <div class="infos col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="subtitle">
          <h3>Contato</h3>
        </div>
        <mat-form-field floatLabel="always">
            <input maxLength="100" matInput #addressInput
                   placeholder="Endereço"
                   [value]="this.escola.endereco"
                   class="w-100"
            >
        </mat-form-field>
        <p>
          <mat-form-field floatLabel="always">
            <input matInput placeholder="Telefone" mask="(00) 0000-0000||(00) 00000-0000" [(ngModel)]="escola.fone">
          </mat-form-field>
        </p>
        <p id="email_form">
          <mat-form-field floatLabel="always">
            <input maxLength="100" matInput placeholder="e-mail"
                    [(ngModel)]="escola.email"
                    [formControl]="emailFormControl">
            <mat-error style="text-align: right; top: 0" *ngIf="!emailFormControl.valid">
              e-mail inválido
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field floatLabel="always">
            <mat-label>Site</mat-label>
            <input maxLength="100" matInput
                    placeholder="https://www.<site da escola>.com.br"
                    [(ngModel)]="escola.site"
                    [formControl]="siteFormControl">
            <mat-error style="text-align: right; top: 0" *ngIf="!siteFormControl.valid">
              Site/URL inválido (ex. https://site.com.br)
            </mat-error>
          </mat-form-field>
        </p>
      </div>
      <div class="infos col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="item">
          <h3>Mensalidade Média</h3>
          <div fxLayout="row">
            <mat-form-field>
              <mat-label>Valor</mat-label>
              <input maxLength="10" matInput
                      [formControl]="mensalidadeControl"
                      [ngModel]="escola.mensalidade | number:'1.0-2':'pt-BR'"
                      (ngModelChange)="escola.mensalidade =  this.decimalParser($event)">
              <button matSuffix class="fas fa-calculator" title="Calcular Média" (click)="calcMesalidadeMedia()"></button>
            </mat-form-field>
            <mat-form-field style="max-width: 120px">
              <mat-label>A partir do ano de</mat-label>
              <input matInput mask="0000" showMaskTyped="true" 
                [formControl]="mensalidadeAnoControl"
                [(ngModel)]="escola.data_mensalidade_ano">
            </mat-form-field>
          </div>
          <mat-error style="text-align: right; top: 0" *ngIf="!mensalidadeAnoControl.valid">
            Ano da mensalidade é obrigatório
          </mat-error>

          <div fxLayout="row" *ngIf="userService.master == true" class="row-radio">
            <mat-form-field>
              <mat-label>Mostrar mensalidade</mat-label>
              <mat-select [(ngModel)]="escola.mensalidade_show" name="mensalidade_show">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <ng-template [ngIf]="userService.master == true">
            <div fxLayout="row">
              <h3>Editar benefícios</h3>
            </div>
            <div fxLayout="row" class="row-radio">
              <input id="bolsa_nenhum" 
                     type="radio" 
                     [checked]="escolaBeneficio.tipo === 0" 
                     [(ngModel)]="escolaBeneficio.tipo"
                     [value]="0"
                     name="bolsa" />
              <label for="bolsa_nenhum" class="radio-label">Sem benefício</label>
            </div>
            <!-- div fxLayout="row" class="row-radio">
              <input id="bolsa_bolsa" 
                     type="radio" 
                     [checked]="escolaBeneficio.tipo === 1" 
                     [(ngModel)]="escolaBeneficio.tipo"
                     [value]="1" 
                     name="bolsa" />
              <label for="bolsa_bolsa" class="radio-label">Bolsa</label>
            </div -->
            <div fxLayout="row" class="div-beneficios" *ngIf="escolaBeneficio.tipo == 1" style="padding-left: 20px; margin-bottom: 20px;">
              <mat-form-field style="max-width: 120px;">
                <mat-label>Porcentagem</mat-label>
                <input maxLength="2" matInput
                       type="number"
                       style="max-width: 85px;"
                       placeholder="Porcentagem"
                       [(ngModel)]="escolaBeneficio.desconto">
                <b>%</b>
              </mat-form-field>
            </div>
            <div fxLayout="row" class="div-beneficios" class="row-radio">
              <input id="bolsa_beneficio" 
                      type="radio" 
                      [checked]="escolaBeneficio.tipo === 2" 
                      [(ngModel)]="escolaBeneficio.tipo"
                      [value]="2" 
                      name="bolsa" />
              <label for="bolsa_beneficio" class="radio-label">Benefício</label>
            </div>
            <div fxLayout="row" *ngIf="escolaBeneficio.tipo == 2" style="padding-left: 20px;">
              <mat-form-field>
                <mat-label>Nome do Benefício</mat-label>
                <input maxLength="100" matInput placeholder="Nome do Benefício"
                  [(ngModel)]="escolaBeneficio.beneficio">
              </mat-form-field>
            </div>
          </ng-template>
        </div>
        
      </div>
      <div class="infos col-12">
        <div class="item socials" id="redes_sociais_form">
          <div *ngIf="escola.social">
            <h3>Redes Sociais</h3>
            <mat-form-field *ngFor="let item of this.redeSocialArray" floatLabel="always">
              <mat-label>{{item.title}}</mat-label>
              <input matInput maxLength="100" [mask]="item.useMask"
                      [validation]="false"
                      [(ngModel)]="escola.social[item.value]"
                      [formControl]="socialFormControl[item.value]">
              <mat-error style="text-align: right; top: 0" *ngIf="!socialFormControl[item.value].valid">
                Site/URL inválido (ex. {{item.placeholder}})
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>