import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-qualificacoes',
  templateUrl: './escola-qualificacoes.component.html',
  styleUrls: ['./escola-qualificacoes.component.css'],
})
export class EscolaQualificacoesComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}

  showSelo(value: boolean, date: string) {
    return value && (!date || moment(date).isAfter(moment()));
  }
}
