<mat-tab-group fxFlexFill fxFill style="position: relative; top: 80px;">
  <mat-tab label="Parceiros" fxFlexFill [class.d-none]="parceiroArray == null">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <button mat-raised-button class="position-absolute" *ngIf="this.canEdit" (click)="addParceiro()">
        <mat-icon>add</mat-icon>
        Novo Parceiro
      </button>
      <mat-paginator [length]="itemCount"
                     [pageSize]="pageSize"
                     (page)="updatePaginator($event)"
                     #matPaginator>
      </mat-paginator>

      <section style="padding-top: 0px;">
        <div class="row row-map">
          <!-- LIST -->
          <section class="list-schools w-100 col-xl-7 col-lg-7 col-md-7 col-sm-12 ">
            <div class="content">
              <section class="feature list" style="padding-top: 0px;">
                <div class="container">
                  <div class="row">
                    <article class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngFor="let parceiro of parceiroArray">
                      <a
                        href="{{(parceiro.link_url?.startsWith('http'))? parceiro.link_url : '//'+parceiro.link_url}}"
                        fxLayout="column" target="_blank" title="veja mais" class="veja-mais">
                        <div class="image">
                          <img class="escola-image"
                               src="{{(parceiro.logo_path == null) ? ' images/thumb-list.jpg' : parceiro.logo_path}}" alt="Logo Parceiro"
                          />
                        </div>
                        <h3 class="escola-nome" *ngIf="!parceiro.editing">{{parceiro.nome}}</h3>
                      </a>
                      <button mat-mini-fab *ngIf="canEdit && !parceiro.editing"
                              class="position-absolute top-0 start-0"
                              title="Editar Imagem"
                              (click)="editParceiro(parceiro)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <ng-template [ngIf]="parceiro.editing">
                        <mat-form-field class="w-100" required>
                          <mat-label>Nome do Parceiro</mat-label>
                          <input class="nome-lista-edit" matInput
                                 [disabled]="this.saving" placeholder="Nome"
                                 [(ngModel)]="this.parceiro.nome">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                          <mat-label>Site do Parceiro</mat-label>
                          <input class="nome-lista-edit" matInput
                                 [disabled]="this.saving" placeholder="ex. https://schooladvisor.com.br"
                                 [(ngModel)]="this.parceiro.link_url">
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="this.parceiro.flag_exibir_pagina_escola">
                          Exibir na página da Escola
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="this.parceiro.flag_exibir_pagina_parceiro">
                          Exibir na página de Parceiros
                        </mat-checkbox>
                        <button mat-mini-fab *ngIf="parceiro.editing"
                                class="position-absolute top-0 end-0"
                                title="Remover Parceiro"
                                (click)="parceiroRemove(parceiro)">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <button mat-mini-fab *ngIf="parceiro.editing"
                                class="position-absolute end-0" style="top: 45px"
                                title="Editar Imagem"
                                (click)="parceiroImage(parceiro)">
                          <mat-icon>wallpaper</mat-icon>
                        </button>
                        <button mat-mini-fab *ngIf="parceiro.editing"
                                class="position-absolute end-0" style="top: 90px; background-color: #009294"
                                title="Salvar Parceiro"
                                (click)="saveParceiro(parceiro)">
                          <mat-icon>save</mat-icon>
                        </button>
                        <app-uploader showUploadStatus="false" (uploadDone)="uploadDone(parceiro, $event)"></app-uploader>
                      </ng-template>
                    </article>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </section>

      <mat-paginator [length]="itemCount"
                     [pageSize]="pageSize"
                     (page)="updatePaginator($event)"
                     #matPaginatorFooter
      >
      </mat-paginator>
    </div>
    <div class="not-found" *ngIf="filterMessage != null">
      {{this.filterMessage}}
      <!--<mat-icon>search_off</mat-icon>-->
      <!--<mat-progress-bar class="row" mode="query" *ngIf="searchingEscola"></mat-progress-bar>-->
    </div>
  </mat-tab>
</mat-tab-group>
