<!--<h2 mat-dialog-title>Mais filtros</h2>-->
<div fxFlex fxLayout="column">
  <mat-dialog-content class="mat-typography">
    <mat-form-field style="width: 100%">
      <mat-chip-list #chipList aria-label="Listas/Diretórios de Escolas" [formControl]="escolaService.filter">
        <mat-chip *ngFor="let item of escolaService.filter.value" (removed)="remove(item)" removable>
          {{_getTag(item)?.title}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #listaInput
               placeholder="Características"
               [formControl]="listControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        >
      </mat-chip-list>
      <mat-label>Pesquise por características</mat-label>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredTags | async"
                    [value]="item.name">
          <mat-icon>{{item.icon ? item.icon : 'add'}}</mat-icon>
          <div fxFlex fxLayout="column" class="select-item-box" fxLayoutAlign="start stretch">
            <span fxFlex="16px" class="select-group">{{item.group}}</span>
            <span fxFlex="" class="select-title">{{item.title}}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <form [formGroup]="this.escolaService.filterFormGroup">
      <mat-selection-list formControlName="filter">
        <mat-divider></mat-divider>
        <h2>Infraestrutura</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Infraestrutura'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Acessibilidade</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Acessibilidade'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Disciplinas Adicionais</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Disciplinas Adicionais'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Cursos Extracurriculares</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Extracurricular'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Recursos</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Recursos'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Tecnologia</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Tecnologia'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Alimentação</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === 'Alimentação'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

        <h2>Outros</h2>
        <ng-container *ngFor="let item of escolaTags">
          <mat-list-option value="{{item.name}}" *ngIf="item.group === null" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
            {{item.title}}
          </mat-list-option>
        </ng-container>

      </mat-selection-list>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxFill>
      <button mat-button mat-dialog-close mat-flat-button color="primary" (click)="resetFilters()">Limpar Filtros</button>
      <button mat-button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Filtrar</button>
    </div>
  </mat-dialog-actions>
</div>
