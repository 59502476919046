<div style="height: 100px"></div>
<div>
  <div class="content">
    <div class="container" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
      <mat-progress-bar class="w-100" mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
      <ng-template [ngIf]="this.diretorio && !this.loading">
        <!-- VER TODAS -->
        <h2 class="diretorio-nome text-center w-100">{{this.diretorio.nome}}</h2>
<!--        <div class="w-100" fxFlex fxLayout="row-reverse">-->
<!--          <button mat-raised-button-->
<!--                  class="custom-chip truncate"-->
<!--                  (click)="map()">-->
<!--            <mat-icon>map</mat-icon>-->
<!--            Ver no mapa-->
<!--          </button>-->
<!--        </div>-->
        <div class="w-100" style="height: 30px"></div>
        <app-escola-list-vertical
          [showPaginator]="false"
          [pageSize]="0"
          [escolaList]="this.diretorio.escola">
        </app-escola-list-vertical>
      </ng-template>
    </div>
  </div>
</div>
