<article class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  <app-button-favorite [escola]="escola"></app-button-favorite>
  <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" title="veja mais" class="veja-mais">
    <div class="image">
      <img src="{{(escola.logo_escola == null)? 'images/thumb-list.jpg':escola.logo_escola}}" alt="Escola"/>
    </div>
  </a>
  <div class="post-meta d-flex flex-row">
    <div class="niveis d-flex flex-row align-items-baseline">
      <a href="#" title="Berçário">Berçário</a>
      <a href="#" title="Ensino Médio">Ensino Médio</a>
      <a href="#" title="Fundamental I">Fundamental I</a>
      <a href="#" title="Fundamental II">Fundamental II</a>
      <a href="#" title="Infantil">Infantil</a>
    </div>
  </div>
  <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" title="veja mais" class="veja-mais">
    <h3>{{escola.nome}}y</h3>
    <p>Text of the printing and typesetting industry. Lorem Ipsum has been
      the industry's standard dummy text ever since the 1500s</p>
  </a>
</article>


<!--<div class="escola-result" fxFlexFill fxLayout="row">-->
<!--  <div fxFlex="grow" fxLayout="row" fxLayout="space-between stretch" fxLayoutGap="5px">-->
<!--    <a [routerLink]="['/escolas',escola.codigo,escola.nome]" fxFlexFill>-->
<!--      <div class="image-placeholder">-->
<!--        <img mat-result-image class="escola-image"-->
<!--             class="escola-result-image"-->
<!--             [src]="this.logo"-->
<!--             alt="Logo Escola">-->
<!--      </div>-->
<!--      <div fxFlex="100" fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="escola-data">-->
<!--        <div fxFlex="25" fxLayout="row" fxLayoutAlign="space-between start">-->
<!--          <mat-card-title fxFlex="80">{{escola.nome}}</mat-card-title>-->
<!--        </div>-->
<!--        &lt;!&ndash;  <img mat-result-image src="https://source.unsplash.com/random/200x200?sig={{escola.codigo}}" alt="Imagem Escola">&ndash;&gt;-->
<!--        <div fxFlex="25">{{escola.nivel_ensino}}</div>-->
<!--        <div fxFlex="25">{{escola.endereco}}</div>-->
<!--        <div *ngIf="escola.mensalidade">Mensalidade: {{escola.mensalidade|currency:"BRL":"symbol"}}</div>-->
<!--      </div>-->
<!--    </a>-->
<!--  </div>-->
<!--  <div fxFlex="nogrow" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start end">-->
<!--    <button fxFlex fxFlex="nogrow" mat-mini-fab aria-label="favorito" (click)="setFavorito(escola, !escola.favorito)">-->
<!--      <mat-icon [hidden]="!escola.favorito">favorite</mat-icon>-->
<!--      <mat-icon [hidden]="escola.favorito">favorite_border</mat-icon>-->
<!--      &lt;!&ndash;    <mat-icon>favorite_border</mat-icon>&ndash;&gt;-->
<!--    </button>-->
<!--    <rating fxFlex aria-label="rating" [readonly]="true" max="5" rate="0" [(ngModel)]="escola.rate_geral"></rating>-->
<!--  </div>-->
<!--</div>-->
<!--<hr>-->
