import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlNormalizer'
})
export class UrlNormalizerPipe implements PipeTransform {

  transform(value: string): string {
    return value.normalize('NFD').replace(/\p{Diacritic}/gu, '').replace(/\s/gu, '-');
  }

}
