<mat-nav-list>
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon>
    <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item routerLink="/about" (click)="onSidenavClose()">
    <mat-icon>assignment_ind</mat-icon>
    <span class="nav-caption">Quem Somos</span>
  </a>
  <a mat-list-item routerLink="/contato" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Contato</span>
  </a>
  <a mat-list-item routerLink="/escola" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Vantagens Escolas</span>
  </a>
  <a mat-list-item routerLink="/familia" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Vantages Família</span>
  </a>
  <a mat-list-item routerLink="/duvidas" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Dúvidas Frequentes</span>
  </a>
  <a mat-list-item routerLink="/politica" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Política de Privacidade</span>
  </a>
  <a mat-list-item routerLink="/regras" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Regras de Conduta</span>
  </a>
  <a mat-list-item routerLink="/termos" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Termos de Uso</span>
  </a>
  <a mat-list-item routerLink="/mapa" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Buscar Escolas</span>
  </a>
  <a mat-list-item routerLink="/artigos" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Artigos</span>
  </a>
  <a mat-list-item routerLink="#" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">LOGIN</span>
  </a>
</mat-nav-list>
