import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {Observable, combineLatest, of} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/database';
import {DatePipe} from '@angular/common';

export const snapshotToArray = (snapshot: any) => {
  const returnArr: any[] = [];

  snapshot.forEach((childSnapshot: any) => {
    const value = childSnapshot.val();
    if (typeof value === 'object') {
      value.key = childSnapshot.key;
      returnArr.push(value);
    }
  });

  return returnArr;
};

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
      public datepipe: DatePipe,
      private angularFirestore: AngularFirestore,
      private user: UserService,
      private router: Router,
      private db: AngularFireDatabase,
  ) {
  }

  async createRoomIfNotExists(roomName: string, data: { cod_escola: number, room_title: string }): Promise<boolean> {
    let ret = false;
    const refRoom = this.db.database.ref(`chats/rooms/${roomName}`);
    await refRoom.get().then(async value => {
      if (!value.exists()) {
        await refRoom.set(data);
        const refSchool = this.db.database.ref(`escolas/${data.cod_escola}/rooms/${roomName}`);
        await refSchool.set(data);
        ret = true;
      }
    });

    return ret;
    // uid *must* be set by the server
    // const uid = await ref.child('uid').get();
    // if (uid.val() === null) {
    //   await ref.set(data);
    // }
  }

  async sendMessage(roomName: string, content: { message: string, name: string }): Promise<void> {

    const ref = this.db.database.ref(`chats/messages/${roomName}`);
    await ref.push(content);

    // const user = await this.user.userData;
    //
    // if (user) {
    //   const uid = user.uid;
    //   const data = {
    //     uid,
    //     content,
    //     createdAt: Date.now()
    //   };
    //
    //   if (uid) {
    //     const ref = this.angularFirestore.collection('chats').doc(roomName);
    //     return ref.update({
    //       // messages: firestore.arrayUnion(data) // firebased v9.x
    //       messages: firebase.firestore.FieldValue.arrayUnion(data)
    //     });
    //   }
    // }
  }

  joinUsers(chat$: Observable<any>): Observable<any> {
    // console.log('joinUsers');
    let chat: any;
    const joinKeys: any = {};

    return chat$.pipe(
        switchMap(value => {
          // Unique User IDs
          chat = value;
          const uids = Array.from(new Set(value.messages.map((v: { uid: any; }) => v.uid)));

          // Firestore User Doc Reads
          const userDocs = uids.map(u => {
                return this.angularFirestore.doc(`users/${u}`).valueChanges();
              }
          );

          return userDocs.length ? combineLatest(userDocs) : of([]);
        }),
        map(arr => {
          if (arr) {
            arr.forEach(v => {
              if (v) {
                (joinKeys[(v as any).uid] = v);
              }
            });
            chat.messages = chat.messages.map((v: { uid: string }) => {
              return {...v, user: joinKeys[v.uid]};
            });
          }

          return chat;
        })
    );
  }

  /**
   * @deprecated it uses unsafe parameters like date and uid
   * @param message a message
   */
  async leaveChat(message: {
    roomname: string, nickname: string, message: string, date: string, type: string, uid: string
  }): Promise<void> {
    message.uid = this.user.getCurrentUser()?.uid || '';
    message.date = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss') || '';
    message.message = `${message.nickname} leave the room`;
    message.type = 'exit';

    const newMessage = this.db.database.ref('chats/').push();
    await newMessage.set(message);

    this.db.database.ref('roomusers/').orderByChild('roomname').equalTo(message.roomname).on('value', (resp: any) => {
      const roomuser = snapshotToArray(resp);
      const user = roomuser.find(x => x.nickname === message.nickname);
      if (user !== undefined) {
        const userRef = this.db.database.ref('roomusers/' + user.key);
        userRef.update({status: 'offline'});
      }
    });

    await this.router.navigate(['/roomlist']);
  }

  async getChatRooms(cod_escola_array: number[]) {
    const rooms = [];
    for (const cod_escola of cod_escola_array) {
      const escolaRooms = this.db.database.ref(`/escolas/${cod_escola}/rooms/`);
      await escolaRooms.get().then(
          async value => {
            if (value.exists()) {
              const allRoomsPromise = [];
              value.forEach(roomId => {
                const roomInfo = this.db.database.ref(`/chats/rooms/${roomId.key}/room_title`).get();
                allRoomsPromise.push(roomInfo);
                roomInfo.then(snapShot => {
                  rooms.push(snapShot.val());
                });
              });
              // Wait for all promisses to finish
              // It runs in parallel when theres more than one ;)
              await Promise.all(allRoomsPromise);
            }
          }
      );
    }
    return rooms;
  }

}
