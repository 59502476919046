import {AfterViewInit, Component, OnInit} from '@angular/core';
import firebase from 'firebase/app';
import {Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Escola, EscolaLista} from '../../service/Escola';
import {EscolaService} from '../../service/escola.service';

@Component({
  selector: 'app-page-lista',
  templateUrl: './page-lista.component.html',
  styleUrls: ['./page-lista.component.scss']
})
export class PageListaComponent implements OnInit, AfterViewInit {

  escolaList: EscolaLista = null;
  list: Observable<Array<Escola>>;
  escolaCount: number;
  loading = false;

  constructor(private escolaService: EscolaService,
              private route: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    let nome = this.activatedRoute.snapshot.paramMap.get('nome');
    nome = decodeURIComponent(nome);
    // console.log(c);
    nome = nome.replace(/-/gu, ' ');
    this.loading = true;
    firebase.functions().httpsCallable('listas')({nome_lista: nome}).then(value => {
      this.loading = false;
      if (value.data[0]) {
        this.escolaList = value.data[0];
        this.list = of(this.escolaList.escola_lista);
        this.escolaCount = this.escolaList.escola_lista.length;
      } else {
        this.escolaList = null;
      }
    });
  }

  ngOnInit(): void {
  }

  map(): void {
    this.escolaService.listas.setValue([this.escolaList.nome_lista]);
    this.escolaService.filterOption.setValue('listaMap');
    this.route.navigate(['mapa']);
  }
}
