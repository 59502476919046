import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import { EscolaService } from '../../service/escola.service';

@Component({
  selector: 'app-escola-professores-time-pedagogico',
  templateUrl: './escola-professores-time-pedagogico.component.html',
  styleUrls: ['./escola-professores-time-pedagogico.component.css'],
})
export class EscolaProfessoresTimePedagogicoComponent implements OnInit {
  @Input() escola: Escola;
  isExpanded = false;
  blur = true;

  constructor() {}

  get hasSobre(): boolean {
    return this.escola.corpo_docente_sobre && this.escola.corpo_docente_sobre.trim() !== '';
  }

  get hasProporcao(): boolean {
    return this.escola.professores_total > 0
      && this.escola.indices_total_alunos > 0;
  }

  get hasProfissionaisEspecializados(): boolean {
    return this.escola.profissional_nutricao
    || this.escola.profissional_fonoaudiologia
    || this.escola.profissional_psicologia
    || this.escola.profissional_saude
    || this.escola.conselheiro_vocacional;
  }

  get hasFormacaoDocente(): boolean {
    return this.escola.docentes_graduados &&  this.escola.docentes_graduados.trim() !== ''
      || this.escola.docentes_pos_graduados &&  this.escola.docentes_pos_graduados.trim() !== '';
  }

  get hasData(): boolean {
    return this.hasProfissionaisEspecializados 
      || this.hasFormacaoDocente
      || this.hasProporcao
      || this.hasSobre;
  }

  ngOnInit(): void {}

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  unlockInformation(): void {
    this.blur = !this.blur;
  }

  proporcaoAlunosProfessor(): string {
    let total_professores = this.escola.professores_total;

    /*if (this.escola.bercario_professores_total) {
      total_professores += parseInt(this.escola.bercario_professores_total);
    }

    if (this.escola.infantil_professores_total) {
      total_professores += parseInt(this.escola.infantil_professores_total);
    }

    if (this.escola.fundamental_ai_professores_total) {
      total_professores += parseInt(this.escola.fundamental_ai_professores_total);
    }

    if (this.escola.fundamental_af_professores_total) {
      total_professores += parseInt(this.escola.fundamental_af_professores_total);
    }

    if (this.escola.ensino_medio_professores_total) {
      total_professores += parseInt(this.escola.ensino_medio_professores_total);
    }*/

    let proporcao = Math.ceil(this.escola.indices_total_alunos / total_professores);
    return `${proporcao}:1`;
  }

  showICDSA(): boolean {
    return EscolaService.showICDSA(this.escola);
  }


}
