import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, of, BehaviorSubject} from 'rxjs';
import {Escola} from '../../service/Escola';
import {EventEmitter} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UserService} from '../../service/user.service';
import {map} from 'rxjs/operators';
import {AnalyticsService} from '../../service/analytics.service';

@Component({
  selector: 'app-escola-list-vertical',
  templateUrl: './escola-list-vertical.component.html',
  styleUrls: ['./escola-list-vertical.component.scss']
})
export class EscolaListVerticalComponent implements OnInit, AfterViewInit {

  private _escolaList: Observable<Array<Escola>>;
  @Input() escolaCount = 0;
  @Input() pageSize = 20;
  @Input() showPaginator = true;
  @Input() filterMessage: string;
  @Input() showDistanceInFooter = false;
  @Output() mouseEnterEscola = new EventEmitter<{ escola: Escola, index: number }>();
  @Output() mouseLeaveEscola = new EventEmitter<{ escola: Escola, index: number }>();
  @Output() paginatorPage = new EventEmitter<PageEvent>();
  @Output() afterViewInit = new EventEmitter<void>();
  @ViewChild('matPaginator', {static: true}) matPaginator: MatPaginator;
  @ViewChild('matPaginatorFooter', {static: true}) matPaginatorFooter: MatPaginator;

  private minDist: number;
  private maxDist: number;

  // nivelOrder = ['Berçário', 'Infantil', 'Fundamental I', 'Fundamental II', 'Ensino Médio'];

  constructor(
    public userService: UserService,
    private analytics: AnalyticsService,
    private changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    if (this.matPaginator) {
      this.matPaginator._intl.itemsPerPageLabel = 'Escolas por página:';
      this.matPaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {

        if (this.showDistanceInFooter) {
          if (this.minDist && this.maxDist) {
            if (this.maxDist < 1) {
              return `Escolas até ${Math.round(this.maxDist * 1000)} metros`;
            } else {
              return `Escolas até ${Math.round(this.maxDist * 100) / 100} km`;
            }
          }
        }

        if (length === 0 || pageSize === 0) {
          // return `0 de ${length}`;
          return '';
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} de ${length} Escolas`;
      };
    }
  }

  get escolaList(): Observable<Array<Escola>> | Array<Escola> {
    return this._escolaList;
  }

  @Input()
  set escolaList(observable) {
    if (Array.isArray(observable)) {
      this._escolaList = of(observable);
    } else {
      this._escolaList = observable;
    }
    if (this._escolaList) {
      this._escolaList.pipe(map(value => {
        if (value) {
          this.minDist = null;
          this.maxDist = null;
          // console.log('paginator: ' + JSON.stringify(value));
          value.forEach(escola => {
            if (escola.km) {
              this.minDist = Math.min(this.minDist ?? escola.km, escola.km);
              this.maxDist = Math.max(this.maxDist ?? escola.km, escola.km);
            }
          });
          // this.changeDetectorRef.detectChanges();
          // this.changeDetectorRef.markForCheck();
          // console.log(`min: ${this.minDist} max: ${this.maxDist}`);
          
          this.matPaginator.length = this.matPaginator.length; // Force repaint
        }
      })).subscribe();
    }
  }

  updatePaginator(pageEvent: PageEvent): void {
    if (this.matPaginator) {
      this.paginatorPage.emit(pageEvent);
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit.emit();
  }
}
