import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CellepUnidade, cod_parceiro} from '../../service/cellep';
import firebase from 'firebase/app';
import {ActivatedRoute} from '@angular/router';
import {MatOptionSelectionChange} from '@angular/material/core';
import {AnalyticsService} from '../../service/analytics.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-cellep',
  templateUrl: './page-cellep.component.html',
  styleUrls: ['./page-cellep.component.scss']
})
export class PageCellepComponent implements OnInit {

  cellep_list: Array<CellepUnidade> = null;
  loading = false;
  private fragment: string;

  constructor(
    private route: ActivatedRoute,
    public analytics: AnalyticsService,
    private title: Title,
    private meta: Meta
  ) {
    this.title.setTitle('SchoolAdvisor | Unidades Cel.Lep');
    this.meta.updateTag({name: 'description', content: 'Encontre a unidade Cel.Lep mais próxima e melhore seu inglês ou espanhol'});
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });

    this.loading = true;
    firebase.functions().httpsCallable('parceiro_unidade')({cod_parceiro}).then(value => {
      this.loading = false;
      if (value != null) {
        this.cellep_list = value.data;

        setTimeout(() => {
          this.scrollTo(this.fragment);
        }, 1000);

      } else {
        this.cellep_list = null;
      }
    });
  }

  scrollTo(item: string): void {
    // console.log('scrollTo: ' + item);
    if (item != null) {
      // window.scrollTo({top: 0, behavior: 'smooth'});
      const element = document.querySelector('#' + item);
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({top: offsetPosition, behavior: 'smooth'});
    }
  }

}
