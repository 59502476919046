import {Component, Input, OnInit, Output} from '@angular/core';
import {replace} from 'lodash';
import {UserService} from '../../service/user.service';
import {MatDialog} from '@angular/material/dialog';
import {AnalyticsService} from '../../service/analytics.service';
import {Escola} from '../../service/Escola';
import firebase from 'firebase';
import {LoginDialogComponent} from '../auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-blur-display',
  templateUrl: './blur-display.component.html',
  styleUrls: ['./blur-display.component.css']
})
export class BlurDisplayComponent implements OnInit {

  @Input() blurText: string;
  @Input() needLogin = true;
  @Input() fieldName: string;
  @Input() analyticsFieldName: string;
  @Input() sendAnalytics = true;
  @Input() sendEventMail = false;
  @Input() escola: Escola;
  @Input() buttonCaption = 'Ver';
  _value: any;
  showValue = false;

  constructor(
    private matDialog: MatDialog,
    private userService: UserService,
    private analytics: AnalyticsService,
  ) {
  }

  @Input() set value(v: any) {
    this._value = v;
    if (this.blurText == null) {
      this.blurText = replace(v, /\d/g, '9').replace(/[a-zA-Z]/g, '#');
    }
  }

  public get value(): any {
    return this._value;
  }

  ngOnInit(): void {
  }

  async view() {
    const user = this.userService.getCurrentUser();

    if (this.sendAnalytics) {
      const name = this.analyticsFieldName ?? this.fieldName;
      this.analytics.viewEscolaField(this.escola, name, user);
    }

    if (this.sendEventMail) {
      this.analytics.sendEventMail(this.escola, user);
    }
    
    if ((this.needLogin) && (user == null)) {
      this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
    } else {
      this.showValue = true;
    }
  }
}
