<div class="mb-5 about-neighborhood">
  <div class="container">
    <div class="row content-more mb-4" [class.active]="isExpanded">
      <div class="col-12 mb-2">
        <h2 class="text-black font-size-biggest mb-0">Sobre a região</h2>
      </div>
      <div class="col-12 mb-2 content">
        <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.sobre_regiao }}</p>
      </div>
      <div class="col-12 mb-4">
        <div class="action">
          <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
        </div>
      </div>
    </div>
    <div class="row map mb-4" *ngIf="iframeSrc">
      <div class="col-12">
        <div class="content-iframe">
          <iframe width="600" height="450" style="border: 0" loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade" [src]="iframeSrc"> </iframe>
        </div>
      </div>
    </div>
    <div class="row mb-4 pt-2" *ngIf="false">
      <div class="col-12 mb-4">
        <h2 class="text-black font-size-biggest mb-2">Mensalidade média da região</h2>
        <p class="font-size-regular text-lightgray blur mb-0">As mensalidades nessa região variam entre R$ 900,00 e R$ 7.000,00 para o período regular.</p>
      </div>
      <!-- ACTION -->
      <div class="action d-flex align-items-start justify-content-start flex-column">
        <a href="#" class="btn background-pink text-white font-size-regular semibold">Desbloqueie esta informação</a>
      </div>
      <!-- END ACTION -->
    </div>
    <div class="row" *ngIf="false">
      <div class="col-12 content">
        <p class="mb-2"><a href="#" title="Escolas na região da Lapa e Perdizes" class="font-size-regular text-lightgray">Escolas na região da Lapa e Perdizes</a></p>
        <p class="mb-2"><a href="#" title="Escolas na região de Moema e Vila Mariana" class="font-size-regular text-lightgray">Escolas na região de Moema e Vila Mariana</a></p>
        <p class="mb-2"><a href="#" title="Escolas na região de Vila Olímpia e Itaim Bibi" class="font-size-regular text-lightgray">Escolas na região de Vila Olímpia e Itaim Bibi</a></p>
      </div>
    </div>
  </div>
</div>
