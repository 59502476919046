import {Component, Input, OnInit} from '@angular/core';
import {Colecao} from '../../service/Colecao';
import firebase from 'firebase';
import {UserService} from '../../service/user.service';
import {Diretorio} from '../../service/Diretorio';
import {AnalyticsService} from '../../service/analytics.service';
import {FormControl} from '@angular/forms';
import {debounceTime, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-colecao',
  templateUrl: './colecao.component.html',
  styleUrls: ['./colecao.component.css']
})
export class ColecaoComponent implements OnInit {

  @Input() _colecao: Colecao;
  diretorios: Diretorio[];
  diretorioControl = new FormControl();
  @Input() editingMode = false;
  loading = false;
  loadingDiretorioMessage: string = null;
  isLoadingDiretorio = false;

  constructor(
    public userService: UserService,
    public analytics: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.diretorioControl.valueChanges.pipe(
      debounceTime(1500),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (value.length < 3) {
          this.loadingDiretorioMessage = `Digite ao menos 3 letras para pesquisar.`;
        } else {
          this.isLoadingDiretorio = true;
          this.loadingDiretorioMessage = `Procurando diretórios com nome '${value}'`;
          let nome: string;
          if (typeof value === 'string') {
            nome = value;
          } else {
            nome = value.nome;
          }
          firebase.functions().httpsCallable('diretorio')({nome}).then(result => {
            this.isLoadingDiretorio = false;
            const diretorioFiltered: Diretorio[] = result.data;
            this.diretorios = [];
            if (diretorioFiltered?.length > 0) {
              diretorioFiltered.forEach(resultItem => {
                let duplicate = false;
                this.colecao.diretorio.forEach(currentDiretorioList => {
                  if (resultItem.codigo === currentDiretorioList.codigo) {
                    duplicate = true;
                  }
                });
                if (!duplicate) {
                  this.diretorios.push(resultItem);
                }
              });
            }
          });
        }
        return [];
      })
    ).subscribe();
  }

  set colecao(colecao: Colecao) {
    this._colecao = colecao;
  }

  get colecao(): Colecao {
    return this._colecao;
  }

  displayDiretorio(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  loadDiretorios(cod_colecao: number): void {
    this.loading = true;
    firebase.functions().httpsCallable('diretorio')({cod_colecao}).then(value => {
      this.loading = false;
      this.colecao.diretorio = value.data;
    });
  }

}
