import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Parceiro, ParceiroLista} from '../../service/Parceiro';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import firebase from 'firebase/app';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {ParceiroService} from '../../service/parceiro.service';
import * as clone from 'clone';
import {DefaultSliderConfig} from '../../navigation/Slider';

@Component({
  selector: 'app-banner-parceiro',
  templateUrl: './banner-parceiro.component.html',
  styleUrls: ['./banner-parceiro.component.scss']
})
export class BannerParceiroComponent implements OnInit {

  @Input() parceiroLista: ParceiroLista;
  @Input() readonly = false;
  parceiroFiltered: Parceiro[] | string[] = [];
  sliderConfig = DefaultSliderConfig;
  editing = false;
  canEdit = false;
  saving = false;
  isLoading = false;
  selectedParceiro: Parceiro = null;
  parceiroFormControl = new FormControl();
  loadingMessage: string = null;
  @ViewChild('slickModal', {static: false}) slickCarouselComponent: SlickCarouselComponent;

  constructor(private parceiroService: ParceiroService,
              private route: Router,
              public userService: UserService) {
  }

  ngOnInit(): void {
    this.parceiroFormControl.valueChanges.pipe(
      tap((value) => {
        this.parceiroService.nome.setValue(value);
      }),
      debounceTime(1500),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (typeof value === 'string') {
          this.selectedParceiro = null;
          if (value.length < 3) {
            this.loadingMessage = `Digite ao menos 3 letras para pesquisar.`;
          } else {
            this.isLoading = true;
            const criteria = {
              nome: value
            };
            this.loadingMessage = `Procurando parceiros com nome '${value}'`;
            firebase.functions().httpsCallable('nome_parceiro')(criteria).then(value1 => {
              if (value1.data?.length > 0) {
                this.parceiroFiltered = value1.data;
              } else {
                this.parceiroFiltered = [];
              }
              this.isLoading = false;
            });
          }
        } else {
          this.isLoading = false;
          this.selectedParceiro = value;
          // Traga somente a escola marcada
          this.parceiroFiltered = [value];
        }
        return [];
      })
    ).subscribe();

    if (this.parceiroLista?.cod_escola != null) {
      this.refresh();
    }
  }

  private async refresh(): Promise<any> {
    this.parceiroLista.parceiro_lista = null;
    firebase.functions().httpsCallable('lista_parceiro')(this.parceiroLista)
      .then(value => {
        this.parceiroLista = value.data[0];
      });
  }

  map(): void {
    this.parceiroService.listas.setValue([this.parceiroLista.nome_lista]);
    this.parceiroService.filterOption.setValue('listaMap');
    this.route.navigate(['mapa']);
  }

  removeLista(): void {
    this.parceiroLista.parceiro_lista = [];
    firebase.functions().httpsCallable('set_lista_parceiro')(this.parceiroLista)
      .then(value => {
        this.editing = false;
        this.refresh().then();
      });
  }

  addParceiro(parceiro: Parceiro): void {
    // console.log(`parceiroLista: ${JSON.stringify(this.parceiroLista)}`);
    // console.log(`add parceiro: ${JSON.stringify(parceiro)}`);
    if (this.parceiroLista.parceiro_lista == null) {
      this.parceiroLista.parceiro_lista = [];
    }
    this.parceiroLista.parceiro_lista.push(parceiro);
    if (this.slickCarouselComponent) {
      this.slickCarouselComponent.slickGoTo(this.slickCarouselComponent.slides.length);
    }
    // console.log(`parceiro_lista: ${JSON.stringify(this.parceiroLista.parceiro_lista)}`);
  }

  displayParceiro(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  removeParceiro(parceiro: Parceiro): void {
    this.parceiroLista.parceiro_lista.splice(this.parceiroLista.parceiro_lista.indexOf(parceiro), 1);
  }

  saveLista(): void {
    this.saving = true;
    console.log(`this.parceiroLista: ${JSON.stringify(this.parceiroLista)}`);
    firebase.functions().httpsCallable('set_lista_parceiro')(this.parceiroLista)
      .then(value => {
        this.saving = false;
        this.editing = false;
        this.refresh().then();
      });
  }

  cancelEdit(): void {
    this.editing = false;
    this.refresh().then();
  }

  startEdit(): void {
    this.editing = true;
    this.parceiroLista.nome_lista_original = this.parceiroLista.nome_lista;
  }

  move(fromIndex: number, toIndex: number): void {
    if (fromIndex >= 0 && toIndex >= 0) {
      const cloneArray = clone(this.parceiroLista.parceiro_lista);
      const escola = cloneArray[fromIndex];
      cloneArray.splice(fromIndex, 1);
      cloneArray.splice(toIndex, 0, escola);
      this.parceiroLista.parceiro_lista = cloneArray;
    }
  }

}
