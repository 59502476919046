<!--  wrapper  -->
<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <!--section -->
    <section class="gray-section" id="sec1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 display-page nosidebar">
            <div class="list-single-main-wrapper fl-wrap" id="sec2">


              <article id="post-42256" class="post-42256 page type-page status-publish hentry">
                <div class="list-single-main-item-title fl-wrap">
                </div><!-- .list-single-main-item-title-->
                <div class="entry-content clearfix">
                  <div data-elementor-type="wp-post" data-elementor-id="42256" class="elementor elementor-42256" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          class="elementor-element elementor-element-eff2c99 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="eff2c99" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-background-overlay"></div>
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-250f548e elementor-column elementor-col-50 elementor-top-column" data-id="250f548e"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-c3e8623 elementor-widget elementor-widget-heading" data-id="c3e8623"
                                         data-element_type="widget" data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h1 class="elementor-heading-title elementor-size-default">Melhore seu desempenho na captação e retenção de
                                          alunos!</h1></div>
                                    </div>
                                    <div class="elementor-element elementor-element-e558dac elementor-widget elementor-widget-text-editor" data-id="e558dac"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><h4>Ter pais bem informados sobre a escola, significa leads
                                          mais qualificados, menos tempo investido na realização de visitas e maiores chances de uma relação duradoura com
                                          as famílias.</h4></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-c1e25b0 elementor-column elementor-col-50 elementor-top-column" data-id="c1e25b0"
                                   data-element_type="column">
                                <div class="elementor-column-wrap">
                                  <div class="elementor-widget-wrap">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-element elementor-element-fa12020 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="fa12020" data-element_type="section" id="sec3">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-560596f elementor-column elementor-col-33 elementor-top-column" data-id="560596f"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-b8cff5b animated-slow elementor-widget elementor-widget-heading"
                                         data-id="b8cff5b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}"
                                         data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h1 class="elementor-heading-title elementor-size-xxl">80%</h1></div>
                                    </div>
                                    <div class="elementor-element elementor-element-513e221 elementor-widget elementor-widget-divider" data-id="513e221"
                                         data-element_type="widget" data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-8f8434c elementor-widget elementor-widget-text-editor" data-id="8f8434c"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><p><span style="font-weight: 400;">das famílias visita em média 6 escolas antes de escolher  onde seus filhos vão estudar.</span>
                                        </p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-c8042e8 elementor-column elementor-col-33 elementor-top-column" data-id="c8042e8"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-939fd53 animated-slow elementor-widget elementor-widget-heading"
                                         data-id="939fd53" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}"
                                         data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-xxl">90%</h2></div>
                                    </div>
                                    <div class="elementor-element elementor-element-b131dfd elementor-widget elementor-widget-divider" data-id="b131dfd"
                                         data-element_type="widget" data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-6b4d00b elementor-widget elementor-widget-text-editor" data-id="6b4d00b"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><p><span style="font-weight: 400;">das escolas tem questões de comunicação pois as famílias desconhecem sua proposta.</span>
                                        </p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-e302f59 elementor-column elementor-col-33 elementor-top-column" data-id="e302f59"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-06cf1fa animated-slow elementor-widget elementor-widget-heading"
                                         data-id="06cf1fa" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}"
                                         data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-xxl">76%</h2></div>
                                    </div>
                                    <div class="elementor-element elementor-element-a7e9f94 elementor-widget elementor-widget-divider" data-id="a7e9f94"
                                         data-element_type="widget" data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-245943b elementor-widget elementor-widget-text-editor" data-id="245943b"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><p><span style="font-weight: 400;">das escolas afirma que famílias alinhadas aos seus valores diminui as taxas de evasão.</span>
                                        </p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-element elementor-element-8f7efdc elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="8f7efdc" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-background-overlay"></div>
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-4d57b55 elementor-column elementor-col-50 elementor-top-column" data-id="4d57b55"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-8dc6215 elementor-widget elementor-widget-text-editor" data-id="8dc6215"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <h2>Famílias que utilizam a SchoolAdvisor exigem menos esforços de captação.</h2>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-cef680d elementor-widget elementor-widget-text-editor" data-id="cef680d"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><h4>O tempo dedicado pelas equipes das escolas para realizar
                                          visitas guiadas &#8211; individuais ou em grupo &#8211; gera altos custos de aquisição de alunos (CAC).<br>&nbsp;<br>Ao
                                          munir as famílias com informações e recomendações, a SchoolAdvisor encaminha para sua instituição visitantes
                                          mais alinhados com a proposta pedagógica, aumentando as chances de conversão.</h4></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-290d37d elementor-column elementor-col-50 elementor-top-column" data-id="290d37d"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-cce3993 elementor-widget elementor-widget-image" data-id="cce3993"
                                         data-element_type="widget" data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="768" height="516" class="attachment-medium_large size-medium_large lazy" alt="" loading="lazy"
                                               src="./images/escolas/escolas_6-768x516.jpg"
                                               data-src="./images/escolas/escolas_6-768x516.jpg" data-lazy="./images/escolas/escolas_6-768x516.jpg"/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-element elementor-element-2056319 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="2056319" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-056171b elementor-column elementor-col-100 elementor-top-column" data-id="056171b"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-6bdec1f elementor-widget elementor-widget-section_title" data-id="6bdec1f"
                                         data-element_type="widget" data-widget_type="section_title.default">
                                      <div class="elementor-widget-container">
                                        <div class="section-title">
                                          <h2>Como é a visibilidade da sua escola na SchoolAdvisor?</h2>                                    <span
                                          class="section-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-136e4e6 elementor-widget elementor-widget-text-editor" data-id="136e4e6"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><h4><span style="font-weight: 400;">Todas as escolas particulares possuem uma </span><b>Página
                                          Padrão</b><span
                                          style="font-weight: 400;"> composta por informações cedidas pelo Censo Escolar do INEP e complementadas de forma colaborativa pelas famílias. </span>
                                        </h4><h4><span style="font-weight: 400;"> </span></h4><h4><span style="font-weight: 400;">O cadastro completo da sua escola poderá ser realizado pelo seu time gratuitamente. Todas as páginas com dados oficiais, cedidos pelas instituições, recebem o selo de Página Verificada. Dessa forma, garantimos aos nossos usuários a diferenciação entre os conteúdos construídos de forma colaborativa e as informações homologadas pelas escolas. As </span><b>Páginas
                                          Verificadas Gratuitas</b><span style="font-weight: 400;"> exibem aproximadamente 30 itens de consideração para que os pais conheçam sua proposta.</span>
                                        </h4><h4><span style="font-weight: 400;">  </span></h4><h4>Quer mais visibilidade e destaque para sua escola? Então
                                          conheça a <b>Página Verificada Premium</b>. São exibidos para as famílias mais de 70 itens de consideração e sua
                                          instituição ainda conta com benefícios exclusivos.</h4><h4></h4><h4>Confira abaixo suas oportunidades ao ter uma
                                          <b>Página Verificada Premium:</b></h4></div>
                                      </div>
                                    </div>
                                    <section
                                      class="elementor-element elementor-element-46e6722 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="46e6722" data-element_type="section">
                                      <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-row">
                                          <div class="elementor-element elementor-element-b1cb91e elementor-column elementor-col-50 elementor-inner-column"
                                               data-id="b1cb91e" data-element_type="column">
                                            <div class="elementor-column-wrap  elementor-element-populated">
                                              <div class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-220b6f0 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                                     data-id="220b6f0" data-element_type="widget" data-widget_type="image-box.default">
                                                  <div class="elementor-widget-container">
                                                    <div class="elementor-image-box-wrapper">
                                                      <figure class="elementor-image-box-img"><img
                                                        src="./images/thumbs/troph.png"
                                                        title="exiba" alt="exiba"/></figure>
                                                      <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Exiba sua
                                                        escola como sugestão na página dos concorrentes</h3>
                                                        <p class="elementor-image-box-description">Escolha até duas escolas concorrentes
                                                          onde sua Instituição vai aparecer como sugestão na sessão "Você também pode se
                                                          interessar por estas escolas"</p></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="elementor-element elementor-element-e8aba39 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                                     data-id="e8aba39" data-element_type="widget" data-widget_type="image-box.default">
                                                  <div class="elementor-widget-container">
                                                    <div class="elementor-image-box-wrapper">
                                                      <figure class="elementor-image-box-img"><img
                                                        src="./images/thumbs/camera.png"
                                                        title="fotos" alt="fotos"/></figure>
                                                      <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Torne sua
                                                        escola mais atrativa com mais fotos e vídeo</h3>
                                                        <p class="elementor-image-box-description">Proporcione uma experiência mais completa
                                                          e visualmente mais atrativa para as famílias, incluindo até 10 fotos e 1 vídeo
                                                          na Página Premium da sua escola.</p></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-element elementor-element-4f54ace elementor-column elementor-col-50 elementor-inner-column"
                                               data-id="4f54ace" data-element_type="column">
                                            <div class="elementor-column-wrap  elementor-element-populated">
                                              <div class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-f82a1d7 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                                     data-id="f82a1d7" data-element_type="widget" data-widget_type="image-box.default">
                                                  <div class="elementor-widget-container">
                                                    <div class="elementor-image-box-wrapper">
                                                      <figure class="elementor-image-box-img"><img
                                                        src="./images/thumbs/padlock.png"
                                                        title="exclua" alt="exclua"/></figure>
                                                      <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Oculte da
                                                        sua página escolas sugeridas e concorrentes</h3>
                                                        <p class="elementor-image-box-description">Exclua da página premium da sua escola. a
                                                          sessão de escolas concorrentes sugeridas com base no perfil ("Você também pode
                                                          se interessar por estas escolas"). </p></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="elementor-element elementor-element-12f127f elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                                     data-id="12f127f" data-element_type="widget" data-widget_type="image-box.default">
                                                  <div class="elementor-widget-container">
                                                    <div class="elementor-image-box-wrapper">
                                                      <figure class="elementor-image-box-img"><img
                                                        src="./images/thumbs/talk.png"
                                                        title="embaixadores" alt="embaixadores"/></figure>
                                                      <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Fortaleça
                                                        sua marca com pais embaixadores</h3>
                                                        <p class="elementor-image-box-description">Recrute até 5 pais para serem os
                                                          embaixadores da sua escola na SchoolAdvisor. Um canal aberto de comunicação para
                                                          reforço positivo da sua instituição.</p></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section
                          class="elementor-element elementor-element-0ff136f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="0ff136f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-background-overlay"></div>
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-0a5b7af elementor-column elementor-col-100 elementor-top-column" data-id="0a5b7af"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-0567e40 elementor-widget elementor-widget-parallax_content"
                                         data-id="0567e40" data-element_type="widget" data-widget_type="parallax_content.default">
                                      <div class="elementor-widget-container">
                                        <div class="parallax-content">
                                          <div class="intro-item fl-wrap">
                                            <p>
                                              <a href="/contato/0" class="trs-btn">
                                                Clique aqui para saber mais sobre a Página Premium
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div><!-- .entry-content -->
              </article><!-- #post-## -->

            </div>
            <!-- end list-single-main-wrapper -->
          </div>
          <!-- end display-posts col-md-8 -->


        </div>
        <!-- end row -->
      </div>
      <!-- end container -->

    </section>
    <!-- section end -->


  </div>
  <!-- Content end -->
</div>
