import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { Escola } from '../../service/Escola';
import { UserService } from '../../service/user.service';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';
import { AnalyticsService } from '../../service/analytics.service';

@Component({
  selector: 'app-escola-niveis-oferecidos',
  templateUrl: './escola-niveis-oferecidos.component.html',
  styleUrls: ['./escola-niveis-oferecidos.component.css'],
})
export class EscolaNiveisOferecidosComponent implements OnInit {
  @Input() escola: Escola;
  isExpanded = false;
  isBlur = true;
  schoolContactSending = false;
  schoolContactSented = false;

  constructor(private analytics: AnalyticsService, private matDialog: MatDialog, public userService: UserService) {}

  ngOnInit(): void {}

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  showData(): void {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.analytics.viewEscolaField(this.escola, 'regiao', user);

    this.isBlur = false;
  }

  get hasAbout(): boolean {
    return (this.escola.nivel_ensino_sobre && this.escola.nivel_ensino_sobre.trim() !== '')
  }

  get hasSelos(): boolean {
    return (this.escola.geekie)
      || (this.escola.poliedro)
      || (this.escola.google_education)
  }

  get hasNiveis(): boolean {
    return this.escola.nivel_ensino && this.escola.nivel_ensino.length > 0
  }

  get hasProcessoIngresso(): boolean {
    return this.escola.bercario_processo_admissao && this.escola.bercario_processo_admissao.trim() !== ''
      || this.escola.infantil_processo_admissao && this.escola.infantil_processo_admissao.trim() !== ''
      || this.escola.fundamental1_processo_admissao && this.escola.fundamental1_processo_admissao.trim() !== ''
      || this.escola.fundamental2_processo_admissao && this.escola.fundamental2_processo_admissao.trim() !== ''
      || this.escola.ensino_medio_processo_admissao && this.escola.ensino_medio_processo_admissao.trim() !== ''
  }

  get hasSistemaEnsino(): boolean {
    return this.escola.qual_sistema_ensino && this.escola.qual_sistema_ensino.trim() !== ''
  }

  get hasData(): boolean {
    return this.hasAbout
      || this.hasSelos
      || this.hasNiveis
      || this.hasProcessoIngresso
      || this.hasSistemaEnsino
  }

  async sendContact(event: Event): Promise<void> {
    event.preventDefault();

    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.schoolContactSending = true;

    const currentUser = firebase.auth().currentUser;
    const data = {
      nome: currentUser.displayName,
      emailUser: currentUser.email,
      schoolName: this.escola.nome,
      emailTo: this.escola.email,
      tipo: 0,
      premium: this.escola.premium,
    };

    try {
      await firebase.functions().httpsCallable('escola_send_beneficios')(data);
      this.analytics.interesseEscolaField(this.escola, 'escola', this.userService.getCurrentUser());

      this.schoolContactSending = false;
      this.schoolContactSented = true;
    } catch (error) {
      this.schoolContactSending = false;
      this.schoolContactSented = false;
    }
  }

  // this.escola.nivel_ensino.toString()
  renderNiveisDeEnsino(): string {
    let niveis = [];
    this.escola.nivel_ensino.forEach((nivel) => {
      if (nivel === 'Fundamental I') {
        niveis.push('Fundamental Anos Iniciais');
      } else if (nivel === 'Fundamental II') {
        niveis.push('Fundamental Anos Finais');
      } else {
        niveis.push(nivel);
      }
    });
    return niveis.join(', ');
  }
}
