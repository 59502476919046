import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-visao-especialista',
  templateUrl: './escola-visao-especialista.component.html',
  styleUrls: ['./escola-visao-especialista.component.css'],
})
export class EscolaVisaoEspecialistaComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}
}
