import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../service/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import firebase from 'firebase';
import {Subscription} from 'rxjs';
import {ID_LISTA_LOGIN, ID_LISTA_TESTE, MailingBuilderallService} from '../../../service/mailing-builderall.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}


@Component({
  selector: 'dialog-optin',
  templateUrl: './dialog-optin.html',
  styleUrls: ['./dialog-optin.scss']
})
export class DialogOptIn {
  disableAgree = false;
  hintRequired = false;
  subscription: Subscription;
  mensalidadeArray: Array<number> = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];
  dataMatriculasArray: Array<number> = [3, 6, -1];

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogOptIn>,
    private matDialog: MatDialog,
    private mailing: MailingBuilderallService,
    
    @Inject(MAT_DIALOG_DATA) public data: {
      data_aceite: any, 
      data_optin: any, 
      responsavel_crianca: boolean, 
      gestor_funcionario_escola: boolean,
      fornecedor: boolean,
      telefone: any,
    }) {
    dialogRef.disableClose = true;
    this.disableAgree = (data.data_aceite != null);

    this.subscription = this.userService.userDataEvent.subscribe(value => {
      if (value !== null) {
        const data_aceite = userService.userData.data_aceite;
        const data_optin = userService.userData.data_optin;
        const last_prompt = userService.userData.last_prompt;
        if ((data_aceite === null) || (last_prompt)) {
          this.matDialog.open(DialogOptIn, {data: {data_aceite, data_optin}, disableClose: false});
        } else {
          this.matDialog.closeAll();
        }
      }
      this.subscription.unsubscribe();
    });
  }

  agree(): void {
    // console.log(JSON.stringify(this.data));
    if (!this.data.data_aceite || !this.data.telefone) {
      this.hintRequired = true;
    } else {
      const user = {...this.userService.getCurrentUser(), ...this.data};
      this.userService.updateUserData(user).then(() => {
        this.dialogRef.close();
      });

      /*this.mailing.sendMail(this.userService.getCurrentUser()?.email, ID_LISTA_LOGIN, 'login', {
        site_data_optin: (this.data.data_optin != null),
        data_aceite: (this.data.data_aceite ? new Date(this.data.data_aceite).toISOString() : undefined),
        responsavel_crianca: this.data.responsavel_crianca,
        gestor_funcionario_escola: this.data.gestor_funcionario_escola,
      });*/
    }

    // firebase.functions().httpsCallable('set_user_data')({
    //   data_optin: this.data.data_optin, data_aceite: this.data.data_aceite
    // });
  }

  reject(): void {
    this.matDialog.open(DialogYesNo, {data: {}, disableClose: false})
        .afterClosed().subscribe(value => {
      this.dialogRef.close();
    });
  }
}

@Component({
  selector: 'yes-no-dialog',
  templateUrl: './yes-no-dialog.html',
})
export class DialogYesNo {
  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogYesNo>,
    @Inject(MAT_DIALOG_DATA) public data: {
      data_aceite: any, data_optin: any, responsavel_crianca: boolean, gestor_funcionario_escola: boolean
    }) {
  }

  remove(): void {
    this.userService.deleteUser();
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
