import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EscolaService} from '../../service/escola.service';
import {Tag, Tags, getTag} from '../../service/Tag';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-escola-filter',
  templateUrl: './escola-filter-tags.component.html',
  styleUrls: ['./escola-filter-tags.component.css']
})
export class EscolaFilterTagsComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<Array<any>>;
  listControl = new FormControl();
  escolaTags = Tags;
  @ViewChild('listaInput', {static: true}) listaInput: ElementRef<HTMLInputElement>;

  constructor(public escolaService: EscolaService) {
  }

  ngOnInit(): void {
    this.filteredTags = this.listControl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => {
        return this._filter(value);
      })
    );
  }

  resetFilters(): void {
    this.escolaService.filterFormGroup.reset();
  }

  remove(item: string): void {
    const currentValues: string[] = this.escolaService.filter.value;
    if (currentValues == null) {
      return;
    }
    const index = currentValues.indexOf(item);
    if (index >= 0) {
      currentValues.splice(index, 1);
      this.escolaService.filter.setValue(currentValues);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('Selected: ' + event.option.value);
    let currentValues: string[] = this.escolaService.filter.value;
    if (currentValues == null) {
      currentValues = [];
    }
    currentValues.push(event.option.value);
    this.escolaService.filter.setValue(currentValues);
    this.listaInput.nativeElement.value = '';
    this.listControl.setValue(null);
  }

  private _filter(value: string): Array<Tag> {
    if (value == null) {

    } else {

      const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();

      const filtered = Tags.filter((tag) => {

        // let selectedTag: Tag[] = [];
        //
        // if (Tags.indexOf(tag) < 0) { // Hide selected itens
        //   if (value == null) {
        //     return tag;
        //   } else {
        //     const filterValue = value.toLowerCase();
        //     if (tag.nome.toLowerCase().indexOf(filterValue) >= 0) {
        //       return tag;
        //     }
        //   }
        // }
        const normalizedName = tag.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        if (normalizedName.indexOf(normalizedValue) >= 0) {
          return tag;
        }
      });
      return filtered;
    }
  }

  private _getTag(name: string): Tag {
    const tag = getTag(name);
    return tag;
  }

}
