<!--  FOOTER  -->
<footer>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Para Escolas</h3>
        <ul>
          <li><a href="https://comunicacao.schooladvisor.com.br/cadastro-escola" target="_blank" title="Cadastrar minha Escola">Cadastrar minha Escola</a></li>
          <li><a href="https://www.blog.schooladvisor.com.br/conhecer" target="_blank" title="Serviços para Escolas">Serviços para Escolas</a></li>
          <li><a href="https://www.guiaparaescolas.com.br/" target="_blank" title="Guia de Fornecedores para Escolas">Guia de Fornecedores para Escolas</a></li>
          <li><a [routerLink]="['/contato',0]" target="_blank" title="Fale com a SchoolAdvisor">Fale com a SchoolAdvisor</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Para Famílias</h3>
        <ul>
          <li><a [routerLink]="['/mapa']" target="_blank" title="Buscar Escolas">Buscar Escolas</a></li>
          <li><a href="https://www.blog.schooladvisor.com.br/ferramentas" target="_blank" title="Ferramentas">Ferramentas</a></li>
          <li><a href="https://blog.schooladvisor.com.br" target="_blank" title="Conteúdo/Blog">Conteúdo/Blog</a></li>
          <li><a [routerLink]="['/calculadora-de-gastos-escola']" target="_blank" title="Calculadora da Educação">Calculadora da Educação</a></li>
          <li><a href="https://www.blog.schooladvisor.com.br/conduta" target="_blank" title="Regras de Conduta">Regras de Conduta</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Sobre</h3>
        <ul>
          <li><a [routerLink]="['/about']" target="_blank" title="Quem Somos">Quem Somos</a></li>
          <li><a href="https://www.blog.schooladvisor.com.br/privacidade" target="_blank" title="Política de Privacidade">Política de Privacidade</a></li>
          <li><a href="https://www.blog.schooladvisor.com.br/termos-de-uso" target="_blank" title="Termos de Uso">Termos de Uso</a></li>
          <li><a [routerLink]="['/contato']" target="_blank" title="Fale com a SchoolAdvisor">Fale com a SchoolAdvisor</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 redes-sociais">
        <h3>Siga nossas redes sociais</h3>
        <div class="d-flex flex-row align-items-center">
          <a href="https://www.facebook.com/schooladvisorbr/" class="facebook" title="Facebook SchoolAdvisor"
             target="_blank" (click)="analytics.clickRedeSocial('facebook')">
            <img src="images/media-facebook.svg" alt="Facebook SchoolAdvisor"/>
          </a>
          <a href="https://www.facebook.com/groups/2393964620875390" class="facebook" title="Facebook Groups SchoolAdvisor"
             target="_blank" (click)="analytics.clickRedeSocial('facebook groups')">
            <img src="images/media-facebook-group.svg" alt="Facebook SchoolAdvisor Groups"/>
          </a>
          <a href="https://www.instagram.com/schooladvisorbr" class="instagram" title="Instagram SchoolAdvisor"
             target="_blank" (click)="analytics.clickRedeSocial('instagram')">
            <img src="images/media-instagram.svg" alt="Instagram SchoolAdvisor"/>
          </a>
          <a href="https://www.linkedin.com/company/14068696/" class="linkedin" title="Linkedin SchoolAdvisor"
             target="_blank" (click)="analytics.clickRedeSocial('linkedin')">
          <img src="images/media-linkedin.svg" alt="Linkedin SchoolAdvisor"/>
          </a>
        </div>
      </div>
      <div class="col-12 copy">
        <small>© 2021 Copyright - SchoolAdvisor.com.br</small>
      </div>
    </div>
  </div>
</footer>
