<!--<div class="top">-->
<!--    <span>chats/{{chatId}}</span>-->
<!--</div>-->

<ng-container *ngIf="(chat$ | async) as chat" class="">
    <mat-tab-group class="h-100">
        <mat-tab label="Chat" class="h-100" fxLayoutAlign="space-between center" fxLayout="column">
            <div class="top w-100 text-center" fxFlex="10">
                <h3>{{chat.id}}</h3>
            </div>
            <div>
                <ul>
                    <li class="msg" [ngClass]="msg.user?.uid === userService.getCurrentUser().uid ? 'me' : 'him'"
                        *ngFor="let msg of chat.messages; let i = index; trackBy: trackByCreated">
                        {{ msg.content }}
                        <div class="user">
                            <span>{{ msg.user?.name}}</span>
                            <span>{{i}}</span>
                            <!--<img [src]="msg.user?.photoURL">-->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="form w-100 p-2 position-absolute bottom-0 translate-middle-y" fxLayout="row" fxLayoutGap="5px">
                <input [(ngModel)]="newMsg" (keydown.enter)="submit(chat.id)" class="w-100">
                <button mat-stroked-button (click)="submit(chat.id)">Enviar</button>
            </div>
        </mat-tab>
        <mat-tab label="Privado">

        </mat-tab>
    </mat-tab-group>

</ng-container>
