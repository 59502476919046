import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import { EscolaService } from '../../service/escola.service';

@Component({
  selector: 'app-escola-infra-acessibilidade',
  templateUrl: './escola-infra-acessibilidade.component.html',
  styleUrls: ['./escola-infra-acessibilidade.component.css'],
})
export class EscolaInfraAcessibilidadeComponent implements OnInit {
  @Input() escola: Escola;
  blur = true;

  constructor() {}

  ngOnInit(): void {}

  unlockInformation(): void {
    this.blur = !this.blur;
  }

  showIIESA(): boolean {
    return EscolaService.showIIESA(this.escola);
  }

  get hasSobre(): boolean {
    return this.escola.infra_sobre && this.escola.infra_sobre.trim() !== '';
  }

  get hasEspacosColetivos(): boolean {
    return this.escola.infra_area_verde
      || this.escola.infra_auditorio
      || this.escola.infra_banheiro_adaptado
      || this.escola.infra_berco
      || this.escola.infra_biblioteca
      || this.escola.infra_camera
      || this.escola.infra_cantina
      || this.escola.infra_enfermaria
      || this.escola.infra_estacionamento
      || this.escola.infra_parque
      || this.escola.infra_patio_coberto
      || this.escola.infra_patio_descoberto
      || this.escola.infra_piscina
      || this.escola.infra_quadra_coberta
      || this.escola.infra_quadra_descoberta
      || this.escola.infra_refeitorio
      || this.escola.infra_transporte
      || this.escola.infra_viveiro;
  }

  get hasLaboratorios(): boolean {
    return this.escola.infra_laboratorio_ciencias
    || this.escola.infra_laboratorio_robotica
    || this.escola.infra_sala_artes
    || this.escola.infra_sala_climatizada
    || this.escola.infra_sala_esportes
    || this.escola.infra_sala_musica;
  }

  get hasAcessibilidade(): boolean {
    return this.escola.acessibilidade_infra_corrimao
      || this.escola.acessibilidade_infra_rampa
      || this.escola.acessibilidade_infra_elevador
      || this.escola.acessibilidade_infra_banheiro_deficiente
      || this.escola.acessibilidade_sinalizacao_sonora
      || this.escola.acessibilidade_sinalizacao_tatil
      || this.escola.acessibilidade_sinalizacao_visual;
  }

  get hasData(): boolean {
    return this.hasSobre
      || this.showIIESA()
      || this.hasEspacosColetivos
      || this.hasLaboratorios
      || this.hasAcessibilidade;
  }
}
