export interface Escola {
  type: 'Escola';
  codigo: number;
  inep?: number;
  nome?: string;
  cnpj?: string;
  cep?: string;
  latitude?: number;
  longitude?: number;
  endereco?: string;
  endereco_json?: Endereco;
  pais?: string;
  email?: string;
  fone?: string;
  site?: string;
  social?: Social;
  videos?: string[];
  video360?: string;
  ano_fundacao?: number;
  grupo_educacional?: string;
  nivel_ensino?: string[];
  abordagem_pedagogica?: string;
  mensalidade?: number;
  data_mensalidade_ano?: number;
  data_mensalidade_atualizada?: string;
  horario_funcionamento_min?: string;
  horario_funcionamento_max?: string;
  // horario_funcionamento_descricao?: string;
  educacao_bilingue?: string;
  // avaliacao_geral?: number;
  // indices
  indices?: string;
  indices_total_alunos?: number;
  indices_quantidade_salas?: number;
  indices_media_horas_por_dia?: number;
  indices_preparatorio_vestibular?: boolean;
  indices_ranking_enem?: number;
  indices_schooladvisor?: number;

  // turno?: string;
  idioma_sobre?: string;
  idioma_principal?: string[];
  idioma_secundario?: string;
  outros_idiomas?: string[];
  // medicao_didatico_pedagogica?: string;
  modalidade_ensino?: string;

  // infraestrutura
  infra_sobre?: string;
  infra_estacionamento?: boolean;
  infra_sala_climatizada?: boolean;
  infra_banheiro_adaptado?: boolean;
  infra_quadra_coberta?: boolean;
  infra_quadra_descoberta?: boolean;
  infra_piscina?: boolean;
  infra_sala_esportes?: boolean;
  infra_sala_artes?: boolean;
  infra_sala_leitura?: boolean;
  infra_parque?: boolean;
  infra_patio_coberto?: boolean;
  infra_patio_descoberto?: boolean;
  infra_area_verde?: boolean;
  infra_viveiro?: boolean;
  infra_camera?: boolean;
  infra_auditorio?: boolean;
  infra_sala_musica?: boolean;
  infra_biblioteca?: boolean;
  infra_laboratorio_ciencias?: boolean;
  infra_laboratorio_robotica?: boolean;
  infra_enfermaria?: boolean;
  infra_cantina?: boolean;
  infra_refeitorio?: boolean;
  infra_transporte?: boolean;
  infra_berco?: boolean;
  infra_sala_amamentacao?: boolean;
  infra_lactario?: boolean;

  // acessibilidade: infra
  acessibilidade_sobre?: string;
  acessibilidade_qtde_salas?: number;
  acessibilidade_infra_vao_livre?: boolean;
  acessibilidade_infra_corrimao?: boolean;
  acessibilidade_infra_rampa?: boolean;
  acessibilidade_infra_elevador?: boolean;
  acessibilidade_infra_banheiro_deficiente?: boolean;
  // acessibilidade: sinalizacao
  acessibilidade_sinalizacao_piso_tatil?: boolean;
  acessibilidade_sinalizacao_sonora?: boolean;
  acessibilidade_sinalizacao_tatil?: boolean;
  acessibilidade_sinalizacao_visual?: boolean;

  // disciplinas adicionais
  disciplinas_adicionais_sobre?: string;
  disciplinas_adicionais_ensino_religioso?: boolean;
  disciplinas_adicionais_convenio_com_escolas_no_exterior?: boolean;
  disciplinas_adicionais_duplo_diploma?: boolean;
  disciplinas_adicionais_educacao_empreendedora?: boolean;
  disciplinas_adicionais_educacao_socioemocional?: boolean;
  disciplinas_adicionais_educacao_nutricional_culinaria?: boolean;
  disciplinas_adicionais_aulas_de_programacao_coding?: boolean;
  disciplinas_adicionais_oportunidades_de_voluntariado?: boolean;
  disciplinas_informacao_adicional_metodologia_cursos?: string;

  // extracurricular
  extracurricular_sobre?: string;
  extracurricular_artes_desenho?: boolean;
  extracurricular_artes_literatura?: boolean;
  extracurricular_artes_musica?: boolean;
  extracurricular_artes_prod_artistica?: boolean;
  extracurricular_artes_teatro?: boolean;
  extracurricular_esporte_artes_marciais?: boolean;
  extracurricular_esporte_arquearia?: boolean;
  extracurricular_esporte_atletismo?: boolean;
  extracurricular_esporte_badminton?: boolean;
  extracurricular_esporte_ballet?: boolean;
  extracurricular_esporte_basquete?: boolean;
  extracurricular_esporte_circo?: boolean;
  extracurricular_esporte_dancas?: boolean;
  extracurricular_esporte_esgrima?: boolean;
  extracurricular_esporte_futebol?: boolean;
  extracurricular_esporte_ginastica?: boolean;
  extracurricular_esporte_handebol?: boolean;
  extracurricular_esporte_hiphop?: boolean;
  extracurricular_esporte_hoquei?: boolean;
  extracurricular_esporte_ioga?: boolean;
  extracurricular_esporte_judo?: boolean;
  extracurricular_esporte_natacao?: boolean;
  extracurricular_esporte_patinacao?: boolean;
  extracurricular_esporte_rugby?: boolean;
  extracurricular_esporte_skate?: boolean;
  extracurricular_esporte_tchoukball?: boolean;
  extracurricular_esporte_tenis?: boolean;
  extracurricular_esporte_tenis_mesa?: boolean;
  extracurricular_esporte_voleibol?: boolean;
  extracurricular_habilidade_logica_catequese?: boolean;
  extracurricular_habilidade_logica_culinaria?: boolean;
  extracurricular_habilidade_logica_educ_ambiental?: boolean;
  extracurricular_habilidade_logica_educ_financeira?: boolean;
  extracurricular_habilidade_logica_empreendedorismo?: boolean;
  extracurricular_habilidade_logica_horticultura?: boolean;
  extracurricular_habilidade_logica_idiomas?: boolean;
  extracurricular_habilidade_logica_libras?: boolean;
  extracurricular_habilidade_logica_maker?: boolean;
  extracurricular_habilidade_logica_orint_vocacional?: boolean;
  extracurricular_habilidade_logica_perp_vestibular?: boolean;
  extracurricular_habilidade_logica_prep_enem?: boolean;
  extracurricular_habilidade_logica_robotica?: boolean;

  material_didatico_apostilado?: boolean;
  material_didatico_sobre?: string;
  material_didatico_incluso_mensalidade?: boolean;
  qual_sistema_ensino?: string;
  valor_materialdidatico?: number;
  // info_adicional_material_didatico?: string;
  pais_embaixadores?: boolean;

  // recursos
  recursos_sobre?: string;
  // recuros:pedagogico
  recursos_pedagogico_jogos_educativos?: boolean;
  recursos_pedagogico_material_cultural?: boolean;
  recursos_pedagogico_material_cientifico?: boolean;
  // recuros:recreativo
  recursos_recreativo_brinquedo_infantil?: boolean;
  recursos_recreativo_instrumentos?: boolean;
  recursos_recreativo_material_desportivo?: boolean;

  // Visão especialista
  visao_especialista?: string;

  // tecnologia
  tecnologia_sobre?: string;
  tecnologia_aula_online?: boolean;
  // tecnologia:recurso
  tecnologia_recurso_sala_multimidia?: boolean;
  tecnologia_recurso_lousa_digital?: boolean;
  tecnologia_recurso_desktop_aluno?: boolean;
  tecnologia_recurso_notebook_aluno?: boolean;
  tecnologia_recurso_tablet_aluno?: boolean;
  // tecnologia:internet
  tecnologia_internet_aluno?: boolean;
  tecnologia_internet_ensino?: boolean;
  google_education?: boolean;
  geekie?: boolean;
  poliedro?: boolean;

  // alimentacao
  alimentacao_sobre?: string;
  alimentacao_oferece_almoco?: string;
  alimentacao_oferece_jantar?: string;
  alimentacao_oferece_lanche?: string;
  alimentacao_alimentos_cozinha_escola?: string;
  alimentacao_organica?: boolean;
  alimentacao_restricoes?: boolean;
  // alimentacao_informacao_adicional_alimentacao?: string;

  // rate
  rate_geral?: number;
  rate_pedagogico?: number;
  rate_infraestrutura?: number;
  rate_higiene_limpeza?: number;
  rate_alimentacao?: number;
  rate_seguranca?: number;
  rate_extracurriculares?: number;
  rate_acolhimento?: number;
  rate_diversidade?: number;
  rate_inclusao?: number;

  // logo
  logo_path?: string;
  logo_data?: string;
  // icon
  icon_path?: string;
  logo_escola?: string;
  logo_escola_set?: string;

  // nivel de ensino
  nivel_ensino_sobre?: string;
  bercario_periodos_disponiveis?: string[];
  bercario_processo_admissao?: string;
  bercario_profissionais_em_sala?: number;
  bercario_alunos_por_turma?: number;
  bercario_mensalidade_media?: number;
  bercario_matricula_media?: number;
  infantil_periodos_disponiveis?: string[];
  infantil_processo_admissao?: string;
  infantil_profissionais_em_sala?: number;
  infantil_alunos_por_turma?: number;
  infantil_mensalidade_media?: number;
  infantil_matricula_media?: number;
  fundamental1_periodos_disponiveis?: string[];
  fundamental1_processo_admissao?: string;
  fundamental1_profissionais_em_sala?: number;
  fundamental1_alunos_por_turma?: number;
  fundamental1_mensalidade_media?: number;
  fundamental1_matricula_media?: number;
  fundamental2_periodos_disponiveis?: string[];
  fundamental2_processo_admissao?: string;
  fundamental2_profissionais_em_sala?: number;
  fundamental2_alunos_por_turma?: number;
  fundamental2_mensalidade_media?: number;
  fundamental2_matricula_media?: number;
  ensino_medio_periodos_disponiveis?: string[];
  ensino_medio_processo_admissao?: string;
  ensino_medio_profissionais_em_sala?: number;
  ensino_medio_alunos_por_turma?: number;
  ensino_medio_mensalidade_media?: number;
  ensino_medio_matricula_media?: number;

  corpo_docente_sobre?: string;
  professores_estrangeiros?: boolean;
  professores_ensino_superior?: number;
  centro_formacao_propria?: boolean;
  profissional_fonoaudiologia?: boolean;
  profissional_psicologia?: boolean;
  profissional_nutricao?: boolean;
  profissional_saude?: boolean;
  cellep?: string;
  clima?: boolean;
  oebi?: boolean;
  supercerebro?: boolean;

  verificada?: boolean;
  premium?: boolean;
  sobre_escola?: string;
  compra?: boolean;
  data_desativada?: any;
  motivo_desativada?: string;

  favorito?: boolean;
  marker?: any;
  disabled?: boolean;
  tags?: { [key: string]: string[] } | string[];
  parcerias?: string[];
  afiliacoes?: any;
  km?: number;

  lista_escolas?: number[];
  lista_concorrentes?: number[];

  concorrente?: boolean;
  can_edit?: boolean;

  score_info?: Score;

  prevencao_acidentes?: boolean;
  prevencao_acidentes_validade?: string;

  diversidade?: boolean;
  diversidade_validade?: string;

  // eventos
  eventos?: {
    data: string;
    link: string;
    thumb: string;
    thumb_file?: File;
    titulo: string;
  }[];

  // videos
  video_intro?: string;

  bercario_professores_total?: string;
  infantil_professores_total?: string;
  fundamental_ai_professores_total?: string;
  fundamental_af_professores_total?: string;
  ensino_medio_professores_total?: string;

  docentes_graduados?: string;
  docentes_pos_graduados?: string;

  origem_escola?: string;
  competicoes?: string[];
  sobre_regiao?: string;
  matriculas_ano_referencia?: string;
  matriculas_masculino?: string;
  matriculas_feminino?: string;
  matriculas_raca_branca?: string;
  matriculas_raca_preta?: string;
  matriculas_raca_indigena?: string;
  matriculas_raca_nd?: string;
  matriculas_nacionalidades?: NacionalidadeQtd[];
  matriculas_universidades?: {
    title?: string;
    quantidade?: number;
    ano_referencia?: string;
  }[];

  professores_ano_referencia?: string;
  professores_feminino?: string;
  professores_masculino?: string;

  professores_raca_branca?: string;
  professores_raca_preta?: string;
  professores_raca_indigena?: string;
  professores_raca_nd?: string;
  professores_nacionalidades?: NacionalidadeQtd[];
  professores_total?: number;

  mensalidade_show?: boolean;

  intercambio?: boolean;
  competicoes_internacionais?: boolean;
  certificao_outro_idioma?: boolean;
  conselheiro_vocacional?: boolean;

  cod_regiao?: number;
}

export interface NacionalidadeQtd {
  nacionalidade?: string;
  quantidade?: number;
}

export interface TipoCursoExtracurricular {
  esporte: ['artes_marciais'];
  habilidades_logicas: [];
  artes: [];
}

/**
 * endereco: "<LOGRADOURO>, <NUMERO> - <BAIRRO>, <CIDADE> - <ESTADO>, <CEP>, <PAIS>"
 */
export interface Endereco {
  logradouro: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
  pais: string;
}

export interface Unidades {
  cod_unidade: string;
  unidade_nome: string;
  unidade_logo_path: string;
  data_desativada?: any;
}

export interface Social {
  facebook?: string;
  youtube?: string;
  twitter?: string;
  instagram?: string;
  whatsapp?: string;
  linkedin?: string;
}

export interface EscolaLista {
  nome_lista: string;
  nome_lista_original?: string;
  escola_lista?: Escola[];
  cod_escola?: number;
  prioridade?: number;
  origin?: { latitude: number; longitude: number };
  random?: boolean;
  ativa?: boolean;
}

//export const tags: Array<string> = ['Treinamento esportivo', 'Jogos de tabuleiro', 'Ensino religioso', 'Desenvolvimento artístico e musical', 'Desenvolvimento socioemocional', 'Empreendedorismo e finanças', 'Voluntariado e projetos sociais', 'Sustentabilidade e natureza', 'Protagonismo dos estudantes', 'Vestibular e Projeto de Vida', 'Robótica e Programação', 'Fluência digital', 'Estímulo a desafios e competições', 'Criatividade e inovação', 'Mão na massa', 'Pesquisas e estudo do meio', 'Aprendizado por projetos', 'Trabalho em grupo', 'Network', 'Redação e Argumentação', 'Formação internacional e multicultural'];

export const tags: Array<string> = ['Artes e cultura', 'Valores religiosos e espirituais', 'Esportes', 'Envolvimento familiar', 'Socioemocional', 'Personalização do ensino', 'Educação ambiental e sustentabilidade', 'Aprendizagem experiencial e prática', 'Educação tecnológica e fluência digital', 'Cidadania e globalização', 'Exigência acadêmica', 'Preparação universitária'];

export const educacaoBilingue = ['Programa Opcional', 'Sim', 'Não'];

export function updateSrcSet(escola: Escola, currentDimension: string = '600x600', alternativeDimensionsWidth: number[] = [200, 400]): string {
  if (escola?.logo_escola) {
    // tslint:disable-next-line:max-line-length
    // https://firebasestorage.googleapis.com/v0/b/firesample-7bd56.appspot.com/o/images%2FSchoolAdvisor%2FSchoolAdvisor-Escolas0003_600x600.webp?alt=media&token=ac44b43a-8273-462f-9004-2ad15641751c
    escola.logo_escola_set = '';
    alternativeDimensionsWidth.forEach((value) => {
      escola.logo_escola_set += escola.logo_escola.replace(currentDimension, '');
    });
  }
  return null;
}

export interface Score {
  score: number;
  total: number;
  score_info: [
    {
      text: string;
      score: number;
      total: number;
    }
  ];
}

export interface EscolaBeneficio {
  codigo: number;
  tipo: number;
  email?: string;
  desconto?: number;
  beneficio?: string;
}
