import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-termos',
  templateUrl: './page-termos.component.html',
  styleUrls: ['./page-termos.component.css', '../../../css/elementor/post-220.css', '../../../css/elementor/global.css', '../../../css/elementor/frontend.min.css', '../../../css/elementor/style.css'],
})
export class PageTermosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
