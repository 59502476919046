import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-about',
  templateUrl: './page-about.component.html',
  styleUrls: [
    './page-about.component.css',
    '../../../css/elementor/post-220.css',
    '../../../css/elementor/post-531.css',
    '../../../css/elementor/global.css',
    '../../../css/elementor/frontend.min.css',
    '../../../css/elementor/style.css',
  ]
})
export class PageAboutComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta,
  ) {
  }

  ngOnInit(): void {
    const title = 'SchoolAdvisor | Quem Somos';
    this.title.setTitle(title);
    const description = 'A SchoolAdvisor foi Fundada por Três Mulheres e Mães com mais de 15 anos de Experiência no Mercado Corporativo.';
    const keyword = 'schooladvisor, fernanda etlinger, viviane massaini, fernanda tavolaro, google for startups, b2mamy';
    this.meta.updateTag({name: 'description', content: description});
    this.meta.updateTag({name: 'keyword', content: keyword});
    console.log('description: ' + description);
    console.log('keyword:' + keyword);
  }

}
