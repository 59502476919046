<header>
  <div class="bg-header-home"></div>
  <div class="content">
    <div class="container">
      <!-- H1 -->
      <div class="content-title d-flex flex-column align-items-center justify-content-center">
        <h1 class="title">Encontre escolas que têm mais afinidade com sua família</h1>
      </div>
    </div>
  </div>
  <div style="position: absolute; bottom: 10px; width: 100%;" fxFlex fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="primary"
            style="font-size: 2em; border-radius: 30px; padding: 10px 20px;"
            (click)="this.router.navigate(['perfil-afinidade']);"
            ngStyle.lt-sm="font-size: 1em; padding: 0px 10px;">
      Buscar por Afinidade
    </button>
  </div>
</header>

<div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 5vh">
  <div fxLayout="column" fxFlex="80" fxFlex.lt-sm="95">
    <mat-progress-bar mode="indeterminate" *ngIf="this.loading else colecaoFound"></mat-progress-bar>
    <ng-template #colecaoFound>
      <div *ngFor="let diretorio of this.diretorios; let i = index">

        <app-diretorio style="border-bottom: 1px solid black" [diretorio]="diretorio"></app-diretorio>

        <div class="cellep-banner w-100 container align-items-center justify-content-center" *ngIf="i === 0">
          <a href="https://info.rosedaleedu.com/pt-br/pb02/rosedale-global-student-sucess-forum-2024?utm_source=schooladvisor" target="_blank" (click)="analytics.clickCellepBannerHome()">
            <img class="w-100" src="/images/banners/banner_fornecedores_escolas.png">
          </a>
        </div>

        <div class="confira-tambem-list" *ngIf="(colecoes != null) && (i === 1)">
          <h1 class="w-100" class="colecao-nome-list" *ngIf="colecoes != null">Confira também</h1>

          <div fxLayout="row">
            <div class="navigation-arrow navigation-previous" fxLayout="column" fxLayoutAlign="center center">
              <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab (click)="previousClick()">
                <mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_left</mat-icon>
              </button>
            </div>
            <swiper #swiperComponent [config]="this.config" fxFlex="100">
              <ng-template swiperSlide *ngFor="let colecao of this.colecoes">
                <ng-template [ngIf]="colecao">
                  <div>
                    <mat-grid-list class="card-image m-1" cols="1" rowHeight="3:2">
                      <mat-grid-tile class="top-0 bottom-0">
                        <a [routerLink]="['/colecao',colecao.codigo,colecao.nome | urlNormalizer]" target="_blank" style="display: contents">
                          <img class="colecao-image m-auto swiper-lazy" *ngIf="colecao.thumb != ''"
                               src="{{'https://firebasestorage.googleapis.com/v0/b/firesample-7bd56.appspot.com/o/colecao%2Fthumb_'+colecao.codigo+'_600x600.webp?alt=media'}}"
                               (error)="colecao.thumb = ''"
                          />
                        </a>
                      </mat-grid-tile>
                    </mat-grid-list>
                  </div>
                  <div class="m-1 escola-data">
                    <a [routerLink]="['/colecao',colecao.codigo,colecao.nome | urlNormalizer]" target="_blank">
                      <h3 class="colecao-nome">{{colecao.nome}}</h3>
                    </a>
                  </div>
                </ng-template>
              </ng-template>
            </swiper>
            <div class="navigation-arrow navigation-next" fxLayout="column" fxLayoutAlign="center center">
              <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab (click)="nextClick()">
                <mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Parceiros -->
    <section class="feature slide">
      <app-banner-parceiro [parceiroLista]="this.parceiroLista"></app-banner-parceiro>
    </section>
  </div>
</div>
