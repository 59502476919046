<div style="height: 100px"></div>
<div fxFlexFill fxFill style="display: contents; position: relative; top: 100px;">
  <section style="padding-top: 0px;">
    <div class="row">
      <div class="content">

      </div>
      <!-- LIST -->
      <section class="list-schools w-100 col-xl-7 col-lg-7 col-md-7 col-sm-12 ">
        <div class="content">
          <div class="container" fxFlex fxLayout="column">
            <h1 class="w-100 align-content-center align-items-center text-center">Seleção de parceiros SchoolAdvisor</h1>
            <p></p>
            <h2 class="w-100 align-content-center align-items-center text-center">Conheça aqui Soluções e Produtos com vantagens exclusivas para sua escola!</h2>
            <p></p>
            <section class="feature list">
              <div class="container">
                <div class="row">
                  <article class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngFor="let parceiro of parceiroArray">
                    <a [href]="(parceiro.link_url?.startsWith('http'))? parceiro.link_url : '//'+parceiro.link_url"
                       (click)="analytics.clickParceiro(parceiro.nome)"
                      fxLayout="column" target="_blank" title="veja mais" class="veja-mais">
                      <mat-grid-list cols="1" rowHeight="5:4" class="image-div">
                        <img class="escola-image"
                             src="{{(parceiro.logo_path == null) ? ' images/thumb-list.jpg' : parceiro.logo_path}}" alt="Logo Parceiro"
                        />
                      </mat-grid-list>
                      <h3 class="escola-nome" *ngIf="!parceiro.editing">{{parceiro.nome}}</h3>
                    </a>
                  </article>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </section>

</div>
