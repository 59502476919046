import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-go-top-new',
  templateUrl: './button-go-top-new.component.html',
  styleUrls: ['./button-go-top-new.component.css'],
})
export class ButtonGoTopNewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goTop(event: Event): void {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
