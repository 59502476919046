import {Component, Input, OnInit} from '@angular/core';
import {Escola} from '../../service/Escola';
import {DomSanitizer} from '@angular/platform-browser';
import {EscolaService} from '../../service/escola.service';
import {UserService} from '../../service/user.service';

@Component({
  selector: 'app-escola-result',
  templateUrl: './escola-result.component.html',
  styleUrls: ['./escola-result.component.scss']
})
export class EscolaResultComponent implements OnInit {

  @Input() escola: Escola;
  @Input() imageSrc: string;
  logo;
  escolaService = EscolaService;

  constructor(
    private sanitizer: DomSanitizer,
    public userService: UserService) {
  }

  ngOnInit(): void {
    if (this.escola.logo_escola) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png;base64, ${this.escola.logo_escola}`);
    } else {
      this.logo = './images/icone-site-150x150.png';
    }
  }

}
