import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import {Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {UserService} from '../service/user.service';
import {MatDialog} from '@angular/material/dialog';
import {NivelEnsinoAttributes, NivelEnsinoData, NivelEnsinoEnum} from '../service/NivelEnsino';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import firebase from 'firebase/app';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {AnalyticsService} from '../service/analytics.service';
import {ID_LISTA_CALCULADORA, ID_LISTA_TESTE, MailingBuilderallService} from '../service/mailing-builderall.service';
import {LoginDialogComponent} from '../navigation/auth/login-dialog/login-dialog.component';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface NivelData {
  nivel_ensino: NivelEnsinoEnum;
  nome: string;
  index: number;
  serie: number;
}

interface CustoAno {
  nivel_ensino: NivelEnsinoEnum;
  nome: string;
  serie: number;
  mensalidade: number;
  material: number;
  transporte: number;
  idioma: number;
  uniforme: number;
  extra: number;
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  // console.log('value:' + filterValue);
  // console.log('opt: ' + JSON.stringify(opt));
  // console.log('ret: ' + JSON.stringify(opt.filter(item => item.toLowerCase().includes(filterValue))));
  return opt.filter(item => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  animations: [
    trigger('bounce', [transition('false <=> true',
      [
        animate('1000ms ease-in', keyframes([
          style({color: 'red', offset: 0.1}),
          style({transform: 'translate3d(-3px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(3px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-6px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(6px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-6px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(6px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-6px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(3px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-3px, 0, 0)', offset: 0.9}),
        ])),
      ]
    )]),
    trigger('Fading', [
      state('false', style({opacity: 0, display: 'none'})),
      state('true', style({opacity: 1, display: ''})),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('100ms ease-in')),
    ])
  ]
})
export class CalculadoraComponent implements OnInit {

  materialIncluso: boolean = null;
  transporteIncluso: boolean = null;
  idiomaIncluso: 'incluso mensalidade' | 'não incluso' | 'adicionar' = null;
  uniformeIncluso: boolean = null;
  extraIncluso: 'incluso mensalidade' | 'não incluso' | 'adicionar' = null;
  nascimentoBounce = false;
  serieBounce = false;
  mensalidadeBounce = false;
  materialBounce = false;
  transporteBounce = false;
  idiomaBounce = false;
  extraBounce = false;
  @ViewChild('nascimentoItem', {static: true}) nascimentoItem: ElementRef;
  @ViewChild('serieItem', {static: true}) serieItem: ElementRef;
  @ViewChild('mensalidadeItem', {static: true}) mensalidadeItem: ElementRef;
  @ViewChild('materialItem', {static: true}) materialItem: ElementRef;
  @ViewChild('transporteItem', {static: true}) transporteItem: ElementRef;
  @ViewChild('idiomaItem', {static: true}) idiomaItem: ElementRef;
  @ViewChild('extraItem', {static: true}) extraItem: ElementRef;
  @ViewChild('serieInput', {static: true}) serieInput: ElementRef;

  dataNascimentoForm = new FormControl(null, [Validators.required]);
  nivelEnsinoList: NivelData[] = [];
  nivelEnsinoOptions: Observable<NivelData[]>;
  serieForm: FormGroup = this._formBuilder.group({
    serieGroup: null,
  });

  anosTotal = (
    NivelEnsinoData[NivelEnsinoEnum.bercario].anos
    + NivelEnsinoData[NivelEnsinoEnum.infantil].anos
    + NivelEnsinoData[NivelEnsinoEnum.fundamental_1].anos
    + NivelEnsinoData[NivelEnsinoEnum.fundamental_2].anos
    + NivelEnsinoData[NivelEnsinoEnum.ensino_medio].anos
  ); // probably 18
  series_data: CustoAno[] = [];
  graph_nivel_data: { name: string, value: number }[];
  graph_item_data: { name: string, value: number }[];
  selected_serie_index: NivelData;
  ipca = 0.08;
  ano_passado: number;
  valor_total_passado: number;
  valor_total_futuro: number;
  este_ano: number;
  ano_formatura: number;
  valor_mensalidade_input: number;
  hash_item_passado = {
    Mensalidade: 0,
    Material: 0,
    Transporte: 0,
    Idioma: 0,
    Uniforme: 0,
    Cursos: 0
  };
  hash_item_futuro = {
    Mensalidade: 0,
    Material: 0,
    Transporte: 0,
    Idioma: 0,
    Uniforme: 0,
    Cursos: 0
  };

  single = [];
  multi: any[];

  // options
  gradient = true;
  showLegend = false;
  showLabels = true;
  isDoughnut = false;
  legendPosition = 'below';

  colorScheme = {
    domain: ['#ef3f5d', '#009294',
      '#F7A359', '#3A373C',
      '#F2F7F2']
  };
  showCalc = false;

  constructor(
    private userService: UserService,
    private matDialog: MatDialog,
    private _formBuilder: FormBuilder,
    private router: Router,
    private title: Title,
    private meta: Meta,
    public analytics: AnalyticsService,
    private mailing: MailingBuilderallService,
  ) {
    Object.assign(this, {single: this.single});
    this.title.setTitle('SchoolAdvisor | Calculadora de Gastos Escolares');
    this.meta.updateTag({
      name: 'description',
      content: 'Calcule o valor aproximado que irá investir na educação do seu filho até o final do Ensino Médio'
    });
  }

  ngOnInit(): void {
    let index = 1;
    for (const nivel of Object.values(NivelEnsinoEnum)) {
      this.nivelEnsinoList.push(
        ...Array.from({length: NivelEnsinoData[nivel].anos},
          (_, i) => {
            const item: NivelData = {
              nivel_ensino: nivel, nome: null, index, serie: (i + 1)
            };
            index++;
            if (NivelEnsinoData[nivel].anos > 1) {
              const offset = NivelEnsinoData[nivel].offset_nivel ?? 0;
              item.nome = String(nivel + ': ' + (i + 1 + offset) + 'º ano');
            } else {
              item.nome = nivel;
            }
            return item;
          })
      );
    }

    this.nivelEnsinoOptions = this.serieForm.get('serieGroup').valueChanges.pipe(
      startWith(''),
      map(() => this._filter(this.serieInput.nativeElement.value))
    );
  }

  private _filter(value: NivelData | string): NivelData[] {
    // console.log('value: ' + JSON.stringify(value));
    let filterValue: string;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value == null) {
      filterValue = '';
    } else {
      filterValue = value.nome.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
    }
    const filters = filterValue.split(/\s/);
    let ret = this.nivelEnsinoList;
    // console.log('ret: ' + JSON.stringify(ret));
    for (const i of filters) {
      ret = ret.filter(option => {
        if (option.nome.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(i)) {
          return option.nome;
        }
      });
    }
    return ret;
  }

  calc(): void {
    const data: any = {};
    if (this.userService.getCurrentUser()) {
      let scroll = Number.MAX_VALUE;

      if (this.dataNascimentoForm.value == null) {
        this.nascimentoBounce = !this.nascimentoBounce;
        scroll = Math.min(this.nascimentoItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      // console.log('Serie: ' + JSON.stringify(this.serieForm.value));
      if (this.serieForm.value == null) {
        this.serieBounce = !this.serieBounce;
        scroll = Math.min(this.serieItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      if (this.valor_mensalidade_input == null) {
        this.mensalidadeBounce = !this.mensalidadeBounce;
        scroll = Math.min(this.mensalidadeItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      if (this.materialIncluso == null) {
        this.materialBounce = !this.materialBounce;
        scroll = Math.min(this.materialItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      if (this.transporteIncluso == null) {
        this.transporteBounce = !this.transporteBounce;
        scroll = Math.min(this.transporteItem.nativeElement.getBoundingClientRect().top, scroll);
      }
      if (this.idiomaIncluso == null) {
        this.idiomaBounce = !this.idiomaBounce;
        scroll = Math.min(this.idiomaItem.nativeElement.getBoundingClientRect().top, scroll);
      }
      if (this.extraIncluso == null) {
        this.extraBounce = !this.extraBounce;
        scroll = Math.min(this.extraItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      // console.log('scroll: ' + scroll);
      if (scroll < Number.MAX_VALUE) {
        window.scrollBy({behavior: 'smooth', top: scroll - 150});
        return;
      }

      const valor_mensalidades_ano = (this.valor_mensalidade_input ?? 0) * 13;

      this.series_data = [];
      this.graph_nivel_data = [];
      this.graph_item_data = [];
      const hash_nivel = {};
      const hash_item_total = {
        Mensalidade: 0,
        Material: 0,
        Transporte: 0,
        Idioma: 0,
        Uniforme: 0,
        Cursos: 0
      };
      // Clone hash_item_values
      this.hash_item_passado = {...hash_item_total};
      this.hash_item_futuro = {...hash_item_total};
      this.valor_total_passado = 0;
      this.valor_total_futuro = 0;
      for (const item of this.nivelEnsinoList) {

        const nivel_ensino = item.nivel_ensino;
        const ano_diff = item.index - this.selected_serie_index.index;
        let depreciacao: number;
        if (ano_diff < 0) {
          depreciacao = Math.pow(1 - this.ipca, Math.abs(ano_diff));
        } else {
          depreciacao = Math.pow(1 + this.ipca, Math.abs(ano_diff));
        }
        const nivelAttributes: NivelEnsinoAttributes = NivelEnsinoData[nivel_ensino];
        const ca: CustoAno = {
          nivel_ensino,
          nome: item.nome,
          serie: item.index,
          mensalidade: valor_mensalidades_ano * depreciacao,
          material: this.materialIncluso ? 0 : nivelAttributes.material * depreciacao,
          transporte: this.transporteIncluso ? 0 : nivelAttributes.transporte * depreciacao * 12,
          idioma: this.idiomaIncluso !== 'adicionar' ? 0 : nivelAttributes.idiomas * depreciacao * 13,
          uniforme: this.uniformeIncluso ? 0 : nivelAttributes.uniforme * depreciacao,
          extra: this.extraIncluso !== 'adicionar' ? 0 : nivelAttributes.extracurriculares * depreciacao * 12,
        };
        // console.log('Debug: ' + JSON.stringify(ca));
        const nivel_total = ca.mensalidade + ca.material + ca.transporte + ca.idioma + ca.uniforme + ca.extra;
        hash_nivel[nivel_ensino] = (hash_nivel[nivel_ensino] ?? 0) + nivel_total;
        hash_item_total.Mensalidade += ca.mensalidade;
        hash_item_total.Material += ca.material;
        hash_item_total.Transporte += ca.transporte;
        hash_item_total.Idioma += ca.idioma;
        hash_item_total.Uniforme += ca.uniforme;
        hash_item_total.Cursos += ca.extra;

        if (ano_diff < 0) {
          this.hash_item_passado.Mensalidade += ca.mensalidade;
          this.hash_item_passado.Material += ca.material;
          this.hash_item_passado.Transporte += ca.transporte;
          this.hash_item_passado.Idioma += ca.idioma;
          this.hash_item_passado.Uniforme += ca.uniforme;
          this.hash_item_passado.Cursos += ca.extra;
          this.valor_total_passado += ca.mensalidade + ca.material +
            ca.transporte + ca.idioma + ca.uniforme + ca.extra;
        } else {
          this.hash_item_futuro.Mensalidade += ca.mensalidade;
          this.hash_item_futuro.Material += ca.material;
          this.hash_item_futuro.Transporte += ca.transporte;
          this.hash_item_futuro.Idioma += ca.idioma;
          this.hash_item_futuro.Uniforme += ca.uniforme;
          this.hash_item_futuro.Cursos += ca.extra;
          this.valor_total_futuro += ca.mensalidade + ca.material +
            ca.transporte + ca.idioma + ca.uniforme + ca.extra;
        }

        this.series_data.push(ca);
      }

      // const data_instance = hash_item_total;
      const data_instance = this.hash_item_futuro;
      for (const item of Object.keys(data_instance)) {
        const value = data_instance[item];
        if (value != null) {
          this.graph_item_data.push({name: item, value});
        }
      }

      for (const item of Object.keys(hash_nivel)) {
        const value = hash_nivel[item];
        if (value != null) {
          this.graph_nivel_data.push({name: item, value});
        }
      }

      this.este_ano = moment().year();
      this.ano_passado = this.este_ano - 1;
      const anos_passado = this.serieForm.value.serieGroup.index;
      const anos_futuro = this.anosTotal - anos_passado;
      this.ano_formatura = this.este_ano + anos_futuro;


      if (this.serieForm.value == null) {
        this.serieBounce = !this.serieBounce;
        scroll = Math.min(this.serieItem.nativeElement.getBoundingClientRect().top, scroll);
      }

      const data_nascimento_moment = this.dataNascimentoForm.value;
      data.data_nascimento = data_nascimento_moment.toISOString();
      data.serie = this.serieForm.value;
      data.mensalidade = this.valor_mensalidade_input;
      data.materialIncluso = this.materialIncluso;
      data.transporteIncluso = this.transporteIncluso;
      data.idiomaIncluso = this.idiomaIncluso;
      data.extraIncluso = this.extraIncluso;
      data.resultado = {};
      data.resultado.series = this.series_data;

      firebase.functions().httpsCallable('calculadora_gasto_escolar')(data);

      window.scrollTo({top: 0});
      this.showCalc = true;

    } else {
      this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
    }
    this.analytics.clickCalculadoraCalcular();
    // this.mailing.getLista();
    if (this.userService.getCurrentUser()?.email) {
      this.mailing.sendMail(this.userService.getCurrentUser()?.email, ID_LISTA_CALCULADORA, 'calculadora');
    }
  }

  // public test(): void {
  //   this.dataNascimentoForm.setValue(moment(), {emitEvent: true});
  //   this.serieForm.setValue({serieGroup: 5}, {emitEvent: true});
  //   this.selected_serie_index = {serie: 2, index: 2, nivel_ensino: NivelEnsinoEnum.infantil, nome: 'Teste'};
  //   this.valor_mensalidade_input = 100;
  //   this.materialIncluso = true;
  //   this.transporteIncluso = true;
  //   this.idiomaIncluso = 'adicionar';
  //   this.extraIncluso = 'adicionar';
  //   this.calc();
  // }

  chosenYearHandler(normalizedYear: Moment): void {
    let ctrlValue = this.dataNascimentoForm.value;
    if (ctrlValue == null) {
      ctrlValue = moment();
    }
    ctrlValue.year(normalizedYear.year());
    this.dataNascimentoForm.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>): void {
    const ctrlValue = this.dataNascimentoForm.value;
    ctrlValue.month(normalizedMonth.month());
    this.dataNascimentoForm.setValue(ctrlValue);
    datepicker.close();
  }

  decimalParser(event: any): number {
    if (event !== null) {
      const c = event.toString().replace(/[^\d,]/g, '').replace(',', '.');
      if (c.length > 0) {
        return c;
      }
    }
    return null;
  }

  selectNivel(item: NivelData): void {
    this.selected_serie_index = item;
  }

  displayNivelOptionItem(item: NivelData): string {
    return item.nome;
  }

  recalc(): void {
    this.showCalc = false;
    window.scrollTo({top: 0});
    this.analytics.clickCalculadoraCalcularDeNovo();
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  goMap(): void {
    this.router.navigate(['/mapa']);
    this.analytics.clickCalculadoraEncontreEscola();
  }
}
