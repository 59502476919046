import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-regras',
  templateUrl: './page-regras.component.html',
  styleUrls: [
    './page-regras.component.css',
    '../../../css/elementor/post-220.css',
    '../../../css/elementor/post-7248.css',
    '../../../css/elementor/global.css',
    '../../../css/elementor/frontend.min.css',
    '../../../css/elementor/style.css',
  ]
})
export class PageRegrasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
