import {Component, Inject, OnInit} from '@angular/core';
import {PagseguroService, Plano} from '../../service/pagseguro.service';
import {Escola} from '../../service/Escola';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AnalyticsService} from '../../service/analytics.service';

@Component({
  selector: 'app-escola-products',
  templateUrl: './escola-products.component.html',
  styleUrls: ['./escola-products.component.css']
})
export class EscolaProductsComponent implements OnInit {

  saving = false;

  constructor(
    public pagseguroService: PagseguroService,
    @Inject(MAT_DIALOG_DATA) public escola: Escola,
    private analytics: AnalyticsService,
    ) {
  }

  ngOnInit(): void {
  }

  checkout(plano: Plano): void {
    this.saving = true;
    // console.log(`plano: ${JSON.stringify(plano)} saving: ${this.saving} escola:  ${this.escola.inep}`);

    const codigo = (this.escola) ? this.escola.inep : null;
    this.analytics.clickComprar(codigo, plano.nome);
    this.pagseguroService.checkout(plano, codigo).then(() => {
      this.saving = false;
    });
  }
}
