import {Subscription} from 'rxjs';
import {Component, Inject} from '@angular/core';
import {UserService} from '../../../service/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'dialog-confirm-data',
  templateUrl: './dialog-confirm-data.html',
  styleUrls: ['./dialog-confirm-data.scss']
})
export class DialogConfirmData {

    subscription: Subscription;

    constructor(
        public userService: UserService,
        public dialogRef: MatDialogRef<DialogConfirmData>,
        private matDialog: MatDialog,

        @Inject(MAT_DIALOG_DATA) public data: {
            //displayName: any,
            telefone: any,
        }) {

        /*const displayName = userService.getCurrentUser().displayName;
        const telefone = userService.userData.telefone;

        this.subscription = this.userService.userDataEvent.subscribe(value => {

            if (!telefone) {
                this.matDialog.open(DialogConfirmData, {data: {displayName, telefone}, disableClose: false});
            }
            this.subscription.unsubscribe();
        });*/
    }

    save(): void {
        const user = {...this.userService.getCurrentUser(), ...this.data};

        /*if (user.displayName != this.data.displayName)
            user.displayName = this.data.displayName;*/

        this.userService.updateUserData(user).then(() => {
            this.dialogRef.close();
        });
    }
}