import { Component, Input, OnInit, ViewChild } from '@angular/core';
import MapTypeId = google.maps.MapTypeId;
import ZoomControlStyle = google.maps.ZoomControlStyle;
import { Escola } from '../../service/Escola';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-escola-map',
  templateUrl: './escola-map.component.html',
  styleUrls: ['./escola-map.component.css'],
})
export class EscolaMapComponent implements OnInit {
  @ViewChild('staticMapImage', { static: true }) staticImageMap: HTMLImageElement;
  @Input() escola: Escola;
  mapSizeWidth = 300;
  mapSizeHeight = 160;
  GOOGLE_MAPS_API_KEY = environment.GOOGLE_MAPS_API_KEY;

  constructor() {}

  ngOnInit(): void {
    this.mapSizeWidth = innerWidth / 2;

    // src="https://maps.googleapis.com/maps/api/staticmap?
    // center=Brooklyn+Bridge,New+York,NY&amp;zoom=13&amp;
    // size=600x300&amp;maptype=roadmap&amp;
    // markers=color:blue%7Clabel:S%7C40.702147,-74.015794&amp;
    // markers=color:green%7Clabel:G%7C40.711614,-74.012318&amp;
    // markers=color:red%7Clabel:C%7C40.718217,-73.998284&amp;
    // key=AIzaSyA3kg7YWugGl1lTXmAmaBGPNhDW9pEh5bo&amp;
    // signature=GJnbP6sQrFY1ce8IsvG2WR2P0Jw="
    // this.staticImageMap.src = 'https://maps.googleapis.com/maps/api/staticmap?'
    //   + 'center=' + this.escola.latitude + ',' + this.escola.longitude
    //   + '&zoom=13&maptype=roadmap'
    //   + '&key=' + environment.GOOLE_MAPS_API_KEY;
  }
}
