  <div *ngIf="seloData" class="col-12">
    <div class="content-border border-lightgray">
      <div class="row">
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <!-- IMAGE -->
          <figure *ngIf="seloData.selo.imagem" class="feature-image mb-2 mt-1">
            <img [src]="seloData.selo.imagem" [alt]="seloData.selo.nome" />
          </figure>
          <!-- TEXT -->
          <div class="feature-text">
            <p class="font-size-regular text-black mb-0">{{seloData.selo.descricao}}</p>
          </div>
        </div>

        <div class="col-12">
          <div class="div w-100"></div>
        </div>

        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between graph mb-4">
          <h3 class="text-pink font-size-small mb-3">Compare com outras escolas</h3>

          <div class="col-12 content-graph">
            <div class="row">
              <!-- BARS -->
              <div *ngIf="porcentagemRegiao" class="col-12 mb-3">
                <h3 class="font-size-regular text-black bold mb-2">Porcentagem das escolas que possuem o selo no bairro</h3>
                <ul>
                  <li *ngIf="this.showInfo">
                    <div class="bar">
                      <div class="fill background-yellow" style="width: {{porcentagemRegiao}}%"></div>
                    </div>
                    <span class="font-size-small text-yellow">{{porcentagemRegiao}}%</span>
                  </li>
                  <li *ngIf="!this.showInfo" class="blur">
                    <div class="bar">
                      <div class="fill background-yellow" style="width: 50%"></div>
                    </div>
                    <span class="font-size-small text-yellow">50%</span>
                  </li>
                </ul>
              </div>
              <!-- END BARS -->
              <!-- BARS -->
              <div *ngIf="porcentagemCidade" class="col-12">
                <h3 class="font-size-regular text-black bold mb-2">Porcentagem das escolas que possuem o selo na cidade</h3>
                <ul>
                  <li *ngIf="this.showInfo">
                    <div class="bar">
                      <div class="fill background-yellow" style="width: {{porcentagemCidade}}%"></div>
                    </div>
                    <span class="font-size-small text-yellow">{{porcentagemCidade}}%</span>
                  </li>
                  <li *ngIf="!this.showInfo" class="blur">
                    <div class="bar">
                      <div class="fill background-yellow" style="width: 50%"></div>
                    </div>
                    <span class="font-size-small text-yellow">50%</span>
                  </li>
                </ul>
              </div>
              <!-- END BARS -->
            </div>
          </div>
        </div>

        <!-- ACTION -->
        <div class="action d-flex align-items-start justify-content-start flex-column">
          <button *ngIf="!this.showInfo" class="btn background-pink text-white font-size-regular semibold" (click)="showAllInfo()">Desbloquear esta informação</button>
        </div>
        <!-- END ACTION -->
      </div>
    </div>
  </div>