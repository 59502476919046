<div id="professores-time-pedagogico" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Sobre os professores e time pedagógico</h2>
  </div>
  <div *ngIf="this.escola.premium && hasSobre" class="row about-school content-more mb-4" [class.active]="isExpanded">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.corpo_docente_sobre }}</p>
    </div>
    <div class="col-12">
      <div class="action">
        <a href="#verMais" *ngIf="this.escola.corpo_docente_sobre" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="false && this.showICDSA()" class="col-12 mb-4">
    <div *ngIf="this.showICDSA()" class="content-border border-lightgray">
      <div class="row">
        <div *ngIf="false && 'Ocultar por enquanto'" class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <!-- IMAGE -->
          <figure class="feature-image mb-2 mt-1">
            <img src="images/new/school/logo-school-docente.png" alt="SchoolAdvisor Destaque Docente 2023" />
          </figure>
          <!-- TEXT -->
          <div class="feature-text">
            <p class="font-size-regular text-black mb-0">Esta escola se destaca pelo Corpo Docente</p>
          </div>
        </div>
        <div *ngIf="false" class="col-12">
          <div class="div w-100"></div>
        </div>
        <div *ngIf="false" class="col-12 d-flex flex-wrap align-items-center justify-content-between graph mb-4">
          <h3 class="text-pink font-size-small mb-3">Compare com outras escolas</h3>

          <div class="col-12 content-graph">
            <div class="row">
              <!-- BARS -->
              <div class="col-12 mb-3" [class.blur]="blur">
                <h3 class="font-size-regular text-black bold mb-2">Porcentagem das escolas que possuem o selo no bairro</h3>
                <ul>
                  <li>
                    <div class="bar">
                      <div class="fill background-yellow" style="width: 8%"></div>
                    </div>
                    <span class="font-size-small text-yellow">8%</span>
                  </li>
                </ul>
              </div>
              <!-- END BARS -->
              <!-- BARS -->
              <div class="col-12" [class.blur]="blur">
                <h3 class="font-size-regular text-black bold mb-2">Porcentagem das escolas que possuem o selo na cidade</h3>
                <ul>
                  <li>
                    <div class="bar">
                      <div class="fill background-yellow" style="width: 8%"></div>
                    </div>
                    <span class="font-size-small text-yellow">8%</span>
                  </li>
                </ul>
              </div>
              <!-- END BARS -->
            </div>
          </div>
        </div>
        <!-- ACTION -->
        <div *ngIf="false" class="action d-flex align-items-start justify-content-start flex-column">
          <a href="#" (click)="unlockInformation(); $event.preventDefault()" class="btn background-pink text-white font-size-regular semibold">
            {{ this.blur ? "Desbloqueie esta informação" : "Bloquear esta informação" }}
          </a>
        </div>
        <!-- END ACTION -->
      </div>
    </div>
  </div>

  <div *ngIf="hasProporcao || hasProfissionaisEspecializados || hasFormacaoDocente"  class="col-12 mt-2">
    <div class="row mb-2">
      <div *ngIf="hasProporcao" class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 content-text-teacher-student">
        <h3 class="text-teacher-student text-black mb-1">{{ proporcaoAlunosProfessor() }}</h3>
        <p class="font-size-small text-lightgray mb-0">proporção de alunos por professor</p>
      </div>
      <div *ngIf="hasProfissionaisEspecializados" class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
        <p class="text-pink font-size-smallest mb-1">Profissionais especialistas</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.profissional_nutricao">Nutricionista</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.profissional_fonoaudiologia">Fonoaudiólogo</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.profissional_psicologia">Psicólogo</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.profissional_saude">Equipe de Enfermagem / Bombeiro</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.conselheiro_vocacional">Conselheiro Vocacional</p>
      </div>
      <div *ngIf="hasFormacaoDocente" class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
        <p class="text-pink font-size-smallest mb-1">Formação docente</p>
        <p class="font-size-regular text-black semibold mb-1">{{ this.escola.docentes_graduados }}% Graduado</p>
        <p class="font-size-regular text-black semibold mb-0">{{ this.escola.docentes_pos_graduados }}% Pós-graduados</p>
      </div>
    </div>
  </div>

  <div *ngIf="!hasData" class="row about-school content-more mb-4">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">Não Informado</p>
    </div>
  </div>
</div>
