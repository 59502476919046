<!--  wrapper  -->
<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <!--section -->
    <section class="gray-section" id="sec1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 display-page nosidebar">
            <div class="list-single-main-wrapper fl-wrap" id="sec2">


              <article id="post-194" class="post-194 page type-page status-publish hentry">
                <div class="list-single-main-item-title fl-wrap">
                  <h3 class="entry-title">Política de Privacidade</h3></div><!-- .list-single-main-item-title-->
                <div class="entry-content clearfix">
                  <div data-elementor-type="wp-page" data-elementor-id="194" class="elementor elementor-194" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          class="elementor-element elementor-element-215bd0e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="215bd0e1" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-7438cdc7 elementor-column elementor-col-100 elementor-top-column" data-id="7438cdc7"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-551cba2 elementor-widget elementor-widget-text-editor" data-id="551cba2"
                                         data-element_type="widget" data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix"><p><strong>I. Informações gerais</strong></p>
                                          <p>A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou
                                            parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso site. Seu
                                            objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e
                                            da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.</p>
                                          <p>Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014
                                            (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados
                                            Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção
                                            de Dados Pessoais &#8211; RGDP).</p>
                                          <p>Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão
                                            pela qual se convida o usuário a consultar periodicamente esta seção.</p>
                                          <p><strong><br/><br/>II. Direitos do usuário</strong></p>
                                          <p>O site se compromete a cumprir as normas previstas no RGPD, em respeito aos seguintes princípios:</p>
                                          <p>&#8211; Os dados pessoais do usuário serão processados de forma lícita, leal e transparente (licitude,
                                            lealdade e transparência);</p>
                                          <p>&#8211; Os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e
                                            legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades
                                            (limitação das finalidades);</p>
                                          <p>&#8211; Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades
                                            do objetivo para os quais eles são processados (minimização dos dados);</p>
                                          <p>&#8211; Os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os
                                            dados inexatos sejam apagados ou retificados quando possível (exatidão);</p>
                                          <p>&#8211; Os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos titulares
                                            dos dados apenas durante o período necessário para as finalidades para as quais são tratados (limitação da
                                            conservação);</p>
                                          <p>&#8211; Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado
                                            ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou
                                            organizativas adequadas (integridade e confidencialidade).</p>
                                          <p>O usuário do site possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados Pessoais e pelo
                                            RGPD:</p>
                                          <p>&#8211; Direito de confirmação e acesso: é o direito do usuário de obter do site a confirmação de que os
                                            dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de
                                            acessar os seus dados pessoais;</p>
                                          <p>&#8211; Direito de retificação: é o direito do usuário de obter do site, sem demora injustificada, a
                                            retificação dos dados pessoais inexatos que lhe digam respeito;</p>
                                          <p>&#8211; Direito à eliminação dos dados (direito ao esquecimento): é o direito do usuário de ter seus dados
                                            apagados do site;</p>
                                          <p>&#8211; Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus
                                            dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito,
                                            quando o site não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao
                                            tratamento dos dados e em caso de tratamento de dados desnecessários;</p>
                                          <p>&#8211; Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados
                                            com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor
                                            ainda ao uso de seus dados pessoais para definição de perfil de marketing (profiling);</p>
                                          <p>&#8211; Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe digam
                                            respeito e que tenha fornecido ao site, num formato estruturado, de uso corrente e de leitura automática, e
                                            o direito de transmitir esses dados a outro site;</p>
                                          <p>&#8211; Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar sujeito a
                                            nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis
                                            (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma
                                            similar.</p>
                                          <p>O usuário poderá exercer os seus direitos por meio de comunicação escrita enviada ao site com o assunto
                                            &#8220;RGDP-https://www.schooladvisor.com.br&#8221;, especificando:</p>
                                          <p>&#8211; Nome completo ou razão social, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do
                                            Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jurídica, da Receita Federal do Brasil) e endereço de e-mail do
                                            usuário e, se for o caso, do seu representante;</p>
                                          <p>&#8211; Direito que deseja exercer junto ao site;</p>
                                          <p>&#8211; Data do pedido e assinatura do usuário;</p>
                                          <p>&#8211; Todo documento que possa demonstrar ou justificar o exercício de seu direito.</p>
                                          <p>O pedido deverá ser enviado ao e-mail: contato@schooladvisor.com.br, ou por correio, ao seguinte
                                            endereço:</p>
                                          <p><strong>SCHOOLADVISOR CONSULTORIA E MARKETING LTDA</strong></p>
                                          <p>Avenida Jabaquara, 1469 &#8211; Bloco B <br/>Bairro Mirandópolis <br/>São Paulo &#8211; SP <br/>CEP 04045-002
                                          </p>
                                          <p>O usuário será informado em caso de retificação ou eliminação dos seus dados.</p>
                                          <p></p>
                                          <p><strong>III. Dever de não fornecer dados de terceiros</strong></p>
                                          <p>Durante a utilização do <em>site</em>, a fim de resguardar e de proteger os direitos de terceiros, o usuário
                                            do site deverá fornecer somente seus dados pessoais, e não os de terceiros.</p>
                                          <p><strong><br/><br/>IV. Informações coletadas</strong></p>
                                          <p>A coleta de dados dos usuários se dará em conformidade com o disposto nesta Política de Privacidade e
                                            dependerá do consentimento do usuário, sendo este dispensável somente nas hipóteses previstas no art. 11,
                                            inciso II, da Lei Geral de Proteção de Dados Pessoais.</p>
                                          <p><strong><br/>4.1. Tipos de dados coletados</strong></p>
                                          <p><strong><em>4.1.1. Dados de identificação do usuário para realização de cadastro</em></strong></p>
                                          <p>A utilização, pelo usuário, de determinadas funcionalidades do site dependerá de cadastro, sendo que, nestes
                                            casos, os seguintes dados do usuário serão coletados e armazenados:</p>
                                          <p>&#8211; nome</p>
                                          <p>&#8211; data de nascimento</p>
                                          <p>&#8211; endereço de e-mail</p>
                                          <p>&#8211; endereço postal</p>
                                          <p>&#8211; detalhes de redes sociais</p>
                                          <p>&#8211; número de telefone</p>
                                          <p>&#8211; número de CPF</p>
                                          <p>&#8211; número de CNPJ</p>
                                          <p>&#8211; data de nascimento dos filhos <br/>&#8211; nome dos filhos <br/>&#8211; informações cadastrais sobre
                                            a escola</p>
                                          <p></p>
                                          <p><strong><em>4.1.2. Dados informados no formulário de contato</em></strong></p>
                                          <p>Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no site,
                                            incluindo o teor da mensagem enviada, serão coletados e armazenados.</p>
                                          <p></p>
                                          <p><strong><em>4.1.3. Dados relacionados à execução de contratos firmados com o usuário</em></strong></p>
                                          <p>Para a execução de contrato de compra e venda ou de prestação de serviços eventualmente firmado entre o site
                                            e o usuário, poderão ser coletados e armazenados outros dados relacionados ou necessários a sua execução,
                                            incluindo o teor de eventuais comunicações tidas com o usuário.</p>
                                          <p></p>
                                          <p><strong><em>4.1.4. Registros de acesso</em></strong></p>
                                          <p>Em atendimento às disposições do art. 15, <em>caput</em> e parágrafos, da Lei Federal n. 12.965/2014 (Marco
                                            Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis
                                            meses.</p>
                                          <p></p>
                                          <p><strong><em>4.1.5. Newsletter</em></strong></p>
                                          <p>O endereço de <em>e-mail</em> cadastrado pelo usuário que optar por se inscrever em nossa <em>Newsletter</em>
                                            será coletado e armazenado até que o usuário solicite seu descadastro.</p>
                                          <p></p>
                                          <p><strong><em>4.1.6. Dados sensíveis</em></strong></p>
                                          <p><strong>Não</strong> serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos
                                            arts. 9º e 10 do RGPD e nos arts. 11 e seguintes da Lei Geral de Proteção de Dados Pessoais. Assim, dentre outros,
                                            <strong>não</strong> haverá coleta dos seguintes dados:</p>
                                          <p>&#8211; dados que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou
                                            filosóficas, ou a filiação sindical do usuário;</p>
                                          <p>&#8211; dados genéticos;</p>
                                          <p>&#8211; dados biométricos para identificar uma pessoa de forma inequívoca;</p>
                                          <p>&#8211; dados relativos à saúde do usuário;</p>
                                          <p>&#8211; dados relativos à vida sexual ou à orientação sexual do usuário;</p>
                                          <p>&#8211; dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.</p>
                                          <p></p>
                                          <p><strong><em>4.1.7. Coleta de dados não previstos expressamente</em></strong></p>
                                          <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser
                                            coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida
                                            ou imposta por lei.</p>
                                          <p><strong><br/>4.2. Fundamento jurídico para o tratamento dos dados pessoais</strong></p>
                                          <p>Ao utilizar os serviços do site, o usuário está consentindo com a presente Política de Privacidade.</p>
                                          <p>O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do
                                            tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita pelo
                                            e-mail: contato@schooladvisor.com.br, ou por correio enviado ao seguinte endereço:</p>
                                          <p>Avenida Jabaquara, 1469 &#8211; Bloco B <br/>Bairro Mirandópolis <br/>São Paulo &#8211; SP <br/>CEP 04045-002
                                          </p>
                                          <p>O consentimento dos relativamente ou absolutamente incapazes, especialmente de crianças menores de 16
                                            (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou representados.</p>
                                          <p>Poderão ainda ser coletados dados pessoais necessários para a execução e cumprimento dos serviços contratados
                                            pelo usuário no site.</p>
                                          <p>O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse
                                            legítimo ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes:</p>
                                          <p>&#8211; para o cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                                          <p>&#8211; para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização
                                            dos dados pessoais;</p>
                                          <p>&#8211; quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a
                                            contrato do qual seja parte o usuário, a pedido do titular dos dados;</p>
                                          <p>&#8211; para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último
                                            nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</p>
                                          <p>&#8211; para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro;</p>
                                          <p>&#8211; para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades
                                            sanitárias;</p>
                                          <p>&#8211; quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso
                                            de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a proteção dos dados
                                            pessoais;</p>
                                          <p>&#8211; para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</p>
                                          <p><strong><br/>4.3. Finalidades do tratamento dos dados pessoais</strong></p>
                                          <p>Os dados pessoais do usuário coletados pelo site têm por finalidade facilitar, agilizar e cumprir os
                                            compromissos estabelecidos com o usuário e a fazer cumprir as solicitações realizadas por meio do
                                            preenchimento de formulários.</p>
                                          <p>Os dados pessoais poderão ser utilizados também com uma finalidade comercial, para personalizar o conteúdo
                                            oferecido ao usuário, bem como para dar subsídio ao site para a melhora da qualidade e funcionamento de seus
                                            serviços.</p>
                                          <p>O site recolhe os dados do usuário para que seja realizada definição de perfis (profiling), ou seja,
                                            tratamento automatizado de dados pessoais que consista em utilizar estes dados para avaliar certos aspectos
                                            pessoais do usuário, principalmente para analisar ou prever características relacionadas ao seu desempenho
                                            profissional, a sua situação econômica, saúde, preferências pessoais, interesses, fiabilidade,
                                            comportamento, localização ou deslocamento.</p>
                                          <p>Os dados de cadastro serão utilizados para permitir o acesso do usuário a determinados conteúdos do site,
                                            exclusivos para usuários cadastrados.</p>
                                          <p>A coleta de dados relacionados ou necessários à execução de um contrato de compra e venda ou de prestação de
                                            serviços eventualmente firmado com o usuário terá a finalidade de conferir às partes segurança jurídica,
                                            além de facilitar e viabilizar a conclusão do negócio.</p>
                                          <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá
                                            mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui previstos
                                            permanecerão aplicáveis.</p>
                                          <p><strong><br/>4.4. Prazo de conservação dos dados pessoais</strong></p>
                                          <p>Os dados pessoais do usuário serão conservados por um período não superior ao exigido para cumprir os
                                            objetivos em razão dos quais eles são processados.</p>
                                          <p>O período de conservação dos dados são definidos de acordo com os seguintes critérios:</p>
                                          <p>Os dados serão armazenados por tempo indeterminado.</p>
                                          <p>Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes
                                            hipóteses:</p>
                                          <p>&#8211; para o cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                                          <p>&#8211; para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados
                                            pessoais;</p>
                                          <p>&#8211; para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos
                                            na legislação;</p>
                                          <p>&#8211; para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os
                                            dados.</p>
                                          <p><strong><br/>4.5. Destinatários e transferência dos dados pessoais</strong></p>
                                          <p>Os dados pessoais do usuário poderão ser compartilhados com as seguintes pessoas ou empresas:</p>
                                          <p>&#8211; Google Analytics com endereço à Av. Brigadeiro Faria Lima, 3477 &#8211; São Paulo &#8211; CEP
                                            04538-133, Brasil </p>
                                          <p>&#8211; Google Cloud com endereço à Av. Brigadeiro Faria Lima, 3477 &#8211; São Paulo &#8211; CEP 04538-133,
                                            Brasil </p>
                                          <p>&#8211; WordPress com endereço virtual à https://br.wordpress.org/ </p>
                                          <p>&#8211; PagSeguro com endereço à Av. Brg. Faria Lima, 1384 &#8211; São Paulo &#8211; CEP 01452-002,
                                            Brasil </p>
                                          <p>&#8211; Google Maps com endereço à Av. Brigadeiro Faria Lima, 3477 &#8211; São Paulo &#8211; CEP 04538-133,
                                            Brasil</p>
                                          <p></p>
                                          <p>A transferência apenas poderá ser feita para outro país caso o país ou território em questão ou a organização
                                            internacional em causa assegurem um nível de proteção adequado dos dados do usuário.</p>
                                          <p>Caso não haja nível de proteção adequado, o site se compromete a garantir a proteção dos seus dados de acordo
                                            com as regras mais rigorosas, por meio de cláusulas contratuais específicas para determinada transferência,
                                            cláusulas-padrão contratuais, normas corporativas globais ou selos, certificados e códigos de conduta
                                            regularmente emitidos.</p>
                                          <p><strong><br/><br/>V. Do tratamento dos dados pessoais</strong></p>
                                          <p></p>
                                          <p><strong>5.1. Do responsável pelo tratamento dos dados (<em>data controller</em>)</strong></p>
                                          <p>O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou jurídica, a
                                            autoridade pública, a agência ou outro organismo que, individualmente ou em conjunto com outras, determina
                                            as finalidades e os meios de tratamento de dados pessoais.</p>
                                          <p>Neste site, o responsável pelo tratamento dos dados pessoais coletados é <strong>SCHOOLADVISOR CONSULTORIA E
                                            MARKETING LTDA</strong>, representada por Fernanda Etlinger, que poderá ser contactado pelo e-mail:
                                            fernanda.etlinger@schooladvisor.com.br ou no endereço:</p>
                                          <p>Avenida Jabaquara, 1469 &#8211; Bloco B <br/>Bairro Mirandópolis <br/>São Paulo &#8211; SP <br/>CEP 04045-002
                                          </p>
                                          <p>O responsável pelo tratamento dos dados se encarregará diretamente do tratamento dos dados pessoais do
                                            usuário.</p>
                                          <p><strong><br/>5.2. Do encarregado de proteção de dados (<em>data protection officer</em>)</strong></p>
                                          <p>O encarregado de proteção de dados (<em>data protection officer</em>) é o profissional encarregado de
                                            informar, aconselhar e controlar o responsável pelo tratamento dos dados, bem como os trabalhadores que
                                            tratem os dados, a respeito das obrigações do site nos termos do RGDP, da Lei Geral de Proteção de Dados Pessoais
                                            e de outras disposições de proteção de dados presentes na legislação nacional e internacional, em cooperação
                                            com a autoridade de controle competente.</p>
                                          <p>Neste site o encarregado de proteção de dados (<em>data protection officer</em>) é <strong>Fernanda
                                            Etlinger</strong>, que poderá ser contactado pelo e-mail: fernanda.etlinger@schooladvisor.com.br.</p>
                                          <p><strong><br/><br/>VI. Segurança no tratamento dos dados pessoais do usuário</strong></p>
                                          <p>O site se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de
                                            acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais
                                            dados.</p>
                                          <p>Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os
                                            custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os
                                            direitos e liberdades do usuário.</p>
                                          <p>O site utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma
                                            segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o usuário, e em
                                            retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.</p>
                                          <p>No entanto, o site se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de
                                            hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a
                                            terceiro. O site se compromete, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de
                                            violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e
                                            liberdades pessoais.</p>
                                          <p>A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a
                                            destruição, a perda, a alteração, a divulgação ou o acesso não autorizados a dados pessoais transmitidos,
                                            conservados ou sujeitos a qualquer outro tipo de tratamento.</p>
                                          <p>Por fim, o site se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites
                                            legais.</p>
                                          <p></p>
                                          <p><strong>VII. Dados de navegação (<em>cookies</em>)</strong></p>
                                          <p><em>Cookies</em> são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam
                                            armazenados, com informações relacionadas à navegação do site.</p>
                                          <p>Por meio dos <em>cookies</em>, pequenas quantidades de informação são armazenadas pelo navegador do usuário
                                            para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o
                                            dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site.</p>
                                          <p>Os <em>cookies</em> não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do
                                            usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não
                                            tenham partido do usuário ou da forma como utiliza os recursos do site.</p>
                                          <p><br/>É importante ressaltar que nem todo <em>cookie</em> contém informações que permitem a identificação do
                                            usuário, sendo que determinados tipos de <em>cookies</em> podem ser empregados simplesmente para que o site
                                            sejam carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.</p>
                                          <p>As informações eventualmente armazenadas em <em>cookies</em> que permitam identificar um usuário são
                                            consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também
                                            lhes são aplicáveis.</p>
                                          <p><strong><br/>7.1. Cookies do</strong> <strong>site</strong></p>
                                          <p>Os <em>cookies</em> do site são aqueles enviados ao computador ou dispositivo do usuário e administrador
                                            exclusivamente pelo site.</p>
                                          <p>As informações coletadas por meio destes <em>cookies</em> são utilizadas para melhorar e personalizar a
                                            experiência do usuário, sendo que alguns <em>cookies</em> podem, por exemplo, ser utilizados para lembrar as
                                            preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.</p>
                                          <p><strong><br/>7.2. Cookies de terceiros</strong></p>
                                          <p>Alguns de nossos parceiros podem configurar <em>cookies</em> nos dispositivos dos usuário que acessam nosso
                                            site.</p>
                                          <p>Estes <em>cookies</em>, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus
                                            serviços ao usuário que acessa nosso site de forma personalizada, por meio da obtenção de dados de navegação
                                            extraídos a partir de sua interação com o site.</p>
                                          <p>O usuário poderá obter mais informações sobre os <em>cookies</em> de terceiro e sobre a forma como os dados
                                            obtidos a partir dele são tratados, além de ter acesso à descrição dos <em>cookies</em> utilizados e de suas
                                            características, acessando o seguinte <em>link</em>:</p>
                                          <p>Google Analytics &#8211;
                                            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt-br</p>
                                          <p>As entidades encarregadas da coleta dos <em>cookies</em> poderão ceder as informações obtidas a terceiros.
                                          </p>
                                          <p><strong><br/>7.3. Cookies de redes sociais</strong></p>
                                          <p>O site utiliza <em>plugins</em> de redes sociais, que permitem acessá-las a partir do site. Assim, ao
                                            fazê-lo, os <em>cookies</em> utilizados por elas poderão ser armazenados no navegador do usuário.</p>
                                          <p>Cada rede social possui sua própria política de privacidade e de proteção de dados pessoais, sendo as pessoas
                                            físicas ou jurídicas que as mantêm responsáveis pelos dados coletados e pelas práticas de privacidade
                                            adotadas.</p>
                                          <p>O usuário pode pesquisar, junto às redes sociais, informações sobre como seus dados pessoais são tratados. A
                                            título informativo, disponibilizamos os seguintes <em>links</em>, a partir dos quais poderão ser consultadas
                                            as políticas de privacidade e de <em>cookies</em> adotadas por algumas das principais redes sociais:</p>
                                          <p>Facebook: https://www.facebook.com/policies/cookies/</p>
                                          <p>Twitter: https://twitter.com/pt/privacy</p>
                                          <p>Instagram: https://help.instagram.com/1896641480634370?ref=ig</p>
                                          <p>Youtube: https://policies.google.com/privacy?hl=pt-BR&amp;gl=pt</p>
                                          <p>Google+: https://policies.google.com/technologies/cookies?hl=pt</p>
                                          <p>Pinterest: https://policy.pinterest.com/pt-br/privacy-policy</p>
                                          <p>LinkedIn: https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies</p>
                                          <p><strong><br/>7.4. Gestão dos cookies e configurações do navegador</strong></p>
                                          <p>O usuário poderá se opor ao registro de <em>cookies</em> pelo site, bastando que desative esta opção no seu
                                            próprio navegador ou aparelho.</p>
                                          <p>A desativação dos <em>cookies</em>, no entanto, pode afetar a disponibilidade de algumas ferramentas e
                                            funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é
                                            remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.</p>
                                          <p>A seguir, são disponibilizados alguns links para as páginas de ajuda e suporte dos navegadores mais
                                            utilizados, que poderão ser acessadas pelo usuário interessado em obter mais informações sobre a gestão de
                                            <em>cookies</em> em seu navegador:</p>
                                          <p>Internet Explorer: <br/>https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
                                          </p>
                                          <p>Safari: <br/>https://support.apple.com/pt-br/guide/safari/sfri11471/mac</p>
                                          <p>Google Chrome: <br/>https://support.google.com/chrome/answer/95647?hl=pt-BR&amp;hlrm=pt</p>
                                          <p>Mozila Firefox: <br/>https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</p>
                                          <p>Opera: <br/>https://www.opera.com/help/tutorials/security/privacy/</p>
                                          <p><strong><br/><br/></strong><strong>VIII. Reclamação a uma autoridade de controle</strong></p>
                                          <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados têm
                                            direito a apresentar reclamação a uma autoridade de controle. A reclamação poderá ser feita à autoridade da
                                            sede do site, do país de residência habitual do usuário, do seu local de trabalho ou do local onde foi
                                            alegadamente praticada a infração.</p>
                                          <p><strong><br/><br/>IX. Das alterações</strong></p>
                                          <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 21/04/2020.</p>
                                          <p>O editor se reserva o direito de modificar, a qualquer momento e sem qualquer aviso prévio, o site as
                                            presentes normas, especialmente para adaptá-las às evoluções do site <strong>SCHOOLADVISOR</strong>, seja
                                            pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
                                          </p>
                                          <p>Dessa forma, convida-se o usuário a consultar periodicamente esta página para verificar as atualizações.</p>
                                          <p>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas.
                                            Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e
                                            apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>
                                          <p><strong><br/><br/></strong><strong>X. Do Direito aplicável e do foro</strong></p>
                                          <p>Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito
                                            brasileiro.</p>
                                          <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do
                                            site.</p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div><!-- .entry-content -->
              </article><!-- #post-## -->

            </div>
            <!-- end list-single-main-wrapper -->
          </div>
          <!-- end display-posts col-md-8 -->


        </div>
        <!-- end row -->
      </div>
      <!-- end container -->

    </section>
    <!-- section end -->


  </div>
  <!-- Content end -->
</div>
<!-- wrapper end -->
