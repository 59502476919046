<div>
  <mat-tab-group>
    <mat-tab label="Entrar">
      <app-sign-in></app-sign-in>
    </mat-tab>
    <mat-tab label="Registrar">
      <app-sign-up></app-sign-up>
    </mat-tab>
  </mat-tab-group>
</div>
