<div *ngIf="text!=null || editing" class="content position-relative text-bound w-100" [class.editing]="editing" #textDiv>
  <ng-template [ngIf]="!editing">
  {{text ? text + '\n\n' : ''}}
  <a href="javascript:;"
     class="text-more-less position-absolute bottom-0 end-0 badge rounded-pill"
     *ngIf="this.isOverFlow(textDiv) || !this.collapsed"
     (click)="click(textDiv)">
    {{(textDiv.classList.contains('text-full')) ? 'ver menos -' : 'ver mais +'}}
  </a>
  </ng-template>
  <ng-template [ngIf]="editing">
    <mat-form-field style="width: 100%">
      <!--<mat-label>Leave a comment</mat-label>-->
      <textarea [(ngModel)]="text" matInput rows="5" (change)="textChange.emit(this.text)"></textarea>
    </mat-form-field>
  </ng-template>
</div>
