<div class="" [style.display]="((escolaList | async)?.length > 0)? 'block': 'none'">
  <section style="padding-top: 0px;">
    <div class="row row-map">
      <!-- LIST -->
      <section class="list-schools col-xl-7 col-lg-7 col-md-7 col-sm-12" style="width: 100%">
        <div class="content">
          <section class="feature list" style="padding-top: 0px;">
            <div>
              <div class="row">
                <app-escola-card class="col-xl-4 col-lg-4 col-md-6 col-sm-12" [escola]="escola"
                                 *ngFor="let escola of escolaList | async; let i = index"
                                 (mouseenter)="this.mouseEnterEscola.emit({escola: escola, index: i})"
                                 (mouseleave)="this.mouseLeaveEscola.emit({escola: escola, index: i})">
                </app-escola-card>
              </div>
              <mat-paginator
                  [length]="escolaCount"
                  [pageSize]="pageSize"
                  (page)="updatePaginator($event)"
                  style="position: sticky; bottom: 0; z-index: 10;"
                  [style]="{'display': showPaginator? '' : 'none'}"
                  [matTooltipDisabled]="!userService.master"
                  [matTooltip]="(escolaCount | number:'1.0-0':'pt-BR')  + ' escolas filtradas'"
                  #matPaginator>
              </mat-paginator>
            </div>
          </section>
        </div>
      </section>

    </div>
  </section>

  <!--              <app-escola-result-->
  <!--                (mouseenter)="this.mouseEnterEscola.emit(escola)"-->
  <!--                (mouseleave)="this.mouseLeaveEscola.emit(escola)"-->
  <!--                [escola]="escola">-->
  <!--              </app-escola-result>-->


</div>
<div class="not-found" *ngIf="filterMessage != null">
  {{this.filterMessage}}
  <!--<mat-icon>search_off</mat-icon>-->
  <!--<mat-progress-bar class="row" mode="query" *ngIf="searchingEscola"></mat-progress-bar>-->
</div>
