import {EventEmitter} from '@angular/core';
import {Component, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from '@angular/material/checkbox';

@Component({
  selector: 'app-tri-state-checkbox',
  templateUrl: './tri-state-checkbox.component.html',
  styleUrls: ['./tri-state-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TriStateCheckboxComponent),
      multi: true,
    },
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions},
  ],
})
export class TriStateCheckboxComponent { // implements ControlValueAccessor {

  @Input() tripleValueList = [null, true, false];
  @Output() valueChange = new EventEmitter<boolean | null>();
  @Input() value: any;
  disabled: boolean;

  // private onChange: (val: boolean) => void;
  // private onTouched: () => void;

  // writeValue(value: any): void {
  //   this.value = value;
  // }

  // setDisabledState(disabled: boolean): void {
  //   this.disabled = disabled;
  // }

  next(): void {
    // this.onChange(this.value = this.tripleValueList[(this.tripleValueList.indexOf(this.value) + 1) % this.tripleValueList.length]);
    // this.onTouched();
    this.value = this.tripleValueList[(this.tripleValueList.indexOf(this.value) + 1) % this.tripleValueList.length]
    this.valueChange.emit(this.value);
  }

  // registerOnChange(fn: any): void {
  //   this.onChange = fn;
  // }
  //
  // registerOnTouched(fn: any): void {
  //   this.onTouched = fn;
  // }

}
