import {ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {Escola, EscolaLista} from '../service/Escola';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {EscolaService, orderTypeEnum} from '../service/escola.service';
import {LabelType, Options} from '@angular-slider/ngx-slider';
import {MatSelectionList} from '@angular/material/list';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {debounceTime, share, takeUntil, tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import firebase from 'firebase/app';
import {EscolaListVerticalComponent} from '../layout/escola-list-vertical/escola-list-vertical.component';
import * as $ from 'jquery';
import * as LZString from 'lz-string';
import {EscolaFilterAllComponent} from '../layout/escola-filter-all/escola-filter-all.component';
import {UserService} from '../service/user.service';
import {FormControl} from '@angular/forms';
import {getTag, Tag, Tags} from '../service/Tag';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {CellepUnidade, cod_parceiro} from '../service/cellep';
import {
  checkSvg,
  ICON_DEFAULT,
  ICON_OVER,
  ICON_SELECTED,
  pinCellep,
  pinDisabled,
  pinSchool,
  pinSchoolCellep, pinSchoolSelectd
} from '../service/Map';
import {AnalyticsService} from '../service/analytics.service';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import LatLng = google.maps.LatLng;
import Marker = google.maps.Marker;
import MapOptions = google.maps.MapOptions;
import Autocomplete = google.maps.places.Autocomplete;
import PlaceResult = google.maps.places.PlaceResult;
import Animation = google.maps.Animation;
import {PaginatorComponent} from '../navigation/paginator/paginator.component';
import {parseAddressName} from '../layout/escola-filter-header/escola-filter-header.component';
import {Perfil} from '../layout/perfil-afinidade/perfil-afinidade.component';
import LatLngLiteral = google.maps.LatLngLiteral;
import {Subject} from 'rxjs';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements OnInit, OnDestroy {

  // @ViewChild(GoogleMap, {static: false}) map: GoogleMap;
  map: google.maps.Map;
  mapHasBounds = false;
  mapMarkers: Array<Marker> = [];
  parceiroMapMarkers: Array<Marker> = [];
  _parseAddressName = parseAddressName;
  mapOptions: MapOptions = {
    zoom: 4,
    center: {
      lat: -15.189310,
      lng: -51.173592
    },
    // zoom: 16, //São Paulo
    // center: {
    //   lat: -23.5557714,
    //   lng: -46.6395571
    // },
    styles: [{
      featureType: 'poi',
      stylers: [
        {visibility: 'off'}
      ]
    }]
  }; // center: {lat: -23.6904602, lng: -46.710106}};
  currentPosition;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  // infoWindow: google.maps.InfoWindow;
  currentMarker;
  currentInfowindow;
  autoCompleteObj: Autocomplete;
  mapInitialized = false;
  mapScrollRefresh = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('listaInput', {static: true}) listaInput: ElementRef<HTMLInputElement>;
  listControl = new FormControl();
  config: SwiperOptions = {
    direction: 'vertical',
    // slidesPerGroup: 1,
    slidesPerView: 1,
    // slidesPerColumnFill: 'row',
    centeredSlides: false,
    // slidesPerColumn: 2,
    spaceBetween: 5,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  };
  minValue = 0;
  maxValue = 0;
  options: Options = {
    floor: 0,
    ceil: 0,
    translate: (value: number, label: LabelType): string => {
      if (value === this.options.ceil) {
        return '+∞';
      }
      switch (label) {
          // case LabelType.Low:
          //   return 'Mínimo: $' + value;
          // case LabelType.High:
          //   return '+∞';
        default:
          return '$' + value;
      }
    }
  };
  @Input() escolaList: Array<Escola>;
  @ViewChild('menuNivel', {static: true}) menuNivel: MatMenu;
  @ViewChild('listNivel', {static: true}) listNivel: MatSelectionList;
  @ViewChild('listContainer', {static: true}) listContainer: HTMLDivElement;
  @ViewChild('escolaListVertical', {static: true}) escolaListVertical: EscolaListVerticalComponent;
  @ViewChild('paginatorTop', {static: true}) paginatorTop: PaginatorComponent;
  @ViewChild('matPaginator', {static: true}) matPaginator: MatPaginator;
  @ViewChild('matPaginatorFooter', {static: true}) matPaginatorFooter: MatPaginator;
  @ViewChild('nivelMenuTrigger') nivelMenuTrigger: MatMenuTrigger;
  escolaTags = Tags;
  nivelTags = this.escolaTags.filter(value => value.group === 'Nível de Ensino');
  nivelTagValue: any = {};
  mensalidadeTooltip: string = null;
  escolaCount = 0;
  PAGESIZE_USER = 15;
  PAGESIZE_MASTER = 300;
  pageSize = this.PAGESIZE_USER;
  filterMessage: string;
  lastFilterCriteria;
  lastPaginationIndex: number;
  searchingEscola = false;
  // chipChanged: Subject<any> = new Subject<any>();
  debounceTime = 2000;
  mapToggle = true;
  loading: string = null;
  loadingDialogRef: MatDialogRef<MapaLoading>;
  criarListaDialogRef: MatDialogRef<MapaCriarDiretorioPopup>;
  mensalidadesOptions = [
    {value: 1000, label: 'Até R$ 1.000,00'},
    {value: 2000, label: 'Entre R$1.001,00 Até R$ 2.000,00'},
    {value: 3000, label: 'Entre R$2.001,00 Até R$ 3.000,00'},
    {value: 4000, label: 'Entre R$3.001,00 Até R$ 4.000,00'},
    {value: 5000, label: 'Entre R$4.001,00 Até R$ 5.000,00'},
    {value: 6000, label: 'Entre R$5.001,00 Até R$ 6.000,00'},
    {value: 7000, label: 'Entre R$6.001,00 Até R$ 7.000,00'},
    {value: 8000, label: 'Entre R$7.001,00 Até R$ 8.000,00'},
    {value: -1, label: 'Acima de R$ 8.000,00'}
  ]
  sliderConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerPadding: '40px',
    draggable: true,
    centerMode: false,
    variableWidth: true,
    arrows: false,
  };
  public cellep_list: Array<CellepUnidade> = null;
  urlLink: URLSearchParams;
  searchLink: string = null;
  selectedEscola: Escola;
  selectedCellep: CellepUnidade;
  orderTypeEnum = orderTypeEnum;
  private readonly onDestroy = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              public userService: UserService,
              private bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              public escolaService: EscolaService,
              private analytics: AnalyticsService,
  ) {
    this.escolaService.filterEvent.pipe(
        tap(value => {
          if ((value === undefined) || (value.type === 'map')) {
            this.loading = 'Refinando...';
          }
        }),
        takeUntil(this.onDestroy),
        debounceTime(1000)
    ).subscribe(value => {
          // console.log('escolaService.filterEvent.pipe value: ' + JSON.stringify(value));
          if ((value === undefined) || (value.type === 'map')) {
            if (value?.reset === true) {
              this.filterData('Update Paginator', null, false, false);
            } else {
              this.filterData(`FilterEvent.type: ${JSON.stringify(value)}`);
            }
          }
        }
    );
    // //Raise an event when a value in header changes
    this.escolaService.headerFormGroup.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(value => {
          this.escolaService.filterEvent.next({type: 'map', description: 'headerFormGroup.valueChanges'});
        });
    this.userService.userDataEvent
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
      this.refreshUserLimit();
      const params = this.getFilterParamsFromURL();
      if (params != null) {
        // this.filterData('userService.userDataEvent', params);
        this.escolaService.filterEvent.next({type: 'map', description: 'userDataEvent'});
      }
    });
    this.refreshUserLimit();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    // console.log('destroyed');
  }

  ngOnInit(): void {
    this.escolaService.regiao.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(value => {
      if (value != null) {
        if (value.geometry) {
          this.goToMapPosition(value.geometry.location);
        }
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  async afterMapInit(map: google.maps.Map) {
    window.scrollTo(0, 0);
    // console.log('map init: ' + this.map);
    this.map = map;
    /**
     * bounds_changed event is here because:
     * somehow google chrome does not send the bounds (aka map.getBounds().getNorthEast().lng())
     * until it is "fully initialized".
     * And "fully initialized" is something beyond my understanding.
     * By the way, there's a mapInitialized event for that, and it still does not work.
     *
     * ... well ... fuck you chrome.
     *
     * NOTE: Firefox is better than Chrome
     */
    google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      this.mapHasBounds = true;
      console.log('map has bounds');
    });

    console.log('afterMapInit');
    if (this.userService.userData?.perfil
      && this.userService.userData?.perfil?.place) {
      const p: Perfil = this.userService.userData.perfil;
      let latLng: LatLng;
      const loc = p.place.geometry.location as any;
      if (typeof loc.lat === 'function') {
        latLng = new google.maps.LatLng(loc.lat(), loc.lng());
      } else {
        latLng = new google.maps.LatLng(loc.lat, loc.lng);
      }
      map.setCenter(latLng);
      this.map.setZoom(15);
    }

    if (this.escolaService.regiao.value != null) {
      const loc = this.escolaService.regiao.value.geometry?.location as any;
      if (loc) {
        if (typeof loc.lat === 'function') {
          const latLng: LatLng = new google.maps.LatLng(loc.lat(), loc.lng());
          this.map.panTo(latLng);
          this.map.setZoom(15);
        } else {
          const latLng: LatLng = new google.maps.LatLng(loc.lat, loc.lng);
          this.map?.panTo(latLng);
          this.map.setZoom(15);
        }
      } else {
        this.escolaService.filterEvent.next({type: 'map', description: 'escolaService.regiao.value changed'});
      }
    } else if (this.userService.userData?.perfil && this.route.snapshot.queryParamMap.get('type') === 'perfil') {
      this.escolaService.order.setValue('score');
    } else {
      await this.getCurrentPosition().then((position) => {
        if (this.escolaService.regiao.value == null) {
          if (position) {
            const latLng: LatLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            this.map.panTo(latLng);
            this.map.setZoom(15);
          } else {
            this.handleLocationError(true, this.infoWindow, this.map.getCenter());
          }
          // this.filterData('afterMapInit found GPS position', this.getFilterParamsFromURL());
          this.escolaService.filterEvent.next({type: 'map', description: 'afterMapInit found GPS position'});
        } else if (this.escolaList == null) {
        }
      }).catch(reason => {
        console.log(reason);
        // if (this.escolaList == null) {
        this.filterData('afterMapInit Exception/Error', this.getFilterParamsFromURL());
        // }
      });
    }
    this.escolaService.filterEvent.next({type: 'map', description: 'map initialized'});
    this.mapInitialized = true;
  }

  markForFilter(): void {
    this.escolaService.filterEvent.next({type: 'map', description: 'markForFilter'});
  }

  resetNivelEnsinoFilter(): void {
    const valueArray = [];
    this.escolaService.headerFormGroup.patchValue({nivel_ensino: valueArray}, {onlySelf: true, emitEvent: true});
  }

  private initMap(): boolean {
    const htmlElement = document.getElementById('gmap');

    if (htmlElement != null) {

      // JS API is loaded and available
      // MapaComponent.map = new google.maps.Map(htmlElement, {
      //   mapTypeControlOptions: {
      //     mapTypeIds: []
      //   },
      //   mapTypeId: MapTypeId.ROADMAP,
      //   disableDefaultUI: true,
      //   zoomControlOptions: {
      //     style: ZoomControlStyle.LARGE
      //   },
      //   styles: [{
      //     featureType: 'poi.business',
      //     stylers: [{visibility: 'off'}],
      //   }],
      //   zoomControl: true,
      //   mapTypeControl: true,
      //   // center: startPosition == null ? {lat: -14.235004, lng: -51.92528} : startPosition,
      //   zoom: 16,
      // });

      // (window as any)._map = MapaComponent.map;

      // Click to go on map
      // google.maps.event.addListener(MapaComponent.map, 'click', event => {
      //   // console.log("clickToGo:" + mapClickToGo);
      //   if (MapaComponent.mapClickToGo) {
      //     this.goToMapPosition(event.latLng);
      //     MapaComponent.mapClickToGo = false;
      //   }
      // });

      // Refresh on drag
      google.maps.event.addListener(this.map, 'dragend', event => {
        this.mapDragEndEvent();
      });

      // google.maps.event.addListener(MapaComponent.map, 'idle', event => {
      //   // console.log('dragEnd: ' + MapaComponent.map.getCenter().lat() + ':' + MapaComponent.map.getCenter().lng());
      //   window.setTimeout(() => {
      //     this.filterData();
      //   }, 3000);
      // });

      // // Create a <script> tag and set the USGS URL as the source.
      const script: HTMLScriptElement = document.createElement('script');
      script.defer = false;
      document.getElementsByTagName('head')[0].appendChild(script);

      let center = this.map.getCenter();
      // console.log(`Center is: ${JSON.stringify(center)}`);
      if (center === undefined) {
        center = new google.maps.LatLng(0, 0);
      }

      if (this.escolaService.regiao.value != null) {
        this.goToMapPosition(this.escolaService.regiao.value.geometry.location);
      }

      return true;
    } else {
      console.log('Map Element not found!');
    }
    return false;
  }

  mapDragEndEvent(): void {
    if (this.mapInitialized && this.mapScrollRefresh) {
      // console.log('dragEnd: ' + MapaComponent.map.getCenter().lat() + ':' + MapaComponent.map.getCenter().lng());
      if (this.escolaService.regiao.value !== null && this.escolaService.regiao.value !== '') {
        this.escolaService.regiao.setValue(null);
      }
      const escolaNome = this.escolaService.nome.value;
      if (typeof escolaNome === 'object' && escolaNome != null && escolaNome !== '') {
        // console.log(`escolaNome: ${JSON.stringify(escolaNome)}`);
        this.escolaService.nome.setValue('');
      }
      console.log('');
      this.escolaService.filterEvent.next({type: 'map', description: 'mapDragEndEvent'});
    }
  }

  private getFilterParamsFromURL(): any {
    const params = new URL(location.href).searchParams;
    let filterData = null;
    if (params.has('filter')) {
      const compressed = params.get('filter');
      const decompressed = LZString.decompressFromEncodedURIComponent(compressed);
      filterData = JSON.parse(decompressed);
      // console.log(`filter: ${compressed} -> ${decompressed}`);

      // TODO: change all names to match between filter and form
      if (filterData.regiao) {
        this.escolaService.regiao.setValue(filterData.regiao);
      }
      if (filterData.nivel_ensino) {
        this.escolaService.nivelEnsino.setValue(filterData.nivel_ensino);
      }
      if (filterData.not_nivel_ensino) {
        this.escolaService.notNivelEnsino.setValue(filterData.not_nivel_ensino);
      }
      // if (filterData.nome) {
      //   this.escolaService.nome.setValue(filterData.nome);
      // }
      if (filterData.mensalidade_max) {
        this.escolaService.mensalidadeMax.setValue(filterData.mensalidade_max);
      }
      if (filterData.distancia_max) {
        this.escolaService.distanciaMax.setValue(filterData.distancia_max);
      }
      if (filterData.rate_geral) {
        this.escolaService.rateGeral.setValue(filterData.rate_geral);
      }
      if (filterData.indices_total_alunos_max) {
        this.escolaService.indicesTotalAlunosMax.setValue(filterData.indices_total_alunos_max);
      }
      if (filterData.abordagemPedagogica) {
        this.escolaService.abordagemPedagogica.setValue(filterData.abordagemPedagogica);
      }
      if (filterData.filter) {
        this.escolaService.filter.setValue(filterData.filter);
      }
    }
    return filterData;
  }

  private refreshUserLimit(): void {
    if (this.userService.master) {
      this.pageSize = this.PAGESIZE_MASTER;
    } else {
      this.pageSize = this.PAGESIZE_USER;
    }
  }

  goToMapPosition(latLng: LatLng): void {
    this.map.panTo(latLng);
    this.loadPlaceName(latLng, false);
    if (this.mapScrollRefresh) {
      this.escolaService.filterEvent.next({type: 'map', description: 'goToMapPosition'});
    }
  }

  getCurrentPosition(): Promise<any> {
    if (navigator.geolocation) {
      // Try HTML5 geolocation
      return new Promise<any>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    } else {
      return new Promise<any>(resolve => resolve({}));
    }
  }

  loadPlaceName(latlng, forceLoad: boolean): void {
    // console.log('loadPlaceName: ' + JSON.stringify(latlng));
    const geocoder = new google.maps.Geocoder();
    // This is making the Geocode request
    geocoder.geocode({location: latlng}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // const address = (results[0].formatted_address);
        // This is placing the returned address in the 'Address' field on the HTML form
        // document.getElementById('autocomplete').value = results[0].formatted_address;
        // this.searchForm.getItem('address').setValue(results[0].formatted_address);
        // console.log('address: ' + address);
        this.map.setCenter(latlng);
        // this.map.setZoom(14);

        if (forceLoad) {
          // this.filterData('loadPlaceName (forceLoad)', this.getFilterParamsFromURL());
          this.escolaService.filterEvent.next({type: 'map', description: 'loadPlaceName (forceLoad)'});
        }
      }
    });
  }

  clearMap(): void {
    // Clear all markers from the map (or is it the other way around?)
    this.mapMarkers.forEach(item => {
      item.setMap(null);
      // console.log("clear marker: " + i + "." + markers[i]);
    });
    // Deletes all markers in the array by removing references to them.
    this.mapMarkers = [];
  }

  addMarker(escola: Escola): Marker {
    const latLng = new google.maps.LatLng(escola.latitude, escola.longitude);
    let pin;
    if (escola.data_desativada != null) {
      pin = pinDisabled;
    } else if (escola.cellep != null) {
      pin = pinSchoolCellep;
    } else {
      pin = pinSchool;
    }

    const marker = new google.maps.Marker({
      title: escola.nome,
      position: latLng,
      icon: pin,
      // icon: MapaComponent.ICON_DEFAULT,
      // icon: {
      //   url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
      // },
      // animation: google.maps.Animation.DROP,
    });
    escola.marker = marker;

    let content = '   <article class="info-card-slide">'
        // '     <div class="info-card-slide-image">' +
        // `      <img src="${(escola.logo_escola == null) ? 'images/thumb-list.jpg' : escola.logo_escola}" alt="Escola"/>` +
        // '     </div>' +
        + `    <a href="/escolas/${escola.codigo}/${escola.nome}" target="_blank" title="veja mais" class="veja-mais">`;
    if (escola.cellep != null) {
      content += `    <img src="images/cellep/logo.svg" alt="Escola Parceira" class="w-100" style="max-width: 100px">`
          + '<h3 style="font-size: 12px; color: #ef3f5d; margin-bottom: 10px;" class="escola-parceira">Escola Parceira</h3>';
    }
    content += `     <h3 class="info-escola-nome">${escola.nome}</h3>`
        + `     <p class="info-escola-endereco">${escola.endereco}</p>`
        + '    </a>'
        + '   </article>';

    const infowindow = new google.maps.InfoWindow({
      // content: '<div id=\'map_infowindow\'> ' + escola.nome + '<br>' + escola.endereco + '<br>' + (escola.nivel_ensino ?? '') + '</div>'
      content
    });

    marker.addListener('click', () => {
      if (this.currentInfowindow != null) {
        this.currentInfowindow.close();
      }
      this.currentInfowindow = infowindow;
      // infowindow.open(this.map, marker);

      if (this.currentMarker != null) {
        this.currentMarker.setAnimation(null);
      }
      this.currentMarker = marker;

      if (marker.getAnimation() != null) {
        marker.setAnimation(null);
        // tileGrid.selectRecord(escola, false);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        // tileGrid.selectRecord(escola, true);
      }
    });
    marker.setIcon(pinSchool);
    // marker.addListener('mouseover', () => {
    //   marker.setIcon(MapaComponent.ICON_OVER);
    // });
    // marker.addListener('mouseout', () => {
    //   marker.setIcon(MapaComponent.ICON_DEFAULT);
    // });

    this.mapMarkers.push(marker);
    return marker;
  }

  addParceiroMakers(): void {
    this.parceiroMapMarkers = [];
    for (const item of this.cellep_list) {
      const latLng = new google.maps.LatLng(item.latitude, item.longitude);
      const pin = pinCellep;
      const marker = new google.maps.Marker({
        title: 'Cel.Lep ' + item.nome,
        position: latLng,
        map: this.map,
        icon: pin,
      });
      item.marker = marker;
      // const infowindow = new google.maps.InfoWindow({
      //   content:
      //       `   <article class="info-card-slide d-flex flex-column align-items-center justify-content-center"> ` +
      //       `    <a href="/cellep/#${item.nome}" title="veja mais" target="_blank" class="veja-mais">` +
      //       `    <img src="images/cellep/logo.svg" alt="Escola Parceira" class="w-100" style="max-width: 100px">` +
      //       `     <h3 class="info-escola-nome">${item.nome}</h3>` +
      //       `     <p class="info-escola-endereco">${item.endereco}</p>` +
      //       '    </a>' +
      //       '   </article>'
      // });
      // marker.addListener('click', () => {
      //   if (this.currentInfowindow != null) {
      //     this.currentInfowindow.close();
      //   }
      //   this.currentInfowindow = infowindow;
      //   infowindow.open(this.map.googleMap, marker);
      //
      //   if (this.currentMarker != null) {
      //     this.currentMarker.setAnimation(null);
      //   }
      //   this.currentMarker = marker;
      //
      //   if (marker.getAnimation() != null) {
      //     marker.setAnimation(null);
      //     // tileGrid.selectRecord(escola, false);
      //   } else {
      //     marker.setAnimation(google.maps.Animation.BOUNCE);
      //     // tileGrid.selectRecord(escola, true);
      //   }
      // });
      this.parceiroMapMarkers.push(marker);
    }
  }

  fetchParceiroUnidade(): void {
    const data: any = {
      cod_parceiro
    };
    // const bounds = this.map.getBounds();
    // if (bounds) {
    //   data.bounds = {
    //     north: bounds.getNorthEast().lat(),
    //     east: bounds.getNorthEast().lng(),
    //     south: bounds.getSouthWest().lat(),
    //     west: bounds.getSouthWest().lng(),
    //   };
    // }
    const center = this.map.getCenter();
    if (center) {
      data.lat = center.lat();
      data.lng = center.lng();
    }
    firebase.functions().httpsCallable('parceiro_unidade')(data).then(value => {
      if (value != null) {
        this.cellep_list = value.data;
      } else {
        this.cellep_list = null;
      }
      this.addParceiroMakers();
    });
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos): void {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
        'Error: The Geolocation service failed.' :
        'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(this.map);
  }

  setPlace(place: PlaceResult): void {
    if (this.currentPosition != null) {
      this.currentPosition.setMap(null);
    }

    if (place && place.geometry) {
      const pos = place.geometry.location;

      if (this.map) {
        this.map.setZoom(15);
        this.map.panTo(pos);
      }
    }
  }

  updatePaginator(): void {
    // this.filterData('Update Paginator', null, false, false);
    console.log('UpdatePaginator');
    this.escolaService.filterEvent.next({type: 'map', reset: true});
  }

  async filterData(reason: string, data: any = null, fitBounds: boolean = false, resetPage = true) {
    console.log('reason:' + reason);
    console.log(JSON.stringify(this.mapOptions));

    this.escolaService.place.setValue(null); // place = nome da escola

    if (this.loading === 'Pesquisando...') {
      return;
    }
    // this.escolaService.place.setValue(null);

    if (data == null) {
      data = {
        totalRows: true
      };
      // console.log(`starting filter without parameters`);
      // if (this.escolaService.nome.value != null) {
      //   const eValue = this.escolaService.nome.value;
      //   if (typeof eValue === 'string') {
      //     data.nome = this.escolaService.nome.value;
      //   } else { // TODO: remove this block all values will be strings from now on 2021-05-08
      //     const escola: Escola = this.escolaService.nome.value;
      //     if (escola.latitude && escola.longitude) {
      //       const latlng: LatLng = new google.maps.LatLng(escola.latitude, escola.longitude);
      //       this.map.panTo(latlng);
      //     }
      //   }
      // }

      // if (this.escolaService.regiao.value != null) {
      //   const v = this.escolaService.regiao.value;
      //   if (v.geometry) {
      //     if (typeof v.geometry.location.lat === 'function') {
      //       data.lat = v.geometry.location.lat();
      //       data.lng = v.geometry.location.lng();
      //     }else {
      //       data.lat = v.geometry.location.lat;
      //       data.lng = v.geometry.location.lng;
      //     }
      //   }
      // } else
      if (this.escolaService.filterOption.value !== 'listaMap') {

        // const bounds = this.map.getBounds();
        const center = this.map.getCenter();
        if (center) {
          data.lat = center.lat();
          data.lng = center.lng();
        }
      }

      if (this.escolaService.nivelEnsino.value?.length > 0) {
        data.nivel_ensino = this.escolaService.nivelEnsino.value;
      }

      if (this.escolaService.notNivelEnsino.value?.length > 0) {
        data.not_nivel_ensino = this.escolaService.notNivelEnsino.value;
      }

      if (this.escolaService.mensalidadeMin.value > 0) {
        data.mensalidade_min = this.escolaService.mensalidadeMin.value;
      }

      if (this.escolaService.mensalidadeMax.value > 0) {
        data.mensalidade_max = this.escolaService.mensalidadeMax.value;
      }

      if (this.escolaService.distanciaMax.value > 0) {
        data.distancia_max = this.escolaService.distanciaMax.value;
      }

      // if (this.mensalidadeSlider.highValue !== this.mensalidadeSlider.options.ceil) {
      //   data.mensalidade_max = this.mensalidadeSlider.highValue;
      // }

      if (this.escolaService.indicesTotalAlunosMax.value > 0) {
        data.indices_total_alunos_max = this.escolaService.indicesTotalAlunosMax.value;
      }

      if (this.escolaService.abordagemPedagogica.value?.length > 0) {
        data.abordagem_pedagogica = this.escolaService.abordagemPedagogica.value;
      }

      if (this.escolaService.rateGeral.value > 0) {
        data.rate_geral = this.escolaService.rateGeral.value;
      }

      if (this.escolaService.cellep.value?.length > 0) {
        data.cellep = true;
      }

      if (this.escolaService.oebi.value?.length > 0) {
        data.oebi = true;
      }

      if (this.escolaService.geekie.value?.length > 0) {
        data.geekie = true;
      }

      if (this.escolaService.poliedro.value?.length > 0) {
        data.poliedro = true;
      }

      if (this.escolaService.clima.value?.length > 0) {
        data.clima = true;
      }

      if (this.escolaService.supercerebro.value?.length > 0) {
        data.supercerebro = true;
      }

      if (this.escolaService.filter.value?.length > 0) {
        data.filter = (this.escolaService.filter.value as Array<Tag>).filter(value => {
          return !(value.localOnly === true);
        });
      }

      if (this.escolaService.listas.value?.length > 0) {
        data.listas = this.escolaService.listas.value;
      }

      if (this.userService.userData?.perfil) {
        data.perfil = this.userService.userData.perfil;
      }

      if (this.escolaService.order.value) {
        data.order = this.escolaService.order.value;
      }

      data.offset = (this.escolaListVertical.matPaginator == null) ?
          0 :
          this.escolaListVertical.matPaginator.pageIndex * this.escolaListVertical.matPaginator.pageSize;
      data.limit = this.pageSize;

      if (this.map != null && this.mapHasBounds) {
        const bound = {
          north: this.map.getBounds().getNorthEast().lng(),
          south: this.map.getBounds().getSouthWest().lng(),
          east: this.map.getBounds().getNorthEast().lat(),
          west: this.map.getBounds().getSouthWest().lat(),
        };
        data.bound = bound;
      }

    } else {
      // console.log(`starting filter with parameters: ${JSON.stringify(data)}`);
    }

    data.fields = 'map'; // Return less fields (only map fields)

    if (resetPage) {
      this.escolaListVertical.matPaginator.pageIndex = 0;
      data.offset = 0;
    }

    this.lastPaginationIndex = (this.escolaListVertical.matPaginator == null) ? 0 : this.escolaListVertical.matPaginator.pageIndex;
    this.lastFilterCriteria = data; // Object.assign({}, criteria); // Clone it

    this.filterMessage = 'Procurando escolas...';
    this.searchingEscola = true;
    // console.log('Perform Fetch [Escola]');

    this.loading = 'Pesquisando...';
    // this.map.options.gestureHandling = 'none';
    // this.loadingDialogRef = this.dialog.open(MapaLoading);

    this.analytics.buscaEscola(data);
    await firebase.functions().httpsCallable('escola')(data).then(value => {
      this.escolaService.updateImageSet(value.data.rows);
      this.escolaList = value.data.rows;
      // console.log(`escolas: ${JSON.stringify(value.data)}`);
      this.clearMap();

      // this.paginatorTop.offset = data.offset;
      // this.paginatorTop.limit = this.pageSize;
      // this.paginatorTop.total = value.data.totalRows;

      const markerBounds = new google.maps.LatLngBounds();
      let mensalidadeMin = 0;
      let mensalidadeMax = 1;
      const markerArray: Marker[] = [];
      // this.mapMarkerPositions = [];
      for (const escola of value.data.rows) {
        // console.log('parsing escola: ' + escola.nome);
        // markerArray.push(this.addMarker(escola));
        // const latLng = new google.maps.LatLng(escola.latitude, escola.longitude);
        // console.log('latLng: ' + latLng);
        const la: number = +escola.latitude;
        const lo: number = +escola.longitude;
        const latLng = new google.maps.LatLng(la, lo);
        markerBounds.extend(latLng);
        const m = new Marker({
          position: {lat: la, lng: lo}
        });
        m.setPosition({lat: la, lng: lo});
        m.setIcon(pinSchool);
        this.mapMarkers.push(m);
        // this.mapMarkerPositions.push({lat: la, lng: lo});
        // this.map.center = {lat: 24, lng: 12};

        mensalidadeMin = Math.min(mensalidadeMin, escola.mensalidade ?? 0);
        mensalidadeMax = Math.max(mensalidadeMax, escola.mensalidade ?? 0);
      }

      const format = {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'};
      this.mensalidadeTooltip =
          'De ' + mensalidadeMin.toLocaleString('pt-BR', format)
          + ' até ' + mensalidadeMax.toLocaleString('pt-BR', format);

      mensalidadeMax = Math.ceil(mensalidadeMax / 1000) * 1000;

      // Due to change detection rules in Angular, we need to re-create the options object to apply the change
      const newOptions: Options = Object.assign({}, this.options);
      newOptions.floor = 0;
      newOptions.ceil = mensalidadeMax;
      this.options = newOptions;
      // this.mensalidadeSlider.value = mensalidadeMin;
      // this.mensalidadeSlider.highValue = mensalidadeMax;
      this.escolaCount = value.data.totalRows;
      // console.log('mensalidade min: ' + mensalidadeMin + ' max: ' + mensalidadeMax);

      if (value.data.rows.length > 0) {

        window.scrollTo({top: 0});
        document.getElementById('listContainer').scrollTop = 0;

        this.filterMessage = null;

        // Always fit to bounds
        // fitBounds = true;
        // if ((this.escolaService.filterOption.value === 'listaMap') || (this.map.getCenter() === undefined)) {
        //   fitBounds = true;
        // }

        // console.log(`markerBounds: ${markerBounds}`);
        // Finally we can call the Map.fitBounds() method to set the map to fit our markerBounds
        if (fitBounds) {
          // console.log('fitbounds: ' + JSON.stringify(markerBounds));
          const page = this.escolaListVertical.matPaginator.pageIndex;
          if (page > 0) {
            let contained = true;
            const center = this.map.getCenter();
            this.map.fitBounds(markerBounds);
            this.map.setCenter(center);
            const newbounds = this.map.getBounds();
            for (const markItem of markerArray) {
              if (!newbounds.contains(markItem.getPosition())) {
                contained = false;
              }
            }
            if (!contained) {
              this.map.setZoom(this.map.getZoom() - 1);
            }
          } else {
            // console.log('not fitbounds: ' + JSON.stringify(markerBounds));
            this.map.fitBounds(markerBounds, 0);
          }
        }
        // bottomLayout.animateResize(bottomLayout.getWidth(), 150, () => {
        //   hideFilterPanel();
        //   compareButton.setVisibility('visible');
        // }, 500, 'none');
        // this.escolaList = data;
        // if (dsResponse.message) {
        //   isc.notify(dsResponse.message);
        // }

        // this.datamessage = (dsResponse.startRow + 1) + '–' + dsResponse.endRow + ' de ' + dsResponse.totalRows + ' Escolas.';
      } else {
        this.escolaList = null;
        // this.datamessage = null;
        // isc.notify('Nenhuma escola foi encontrada.');
        this.filterMessage = 'Nenhuma escola foi encontrada.';
        // bottomLayout.animateResize(bottomLayout.getWidth(), 0, () => {
        //   hideFilterPanel();
        //   compareButton.setVisibility('hidden');
        // }, 500, 'none');
      }
      this.searchingEscola = false;

      // if (this.escolaService.filterOption.value === 'listaMap') {
      //   this.escolaService.filterOption.setValue(null, {onlySelf: true, emitEvent: false});
      // }

      // if (searchForm.getValue('groupMarker')) {
      //   markerCluster = new MarkerClusterer(map, markers,
      //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
      // }
      this.loading = null;
      // this.loadingDialogRef.close();
      this.changeDetectorRef.detectChanges();

      // TODO: pegar a lista dos parceiros do cellep
      // if (this.cellep_list == null)
      //   this.fetchParceiroUnidade();

      setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 1000);

      // this.map.options.gestureHandling = 'auto';
      this.analytics.resultadoBuscaEscola(value.data.rows);
    });

    this.urlLink = new URLSearchParams();
    this.urlLink.set('filter', LZString.compressToEncodedURIComponent(JSON.stringify(data)));
    // console.log(this.urlLink.toString());
  }

  bounce(escola: Escola, index: number, enter: boolean = true): void {
    if (enter) {
      // escola.marker.setAnimation(google.maps.Animation.BOUNCE);
      this.mapMarkers[index].setAnimation(google.maps.Animation.BOUNCE);
      this.mapMarkers[index].setIcon(pinSchoolSelectd);
    } else {
      // escola.marker.setAnimation(null);
      this.mapMarkers[index].setAnimation(null);
      this.mapMarkers[index].setIcon(pinSchool);
    }

    // Force favorito over any other option
    if (escola.favorito) {
      escola.marker.setIcon(pinSchool);
    }
  }

  openInfoWindow(m: MapMarker, index: number): void {
    // console.log('openInfoWindow.MapMarker: ' + m);
    // console.log('index: ' + index);
    this.selectedEscola = this.escolaList[index];
    this.selectedCellep = null;
    // console.log('Selected Escola: ' + JSON.stringify(this.selectedEscola));
    this.infoWindow.open(m);
  }

  openMarkerWindow(m: MapMarker, index: number): void {
    // console.log('openMarkerWindow.MapMarker: ' + m);
    // console.log('index: ' + index);
    this.selectedEscola = null;
    this.selectedCellep = this.cellep_list[index];
    // console.log('Selected Escola: ' + JSON.stringify(this.selectedEscola));
    this.infoWindow.open(m);
  }

  getRadius(map: google.maps.Map): any {
    const bounds = map.getBounds();
    const center = map.getCenter();
    if (bounds && center) {
      const ne = bounds.getNorthEast();
      // Calculate radius (in meters).
      const radius = google.maps.geometry.spherical.computeDistanceBetween(center, ne);
      // console.log('calc radius: ' + radius);
      // 1000 for meters to km, and 0.9 for floor
      return (radius / 1000) * 0.9;
    }
  }

  allFilterClick(): void {
    const dialogRef = this.dialog.open(EscolaFilterAllComponent);
    let valueChangeFlag = false;
    const subscription = this.escolaService.filterFormGroup.valueChanges.subscribe(() => {
      valueChangeFlag = true;
    });

    dialogRef.afterClosed().subscribe(() => {
      subscription.unsubscribe();
      if (valueChangeFlag) {
        this.escolaService.filterEvent.next({type: 'map', description: 'allFilterClick'});
      }
    });
  }

  showMap(): void {
    this.mapToggle = true;
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  showList(): void {
    this.mapToggle = false;
  }

  private _getTag(name: string): Tag {
    const tag = getTag(name);
    return tag;
  }

  remove(item: string): void {

    const itemGroup = this._getTag(item).group;
    let formControl: FormControl;
    switch (itemGroup) {
      case 'Nível de Ensino':
        formControl = this.escolaService.nivelEnsino;
        break;
      default:
        formControl = this.escolaService.filter;
        break;
    }
    const currentValues: string[] = formControl.value;
    if (currentValues == null) {
      return;
    }
    const index = currentValues.indexOf(item);
    if (index >= 0) {
      currentValues.splice(index, 1);
      formControl.setValue(currentValues);
    }

    // this.chipChanged.next();
  }

  // newPlace(place: google.maps.places.PlaceResult): void {
  //   this.escolaService.regiao.setValue(place, {onlySelf: true});
  //   this.map.panTo(place.geometry.location);
  //   // this.matMenuTrigger.closeMenu();
  // }

  clickCellep(): void {
    const value = this.escolaService.cellep.value;
    if (value?.length > 0) {
      this.escolaService.cellep.setValue([]);
    } else {
      this.escolaService.cellep.setValue(['cellep']);
    }
    // this.filterData('clickCellep');
    this.escolaService.filterEvent.next({type: 'map', description: 'clickCellep'});
  }

  generateLink(): void {
    this.searchLink = window.location.href.split('?')[0] + '?' + this.urlLink.toString();
  }

  setNivel(tag: Tag, value: boolean | null): void {
    const name = tag.name;
    const indexCheck = this.escolaService.nivelEnsino.value?.indexOf(name);
    const indexNot = this.escolaService.notNivelEnsino.value?.indexOf(name);

    if (indexCheck >= 0) {
      this.escolaService.nivelEnsino.value.splice(indexCheck, 1);
    }
    if (indexNot >= 0) {
      this.escolaService.notNivelEnsino.value.splice(indexNot, 1);
    }

    if (value === false) {
      this.escolaService.nivelEnsino.value.push(name);
      this.escolaService.notNivelEnsino.value.push(name);
    } else if (value === true) {
      this.escolaService.nivelEnsino.value.push(name);
    }
  }

  refreshNivelTagValue(): void {
    this.nivelTags.forEach((v) => {
      this.nivelTagValue[v.title] = null;
      if (this.escolaService.nivelEnsino.value.indexOf(v.title) >= 0) {
        if (this.escolaService.notNivelEnsino.value.indexOf(v.title) >= 0) {
          this.nivelTagValue[v.title] = false;
        } else {
          this.nivelTagValue[v.title] = true;
        }
      }
    });
  }

  createList(): void {
    const escola_lista: EscolaLista = {nome_lista: '', ativa: true, prioridade: 100, escola_lista: []};
    this.escolaList.forEach(value1 => {
      const e: Escola = {...value1};
      e.marker = null;
      escola_lista.escola_lista.push(e);
    });
    this.criarListaDialogRef = this.dialog.open(MapaCriarDiretorioPopup, {
      data: {nome: '', limit: this.pageSize, count: this.escolaCount, escola_lista}
    });
  }

  removeNivelEnsinoFilter(nivel: any) {
    const values: string[] = this.escolaService.nivelEnsino.value;
    const i = values.indexOf(nivel);
    values.splice(i, 1);
    this.escolaService.nivelEnsino.setValue(values, {onlySelf: true, emitEvent: true});
  }

  updateMensalidade(event): void {
    let mensalidadeMax = event;
    let mensalidadeMin = mensalidadeMax - 999;

    if (mensalidadeMin <= 1) {
      mensalidadeMin = null
    }

    if (mensalidadeMax <= 0) {
      mensalidadeMax = null
      mensalidadeMin = 8000
    }

    console.log('mensalidadeMin: ' + mensalidadeMin + ' mensalidadeMax: ' + mensalidadeMax);

    this.escolaService.mensalidadeMin.setValue(mensalidadeMin);
    this.escolaService.mensalidadeMax.setValue(mensalidadeMax);

    /*this.escolaService.mensalidadeMax.setValue(this.options.ceil);
    this.escolaService.filterEvent.next({type: 'map', description: 'updateMensalidade'});*/

    this.escolaService.filterEvent.next()
  }

  updateMinMensalidade(event): void {
    let mensalidadeMin = parseInt(event);

    if (mensalidadeMin <= 1) {
      mensalidadeMin = null
    }

    this.escolaService.mensalidadeMin.setValue(mensalidadeMin)
    this.escolaService.filterEvent.next()
  }
}

@Component({
  selector: 'mapa-loading',
  templateUrl: 'mapa-loading.html',
})
export class MapaLoading {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

@Component({
  selector: 'mapa-criar-diretorio-popup',
  templateUrl: 'mapa-criar-diretorio-popup.html',
})
export class MapaCriarDiretorioPopup {
  sliderConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    centerPadding: '10px',
    draggable: true,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '10px'
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px'
        }
      }
    ]
  };

  constructor(
      public dialogRef: MatDialogRef<MapaCriarDiretorioPopup>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  cancelar(): void {
    this.dialogRef.close();
  }

}
