import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {AbordagemPedagogica, getTag, Tag, Tags} from '../../service/Tag';
import {EscolaService} from '../../service/escola.service';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {LabelType, Options} from '@angular-slider/ngx-slider';
import {CurrencyPipe, formatNumber} from '@angular/common';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-escola-filter-all',
  templateUrl: './escola-filter-all.component.html',
  styleUrls: ['./escola-filter-all.component.css']
})
export class EscolaFilterAllComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<Array<any>>;
  listControl = new FormControl();
  escolaTags = Tags;
  abordagemPedagogica = AbordagemPedagogica;
  @ViewChild('listaInput', {static: true}) listaInput: ElementRef<HTMLInputElement>;
  // @ViewChild('mensalidadeSlider', {static: true}) mensalidadeSlider: SliderComponent;
  // public rateGeral = 0;
  alunosStep = 10;
  indicesTotalAlunosMinValue = 0;
  indicesTotalAlunosMaxValue = 10000;
  mensalidadeStep = 50;
  mensalidadeMinValue = 0;
  mensalidadeMaxValue = 10000;
  distanciaStep = 0.5;
  distanciaMinValue = 0;
  distanciaMaxValue = 10;
  options: Options = {
    floor: 0,
    ceil: this.mensalidadeMaxValue,
    translate: (value: number, label: LabelType): string => {
      if (value === this.options.ceil) {
        return '+∞';
      }
      switch (label) {
        // case LabelType.Low:
        //   return 'Mínimo: $' + value;
        // case LabelType.High:
        //   return '+∞';
        default:
          return '$' + value;
      }
    }
  };

  constructor(public escolaService: EscolaService) {
  }

  ngOnInit(): void {
    this.filteredTags = this.listControl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => {
        return this._filter(value);
      })
    );
    // this.mensalidadeSlider.value = 0;
    // this.mensalidadeSlider.highValue = this.options.ceil;
  }

  resetFilters(): void {
    this.escolaService.filterFormGroup.reset();
  }

  remove(item: string): void {
    const itemGroup = this._getTag(item).group;
    let formControl: FormControl;
    switch (itemGroup) {
      case 'Nível de Ensino':
        formControl = this.escolaService.nivelEnsino;
        break;
      default:
        formControl = this.escolaService.filter;
        break;
    }
    const currentValues: string[] = formControl.value;
    if (currentValues == null) {
      return;
    }
    const index = currentValues.indexOf(item);
    if (index >= 0) {
      currentValues.splice(index, 1);
      formControl.setValue(currentValues);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // console.log('Selected: ' + event.option.value);
    const itemGroup = this._getTag(event.option.value).group;
    // console.log(`value:  ${event.option.value} group: ${itemGroup}`);
    let formControl: FormControl;
    switch (itemGroup) {
      case 'Nível de Ensino':
        formControl = this.escolaService.nivelEnsino;
        break;
      case 'Abordagem Pedagógica':
        formControl = this.escolaService.abordagemPedagogica;
        break;
      default:
        formControl = this.escolaService.filter;
        break;
    }
    let currentValues: string[] = formControl.value;
    if (currentValues == null) {
      currentValues = [];
    }
    currentValues.push(event.option.value);
    formControl.setValue(currentValues);
    this.listaInput.nativeElement.value = '';
    this.listControl.setValue(null);
  }

  private _filter(value: string): Array<Tag> {

    if (value == null) {
    } else {
      const selectedNivel: Array<string> = this.escolaService.nivelEnsino.value;
      const selectedAbordagemPedagogica: Array<string> = this.escolaService.abordagemPedagogica.value;
      const selectedTags: Array<string> = this.escolaService.filter.value;

      let allSelect: Array<string> = [];
      if (selectedNivel != null) {
        allSelect = allSelect.concat(selectedNivel);
      }
      if (selectedAbordagemPedagogica != null) {
        allSelect = allSelect.concat(selectedAbordagemPedagogica);
      }
      if (selectedTags != null) {
        allSelect = allSelect.concat(selectedTags);
      }

      // console.log(`NivelEnsino: ${JSON.stringify(selectedNivel)} Tags: ${JSON.stringify(selectedTags)}`);
      // console.log(`AllSelect: ${JSON.stringify(allSelect)}`);

      const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();

      const filtered = Tags.filter((tag) => {

        let alreadySelected = false;

        if (allSelect != null) {
          // console.log(`allSelect.indexOf(${tag.name})`);
          if (allSelect.indexOf(tag.name) >= 0) {
            // console.log(`Already Exist Tag: ${tag.name}`);
            alreadySelected = true;
          }
        }

        if (!alreadySelected) {
          const normalizedName = tag.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
          if (normalizedName.indexOf(normalizedValue) >= 0) {
            return tag;
          }
        }
      });
      return filtered;
    }
  }

  private _getTag(name: string): Tag {
    const tag = getTag(name);
    return tag;
  }

  formatLabel(value: number): string {

    if (value == null) {
      return null;
    }

    if (value >= this.mensalidadeMaxValue) {
      return '';
    }

    let r: number = value;
    let sulfix = '';
    if (r >= 1000) {
      r = r / 1000;
      sulfix = 'k';
    }

    return formatNumber(r, 'pt-BR', '1.0-0') + sulfix;
  }

}
