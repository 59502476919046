<div class="review-detail-card" fxFlex fxLayout="column">
    <div class="user align-items-center" fxFlex fxLayout="row">
        <div class="review-avatar image">
            <div *ngIf="review.anonimo || (review.avatar_url == null)" style="width: 50px; height: 50px;">
                <mat-icon style="font-size: 50px;">account_circle</mat-icon>
            </div>
            <img *ngIf="!review.anonimo && (review.avatar_url != null)" class="avatar" [src]="review.avatar_url"
                 alt="Avatar"/>
        </div>
        <div class="w-100">
            <p *ngIf="review.anonimo" style="color: #ef3f5d;">Avaliação Anônima</p>
            <p>{{review.nome_usuario}}</p>
            <p>
                {{review.data_hora_criada?.toLocaleString('pt-BR', {month: 'long', year: 'numeric'})}}
            </p>
            <p *ngIf="review.aprovado != true" style="color: #ef3f5d;">aguardando aprovação</p>
            <!--      <a *ngIf="review.rate_geral"-->
            <!--         href="javascript:" (click)="showRankingCompleto(review)"-->
            <!--         class="ranking" title="Ranking Geral" data-toggle="modal" data-target="#rankingModal">-->
            <!--        <img src="images/school/star.svg" alt="Estrela"><span><strong>{{review.rate_geral | number:'1.2-2':'pt-BR'}}</strong></span>-->
            <!--      </a>-->
            <div fxLayout="row" fxLayoutGap="3px" *ngIf="review.rate_geral">
                <img src="images/school/star.svg" alt="Estrela">
                <span style="font-weight: bold;">{{review.rate_geral | number:'1.2-2':'pt-BR'}}</span>
            </div>
        </div>
        <div class="review-controls" *ngIf="(this.review.owner && this.review.premium) || (userService.master === true)">
            <button mat-stroked-button (click)="this.editing = true" *ngIf="!editing">Responder</button>
        </div>
    </div>
    <div class="content" aria-expanded="false">
        <app-text-bound [text]="review.descricao"></app-text-bound>
    </div>

    <div class="content div-resposta-escola" aria-expanded="false" *ngIf="review.resposta || this.editing">
        <p *ngIf="review.resposta || this.editing">Resposta da Escola</p>

        <app-text-bound [class.visible]="(review.resposta || this.editing)? '' : 'hidden' " [text]="review.resposta"
                        [editing]="this.editing" #respostaBound></app-text-bound>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxFill>
            <button mat-stroked-button *ngIf="review.owner && this.editing"
                    (click)="this.editing = false">
                Cancelar
            </button>
            <button mat-stroked-button *ngIf="review.owner && this.editing"
                    [disabled]="this.saving"
                    (click)="this.sendResposta(respostaBound.text)">
                Salvar
                <mat-progress-bar mode="indeterminate" [style.display]="saving? '' : 'none'"></mat-progress-bar>
            </button>
        </div>
    </div>

</div>
