import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import firebase from 'firebase/app';

@Component({
  selector: 'app-selo-escola',
  templateUrl: './selo-escola.component.html',
  styleUrls: ['./selo-escola.component.css'],
})
export class SeloEscolaComponent implements OnInit {
  @Input() escola: Escola;
  @Input() seloId: number;
  seloData: any;

  constructor() {}

  get porcentagemRegiao() {
    return this.calcularPorcentagemSelo(this.seloData.selosRegiao);
  }

  get porcentagemCidade() {
    return this.calcularPorcentagemSelo(this.seloData.selosCidade);
  }

  async ngOnInit() {
    const request = {
      cod_escola: this.escola.codigo,
      cod_regiao: this.escola.cod_regiao,
      cod_selo: this.seloId,
    }

    const response = await firebase.functions().httpsCallable('escola_selo_grafico')(request);
    this.seloData = response.data;
    console.log("SeloData", this.seloData);
  }



  calcularPorcentagemSelo(selos: any[]) {
    if (!selos || selos.length === 0) {
      return 0;
    }

    let total = 0;
    let regiao = 0;

    selos.forEach((data) => {
      if (data.selo) {
        regiao += data.qtd
      }
      else {
        total += data.qtd
      }
    });

    let porcentagem = Math.round((regiao / total) * 100);

    if (porcentagem <= 0) {
      porcentagem = 1;
    }

    return porcentagem;
  }
}
