<h1 mat-dialog-title>Nova Lista/Diretório</h1>
<div mat-dialog-content>
  <p>Foram filtradas {{data.count | number:'1.0-0':'pt-BR'}} escolas. A lista será criada com até 50 escolas.</p>
  <app-escola-list-horizontal [escolaLista]="data.escola_lista" [editing]="true" [sliderConfig]="sliderConfig">
  </app-escola-list-horizontal>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelar()">Cancelar</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button>
</div>
