import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/analytics';
import User = firebase.User;
import {Escola, EscolaLista} from './Escola';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {
  }

  /**
   * Registra que foi clicado em uma midia
   * @param escola inep da escola
   * @param fieldName video, tour ou image
   */
  clickEscolaMidia(escola: Escola, fieldName: 'video' | 'tour' | 'image', index?: number, user?: User): void {
    const fa = firebase.analytics();
    fa.logEvent('link_escola_field', {
      event_label: fieldName,
      content_id: escola.inep,
      view_item: index
    });

    firebase.functions().httpsCallable('event_escola_field')({
      field_name: fieldName,
      cod_escola: escola.codigo,
      name: escola.nome,
      endereco: escola.endereco,
      nome_user: user?.displayName ?? '',
      event_type: 'click_link'
    });
  }

  /**
   * Registra que foi clicado para ver uma info/campo da escola
   * @param escola (JSON)
   * @param fieldName (string)
   */
  linkEscolaField(escola: Escola, fieldName: string, user?: User): void {
    
    const fa = firebase.analytics();
    fa.logEvent('link_escola_field', {
      event_label: fieldName,
      content_id: escola.inep,
    });
    firebase.functions().httpsCallable('event_escola_field')({
      field_name: fieldName,
      cod_escola: escola.codigo,
      name: escola.nome,
      endereco: escola.endereco,
      nome_user: user?.displayName ?? '',
      event_type: 'click_link'
    });
  }

  /**
   * Registra que foi clicado para ver uma info/campo da escola
   * @param escola (JSON)
   * @param fieldName (string)
   */
  viewEscolaField(escola: Escola, fieldName: string, user?: User, eventType: string = "click_view"): void {
    const fa = firebase.analytics();
    console.log('viewEscolaField', escola, fieldName, user, eventType);

    fa.logEvent('view_escola_field', {
      event_label: fieldName,
      content_id: escola.inep,
    });

    console.log('loeg_evented')
    
    firebase.functions().httpsCallable('event_escola_field')({
      field_name: fieldName,
      cod_escola: escola.codigo,
      name: escola.nome,
      endereco: escola.endereco,
      nome_user: user?.displayName ?? '',
      event_type: eventType
    });

    console.log('event_escola_field')
  }

  interesseEscolaField(escola: Escola, fieldName: string, user?: User): void {
    const fa = firebase.analytics();
    fa.logEvent('interesse_escola_field', {
      event_label: fieldName,
      content_id: escola.inep,
    });

    firebase.functions().httpsCallable('event_escola_field')({
      field_name: fieldName,
      cod_escola: escola.codigo,
      name: escola.nome,
      endereco: escola.endereco,
      nome_user: user?.displayName ?? '',
      event_type: "click_interest"
    });
  }

  /**
   * Regsitra que foi clicado para solicitar uma nova uma info/campo da escola
   * @param escola (JSON)
   * @param fieldName (string)
   */
  requestEscolaField(escola: Escola, fieldName: string, user?: User): void {
    const fa = firebase.analytics();
    fa.logEvent('request_escola_field', {
      event_label: fieldName,
      content_id: escola.inep,
    });
    firebase.functions().httpsCallable('event_escola_field')({
      field_name: fieldName,
      cod_escola: escola.codigo,
      name: escola.nome,
      endereco: escola.endereco,
      nome_user: user?.displayName ?? '',
      event_type: 'click_request'
    });
  }

  /**
   * Vai para o site (estático) das unidades do cellep (ex: https://br.cellep.com/unidades)
   */
  linkCellep(): void {
    const fa = firebase.analytics();
    fa.logEvent('link_cellep', {content_id: null});
  }

  /**
   * Vai para o site (estático) das unidades do cellep (ex: https://br.cellep.com/unidades)
   */
  linkOEBI(): void {
    const fa = firebase.analytics();
    fa.logEvent('link_oebi', {content_id: null});
  }

  /**
   * Link para falar com a unidade do cellep pelo whatsapp
   * @param nome Unidade
   */
  linkCellepWhatsapp(nome: string): void {
    const fa = firebase.analytics();
    fa.logEvent('link_cellep_whatsapp', {content_id: nome});
  }

  /**
   * Click no banner do cellep que esta na home
   */
  clickCellepBannerHome(): void {
    const fa = firebase.analytics();
    fa.logEvent('banner_home', {content_id: null});
  }

  /**
   * Click em algum parceiro (página de parceiro)
   * @param value Parceiro
   */
  clickParceiro(value: string): void {
    const fa = firebase.analytics();
    fa.logEvent('click_parceiro', {
      event_category: 'Link',
      content_id: value,
    });
  }

  /**
   * O click escola acontece qndo alguém clica em alguma escola de um carrossel.
   *  Exemplos: carrossel do home ou do mapa.
   *  Observações:
   *    o content_id = <inep da escola clicada>, event_category = 'Link'
   */
  clickEscola(escola: Escola): void {
    const fa = firebase.analytics();
    fa.logEvent('click_escola', {
      event_category: 'Link',
      content_id: escola.inep,
    });
  }

  /**
   * Favoritou (ou desfavoritou) uma escola
   * @param escola informacao da escola (inep)
   * @param value favorito = true, desfavorito = false
   */
  favoritoEscola(escola: Escola, value: boolean = true): void {
    const fa = firebase.analytics();
    let name;
    if (value) {
      name = 'favorito';
    } else {
      name = 'desfavorito';
    }

    fa.logEvent(name, {content_id: escola.inep});
  }

  /**
   * Print/exibiu um carrossel
   * @param escolaLista escolas do carrossel
   */
  escolaListaPrint(escolaLista: Array<EscolaLista>): void {
    const fa = firebase.analytics();
    for (const eLista of escolaLista) {
      const items: Array<firebase.analytics.Item> = [];
      for (const escola of eLista.escola_lista) {
        items.push({
          item_id: '' + escola.inep,
          item_name: escola.nome
        });
      }

      fa.logEvent('escola_lista', {content_id: eLista.nome_lista, item_list_name: eLista.nome_lista, items});
    }
  }

  /**
   * Efetuou uma busca (filtro do mapa)
   * @param filter dados da busca
   */
  buscaEscola(filter: any): void {
    const fa = firebase.analytics();
    fa.logEvent('busca_escola', {
      item_list_name: JSON.stringify(filter),
      description: JSON.stringify(filter),
    });
  }

  /**
   * Resultado da busca da escola
   * @param escolas que vieram do filtro
   */
  resultadoBuscaEscola(escolas: Array<Escola>): void {
    const fa = firebase.analytics();
    const items: Array<firebase.analytics.Item> = [];
    for (const escola of escolas) {
      items.push({item_id: '' + escola.inep});
    }
    fa.logEvent('busca_escola_resultado', {items, description: JSON.stringify(items)});
  }

  /**
   * Click da rede social
   * @param redeSocial nome da rede social clicada
   */
  clickRedeSocial(redeSocial: string): void {
    const fa = firebase.analytics();
    fa.logEvent('click_rede_social_school', {content_id: redeSocial});
  }

  /**
   * Click com intenção de comprar escola
   * @param escola_codigo dados da escola
   * @param plano nome do plano
   */
  clickComprar(escola_codigo: number, plano: string): void {
    const fa = firebase.analytics();
    fa.logEvent('click_comprar', {content_id: escola_codigo, value: plano});
  }

  /**
   * Solicita ser o proprietário da escola
   * @param escola salva o inep da escola
   */
  clickSolicitarProprietario(escola: Escola): void {
    const fa = firebase.analytics();
    fa.logEvent('click_solicitar_proprietario', {content_id: escola.inep, inep: escola.inep});
  }

  /**
   * Vai para o site (estático) das unidades do cellep (ex: https://br.cellep.com/unidades)
   */
  clickCalculadoraCalcular(): void {
    const fa = firebase.analytics();
    fa.logEvent('click_calculadora_calcular', {content_id: null});
  }

  clickCalculadoraCalcularDeNovo(): void {
    const fa = firebase.analytics();
    fa.logEvent('click_calculadora_calcular_de_novo', {content_id: null});
  }

  clickCalculadoraEncontreEscola(): void {
    const fa = firebase.analytics();
    fa.logEvent('click_calculadora_encontre_escola', {content_id: null});
  }

  clickBannerParceiros(): void {
    const fa = firebase.analytics();
    fa.logEvent('click_banner_parceiros', {content_id: null});
  }

  clickMozperBannerCalculadora(): void {
    const fa = firebase.analytics();
    fa.logEvent('click_mozper_banner_calculadora', {content_id: null});
  }

  async sendEventMail(escola: Escola, user?: User): Promise<void> {
    if (user == null) {
      user = firebase.auth().currentUser;
    }

    const data = {
      nome: user.displayName,
      emailUser: user.email,
      schoolName: escola.nome,
      emailTo: escola.email,
      premium: escola.premium
    }

    try
    {
      await firebase.functions().httpsCallable('escola_send_email_event')(data)
    }
    catch (error)
    {
      console.error('Error on request send event email', error);
    
    }
  }
}

