import {AfterViewInit, Component, OnInit} from '@angular/core';
import {trigger, animate, style, transition, state} from '@angular/animations';
import {Router} from '@angular/router';
import {AnalyticsService} from '../../service/analytics.service';

@Component({
  selector: 'app-bottom-banner',
  templateUrl: './bottom-banner.component.html',
  styleUrls: ['./bottom-banner.component.scss'],
  animations: [
    trigger('fadeInOutAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate(1000, style({opacity: 1})),
      ]),
      transition(':leave', [
        animate(1000, style({opacity: 0}))
      ])
    ]),
    trigger('openClose', [
      state('open', style({
        'max-height': '100px',
        // background: 'red',
      })),
      state('close', style({
        'max-height': '0',
        // background: 'yellow',
      })),
      transition('* => *', [
        animate('1s')
      ]),
    ]),
    trigger('flyInOut', [
      state('open', style({
        transform: 'translateY(0)',
      })),
      state('close', style({
        transform: 'translateY(100%)',
      })),
      transition('* => *', [
        animate('1s')
      ]),
    ]),
    trigger('translateUpDown', [
      state('open', style({
        // transform: 'translateY(-50%)',
        top: '-25px',
      })),
      state('close', style({
        // transform: 'translateY(-80%)',
        top: '-50px',
      })),
      transition('* => *', [
        animate('1s')
      ]),
    ])

  ]
})
export class BottomBannerComponent implements OnInit, AfterViewInit {

  isOpen = true;
  show = true;

  constructor(
    private router: Router,
    private analytics: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.refreshRoute();
  }

  ngAfterViewInit(): void {
    this.refreshRoute();
  }

  refreshRoute(): void {
    // console.log('Route: ' + this.router.url);
    if (this.router.url === '/parceiros') {
      this.show = false;
    }
  }

  clickBannerParceiros(): void {
    this.analytics.clickBannerParceiros();
  }
}
