<mat-accordion multi>
    <div *ngFor="let chat of chats">
        <mat-expansion-panel (afterExpand)="this.expand(chatroom)">
            <mat-expansion-panel-header>
                <mat-panel-title fxFlex="50">
                    {{chat.email}}
                </mat-panel-title>
                <mat-panel-description fxFlex="50">
                    <div fxFlex="100">
                        <span fxFlex="100">{{chat.name}}</span>
                        <mat-icon [matTooltip]="'Chat ID: '+chat.uid">info</mat-icon>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div style="height: 500px">
                <app-chatroom #chatroom [roomname]="chat.uid"></app-chatroom>
            </div>
        </mat-expansion-panel>
    </div>
</mat-accordion>
