import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-politica',
  templateUrl: './page-politica.component.html',
  styleUrls: [
    './page-politica.component.css',
    '../../../css/elementor/post-220.css',
    '../../../css/elementor/global.css',
    '../../../css/elementor/frontend.min.css',
    '../../../css/elementor/style.css',
  ]
})
export class PagePoliticaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
