export enum NivelEnsinoEnum {
  bercario = 'Berçário',
  infantil = 'Infantil',
  fundamental_1 = 'Fundamental I',
  fundamental_2 = 'Fundamental II',
  ensino_medio = 'Ensino Médio',
}

export const NivelEnsinoArray: string[] = Object.values(NivelEnsinoEnum);

export interface NivelEnsinoAttributes {
  nome: NivelEnsinoEnum;
  anos: number;
  material: number;
  transporte: number;
  idiomas: number;
  uniforme: number;
  extracurriculares: number;
  /**
   * Usado somente para somar ao label do nivel de ensino fundamental II
   * Por exemplo, se o offset_nivel = 5:
   * Muda Fundamental II: 1º ano para Fundamental II: 6º ano (pois o offset é +5)
   */
  offset_nivel?: number;
}

export type NivelEnsinoInterace = {
  [nivel_ensino in NivelEnsinoEnum]: NivelEnsinoAttributes;
};

export const NivelEnsinoData: NivelEnsinoInterace = {
  [NivelEnsinoEnum.bercario]: {
    nome: NivelEnsinoEnum.bercario,
    anos: 1,
    material: 700,
    transporte: 0,
    idiomas: 0,
    uniforme: 0,
    extracurriculares: 0,
  },
  [NivelEnsinoEnum.infantil]: {
    nome: NivelEnsinoEnum.infantil,
    anos: 5,
    material: 700,
    transporte: 450,
    idiomas: 0,
    uniforme: 500,
    extracurriculares: 150,
  },
  [NivelEnsinoEnum.fundamental_1]: {
    nome: NivelEnsinoEnum.fundamental_1,
    anos: 5,
    material: 1700,
    transporte: 450,
    idiomas: 400,
    uniforme: 500,
    extracurriculares: 150,
  },
  [NivelEnsinoEnum.fundamental_2]: {
    nome: NivelEnsinoEnum.fundamental_2,
    anos: 4,
    material: 1700,
    transporte: 450,
    idiomas: 400,
    uniforme: 500,
    extracurriculares: 150,
    offset_nivel: 5,
  },
  [NivelEnsinoEnum.ensino_medio]: {
    nome: NivelEnsinoEnum.ensino_medio,
    anos: 3,
    material: 1700,
    transporte: 450,
    idiomas: 400,
    uniforme: 500,
    extracurriculares: 150,
  }
};

// NIVEL ENSINO:  bercario = 1, infantil = 5; fundamental1 = 5; fundamental2 = 4; ension medio = 3
// MATERIAL: bercario = 700 a infantil = 700 fundamental 1 = 1700 fundamental 2 = 1700 ensino medio = 1700 //por ano
// TRANSPORTE: bercario = 0 outros são = 450 //por mes (12 meses)
// IDIOMAS: bercario = 0 infantil = 0 outros são = 250 //por mes (13 meses)
// UNIFORME: bercario = 0 outros = 500 //por ano
// EXTRACURRICULARES: bercario = 0 outros são = 150 //por mes (13 meses)
