import {Component, OnInit} from '@angular/core';
import {ChatService} from '../service/chat.service';
import {ActivatedRoute} from '@angular/router';
import {interval, Observable} from 'rxjs';
import {UserService} from '../service/user.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  chat$: Observable<any> | undefined;
  chatId: string;
  newMsg: string;

  constructor(
      public chatService: ChatService,
      private route: ActivatedRoute,
      public userService: UserService,
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.chatId = id;
      // const source = this.chatService.get(this.chatId);
      // this.chat$ = this.chatService.joinUsers(source);

      this.chat$.subscribe(value => {
        interval(2000).subscribe(() => {
          this.scrollDown();
        });
      });

    }
  }

  submit(chatId: string): void {
    const name: string = '' + this.userService.getCurrentUser().displayName;
    this.chatService.sendMessage(chatId, {message: this.newMsg, name}).then(() => {
          this.scrollDown();
        }
    );
    this.newMsg = '';
  }

  trackByCreated(i: number, msg: any): any {
    return msg.createdAt;
  }

  private scrollDown(): void {
    const el = document.getElementsByClassName('mat-tab-body-content')[0];
    if (el) {
      el.scrollTo({top: el.scrollHeight, behavior: 'smooth'});
    } else {
      console.log('scroll not initialized');
    }
  }

  exitChat(): void {

  }
}
