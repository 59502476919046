<div class="container" fxFlex fxLayout="column" *ngIf="escolaLista">
    <div class="subtitle" fxFlex fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px">
        <a [routerLink]="['/lista',this.escolaLista.nome_lista | urlNormalizer]" *ngIf="!this.editing else editNome"
           target="_blank" title="veja mais" class="veja-mais">
            <h2 class="w-100">{{this.escolaLista.nome_lista}}</h2>
        </a>
        <ng-template #editNome>
            <mat-form-field class="w-100">
                <mat-label>Nome da Lista</mat-label>
                <input maxLength="50" class="nome-lista-edit" matInput
                       [disabled]="this.saving"
                       placeholder="Nome" [(ngModel)]="this.escolaLista.nome_lista">
            </mat-form-field>
        </ng-template>
        <ng-template [ngIf]="userService.master && !this.editing && !this.readonly">
            <button mat-mini-fab [disabled]="this.saving"
                    matTooltip="Editar Lista/Diretório"
                    (click)="startEdit()">
                <mat-icon>edit</mat-icon>
            </button>
            <mat-label>Prioridade: {{this.escolaLista.prioridade}}</mat-label>
        </ng-template>
        <ng-template [ngIf]="this.editing">
            <button mat-mini-fab [disabled]="this.saving"
                    matTooltip="Salvar Lista/Diretório"
                    (click)="saveLista()">
                <mat-icon>done</mat-icon>
            </button>
            <button mat-mini-fab [disabled]="this.saving"
                    matTooltip="Cancelar alterações"
                    (click)="cancelEdit()">
                <mat-icon>cancel</mat-icon>
            </button>
            <button mat-mini-fab [disabled]="this.saving"
                    [matMenuTriggerFor]="menuRemove"
                    matTooltip="Remover Lista/Diretório">
                <mat-icon>delete</mat-icon>
            </button>
            <mat-menu #menuRemove="matMenu" class="w-100">
                <button mat-menu-item (click)="removeLista()">
                    Remover a lista <span
                        style="font-weight: bold">{{(this.escolaLista.nome_lista?.length > 0) ? this.escolaLista.nome_lista : ''}}</span>?
                </button>
            </mat-menu>
            <button mat-mini-fab
                    [disabled]="this.saving"
                    [matMenuTriggerFor]="menuAdd"
                    (menuOpened)="nomeEscolaInput.focus(); nomeEscolaInput.select(); nomeEscolaInput.value = null;"
                    matTooltip="Adicionar Escola">
                <mat-icon>add</mat-icon>
            </button>
            <mat-slide-toggle [disabled]="this.saving"
                              [(ngModel)]="this.escolaLista.random"
                              labelPosition="before"
                              matTooltip="Ordem dos Itens é randômica">
                Ordem Randômica
            </mat-slide-toggle>
            <mat-slide-toggle [disabled]="this.saving"
                              [(ngModel)]="this.escolaLista.ativa"
                              labelPosition="before"
                              matTooltip="Na home só aparecem as listas ativas (para os pais e escolas)">
                Ativa
            </mat-slide-toggle>
            <mat-form-field>
                <mat-label>Prioridade</mat-label>
                <input matInput type="number" placeholder="Ex. 12"
                       matTooltip="Menor Prioridade equivale a Topo da Lista"
                       [(ngModel)]="this.escolaLista.prioridade" [disabled]="this.saving">
            </mat-form-field>
        </ng-template>
        <mat-menu #menuAdd="matMenu" class="w-100">
            <mat-form-field fxFlexFill (click)="$event.stopPropagation()" class="nome-escola-input">
                <mat-label class="custom-label">Escola</mat-label>
                <input fxFlex matInput #nomeEscolaInput
                       [formControl]="this.escolaFormControl"
                       [matAutocomplete]="autoEscolaNome"
                       placeholder="Nome da escola"
                >
            </mat-form-field>

            <mat-autocomplete
                    class="escola-autocomplete"
                    #autoEscolaNome="matAutocomplete"
                    [displayWith]="displayEscola"
                    (optionSelected)="addEscola($event.option.value)">
                <mat-option *ngIf="isLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <span>{{this.loadingMessage}}</span>
                </mat-option>
                <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let escola of escolaFiltered" [value]="escola">
                        <div *ngIf="!escola?.nome" fxFlex fxLayout="row" class="select-item-box"
                             fxLayoutAlign="start center">
                            <mat-icon>search</mat-icon>
                            <span fxFlex="16px" class="select-nome">{{escola}}</span>
                        </div>
                        <div *ngIf="escola?.nome" fxFlex fxLayout="row" class="select-item-box"
                             fxLayoutAlign="start center">
                            <div>
                                <p><span class="select-inep position-absolute top-0 end-0">{{escola.inep}}</span></p>
                                <p><span class="select-nome position-absolute top-0">{{escola.nome}}</span></p>
                                <p><span class="select-address position-absolute bottom-0">{{escola.endereco}}</span>
                                </p>
                            </div>
                        </div>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-menu>
    </div>
    <div *ngIf="(this.escolaLista.escola_lista == null) else escolaFound">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <ng-template #escolaFound>
        <div class="w-100" fxLayout="row">
            <div class="navigation-arrow navigation-previous" fxLayout="column" fxLayoutAlign="center center">
                <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab
                        (click)="swiperComponent.swiperRef.slidePrev()"
                        [disabled]="swiperComponent?.swiperRef?.isBeginning">
                    <mat-icon [color]="swiperComponent?.swiperRef?.isBeginning ? 'gray' : 'primary'" [inline]="true"
                              style="font-size: 3em">chevron_left
                    </mat-icon>
                </button>
            </div>
            <swiper fxFlex="100" #swiperComponent style="padding-bottom: 25px" [config]="this.config">
                <ng-template swiperSlide class="slide-container"
                             *ngFor="let escola of this.escolaLista.escola_lista; let i = index;">
                    <ng-template [ngIf]="escola">
                        <app-escola-card [escola]="escola"></app-escola-card>
                        <span class="position-absolute align-top concorrente-tag"
                              *ngIf="escola.concorrente && this.userService.master">Concorrente</span>
                    </ng-template>
                </ng-template>
            </swiper>
            <div class="navigation-arrow navigation-next" fxLayout="column" fxLayoutAlign="center center">
                <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab
                        (click)="swiperComponent.swiperRef.slideNext()"
                        #navButtonNext [disabled]="swiperComponent?.swiperRef?.isEnd">
                    <mat-icon [color]="swiperComponent?.swiperRef?.isEnd ? 'gray' : 'primary'" [inline]="true"
                              style="font-size: 3em">chevron_right
                    </mat-icon>
                </button>
            </div>
            <!-- VER TODAS -->
        </div>
<!--        <div *ngIf="(this.escolaLista.escola_lista != null) && (this.escolaLista.escola_lista.length > 0)"-->
<!--             style="display: flex; flex-direction: row-reverse; padding-bottom: 5px; margin-bottom: 25px; border-bottom: 1px solid #dadada;">-->
<!--            <div class="btn-ver-todas">-->
<!--                <a>-->
<!--                    <span style="cursor: pointer" (click)="map()">ver todas</span>-->
<!--                    &lt;!&ndash;        <button mat-button title="ver todas" (click)="map()">ver todas</button>&ndash;&gt;-->
<!--                </a>-->
<!--                &lt;!&ndash;      <a name="ver todas" title="ver todas" (click)="map()">ver todas</a>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
    </ng-template>
</div>
