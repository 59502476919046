<div class="map-container">
    <div class="list-container"
         ngClass.lt-sm="list-container-mobile"
         ngClass.gt-sm="list-container-desk"
         fxFlex fxFlex.lt-sm="100"
         [fxHide.lt-sm]="mapToggle"
         fxLayout="column" id="listContainer">
        <div fxFlex fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center"
                 style="margin-bottom: 10px; padding: 10px; position: sticky; top: 0; background-color: white; z-index: 10;">
                <button mat-mini-fab *ngIf="this.userService.master"
                        (click)="generateLink()"
                        [matTooltip]="'Configurações'" [matMenuTriggerFor]="configMenu">
                    <mat-icon fontSet="fas" fontIcon="fa-bookmark"></mat-icon>
                </button>
                <mat-menu #configMenu="matMenu" yPosition="below">
                    <mat-form-field appearance="outline" (click)="$event.stopPropagation()" class="w-100">
                        <!--            <label>Link para esse filtro</label>-->
                        <button mat-button matPrefix mat-icon-button aria-label="Copiar Link"
                                matTooltip="Copiar Link" [cdkCopyToClipboard]="this.searchLink">
                            <mat-icon fontSet="fas" fontIcon="fa-copy"></mat-icon>
                        </button>
                        <input fxFlex matInput #nomeEscolaInput [disabled]="true" [value]="this.searchLink">
                    </mat-form-field>
                    <button mat-stroked-button class="w-100 filter-button" (click)="createList()">Criar
                        Lista/Diretório
                    </button>
                </mat-menu>
                <div aria-label="Filtros" class="w-100 d-flex flex-wrap" fxLayoutGap="3px">
                    <button mat-stroked-button class="custom-chip truncate"
                            *ngFor="let nivel of this.escolaService.nivelEnsino.value">
                        <div class="custom-chip-content">
                            <!--                <mat-icon>school</mat-icon>-->
                            <span class="truncate">{{nivel}}</span>
                            <mat-icon *ngIf="escolaService.nivelEnsino.value"
                                      (click)="this.removeNivelEnsinoFilter(nivel); this.markForFilter()"
                                      color="primary">
                                highlight_off
                            </mat-icon>
                        </div>
                    </button>

                    <button mat-stroked-button class="custom-chip truncate"
                            [ngClass]="{'mat-chip-selected': this.escolaService.distanciaMax.value > 0}"
                            *ngIf="escolaService.distanciaMax.value > 0">
                        <div class="custom-chip-content">
                            <span class="truncate">{{'até ' + this.escolaService.distanciaMax.value + 'km'}}</span>
                            <mat-icon (click)="escolaService.distanciaMax.setValue(null); this.markForFilter();"
                                      color="primary">highlight_off
                            </mat-icon>
                        </div>
                    </button>

                    
                    <button mat-stroked-button class="custom-chip truncate"
                            [ngClass]="{'mat-chip-selected': this.escolaService.listas.value > 0}"
                            *ngFor="let lista of this.escolaService.listas.value">
                        <div class="custom-chip-content" [matTooltip]="lista">
                            <span class="truncate">{{lista}}</span>
                            <mat-icon
                                    (click)="escolaService.listas.value.splice(escolaService.listas.value.indexOf(lista), 1); markForFilter();"
                                    color="primary">highlight_off
                            </mat-icon>
                        </div>
                    </button>
                </div>
                <button mat-stroked-button color="primary" (click)="allFilterClick()"
                        matBadgePosition="below after"
                        matTooltip="Refinar a Busca"
                        style="min-width: 100px"
                        ngStyle.lt-sm="min-width: 60px"
                        [matBadgeHidden]="(
                this.escolaService.filter.value?.length
                + (this.escolaService.rateGeral.value > 0)
                + (this.escolaService.distanciaMax.value > 0)
                + (this.escolaService.indicesTotalAlunosMax.value > 0)
                + (this.escolaService.mensalidadeMax.value > 0)
                + (this.escolaService.cellep.value?.length > 0)
                ) == 0"
                        [matBadge]="(
                this.escolaService.filter.value?.length
                + (this.escolaService.rateGeral.value > 0)
                + (this.escolaService.distanciaMax.value > 0)
                + (this.escolaService.indicesTotalAlunosMax.value > 0)
                + (this.escolaService.mensalidadeMax.value > 0)
                + (this.escolaService.cellep.value?.length > 0))"
                        [ngClass]="{'mat-chip-selected': (
                this.escolaService.filter.value?.length
                + (this.escolaService.rateGeral.value > 0)
                + (this.escolaService.distanciaMax.value > 0)
                + (this.escolaService.indicesTotalAlunosMax.value > 0)
                + (this.escolaService.mensalidadeMax.value > 0)
                + (this.escolaService.cellep.value?.length > 0))}"
                >
                    <span fxHide.lt-md>Filtros</span>
                    <mat-icon>filter_list</mat-icon>
                </button>
                <mat-form-field appearance="outline"
                                (click)="$event.stopPropagation()"
                                [hidden]="!(this.userService.userData?.perfil)">
                    <mat-label>Ordenar por</mat-label>
                    <mat-select [formControl]="this.escolaService.order"
                                (valueChange)="this.escolaService.filterEvent.next()"
                                style="min-width: 100px">
                        <mat-option *ngFor="let item of orderTypeEnum | keyvalue" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="container" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
<!--                <app-paginator #paginatorTop></app-paginator>-->
                <app-escola-list-vertical
                        [escolaCount]="this.escolaCount"
                        [filterMessage]="this.filterMessage"
                        [pageSize]="this.pageSize"
                        [escolaList]="this.escolaList"
                        (mouseEnterEscola)="this.bounce($event.escola, $event.index, true)"
                        (mouseLeaveEscola)="this.bounce($event.escola, $event.index, false)"
                        (paginatorPage)="this.updatePaginator()"
                        #escolaListVertical
                ></app-escola-list-vertical>
                <span *ngIf="this.escolaList === null" style="text-align: center">
                    Tente alterar ou remover alguns filtros, ou
                    <a (click)="this.map.setZoom(this.map.getZoom()-3)" href="javascript:">
                        buscar em uma região maior
                    </a>
                </span>
            </div>
        </div>
    </div>
    <div fxFlex="0 1 50" [fxHide.lt-sm]="!mapToggle" fxFlex.lt-sm="100">

        <div class="map-div">
            <google-map
                    [options]="mapOptions" width="100%" height="100%"
                    (mapDragend)="this.mapDragEndEvent()"
                    (zoomChanged)="this.mapDragEndEvent()"
                    (mapInitialized)="this.afterMapInit($event)"
            >
                <map-marker #marker="mapMarker"
                            *ngFor="let m of mapMarkers; let i = index"
                            [position]="m.getPosition()"
                            [options]="{animation: m.getAnimation(), draggable: false, icon: m.getIcon()}"
                            (mapClick)="this.openInfoWindow(marker, i)"
                >
                </map-marker>
                <map-marker #marker="mapMarker"
                            *ngFor="let m of parceiroMapMarkers; let i = index"
                            [position]="m.getPosition()"
                            [options]="{animation: m.getAnimation(), draggable: false, icon: m.getIcon()}"
                            (mapClick)="this.openMarkerWindow(marker, i)"
                >
                </map-marker>
                <map-info-window>
                    <ng-template [ngIf]="this.selectedEscola">
                        <app-escola-card [escola]="this.selectedEscola"></app-escola-card>
                    </ng-template>
                    <ng-template [ngIf]="this.selectedCellep">
                        <article
                                class="info-card-slide d-flex flex-column align-items-center justify-content-center">
                            <a href="/cellep/#${item.nome}" title="veja mais" target="_blank" class="veja-mais">
                                <img src="images/cellep/logo.svg" alt="Parceiro" class="w-100"
                                     style="max-width: 100px">
                                <h3 class="info-escola-nome">{{this.selectedCellep.nome}}</h3>
                                <p class="info-escola-endereco">{{this.selectedCellep.endereco}}</p>
                            </a>
                        </article>
                    </ng-template>
                </map-info-window>
            </google-map>

            <mat-checkbox
                    class="btn-refresh"
                    color="primary"
                    [disabled]="loading"
                    [(ngModel)]="mapScrollRefresh"
                    [fxHide.lt-sm]="true">
                {{loading ? loading : 'Buscar ao mover o mapa'}}
                <mat-progress-bar mode="indeterminate" [style.display]="loading? '' : 'none'"></mat-progress-bar>
            </mat-checkbox>

            <mat-checkbox
                    color="primary"
                    class="btn-refresh align50"
                    [disabled]="loading"
                    [(ngModel)]="mapScrollRefresh"
                    fxHide="true"
                    [fxHide.lt-sm]="!mapToggle">
                {{loading ? loading : 'Buscar ao mover o mapa'}}
                <mat-progress-bar mode="indeterminate" [style.display]="loading? '' : 'none'"></mat-progress-bar>
            </mat-checkbox>
        </div>

        <div class="mapa-carousel position-absolute" [fxShow.lt-sm]="mapToggle" fxHide>
            <ngx-slick-carousel
                    style="max-height: 20vh"
                    [config]="sliderConfig"
                    #slickModal="slick-carousel">
                <article
                        class="card-slide"
                        ngxSlickItem
                        fxFlex
                        fxLayoutGap="10px"
                        *ngFor="let escola of escolaList">
                    <div class="card-slide-image">
                        <img src="{{(escola.logo_escola == null)? 'images/thumb-list.jpg':escola.logo_escola}}"
                             alt="Escola"/>
                    </div>
                    <!--          <app-button-favorite [escola]="escola"></app-button-favorite>-->
                    <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" title="veja mais"
                       class="veja-mais" target="_blank">
                        <h3 class="escola-nome">{{escola.nome}}</h3>
                        <p class="escola-endereco">{{escola.endereco}}</p>
                    </a>
                </article>
            </ngx-slick-carousel>
        </div>
    </div>
</div>


<!-- BTN MAP -->
<div class="btn-mobile-map" [fxShow.lt-sm]="!mapToggle" fxHide>
    <a title="mapa" href="javascript:" (click)="showMap()">
        <img src="images/map.svg" alt="mapa">mapa
    </a>
</div>

<!-- BTN LIST -->
<div class="btn-mobile-list" [fxShow.lt-sm]="mapToggle" fxHide>
    <a title="lista" href="javascript:" (click)="showList()">
        <img src="images/list.svg" alt="lista">lista
    </a>
</div>

<!--    <mat-spinner diameter="20" [style.display]="loading? '' : 'none'"></mat-spinner>-->
