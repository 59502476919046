import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-mais-sobre',
  templateUrl: './escola-mais-sobre.component.html',
  styleUrls: ['./escola-mais-sobre.component.css'],
})
export class EscolaMaisSobreComponent implements OnInit {
  @Input() escola: Escola;
  isExpanded = false;

  constructor() {}

  ngOnInit(): void {}

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  hasData(): boolean {
    return (this.escola.abordagem_pedagogica && this.escola.abordagem_pedagogica.trim() !== '') 
        || (this.escola.indices_total_alunos > 0) 
        || (this.escola.grupo_educacional && this.escola.grupo_educacional.trim() !== '') 
        || (this.escola.ano_fundacao> 0);
  }
}
