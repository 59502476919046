import { Component, Renderer2, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-template-new',
  templateUrl: './template-new.component.html',
  styleUrls: [
    '../../../css/new/bootstrap-grid.min.css', 
    '../../../css/new/bootstrap-reboot.min.css', 
    '../../../css/new/bootstrap.min.css', 
    '../../../../node_modules/swiper/swiper-bundle.css', 
    '../../../css/new/global.css', 
    '../../../css/new/header.css', 
    '../../../css/new/footer.css', 
    '../../../css/new/aside-menu.css', 
    '../../../css/new/school-gallery.css', 
    '../../../css/new/card.css', 
    '../../../css/new/coments.css', 
    '../../../css/new/slider.css', 
    '../../../css/new/fotorama.css', 
    '../../../css/new/gallery.css', 
    '../../../css/new/modal.css', 
    '../../../css/new/star-rating.css', 
    '../../../css/new/escola.css', 
    './template-new.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateNewComponent implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'school');
    this.loadStyles();
  }

  private loadStyles(): void {
    const styles = [
      '../../../css/new/bootstrap-grid.min.css', 
      '../../../css/new/bootstrap-reboot.min.css', 
      '../../../css/new/bootstrap.min.css', 
      '../../../../node_modules/swiper/swiper-bundle.css', 
      '../../../css/new/global.css', 
      '../../../css/new/header.css', 
      '../../../css/new/footer.css', 
      '../../../css/new/aside-menu.css', 
      '../../../css/new/school-gallery.css', 
      '../../../css/new/card.css', 
      '../../../css/new/coments.css', 
      '../../../css/new/slider.css', 
      '../../../css/new/fotorama.css', 
      '../../../css/new/gallery.css', 
      '../../../css/new/modal.css', 
      '../../../css/new/star-rating.css', 
      '../../../css/new/escola.css', 
      './template-new.component.scss'
    ];

    styles.forEach((style) => {
      const linkElement = this.renderer.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = style;
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'school');
  }

  onActivate(): void {
    window.scroll(0, 0);
  }
}
