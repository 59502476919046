import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-publicidade',
  templateUrl: './escola-publicidade.component.html',
  styleUrls: ['./escola-publicidade.component.css'],
})
export class EscolaPublicidadeComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}
}
