<!--
<div class="mb-5 programs-certifications" *ngIf="galerryImages.length > 0">
  <div class="container">
    <div class="col-12 text-center mb-5">
      <h2 class="text-black font-size-biggest mb-0">Parcerias, Associações, Certificações e Acreditações da escola</h2>
    </div>

    <div id="recipeLogos" class="carousel slide slider logos" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div *ngFor="let image of galerryImages; let i = index" [class.active]="i === 0" class="carousel-item">
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
            <figure class="ads">
              <img [src]="image.src" [alt]="image.alt" style="height: 50%;" />
            </figure>
          </div>
        </div>
      </div>
      <a class="control carousel-control-prev" href="#recipeLogos" role="button" data-bs-slide="prev" (click)="preventDefault($event)">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="control carousel-control-next" href="#recipeLogos" role="button" data-bs-slide="next" (click)="preventDefault($event)">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
  </div>
</div>
-->

<div class="mb-3 ads-sliders" *ngIf="galerryImages.length > 0">
  <div class="container">

    <div class="col-12 text-center mb-5">
      <h2 class="text-black font-size-biggest mb-0">Parcerias, Associações, Certificações e Acreditações da escola</h2>
    </div>

    <div class="w-100" fxLayout="row">
        <div class="navigation-arrow navigation-previous" fxLayout="column" fxLayoutAlign="center center">
            <button style="background-color: transparent;" 
              class="mat-elevation-z0"
              (click)="previousClick()"
              mat-mini-fab>
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>
        </div>
        <swiper fxFlex="100" #swiperLogoComponent style="padding-bottom: 25px; height: 250px;" [config]="this.config">
            <ng-template swiperSlide class="carousel slide slider logos slide-container h-100"
                        *ngFor="let image of galerryImages; let i = index;">
              <div class="carousel-inner" style="height: 100%;">
                <ng-template [ngIf]="image">
                  <figure class="ads">
                    <img [src]="image.src" [alt]="image.alt" style="height: 50%;" />
                  </figure>
                </ng-template>
              </div>
            </ng-template>
        </swiper>
        <div class="navigation-arrow navigation-next" fxLayout="column" fxLayoutAlign="center center">
            <button style="background-color: transparent;" 
              class="mat-elevation-z0" 
              (click)="nextClick()"
              mat-mini-fab>
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </button>
        </div>
    </div>
  </div>
</div>