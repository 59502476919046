import {Component, OnInit} from '@angular/core';
import {Escola} from '../../service/Escola';
import {EscolaService} from '../../service/escola.service';
import firebase from 'firebase';
import {PageEvent} from '@angular/material/paginator';
import {debounceTime, tap} from 'rxjs/operators';

@Component({
  selector: 'app-page-escolas',
  templateUrl: './page-escolas.component.html',
  styleUrls: ['./page-escolas.component.css']
})
export class PageEscolasComponent implements OnInit {
  escolaList: Array<Escola>;
  escolaCount = 0;
  loading = false;
  latitude = null;
  longitude = null;
  pageIndex = 0;
  pageSize = 20;
  filterMessage: string;

  constructor(private escolaService: EscolaService) {
    this.getCurrentPosition().then((position) => {
      if (position) {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      }
      this.refreshList();
    }).catch(reason => {
      this.refreshList();
    });

    this.escolaService.filterEvent.subscribe(value => {
      if (value?.type === 'list') {
        this.refreshList();
      }
    });
  }

  ngOnInit(): void {
  }

  getCurrentPosition(): Promise<any> {
    if (navigator.geolocation) {
      // Try HTML5 geolocation
      return new Promise<any>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    } else {
      return new Promise<any>(resolve => resolve({}));
    }
  }

  updatePagination(event: PageEvent): void {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.refreshList();
  }

  refreshList(): void {
    this.escolaService.regiao.setValue(null);
    this.escolaService.nivelEnsino.setValue(null);
    const data = {
      nome: this.escolaService.nome.value,
      totalRows: true,
      lat: this.latitude,
      lng: this.longitude,
      limit: this.pageSize,
      offset: this.pageIndex * this.pageSize
    };
    this.loading = true;
    this.filterMessage = 'Procurando escolas...';
    this.escolaList = null;
    firebase.functions().httpsCallable('escola')(data).then(filterResult => {
      this.escolaCount = filterResult.data.totalRows;
      this.escolaList = filterResult.data.rows;
      this.loading = false;
      if (filterResult.data.rows.length > 0) {
        this.filterMessage = null;
      }else {
      this.filterMessage = 'Nenhuma escola foi encontrada.';
      }
    });
  }

}
