import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-planos',
  templateUrl: './page-planos.component.html',
  styleUrls: ['./page-planos.component.css', '../../../css/elementor/post-6291.css', '../../../css/elementor/post-220.css', '../../../css/elementor/global.css', '../../../css/elementor/frontend.min.css', '../../../css/elementor/style.css'],
})
export class PagePlanosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
