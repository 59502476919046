import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../service/user.service';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css'],
})
export class HeaderNewComponent implements OnInit {
  
  @Output() onSignOut: EventEmitter<void> = new EventEmitter<void>();

  linksStatic: Array<{ icon: string; text: string; callback: any }> = [
    // {icon: 'home', text: 'Minhas Escolas', callback: () => this.minhasCompras()},
    { icon: 'favorite', text: 'Escolas Favoritas', callback: () => this.minhasEscolas() },
    // {icon: 'add', text: 'Avaliações', callback: () => this.avaliacoes()},
    // {icon: 'add', text: 'Cadastrar Escola', callback: () => this.cadastroEscola()},
    { icon: 'question_answer', text: 'Fale com a SchoolAdvisor', callback: () => this.faleConosco() },
    { icon: 'badge', text: 'Perfil de Afinidade', callback: () => this.perfilAfinidade() },
  ];
  links = Object.create(this.linksStatic);
  showSearchButton = true;
  showFilters = false;

  constructor(public userService: UserService, 
    public router: Router, 
    private matDialog: MatDialog, 
    private http: HttpClient,
    public auth: AngularFireAuth) {
    userService.userDataEvent.subscribe((value) => {
      this.refreshMenu();
    });
  }

  private refreshMenu(): void {
    this.links = [];
    if (this.userService.master) {
      this.links.push({ icon: 'person_pin', text: 'Proprietários', callback: () => this.listaProprietario() });
      this.links.push({ icon: 'description', text: 'Relatório Matrículas', callback: () => this.reportMatriculas() });
      this.links.push({ icon: 'collections', text: 'Coleções', callback: () => this.treeColecao() });
    } else {
      for (const item of this.linksStatic) {
        this.links.push(item);
      }
    }
    if (this.userService.userData?.escola && this.userService.beta) {
      const cod_escola_array: number[] = this.userService.userData.escola;
      if (cod_escola_array.length === 1) {
        this.links.push({ icon: 'message', text: 'Chat', callback: () => this.chatLista(cod_escola_array[0]) });
      } else if (cod_escola_array.length > 1) {
        for (const cod_escola of cod_escola_array) {
          this.links.push({
            icon: 'message',
            text: `Chat (INEP ${cod_escola})`,
            callback: () => this.chatLista(cod_escola),
          });
        }
      }
    }
    if (this.userService.userData?.escola || this.userService.master) {
      this.links.push({ icon: 'group', text: 'Parceiros', callback: () => this.listaParceiro() });
      /*this.chatService.getChatRooms(this.userService.userData.escola).then(value => {
        console.log('value: ' + JSON.stringify(value));
        this.links.push({icon: 'message', text: 'Chats', callback: () => this.})
      });*/
    } else {
      this.links.forEach((value1, index) => {
        if (value1.text === 'Parceiros') {
          this.links.splice(index, 1);
        }
      });
    }
    this.matDialog.getDialogById('loginDialog')?.close();
  }

  minhasEscolas(): Promise<boolean> {
    return this.router.navigate(['minhas_escolas']);
  }

  perfilAfinidade(): Promise<boolean> {
    return this.router.navigate(['perfil-afinidade']);
  }

  listaProprietario(): Promise<boolean> {
    return this.router.navigate(['lista_proprietario']);
  }

  treeColecao(): Promise<boolean> {
    return this.router.navigate(['tree_colecao']);
  }

  listaParceiro(): Promise<boolean> {
    return this.router.navigate(['lista_parceiro']);
  }

  faleConosco(): Promise<boolean> {
    return this.router.navigate(['contato']);
  }

  chatLista(cod_escola: number): Promise<boolean> {
    return this.router.navigate(['chats', cod_escola, 'chat']);
  }

  toggleSearch() {
    this.showSearchButton = !this.showSearchButton;
    this.showFilters = !this.showFilters;
    // console.log('showSearchButton: ' + this.showSearchButton);
    //this.isSearchAnimation = true;
  }

  showSearch() {
    this.showFilters = !this.showFilters;
  }

  private reportMatriculas(): Promise<boolean> {
    // const url = 'https://us-central1-firesample-7bd56.cloudfunctions.net/report_escola_matricula';
    const url = 'http://localhost:5001/firesample-7bd56/us-central1/report_escola_matricula';

    this.userService
      .getCurrentUser()
      .getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + authToken });
        return this.http.post(url, {}, { responseType: 'arraybuffer', headers }).subscribe((response) => {
          const blob = new Blob([response as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const urlResponse = window.URL.createObjectURL(blob);
          const pwa = window.open(urlResponse);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
          }
        });
      });

    return null;
  }

  public login(event: Event): void {
    event.preventDefault();
    this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
  }

  async signOut(): Promise<void> {
    try {
      console.log('Saindo do sistema...');
      await this.auth.signOut();
      // Sign-out successful.
      this.onSignOut.emit();
      window.location.reload();
    } catch (e) {
      // An error happened.
      console.error('Ocorreu um erro ao sair do sistema!', e);
    }
  }

  ngOnInit(): void {}
}
