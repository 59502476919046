import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AngularFireDatabase} from '@angular/fire/database';
import {MatSnackBar} from '@angular/material/snack-bar';

interface Room {
  cod_escola: number;
  room_title: string;
  uid: string;
  roomname: string;
}

@Component({
  selector: 'app-roomlist',
  templateUrl: './roomlist.component.html',
  styleUrls: ['./roomlist.component.css']
})
export class RoomlistComponent implements OnInit {

  nickname = '';
  displayedColumns: string[] = ['room_title'];
  rooms: Room[] = [];
  roomname: string | null;
  isLoadingResults = true;
  cod_escola: number;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private db: AngularFireDatabase,
      private _snackBar: MatSnackBar,
      public datepipe: DatePipe) {
    // this.nickname = localStorage.getItem('nickname') || '';
    this.cod_escola = +this.activatedRoute.snapshot.paramMap.get('cod_escola');
  }

  ngOnInit(): void {
    try {
      this.db.database.ref(`/escolas/${this.cod_escola}/rooms/`).get().then(value => {
        // console.log('get done: ' + JSON.stringify(value));
        this.rooms = [];
        value.forEach(a => {
          const room = a.val();
          room.roomname = a.key;
          this.rooms.push(room);
        });
        this.isLoadingResults = false;
      });
    } catch (e) {
      if (e.message === 'Permission denied') {
        const message = 'Esse usuário não tem permissao para responder pela escola.';
        this._snackBar.open(message, 'Ok');
      }
    }
  }

  logout(): void {
    localStorage.removeItem('nickname');
    this.router.navigate(['/login']);
  }

  toggleChat(roomname: string, cod_escola: number): void {
    if (this.roomname === roomname) {
      this.roomname = null;
    } else {
      this.roomname = roomname;
      this.cod_escola = cod_escola;
    }
  }
}
