import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'urlFixer'
})
export class UrlFixerPipe implements PipeTransform {

  transform(value: string): string {
    if (value == null) {
      return null;
    }

    return 'http://' + value.replace(/^(https?|ftp):\/\//, '');
  }

}
