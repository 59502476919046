import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import { ScriptLoaderService } from '../../service/script-loader.service';
import * as moment from 'moment';

@Component({
  selector: 'app-escola-parcerias-e-certificacoes',
  templateUrl: './escola-parcerias-e-certificacoes.component.html',
  styleUrls: ['./escola-parcerias-e-certificacoes.component.css'],
})
export class EscolaParceriasECertificacoesComponent implements OnInit, AfterViewInit {
  @Input() escola: Escola;
  galerryImages: { src: string; alt: string }[] = [];

  constructor(private scriptLoader: ScriptLoaderService) {}

  ngOnInit(): void {
    if (this.showSelo(this.escola.prevencao_acidentes, this.escola.prevencao_acidentes_validade)) {
      this.galerryImages.push({ src: 'images/selos/logo-prevencao_acidentes.png', alt: 'Selo escolas pela prevenção de acidentes' });
    }

    if (this.showSelo(this.escola.diversidade, this.escola.diversidade_validade)) {
      this.galerryImages.push({ src: 'images/selos/logo-diversidade.png', alt: 'Selo escolas pela diversidade' });
    }

    if (this.escola.clima) {
      this.galerryImages.push({ src: 'images/selos/logo-clima.png', alt: 'Selo escolas pelo clima' });
    }

    if (this.escola.google_education) {
      this.galerryImages.push({ src: 'images/selos/google_education.png', alt: 'Google Education' });
    }

    if (this.escola.oebi) {
      this.galerryImages.push({ src: 'images/oebi/logo-oebi.png', alt: 'Bilíngue OEBI' });
    }

    /*if (this.escola.supercerebro) {
      this.galerryImages.push({ src: 'images/supercerebro/logo-supercerebro.png', alt: 'Super Cérebro' });
    }*/

    if (this.escola.geekie) {
      this.galerryImages.push({ src: 'images/geekie/logo-geekie.png', alt: 'Geekie' });
    }

    if (this.escola.poliedro) {
      this.galerryImages.push({ src: 'images/selos/poliedro.png', alt: 'Poliedro' });
    }
  }

  ngAfterViewInit() {
    this.scriptLoader
      .loadScript('fotorama', '../../../js/new/bootstrap.bundle.min.js')
      .then(() => {
        let itemsLogos = document.querySelectorAll('.carousel.logos .carousel-item');

        itemsLogos.forEach((el) => {
          const minPerSlide = 6;
          let next = el.nextElementSibling;
          for (var i = 1; i < minPerSlide; i++) {
            if (!next) {
              // wrap carousel by using first child
              next = itemsLogos[0];
            }
            let cloneChild = next.cloneNode(true);
            el.appendChild((cloneChild as Element).children[0]);
            next = next.nextElementSibling;
          }
        });
      })
      .catch((error) => console.error('Erro ao carregar o script Fotorama:', error));
  }

  showSelo(value: boolean, date: string) {
    return value && (!date || moment(date).isAfter(moment()));
  }
}
