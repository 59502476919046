import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {MatToolbar} from '@angular/material/toolbar';

@Component({
  selector: 'app-button-to-top',
  templateUrl: './button-to-top.component.html',
  styleUrls: ['./button-to-top.component.css']
})
export class ButtonToTopComponent implements OnInit {

  @Input() container: MatSidenavContainer;
  @ViewChild('backTopDiv', {static: true}) backTopDiv: HTMLDivElement;
  visible = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  toTop(): void {
    if (this.container) {
      this.container.scrollable.scrollTo({top: 0, behavior: 'smooth'});
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    // console.log(`scrollEvent: ${event}`);
    // console.log(`window.pageYOffset: ${window.pageYOffset}`);
    // console.log(`document.documentElement.scrollTop: ${document.documentElement.scrollTop}`);
    // console.log(`document.body.scrollTop: ${document.body.scrollTop}`);
    this.visible = (window.pageYOffset > 50);
  }

}
