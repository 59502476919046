import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import firebase from 'firebase/app';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

export type UploadFileType = 'images' | 'icons' | 'main';

export interface UploadFileData {
  cod_escola: number;
  src?: string;
  thumb?: string;
  type: UploadFileType;
}

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() codEscola: number;
  @Input() type: UploadFileType = 'images';
  @Input() path;
  @Output() uploadEvent = new EventEmitter<UploadFileData>();

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(private storage: AngularFireStorage) {
  }

  ngOnInit(): void {
    this.startUpload();
  }

  startUpload(path?: string): void {

    // The storage path
    if (path == null) {
      path = `${this.type}/${Date.now()}_${this.file.name}`;
    }

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(),
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        // await this.db.collection('files').add({
        //   downloadURL: this.downloadURL,
        //   path,
        //   cod_escola: this.codEscola,
        //   type: this.type,
        //   file_name: this.file.name
        // });

        const data: UploadFileData = {
          cod_escola: this.codEscola,
          src: this.downloadURL,
          thumb: this.downloadURL,
          type: this.type
        };
        if (this.codEscola == null) {
          this.uploadEvent.emit(data);
        }else if (this.type === 'icons') {
          firebase.functions().httpsCallable('escola_update')({
            codigo: this.codEscola,
            icon_path: this.downloadURL // TODO: use thumbnail
          }).then(() => {
            // console.log(`Images registered with escola ${this.codEscola}`);
            this.uploadEvent.emit(data);
          });
        } else {
          firebase.functions().httpsCallable('escola_gallery_add')({
            downloadURL: this.downloadURL,
            path,
            cod_escola: this.codEscola,
            type: this.type,
            file_name: this.file.name
          }).then(() => {
            // console.log(`Images registered with escola ${this.codEscola}`);
            this.uploadEvent.emit(data);
          });
        }

        // console.log(`Added file ${this.file} at ${path}`);
      }),
    );
  }

  isActive(snapshot): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
