<h1 mat-dialog-title>Motivo para bloqueio</h1>
<div mat-dialog-content>
  <p>Qual o motivo para bloquear essa escola?</p>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>Motivo</mat-label>
    <textarea class="w-100" matInput [(ngModel)]="escola.motivo_desativada"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelar()">Cancelar</button>
  <button mat-button [mat-dialog-close]="escola" cdkFocusInitial>Ok</button>
</div>
