import {Component, Input, OnInit} from '@angular/core';
import {Diretorio} from '../../service/Diretorio';
import {EscolaService} from '../../service/escola.service';
import {ActivatedRoute, Router} from '@angular/router';
import firebase from 'firebase';

@Component({
  selector: 'app-page-diretorio',
  templateUrl: './page-diretorio.component.html',
  styleUrls: ['./page-diretorio.component.css']
})
export class PageDiretorioComponent implements OnInit {

  @Input() diretorio: Diretorio;
  loading = true;
  oldLista: { path: string, codigo: number }[] =
    [
      {path: '10 Escolas no Morumbi', codigo: 49},
      {path: 'Escolas Infantis Região Itaim / SP', codigo: 56},
      {path: 'Escolas São José dos Campos', codigo: 62},
      {path: 'Escolas Low Cost em São Paulo', codigo: 61},
      {path: 'Escolas Infantis Região Moema / SP', codigo: 57},
      {path: '5 Escolas na Vila Mariana com Mensalidades até R$3.000', codigo: 51},
      {path: '5 Escolas perto do Ibirapuera', codigo: 53},
      {path: '5 Escolas no Campo Belo com até 600 Alunos', codigo: 52},
      {path: '10 Escolas Premiadas no Xadrez', codigo: 48},
      {path: 'Escolas parceiras Cel.Lep', codigo: 63},
      {path: 'Escolas Japonesas em São Paulo', codigo: 60},
      {path: 'Escolas Associadas à UNESCO no Distrito Federal', codigo: 54},
      {path: 'Vale a pena você olhar essas escolas', codigo: 67},
      {path: 'TOP 5 escolas de Belo Horizonte no ENEM', codigo: 66},
      {path: 'Escolas Infantis SP Brooklin, Campo Belo e Berrini', codigo: 59},
      {path: 'Escolas Infantis Região Vl Mariana / SP', codigo: 58},
      {path: 'Escolas Autorizadas pelo International Baccalaureate no Rio de Janeiro', codigo: 55},
      {path: '5 Escolas Bilingues em Alphaville e Tamboré', codigo: 50},
    ];

  constructor(private escolaService: EscolaService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(routeParams => {

      const snapshot: any = activatedRoute.snapshot;
      const segments = snapshot._urlSegment.segments;
      const currentPath = segments[0].path;

      if (currentPath === 'lista') {
        let codDiretorio = snapshot.params.codigo;
        const nomeDiretorio = snapshot.params.nome;
        this.oldLista.forEach(value => {
          if (value.path.startsWith(nomeDiretorio)) {
            codDiretorio = '' + value.codigo;
          }
        });

        if (this.diretorio == null) {
          this.refresh(+codDiretorio);
        } else {
          this.loadEscola();
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      if (currentPath === 'diretorio') {
        const codDiretorio = snapshot.params.codigo;
        // const nomeDiretorio = this.activatedRoute.snapshot.paramMap.get('nome');

        if (this.diretorio == null) {
          this.refresh(+codDiretorio);
        } else {
          this.loadEscola();
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }

  ngOnInit(): void {
  }

  private async refresh(codDiretorio: number = null): Promise<any> {
    let codigo = codDiretorio;
    if (codigo == null) {
      codigo = this.diretorio?.codigo;
    }
    return firebase.functions().httpsCallable('diretorio')({codigo})
      .then(value => {
        this.diretorio = value.data[0];

        if (this.diretorio.escola == null) {
          this.loadEscola();
        }
      });
  }

  private async loadEscola(): Promise<any> {
    this.loading = true;
    return firebase.functions().httpsCallable('diretorio_escola')({cod_diretorio: this.diretorio.codigo})
      .then(value => {
        this.loading = false;
        this.diretorio.escola = value.data; // It could be []
      });
  }

  map(): void {
    this.escolaService.diretorio.setValue([this.diretorio]);
    this.router.navigate(['mapa']);
  }

}
