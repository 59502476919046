<div *ngIf="escola.premium" id="mais-sobre-escola" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Saiba mais sobre a escola</h2>
  </div>
  <div *ngIf="hasData()" class="col-12 d-flex flex-column school-profile mb-4">
    <div class="list-school-profile">
      <ul>
        <li class="font-size-regular text-lightgray">{{ this.escola.abordagem_pedagogica }}</li>
        <li *ngIf="this.escola.indices_total_alunos" class="font-size-regular text-lightgray">{{ this.escola.indices_total_alunos }} alunos</li>
        <li class="font-size-regular text-lightgray">{{ this.escola.grupo_educacional }}</li>
        <li *ngIf="this.escola.ano_fundacao" class="font-size-regular text-lightgray">Fundada em {{ this.escola.ano_fundacao }}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="!hasData()" class="col-12 d-flex flex-column school-profile mb-4">
    Não Informado
  </div>

  <div *ngIf="this.escola.sobre_escola && escola.premium" class="row about-school content-more" [class.active]="isExpanded">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.sobre_escola }}</p>
    </div>
    <div class="col-12">
      <div class="action">
        <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
      </div>
    </div>
  </div>
</div>
