<div class="col-12 cards" *ngIf="editing">
  <h2>Eventos e processo de ingresso</h2>

  <form id="eventForm">
    <button type="button" class="btn btn-primary mb-3" (click)="addItem()">Adicionar Item</button>
    <ul class="list-group" id="eventList">
      <li *ngFor="let evento of escola.eventos; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
        <div class="w-100">
          <div class="mb-2">
            <label for="titulo{{ i }}">Título</label>
            <input type="text" class="form-control" [(ngModel)]="evento.titulo" placeholder="Título" name="titulo{{ i }}" id="titulo{{ i }}" />
          </div>
          <div class="mb-2">
            <label for="link{{ i }}">Link</label>
            <input type="url" class="form-control" [(ngModel)]="evento.link" placeholder="Link" name="link{{ i }}" id="link{{ i }}" />
          </div>
          <div class="mb-2">
            <label for="data{{ i }}">Data</label>
            <input type="date" class="form-control" [(ngModel)]="evento.data" name="data{{ i }}" id="data{{ i }}" />
          </div>
          <div class="input-group mb-2">
            <label for="thumb{{ i }}">Thumb</label>
            <input type="file" class="form-control" (change)="previewThumb($event, i)" id="thumb{{ i }}" />
            <div class="thumb-container">
              <img *ngIf="evento.thumb" [src]="evento.thumb" alt="Thumb" class="mb-2" style="width: 250px; max-height: 300px;" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <button type="button" class="btn btn-success mt-3" (click)="saveItems()">Salvar</button> -->
    <!-- button type="button" class="btn btn-primary mb-3" (click)="addItem()">Adicionar Item</button -->
  </form>
</div>
