import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Parceiro} from '../../service/Parceiro';
import firebase from 'firebase/app';
import {AnalyticsService} from '../../service/analytics.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-parceiros',
  templateUrl: './page-parceiros.component.html',
  styleUrls: ['./page-parceiros.component.scss']
})
export class PageParceirosComponent implements OnInit, AfterViewInit {

  parceiroArray: Array<Parceiro> = [];

  constructor(
    private analytics: AnalyticsService,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle('SchoolAdvisor | Parceiros');
    this.meta.updateTag({name: 'description', content: 'Conheça os Parceiros da SchoolAdvisor para sua Escola e diferencie-se.'});
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    firebase.functions().httpsCallable('parceiro')({flag_exibir_pagina_escola: false, flag_exibir_pagina_parceiro: true}).then(value => {
      if (value == null) {
        this.parceiroArray = null;
      } else {
        this.parceiroArray = value.data;
      }
    });
  }

}
