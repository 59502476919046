import {Injectable, Optional, SkipSelf} from '@angular/core';
import firebase from 'firebase/app';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from '../navigation/auth/login-dialog/login-dialog.component';

export enum ProdutoNome {
  perfil_escola = 'Perfil de escola',
  comparativo = 'Comparativo Master',
  diagnostico = 'Diagnóstico de Abordagem',
  duvidas = 'Dúvidas'
}

export enum BuyReturn {
  ok,
  login_required,
  fail
}

export interface Produto {
  nome: string;
  title: string;
  subtitle: string;
  descricao: string;
  image?: string;
}

export interface Plano {
  nome: ProdutoNome;
  subtitle?: string;
  valor?: number;
  descricao: string[];
  nota?: string[];
  disabledMessage?: string;
  icon?: string;
  buttonLabel?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PagseguroService {

  public plano = {
    perfil_escola: {
      nome: ProdutoNome.perfil_escola,
      subtitle: 'Comprar o perfil de uma escola',
      descricao: [
        'Você receberá informações completas sobre essa escola, são até 110 itens de avaliação objetiva!'
      ],
      valor: 80,
      nota: ['* Prazo de entrega: até 10 dias úteis'],
      // icon: 'images/school/cursos-extra-esportes.svg'
    },
    comparativo: {
      nome: ProdutoNome.comparativo,
      subtitle: 'Comprar o compartivo de 5 escolas',
      descricao: [
        'Quero comprar uma curadoria para me ajudar a escolher 5 escolas e o comparativo das 5 escolas.'
      ],
      valor: 550,
      nota: ['Você receberá os próximos-passos por e-mail.', '*Prazo de entrega: até 15 dias úteis.'],
      // icon: 'images/school/cursos-extra-esportes.svg'
    },
    diagnostico: {
      nome: ProdutoNome.diagnostico,
      descricao: [
        '',
      ],
      nota: null,
      valor: null,
      buttonLabel: 'Contato'
      // icon: 'images/school/cursos-extra-esportes.svg'
    },
    duvidas: {
      nome: ProdutoNome.duvidas,
      descricao: [
        'Ainda tenho dúvidas. Quero falar com vocês.',
      ],
      nota: null,
      valor: null,
      buttonLabel: 'Contato'
      // icon: 'images/school/cursos-extra-esportes.svg'
    }
  };

  public planos: Plano[] = [
    // this.plano.perfil_escola, // removido 2021-12-13
    this.plano.comparativo,
    this.plano.duvidas
  ];

  constructor(
    private userService: UserService,
    private route: Router,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Optional() @SkipSelf() parentModule?: PagseguroService) {
    if (parentModule) {
      throw new Error('PagseguroService is already loaded. Import it in AppModule only');
    }
  }

  async buy(produto: ProdutoNome, codEscola: number): Promise<BuyReturn> {
    let ret: BuyReturn = BuyReturn.fail;
    const user = await this.userService.getCurrentUser();
    if (!user) {
      ret = BuyReturn.login_required;
    } else {
      await firebase.functions().httpsCallable('pagseguroCheckoutId')
      ({
        tipo: produto,
        reference: codEscola,
      })
        .then(value => {
          // console.log(value);
          // console.log(value.data);
          if (value?.data) {
            const callback = {
              success: (transactionCode) => {
                // Insira os comandos para quando o usuário finalizar o pagamento.
                // O código da transação estará na variável "transactionCode"
                console.log('Compra feita com sucesso, código de transação: ' + transactionCode);
              },
              abort: () => {
                // Insira os comandos para quando o usuário abandonar a tela de pagamento.
                console.log('abortado');
              }
            };
            // @ts-ignore
            const isOpenLightbox = window.PagSeguroLightbox(value.data, callback);
            console.log(`PagSeguro Lightbox(${value.data}): ${isOpenLightbox}`);
            ret = BuyReturn.ok;
          } else {
            ret = BuyReturn.fail;
          }
        });
    }
    return ret;
  }

  async checkout(_plano: Plano, codEscola: number): Promise<any> {
    const promise = new Promise(((resolve, reject) => {
      const user = this.userService.getCurrentUser();

      if (_plano.valor == null) {
        this.route.navigate(['contato', '6']).then(
          value => this.matDialog.closeAll()
        );
        resolve(null);
        // href = '/contato?p=0';
      } else if (user) {
        this.buy(_plano.nome, codEscola).then(
          value => {
            let message;
            switch (value) {
              case BuyReturn.ok:
                break;
              case BuyReturn.login_required:
                message = 'Para fazer uma compra é necessário fazer login.';
                console.log(message);
                this.snackBar.open(message, 'Ok', {duration: 2000});
                this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
                break;
              case BuyReturn.fail:
                message = 'Não foi possível fazer o checkout do produto.';
                console.log(message);
                this.snackBar.open(message, 'Ok', {duration: 2000});
                break;
            }
            resolve(null);
          });
      } else {
        this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
        resolve(null);
      }
    }));
    return promise;
  }

// curl -X POST \
//   'https://ws.sandbox.pagseguro.uol.com.br/v2/checkout?email=email@email.com&token=token' \
//   -H 'Content-Type: application/x-www-form-urlencoded' \
//   -d 'email=email%40email.com' +
// '&token=token' +
// '&currency=BRL' +
// '&itemId1=001' +
// '&itemDescription1=Item%201' +
// '&itemAmount1=169.90' +
// '&itemQuantity1=1' +
// '&reference=124665c23f7896adff508377925' +
// '&senderName=Natalie%20Green' +
// '&senderAreaCode=51' +
// '&senderPhone=988888888' +
// '&senderEmail=emaildocomprador@pagseguro.com.br' +
// '&shippingAddressRequired=true' +
// '&extraAmount=0.00'

// tslint:disable-next-line:max-line-length
// curl -X POST 'https://ws.sandbox.pagseguro.uol.com.br/v2/checkout?email=schooladvisor.financeiro@gmail.com&token=59765CC154744A9EA66B49B4BBC5E905' -H 'Content-Type: application/x-www-form-urlencoded' -d 'email=email%40email.com&token=token&currency=BRL&itemId1=001&itemDescription1=Item%201&itemAmount1=169.90&itemQuantity1=1&reference=124665c23f7896adff508377925&senderName=Natalie%20Green&senderAreaCode=51&senderPhone=988888888&senderEmail=emaildocomprador@pagseguro.com.br&shippingAddressRequired=true&extraAmount=0.00'

}
