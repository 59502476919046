import { Component, OnInit } from '@angular/core';
import { BuyReturn, PagseguroService, Plano } from '../../service/pagseguro.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-page-funciona',
  templateUrl: './page-funciona.component.html',
  styleUrls: ['./page-funciona.component.css', '../../../css/elementor/post-7141.css', '../../../css/elementor/post-220.css', '../../../css/elementor/global.css', '../../../css/elementor/frontend.min.css', '../../../css/elementor/style.css'],
})
export class PageFuncionaComponent implements OnInit {
  // TODO: Mudar o texto para ser igual ao do perfil da escola
  // TODO: INCLUIR o prazo de entrega nos 2 textos

  saving = false;

  constructor(private route: Router, private snackBar: MatSnackBar, private userService: UserService, private matDialog: MatDialog, public pagseguroService: PagseguroService) {}

  ngOnInit(): void {}

  checkout(_plano: Plano): void {
    const user = this.userService.getCurrentUser();

    if (_plano.valor == null) {
      this.route.navigate(['contato', 1]);
      // href = '/contato?p=0';
    } else if (user) {
      switch (_plano) {
        case this.pagseguroService.plano.duvidas:
          this.route.navigate(['contato', 1]);
          break;
        case this.pagseguroService.plano.comparativo:
          this.route.navigate(['contato', 8]);
          break;
        case this.pagseguroService.plano.perfil_escola:
          this.route.navigate(['contato', 9]);
          break;
        case this.pagseguroService.plano.diagnostico:
          this.route.navigate(['contato', 1]);
          break;
      }
      // this.saving = true;
      // this.pagseguroService.buy(plano.nome, null).then(
      //   value => {
      //     let message;
      //     switch (value) {
      //       case BuyReturn.ok:
      //         break;
      //       case BuyReturn.login_required:
      //         message = 'Para fazer uma compra é necessário fazer login.';
      //         console.log(message);
      //         this.snackBar.open(message, 'Ok', {duration: 2000});
      //         this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
      //         break;
      //       case BuyReturn.fail:
      //         message = 'Não foi possível fazer o checkout do produto.';
      //         console.log(message);
      //         this.snackBar.open(message, 'Ok', {duration: 2000});
      //         break;
      //     }
      //     this.saving = false;
      //   });
    } else {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
    }
  }
}
