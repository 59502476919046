<div style="width: 100%; height: 100px"></div>
<div>
  <table mat-table
         class="w-100 mat-elevation-z8"
         [dataSource]="filteredData"
  >
    <ng-container matColumnDef="codigo">
      <th mat-header-cell *matHeaderCellDef>Cód.</th>
      <td mat-cell *matCellDef="let row">{{row.codigo}}</td>
    </ng-container>

    <ng-container matColumnDef="usuario_nome">
      <th mat-header-cell *matHeaderCellDef>Usuario</th>
      <td mat-cell *matCellDef="let row">{{row.usuario_nome}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>e-mail</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <ng-container matColumnDef="escola_nome">
      <th mat-header-cell *matHeaderCellDef>Escola</th>
      <td mat-cell *matCellDef="let row">
        <a [routerLink]="['/escolas', row.cod_escola, row.escola_nome | urlNormalizer]" target="_blank" title="veja mais" class="veja-mais">{{row.escola_nome}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="inep">
      <th mat-header-cell *matHeaderCellDef>INEP</th>
      <td mat-cell *matCellDef="let row">
        <a [routerLink]="['/escolas', row.cod_escola, row.escola_nome | urlNormalizer]" target="_blank" title="veja mais" class="veja-mais">{{row.inep}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="permissao">
      <th mat-header-cell *matHeaderCellDef>Permissão</th>
      <td mat-cell *matCellDef="let row">{{row.permissao}}</td>
    </ng-container>

    <ng-container matColumnDef="creation_date">
      <th mat-header-cell *matHeaderCellDef>Criado</th>
      <td mat-cell *matCellDef="let row">{{row.creation_date | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button (click)="setPermission(row,'editar')">Editar</button>
        <button mat-raised-button (click)="setPermission(row,'bloquear')">Bloquear</button>
        <button mat-raised-button (click)="removePermission(row)">Apagar</button>
      </td>
    </ng-container>
    <!--    <button mat-button>Ok</button>-->

    <!--        <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">-->
    <!--          <th mat-header-cell *matHeaderCellDef>{{col.title}}</th>-->
    <!--          <td mat-cell *matCellDef="let row">{{row[col.field]}}</td>-->
    <!--        </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-progress-bar
    mode="indeterminate" style="display: none"
    [style.display]="this.loading? '' : 'none'"></mat-progress-bar>
</div>
