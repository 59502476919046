<div id="events" class="row events mb-5" *ngIf="escola.premium && hasEvents">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Inscreva-se em eventos e processo de ingresso</h2>
  </div>
  <div *ngFor="let evento of nextsEvents" class="col-12 mb-4">
    <!-- ITEM EVENT -->
    <div class="event border-lightgray">
      <div class="row align-items-center">
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4">
          <figure>
            <img [src]="evento.thumb" alt="Evento" />
          </figure>
        </div>
        <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-9 col-sm-9 col-8 date">
          <p class="text-black font-size-biggest mb-0">{{ getDia(evento.data) }}</p>
          <p class="text-black font-size-regular mb-0">{{ getMes(evento.data) }}</p>
        </div>
        <div class="col-xxl-5 col-xl-5 col-lg-9 col-md-12 col-sm-12 col-12 description">
          <p class="font-size-regular text-lightgray">{{ evento.titulo }}</p>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="action d-flex align-items-center justify-content-end">
            <a [href]="evento.link" target="_blank" class="btn background-green text-white font-size-regular semibold" title="Inscreva-se">Inscreva-se</a>
          </div>
        </div>
      </div>
    </div>
    <!-- END ITEM EVENT -->
  </div>
  <!-- <div class="col-12">
    <div class="action text-end">
      <a href="" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">mostrar mais</a>
    </div>
  </div> -->
</div>
