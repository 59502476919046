import {Component, Input, OnInit} from '@angular/core';
import {Escola} from '../../service/Escola';

@Component({
  selector: 'app-button-parceiro',
  templateUrl: './button-parceiro.component.html',
  styleUrls: ['./button-parceiro.component.scss']
})
export class ButtonParceiroComponent implements OnInit {

  @Input() escola: Escola;

  constructor() {
  }

  ngOnInit(): void {
  }

}
