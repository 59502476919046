<div class="mb-5 programs-certifications" *ngIf="galerryImages.length > 0">
  <div class="container">
    <!-- TITLE -->
    <div class="col-12 text-center mb-5">
      <h2 class="text-black font-size-biggest mb-0">Parcerias, Associações, Certificações e Acreditações da escola</h2>
    </div>
    <!-- END TITLE -->

    <!-- SLIDER LOGOS -->
    <div id="recipeLogos" class="carousel slide slider logos" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div *ngFor="let image of galerryImages; let i = index" [class.active]="i === 0" class="carousel-item">
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
            <figure class="ads">
              <img [src]="image.src" [alt]="image.alt" style="height: 50%;" />
            </figure>
          </div>
        </div>
      </div>
      <a class="control carousel-control-prev" href="#recipeLogos" role="button" data-bs-slide="prev" (click)="preventDefault($event)">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="control carousel-control-next" href="#recipeLogos" role="button" data-bs-slide="next" (click)="preventDefault($event)">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <!-- END SLIDER LOGOS -->
  </div>
</div>
