import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperComponent} from 'swiper/angular';
import {SwiperOptions} from 'swiper';
import {UserService} from '../../service/user.service';
import firebase from 'firebase';
import {Parceiro} from '../../service/Parceiro';
import {AnalyticsService} from '../../service/analytics.service';

@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss']
})
export class ParceiroComponent implements OnInit, AfterViewInit {

  @Input() flag_exibir_pagina_escola: boolean | null = null;
  @Input() flag_exibir_pagina_parceiro: boolean | null = null;
  parceiroArray: Array<Parceiro> = [];
  @ViewChild('swiperComponent', {static: false}) swiperComponent: SwiperComponent;
  width: number;
  loadingParceiro = false;
  loadingEscolaFilter = false;
  loadingMessage: string = null;
  config: SwiperOptions = {
    breakpoints: {
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      1280: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      },
      1: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      }
    },
    autoHeight: true,
    lazy: true,
    navigation: false,
    slidesPerView: 5,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }
  };

  constructor(public userService: UserService,
              public analytics: AnalyticsService) {
  }

  ngOnInit(): void {
    firebase.functions().httpsCallable('parceiro')({
      flag_exibir_pagina_escola: this.flag_exibir_pagina_escola,
      flag_exibir_pagina_parceiro: this.flag_exibir_pagina_parceiro
    }).then(value => {
      // console.log(JSON.stringify(value));
      if (value == null) {
        this.parceiroArray = null;
      } else {
        this.parceiroArray = value.data;
      }
    });
  }

  previousClick(): void {
    this.swiperComponent.swiperRef.slidePrev();
  }

  nextClick(): void {
    this.swiperComponent.swiperRef.slideNext();
  }

  ngAfterViewInit(): void {
  }
}
