<div style="width: 100%; height: 100%; background: #e1e1e1" *ngIf="!escola">
  <img style="margin: auto" src="/images/loading.png" class="start-50 translate-middle position-relative top-50 ld ld-fade" />
</div>

<main role="main" *ngIf="escola">
  <section class="page-school">
    <!-- SCHOOL - INFO -->
    <div class="container">
      <section class="bg-section feature-info">
        <div class="row">
          <div class="gallery col-12 d-grid">
            <app-escola-gallery style="height: 100%" (clickMessageLink)="this.requestInfo('gallery')" #escolaGallery [escola]="this.escola" *ngIf="escola" [editing]="this.editing"></app-escola-gallery>
            <app-uploader [codEscola]="this.escola.codigo" *ngIf="editing" (uploadDone)="markForReload()"> </app-uploader>
          </div>
          <div class="name col-xl-7 col-lg-5 col-md-12 col-sm-12" *ngIf="escola">
            <div class="row">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <ng-template [ngIf]="!editing">
                  <h1 [ngStyle]="{ 'text-decoration': escola.data_desativada ? 'line-through' : '' }">{{ this.escola.nome }}</h1>
                  <span *ngIf="escola.data_desativada">{{ "Escola desativada " + moment(escola.data_desativada).format("DD/MM/YYYY") }}</span>
                </ng-template>
                <ng-template [ngIf]="editing">
                  <mat-form-field floatLabel="always">
                    <input class="nome-escola-edit" matInput required maxLength="100" placeholder="Nome" [(ngModel)]="escola.nome" />
                  </mat-form-field>
                </ng-template>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              </div>
            </div>
            <div class="row">
              <div class="logo-school col-xl-2 col-lg-3 col-md-3 col-sm-3 col-4">
                <img src="{{ escola.icon_path ? escola.icon_path : '/images/icone-site-150x150.png' }}" alt="Logo" />
                <input type="file" style="display: none" #iconFileInput (change)="chooseIconFile($event)" />
                <app-upload-task *ngIf="iconFile" type="icons" [codEscola]="escola?.codigo" [file]="iconFile" (uploadEvent)="uploadIcon($event)"></app-upload-task>
                <button mat-mini-fab *ngIf="editing" class="position-absolute top-0 end-0" title="Novo Logo" (click)="iconFileInput.click()">
                  <mat-icon>image</mat-icon>
                </button>
              </div>
              <div class="rating-school col-xl-10 col-lg-9 col-md-9 col-sm-9 col-8">
                <div class="verified" *ngIf="escola.premium == true"><span class="fas fa-medal"></span><span>Escola Parceira</span></div>
                <!-- div class="comments">
                  <a href="javascript:" (click)="scrollTo('rate')" class="smooth-scroll">
                    <img src="images/school/star.svg" alt="Estrela" /><span
                      ><strong>{{ escola.rate_geral }}</strong>
                    </span>
                  </a>
                  <a href="javascript:" (click)="showRankingCompleto()" class="ranking" title="Ranking Geral" data-toggle="modal" data-target="#rankingModal">ver ranking completo</a>
                </div -->
              </div>
            </div>
            <!-- div class="row">
              <div class="infos col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="subtitle d-flex">
                  <h3>Níveis de ensino</h3>
                </div>
                <div class="items d-flex flex-wrap" *ngIf="escola.nivel_ensino">
                  <div class="item" *ngIf="escola.nivel_ensino.indexOf('Berçário') >= 0"><img src="images/school/bercario.svg" alt="Berçário" /><span>Berçário</span></div>
                  <div class="item" *ngIf="escola.nivel_ensino.indexOf('Infantil') >= 0"><img src="images/school/infantil.svg" alt="Infantil" /><span>Infantil</span></div>
                  <div class="item" *ngIf="escola.nivel_ensino.indexOf('Fundamental I') >= 0"><img src="images/school/fundamental.svg" alt="Fundamental I" /><span>Fundamental I</span></div>
                  <div class="item" *ngIf="escola.nivel_ensino.indexOf('Fundamental II') >= 0"><img src="images/school/fundamental.svg" alt="Fundamental II" /><span>Fundamental II</span></div>
                  <div class="item" *ngIf="escola.nivel_ensino.indexOf('Ensino Médio') >= 0"><img src="images/school/medio.svg" alt="Médio" /><span>Médio</span></div>
                </div>
                <ng-template [ngIf]="editing">
                  <li><a [routerLink]="[]" fragment="nivel_ensino" title="Níveis de Ensino" class="smooth-scroll level"> Editar Níveis de Ensino </a></li>
                </ng-template>
              </div>

              <div class="infos col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <app-escola-score *ngIf="this.escola.score_info" [expanded]="true" [score]="this.escola.score_info" [canExpand]="false"> </app-escola-score>
              </div>
            </div -->
          </div>
          <school-detail-more-info #schoolDetailMoreInfo [escola]="escola" [escolaBeneficio]="escolaBeneficio" [editing]="editing" [requestInfo]="requestInfo"> </school-detail-more-info>

          <school-qualifications #schoolQualifications [escola]="escola" [familyChoice]="familyChoice" [tagControl]="tagControl" [editing]="editing"> </school-qualifications>

          <ng-template [ngIf]="editing">
            <div class="infos-adicionais col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-top" *ngIf="escola">
              <h2 *ngIf="userService.master == true" style="font-weight: bold; font-size: 16px; margin-bottom: 6px; padding-left: 10px">Visão do especialista</h2>
              <div class="row align-items-baseline" *ngIf="userService.master == true">
                <div class="infos col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row">
                  <app-text-bound *ngIf="editing" [editing]="editing" [(text)]="escola.visao_especialista"></app-text-bound>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </section>
      <section class="content-other-infos">
        <div class="row" fxFlex>
          <section class="content-info" fxFlex.gt-sm="100">
            <!-- Dados Principais -->
            <section id="dados" class="bg-section col-12">
              <div class="subtitle">
                <h2>Dados Principais</h2>
              </div>
              <div class="content" *ngIf="escola">
                <div fxLayout="row" fxLayout.lt-sm="column">
                  <div class="row" fxFlex.lt-sm="100">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <div class="item">
                        <h3>Abordagem Pedagógica</h3>
                        <p>
                          <ng-template [ngIf]="!editing">
                            {{ escola.abordagem_pedagogica }}
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.abordagem_pedagogica">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of this.abordagemPedagogica" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </p>
                      </div>
                    </div>
                    <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <div class="item">
                        <h3>Horário de Abertura / Fechamento</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.horario_funcionamento_min == null || escola.horario_funcionamento_max == null ? "" : escola.horario_funcionamento_min.substring(0, 5) + " / " + escola.horario_funcionamento_max.substring(0, 5) }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <div floatLabel="always" fxLayout="row">
                            <input matInput [value]="escola?.horario_funcionamento_min?.slice(0, 5)" [ngxTimepicker]="this.timeMin" [format]="24" fxFlex="50" readonly />
                            &nbsp;/&nbsp;
                            <input matInput [value]="escola?.horario_funcionamento_max?.slice(0, 5)" [ngxTimepicker]="this.timeMax" [format]="24" fxFlex="50" readonly />
                            <ngx-material-timepicker #timeMin (timeSet)="horarioFuncionamento('min', $event)"></ngx-material-timepicker>
                            <ngx-material-timepicker #timeMax (timeSet)="horarioFuncionamento('max', $event)"></ngx-material-timepicker>
                          </div>
                        </ng-template>
                      </div>
                    </div -->
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <div class="item">
                        <h3>País de Origem</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.pais }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input matInput maxLength="20" [(ngModel)]="escola.pais" />
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <div class="item mr-4">
                        <h3>Ano de Fundação</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.ano_fundacao }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input matInput mask="0000" showMaskTyped="true" [(ngModel)]="escola.ano_fundacao" />
                        </ng-template>
                      </div>
                    </div>
                    <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.modalidade_ensino?.length > 0 || editing">
                      <div class="item mr-4">
                        <h3>Modalidade de Ensino</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.modalidade_ensino }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.modalidade_ensino">
                            <mat-option *ngFor="let item of modalidadeEnsino" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                    </div -->
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.grupo_educacional || editing">
                      <div class="item">
                        <h3>Grupo</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.grupo_educacional }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input matInput maxLength="100" [(ngModel)]="escola.grupo_educacional" />
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.indices_total_alunos || editing">
                      <div class="item">
                        <h3>Total de alunos</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.indices_total_alunos | number : "1.0-0" : "pt-BR" }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input maxLength="10" matInput mask="separator.0" thousandSeparator="." [(ngModel)]="escola.indices_total_alunos" />
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.indices_quantidade_salas || editing">
                      <div class="item">
                        <h3>Total de salas</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.indices_quantidade_salas }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input maxLength="10" matInput [ngModel]="escola.indices_quantidade_salas | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.indices_quantidade_salas = this.decimalParser($event)" />
                        </ng-template>
                      </div>
                    </div>
                    <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.indices_media_horas_por_dia || editing">
                      <div class="item">
                        <h3>Média de horas/aula por dia</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.indices_media_horas_por_dia ? escola.indices_media_horas_por_dia + "h" : "" }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <input maxLength="10" matInput [ngModel]="escola.indices_media_horas_por_dia | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.indices_media_horas_por_dia = this.decimalParser($event)" />
                        </ng-template>
                      </div>
                    </div -->
                    <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="escola?.indices_preparatorio_vestibular || editing">
                      <div class="item">
                        <h3>Foco Vestibular</h3>
                        <ng-template [ngIf]="!editing">
                          <p *ngIf="(escola?.premium || escola?.compra) && !this.editing; else showMessagePremium">
                            {{ escola.indices_preparatorio_vestibular == true ? "Sim" : "Não" }}
                          </p>
                          <ng-template #showMessagePremium>
                            <a href="javascript:" class="class-premium" (click)="requestInfo('indices_preparatorio_vestibular')">{{ this.messagePremium }}</a>
                          </ng-template>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.indices_preparatorio_vestibular">
                            <mat-option></mat-option>
                            <mat-option [value]="false">Não</mat-option>
                            <mat-option [value]="true">Sim</mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                    </div -->
                  </div>
                </div>
                <div class="slider col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!editing && outrasUnidades?.length > 0 && (escola?.premium || escola?.compra)">
                  <h3>Outras unidades</h3>

                  <div class="slider-unidades">
                    <ngx-slick-carousel style="max-width: 50vw" class="slider-responsive" #slickModal="slick-carousel" [config]="sliderUnidades">
                      <article ngxSlickItem class="card-slide slick-slide" style="max-width: 100px" *ngFor="let item of outrasUnidades">
                        <a [routerLink]="['/escolas', item.cod_unidade, item.unidade_nome | urlNormalizer]" title="veja mais" class="veja-mais">
                          <div class="image">
                            <img [style]="item.data_desativada ? 'filter: grayscale(100%);' : ''" src="{{ item.unidade_logo_path ? item.unidade_logo_path : 'images/thumb-list.jpg' }}" alt="Escola" />
                          </div>
                          <div class="name">
                            <p class="text-bound" [style]="item.data_desativada ? 'text-decoration: line-through;' : ''">{{ item.unidade_nome }}</p>
                          </div>
                        </a>
                      </article>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </section>

            <!-- Perfil da Escola -->
            <section id="perfil-do-estudantes" class="bg-section col-12" *ngIf="editing">
              <div class="subtitle">
                <h2>Perfil dos Estudantes</h2>
              </div>
              <div class="content" *ngIf="escola">
                <div fxLayout="row" fxLayout.lt-sm="column">
                  <div class="row" fxFlex.lt-sm="100">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Gênero</h3>
                        </div>
                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h3>Masculino</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_masculino" />
                            </ng-template>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h3>Feminino</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_feminino" />
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Diversidade e inclusão</h3>
                        </div>
                        <div class="row">
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Brancos</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_raca_branca" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Negros</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_raca_preta" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Indígenas</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_raca_indigena" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Neurodivergentes</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.matriculas_neurodivergentes" />
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Nacionalidade</h3>
                          <button class="btn btn-danger btn-sm ml-2 btn-add-universidade" (click)="addNacionalidadeEstudantes()">Adicionar</button>
                        </div>
                        <div class="row">
                          <div *ngFor="let nacionalidade of escola.matriculas_nacionalidades; let i = index" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="card card-matricula mb-2">
                              <div class="card-body d-flex justify-content-between align-items-center gap-10" style="flex-direction: column">
                                <div class="mb-2" style="width: 100%">
                                  <label for="nacionalidade_estudante_{{ i }}">Nacionalidade</label>
                                  <input type="text" class="form-control" [(ngModel)]="nacionalidade.nacionalidade" placeholder="Título" name="nacionalidade_estudante_{{ i }}" id="nacionalidade_estudante_{{ i }}" />
                                </div>
                                <div class="mb-2" style="width: 100%">
                                  <label for="nacionalidade_estudante_quantidade_{{ i }}">Quantidade</label>
                                  <input type="number" class="form-control" [(ngModel)]="nacionalidade.quantidade" placeholder="Título" name="nacionalidade_estudante_quantidade_{{ i }}" id="nacionalidade_estudante_quantidade_{{ i }}" />
                                </div>
                                <button class="btn btn-danger btn-sm ml-2 btn-delete-universidade" (click)="removeNacionalidadeEstudantes(i)">X</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Universidades/escolas onde aprova</h3>
                          <button class="btn btn-danger btn-sm ml-2 btn-add-universidade" (click)="addMatriculasUniversidades()">Adicionar</button>
                        </div>
                        <div class="row">
                          <div *ngFor="let universidade of escola.matriculas_universidades; let i = index" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="card card-matricula mb-2">
                              <div class="card-body d-flex justify-content-between align-items-center gap-10" style="flex-direction: column">
                                <div class="mb-2" style="width: 100%">
                                  <label for="titulo{{ i }}">Título</label>
                                  <input type="text" class="form-control" [(ngModel)]="universidade.title" placeholder="Título" name="titulo{{ i }}" id="titulo{{ i }}" />
                                </div>
                                <div class="mb-2" style="width: 100%">
                                  <label for="quantidade{{ i }}">Quantidade</label>
                                  <input type="number" class="form-control" [(ngModel)]="universidade.quantidade" placeholder="Título" name="quantidade{{ i }}" id="quantidade{{ i }}" />
                                </div>
                                <!-- <div class="mb-2" style="width: 100%">
                                  <label for="quantidade{{ i }}">Ano Referencia</label>
                                  <input type="number" class="form-control" [(ngModel)]="universidade.ano_referencia" placeholder="Ano Referencia" name="ano_referencia{{ i }}" id="ano_referencia{{ i }}" />
                                </div> -->
                                <button class="btn btn-danger btn-sm ml-2 btn-delete-universidade" (click)="removeMatriculasUniversidades(i)">X</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <h3>Ano de referência</h3>
                        <ng-template [ngIf]="editing">
                          <input matInput maxLength="20" [(ngModel)]="escola.matriculas_ano_referencia" />
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="perfil-do-estudantes" class="bg-section col-12" *ngIf="editing">
              <div class="subtitle">
                <h2>Perfil dos Professores</h2>
              </div>
              <div class="content" *ngIf="escola">
                <div fxLayout="row" fxLayout.lt-sm="column">
                  <div class="row" fxFlex.lt-sm="100">
                    <!-- div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <h3>Ano de referência</h3>
                        <ng-template [ngIf]="editing">
                          <input matInput maxLength="20" [(ngModel)]="escola.professores_ano_referencia" />
                        </ng-template>
                      </div>
                    </div -->
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Gênero</h3>
                        </div>
                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h3>Masculino</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_masculino" />
                            </ng-template>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h3>Feminino</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_feminino" />
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Diversidade e inclusão</h3>
                        </div>
                        <div class="row">
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Brancos</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_raca_branca" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Negros</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_raca_preta" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Indígenas</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_raca_indigena" />
                            </ng-template>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <h3>Neurodivergentes</h3>
                            <ng-template [ngIf]="editing">
                              <input matInput maxLength="20" [(ngModel)]="escola.professores_raca_nd" />
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="item">
                        <div class="subtitle">
                          <h3 class="sub-divisao">Nacionalidade</h3>
                          <button class="btn btn-danger btn-sm ml-2 btn-add-universidade" (click)="addNacionalidadeProfessores()">Adicionar</button>
                        </div>
                        <div class="row">
                          <div *ngFor="let nacionalidade of escola.professores_nacionalidades; let i = index" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="card card-matricula mb-2">
                              <div class="card-body d-flex justify-content-between align-items-center gap-10" style="flex-direction: column">
                                <div class="mb-2" style="width: 100%">
                                  <label for="nacionalidade_professor_{{ i }}">Nacionalidade</label>
                                  <input type="text" class="form-control" [(ngModel)]="nacionalidade.nacionalidade" placeholder="Título" name="nacionalidade_professor_{{ i }}" id="nacionalidade_professor_{{ i }}" />
                                </div>
                                <div class="mb-2" style="width: 100%">
                                  <label for="nacionalidade_professor_quantidade_{{ i }}">Quantidade</label>
                                  <input type="number" class="form-control" [(ngModel)]="nacionalidade.quantidade" placeholder="Título" name="nacionalidade_professor_quantidade_{{ i }}" id="nacionalidade_professor_quantidade_{{ i }}" />
                                </div>
                                <button class="btn btn-danger btn-sm ml-2 btn-delete-universidade" (click)="removeNacionalidadeProfessores(i)">X</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Fim Perfil da Escola -->

            <!-- Sobre -->
            <!-- section id="sobre-regiao" class="bg-section col-12 w-100" *ngIf="this.editing">
              <div class="subtitle">
                <h2>Sobre a região</h2>
              </div>
              <div class="content">
                <div class="row row-eq-height">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100">
                    <app-text-bound class="w-100" *ngIf="escola?.premium || escola?.compra || editing" [editing]="editing && userService.master == true" [(text)]="escola.sobre_regiao"></app-text-bound>
                  </div>
                </div>
              </div>
            </section -->
            <!-- Sobre -->
            <section id="sobre" class="bg-section col-12 w-100" cards video *ngIf="escola?.sobre_escola || this.editing">
              <div class="subtitle">
                <h2>Sobre</h2>
              </div>
              <div class="content">
                <div class="row row-eq-height">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100">
                    <p *ngIf="!(escola?.premium || escola?.compra) && !this.editing">
                      <a href="javascript:" class="class-premium" (click)="requestInfo('sobre_escola')">{{ this.messagePremium }}</a>
                    </p>
                    <app-text-bound class="w-100" *ngIf="escola?.premium || escola?.compra || editing" [editing]="editing" [(text)]="escola.sobre_escola"></app-text-bound>
                  </div>
                </div>
              </div>
            </section>

            <!-- Material Didático -->
            <section id="material" class="bg-section col-12 cards" *ngIf="showPremiumFields || escola?.compra || editing">
              <div class="subtitle">
                <h2>Selos Ensino</h2>
              </div>
              <app-text-bound *ngIf="false && (escola?.premium || escola?.compra || editing)" [(text)]="escola.material_didatico_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="row row-eq-height mb-2">
                  <div class="col-12">
                    <h3>Sistema de ensino</h3>
                    <ng-template [ngIf]="editing">
                      <input maxLength="20" matInput [(ngModel)]="escola.qual_sistema_ensino" />
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="false" class="row row-eq-height">
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/material-didatico-apostilado.svg" alt="Material apostilado" />
                      <h3>Material apostilado</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">{{ escola.material_didatico_apostilado == true ? "Sim" : "Não" }}</p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('material_didatico_apostilado')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.material_didatico_apostilado">
                          <mat-option></mat-option>
                          <mat-option [value]="false">Não</mat-option>
                          <mat-option [value]="true">Sim</mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/material-didatico-sistema-ensino.svg" alt="Sistema de ensino" />
                      <h3>Sistema de ensino</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          {{ escola.qual_sistema_ensino }}
                        </p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('qual_sistema_ensino')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <input maxLength="20" matInput [(ngModel)]="escola.qual_sistema_ensino" />
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/material-didatico-valor.svg" alt="Incluso na mensalidade" />
                      <h3>Incluso na mensalidade</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">{{ escola.material_didatico_incluso_mensalidade == true ? "Sim" : "Não" }}</p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('material_didatico_incluso_mensalidade')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.material_didatico_incluso_mensalidade">
                          <mat-option></mat-option>
                          <mat-option [value]="false">Não</mat-option>
                          <mat-option [value]="true">Sim</mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div class="row row-eq-height">
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" style="min-height: 120px" *ngIf="escola.geekie || this.editing">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img class="w-100" src="images/geekie/logo-geekie.png" style="width: 90px !important; max-height: fit-content" alt="Geekie" />
                      <ng-template [ngIf]="this.editing">
                        <mat-checkbox [(ngModel)]="escola.geekie"></mat-checkbox>
                      </ng-template>
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" style="min-height: 120px" *ngIf="escola.poliedro || this.editing">
                    <a href="https://www.guiaparaescolas.com.br/post/poliedro-sistema-de-ensino" target="_blank" class="item d-flex flex-column align-items-center justify-content-center">
                      <img class="w-100" src="images/selos/poliedro.png" style="width: 90px !important; max-height: fit-content" alt="Poliedro" />
                      <ng-template [ngIf]="this.editing">
                        <mat-checkbox [(ngModel)]="escola.poliedro"></mat-checkbox>
                      </ng-template>
                    </a>
                  </div>


                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" style="min-height: 120px" *ngIf="escola.google_education || this.editing">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img class="w-100" src="images/selos/google_education.png" style="width: 90px !important; max-height: fit-content" alt="Google Education" />
                      <ng-template [ngIf]="this.editing">
                        <mat-checkbox [(ngModel)]="escola.google_education"></mat-checkbox>
                      </ng-template>
                    </div>
                  </div>

                </div>
              </div>
            </section>

            <!-- Níveis de Ensino -->
            <section id="nivel_ensino" class="bg-section col-12 niveis cards">
              <div class="subtitle">
                <h2>Níveis de Ensino</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.nivel_ensino_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="item d-flex align-items-center" *ngIf="escola.bercario_periodos_disponiveis?.length > 0 || editing">
                  <div class="image d-flex justify-content-center flex-column">
                    <img src="images/school/bercario.svg" alt="Berçário" />
                    <small>Berçário</small>
                  </div>
                  <div class="info">
                    <div class="row">
                      <div [ngClass]="{ 'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12': showPremiumFields || escola?.compra }">
                        <h3>Períodos disponíveis</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.bercario_periodos_disponiveis?.join(", ") }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.bercario_periodos_disponiveis" multiple>
                            <mat-option *ngFor="let item of turnos" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                      <ng-template [ngIf]="escola.bercario_periodos_disponiveis?.length > 0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Processo seletivo</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showProcesso">{{ escola.bercario_processo_admissao }}</p>
                            <ng-template #showProcesso>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('bercario_processo_admissao')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.bercario_processo_admissao">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of processoSeletivo" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Máx. de Alunos por turma</h3>
                          <ng-template [ngIf]="!editing">
                            <p>
                              {{ escola.bercario_alunos_por_turma }}
                            </p>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.bercario_alunos_por_turma | number : '1.0-0' : 'pt-BR'" (ngModelChange)="escola.bercario_alunos_por_turma = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Profissionais em sala</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.bercario_profissionais_em_sala }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('bercario_profissionais_em_sala')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.bercario_profissionais_em_sala | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.bercario_profissionais_em_sala = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Mensalidade</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              <app-blur-display blurText="R$ 00.000,00" fieldName="bercario_mensalidade_media" sendEventMail="true" [buttonCaption]="'Ver mensalidade'" [escola]="this.escola" *ngIf="escola.bercario_mensalidade_media; else showMessagePremium">
                                {{ escola.bercario_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                              </app-blur-display>
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('bercario_mensalidade_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.bercario_mensalidade_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.bercario_mensalidade_media = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Matrícula</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="bercario_matricula_media" sendEventMail="true" [buttonCaption]="'Ver matrícula'" [escola]="this.escola" *ngIf="escola.bercario_matricula_media; else showMessagePremium">
                              {{ escola.bercario_matricula_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('bercario_matricula_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.bercario_matricula_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.bercario_matricula_media = this.decimalParser($event)" />
                          </ng-template>
                        </div -->
                        <div *ngIf="false && (showPremiumFields || escola?.compra)" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Quantidade de professores total</h3>
                          <ng-template [ngIf]="editing">
                            <input matInput [ngModel]="escola.bercario_professores_total" />
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="item d-flex align-items-center" *ngIf="escola.infantil_periodos_disponiveis?.length > 0 || editing">
                  <div class="image d-flex justify-content-center flex-column">
                    <img src="images/school/infantil.svg" alt="Infantil" />
                    <small>Infantil</small>
                  </div>
                  <div class="info d-flex flex-row">
                    <div class="row">
                      <div [ngClass]="{ 'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12': showPremiumFields || escola?.compra }">
                        <h3>Períodos disponíveis</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.infantil_periodos_disponiveis?.join(", ") }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.infantil_periodos_disponiveis" multiple>
                            <mat-option *ngFor="let item of turnos" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                      <ng-template [ngIf]="escola.infantil_periodos_disponiveis?.length > 0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Processo seletivo</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.infantil_processo_admissao }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('infantil_processo_admissao')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.infantil_processo_admissao">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of processoSeletivo" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Máx. de Alunos por turma</h3>
                          <ng-template [ngIf]="!editing">
                            <p>
                              {{ escola.infantil_alunos_por_turma }}
                            </p>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.infantil_alunos_por_turma | number : '1.0-0' : 'pt-BR'" (ngModelChange)="escola.infantil_alunos_por_turma = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Profissionais em sala</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.infantil_profissionais_em_sala }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('infantil_profissionais_em_sala')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.infantil_profissionais_em_sala | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.infantil_profissionais_em_sala = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Mensalidade</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="infantil_mensalidade_media" sendEventMail="true" [buttonCaption]="'Ver mensalidade'" [escola]="this.escola" *ngIf="escola.infantil_mensalidade_media; else showMessagePremium">
                              {{ escola.infantil_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('infantil_mensalidade_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.infantil_mensalidade_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.infantil_mensalidade_media = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Matrícula</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="infantil_matricula_media" sendEventMail="true" [buttonCaption]="'Ver matrícula'" [escola]="this.escola" *ngIf="escola.infantil_matricula_media; else showMessagePremium">
                              {{ escola.infantil_matricula_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('infantil_matricula_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.infantil_matricula_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.infantil_matricula_media = this.decimalParser($event)" />
                          </ng-template>
                        </div -->
                        <div *ngIf="false && (showPremiumFields || escola?.compra)" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Quantidade de professores total</h3>
                          <ng-template [ngIf]="editing">
                            <input matInput [ngModel]="escola.infantil_professores_total" />
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="item d-flex align-items-center" *ngIf="escola.fundamental1_periodos_disponiveis?.length > 0 || editing">
                  <div class="image d-flex justify-content-center flex-column">
                    <img src="images/school/fundamental.svg" alt="Fundamental I" />
                    <small>Fundamental I</small>
                  </div>
                  <div class="info d-flex flex-row">
                    <div class="row">
                      <div [ngClass]="{ 'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12': showPremiumFields || escola?.compra }">
                        <h3>Períodos disponíveis</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.fundamental1_periodos_disponiveis?.join(", ") }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.fundamental1_periodos_disponiveis" multiple>
                            <mat-option *ngFor="let item of turnos" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                      <ng-template [ngIf]="escola.fundamental1_periodos_disponiveis?.length > 0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Processo seletivo</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.fundamental1_processo_admissao }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental1_processo_admissao')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.fundamental1_processo_admissao">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of processoSeletivo" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Máx. de Alunos por turma</h3>
                          <ng-template [ngIf]="!editing">
                            <p>
                              {{ escola.fundamental1_alunos_por_turma }}
                            </p>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental1_alunos_por_turma | number : '1.0-0' : 'pt-BR'" (ngModelChange)="escola.fundamental1_alunos_por_turma = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Profissionais em sala</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.fundamental1_profissionais_em_sala }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental1_profissionais_em_sala')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental1_profissionais_em_sala | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental1_profissionais_em_sala = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Mensalidade</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="fundamental1_mensalidade_media" sendEventMail="true" [buttonCaption]="'Ver mensalidade'" [escola]="this.escola" *ngIf="escola.fundamental1_mensalidade_media; else showMessagePremium">
                              {{ escola.fundamental1_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental1_mensalidade_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental1_mensalidade_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental1_mensalidade_media = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Matrícula</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="fundamental1_matricula_media" sendEventMail="true" [buttonCaption]="'Ver matrícula'" [escola]="this.escola" *ngIf="escola.fundamental1_matricula_media; else showMessagePremium">
                              {{ escola.fundamental1_matricula_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental1_matricula_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental1_matricula_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental1_matricula_media = this.decimalParser($event)" />
                          </ng-template>
                        </div -->
                        <div *ngIf="false && (showPremiumFields || escola?.compra)" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Quantidade de professores total</h3>
                          <ng-template [ngIf]="editing">
                            <input matInput [ngModel]="escola.fundamental_ai_professores_total" />
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="item d-flex align-items-center" *ngIf="escola.fundamental2_periodos_disponiveis?.length > 0 || editing">
                  <div class="image d-flex justify-content-center flex-column">
                    <img src="images/school/fundamental.svg" alt="Fundamental II" />
                    <small>Fundamental II</small>
                  </div>
                  <div class="info d-flex flex-row">
                    <div class="row">
                      <div [ngClass]="{ 'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12': showPremiumFields || escola?.compra }">
                        <h3>Períodos disponíveis</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.fundamental2_periodos_disponiveis?.join(", ") }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.fundamental2_periodos_disponiveis" multiple>
                            <mat-option *ngFor="let item of turnos" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                      <ng-template [ngIf]="escola.fundamental2_periodos_disponiveis?.length > 0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Processo seletivo</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.fundamental2_processo_admissao }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental2_processo_admissao')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.fundamental2_processo_admissao">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of processoSeletivo" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Máx. de Alunos por turma</h3>
                          <ng-template [ngIf]="!editing">
                            <p>
                              {{ escola.fundamental2_alunos_por_turma }}
                            </p>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental2_alunos_por_turma | number : '1.0-0' : 'pt-BR'" (ngModelChange)="escola.fundamental2_alunos_por_turma = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Profissionais em sala</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.fundamental2_profissionais_em_sala }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental2_profissionais_em_sala')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental2_profissionais_em_sala | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental2_profissionais_em_sala = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Mensalidade</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="fundamental2_mensalidade_media" sendEventMail="true" [buttonCaption]="'Ver mensalidade'" [escola]="this.escola" *ngIf="escola.fundamental2_mensalidade_media; else showMessagePremium">
                              {{ escola.fundamental2_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental2_mensalidade_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental2_mensalidade_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental2_mensalidade_media = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Matrícula</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="fundamental2_matricula_media" sendEventMail="true" [buttonCaption]="'Ver matrícula'" [escola]="this.escola" *ngIf="escola.fundamental2_matricula_media; else showMessagePremium">
                              {{ escola.fundamental2_matricula_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('fundamental2_matricula_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.fundamental2_matricula_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.fundamental2_matricula_media = this.decimalParser($event)" />
                          </ng-template>
                        </div -->
                        <div *ngIf="false && (showPremiumFields || escola?.compra)" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Quantidade de professores total</h3>
                          <ng-template [ngIf]="editing">
                            <input matInput [ngModel]="escola.fundamental_af_professores_total" />
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="item d-flex align-items-center" *ngIf="escola.ensino_medio_periodos_disponiveis?.length > 0 || editing">
                  <div class="image d-flex justify-content-center flex-column">
                    <img src="images/school/medio.svg" alt="Médio" />
                    <small>Médio</small>
                  </div>
                  <div class="info d-flex flex-row">
                    <div class="row">
                      <div [ngClass]="{ 'col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12': showPremiumFields || escola?.compra }">
                        <h3>Períodos disponíveis</h3>
                        <ng-template [ngIf]="!editing">
                          <p>{{ escola.ensino_medio_periodos_disponiveis?.join(", ") }}</p>
                        </ng-template>
                        <ng-template [ngIf]="editing">
                          <mat-select [(ngModel)]="escola.ensino_medio_periodos_disponiveis" multiple>
                            <mat-option *ngFor="let item of turnos" [value]="item">
                              {{ item }}
                            </mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                      <ng-template [ngIf]="escola.ensino_medio_periodos_disponiveis?.length > 0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Processo seletivo</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.ensino_medio_processo_admissao }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('ensino_medio_processo_admissao')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <mat-select [(ngModel)]="escola.ensino_medio_processo_admissao">
                              <mat-option></mat-option>
                              <mat-option *ngFor="let item of processoSeletivo" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Máx. de Alunos por turma</h3>
                          <ng-template [ngIf]="!editing">
                            <p>
                              {{ escola.ensino_medio_alunos_por_turma }}
                            </p>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.ensino_medio_alunos_por_turma | number : '1.0-0' : 'pt-BR'" (ngModelChange)="escola.ensino_medio_alunos_por_turma = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Profissionais em sala</h3>
                          <ng-template [ngIf]="!editing">
                            <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                              {{ escola.ensino_medio_profissionais_em_sala }}
                            </p>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('ensino_medio_profissionais_em_sala')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.ensino_medio_profissionais_em_sala | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.ensino_medio_profissionais_em_sala = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Mensalidade</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="ensino_medio_mensalidade_media" sendEventMail="true" [buttonCaption]="'Ver mensalidade'" [escola]="this.escola" *ngIf="escola.ensino_medio_mensalidade_media; else showMessagePremium">
                              {{ escola.ensino_medio_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('ensino_medio_mensalidade_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.ensino_medio_mensalidade_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.ensino_medio_mensalidade_media = this.decimalParser($event)" />
                          </ng-template>
                        </div>
                        <!-- div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                          <h3>Matrícula</h3>
                          <ng-template [ngIf]="!editing">
                            <app-blur-display blurText="R$ 00.000,00" fieldName="ensino_medio_matricula_media" sendEventMail="true" [buttonCaption]="'Ver matrícula'" [escola]="this.escola" *ngIf="escola.ensino_medio_matricula_media; else showMessagePremium">
                              {{ escola.ensino_medio_matricula_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}
                            </app-blur-display>
                            <ng-template #showMessagePremium>
                              <a href="javascript:" class="class-premium" (click)="requestInfo('ensino_medio_matricula_media')">{{ this.messagePremium }}</a>
                            </ng-template>
                          </ng-template>
                          <ng-template [ngIf]="editing">
                            <input maxLength="10" matInput [ngModel]="escola.ensino_medio_matricula_media | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.ensino_medio_matricula_media = this.decimalParser($event)" />
                          </ng-template>
                        </div -->
                        <div *ngIf="false && (showPremiumFields || escola?.compra)" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <h3>Quantidade de professores total</h3>
                          <ng-template [ngIf]="editing">
                            <input matInput [ngModel]="escola.ensino_medio_professores_total" />
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="bg-section col-12 feature-info">
              <div class="subtitle">
                <h2>Eventos</h2>
              </div>
              <app-escola-eventos-edit [escola]="escola" [editing]="editing"></app-escola-eventos-edit>
            </section>

            <!-- Vídeos -->
            <section id="videos" class="bg-section col-12 cards video" *ngIf="escola?.videos?.length > 0 || this.editing">
              <div class="subtitle">
                <h2>Intro sobre os vídeos da escola</h2>
              </div>
              <div class="content">
                <div class="row row-eq-height">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100">
                    <app-text-bound class="w-100" *ngIf="escola?.premium || escola?.compra || editing" [editing]="editing" [(text)]="escola.video_intro"></app-text-bound>
                  </div>
                </div>
              </div>
              <div class="subtitle">
                <h2>Vídeos</h2>
              </div>
              <div class="content" *ngIf="escola">
                <div class="row row-eq-height">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <p *ngIf="!(escola?.premium || escola?.compra) && !this.editing">
                      <a href="javascript:" class="class-premium" (click)="requestInfo('videos')">{{ this.messagePremium }}</a>
                    </p>
                    <div class="item d-flex flex-column align-items-center justify-content-center" *ngIf="escola?.premium || escola?.compra || this.editing">
                      <div class="slider-videos">
                        <ng-template [ngIf]="editing">
                          <mat-form-field floatLabel="always">
                            <mat-chip-list #videoList aria-label="Videos">
                              <mat-chip *ngFor="let item of escola.videos" selectable removable (removed)="removeVideo(item)">
                                {{ item }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                            <input matInput class="w-100" placeholder="Videos" [formControl]="videosControl" [matChipInputFor]="videoList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addVideo($event)" />
                          </mat-form-field>
                          <mat-error style="text-align: right; top: 0" *ngIf="!videosControl.valid"> Site/URL inválido (apenas url do YoutTube) </mat-error>
                        </ng-template>
                        <ng-template [ngIf]="escola?.videos?.length > 0">
                          <article class="card-slide" *ngFor="let item of escola.videos" style="width: calc(50% - 26px); height: 300px; float: left; margin: 12px">
                            <div class="iframe-video" style="height: 300px">
                              <iframe width="560" height="315" [src]="item | safe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>
                            </div>
                          </article>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Infraestrutura e Segurança -->
            <section id="infraestrutura" class="bg-section col-12 list">
              <div class="subtitle">
                <h2>Infraestrutura e Segurança</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.infra_sobre" [editing]="editing"></app-text-bound>
              <div class="content">
                <div class="row">
                  <div class="item col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h3>Espaços coletivos</h3>
                    <ng-template [ngIf]="!editing">
                      <p *ngIf="escola.infra_area_verde == true">Área Verde</p>
                      <p *ngIf="escola.infra_auditorio == true">Auditório</p>
                      <p *ngIf="escola.infra_banheiro_adaptado == true">Banheiro Infantil</p>
                      <p *ngIf="escola.infra_berco == true">Berços ou Camas Individuais</p>
                      <p *ngIf="escola.infra_biblioteca == true">Biblioteca</p>
                      <p *ngIf="escola.infra_camera == true">Câmeras de Segurança</p>
                      <p *ngIf="escola.infra_cantina == true">Cantina</p>
                      <p *ngIf="escola.infra_enfermaria == true">Enfermaria</p>
                      <p *ngIf="escola.infra_estacionamento == true">Estacionamento</p>
                      <p *ngIf="escola.infra_lactario == true">Lactário</p>
                      <p *ngIf="escola.infra_parque == true">Parque</p>
                      <p *ngIf="escola.infra_patio_coberto == true">Pátio Coberto</p>
                      <p *ngIf="escola.infra_patio_descoberto == true">Pátio Descoberto</p>
                      <p *ngIf="escola.infra_piscina == true">Piscina</p>
                      <p *ngIf="escola.infra_quadra_coberta == true">Quadra Coberta</p>
                      <p *ngIf="escola.infra_quadra_descoberta == true">Quadra Descoberta</p>
                      <p *ngIf="escola.infra_refeitorio == true">Refeitório</p>
                      <p *ngIf="escola.infra_transporte == true">Transporte Escolar</p>
                      <p *ngIf="escola.infra_viveiro == true">Viveiro de Animais</p>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_area_verde">Área Verde</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_auditorio">Auditório</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_banheiro_adaptado">Banheiro Infantil</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_berco">Berços ou Camas Individuais</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_biblioteca">Biblioteca</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_camera">Câmeras de Segurança</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_cantina">Cantina</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_enfermaria">Enfermaria</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_estacionamento">Estacionamento</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_lactario">Lactário</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_parque">Parque</mat-checkbox>
                      </p>
                      <!--                      <p><mat-checkbox [(ngModel)]="escola.infra_parque_coberto">Parque Coberto</mat-checkbox></p>-->
                      <!--                      <p><mat-checkbox [(ngModel)]="escola.infra_parque_descoberto">Parque Descoberto</mat-checkbox></p>-->
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_patio_coberto">Pátio Coberto</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_patio_descoberto">Pátio Descoberto</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_piscina">Piscina</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_quadra_coberta">Quadra Coberta</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_quadra_descoberta">Quadra Descoberta</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_refeitorio">Refeitório</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_transporte">Transporte Escolar</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_viveiro">Viveiro de Animais</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                  <div class="item col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h3>Laboratório e Salas</h3>
                    <ng-template [ngIf]="!editing">
                      <p *ngIf="escola.infra_laboratorio_ciencias == true">Laboratório de Ciências</p>
                      <p *ngIf="escola.infra_laboratorio_robotica == true">Laboratório de Robótica/Maker</p>
                      <p *ngIf="escola.infra_sala_amamentacao == true">Sala de Amamentação</p>
                      <p *ngIf="escola.infra_sala_artes == true">Sala de Artes</p>
                      <p *ngIf="escola.infra_sala_climatizada == true">Salas Climatizadas</p>
                      <p *ngIf="escola.infra_sala_esportes == true">Sala de Esportes</p>
                      <p *ngIf="escola.infra_sala_leitura == true">Sala de Leitura</p>
                      <p *ngIf="escola.infra_sala_musica == true">Sala de Música</p>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_laboratorio_ciencias">Laboratório de Ciências</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_laboratorio_robotica">Laboratório de Robótica/Maker</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_amamentacao">Sala de Amamentação</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_artes">Sala de Artes</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_climatizada">Salas Climatizadas</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_esportes">Sala de Esportes</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_leitura">Sala de Leitura</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.infra_sala_musica">Sala de Música</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                  <div class="item col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h3>Acessibilidade</h3>
                    <ng-template [ngIf]="!editing">
                      <p *ngIf="escola.acessibilidade_infra_vao_livre == true">Vão Livre</p>
                      <p *ngIf="escola.acessibilidade_infra_corrimao == true">Corrimão</p>
                      <p *ngIf="escola.acessibilidade_infra_rampa == true">Rampa</p>
                      <p *ngIf="escola.acessibilidade_infra_elevador == true">Elevador</p>
                      <p *ngIf="escola.acessibilidade_infra_banheiro_deficiente == true">Banheiro PCD</p>
                      <p *ngIf="escola.acessibilidade_sinalizacao_piso_tatil == true">Piso Tátil</p>
                      <p *ngIf="escola.acessibilidade_sinalizacao_sonora == true">Sinalização Sonora</p>
                      <p *ngIf="escola.acessibilidade_sinalizacao_tatil == true">Sinalização Tátil</p>
                      <p *ngIf="escola.acessibilidade_sinalizacao_visual == true">Sinalização Visual</p>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_infra_vao_livre">Vão Livre</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_infra_corrimao">Corrimão</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_infra_rampa">Rampa</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_infra_elevador">Elevador</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_infra_banheiro_deficiente">Banheiro PCD</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_sinalizacao_piso_tatil">Piso Tátil</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_sinalizacao_sonora">Sinalização Sonora</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_sinalizacao_tatil">Sinalização Tátil</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.acessibilidade_sinalizacao_visual">Sinalização Visual</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>

                  <!--<div class="col-12">-->
                  <!--<div class="btn-mostrar-mais">-->
                  <!--<a href="#" title="Filtrar" data-toggle="modal" data-target="#infraModal">veja mais</a>-->
                  <!--</div>-->
                  <!--</div>-->
                </div>
              </div>
            </section>
            <!-- Vídeo 360 -->
            <section id="video360" class="bg-section col-12 cards video" *ngIf="escola?.video360 || (editing && userService.master == true)">
              <div class="subtitle">
                <h2>Tour Virtual</h2>
              </div>
              <div class="content" *ngIf="escola">
                <div class="row row-eq-height">
                  <ng-template [ngIf]="editing && userService.master == true">
                    <mat-form-field floatLabel="always">
                      <mat-label>Tour Virtual</mat-label>
                      <input matInput [(ngModel)]="escola.video360" />
                      <!--                                  [formControl]="videos360Control">-->
                      <!--                           <mat-error style="text-align: right; top: 0" *ngIf="!videos360Control.valid">-->
                      <!--                               Site/URL inválido (use o formato https://www.youtube.com/embed/...)-->
                      <!--                           </mat-error>-->
                    </mat-form-field>
                  </ng-template>
                  <ng-template [ngIf]="!editing">
                    <p *ngIf="!(escola?.premium || escola?.compra)">
                      <a href="javascript:" class="class-premium" (click)="requestInfo('video360')">{{ this.messagePremium }}</a>
                    </p>
                    <ng-template [ngIf]="escola?.premium || escola?.compra">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="escola.video360">
                        <div class="item d-flex flex-column align-items-center justify-content-center">
                          <div class="content-video">
                            <iframe width="560" height="315" [src]="escola.video360 | safe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </section>
            <!-- Acessibilidade -->
            <section *ngIf="false" id="acessibilidade" class="bg-section col-12 list">
              <div class="subtitle">
                <h2>Acessibilidade</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.acessibilidade_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="row">
                  <div class="item col-xl-4 col-lg-4 col-md-4 col-sm-12" *ngIf="escola.acessibilidade_qtde_salas > 0 || editing">
                    <h3>Quantidade de Salas com Acessibilidade</h3>
                    <ng-template [ngIf]="!editing">
                      <p>{{ escola.acessibilidade_qtde_salas }}</p>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <input maxLength="10" matInput [ngModel]="escola.acessibilidade_qtde_salas | number : '1.0-2' : 'pt-BR'" (ngModelChange)="escola.acessibilidade_qtde_salas = this.decimalParser($event)" />
                    </ng-template>
                  </div>
                </div>
              </div>
            </section>
            <!-- Recursos de Aprendizagem -->
            <section *ngIf="false" id="recursos" class="bg-section col-12 recursos">
              <div class="subtitle">
                <h2>Recursos de Aprendizagem</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.recursos_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="row">
                  <div class="item col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <h3>Recreativos</h3>
                    <ng-template [ngIf]="!editing">
                      <ng-template [ngIf]="recursosRecreativosGroup?.length > 0">
                        <p *ngIf="escola.recursos_recreativo_brinquedo_infantil == true">Brinquedo Infantil</p>
                        <p *ngIf="escola.recursos_recreativo_instrumentos == true">Instrumentos</p>
                        <p *ngIf="escola.recursos_recreativo_material_desportivo == true">Material Desportivo</p>
                      </ng-template>
                      <ng-template [ngIf]="recursosRecreativosGroup?.length == 0">
                        <p>Não possui recurso</p>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_recreativo_brinquedo_infantil">Brinquedo Infantil</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_recreativo_instrumentos">Instrumentos</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_recreativo_material_desportivo">Material Desportivo</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                  <div class="item col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <h3>Pedagógicos</h3>
                    <ng-template [ngIf]="!editing">
                      <ng-template [ngIf]="recursosPedagogicosGroup?.length > 0">
                        <p *ngIf="escola.recursos_pedagogico_jogos_educativos == true">Jogos Educativos</p>
                        <p *ngIf="escola.recursos_pedagogico_material_cientifico == true">Material Científico</p>
                        <p *ngIf="escola.recursos_pedagogico_material_cultural == true">Material Cultural</p>
                      </ng-template>
                      <ng-template [ngIf]="recursosPedagogicosGroup?.length == 0">
                        <p>Não possui recurso</p>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_pedagogico_jogos_educativos">Jogos Educativos</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_pedagogico_material_cientifico">Material Científico</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.recursos_pedagogico_material_cultural">Material Cultural</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                </div>
              </div>
            </section>
            <!-- Tecnologia e Recursos Digitais -->
            <section *ngIf="false" id="tecnologia" class="bg-section col-12 list">
              <div class="subtitle">
                <h2>Tecnologia e Recursos Digitais</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.tecnologia_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="row">
                  <div class="item col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <h3>Recursos Digitais</h3>
                    <ng-template [ngIf]="!editing">
                      <ng-template [ngIf]="tecnologiaRecursoGroup?.length > 0">
                        <p *ngIf="escola.tecnologia_recurso_lousa_digital == true">Lousa Digital</p>
                        <p *ngIf="escola.tecnologia_recurso_sala_multimidia == true">Sala Multimídia</p>
                        <p *ngIf="escola.tecnologia_recurso_desktop_aluno == true">Desktop Aluno</p>
                        <p *ngIf="escola.tecnologia_recurso_notebook_aluno == true">Notebook Aluno</p>
                        <p *ngIf="escola.tecnologia_recurso_tablet_aluno == true">Tablet Aluno</p>
                      </ng-template>
                      <ng-template [ngIf]="tecnologiaRecursoGroup?.length == 0">
                        <p>Não possui recurso</p>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_recurso_lousa_digital">Lousa Digital</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_recurso_sala_multimidia">Sala Multimídia</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_recurso_desktop_aluno">Desktop Aluno</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_recurso_notebook_aluno">Notebook Aluno</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_recurso_tablet_aluno">Tablet Aluno</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                  <div class="item col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <h3>Acesso à Internet</h3>
                    <ng-template [ngIf]="!editing">
                      <ng-template [ngIf]="tecnologiaInternetGroup?.length > 0">
                        <p *ngIf="escola.tecnologia_internet_ensino == true">Acesso à Internet para processos de ensino e aprendizagem</p>
                        <p *ngIf="escola.tecnologia_internet_aluno == true">Acesso à Internet para alunos</p>
                      </ng-template>
                      <ng-template [ngIf]="tecnologiaInternetGroup?.length == 0">
                        <p>Não possui recurso</p>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_internet_ensino">Acesso à Internet para processos de ensino e aprendizagem</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox [(ngModel)]="escola.tecnologia_internet_aluno">Acesso à Internet para alunos</mat-checkbox>
                      </p>
                    </ng-template>
                  </div>
                  <div class="item col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" *ngIf="escola?.tecnologia_aula_online">
                    <h3>Sistema de aula online</h3>
                    <ng-template [ngIf]="!editing">
                      <p>{{ escola.tecnologia_aula_online == true ? "Sim" : "Não" }}</p>
                    </ng-template>
                    <ng-template [ngIf]="editing">
                      <mat-select [(ngModel)]="escola.tecnologia_aula_online">
                        <mat-option></mat-option>
                        <mat-option [value]="false">Não</mat-option>
                        <mat-option [value]="true">Sim</mat-option>
                      </mat-select>
                    </ng-template>
                    <div style="min-height: 120px; margin-top: 20px" *ngIf="escola.google_education || this.editing">
                      <div class="item d-flex flex-column">
                        <img class="w-100" src="images/selos/google_education.png" style="width: 140px !important; max-height: fit-content" alt="Google Education" />
                        <ng-template [ngIf]="this.editing">
                          <mat-checkbox [(ngModel)]="escola.google_education" style="margin-top: 10px"></mat-checkbox>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="item col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" *ngIf="!escola?.tecnologia_aula_online">
                    <div style="min-height: 120px; margin-top: 20px" *ngIf="escola.google_education || this.editing">
                      <div class="item d-flex flex-column">
                        <img class="w-100" src="images/selos/google_education.png" style="width: 140px !important; max-height: fit-content" alt="Google Education" />
                        <ng-template [ngIf]="this.editing">
                          <mat-checkbox [(ngModel)]="escola.google_education" style="margin-top: 10px"></mat-checkbox>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Corpo Docente -->
            <section id="docentes" class="bg-section col-12 cards">
              <div class="subtitle">
                <h2>Corpo Docente</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.corpo_docente_sobre" [editing]="editing"></app-text-bound>
              <div class="content" *ngIf="escola">
                <div class="row row-eq-height mb-2">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                    <h3>% de docentes Graduados?</h3>
                    <ng-template [ngIf]="editing">
                      <input matInput [(ngModel)]="escola.docentes_graduados" />
                    </ng-template>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="showPremiumFields || escola?.compra">
                    <h3>% de docentes Pós-graduados?</h3>
                    <ng-template [ngIf]="editing">
                      <input matInput [(ngModel)]="escola.docentes_pos_graduados" />
                    </ng-template>
                  </div>
                </div>
                <div class="row row-eq-height mb-2">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3>Total de professores</h3>
                    <input type="text" class="form-control" [(ngModel)]="escola.professores_total" />
                  </div>
                </div>
                <div class="row row-eq-height">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/corpo-docente-profissionais-especializados.svg" alt="Profissionais especializados" />
                      <h3>Profissionais especializados</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola.profissional_fonoaudiologia == true">Fonoaudióloga</p>
                        <p *ngIf="escola.profissional_psicologia == true">Psicóloga</p>
                        <p *ngIf="escola.profissional_nutricao == true">Nutricionista</p>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-checkbox [(ngModel)]="escola.profissional_nutricao"> Nutricionista </mat-checkbox>
                        <mat-checkbox [(ngModel)]="escola.profissional_fonoaudiologia"> Fonoaudiologia </mat-checkbox>
                        <mat-checkbox [(ngModel)]="escola.profissional_psicologia"> Psicóloga </mat-checkbox>
                        <mat-checkbox [(ngModel)]="escola.profissional_saude"> Equipe de Enfermagem / Bombeiro </mat-checkbox>
                        <mat-checkbox [(ngModel)]="escola.conselheiro_vocacional"> Conselheiro Vocacional </mat-checkbox>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Idiomas -->
            <section id="idiomas" class="bg-section col-12 cards" *ngIf="showPremiumFields || escola?.compra">
              <div class="subtitle">
                <h2>Idiomas</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.idioma_sobre" [editing]="editing"></app-text-bound>
              <div class="content">
                <div class="row row-eq-height">
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <h3>Ensino</h3>
                      <ng-template [ngIf]="!editing">
                        <p>{{ escola.modalidade_ensino }}</p>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.modalidade_ensino">
                          <mat-option *ngFor="let item of modalidadeEnsino" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <!-- div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/idiomas-bilingue.svg" alt="Bilíngue" />
                      <h3>Bilíngue</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          {{ escola.educacao_bilingue }}
                        </p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('educacao_bilingue')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.educacao_bilingue">
                          <mat-option></mat-option>
                          <mat-option *ngFor="let item of educacaoBilingue" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div -->
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/idiomas-idioma-principal.svg" alt="Idioma Principal" />
                      <h3>Idioma Principal</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">{{ escola.idioma_principal?.join(", ") }}</p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('idioma_principal')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.idioma_principal" multiple>
                          <mat-option *ngFor="let item of idiomas" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola.outros_idiomas?.length > 0 || this.editing">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/idiomas-outros-idiomas.svg" alt="Outros" />
                      <h3>Outros</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">{{ escola.outros_idiomas?.join(", ") }}</p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('outros_idiomas')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.outros_idiomas" multiple>
                          <mat-option *ngFor="let item of idiomas" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <!--                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola.cellep || (userService.master && this.editing)">-->
                  <!--                    <div class="item d-flex flex-column align-items-center justify-content-center">-->
                  <!--                      <img class="w-100" src="images/cellep/logo.svg" alt="Escola Parceira"/>-->
                  <!--                      <h3>Escola Parceira</h3>-->
                  <!--                      <ng-template [ngIf]="userService.master && this.editing">-->
                  <!--                        <mat-select [(ngModel)]="escola.cellep">-->
                  <!--                          <mat-option></mat-option>-->
                  <!--                          <mat-option *ngFor="let item of parceira_cellep" [value]="item">-->
                  <!--                            {{item}}-->
                  <!--                          </mat-option>-->
                  <!--                        </mat-select>-->
                  <!--                      </ng-template>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola.oebi || this.editing">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img class="w-100" src="images/oebi/logo-oebi.png" style="width: 60px !important; max-height: fit-content" alt="Bilíngue OEBI" />
                      <!--                          <h3>Bilíngue OEBI</h3>-->
                      <ng-template [ngIf]="this.editing">
                        <mat-checkbox [(ngModel)]="escola.oebi"></mat-checkbox>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Cursos Extracurriculares -->
            <section id="cursos" class="bg-section col-12 cards" *ngIf="showPremiumFields || escola?.compra || editing">
              <div class="subtitle">
                <h2>Cursos Extracurriculares</h2>
              </div>
              <app-text-bound *ngIf="(escola?.premium || escola?.compra || editing)" [(text)]="escola.extracurricular_sobre" [editing]="editing"></app-text-bound>

              <div class="content mb-3" *ngIf="escola">
                <div class="row row-eq-height">
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item">
                      <img src="images/school/cursos-extra-esportes.svg" alt="Esportes" />
                      <h3>Esportes</h3>
                      <ng-template [ngIf]="!editing">
                        <div *ngIf="escola?.premium || escola?.compra; else showMessagePremium" fxLayout="column" fxLayoutAlign="space-between center">
                          <p *ngIf="escola.extracurricular_esporte_atletismo == true">Atletismo</p>
                          <p *ngIf="escola.extracurricular_esporte_arquearia == true">Arquearia</p>
                          <p *ngIf="escola.extracurricular_esporte_artes_marciais == true">Artes Marciais</p>
                          <p *ngIf="escola.extracurricular_esporte_badminton == true">Badminton</p>
                          <p *ngIf="escola.extracurricular_esporte_ballet == true">Ballet</p>
                          <p *ngIf="escola.extracurricular_esporte_basquete == true">Basquete</p>
                          <p *ngIf="escola.extracurricular_esporte_circo == true">Circo</p>
                          <p *ngIf="escola.extracurricular_esporte_dancas == true">Danças</p>
                          <p *ngIf="escola.extracurricular_esporte_esgrima == true">Esgrima</p>
                          <p *ngIf="escola.extracurricular_esporte_futebol == true">Futebol</p>
                          <p *ngIf="escola.extracurricular_esporte_ginastica == true">Ginástica</p>
                          <p *ngIf="escola.extracurricular_esporte_handebol == true">Handebol</p>
                          <p *ngIf="escola.extracurricular_esporte_hiphop == true">Hip Hop</p>
                          <p *ngIf="escola.extracurricular_esporte_hoquei == true">Hoquei</p>
                          <p *ngIf="escola.extracurricular_esporte_ioga == true">Ioga</p>
                          <p *ngIf="escola.extracurricular_esporte_judo == true">Judô</p>
                          <p *ngIf="escola.extracurricular_esporte_natacao == true">Natação</p>
                          <p *ngIf="escola.extracurricular_esporte_patinacao == true">Patinação</p>
                          <p *ngIf="escola.extracurricular_esporte_rugby == true">Rugby</p>
                          <p *ngIf="escola.extracurricular_esporte_skate == true">Skate</p>
                          <!--<p *ngIf="escola.extracurricular_esporte_tchoukball == true">Tchoukball</p>-->
                          <p *ngIf="escola.extracurricular_esporte_tenis == true">Tênis</p>
                          <p *ngIf="escola.extracurricular_esporte_tenis_mesa == true">Tênis de Mesa</p>
                          <p *ngIf="escola.extracurricular_esporte_voleibol == true">Voleibol</p>
                        </div>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('extracurricular_esporte')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_atletismo">Atletismo</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_arquearia">Arquearia</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_artes_marciais">Artes Marciais</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_badminton">Badminton</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_ballet">Ballet</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_basquete">Basquete</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_circo">Circo</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_dancas">Danças</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_esgrima">Esgrima</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_futebol">Futebol</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_ginastica">Ginástica</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_handebol">Handebol</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_hiphop">Hip Hop</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_hoquei">Hoquei</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_ioga">Ioga</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_judo">Judô</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_natacao">Natação</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_patinacao">Patinação</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_rugby">Rugby</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_skate">Skate</mat-checkbox>
                        </p>
                        <!--                        <p>-->
                        <!--                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_tchoukball">Tchoukball</mat-checkbox>-->
                        <!--                        </p>-->
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_tenis">Tênis</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_tenis_mesa">Tenis de Mesa</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_esporte_voleibol">Voleibol</mat-checkbox>
                        </p>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item">
                      <img src="images/school/cursos-extra-habilidades-logicas.svg" alt="Habilidades Lógicas" />
                      <h3>Habilidades Lógicas</h3>
                      <ng-template [ngIf]="!editing">
                        <div *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          <p *ngIf="escola.extracurricular_habilidade_logica_catequese == true">Catequese</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_culinaria == true">Culinária</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_educ_ambiental == true">Educação Ambiental</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_educ_financeira == true">Educação Financeira</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_empreendedorismo == true">Empreendedorismo</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_horticultura == true">Horticultura</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_idiomas == true">Idiomas</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_libras == true">Libras</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_maker == true">Maker</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_orint_vocacional == true">Orient. Vocacional</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_perp_vestibular == true">Prep. Vestibular</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_prep_enem == true">Prep. ENEM</p>
                          <p *ngIf="escola.extracurricular_habilidade_logica_robotica == true">Robótica</p>
                        </div>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('extracurricular_habilidade_logica')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_catequese">Catequese</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_culinaria">Culinária</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_educ_ambiental">Educação Ambiental</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_educ_financeira">Educação Financeira</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_empreendedorismo">Empreendedorismo</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_horticultura">Horticultura</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_idiomas">Idiomas</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_libras">Libras</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_maker">Maker</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_orint_vocacional">Orient. Vocacional</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_perp_vestibular">Prep. Vestibular</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_prep_enem">Prep. ENEM</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_habilidade_logica_robotica">Robótica</mat-checkbox>
                        </p>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="item">
                      <img src="images/school/cursos-extra-artes.svg" alt="Artes" />
                      <h3>Artes</h3>
                      <ng-template [ngIf]="!editing">
                        <div *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          <p *ngIf="escola.extracurricular_artes_desenho == true">Desenho</p>
                          <p *ngIf="escola.extracurricular_artes_literatura == true">Literatura</p>
                          <p *ngIf="escola.extracurricular_artes_musica == true">Música</p>
                          <p *ngIf="escola.extracurricular_artes_prod_artistica == true">Prod. Artística</p>
                          <p *ngIf="escola.extracurricular_artes_teatro == true">Teatro</p>
                        </div>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('extracurricular_artes')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_artes_desenho">Desenho</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_artes_literatura">Literatura</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_artes_musica">Música</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_artes_prod_artistica">Prod. Artística</mat-checkbox>
                        </p>
                        <p>
                          <mat-checkbox [(ngModel)]="escola.extracurricular_artes_teatro">Teatro</mat-checkbox>
                        </p>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="false && (escola.supercerebro || this.editing)">
                    <div class="item">
                      <img class="w-100" src="images/supercerebro/logo-supercerebro.png" style="width: 100% !important; max-width: 120px; max-height: fit-content" />
                      <ng-template [ngIf]="this.editing">
                        <mat-checkbox [(ngModel)]="escola.supercerebro"></mat-checkbox>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="showPremiumFields || escola?.compra || editing">
                <h3>Competições que a escola participa</h3>
                <mat-select [(ngModel)]="escola.competicoes" multiple>
                  <mat-option *ngFor="let item of competicoes" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div> -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="showPremiumFields || escola?.compra || editing">
                <div class="item">
                  <div class="subtitle">
                    <h3 class="sub-divisao">Competições que a escola participa (olimpíadas científicas e do conhecimento)</h3>
                    <button class="btn btn-danger btn-sm ml-2 btn-add-universidade" (click)="addCompeticoes()">Adicionar</button>
                  </div>
                  <div class="row">
                    <div *ngFor="let competicao of escola.competicoes; let i = index; trackBy: trackByIndex" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="card card-matricula mb-2">
                        <div class="card-body d-flex justify-content-between align-items-center gap-10" style="flex-direction: column">
                          <div class="mb-2" style="width: 100%">
                            <label for="competicao{{ i }}">Nome da competição</label>
                            <input type="text" class="form-control" [(ngModel)]="escola.competicoes[i]" placeholder="Nome" name="competicao{{ i }}" id="competicao{{ i }}" />
                          </div>
                          <button class="btn btn-danger btn-sm ml-2 btn-delete-universidade" (click)="removeCompeticoes(i)">X</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Disciplinas e Abordagens Adicionais -->
            <section *ngIf="showPremiumFields || escola?.compra || editing" id="disciplinas" class="bg-section col-12 cards">
              <div class="subtitle">
                <h2>Disciplinas da Grade Curricular</h2>
              </div>
              <app-text-bound *ngIf="false && (escola?.premium || escola?.compra || editing)" [(text)]="escola.disciplinas_adicionais_sobre" [editing]="editing"></app-text-bound>
              <div class="content">
                <div class="row row-eq-height">
                  <ng-template [ngIf]="!editing">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="false && escola?.disciplinas_adicionais_aulas_de_programacao_coding == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-programacao.svg" alt="Aulas de Programação/Coding" />
                        <p>Aulas de Programação / Coding</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola?.disciplinas_adicionais_duplo_diploma == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Duplo Diploma" />
                        <p>Duplo Diploma</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="false && escola?.disciplinas_adicionais_educacao_empreendedora == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-educacao.svg" alt="Educação Empreendedora" />
                        <p>Educação Empreendedora</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="false && escola?.disciplinas_adicionais_ensino_religioso == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-bilingue.svg" alt="Educação Religioso" />
                        <p>Ensino Religioso</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola?.disciplinas_adicionais_convenio_com_escolas_no_exterior == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-bilingue.svg" alt="Convênio com Escolas no Exterior" />
                        <p>Convênio com Escolas no Exterior</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="false && escola?.disciplinas_adicionais_oportunidades_de_voluntariado == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-bilingue.svg" alt="Oportunidades de Voluntariado" />
                        <p>Oportunidades de Voluntariado</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="escola?.intercambio == true">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Intercâmbio" />
                        <p>Intercâmbio</p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="editing">
                    <div *ngIf="false" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-programacao.svg" alt="Aulas de Programação/Coding" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_aulas_de_programacao_coding"> Aulas de Programação / Coding </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Duplo Diploma" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_duplo_diploma"> Duplo Diploma </mat-checkbox>
                      </div>
                    </div>
                    <div *ngIf="false" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-educacao.svg" alt="Educação Empreendedora" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_educacao_empreendedora"> Educação Empreendedora </mat-checkbox>
                      </div>
                    </div>
                    <div *ngIf="false" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-bilingue.svg" alt="Educação Religioso" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_ensino_religioso"> Ensino Religioso </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-programacao.svg" alt="Aulas de Progroamação/Coding" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_convenio_com_escolas_no_exterior"> Convênio com Escolas no Exterior </mat-checkbox>
                      </div>
                    </div>
                    <div *ngIf="false" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-bilingue.svg" alt="Oportunidades de Voluntariado" />
                        <mat-checkbox [(ngModel)]="escola.disciplinas_adicionais_oportunidades_de_voluntariado"> Oportunidades de Voluntariado </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Intercâmbio" />
                        <mat-checkbox [(ngModel)]="escola.intercambio"> Intercâmbio </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Competições internacionais" />
                        <mat-checkbox [(ngModel)]="escola.competicoes_internacionais"> Competições internacionais </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="item d-flex flex-column align-items-center justify-content-center">
                        <img src="images/school/disciplinas-diploma.svg" alt="Certificação em outro idioma" />
                        <mat-checkbox [(ngModel)]="escola.certificao_outro_idioma"> Certificação em outro idioma </mat-checkbox>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </section>

            <!-- Alimentação -->
            <section  *ngIf="false && (showPremiumFields || escola?.compra || editing)" id="alimentacao" class="bg-section col-12 cards">
              <div class="subtitle">
                <h2>Alimentação</h2>
              </div>
              <app-text-bound *ngIf="escola?.premium || escola?.compra || editing" [(text)]="escola.alimentacao_sobre" [editing]="editing"></app-text-bound>
              <div class="content">
                <div class="row row-eq-height">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/alimentacao-almoco.svg" alt="Almoço" />
                      <h3>Almoço</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          {{ escola.alimentacao_oferece_almoco }}
                        </p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('alimentacao_oferece_almoco')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.alimentacao_oferece_almoco">
                          <mat-option></mat-option>
                          <mat-option *ngFor="let item of alimentacao" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/alimentacao-jantar.svg" alt="Jantar" />
                      <h3>Jantar</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          {{ escola.alimentacao_oferece_jantar }}
                        </p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('alimentacao_oferece_jantar')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.alimentacao_oferece_jantar">
                          <mat-option></mat-option>
                          <mat-option *ngFor="let item of alimentacao" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="item d-flex flex-column align-items-center justify-content-center">
                      <img src="images/school/alimentacao-lanche.svg" alt="Lanche" />
                      <h3>Lanche</h3>
                      <ng-template [ngIf]="!editing">
                        <p *ngIf="escola?.premium || escola?.compra; else showMessagePremium">
                          {{ escola.alimentacao_oferece_lanche }}
                        </p>
                        <ng-template #showMessagePremium>
                          <a href="javascript:" class="class-premium" (click)="requestInfo('alimentacao_oferece_lanche')">{{ this.messagePremium }}</a>
                        </ng-template>
                      </ng-template>
                      <ng-template [ngIf]="editing">
                        <mat-select [(ngModel)]="escola.alimentacao_oferece_lanche">
                          <mat-option></mat-option>
                          <mat-option *ngFor="let item of alimentacao" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Editar concorrentes -->
            <section class="bg-section col-12 cards" *ngIf="editing && userService.master == true">
              <div class="subtitle">
                <h2>Concorrentes</h2>
              </div>

              <app-escola-competitors #escolaCompetitors [escola]="this.escola" *ngIf="editing && userService.master == true"></app-escola-competitors>
            </section>

            <!-- Parcerias -->
            <!--            <section id="parcerias" class="bg-section col-12 list">-->
            <!--              <div class="subtitle">-->
            <!--                <h2>Parcerias</h2>-->
            <!--              </div>-->
            <!--              <div class="content" *ngIf="escola">-->
            <!--                <div class="row">-->
            <!--                  <div class="item col-xl-12 col-lg-12 col-md-12col-sm-12 col-12" *ngIf="escola.parcerias">-->
            <!--                    <p *ngFor="let item of escola.parcerias">item</p>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </section>-->
            <!-- Afiliações e Certificações -->
            <!--            <section id="certificados" class="bg-section col-12 cards afiliacoes">-->
            <!--              <div class="subtitle">-->
            <!--                <h2>Afiliações e Certificações</h2>-->
            <!--              </div>-->
            <!--              <div class="content" *ngIf="escola">-->
            <!--                <div class="row row-eq-height">-->
            <!--                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="escola.afiliacoes">-->
            <!--                    <div class="item d-flex flex-column align-items-center justify-content-center " *ngFor="let item of escola.afiliacoes">-->
            <!--                      &lt;!&ndash;<img src="images/school/logo-afiliacoes.jpg" alt="Parceiro"/>&ndash;&gt;-->
            <!--                      <img src="{{item.image}}" alt="Parceiro"/>-->
            <!--                      <p>{{item.nome}}</p>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </section>-->

            <!-- VERSAO FREE -->
            <!--            <section id="versao-premium" style="padding: 10px;" class="bg-section col-12 premium"-->
            <!--                     *ngIf="escola && !(escola.premium || escola.compra)">-->
            <!--              <div class="subtitle">-->
            <!--                <h2 style="color: white">Quer comprar as informações completas dessa escola?</h2>-->
            <!--              </div>-->
            <!--              <div class="content">-->
            <!--                <div class="row">-->
            <!--                  <div class="item col-xl-12 col-lg-12 col-md-12 col-sm-12">-->
            <!--                    <p>Se você está buscando escola para seu filho, pode comprar o desbloqueio de todos os dados indicados como <span-->
            <!--                      style="font-weight: bold">"informação paga"</span> dessa página-->
            <!--                      por {{pagseguroService.plano.perfil_escola.valor | currency:'BRL':'symbol':'1.2-2': 'pt-BR'}}. O prazo para liberação do acesso completo aos dados é de até-->
            <!--                      10 dias úteis.</p>-->
            <!--                    <button mat-flat-button (click)="checkout($event)"-->
            <!--                            style="margin-top: 10px; border-radius: 8px; border: 0; font-weight: bold; font-size: 25px; height: 60px;">-->
            <!--                      COMPRAR-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </section>-->

            <!-- Avaliar -->
            <section id="avaliacao" class="bg-section col-12 avaliar" *ngIf="!editing">
              <div class="subtitle">
                <h2>Avaliações</h2>
              </div>
              <app-review-list *ngIf="escola" [escola]="escola"></app-review-list>
            </section>

            <!--            &lt;!&ndash; Depoimentos &ndash;&gt;-->
            <!--            <section id="comments" class="bg-section col-12 depoimentos">-->
            <!--              <div class="subtitle">-->
            <!--                <h2>Depoimentos</h2>-->
            <!--              </div>-->
            <!--              <app-review-list *ngIf="escola" [escola]="escola"></app-review-list>-->
            <!--            </section>-->
          </section>
        </div>
      </section>
    </div>

    <!-- BLOG-->
    <!--    <section class="blog escola">-->
    <!--      <div class="container">-->
    <!--        <div class="subtitle">-->
    <!--          <h2>Aconteceu na escola</h2>-->
    <!--        </div>-->

    <!--        <div class="row">-->
    <!--          <article class="col-xl-3 col-lg-3 col-md-6 col-sm-12">-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <div class="image">-->
    <!--                <img src="images/thumb-list-blog.jpg" alt="Escola"/>-->
    <!--              </div>-->
    <!--            </a>-->
    <!--            <div class="post-meta d-flex flex-row">-->
    <!--              <div class="categories d-flex flex-row align-items-baseline">-->
    <!--                <a href="#" title="Categoria">Categoria</a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <h3>Lorem Ipsum</h3>-->
    <!--              <p>Text of the printing and typesett  ing industry. Lorem Ipsum has been-->
    <!--                the industry's standard dummy text ever since the 1500s</p>-->
    <!--            </a>-->
    <!--          </article>-->
    <!--          <article class="col-xl-3 col-lg-3 col-md-6 col-sm-12">-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <div class="image">-->
    <!--                <img src="images/thumb-list-blog.jpg" alt="Escola"/>-->
    <!--              </div>-->
    <!--            </a>-->
    <!--            <div class="post-meta d-flex flex-row">-->
    <!--              <div class="categories d-flex flex-row align-items-baseline">-->
    <!--                <a href="#" title="Categoria">Categoria</a>-->
    <!--                <a href="#" title="Categoria 1">Categoria 1</a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <h3>Lorem ipsum dolor sit amet consectetur adipiscing elit aliquet</h3>-->
    <!--              <p>Text of the printing and typesetting industry. Lorem Ipsum has been-->
    <!--                the industry's standard dummy</p>-->
    <!--            </a>-->
    <!--          </article>-->
    <!--          <article class="col-xl-3 col-lg-3 col-md-6 col-sm-12">-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <div class="image">-->
    <!--                <img src="images/thumb-list-blog.jpg" alt="Escola"/>-->
    <!--              </div>-->
    <!--            </a>-->
    <!--            <div class="post-meta d-flex flex-row">-->
    <!--              <div class="categories d-flex flex-row align-items-baseline">-->
    <!--                <a href="#" title="Categoria">Categoria</a>-->
    <!--                <a href="#" title="Categoria 1">Categoria 1</a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <h3>Lorem Ipsum is simply dummy</h3>-->
    <!--              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id aliquet-->
    <!--                lectus, eu tristique dolor. Sed cursus sapien purus, non rutrum nunc tincidunt-->
    <!--                ut. Sed sed viverra ipsum, quis finibus quam. Suspendisse sed ante eu erat-->
    <!--                maximus varius. Nam ut libero orci. In diam risus, dictum nec mauris ac,-->
    <!--                pretium efficitur dui. </p>-->
    <!--            </a>-->
    <!--          </article>-->
    <!--          <article class="col-xl-3 col-lg-3 col-md-6 col-sm-12">-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <div class="image">-->
    <!--                <img src="images/thumb-list-blog.jpg" alt="Escola"/>-->
    <!--              </div>-->
    <!--            </a>-->
    <!--            <div class="post-meta d-flex flex-row">-->
    <!--              <div class="categories d-flex flex-row align-items-baseline">-->
    <!--                <a href="#" title="Categoria">Categoria</a>-->
    <!--                <a href="#" title="Categoria 1">Categoria 1</a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <a href="#" title="veja mais">-->
    <!--              <h3>Lorem Ipsum is simply dummy</h3>-->
    <!--              <p>Text of the printing and typesetting industry</p>-->
    <!--            </a>-->
    <!--          </article>-->
    <!--        </div>-->
    <!--        &lt;!&ndash; VER TODAS &ndash;&gt;-->
    <!--        <div class="btn-ver-todas">-->
    <!--          <a href="#" title="ver todas">ver todas</a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->

    <!-- MAPA -->
    <!--    <section class="mapa-escola">-->
    <!--      <div class="container">-->
    <!--        <div class="subtitle">-->
    <!--          <h2>Mapa</h2>-->
    <!--        </div>-->
    <!--        <div class="content-map" *ngIf="escola">-->
    <!--          <iframe class="map"-->
    <!--                  [src]="(-->
    <!--                  'https://www.google.com/maps/embed/v1/place?key=' + GOOLE_MAPS_API_KEY-->
    <!--                  + '&center=' + escola.latitude + ','+escola.longitude-->
    <!--                  + '&zoom=13'-->
    <!--                  + '&q=' + escola.latitude + ','+escola.longitude) | safe"-->
    <!--                  width="600" height="450"-->
    <!--                  frameborder="0" style="border:0;"-->
    <!--                  allowfullscreen="false" aria-hidden="false"-->
    <!--                  tabindex="0"></iframe>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->

    <!-- SLIDER -->

    <section class="feature slide" *ngIf="escolaListaArray != null && !editing">
      <app-escola-list-horizontal [escolaLista]="escolaListaArray" readonly="true"> </app-escola-list-horizontal>
    </section>

    <section class="feature slide">
      <app-parceiro [flag_exibir_pagina_escola]="true"></app-parceiro>
    </section>
  </section>
</main>

<!-- ACTIONS -->
<div class="actions" *ngIf="escola" fxHide.lt-sm>
  <div class="row" fxLayout="column" fxLayoutGap="5px">
    <div *ngIf="escola?.can_edit && !editing">
      <button mat-raised-button (click)="editingClick()" [disabled]="this.editing || this.saving">
        <mat-icon inline="true">edit</mat-icon>
        Editar Escola
      </button>
      <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
    </div>
    <ng-template [ngIf]="editing && this.escola.can_edit">
      <div class="w-100" fxLayout="column" *ngIf="userService.master == true" fxLayoutGap="5px">
        <div fxLayout="column">
          <mat-label>Escola Parceira</mat-label>
          <mat-slide-toggle [(ngModel)]="escola.premium"></mat-slide-toggle>
        </div>
        <div fxLayout="column">
          <mat-label>Escola Ativa</mat-label>
          <mat-slide-toggle [ngModel]="escola.data_desativada == null" (change)="ativaToggle($event)" [matTooltipDisabled]="escola.data_desativada == null" [matTooltip]="'Desativada desde: ' + escola.data_desativada"> </mat-slide-toggle>
        </div>
      </div>
      <button mat-raised-button (click)="saveEdit()">Salvar</button>
      <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
      <button mat-raised-button (click)="cancelEdit()" [disabled]="this.saving">Cancelar</button>
      <!--        <a href="javascript:" (click)="cancelEdit()" class="outros">-->
      <!--          cancelar-->
      <!--        </a>-->
      <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
    </ng-template>
    <div *ngIf="!escola?.can_edit">
      <a href="javascript:" (click)="proprietario()" class="proprietario">Sou proprietário da escola</a>
    </div>
    <div *ngIf="userService?.beta">
      <button mat-raised-button [matBadge]="this.chatBadge" [matBadgeHidden]="this.chatBadge === null" (click)="this.toggleChat()">
        <mat-icon inline>message</mat-icon>
        Conversar com a Escola
      </button>
      <!--          <ng-template cdkConnectedOverlay-->
      <!--                       [cdkConnectedOverlayWidth]="'50vw'"-->
      <!--                       [cdkConnectedOverlayHeight]="'50vh'"-->
      <!--                       [cdkConnectedOverlayOrigin]="trigger"-->
      <!--                       [cdkConnectedOverlayOpen]="chatVisible"-->
      <!--          >-->
      <app-chatroom style="margin-bottom: 150px" [hidden]="!chatVisible" [roomname]="userService.getCurrentUser().uid + '@' + escola.codigo" (newMessage)="this.newMessage($event)" [escola]="this.escola" class="w-50 h-50 position-fixed bottom-0 end-0"> </app-chatroom>
      <!--        </ng-template>-->
    </div>
    <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="this.saving ? '' : 'none'"></mat-progress-bar>
  </div>
</div>
