import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-eventos',
  templateUrl: './escola-eventos.component.html',
  styleUrls: ['./escola-eventos.component.css'],
})
export class EscolaEventosComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}

  get nextsEvents(): any[] {
    return this.escola.eventos.filter((evento) => new Date(evento.data) >= new Date());
  }

  get hasEvents(): boolean {
    return this.nextsEvents.length > 0;
  }

  getDia(data: string): string {
    return new Date(data).getUTCDate().toString().padStart(2, '0');
  }

  getMes(data: string): string {
    return new Date(data).toLocaleString('default', { month: 'short' });
  }
}
