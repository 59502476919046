import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Escola } from '../../service/Escola';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Review, ReviewEditComponent } from '../review-edit/review-edit.component';
import firebase from 'firebase/app';
import { UserService } from '../../service/user.service';
import { ReviewRankingComponent } from '../review-ranking/review-ranking.component';
import { tap } from 'rxjs/operators';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.css'],
})
export class ReviewListComponent implements OnInit, AfterViewInit {
  @Input() escola: Escola;
  @ViewChild('matPaginator', { static: true }) matPaginator: MatPaginator;
  reviewList: Review[] = [];
  reviewCount = 0;
  pageSize = 4;

  constructor(public userService: UserService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.matPaginator._intl.itemsPerPageLabel = 'Avaliações por página:';
    this.matPaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length} Avaliações`;
    };
  }

  ngAfterViewInit(): void {
    this.filterData();
  }

  filterData(): void {
    // const ds: isc.DataSource = isc.DataSource.get('review');

    // const criteria = {
    //   cod_escola: this.escola.codigo
    // };

    // if (this.matPaginator != null) {
    //   console.log('pageIndex: ' + this.matPaginator.pageIndex);
    //   console.log('pageSize: ' + this.matPaginator.pageSize);
    // }
    const rowOffset = this.matPaginator == null ? 0 : this.matPaginator.pageIndex * this.matPaginator.pageSize;
    // const requestProperties = {startRow: rowOffset, endRow: rowOffset + this.pageSize, sortBy: ['-data_hora_editada']};
    // ds.useHttpProxy = true;
    // ds.fetchData(criteria, (dsResponse: isc.DSResponse, data, dsRequest) => {
    //   this.reviewList = data;
    //   this.reviewCount = dsResponse.totalRows;
    // }, requestProperties);

    firebase
      .functions()
      .httpsCallable('review_escola')({ cod_escola: this.escola.codigo, offset: rowOffset })
      .then((value) => {
        const records = value.data.records;
        records.map((item: any) => {
          // data_hora_criada will come as a JSON string Date format
          item.data_hora_criada = new Date(item.data_hora_criada);
        });
        this.reviewList = records;
        this.reviewCount = value.data.total;
      });

    // this.reviewList = [];
    // for (let i = 0; i < this.pageSize; i++) {
    //   const r: Review = ReviewService.getRandomReview();
    //   this.reviewList.add(r);
    // }
  }

  newReview(): void {
    if (this.userService.getCurrentUser()) {
      this.matDialog
        .open(ReviewEditComponent, {
          id: 'reviewDialog',
          data: {
            cod_escola: this.escola.codigo,
          },
        })
        .afterClosed()
        .pipe(tap(() => this.filterData()))
        .subscribe();
    } else {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
    }
  }

  /**
   * @deprecated reviews have the rating bundled, use newReview() instead
   */
  newRating(): void {
    if (this.userService.getCurrentUser()) {
      this.matDialog
        .open(ReviewRankingComponent, {
          id: 'reviewDialog',
          data: {
            escola: this.escola,
            review: {},
            readonly: false,
          },
        })
        .afterClosed()
        .pipe(tap(() => this.filterData()))
        .subscribe();
    } else {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
    }
  }
}
