import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {EscolaService} from '../../service/escola.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {map, startWith, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Escola} from '../../service/Escola';
import firebase from 'firebase/app';

@Component({
  selector: 'app-escola-filter-listas',
  templateUrl: './escola-filter-listas.component.html',
  styleUrls: ['./escola-filter-listas.component.css']
})
export class EscolaFilterListasComponent implements OnInit, AfterViewInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allListas: Array<string> = [];
  filteredListas: Observable<string[]>;
  listControl = new FormControl();
  @ViewChild('listaInput', {static: true}) listaInput: ElementRef<HTMLInputElement>;

  constructor(public escolaService: EscolaService) {
  }

  ngOnInit(): void {
    this.filteredListas = this.listControl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => {
        return this._filter(value);
      })
    );
  }

  ngAfterViewInit(): void {
    const functions = firebase.functions();
    const viewLitasFunction = functions.httpsCallable('view_listas');
    viewLitasFunction({}).then(value => {
      this.allListas = [];
      for (const lista of value.data) {
        this.allListas.push(lista.nome);
      }
    });
  }

  remove(item: string): void {
    const currentValues: string[] = this.escolaService.listas.value;
    if (currentValues == null) {
      return;
    }
    const index = currentValues.indexOf(item);
    if (index >= 0) {
      currentValues.splice(index, 1);
      this.escolaService.listas.setValue(currentValues);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // console.log('Selected: ' + event.option.value);
    let currentValues: string[] = this.escolaService.listas.value;
    if (currentValues == null) {
      currentValues = [];
    }
    currentValues.push(event.option.viewValue);
    this.escolaService.listas.setValue(currentValues);
    this.listaInput.nativeElement.value = '';
    this.listControl.setValue(null);
  }

  private _filter(value: string): Array<string> {

    // console.log(`Filter Value: ${value}`);
    const selected: Array<string> = this.escolaService.listas.value;
    if (value == null) {

      if (selected == null) {
        return this.allListas;
      } else {

        return this.allListas.filter(value1 => {
          console.log(`Null filter check: value1: ${value1} indexOf: ${selected.indexOf(value1)}`);

          if (selected.indexOf(value1) < 0) {
            return value1;
          }
        });
      }
    } else {

      const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
      const filtered = this.allListas.filter((item) => {
        console.log(`item: ${JSON.stringify(item)}`);
        const normalizedName = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        console.log(`normalizedValue: ${normalizedValue} normalizedName: ${normalizedName} normalizedName.indexOf(normalizedValue): ${normalizedName.indexOf(normalizedValue)}`);

        if (normalizedName.indexOf(normalizedValue) >= 0) {

          if (selected == null) {
            return item;
          } else if (selected.indexOf(item) < 0) {
            return item;
          }
        }
      });

      return filtered;
    }
  }

  // private _filter(value: string): Escola[] {
  //   // console.log(`_filter: ${value}`);
  //   const filtered = this.allListas.filter((escola) => {
  //
  //     let selectedEscola: Escola[] = this.escolaService.listas.value;
  //     if (selectedEscola == null) {
  //       selectedEscola = [];
  //     }
  //     // console.log(`selectedEscola: ${JSON.stringify(selectedEscola)}`);
  //     // console.log(`selectedEscola.indexOf(escola.nome): ${selectedEscola.indexOf(escola.nome)}`);
  //     if (selectedEscola.indexOf(escola) < 0) { // Hide selected itens
  //       if (value == null) {
  //         return escola;
  //       } else {
  //         const filterValue = value.toLowerCase();
  //         if (escola.nome.toLowerCase().indexOf(filterValue) >= 0) {
  //           return escola;
  //         }
  //       }
  //     }
  //   });
  //   return filtered;
  // }

  resetFilters(): void {
    this.escolaService.listas.reset();
  }

}
