<div class="search-div" fxLayout="row wrap" fxLayoutGap="5px">
    <form [formGroup]="this.escolaService.headerFormGroup" class="w-100 search-div-back" style="margin: 5px;"
          fxFlex="grow" fxLayout.gt-xs="space-around center" fxLayout.xs="space-around stretch" fxFlex="100"
          fxFlex.gt-xs="65">
        <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutAlign.xs="space-between center"
             fxLayoutGap="3px" class="w-100">
            <div class="header-fields">
                <div fxLayout="column" fxFlex class="w-100">
                    <mat-form-field floatLabel="always" fxFlexFill class="no-subscript-wrapper">
                        <mat-label class="custom-label">Endereço/Região</mat-label>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingUser"></mat-progress-bar>
                        <input fxFlex matInput #regiaoInput id="regiaoInput" autocomplete="off" type="geocode"
                            [hidden]="isLoadingUser"
                            [placeholder]="isLoadingUser? 'Carregando...' : (placeholderRegion? this.router.url == '/mapa'? 'Área do Mapa' : 'Região da escola' : '')"
                            (focusin)="this.placeholderRegion = false"
                            (focusout)="this.placeholderRegion = true"
                        >
                    </mat-form-field>
                </div>
                <mat-divider vertical="true" style="height: 30px;" fxHide.xs></mat-divider>
                <mat-divider vertical="false" style="width: 100%; margin-bottom: 10px;" fxHide.gt-xs></mat-divider>

                <div fxLayout="column" fxFlex class="w-100">
                    <mat-label class="custom-label" style="font-size: 12px;">Mensalidade</mat-label>

                    <div style="min-width: 200px; padding: 0; padding-top: 8px;">
                        <ng5-slider [(value)]="minTuitionValue" [(highValue)]="maxTuitionValue" [options]="sliderOptions" (mousedown)="$event.stopPropagation()"></ng5-slider>
                    </div>
                    <!-- div *ngIf="!showTuition" style="min-width: 200px; padding: 0; padding-top: 8px;">
                        <button style="line-height: inherit" mat-stroked-button (click)="showTuitionFilter()">{{this.tuitionMessage()}}</button>
                    </div -->
                </div>



                <mat-divider vertical="true" style="height: 30px;" fxHide.xs></mat-divider>
                <mat-divider vertical="false" style="width: 100%; margin-bottom: 10px;" fxHide.gt-xs></mat-divider>


                <div fxLayout="column" fxFlex class="w-100">
                    <mat-form-field floatLabel="always" fxFlexFill class="no-subscript-wrapper">
                        <mat-label class="custom-label">Nível</mat-label>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingUser"></mat-progress-bar>
                        <mat-select multiple [formControl]="escolaService.nivelEnsino" [hidden]="isLoadingUser">
                            <mat-option *ngFor="let item of nivelTags"
                                        [value]="item.name">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="header-buttons">
                <button mat-mini-fab fxFlex="nogrow" (click)="map()" #btnSearchMap color="primary">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </form>

    <div fxLayout="row" fxFlex fxFlex.xs="100" class="w-100 search-div-back" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="grow">
            <mat-form-field floatLabel="always" fxFlexFill class="no-subscript-wrapper">
                <mat-label class="custom-label">Encontrar uma Escola</mat-label>
                <input fxFlex matInput id="nomeEscolaInput"
                       [formControl]="escolaService.place"
                       [matAutocomplete]="autoEscolaNome"
                       [placeholder]="placeholderEscola? 'Nome da escola' : ''"
                       (focusin)="this.placeholderEscola = false"
                       (focusout)="this.placeholderEscola = true"
                >
            </mat-form-field>
            <mat-autocomplete
                    class="escola-autocomplete"
                    #autoEscolaNome="matAutocomplete"
                    [displayWith]="displayEscola"
                    (optionSelected)="detail($event.option.value)"
                    style="padding: 10px 0;">
                <mat-option *ngIf="isLoadingEscola">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <span>{{loadingMessage}}</span>
                </mat-option>
                <ng-container *ngIf="!isLoadingEscola">
                    <mat-option *ngFor="let escola of escolaNome"
                                [value]="escola">
                        <div *ngIf="!escola?.nome" fxFlex fxLayout="row"
                             class="select-item-box"
                             fxLayoutAlign="start center">
                            <mat-icon>search</mat-icon>
                            <span fxFlex="16px"
                                  class="select-nome">{{escola}}</span>
                        </div>
                        <div *ngIf="escola?.nome" fxFlex fxLayout="row"
                             class="select-item-box line-bottom"
                             fxLayoutAlign="start center">
                            <!-- <mat-icon>place</mat-icon>-->
                            <div fxFlex="grow" fxLayout="column"
                                 class="select-item-box"
                                 fxLayoutAlign="start stretch">
                                <span fxFlex="grow"
                                      class="select-nome">{{escola.nome}}</span>
                                <span fxFlex="auto"
                                      *ngIf="escola.endereco_json == null"
                                      class="select-address">{{escola.endereco}}</span>
                                <div fxFlex="30"
                                     *ngIf="escola.endereco_json != null && escola.endereco_json.cidade"
                                     fxLayout="row">
                                  <span fxFlex="noshrink" *ngIf="escola.endereco_json.cidade"
                                        class="select-address">{{escola.endereco_json.cidade + ' - ' + escola.endereco_json.estado}}</span>
                                    <span fxFlex="grow" *ngIf="escola.endereco_json.bairro"
                                          class="select-address text-end">{{escola.endereco_json.bairro}}</span>
                                </div>
                                <span fxFlex="30" class="select-address" *ngIf="escola.endereco_json.logradouro && escola.endereco_json.numero">
                                  {{escola.endereco_json.logradouro + ', ' + escola.endereco_json.numero}}</span>
                            </div>
                        </div>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </div>
        <button mat-mini-fab fxFlex="noshrink" (click)="searchEscola('list')" #btnSearchEscola color="primary">
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" fxFlex.gt-xs="65" fxFlex="100" style="padding: 1px 0"
         [fxFlexOrder.gt-xs]="3" fxFlexOrder="2">
        <a [routerLink]="['perfil-afinidade']"
           style="font-size: 12px;color: #ef3f5d;text-decoration: underline;margin-left: 5px;background-color: #ffffffcc; border-radius: 20px; padding: 0 5px;">
            Buscar por Afinidade
        </a>
    </div>
    <!--    <button mat-raised-button color="primary"-->
    <!--            style="font-size: 2em; border-radius: 30px; padding: 10px 20px;"-->
    <!--            (click)="this.router.navigate(['perfil-afinidade']);"-->
    <!--            ngStyle.lt-sm="font-size: 1em; padding: 0px 10px;">-->
    <!--        -->
    <!--    </button>-->
</div>
