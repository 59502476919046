<div style="background-color: transparent;" class="mini-mini-fab" mat-mini-fab disabled *ngIf="escola?.premium"
        matTooltip="Escola Parceira">
  <!--  <span class="fa fa-star-o rating-star"></span>-->
  <!--  <span style="font-size: smaller">{{escola?.rate_geral}}</span>-->
  <!--  <mat-icon fontSet="fas" fontIcon="fa-medal" [inline]="true" [style]="'font-size: 2em; color: #639849'"></mat-icon>-->
  <div class="premium-medal"></div>
</div>

<!--<div class="premium" *ngIf="escola?.premium" matTooltip="Escola Parceira">-->
<!--  <span class="fas fa-medal"></span>-->
<!--</div>-->
