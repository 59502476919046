import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-escola-modal-share',
  templateUrl: './escola-modal-share.component.html',
  styleUrls: ['./escola-modal-share.component.css'],
})
export class EscolaModalShareComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog) {}

  ngOnInit(): void {
    console.log('NETO: ', this.data);
  }

  closeModal(): void {
    this.matDialog.closeAll();
  }
}
