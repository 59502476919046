<footer>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Sobre</h3>
        <ul>
          <li><a href="#" title="Quem Somos">Quem Somos</a></li>
          <li><a href="#" title="Política de Privacidade">Política de Privacidade</a></li>
          <li><a href="#" title="Termos de Uso">Termos de Uso</a></li>
          <li><a href="#" title="Dúvidas Frequentes">Dúvidas Frequentes</a></li>
          <li><a href="#" title="Fale Conosco">Fale Conosco</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Encontre Escola</h3>
        <ul>
          <li><a href="#" title="Buscar Escolas">Buscar Escolas</a></li>
          <li><a href="#" title="Conteúdo">Conteúdo</a></li>
          <li><a href="#" title="Como Funciona">Como Funciona</a></li>
          <li><a href="#" title="Regras de Conduta">Regras de Conduta</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <h3>Para Escolas</h3>
        <ul>
          <li><a href="#" title="Cadastro Escola">Cadastro Escola</a></li>
          <li><a href="#" title="Porque Anunciar">Porque Anunciar</a></li>
          <li><a href="#" title="Planos e Vantagens">Planos e Vantagens</a></li>
          <li><a href="#" title="Solicite uma Visita">Solicite uma Visita</a></li>
        </ul>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 redes-sociais">
        <h3>Siga nossas redes sociais</h3>
        <div class="d-flex flex-row align-items-center">
          <a href="#" class="facebook" title="Facebook SchoolAdvisor"><img src="images/media-facebook.svg" alt="Facebook SchoolAdvisor" /></a>
          <a href="#" class="facebook" title="Facebook Group SchoolAdvisor"><img src="images/media-facebook-group.svg" alt="Facebook Group SchoolAdvisor" /></a>
          <a href="#" class="twitter" title="Twitter SchoolAdvisor"><img src="images/media-twitter.svg" alt="Twitter SchoolAdvisor" /></a>
          <a href="#" class="linkedin" title="Linkedin SchoolAdvisor"><img src="images/media-linkedin.svg" alt="Linkedin SchoolAdvisor" /></a>
        </div>
      </div>
      <div class="col-12 copy">
        <small>© 2024 Copyright - SchoolAdvisor.com.br</small>
      </div>
    </div>
  </div>
</footer>
