import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ReviewRankingComponent} from '../review-ranking/review-ranking.component';
import {MatDialog} from '@angular/material/dialog';
import {Review} from '../review-edit/review-edit.component';
import firebase from "firebase";
import {UserService} from "../../service/user.service";

@Component({
  selector: 'app-review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss']
})
export class ReviewDetailComponent implements OnInit {

  rateGeral: number;
  readonly = true;
  fullText = false;
  @Input() review: Review;
  @ViewChild('textBound', {static: true}) textBound: HTMLDivElement;
  editing: boolean = false;
  saving: boolean = false;

  constructor(private matDialog: MatDialog, public userService: UserService) {
  }

  ngOnInit(): void {
  }

  flipReviewText(): void {
    this.fullText = !this.fullText;
    // if (this.fullText) {
    //   //this.textBound.classList.replace('sample-text', 'full-text');
    //   this.textBound.classList.remove('sample-text');
    //   this.textBound.classList.add('full-text');
    // } else {
    //   this.textBound.classList.replace('full-text', 'sample-text');
    // }
  }

  showRankingCompleto(review: Review): void {
    this.matDialog.open(ReviewRankingComponent,
        {
          id: 'rankingCompletoDialog',
          data: {
            review,
            readonly: true,
          }
        }
    );
  }

  sendResposta(value: string) {
    this.saving = true;
    firebase.functions().httpsCallable('review_resposta')({codigo: this.review.codigo, resposta: value})
        .then(value => {
          this.review.resposta = value.data.resposta;
          this.saving = false;
          this.editing = false;
        });
  }
}
