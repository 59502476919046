<div class="info d-flex flex-row">
    <div class="row">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3" *ngFor="let review of reviewList">
                <app-review-detail [review]="review"></app-review-detail>
            </div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between none">
            <button mat-icon-button (click)="newReview()">
                <!--          <mat-icon>rate_review</mat-icon>-->
                <mat-icon color="primary">add_box</mat-icon>
                Avaliar
            </button>
        </div>
        <div class="col-12">
            <mat-paginator [hidden]="reviewCount == 0" fxFlex="100"
                           [length]="reviewCount"
                           [pageSize]="pageSize"
                           hidePageSize="true"
                           (page)="filterData()"
                           #matPaginator>
            </mat-paginator>
        </div>
        <!--  <mat-paginator [hidden]="reviewCount == 0" fxFlex="100"-->
        <!--                 [length]="reviewCount"-->
        <!--                 [pageSize]="pageSize"-->
        <!--                 (page)="filterData()"-->
        <!--                 #matPaginator>-->
        <!--  </mat-paginator>-->
        <!--  <div *ngIf="reviewCount == 0" fxFlex="100">-->
        <!--    <h2 style="text-align: center;">Essa escola não tem avaliações.</h2>-->
        <!--  </div>-->
        <!--</div>-->
    </div>
</div>
