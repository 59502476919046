import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-ads-slide',
  templateUrl: './escola-ads-slide.component.html',
  styleUrls: ['./escola-ads-slide.component.css'],
})
export class EscolaAdsSlideComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}
}
