<h1 mat-dialog-title>Valide seus dados para uma melhor experiência</h1>

<div mat-dialog-content>
    <div>
        <label>Whatsapp</label>
        <mat-form-field class="w-100 mt-0" floatLabel="never" appearance="outline">
          <input class="field" matInput maxLength="100" mask="(00) 0000-0000||(00) 00000-0000" placeholder="Whatsapp" [(ngModel)]="data.telefone">
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="save()">
      <mat-icon [inline]="false" style="color: #009294;">check</mat-icon>
      Salvar
    </button>
  </div>