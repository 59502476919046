<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <article >
      <div>
        <div class="elementor elementor-294">
          <div class="elementor-inner">
            <div class="elementor-section-wrap">
              <section
                class="elementor-element elementor-element-105d4e8 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="105d4e8" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-05873c4 elementor-column elementor-col-50 elementor-top-column" data-id="05873c4"
                         data-element_type="column">
                      <div class="elementor-column-wrap">
                        <div class="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-3197ba6 elementor-column elementor-col-50 elementor-top-column" data-id="3197ba6"
                         style="min-width: 350px;"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-6c787f9 elementor-widget elementor-widget-image" data-id="6c787f9" data-element_type="widget"
                               data-widget_type="image.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-image">
                                <img class="attachment-wpbdp-mini size-wpbdp-mini lazy" alt="" loading="lazy" src="./images/logo.png"
                                     data-src="./images/logo.png"
                                     data-lazy="./images/logo.png"/>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-66b17f6 elementor-widget elementor-widget-text-editor" data-id="66b17f6"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h4>Fale com a SchoolAdvisor</h4></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-3a1b048 elementor-widget elementor-widget-text-editor" data-id="3a1b048"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h6>(11) 9 8638-1828 | (11) 9 8237-0265</h6></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-bb2c5f0 elementor-widget elementor-widget-text-editor" data-id="bb2c5f0"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h6>contato@schooladvisor.com.br</h6></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-6877a52 elementor-widget elementor-widget-text-editor" data-id="6877a52"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="formItem">
                              <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                                <form [formGroup]="formContato" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                                  <mat-form-field>
                                    <input matInput [formControl]="nomeControl" placeholder="Nome" required/>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput [formControl]="emailControl"
                                           type="email" placeholder="e-mail" required/>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <mat-select [formControl]="assuntoControl" placeholder="assunto">
                                      <mat-option value="{{item}}" *ngFor="let item of assuntos">{{item}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field>
                                        <textarea matInput [formControl]="mensagemControl"
                                                  cols="40" rows="10"
                                                  placeholder="Mensagem" required></textarea>
                                  </mat-form-field>
                                </form>
                                <button mat-raised-button color="primary" (click)="send()" [disabled]="!formContato.valid || sending">Enviar</button>
                              </div>
                              <div *ngIf="sendMessage">
                                <span style="color: black">{{this.sendMessage}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div><!-- .entry-content -->
    </article><!-- #post-## -->
  </div>
</div>
