<div id="infraestrutura-acessibilidade" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Infraestrutura e Acessibilidade</h2>
  </div>
  <div *ngIf="escola.premium && hasSobre" class="row about-school content-more mb-4 active">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.infra_sobre }}</p>
    </div>
    <!-- <div class="col-12">
      <div class="action">
        <a href="" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">mostrar mais</a>
      </div>
    </div> -->
  </div>

  <div *ngIf="this.showIIESA()" class="col-12 mb-4">
    <app-selo-escola [escola]="escola" [seloId]="2"></app-selo-escola>
  </div>

  <div *ngIf="hasEspacosColetivos || hasLaboratorios || hasAcessibilidade" class="col-12 mt-2">
    <div class="row mb-2">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
        <p class="text-pink font-size-smallest mb-1">Espaços Coletivos</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_area_verde">Área verde</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_auditorio">Auditório</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_banheiro_adaptado" >Banheiro infantil</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_berco">Berços ou camas individuais</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_biblioteca">Biblioteca</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_camera">Câmeras de Segurança</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_cantina">Cantina</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_enfermaria">Enfermaria</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_estacionamento">Estacionamento</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_parque">Parque Infantil</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_patio_coberto">Pátio Coberto</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_patio_descoberto">Pátio Descoberto</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_piscina">Piscina</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_quadra_coberta">Quadra Coberta</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_quadra_descoberta">Quadra Descoberta</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_refeitorio">Refeitório</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_transporte">Transporte Escolar</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_viveiro">Viveiro de Animais</p>
      </div>
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
        <p class="text-pink font-size-smallest mb-1">Laboratórios e Salas</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_laboratorio_ciencias">Laboratório de Ciências</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_laboratorio_robotica">Laboratório de Robótica/Maker</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.infra_sala_artes">Sala de Artes</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_sala_climatizada">Salas Climatizadas</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_sala_esportes">Sala Multiuso</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.infra_sala_musica">Sala de Música</p>
      </div>
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
        <p class="text-pink font-size-smallest mb-1">Acessibilidade</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.acessibilidade_infra_corrimao">Corrimão</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.acessibilidade_infra_rampa">Rampa</p>
        <p class="font-size-regular text-black semibold mb-1" [class.tachado]="!this.escola.acessibilidade_infra_elevador">Elevador</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.acessibilidade_infra_banheiro_deficiente">Banheiro PCD</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.acessibilidade_sinalizacao_sonora">Sinalização Sonora</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.acessibilidade_sinalizacao_tatil">Sinalização Tátil</p>
        <p class="font-size-regular text-black semibold mb-0" [class.tachado]="!this.escola.acessibilidade_sinalizacao_visual">Sinalização Visual</p>
      </div>
    </div>
  </div>

  <div *ngIf="!hasData" class="row about-school content-more mb-4 active">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">Não Informado</p>
    </div>
  </div>
</div>
