import {Component, Input, OnInit} from '@angular/core';
import {Score} from '../../service/Escola';

@Component({
  selector: 'app-escola-score',
  templateUrl: './escola-score.component.html',
  styleUrls: ['./escola-score.component.scss'],
  // animations: [
  //   trigger('grow', [
  //     state('closed', style({
  //       hei: 0,
  //     })),
  //     state('open', style({
  //       width: 400
  //     })),
  //     transition('* => *', animate(150))
  //   ]),
  // ]
})
export class EscolaScoreComponent implements OnInit {
  @Input() score: Score;
  @Input() expanded = false;
  @Input() canExpand = true;
  @Input() small = false;
  maxIcons = 5;
  scoreMax = 10;
  showTotal = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  get rate() {
    return (this.score.score / this.score.total);
  }

  set rate(value) {
    this.score.score = value * this.score.total;
  }

  getTooltip(): string {
    return 'Afinidade: ' + Math.round(this.score.score) + '/' + this.score.total;
  }

  toggleExpanded(): void {
    if (this.canExpand) {
      this.expanded = !this.expanded;
    }
  }

  getFinalScore(): number {

    //let score = this.rate * this.scoreMax
    //score = score / 5 * 100
    let score = this.rate * 100
    //score = Math.round(score * 10) / 10

    if (score > 100) {
      score = 100
    }

    return score
  }
}
