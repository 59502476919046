import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import firebase from 'firebase/app';
import {ActivatedRoute, Params} from '@angular/router';
import {UserService} from '../../service/user.service';

@Component({
  selector: 'app-page-contato',
  templateUrl: './page-contato.component.html',
  styleUrls: [
    './page-contato.component.css',
    '../../../css/elementor/post-294.css',
    '../../../css/elementor/global.css',
    '../../../css/elementor/frontend.min.css',
    '../../../css/elementor/style.css',
  ]
})
export class PageContatoComponent implements OnInit {

  nomeControl = new FormControl(null, [Validators.required]);
  emailControl = new FormControl(null, [Validators.required, Validators.email]);
  assuntoControl = new FormControl(null, [Validators.required]);
  mensagemControl = new FormControl(null, [Validators.required]);
  formContato = new FormGroup({
    nome: this.nomeControl,
    email: this.emailControl,
    assunto: this.assuntoControl,
    mensagem: this.mensagemControl,
  });
  sending = false;
  // sended = false;
  sendMessage: string = null;
  assuntos = [
    'Quero saber como destacar minha escola', // 0
    'Tenho uma dúvida, crítica ou sugestão', // 1
  ];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      const sub = this.route.snapshot.paramMap.get('p');

      if (!isNaN(+sub)) {
        this.assuntoControl.setValue(this.assuntos[sub]);
        window.scrollTo(0, 0);
        console.log('scroll done');
      }

      const user = this.userService.getCurrentUser();
      if (user) {
        this.formContato.get('nome').setValue(user.displayName);
        this.formContato.get('email').setValue(user.email);
      }

      window.scrollTo({top: 0, behavior: 'smooth'});
    });
  }

  send(): void {

    if (this.formContato.valid) {
      this.sending = true;
      firebase.functions().httpsCallable('fale_conosco')(this.formContato.value).then(value => {
        // this.sended = true;
        this.sendMessage = value.data;
      });
    }
  }

}
