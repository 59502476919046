import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';
import { AnalyticsService } from '../../service/analytics.service';
import { UserService } from '../../service/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Escola } from '../../service/Escola';
import firebase from 'firebase/app';

@Component({
  selector: 'app-selo-escola',
  templateUrl: './selo-escola.component.html',
  styleUrls: ['./selo-escola.component.css'],
})
export class SeloEscolaComponent implements OnInit {
  @Input() escola: Escola;
  @Input() seloId: number;
  @Input() seloNome: string;
  seloData: any;
  showInfo = false;

  constructor(public userService: UserService, private matDialog: MatDialog, private analytics: AnalyticsService) {}

  get porcentagemRegiao() {
    return this.calcularPorcentagemSelo(this.seloData.selosRegiao);
  }

  get porcentagemCidade() {
    return this.calcularPorcentagemSelo(this.seloData.selosCidade);
  }

  async ngOnInit() {
    const request = {
      cod_escola: this.escola.codigo,
      cod_regiao: this.escola.cod_regiao,
      cod_selo: this.seloId,
    }

    const response = await firebase.functions().httpsCallable('escola_selo_grafico')(request);
    this.seloData = response.data;
  }



  calcularPorcentagemSelo(selos: any[]) {
    if (!selos || selos.length === 0) {
      return 0;
    }

    let total = 0;
    let regiao = 0;

    selos.forEach((data) => {
      if (data.selo) {
        regiao += data.qtd
      }
      else {
        total += data.qtd
      }
    });

    let porcentagem = Math.round((regiao / total) * 100);

    if (porcentagem <= 0) {
      porcentagem = 1;
    }

    return porcentagem;
  }

  async showAllInfo(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    if (this.seloNome) {
      this.analytics.viewEscolaField(this.escola, this.seloNome, user);
      //this.analytics.sendEventMail(this.escola, user);
    }

    this.showInfo = true;
  }
}
