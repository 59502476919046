export interface Tag {
  name: string;
  title: string;
  group:
    'Nível de Ensino' | 'Abordagem Pedagógica' | 'Corpo Docente' | 'Infraestrutura'
    | 'Acessibilidade' | 'Disciplinas Adicionais' | 'Extracurricular'
    | 'Recursos' | 'Tecnologia' | 'Alimentação' | null;
  subgroup?: string;
  icon?: string;
  localOnly?: boolean; // If it's true, do NOT send to server as a filter
}

export const Tags: Array<Tag> = [
  {name: 'Berçário', title: 'Berçário', group: 'Nível de Ensino', icon: 'foundation', localOnly: true},
  {name: 'Infantil', title: 'Infantil', group: 'Nível de Ensino', icon: 'foundation', localOnly: true},
  {name: 'Fundamental I', title: 'Fundamental I', group: 'Nível de Ensino', icon: 'foundation', localOnly: true},
  {name: 'Fundamental II', title: 'Fundamental II', group: 'Nível de Ensino', icon: 'foundation', localOnly: true},
  {name: 'Ensino Médio', title: 'Ensino Médio', group: 'Nível de Ensino', icon: 'foundation', localOnly: true},

  {name: 'Antropológica', title: 'Antropológica', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Construtivista', title: 'Construtivista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  // {name: 'Democrática', title: 'Democrática', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Humanista', title: 'Humanista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Metodologia própria', title: 'Metodologia própria', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Montessoriana', title: 'Montessoriana', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Piaget', title: 'Piaget', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  // {name: 'Progressista', title: 'Progressista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  // {name: 'Progressista Humanista', title: 'Progressista Humanista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Progressista Libertadora/Freiriana', title: 'Progressista Libertadora/Freiriana', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Progressista Libertária/Democrática', title: 'Progressista Libertária/Democrática', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Reggio Emília', title: 'Reggio Emília', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Sóciointeracionista', title: 'Sóciointeracionista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Sócioconstrutivista', title: 'Sócioconstrutivista', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Tradicional', title: 'Tradicional', group: 'Abordagem Pedagógica', icon: '', localOnly: true},
  {name: 'Waldorf', title: 'Waldorf', group: 'Abordagem Pedagógica', icon: '', localOnly: true},

  {name: 'profissional_fonoaudiologia', title: 'Fonoaudióloga', group: 'Corpo Docente', icon: 'foundation'},
  {name: 'profissional_nutricao', title: 'Nutricionista', group: 'Corpo Docente', icon: 'foundation'},
  {name: 'profissional_psicologia', title: 'Psicóloga', group: 'Corpo Docente', icon: 'foundation'},

  // {name: 'infra_estacionamento', title: 'Estacionamento', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_climatizada', title: 'Sala Climatizada', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_banheiro_adaptado', title: 'Banheiro Adaptado', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_quadra', title: 'Quadra', group: 'Infraestrutura', icon: 'foundation'},
  {name: 'infra_quadra_coberta', title: 'Quadra Coberta', group: 'Infraestrutura', icon: 'foundation'},
  {name: 'infra_quadra_descoberta', title: 'Quadra Descoberta', group: 'Infraestrutura', icon: 'foundation'},
  {name: 'infra_piscina', title: 'Piscina', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_esportes', title: 'Sala de Esportes', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_artes', title: 'Sala de Artes', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_leitura', title: 'Sala de Leitura', group: 'Infraestrutura', icon: 'foundation'},
  {name: 'infra_parque', title: 'Parque', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_patio_coberto', title: 'Pátio Coberto', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_patio_descoberto', title: 'Pátio Descoberto', group: 'Infraestrutura', icon: 'foundation'},
  {name: 'infra_area_verde', title: 'Área Verde', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_viveiro', title: 'Viveiro', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_camera', title: 'Câmera', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_auditorio', title: 'Auditório', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_musica', title: 'Sala de Música', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_biblioteca', title: 'Biblioteca', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_laboratorio_ciencias', title: 'Laboratório de Ciências', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_laboratorio_robotica', title: 'Laboratório de Robótica', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_enfermaria', title: 'Enfermaria', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_cantina', title: 'Cantina', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_refeitorio', title: 'Refeitório', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_transporte_proprio', title: 'Transporte Próprio', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_transporte_terceiro', title: 'Transporte Tercerizado', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_berco', title: 'Berço', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_sala_amamentacao', title: 'Sala de Amamentação', group: 'Infraestrutura', icon: 'foundation'},
  // {name: 'infra_lactario', title: 'Lactátio', group: 'Infraestrutura', icon: 'foundation'},
  //
  {name: 'acessibilidade_infra_vao_livre', title: 'Vão Livre', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_infra_corrimao', title: 'Corrimão e Garda Corpos', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_infra_rampa', title: 'Rampa para Deficientes', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_infra_elevador', title: 'Elevadores para Deficientes', group: 'Acessibilidade', icon: 'accessible'},
  {
    name: 'acessibilidade_infra_banheiro_deficiente',
    title: 'Banheiro adaptado para Deficinentes',
    group: 'Acessibilidade',
    icon: 'accessible'
  },
  {name: 'acessibilidade_sinalizacao_piso_tatil', title: 'Piso Tátil', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_sinalizacao_sonora', title: 'Sinalização Sonora', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_sinalizacao_tatil', title: 'Sinalização Tátil', group: 'Acessibilidade', icon: 'accessible'},
  {name: 'acessibilidade_sinalizacao_visual', title: 'Sinalização Visual', group: 'Acessibilidade', icon: 'accessible'},
  //
  // {name: 'disciplinas_adicionais_ensino_religioso', title: 'Ensino Religioso', group: 'Disciplinas Adicionais'},
  // {
  //   name: 'disciplinas_adicionais_convenio_com_escolas_no_exterior',
  //   title: 'Convênio com Escolas no Exterior',
  //   group: 'Disciplinas Adicionais'
  // },
  // {name: 'disciplinas_adicionais_duplo_diploma', title: 'Duplo Diploma', group: 'Disciplinas Adicionais'},
  // {name: 'disciplinas_adicionais_educacao_empreendedora', title: 'Educação Empreendedora', group: 'Disciplinas Adicionais'},
  // {name: 'disciplinas_adicionais_educacao_socioemocional', title: 'Educação Socioemocional', group: 'Disciplinas Adicionais'},
  // {
  //   name: 'disciplinas_adicionais_educacao_nutricional_culinaria',
  //   title: 'Educação Nutricional/Culinária',
  //   group: 'Disciplinas Adicionais'
  // },
  // {name: 'disciplinas_adicionais_aulas_de_programacao_coding', title: 'Aulas de Programação/Coding', group: 'Disciplinas Adicionais'},
  // {
  //   name: 'disciplinas_adicionais_oportunidades_de_voluntariado',
  //   title: 'Oportunidades de Voluntariado',
  //   group: 'Disciplinas Adicionais'
  // },
  // {name: 'extracurricular_artes_desenho', title: 'Desenho', group: 'Extracurricular', icon: 'emoji_objects'},
  // {name: 'extracurricular_artes_literatura', title: 'Literatura', group: 'Extracurricular', icon: 'emoji_objects'},
  // {name: 'extracurricular_artes_musica', title: 'Música', group: 'Extracurricular', icon: 'emoji_objects'},
  // {name: 'extracurricular_artes_prod_artistica', title: 'Produção Artística', group: 'Extracurricular', icon: 'emoji_objects'},
  // {name: 'extracurricular_artes_teatro', title: 'Teatro', group: 'Extracurricular', icon: 'emoji_objects'},
  //
  // {name: 'extracurricular_esporte_artes_marciais', title: 'Artes Marciais', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_arquearia', title: 'Arquearia', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_atletismo', title: 'Atletismo', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_badminton', title: 'Badminton', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_basquete', title: 'Basquete', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_circo', title: 'Circo', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_dancas', title: 'Danças', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_esgrima', title: 'Esgrima', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_futebol', title: 'Futebol', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_ginastica', title: 'Ginástica', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_handebol', title: 'Handebol', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_hoquei', title: 'Hoquei', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_ioga', title: 'Ioga', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_natacao', title: 'Natação', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_patinacao', title: 'Patinação', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_rugby', title: 'Rugby', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_skate', title: 'Skate', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_tchoukball', title: 'Tchoukball', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_tenis', title: 'Tênis', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_tenis_mesa', title: 'Tênis de Mesa', group: 'Extracurricular', icon: 'sports'},
  // {name: 'extracurricular_esporte_voleibol', title: 'Voleibol', group: 'Extracurricular', icon: 'sports'},
  //
  // {name: 'extracurricular_habilidade_logica_catequese', title: 'Catequese', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_culinaria', title: 'Culinaria', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_educ_ambiental', title: 'Educação Ambiental', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_educ_financeira', title: 'Educação Financeira', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_empreendedorismo', title: 'Empreendedorismo', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_horticultura', title: 'Horticultura', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_idiomas', title: 'Idiomas', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_libras', title: 'Libras', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_maker', title: 'Maker', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_orint_vocacional', title: 'Orientação Vocacional', group: 'Extracurricular', icon: 'book'},
  // {
  //   name: 'extracurricular_habilidade_logica_perp_vestibular',
  //   title: 'Preparação para Vestibular',
  //   group: 'Extracurricular',
  //   icon: 'book'
  // },
  // {name: 'extracurricular_habilidade_logica_prep_enem', title: 'Preparação para ENEM', group: 'Extracurricular', icon: 'book'},
  // {name: 'extracurricular_habilidade_logica_robotica', title: 'Robótica', group: 'Extracurricular', icon: 'book'},
  //
  // {name: 'material_didatico_apostilado', title: 'Material Didático Apostilado', group: null, icon: 'miscellaneous_services'},
  // {name: 'valor_materialdidatico', title: 'Material Didático Incluso na Mensalidade', group: null, icon: 'miscellaneous_services'},
  // {name: 'pais_embaixadores', title: 'Pais Embaixadores', group: null, icon: 'miscellaneous_services'},
  //
  // {name: 'recursos_pedagogico_jogos_educativos', title: 'Brinquedos para Educação Infantil', group: 'Recursos'},
  // {name: 'recursos_pedagogico_material_cultural', title: 'Jogos Educativos', group: 'Recursos'},
  // {name: 'recursos_pedagogico_material_cientifico', title: 'Materiais para Atividades Culturais e Artísticas', group: 'Recursos'},
  //
  // {name: 'recursos_recreativo_brinquedo_infantil', title: 'Conjunto de Materiais Científicos', group: 'Recursos'},
  // {name: 'recursos_recreativo_instrumentos', title: 'Instrumentos Musicais', group: 'Recursos'},
  // {name: 'recursos_recreativo_material_desportivo', title: 'Materiais para Prática Desportiva e Recreação', group: 'Recursos'},
  //
  // {name: 'tecnologia_recurso_sala_multimidia', title: 'Sala Multimídia', group: 'Tecnologia', icon: 'computer'},
  // {name: 'tecnologia_recurso_lousa_digital', title: 'Lousa Digital', group: 'Tecnologia', icon: 'computer'},
  {name: 'tecnologia_recurso_desktop_aluno', title: 'Desktop para uso dos Alunos', group: 'Tecnologia', icon: 'computer'},
  {name: 'tecnologia_recurso_notebook_aluno', title: 'Notebook para uso dos Alunos', group: 'Tecnologia', icon: 'computer'},
  {name: 'tecnologia_recurso_tablet_aluno', title: 'Tablet para uso dos Alunos', group: 'Tecnologia', icon: 'computer'},
  //
  {name: 'tecnologia_internet_aluno', title: 'Acesso a Internet para Alunos', group: 'Tecnologia', icon: 'computer'},
  {name: 'tecnologia_internet_ensino', title: 'Acesso a Internet para Ensino e Aprendizagem', group: 'Tecnologia', icon: 'computer'},
  //
  // {name: 'alimentacao_organica', title: 'Orgânica', group: 'Alimentação', icon: 'restaurant'},
  // {name: 'alimentacao_restricoes', title: 'Restrições', group: 'Alimentação', icon: 'restaurant'}
];

export const AbordagemPedagogica: Tag[] = Tags.filter(value => {
  return  value.group === 'Abordagem Pedagógica';
});

export const AbordagemPedagogicaText: string[] = Tags.filter(value => {
  return value.group === 'Abordagem Pedagógica';
}).map(value => {
  return value.name;
});

//   [
//   'Antropológica', 'Construtivista', 'Democrática', 'Humanista', 'Metodologia própria', 'Montessoriana',
//   'Piaget', 'Progressista', 'Progressista Humanista', 'Reggio Emília', 'Sóciointeracionista', 'Sócioconstrutivista',
//   'Tradicional', 'Waldorf'
// ];

export function getTag(name: string): Tag {
  let tag: Tag = null;
  Tags.every(value => {
    if (value.name === name) {
      tag = value;
      return false;
    } else {
      return true;
    }
  });
  return tag;
}
