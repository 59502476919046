import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-duvidas',
  templateUrl: './page-duvidas.component.html',
  styleUrls: ['./page-duvidas.component.css', '../../../css/elementor/post-220.css', '../../../css/elementor/post-7239.css', '../../../css/elementor/global.css', '../../../css/elementor/frontend.min.css', '../../../css/elementor/style.css'],
})
export class PageDuvidasComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
