import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Escola} from '../../service/Escola';
import {EscolaListVerticalComponent} from '../../layout/escola-list-vertical/escola-list-vertical.component';
import firebase from 'firebase/app';

@Component({
  selector: 'app-user-escolas',
  templateUrl: './user-escolas.component.html',
  styleUrls: ['./user-escolas.component.css']
})
export class UserEscolasComponent implements OnInit, AfterViewInit {

  escolaCountFavorito = 0;
  filterMessageFavorito: string;
  pageSizeFavorito = 20;
  escolaListFavorito: Observable<Array<Escola>>;
  @ViewChild('escolaListVerticalFavorito', {static: false}) escolaListVerticalFavorito: EscolaListVerticalComponent;

  escolaCountPerfil = 0;
  filterMessagePerfil: string;
  pageSizePerfil = 20;
  escolaListPerfil: Observable<Array<Escola>>;
  @ViewChild('escolaListVerticalPerfil', {static: false}) escolaListVerticalPerfil: EscolaListVerticalComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  updatePaginatorFavorito(): void {
    const data: any = {
      totalRows: true,
      favorito: true,
    };

    data.offset = (this.escolaListVerticalFavorito.matPaginator == null) ?
      0 :
      this.escolaListVerticalFavorito.matPaginator.pageIndex * this.escolaListVerticalFavorito.matPaginator.pageSize;
    data.limit = this.pageSizeFavorito;

    this.filterMessageFavorito = 'Procurando escolas...';

    firebase.functions().httpsCallable('escola')(data).then(value => {
      this.escolaListFavorito = of(value.data.rows);
      // console.log(`escolas: ${JSON.stringify(value.data)}`);
      this.escolaCountFavorito = value.data.totalRows;
      if (value.data.rows.length > 0) {
        this.filterMessageFavorito = null;
      } else {
        this.escolaListFavorito = null;
        this.filterMessageFavorito = 'Nenhuma escola foi encontrada.';
      }
    });
  }

  updatePaginatorPerfil(): void {
    const data: any = {
      totalRows: true,
      perfil: 'comprado',
    };

    data.offset = (this.escolaListVerticalPerfil.matPaginator == null) ?
      0 :
      this.escolaListVerticalPerfil.matPaginator.pageIndex * this.escolaListVerticalPerfil.matPaginator.pageSize;
    data.limit = this.pageSizePerfil;

    this.filterMessagePerfil = 'Procurando escolas...';

    firebase.functions().httpsCallable('escola')(data).then(value => {
      this.escolaListPerfil = of(value.data.rows);
      // console.log(`escolas: ${JSON.stringify(value.data)}`);
      this.escolaCountPerfil = value.data.totalRows;
      if (value.data.rows.length > 0) {
        this.filterMessagePerfil = null;
      } else {
        this.escolaListPerfil = null;
        this.filterMessagePerfil = 'Nenhuma escola foi encontrada.';
      }
    });
  }

}
