<div class="w-100" style="height: 100px">

</div>

<div>
  <!--  url(../images/cellep/cellep_back.jpg)-->
  <div class="content">
    <div class="container">
      <!-- H1 -->
      <div class="cellep-header">
        <img class="cellep-logo start-0 w-100" src="/images/cellep/logo_branca.svg" style="padding-bottom: 20px" fxHide fxShow.lt-sm>
        <div class="content-title d-flex flex-row align-items-center justify-content-center">
          <img class="cellep-logo start-0" src="/images/cellep/logo_branca.svg" fxShow fxHide.lt-sm>
          <div class="cellep-logo-title w-100">Encontre uma unidade Cel.Lep</div>
        </div>
      </div>
      <div style="margin: 50px 20px">
        <h1>Fluência no Inglês com a melhor escola do país!</h1>
        <h3>
          O Cel.Lep tem todas as soluções que você precisa para conquistar a fluência em um segundo idioma.
          Nova Plataforma de aprendizagem.
          Evolução e autonomia para o desenvolvimento do aluno.
          Bicampeã como a melhor escola de idiomas do país pelo prêmio Top Educação.
          Centro Preparatório para os exames de Cambridge English.
        </h3>
        <a href="https://br.cellep.com/unidades?utm_source=schooladvisor" target="_blank" (click)="analytics.linkCellep()">
          https://br.cellep.com/unidades
        </a>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <mat-form-field appearance="fill">
    <mat-label>Unidades</mat-label>
    <mat-select name="Unidades" [disabled]="loading">
      <mat-option *ngFor="let item of cellep_list" (onSelectionChange)="scrollTo(item.nome)">
        {{item.nome}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<section class="cellep-unidades">
  <ng-template [ngIf]="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
  <div
    class="container mat-elevation-z5 cellep-card-div" *ngFor="let item of cellep_list">
    <div class="cellep-inner-div" [id]="item.nome">
      <span class="cellep-city">{{item.cidade}}</span>
      <h2>{{item.nome}}</h2>
      <p>{{item.endereco}}</p>
      <p></p>
      <p>Segunda a sexta-feira: {{item.horario}}<br>
        Sábado: {{item.horario_fds}}</p>
      <p></p>
      <div class="telefones">Telefone:
        <ul>
          <li *ngFor="let zap of item.fone">
            {{zap | mask: '(00) 00000-0000||(00) 0000-0000'}}
          </li>
        </ul>
      </div>
      <div class="telefones">WhatsApp:
        <ul>
          <li *ngFor="let zap of item.whatsapp">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=55{{zap}}&text=Oi%20encontrei%20o%20cellep%20*{{item.nome}}*%20pelo%20site%20do%20SchoolAdvisor"
              (click)="analytics.linkCellepWhatsapp(item.nome)">
              {{zap | mask: '(00) 00000-0000||(00) 0000-0000'}}
            </a>
          </li>
        </ul>
      </div>
      <p><strong>Cursos:</strong> Inglês e Programação</p>
      <p><strong>Turmas:</strong> Kids, Teens e Adultos</p>
    </div>
  </div>
</section>
