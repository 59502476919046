import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';
import {UserService} from '../../../service/user.service';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return {passwordsNotMatching: true};
};

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;
  layout: 'row' | 'column' = 'row';

  // Private
  private unsubscribeAll: Subject<any>;

  constructor(
    public userService: UserService,
    private formBuilder: FormBuilder,
  ) {
    // Set the private defaults
    this.unsubscribeAll = new Subject();

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
    });
  }

  ngOnInit(): void {

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.registerForm
      .controls
      .password
      .valueChanges.pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.registerForm.controls.passwordConfirm.updateValueAndValidity();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

}
