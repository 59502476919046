import { EscolaService } from '../../service/escola.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Escola } from 'src/app/service/Escola';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';


@Component({
  selector: 'app-aside-menu-new',
  templateUrl: './aside-menu-new.component.html',
  styleUrls: ['./aside-menu-new.component.css'],
})
export class AsideMenuNewComponent implements OnInit, OnDestroy {

  public escola: Escola;
  private escolaAtualSubscription: Subscription;

  constructor(private router: Router,
              private escolaService: EscolaService
  ) {}

  ngOnInit(): void {
    let that = this;


    $(document).ready(() => {
      // ASIDE MENU
      $('.btn-aside-menu .btn-menu').on('click', function () {
        that.openMenu();
      });

      // CLOSE ASIDE MENU
      $('.close-menu').on('click', function () {
        that.closeMenu();
      });
    });

    this.escolaAtualSubscription = this.escolaService.getEscolaAtualObservable().subscribe(
      (e: Escola) => {
        this.escola = e;
      }
    );
  }

  ngOnDestroy() {
    if (this.escolaAtualSubscription) {
      this.escolaAtualSubscription.unsubscribe();
    }
  }

  openMenu() {
    $('.aside-menu').addClass('active');
  }

  closeMenu() {
    $('.aside-menu').removeClass('active');
  }

  async scrollToSection(event: Event, sectionId: string) {
    event.preventDefault();
    await this.router.navigate([], { fragment: sectionId });
    this.closeMenu();
    
    
    
    /*.then(() => {
      const element: HTMLElement = document.getElementById(sectionId);
      console.log(element);

      if (element) {
        window.scrollBy({ 
          behavior: 'smooth', 
          top: element.getBoundingClientRect().top - 100 
        });
        //element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });*/
  }
}
