import {EventEmitter, Injectable, NgZone, Optional, Output, SkipSelf} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase/app';
import User = firebase.User;
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Escola} from './Escola';
import {ICON_DEFAULT, ICON_SELECTED} from './Map';
import {AnalyticsService} from './analytics.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {DialogConfirmData} from '../navigation/auth/login-dialog/dialog-confirm-data';
import {DialogOptIn, LoginDialogComponent} from '../navigation/auth/login-dialog/login-dialog.component';
import AuthProvider = firebase.auth.AuthProvider;
import {Perfil} from '../layout/perfil-afinidade/perfil-afinidade.component';
import {Subject} from 'rxjs';
import { convertToObject } from 'typescript';

export interface UserData {
  permission?: any;
  escola?: number[];
  master?: boolean;
  beta?: string;
  perfil?: Perfil;
  data_aceite?: any;
  last_prompt?: any;
  data_optin?: any;
  telefone?: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private static currentUser: User = null;
  // private static _userData: any = null; // Save logged in user data

  userDataEvent = new Subject<{user?: User, permission?: Permission[], loading?: boolean}>();
  // @Output() public userDataEvent: EventEmitter<{ user: User, permission: Permission[] }> =
  //     new EventEmitter<{ user: User, permission: Permission[] }>();

  constructor(
      public auth: AngularFireAuth,
      public firestore: AngularFirestore,   // Inject Firestore service
      private snackBar: MatSnackBar,
      private matDialog: MatDialog,
      private analytics: AnalyticsService,
      public router: Router,
      @Optional() @SkipSelf() parentModule?: UserService) {
    if (parentModule) {
      throw new Error('UserService is already loaded. Import it in AppModule only');
    }

    // register Google one-tap method callback
    // @ts-ignore
    window['oneTapCallback'] = {
      load: (response) => {
        // console.log('OneTapCallback: ' + JSON.stringify(response));
        // (window as any).cred = response;

        const c = firebase.auth.GoogleAuthProvider.credential(response.credential);
        // Sign in with credential from the Google user.
        firebase.auth().signInWithCredential(c);
      }
    };

    // // Saving user data in localstorage when  logged in and setting up null when logged out
    // this.auth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null);
    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // });

    this.auth.authState.subscribe(async value => {
      UserService.currentUser = value;
      if (value == null) {
        localStorage.removeItem('user_data'); // UserService._userData = null;
        this.userDataEvent.next(null);
      } else {
        await this.loadUserData();
      }
    });
  }

  async loadUserData(): Promise<void> {

    this.userDataEvent.next({loading: true});
    firebase.functions().httpsCallable('user_data')()
        .then(value1 => {
          if (value1.data) {
            // UserService._userData = value1.data;
            console.log('user_data: ' + JSON.stringify(value1.data));
            localStorage.setItem('user_data', JSON.stringify(value1.data));
            this.userDataEvent.next({user: this.getCurrentUser(), permission: this.permission});

            let showPrompt = false;
            if (this.userData) {
              if (!this.userData.data_aceite) {
                showPrompt = true;
              }

              // const now = new Date().getTime();
              // // const weekOld = new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24 * 7));
              // const last_prompt_plus_7_days =
              //     (this.userData.last_prompt ? this.userData.last_prompt : now)
              //     + (1000 * 60 * 60 * 24 * 7);
              //
              // // No optIn and older than 7days
              // if (!this.userData.data_optin && (now > last_prompt_plus_7_days)) {
              //   showPrompt = true;
              // }
            }

            if (showPrompt) {
              this.matDialog.open(DialogOptIn, {
                data: {data_aceite: this.userData.data_aceite, data_optin: this.userData.data_optin},
                disableClose: false,
              });

              return;
            }

            /*const displayName = this.getCurrentUser().displayName;
            const telefone = this.userData.telefone;

            if (!telefone)
              this.matDialog.open(DialogConfirmData, {data: {telefone}, disableClose: false});*/
          }
        })
        .catch(reason => {
          console.error(reason);
        });
  }

  getCurrentUser(): User {
    return UserService.currentUser;
  }

  get permission(): Permission[] {
    return this.userData?.permission;
  }

  get master(): boolean {
    return this.userData?.master;
  }

  get beta(): string {
    return this.userData?.beta;
  }

  get userData(): UserData {
    return JSON.parse(localStorage.getItem('user_data'));
    // return UserService._userData;
  }

  proprietario(escola: Escola): void {
    if (this.getCurrentUser()) {
      firebase.functions().httpsCallable('usuario_escola_add')({cod_escola: escola.codigo}).then(value1 => {
        this.snackBar.open(value1.data.message, 'Ok', {duration: 5000});
      });
    } else {
      const message = 'Efetue o login para solicitar a edição dessa página.';
      this.snackBar.open(message, 'Ok', {duration: 2000});
      this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
    }
  }

  setFavorito(escola: Escola, value): void {
    if (this.getCurrentUser()) {
      escola.favorito = value;
      this.analytics.favoritoEscola(escola, value);
      if (value) {
        firebase.functions().httpsCallable('favorito_add')({cod_escola: escola.codigo})
            .then(() => escola.marker?.setIcon(ICON_SELECTED));
      } else {
        firebase.functions().httpsCallable('favorito_remove')({cod_escola: escola.codigo})
            .then(() => escola.marker?.setIcon(ICON_DEFAULT));
      }
    } else {
      this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'});
    }
  }

  // Sign in with email/password
  signIn(email, password): void {
    this.auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          this.setUserData(userCredential.user);
          window.location.reload();
          return userCredential;
        })
        .catch((error) => {
          // window.alert(error.message);
          console.log(error);
          const message = translateError(error.code);
          this.snackBar.open(message, 'Ok');
        });
  }

  // Sign up with email/password
  signUp(email, password, displayName = null): void {
    this.auth.createUserWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
          // Call the SendVerificaitonMail() function when new user sign up and returns promise
          // this.SendVerificationMail();
          let user = userCredential.user;
          console.log('signUp(1): ' + JSON.stringify(user));
          await user.updateProfile({
            displayName: displayName
          })
          console.log('signUp(3): ' + JSON.stringify(user));
          this.setUserData(user);
          return userCredential;
        })
        .catch((error) => {
          // window.alert(error.message);
          console.log(error);
          const message = translateError(error.code);
          this.snackBar.open(message, 'Ok');
        });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail(): void {
    // return this.afAuth.currentUser.sendEmailVerification()
    //   .then(() => {
    //     this.router.navigate(['verify-email-address']);
    //   });
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail): ReturnType<firebase.auth.Auth['sendPasswordResetEmail']> {
    return this.auth.sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          window.alert('Password reset email sent, check your inbox.');
        }).catch((error) => {
          window.alert(error);
        });
  }

  // Sign in with Google
  googleAuth(): void {
    this.authLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Sign in with Google
  facebookAuth(): void {
    this.authLogin(new firebase.auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  authLogin(provider: AuthProvider): void {
    this.auth.signInWithPopup(provider)
        .then((result) => {
          this.setUserData(result.user);
          console.log("Reload user")
          window.location.reload();
        }).catch((error) => {
      this.snackBar.open('Ocorreu um erro ao tentar acessar pelo ' + provider.providerId, 'Ok', {duration: 2000});
    });
  }

  // Setting up user data when sign in with username/password,
  // sign up with username/password and sign in with social auth
  // provider in Firestore database using AngularFirestore + AngularFirestoreDocument service
  private async setUserData(user): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);

    const userData: UserInterface = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      data_aceite: undefined,
      data_optin: undefined,
      gestor_funcionario_escola: user.gestor_funcionario_escola,
      responsavel_crianca: user.responsavel_crianca,
      fornecedor: user.fornecedor,
      aluno: user.aluno,
      telefone: user.telefone,
      data_matricula: user.data_matricula,
      mensalidade_media: user.mensalidade_media,
      empresa: user.empresa,
      escola: user.escola,
      last_prompt: firebase.firestore.FieldValue.serverTimestamp()
    };

    if (user.data_aceite) {
      userData.data_aceite = firebase.firestore.FieldValue.serverTimestamp();
    }
    if (user.data_optin) {
      userData.data_optin = firebase.firestore.FieldValue.serverTimestamp();
    }

    // let c: firebase.firestore.Timestamp;
    // c = new firebase.firestore.Timestamp(1, 0);

    // Delete undefined attributes
    Object.keys(userData).forEach(key => userData[key] === undefined && delete userData[key]);

    // console.log(`setUserData(): ` + JSON.stringify(userData));

    return userRef.set(userData, {merge: true});
  }

  async updateUserData(user): Promise<void> {
    await this.setUserData(user);
    // await this.loadUserData();
  }

  // Sign out
  signOut(): Promise<void> {
    return this.auth.signOut().then(() => {
      // localStorage.removeItem('user');
      localStorage.removeItem('user_data');
    });
  }

  async deleteUser(): Promise<void> {
    const uid = this.getCurrentUser().uid;
    // console.log('delete auth user');
    await this.getCurrentUser().delete().then(async () => {
      const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${uid}`);
      // console.log('delete firebase user');
      await userRef.delete();
      // console.log('firebase user deleted');
    });
    return;
  }

  setPerfil(value) {
    const userData = this.getCurrentUser();
    this.userData.perfil = value;
    localStorage.setItem('user_data', JSON.stringify(value.data));
    this.userDataEvent.next({user: this.getCurrentUser(), permission: this.permission});
  }
}

export interface Permission {
  permissao: string;
  value: string;
}

export interface UserInterface {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  data_aceite: any;
  data_optin: any;
  gestor_funcionario_escola: boolean;
  responsavel_crianca: boolean;
  fornecedor: boolean;
  aluno: boolean;
  telefone: string;
  data_matricula: any;
  mensalidade_media: number;
  empresa: string;
  escola: string;
  last_prompt: any;
}

export function translateError(errorCode: string): string {
  const msg = firebase_auth_error[errorCode];
  console.log(msg);
  if (msg) {
    return msg;
  } else {
    return 'Erro desconhecido: ' + errorCode;
  }
}

// tslint:disable:max-line-length
export const firebase_auth_error = {
  // Found at https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
  'invalid-argument': 'Erro: Um argumento inválido foi fornecido.',
  'invalid-disabled-field': 'Erro: O valor fornecido para a propriedade de usuário é inválido.',
  'auth/app-deleted': 'A aplicação foi removida.',
  'auth/app-not-authorized': 'Aplicação não está autorizada.',
  'auth/argument-error': 'Argumentos estão errados.',
  'auth/invalid-api-key': 'Chave da API está inválida.',
  'auth/invalid-user-token': 'Token do usuário está inválido.',
  'auth/invalid-tenant-id': 'ID do usuário (inquilina) está inválido.',
  'auth/network-request-failed': 'Erro na rede ou conexão.',
  'auth/requires-recent-login': 'É necessário o último login.',
  'auth/too-many-requests': 'Muitas solicitação foram feitas, aguarde até que seja desbloqueado.',
  'auth/unauthorized-domain': 'O domínio não está autorizado.',
  'auth/user-disabled': 'Usuário foi desabilitado.',
  'auth/user-token-expired': 'Token do usuário está expirado.',
  'auth/web-storage-unsupported': 'Seu navegador não suporta essa versão (web-storage).',
  // https://firebase.google.com/docs/auth/admin/errors?hl=pt
  'auth/claims-too-large': 'O payload de declarações fornecido para setCustomUserClaims() excede o tamanho máximo permitido de 1.000 bytes.',
  'auth/email-already-in-use': 'O e-mail fornecido já está registrado.',
  'auth/email-already-exists': 'O e-mail fornecido foi registrado por outro usuário.',
  'auth/id-token-expired': 'O token de código provisionado expirou.',
  'auth/id-token-revoked': 'O token de ID foi revogado.',
  'auth/insufficient-permission': 'A credencial usada para inicializar o SDK Admin não tem permissão para acessar o recurso solicitado do Authentication. Consulte Configurar um projeto para ver a documentação sobre como gerar uma credencial com as permissões apropriadas e usá-la na autenticação dos SDKs Admin.',
  'auth/internal-error': 'O servidor do Authentication encontrou um erro inesperado ao tentar processar a solicitação. A mensagem de erro incluirá a resposta do servidor de autenticação com informações adicionais. Se o erro persistir, informe o problema ao nosso canal de suporte de Relatório do bug.',
  'auth/invalid-argument': 'Um argumento inválido foi fornecido a um método do Authentication. A mensagem de erro precisa conter informações adicionais.',
  'auth/invalid-claims': 'Os atributos de declaração personalizados fornecidos para setCustomUserClaims() são inválidos.',
  'auth/invalid-continue-uri': 'O URL de confirmação precisa ser uma string de URL válida.',
  'auth/invalid-creation-time': 'O horário da criação precisa ser um string de data UTC válido.',
  'auth/invalid-credential': 'A credencial usada para autenticar os SDKs Admin não pode ser usada para executar a ação desejada. Determinados métodos de autenticação, como createCustomToken() e verifyIdToken(), requerem que o SDK seja inicializado com uma credencial de certificado em oposição a um token de atualização ou uma credencial padrão do aplicativo. Consulte Inicializar o SDK para ver a documentação sobre como autenticar os Admin SDKs com uma credencial de certificado.',
  'auth/invalid-disabled-field': 'O valor fornecido para a propriedade do usuário disabled é inválido. Precisa ser um valor booleano.',
  'auth/invalid-display-name': 'O valor fornecido para a propriedade do usuário displayName é inválido. Precisa ser uma string não vazia.',
  'auth/invalid-dynamic-link-domain': 'O domínio de link dinâmico fornecido não está configurado ou autorizado para o projeto atual.',
  'auth/invalid-email': 'O valor fornecido para a propriedade do usuário email é inválido. Precisa ser um endereço de e-mail de string.',
  'auth/invalid-email-verified': 'O valor fornecido para a propriedade do usuário emailVerified é inválido. Precisa ser um valor booleano.',
  'auth/invalid-hash-algorithm': 'O algoritmo de hash precisa corresponder a uma das strings na lista de algoritmos compatíveis.',
  'auth/invalid-hash-block-size': 'O tamanho do bloco de hash precisa ser um número válido.',
  'auth/invalid-hash-derived-key-length': 'O tamanho da chave derivada do hash precisa ser um número válido.',
  'auth/invalid-hash-key': 'A chave de hash precisa ter um buffer de byte válido.',
  'auth/invalid-hash-memory-cost': 'O custo da memória hash precisa ser um número válido.',
  'auth/invalid-hash-parallelization': 'O carregamento em paralelo do hash precisa ser um número válido.',
  'auth/invalid-hash-rounds': 'O arredondamento de hash precisa ser um número válido.',
  'auth/invalid-hash-salt-separator': 'O campo do separador de "salt" do algoritmo de geração de hash precisa ser um buffer de byte válido.',
  'auth/invalid-id-token': 'O token de código informado não é um token de código válido.',
  'auth/invalid-last-sign-in-time': 'O último horário de login precisa ser um string de data UTC válido.',
  'auth/invalid-page-token': 'O token de próxima página fornecido em listUsers() é inválido. Precisa ser uma string não vazia válida.',
  'auth/invalid-password': 'O valor fornecido para a propriedade do usuário password é inválido. Precisa ser uma string com pelo menos seis caracteres.',
  'auth/invalid-password-hash': 'O hash da senha precisa ser um buffer de byte válido.',
  'auth/invalid-password-salt': 'O "salt" da senha precisa ser um buffer de byte válido',
  'auth/invalid-phone-number': 'O valor fornecido para phoneNumber é inválido. Ele precisa ser uma string de identificador compatível com o padrão E.164 não vazio.',
  'auth/invalid-photo-url': 'O valor fornecido para a propriedade do usuário photoURL é inválido. Precisa ser um URL de string.',
  'auth/invalid-provider-data': 'O providerData precisa ser uma matriz válida de objetos UserInfo.',
  'auth/invalid-provider-id': 'O providerId precisa ser um string de identificador de provedor compatível válido.',
  'auth/invalid-oauth-responsetype': 'Apenas um responseType do OAuth deve ser definido como verdadeiro.',
  'auth/invalid-session-cookie-duration': 'A duração do cookie da sessão precisa ser um número válido em milissegundos entre 5 minutos e 2 semanas.',
  'auth/invalid-uid': 'O uid fornecido precisa ser uma string não vazia com no máximo 128 caracteres.',
  'auth/invalid-user-import': 'O registro do usuário a ser importado é inválido.',
  'auth/maximum-user-count-exceeded': 'O número máximo permitido de usuários a serem importados foi excedido.',
  'auth/missing-android-pkg-name': 'Um nome de pacote Android precisa ser fornecido para a instalação do app Android.',
  'auth/missing-continue-uri': 'Um URL de confirmação válido precisa ser fornecido na solicitação.',
  'auth/missing-hash-algorithm': 'É necessário fornecer o algoritmo de geração de hash e seus parâmetros para importar usuários com hashes de senha.',
  'auth/missing-ios-bundle-id': 'A solicitação está sem o ID do pacote do iOS.',
  'auth/missing-uid': 'Um identificador uid é necessário para a operação atual.',
  'auth/missing-oauth-client-secret': 'A chave secreta do cliente de configuração do OAuth é necessária para ativar o fluxo de código do OIDC.',
  'auth/operation-not-allowed': 'O provedor de login fornecido está desativado para o projeto. Ative-o na seção Método de login do Console.',
  'auth/phone-number-already-exists': 'O phoneNumber fornecido já está sendo usado por um usuário existente. É necessário que cada usuário tenha um phoneNumber exclusivo.',
  'auth/project-not-found': 'Nenhum projeto foi encontrado com a credencial usada para inicializar os Admin SDKs. Consulte Configurar um projeto para ver a documentação sobre como gerar uma credencial para seu projeto e usá-la na autenticação dos Admin SDKs.',
  'auth/reserved-claims': 'Uma ou mais declarações de usuário personalizadas fornecidas para setCustomUserClaims() são reservadas. Por exemplo, não use as declarações específicas do OIDC, como sub, iat, iss, exp, aud, auth_time etc., como chaves para declarações personalizadas.',
  'auth/session-cookie-expired': 'O cookie da sessão fornecido expirou.',
  'auth/session-cookie-revoked': 'O cookie da sessão foi revogado.',
  'auth/uid-already-exists': 'O uid fornecido já está sendo usado por um usuário existente. É necessário que cada usuário tenha um uid exclusivo.',
  'auth/unauthorized-continue-uri': 'O domínio da URL de confirmação não está na lista de permissões. Acesse o Console para colocar o domínio na lista de permissões.',
  'auth/user-not-found': 'Não há registro de usuário existente correspondente ao fornecido.',

  // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
  'auth/account-exists-with-different-credential': 'Essa conta já existe com outra credencial.',
  'auth/auth-domain-config-requiredThrown': 'Esse domínio não esta configurado para autenticar.',
  'auth/cancelled-popup-requestThrown': 'O popup para autenticar foi cancelado.',
  'auth/operation-not-allowedThrown': 'if the type of account corresponding to the credential is not enabled. Enable the account type in the Firebase Console, under the Auth tab.',
  'auth/operation-not-supported-in-this-environmentThrown': 'if this operation is not supported in the environment your application is running on. "location.protocol" must be http or https.',
  'auth/popup-blockedThrown': 'Seu popup para autenticar foi bloqueado. Por favor libere popups para fazer o login.',
  'auth/popup-closed-by-userThrown': 'O popup para autenticar foi fechado.',
  'auth/unauthorized-domainThrown': 'O domínio não esta autorizado para acessar.',

// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithphonenumber
  'auth/captcha-check-failed': 'Erro no captcha',
  // 'auth/invalid-phone-number': 'Thrown if the phone number has an invalid format.',
  'auth/missing-phone-number': 'O número do telefone está faltando.',
  'auth/quota-exceeded': 'A quota de SMS foi excedida.',
  // 'auth/user-disabled': 'Thrown if the user corresponding to the given phone number has been disabled.',
  // 'auth/operation-not-allowed': 'Thrown if you have not enabled the provider in the Firebase Console. Go to the Firebase Console for your project, in the Auth section and the Sign in Method tab and configure the provider.',

  // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
  // 'auth/account-exists-with-different-credential': 'Thrown if there already exists an account with the email address asserted by the credential. Resolve this by calling firebase.auth.Auth.fetchSignInMethodsForEmail and then asking the user to sign in using one of the returned providers. Once the user is signed in, the original credential can be linked to the user with firebase.User.linkWithCredential.',
  // 'auth/invalid-credential': 'Thrown if the credential is malformed or has expired.',
  // 'auth/operation-not-allowed': 'Thrown if the type of account corresponding to the credential is not enabled. Enable the account type in the Firebase Console, under the Auth tab.',
  // 'auth/user-disabled': 'Thrown if the user corresponding to the given credential has been disabled.',
  // 'auth/user-not-found': 'Thrown if signing in with a credential from firebase.auth.EmailAuthProvider.credential and there is no user corresponding to the given email.',
  'auth/wrong-password': 'A senha esta errada para esse e-mail ou o usuário não existe.',
  'auth/invalid-verification-code': 'Código da credencial está inválido.',
  'auth/invalid-verification-id': 'Id da credencial está inválido.',

  // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
  // 'auth/invalid-email': 'Thrown if the email address is not valid.',
  // 'auth/missing-android-pkg-name': 'An Android package name must be provided if the Android app is required to be installed.',
  // 'auth/missing-continue-uri': 'A continue URL must be provided in the request.',
  // 'auth/missing-ios-bundle-id': 'An iOS Bundle ID must be provided if an App Store ID is provided.',
  // 'auth/invalid-continue-uri': 'The continue URL provided in the request is invalid.',
  // 'auth/unauthorized-continue-uri': 'The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.',
  // 'auth/user-not-found': 'Thrown if there is no user corresponding to the email address.',

  // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#verifypasswordresetcode
  'auth/expired-action-code': 'O código para fazer o reset da senha expirou.',
  'auth/invalid-action-code': 'O código para fazer o reset da senha está inválido ou já foi usado.',
  // 'auth/user-disabled': 'Thrown if the user corresponding to the given password reset code has been disabled.',
  // 'auth/user-not-found': 'Thrown if there is no user corresponding to the password reset code. This may have happened if the user was deleted between when the code was issued and when this method was called.',
};
// tslint:enable:max-line-length
