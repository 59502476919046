import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import firebase from 'firebase/app';
import { Escola } from '../../service/Escola';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../service/user.service';
import { AnalyticsService } from '../../service/analytics.service';
import { ScriptLoaderService } from '../../service/script-loader.service';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-escola-recomendadas',
  templateUrl: './escola-recomendadas.component.html',
  styleUrls: ['./escola-recomendadas.component.css'],
})
export class EscolaRecomendadasComponent implements OnInit, AfterViewInit {
  @Input() escola: Escola;
  escolasRecomendadas: Escola[] = [];
  blocked = true;


  constructor(private matDialog: MatDialog, private cdr: ChangeDetectorRef, public userService: UserService, private analytics: AnalyticsService, private scriptLoader: ScriptLoaderService) {}

  ngOnInit(): void {
    let listaIds = [...this.escola.lista_escolas, this.escola.lista_concorrentes];

    if (listaIds.length === 0) return;

    listaIds = listaIds.filter((id) => id !== undefined && id !== null);
    listaIds = listaIds.filter((id) => id !== undefined && id !== null);
    listaIds = listaIds.filter((id, index) => id && listaIds.indexOf(id) === index);
    
    const data: any = { lista_codigo: listaIds, fields: 'recommended' };

    firebase
      .functions()
      .httpsCallable('escola')(data)
      .then((value) => {
        if (value.data.rows.length > 0) {
          value.data.rows.forEach((row: any) => {
            if (row.codigo === this.escola.codigo) return;
            const index = this.escolasRecomendadas.findIndex((escola) => escola.codigo === row.codigo);

            if (index === -1)
              this.escolasRecomendadas.push(row);
          });

          this.cdr.detectChanges();
          this.loadCarousel();
        }
      });
  }

  ngAfterViewInit() {
    const a = document.querySelectorAll('.more-school-info');
    this.loadCarousel();
  }

  loadCarousel(): void {
    this.scriptLoader
      .loadScript('fotorama', '../../../js/new/bootstrap.bundle.min.js')
      .then(() => {
        let itemsLogos = document.querySelectorAll('.carousel.content-school .carousel-item');

        if (this.escolasRecomendadas.length <= 4) return;

        itemsLogos.forEach((el) => {
          const minPerSlide = 4;
          let next = el.nextElementSibling;
          for (var i = 1; i < minPerSlide; i++) {
            if (!next) {
              // wrap carousel by using first child
              next = itemsLogos[0];
            }
            let cloneChild = next.cloneNode(true);
            el.appendChild((cloneChild as Element).children[0]);
            next = next.nextElementSibling;
          }
        });
      })
      .catch((error) => console.error('Erro ao carregar o script Fotorama:', error));
  }

  unlockInformation(): void {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.analytics.viewEscolaField(this.escola, 'recomendadas', user);

    this.blocked = false;
  }
}
