<div class="mb-5 background-ice ads-sliders">
  <div class="container">
    <!-- SLIDER COMPONENT -->
    <div id="recipeAds" class="carousel slide slider ads-four" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <figure class="ads">
              <img src="images/new/img-Padrao-iAB-Retângulo-Medio.jpg" alt="Publicidade" />
            </figure>
          </div>
        </div>
      </div>
      <a class="control carousel-control-prev" href="#recipeAds" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="control carousel-control-next" href="#recipeAds" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <!-- END SLIDER COMPONENT -->
  </div>
</div>
