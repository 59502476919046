<div class="mb-5 background-ice ads-sliders">
  <div class="container">
    <!-- TITLE -->
    <div class="col-12 text-center mb-5">
      <h2 class="text-black font-size-biggest mb-0">Confira os últimos artigos enviados pela escola</h2>
    </div>
    <!-- END TITLE -->

    <!-- SLIDER CONTENT TEXT -->
    <div id="recipeContentSchool" class="carousel slide slider content-school" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper eget cras ipsunm</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla euismod sapien donec bibendum duis quis euismod. Orci, tortor dictumst scelerisque malesuada volutpat amet, massa. In risus arcu nunc, integer at iaculis sed. Cras ultrices sem malesuada enim eu donec. Nullam nulla euismod sapien donec bibendum duis quis euismod.</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper eget cras ipsunm</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper eget cras ipsunm</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper eget cras ipsunm</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-3 col-12">
            <h3 class="text-black font-size-regular bold mb-2">Lorem ipsum dolor sit</h3>
            <p class="font-size-regular text-lightgray mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper eget cras ipsunm</p>
          </div>
        </div>
      </div>
      <a class="control carousel-control-prev" href="#recipeContentSchool" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="control carousel-control-next" href="#recipeContentSchool" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <!-- END SLIDER CONTENT TEXT -->
  </div>
</div>
