import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {EscolaService, filterEventType} from '../../service/escola.service';
import {Router} from '@angular/router';
import {Tags} from '../../service/Tag';
import {Escola} from '../../service/Escola';
import {debounceTime, startWith, switchMap, tap, map} from 'rxjs/operators';
import firebase from 'firebase';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {NivelEnsinoArray} from '../../service/NivelEnsino';
import {UrlNormalizerPipe} from '../../pipe/url-normalizer.pipe';
import Autocomplete = google.maps.places.Autocomplete;
import {UserService} from '../../service/user.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-escola-filter-header',
  templateUrl: './escola-filter-header.component.html',
  styleUrls: ['./escola-filter-header.component.css']
})
export class EscolaFilterHeaderComponent implements OnInit {

  // @ViewChild('searchDiv', {static: true}) searchDiv: HTMLDivElement;
  @ViewChild('regiaoInput', {static: true}) regiaoInput: ElementRef<HTMLInputElement>;
  @ViewChild('nivelEnsinoInput') nivelEnsinoInput: ElementRef<HTMLInputElement>;
  // @ViewChild('btnSearchMap') btnSearchMap: ElementRef<HTMLButtonElement>;
  @Output() searchButtonEvent = new EventEmitter();
  autoCompleteObj: Autocomplete;
  placeholderRegion = true;
  nivelTags = Tags.filter(value => value.group === 'Nível de Ensino');
  mensalidadeArray: Array<number> = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];
  placeholderEscola = true;
  isLoadingEscola = false;
  isLoadingUser = false;
  loadingMessage: string = null;
  escolaNome: Escola[] | string[] = [];
  selectedEscola: Escola = null;
  filteredNivelEnsino: Observable<Array<any>>;
  nivelEnsinoControl = new FormControl('');

  private usePerfil = false;
  

  minTuitionValue = 0
  maxTuitionValue = 8000
  showTuition = false

  sliderOptions = {
    floor: 0,
    ceil: 8000,
    step: 1000,
    translate: (value: number): string => {
      if (value <= 0)
        return '';
      else if (value >= 8000)
        return '';
      else
        return `R$ ${value}`;
    }
  };

  constructor(
      public escolaService: EscolaService,
      public router: Router,
      public userService: UserService,
      private urlNormalizerPipe: UrlNormalizerPipe,
  ) {
  }

  ngOnInit(): void {
    this.escolaService.place.valueChanges.pipe(
        tap((value) => {
          this.escolaService.nome.setValue(value);
        }),
        debounceTime(1500),
        switchMap(value => {
          // the value can be a string or a Escola type
          if (typeof value === 'string') {
            this.selectedEscola = null;
            if (value.length < 3) {
              this.loadingMessage = `Digite ao menos 3 letras para pesquisar.`;
            } else {
              this.isLoadingEscola = true;
              const criteria = {
                nome: value
              };
              this.loadingMessage = `Procurando escolas com nome '${value}'`;
              firebase.functions().httpsCallable('nome_escola')(criteria).then(value1 => {
                if (value1.data?.length > 0) {
                  this.escolaNome = value1.data;
                  this.escolaNome.forEach((value2) => {
                    if (typeof value2 !== 'string') {
                      value2.endereco_json = EscolaService.parseEndereco(value2);
                    }
                  });
                } else {
                  this.escolaNome = [];
                }
                this.isLoadingEscola = false;
              });
            }
          } else {
            this.isLoadingEscola = false;
            this.selectedEscola = value;
            // Traga somente a escola marcada
            this.escolaNome = [value];
          }
          return [];
        })
    ).subscribe();

    this.filteredNivelEnsino = this.nivelEnsinoControl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => {
          return this._filter(value);
        }));

    if (this.usePerfil) {
      this.userService.userDataEvent.subscribe(value => {
        if (value === null) {
          this.isLoadingUser = false;
        } else if (value.loading) {
          this.isLoadingUser = true;
        } else if (this.userService.userData?.perfil) {
          const perfil = this.userService.userData.perfil;
          this.escolaService.regiao.setValue(perfil.place);
          // console.log(JSON.stringify(perfil.place));
          // console.log(JSON.stringify(value));
          // this.regiaoInput.nativeElement.value = perfil.place.formatted_address;
          if (perfil.serie != null) {
            this.escolaService.nivelEnsino.setValue([perfil.serie]);
          }
          this.isLoadingUser = false;
        } else {
          this.escolaService.regiao.setValue(null);
          this.escolaService.nivelEnsino.setValue([]);
          this.isLoadingUser = false;
        }
      });
    }

    this.initAutocomplete();
  }


  initAutocomplete(): void {
    const nativeInput = this.regiaoInput.nativeElement;

    // Create the autocomplete object, restricting the search predictions to
    // @ts-ignore
    nativeInput.geolocate = () => {
      // @ts-ignore
      geolocate();
    };
    // geographical location types.
    this.autoCompleteObj = new google.maps.places.Autocomplete(
        nativeInput as HTMLInputElement,
        {
          types: ['geocode'], // 'establishment' / 'address' / 'geocode'
          componentRestrictions: {country: 'BR'}
        }
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components:
    // address_component, adr_address, business_status, formatted_address, geometry, icon, name, permanently_closed,
    // photo, place_id, plus_code, type, url, utc_offset, or vicinity
    // source: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#autocomplete
    this.autoCompleteObj.setFields(['address_component', 'geometry']);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autoCompleteObj.addListener('place_changed', () => {
      // Get the place details from the autocomplete object.
      const place = this.autoCompleteObj.getPlace();
      this.escolaService.regiao.setValue(place);
    });

    this.escolaService.regiao.valueChanges.subscribe(value => {
      if (value) {
        if (value.formatted_address) {
          nativeInput.value = value.formatted_address;
        } else if (value.address_components) {
          // nativeInput.value = parseAddressName(value.address_components);
          // nativeInput.value = value.address_components[0].long_name;
        }
      } else {
        nativeInput.value = null;
      }
    });
  }

  private _filter(value): Array<string> {
    // console.log(`Filter Value: ${value}`);
    const selected: string[] = this.escolaService.nivelEnsino.value;
    if (value == null) {
      if (selected == null) {
        return NivelEnsinoArray;
      } else {

        return NivelEnsinoArray.filter(value1 => {
          // console.log(`Null filter check: value1: ${value1} indexOf: ${selected.indexOf(value1)}`);
          if (selected.indexOf(value1) < 0) {
            return value1;
          }
        });
      }
    } else {

      const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
      const filtered = NivelEnsinoArray.filter((nivelEnsinoItem) => {
        const normalizedName = nivelEnsinoItem.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();

        if (normalizedName.indexOf(normalizedValue) >= 0) {

          if (selected == null) {
            return nivelEnsinoItem;
          } else if (selected.indexOf(nivelEnsinoItem) < 0) {
            return nivelEnsinoItem;
          }
        }
      });

      return filtered;
    }
  }

  public map(): void {
    this.showTuition = false
    this.escolaService.mensalidadeMin.setValue(this.minTuitionValue <= 0 ? null : this.minTuitionValue)
    this.escolaService.mensalidadeMax.setValue(this.maxTuitionValue >= 8000 ? null : this.maxTuitionValue)

    const goTo = '/mapa';
    if (this.router.url === goTo) {
      this.searchTrigger('map');
    } else {
      this.router.navigate([goTo]);
    }
    this.searchButtonEvent.emit();
  }

  showTuitionFilter(): void {
    this.showTuition = true
  }

  tuitionMessage(): string {
    if (this.minTuitionValue <= 0 && this.maxTuitionValue >= 8000)
      return 'Filtrar por mensalidades'

    if (this.minTuitionValue <= 0)
      return `Até R$ ${this.maxTuitionValue}`

    if (this.maxTuitionValue >= 8000)
      return `A partir de R$ ${this.minTuitionValue}`

    return `De R$ ${this.minTuitionValue} a R$ ${this.maxTuitionValue}`
  }

  displayEscola(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  searchTrigger(type: filterEventType): void {
    this.escolaService.filterEvent.next({type, reset: true});
  }

  public detail(escola: Escola): void {
    this.escolaService.nome.setValue(escola.nome);
    this.router.navigate(['escolas', escola.codigo, this.urlNormalizerPipe.transform(escola.nome)]).then(() => {
      // this.searchTrigger();
    });

    this.escolaService.place.setValue('');
    //this.nomeEscolaControl.setValue('');
    this.searchButtonEvent.emit();
  }

  searchEscola(type: filterEventType = 'map'): void {
    const goTo = '/busca-escolas';
    if (this.router.url === goTo) {
      this.searchTrigger('list');
    } else {
      this.router.navigate([goTo]);
    }
  }
}

export function parseAddressName(value: any): string {
  let street = null;
  let street_number = null;
  for (let i = 0, item; item === value[i]; i++) {
    if (item.types[0] === 'route') {
      street = item.long_name;
    }
    if (item.types[0] === 'street_number') {
      street_number = item.long_name;
    }
  }
  if (street_number) {
    return street + ', ' + street_number;
  } else if (street) {
    return street;
  } else {
    return '';
  }
}
