<div (click)="closeModal()" class="modal fade modal-component show" id="modalAvaliacao" tabindex="-1" aria-labelledby="filterModal" aria-hidden="true" style="display: block">
  <div (click)="$event.stopPropagation()" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <!-- HEADER MODAL -->
      <div class="modal-header">
        <!-- MODAL TITLE -->

        <input type="text" style="width: 1px; height: 1px;" />
        <div class="d-flex flex-column">
          <h2 class="text-black font-size-biggest mb-0">Qual nota você dá para cada um dos critérios abaixo?</h2>
        </div>

        <button type="button" class="close-modal" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- END HEADER MODAL -->

      <!-- BODY MODAL -->
      <div class="modal-body row">
        <!-- CONTENT MODAL -->
        <form class="content-rating">
          <div class="col-12 d-flex flex-wrap content-rating-stars mt-2 mb-1">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-building-estate.svg" alt="Infraestrutura" />
                <p class="font-size-small text-black mb-0">Infraestrutura</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star-5" type="radio" name="star" [checked]="this.review.rate_infraestrutura == 5" (click)="rate('rate_infraestrutura', 5)" />
                <label class="star star-5" for="star-5"></label>
                <input class="star star-4" id="star-4" type="radio" name="star" [checked]="this.review.rate_infraestrutura == 4" (click)="rate('rate_infraestrutura', 4)" />
                <label class="star star-4" for="star-4"></label>
                <input class="star star-3" id="star-3" type="radio" name="star" [checked]="this.review.rate_infraestrutura == 3" (click)="rate('rate_infraestrutura', 3)" />
                <label class="star star-3" for="star-3"></label>
                <input class="star star-2" id="star-2" type="radio" name="star" [checked]="this.review.rate_infraestrutura == 2" (click)="rate('rate_infraestrutura', 2)" />
                <label class="star star-2" for="star-2"></label>
                <input class="star star-1" id="star-1" type="radio" name="star" [checked]="this.review.rate_infraestrutura == 1" (click)="rate('rate_infraestrutura', 1)" />
                <label class="star star-1" for="star-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-security.svg" alt="Segurança" />
                <p class="font-size-small text-black mb-0">Segurança</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star2-5" type="radio" name="star2" [checked]="this.review.rate_seguranca == 5" (click)="rate('rate_seguranca', 5)" />
                <label class="star star-5" for="star2-5"></label>
                <input class="star star-4" id="star2-4" type="radio" name="star2" [checked]="this.review.rate_seguranca == 4" (click)="rate('rate_seguranca', 4)" />
                <label class="star star-4" for="star2-4"></label>
                <input class="star star-3" id="star2-3" type="radio" name="star2" [checked]="this.review.rate_seguranca == 3" (click)="rate('rate_seguranca', 3)" />
                <label class="star star-3" for="star2-3"></label>
                <input class="star star-2" id="star2-2" type="radio" name="star2" [checked]="this.review.rate_seguranca == 2" (click)="rate('rate_seguranca', 2)" />
                <label class="star star-2" for="star2-2"></label>
                <input class="star star-1" id="star2-1" type="radio" name="star2" [checked]="this.review.rate_seguranca == 1" (click)="rate('rate_seguranca', 1)" />
                <label class="star star-1" for="star2-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-certificate.svg" alt="Pedagógico" />
                <p class="font-size-small text-black mb-0">Pedagógico</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star3-5" type="radio" name="star3" [checked]="this.review.rate_pedagogico == 5" (click)="rate('rate_pedagogico', 5)" />
                <label class="star star-5" for="star3-5"></label>
                <input class="star star-4" id="star3-4" type="radio" name="star3" [checked]="this.review.rate_pedagogico == 4" (click)="rate('rate_pedagogico', 4)" />
                <label class="star star-4" for="star3-4"></label>
                <input class="star star-3" id="star3-3" type="radio" name="star3" [checked]="this.review.rate_pedagogico == 3" (click)="rate('rate_pedagogico', 3)" />
                <label class="star star-3" for="star3-3"></label>
                <input class="star star-2" id="star3-2" type="radio" name="star3" [checked]="this.review.rate_pedagogico == 2" (click)="rate('rate_pedagogico', 2)" />
                <label class="star star-2" for="star3-2"></label>
                <input class="star star-1" id="star3-1" type="radio" name="star3" [checked]="this.review.rate_pedagogico == 1" (click)="rate('rate_pedagogico', 1)" />
                <label class="star star-1" for="star3-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-heart-handshake.svg" alt="Acolhimento" />
                <p class="font-size-small text-black mb-0">Acolhimento</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star4-5" type="radio" name="star4" [checked]="this.review.rate_acolhimento == 5" (click)="rate('rate_acolhimento', 5)" />
                <label class="star star-5" for="star4-5"></label>
                <input class="star star-4" id="star4-4" type="radio" name="star4" [checked]="this.review.rate_acolhimento == 4" (click)="rate('rate_acolhimento', 4)" />
                <label class="star star-4" for="star4-4"></label>
                <input class="star star-3" id="star4-3" type="radio" name="star4" [checked]="this.review.rate_acolhimento == 3" (click)="rate('rate_acolhimento', 3)" />
                <label class="star star-3" for="star4-3"></label>
                <input class="star star-2" id="star4-2" type="radio" name="star4" [checked]="this.review.rate_acolhimento == 2" (click)="rate('rate_acolhimento', 2)" />
                <label class="star star-2" for="star4-2"></label>
                <input class="star star-1" id="star4-1" type="radio" name="star4" [checked]="this.review.rate_acolhimento == 1" (click)="rate('rate_acolhimento', 1)" />
                <label class="star star-1" for="star4-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-chess.svg" alt="Cursos extras" />
                <p class="font-size-small text-black mb-0">Cursos extras</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star5-5" type="radio" name="star5" [checked]="this.review.rate_extracurriculares == 5" (click)="rate('rate_extracurriculares', 5)" />
                <label class="star star-5" for="star5-5"></label>
                <input class="star star-4" id="star5-4" type="radio" name="star5" [checked]="this.review.rate_extracurriculares == 4" (click)="rate('rate_extracurriculares', 4)" />
                <label class="star star-4" for="star5-4"></label>
                <input class="star star-3" id="star5-3" type="radio" name="star5" [checked]="this.review.rate_extracurriculares == 3" (click)="rate('rate_extracurriculares', 3)" />
                <label class="star star-3" for="star5-3"></label>
                <input class="star star-2" id="star5-2" type="radio" name="star5" [checked]="this.review.rate_extracurriculares == 2" (click)="rate('rate_extracurriculares', 2)" />
                <label class="star star-2" for="star5-2"></label>
                <input class="star star-1" id="star5-1" type="radio" name="star5" [checked]="this.review.rate_extracurriculares == 1" (click)="rate('rate_extracurriculares', 1)" />
                <label class="star star-1" for="star5-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-user-star.svg" alt="Diversidade" />
                <p class="font-size-small text-black mb-0">Diversidade</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star6-5" type="radio" name="star6" [checked]="this.review.rate_diversidade == 5" (click)="rate('rate_diversidade', 5)" />
                <label class="star star-5" for="star6-5"></label>
                <input class="star star-4" id="star6-4" type="radio" name="star6" [checked]="this.review.rate_diversidade == 4" (click)="rate('rate_diversidade', 4)" />
                <label class="star star-4" for="star6-4"></label>
                <input class="star star-3" id="star6-3" type="radio" name="star6" [checked]="this.review.rate_diversidade == 3" (click)="rate('rate_diversidade', 3)" />
                <label class="star star-3" for="star6-3"></label>
                <input class="star star-2" id="star6-2" type="radio" name="star6" [checked]="this.review.rate_diversidade == 2" (click)="rate('rate_diversidade', 2)" />
                <label class="star star-2" for="star6-2"></label>
                <input class="star star-1" id="star6-1" type="radio" name="star6" [checked]="this.review.rate_diversidade == 1" (click)="rate('rate_diversidade', 1)" />
                <label class="star star-1" for="star6-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-accessible.svg" alt="Inclusão" />
                <p class="font-size-small text-black mb-0">Inclusão</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star7-5" type="radio" name="star7" [checked]="this.review.rate_inclusao == 5" (click)="rate('rate_inclusao', 5)" />
                <label class="star star-5" for="star7-5"></label>
                <input class="star star-4" id="star7-4" type="radio" name="star7" [checked]="this.review.rate_inclusao == 4" (click)="rate('rate_inclusao', 4)" />
                <label class="star star-4" for="star7-4"></label>
                <input class="star star-3" id="star7-3" type="radio" name="star7" [checked]="this.review.rate_inclusao == 3" (click)="rate('rate_inclusao', 3)" />
                <label class="star star-3" for="star7-3"></label>
                <input class="star star-2" id="star7-2" type="radio" name="star7" [checked]="this.review.rate_inclusao == 2" (click)="rate('rate_inclusao', 2)" />
                <label class="star star-2" for="star7-2"></label>
                <input class="star star-1" id="star7-1" type="radio" name="star7" [checked]="this.review.rate_inclusao == 1" (click)="rate('rate_inclusao', 1)" />
                <label class="star star-1" for="star7-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-accessible.svg" alt="Limpeza" />
                <p class="font-size-small text-black mb-0">Limpeza</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star8-5" type="radio" name="star8" [checked]="this.review.rate_higiene_limpeza == 5" (click)="rate('rate_higiene_limpeza', 5)" />
                <label class="star star-5" for="star8-5"></label>
                <input class="star star-4" id="star8-4" type="radio" name="star8" [checked]="this.review.rate_higiene_limpeza == 4" (click)="rate('rate_higiene_limpeza', 4)" />
                <label class="star star-4" for="star8-4"></label>
                <input class="star star-3" id="star8-3" type="radio" name="star8" [checked]="this.review.rate_higiene_limpeza == 3" (click)="rate('rate_higiene_limpeza', 3)" />
                <label class="star star-3" for="star8-3"></label>
                <input class="star star-2" id="star8-2" type="radio" name="star8" [checked]="this.review.rate_higiene_limpeza == 2" (click)="rate('rate_higiene_limpeza', 2)" />
                <label class="star star-2" for="star8-2"></label>
                <input class="star star-1" id="star8-1" type="radio" name="star8" [checked]="this.review.rate_higiene_limpeza == 1" (click)="rate('rate_higiene_limpeza', 1)" />
                <label class="star star-1" for="star8-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div class="rating-title">
                <img src="images/new/school/icon-accessible.svg" alt="Alimentação" />
                <p class="font-size-small text-black mb-0">Alimentação</p>
              </div>
              <!-- COMPONENT STAR RATING -->
              <div class="stars">
                <input class="star star-5" id="star9-5" type="radio" name="star9" [checked]="this.review.rate_alimentacao == 5" (click)="rate('rate_alimentacao', 5)" />
                <label class="star star-5" for="star9-5"></label>
                <input class="star star-4" id="star9-4" type="radio" name="star9" [checked]="this.review.rate_alimentacao == 4" (click)="rate('rate_alimentacao', 4)" />
                <label class="star star-4" for="star9-4"></label>
                <input class="star star-3" id="star9-3" type="radio" name="star9" [checked]="this.review.rate_alimentacao == 3" (click)="rate('rate_alimentacao', 3)" />
                <label class="star star-3" for="star9-3"></label>
                <input class="star star-2" id="star9-2" type="radio" name="star9" [checked]="this.review.rate_alimentacao == 2" (click)="rate('rate_alimentacao', 2)" />
                <label class="star star-2" for="star9-2"></label>
                <input class="star star-1" id="star9-1" type="radio" name="star9" [checked]="this.review.rate_alimentacao == 1" (click)="rate('rate_alimentacao', 1)" />
                <label class="star star-1" for="star9-1"></label>
              </div>
              <!-- END COMPONENT STAR RATING -->
            </div>
          </div>

          <div class="col-12 mb-4 pb-3">
            <!-- DIV -->
            <div class="div mt-0"></div>
            <!-- END DIV -->
          </div>

          <div class="col-12 d-flex flex-wrap content-rating-stars mb-3">
            <!-- TITLE -->
            <div class="col-12 mb-4 d-flex flex-wrap align-items-center justify-content-between">
              <h2 class="text-black font-size-big mb-0">Quais são os 3 principais diferenciais da escola?</h2>
              <small class="text-lightgray mb-0">Escolha até 3 Atributos</small>
            </div>
            <!-- END TITLE -->

            <!-- TAGS -->
            <div class="tags">
              <ul class="d-flex flex-wrap">
                <li *ngFor="let item of this.tags" class="tag clickable font-size-regular semibold" [ngClass]="{ active: this.tagsFormControl.value.indexOf(item) >= 0 }">
                  <button (click)="toggleSelect(item)" [title]="item">{{ item }}</button>
                </li>
              </ul>
            </div>
            <!-- END TAGS -->
          </div>

          <div class="col-12 mb-4 pb-3">
            <!-- DIV -->
            <div class="div mt-0"></div>
            <!-- END DIV -->
          </div>

          <div class="col-12 d-flex flex-wrap content-rating-stars mb-4">
            <!-- TITLE -->
            <div class="col-12 mb-4 d-flex flex-wrap align-items-center justify-content-between">
              <h2 class="text-black font-size-big mb-0">Conte um pouco mais sobre sua experiência.</h2>
            </div>
            <!-- END TITLE -->

            <div class="fields">
              <div class="form-floating">
                <textarea class="form-control" placeholder="Descreva sua experiência" id="floatingTextarea" [maxLength]="maxLength" [formControl]="descricaoFormControl" [disabled]="this.saving" #descricao></textarea>
                <label for="floatingTextarea">Descreva sua experiência</label>
                <div class="d-flex flex-wrap justify-content-between mt-2">
                  <small class="text-lightgray mb-0">Máximo: {{ this.maxLength }} caracteres</small>
                  <span class="max text-lightgray mb-0">{{ descricao.value.length }}/1000</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <!-- ACTION -->
            <div class="action d-flex align-items-end flex-column">
              <button type="button" (click)="saveReview()" class="btn background-pink text-white font-size-regular semibold">Enviar avaliação</button>
            </div>
            <!-- END ACTION -->
          </div>
        </form>
        <!-- END CONTENT MODAL -->
      </div>
      <!-- END BODY MODAL -->
    </div>
  </div>
</div>
