<div>
    <div class="content">
        <div class="container" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
            <app-escola-list-vertical style="width: 100%"
                                      [filterMessage]="this.filterMessage"
                                      [escolaCount]="this.escolaCount"
                                      [escolaList]="this.escolaList"
                                      [showPaginator]="true"
                                      (paginatorPage)="this.updatePagination($event)">
            </app-escola-list-vertical>
            <mat-progress-bar class="w-100" mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
        </div>
    </div>
</div>
