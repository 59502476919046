<div class="content">


  <article id="post-7141" class="post-7141 page type-page status-publish hentry">
    <div class="entry-content clearfix">
      <div data-elementor-type="wp-post" data-elementor-id="7141" class="elementor elementor-7141" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section
              class="elementor-element elementor-element-de052ac elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
              data-id="de052ac" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-6813b64 elementor-column elementor-col-50 elementor-top-column" data-id="6813b64" data-element_type="column">
                    <div class="elementor-column-wrap  elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-5506e74 elementor-widget elementor-widget-image" data-id="5506e74" data-element_type="widget" data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img class="attachment-medium_large size-medium_large lazy loaded" alt="" loading="lazy"
                                   data-src="/images/funciona/6meaq55m.bmp"
                                   data-lazy="/images/funciona/6meaq55m.bmp"
                                   src="/images/funciona/6meaq55m.bmp"
                                   data-was-processed="true" width="768" height="512"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-a9eabbc elementor-column elementor-col-50 elementor-top-column" data-id="a9eabbc" data-element_type="column">
                    <div class="elementor-column-wrap  elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-59b47e5 elementor-widget elementor-widget-text-editor" data-id="59b47e5" data-element_type="widget"
                             data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix"><h2>Famílias que utilizam a SchoolAdvisor precisam de menos visitas presenciais.</h2></div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-6a74813 elementor-widget elementor-widget-text-editor" data-id="6a74813" data-element_type="widget"
                             data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix"><h4><span style="font-weight: 400;">As famílias visitam em média 6 escolas antes de tomarem uma decisão. São mais de 12 horas dedicadas a essa escolha!</span><span
                              style="font-weight: 400;"><br></span><span style="font-weight: 400;"><br></span></h4><h4><span style="font-weight: 400;">A SchoolAdvisor permite que você faça uma comparação prévia entre as instituições e agende visitas presenciais apenas nas escolas que estão alinhadas às suas expectativas.</span>
                            </h4><h4><span style="font-weight: 400;"><br></span>Sabe o que isso significa? Mais tempo para você passar ao lado de quem ama!</h4></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-element elementor-element-6b7c773 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
              data-id="6b7c773" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-ffd6805 elementor-column elementor-col-100 elementor-top-column" data-id="ffd6805" data-element_type="column">
                    <div class="elementor-column-wrap  elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-78e839c elementor-widget elementor-widget-divider" data-id="78e839c" data-element_type="widget"
                             data-widget_type="divider.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-13febb7 elementor-widget elementor-widget-section_title" data-id="13febb7" data-element_type="widget"
                             data-widget_type="section_title.default">
                          <div class="elementor-widget-container">
                            <div class="section-title">
                              <h2>Conheça os tipos de páginas de escolas</h2>                                    <span class="section-separator"></span>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-490f668 elementor-widget elementor-widget-text-editor" data-id="490f668" data-element_type="widget"
                             data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix"><p>A SchoolAdvisor apresenta as páginas das escolas de três maneiras diferentes:</p></div>
                          </div>
                        </div>
                        <section
                          class="elementor-element elementor-element-01b62a4 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                          data-id="01b62a4" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-e861fe3 elementor-column elementor-col-33 elementor-inner-column" data-id="e861fe3" data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-7468473 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                         data-id="7468473" data-element_type="widget" data-widget_type="image-box.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image-box-wrapper">
                                          <figure class="elementor-image-box-img"><img
                                            src="/images/funciona/nao-verificada.png"
                                            title="nao-verificada" alt="nao-verificada"></figure>
                                          <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Página Padrão</h3>
                                            <p class="elementor-image-box-description">As informações são coletadas no Censo Escolar e complementadas de forma colaborativa pelas famílias.<br>Exibe até
                                              30 itens de observação.<br>Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola
                                              selecionada.</p></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-4721c21 elementor-column elementor-col-33 elementor-inner-column" data-id="4721c21" data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-13edfcd elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                         data-id="13edfcd" data-element_type="widget" data-widget_type="image-box.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image-box-wrapper">
                                          <figure class="elementor-image-box-img"><img
                                            src="/images/funciona/selo-verificada.png"
                                            title="selo-verificada" alt="selo-verificada"></figure>
                                          <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Página Verificada</h3>
                                            <p class="elementor-image-box-description">As informações são oficiais da escola. O ícone acima indica que a instituição é uma Página Verificada. <br>Exibe
                                              até 30 itens de observação.<br>Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola
                                              selecionada.</p></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-107138f elementor-column elementor-col-33 elementor-inner-column" data-id="107138f" data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-e2ff4f8 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                         data-id="e2ff4f8" data-element_type="widget" data-widget_type="image-box.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image-box-wrapper">
                                          <figure class="elementor-image-box-img"><img
                                            src="/images/funciona/selo-verificada.png"
                                            title="selo-verificada" alt="selo-verificada"></figure>
                                          <div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Página Premium</h3>
                                            <p class="elementor-image-box-description">As informações são oficiais da escola. O ícone acima indica que a instituição é uma Página Premium. Neste modelo,
                                              as escolas patrocinam sua visita ao perfil completo da instituição.<br>Assim, você pode acessar 70 itens de observação sem que haja necessidade de
                                              realizar nenhuma compra.</p></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div class="elementor-element elementor-element-e3f0b32 elementor-widget elementor-widget-text-editor" data-id="e3f0b32" data-element_type="widget"
                             data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix"><p>Ainda tem dúvidas? <a href="/duvidas/">Clique aqui</a> para acessar as Dúvidas Frequentes.
                            </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!--            <section-->
            <!--              class="elementor-element elementor-element-cfbff8d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"-->
            <!--              data-id="cfbff8d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">-->
            <!--              <div class="elementor-background-overlay"></div>-->
            <!--              <div class="elementor-container elementor-column-gap-default">-->
            <!--                <div class="elementor-row">-->
            <!--                  <div class="elementor-element elementor-element-871437b elementor-column elementor-col-100 elementor-top-column" data-id="871437b" data-element_type="column">-->
            <!--                    <div class="elementor-column-wrap">-->
            <!--                      <div class="elementor-widget-wrap">-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </section>-->
            <section
              class="elementor-element elementor-element-2ac0c7b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
              data-id="2ac0c7b" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-b4ff518 elementor-column elementor-col-100 elementor-top-column" data-id="b4ff518" data-element_type="column">
                    <div class="elementor-column-wrap  elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-78e839c elementor-widget elementor-widget-divider" data-id="78e839c" data-element_type="widget"
                             data-widget_type="divider.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-fbe36e6 elementor-widget elementor-widget-menu-anchor" data-id="fbe36e6" data-element_type="widget"
                             data-widget_type="menu-anchor.default">
                          <div class="elementor-widget-container">
                            <div id="servicos_para_voce" class="elementor-menu-anchor"></div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-3532bd6 elementor-widget elementor-widget-section_title" data-id="3532bd6" data-element_type="widget"
                             data-widget_type="section_title.default">
                          <div class="elementor-widget-container">
                            <div class="section-title">
                              <h2>Serviços para você!</h2>                                    <span class="section-separator"></span>
                            </div>
                          </div>
                        </div>
                        <section id="produtos"
                                 class="elementor-element elementor-element-4cabb10 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                 data-id="4cabb10" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element
                              elementor-element-df2e854 elementor-column
                              elementor-col-50 elementor-inner-column"
                                   data-id="df2e854" data-element_type="column"
                                   *ngFor="let item of pagseguroService.planos">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-c8b6b2b elementor-widget elementor-widget-spacer" data-id="c8b6b2b" data-element_type="widget"
                                         data-widget_type="spacer.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                          <div class="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-e16111e elementor-widget elementor-widget-heading" data-id="e16111e" data-element_type="widget"
                                         data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default" style="height: 3rem">{{item.nome}}</h3></div>
                                    </div>
                                    <div class="elementor-element elementor-element-80eb555 elementor-widget elementor-widget-heading" data-id="80eb555" data-element_type="widget"
                                         data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <br>
                                        <h5 class="elementor-heading-title elementor-size-default">
                                          &nbsp; {{(item.valor | currency:'BRL':'symbol':'1.2-2': 'pt-BR')}}
                                        </h5></div>
                                    </div>
                                    <div class="elementor-element elementor-element-d1d7c76 elementor-widget elementor-widget-divider" data-id="d1d7c76" data-element_type="widget"
                                         data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider">
			                                    <span class="elementor-divider-separator">
						                              </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-862e35a elementor-widget elementor-widget-text-editor" data-id="862e35a" data-element_type="widget"
                                         data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix" style="text-align: justify">
                                          <p *ngFor="let i of item.descricao">
                                            <span style="color: #000000; font-family: avenir-lt-w01_35-light1475496, sans-serif; font-size: 18px; text-align: left;">
                                              {{i}}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div style="padding-bottom: 30px;">
                                      <p *ngFor="let i of item.nota">
                                      <span style="color: #000000; font-family: avenir-lt-w01_35-light1475496, sans-serif; font-size: 14px; text-align: left">
                                        {{i}}</span>
                                      </p>
                                    </div>
                                    <div class="bottom-0 position-absolute elementor-element elementor-element-fdb7275 elementor-align-center elementor-widget elementor-widget-button"
                                         data-id="fdb7275"
                                         data-element_type="widget" data-widget_type="button.default">
                                      <span style="color: gray; font-family: avenir-lt-w01_35-light1475496, sans-serif; font-size: 18px; text-align: left;">
                                        {{item.disabledMessage}}
                                      </span>
                                      <button mat-raised-button color="primary" *ngIf="item.disabledMessage == null"
                                              [disabled]="saving"
                                              (click)="this.checkout(item)"> {{item.buttonLabel ? item.buttonLabel : 'Contratar'}}
                                        <mat-progress-bar mode="indeterminate" [style.display]="saving? '' : 'none'"></mat-progress-bar>
                                      </button>
                                    </div>
                                    <!--                                    <div class="elementor-element elementor-element-1af1b8c elementor-widget elementor-widget-spacer" data-id="1af1b8c" data-element_type="widget"-->
                                    <!--                                         data-widget_type="spacer.default">-->
                                    <!--                                      <div class="elementor-widget-container">-->
                                    <!--                                        <div class="elementor-spacer">-->
                                    <!--                                          <div class="elementor-spacer-inner"></div>-->
                                    <!--                                        </div>-->
                                    <!--                                      </div>-->
                                    <!--                                    </div>-->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-element elementor-element-fb3317e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                          data-id="fb3317e" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-f76c700 elementor-column elementor-col-33 elementor-inner-column" data-id="f76c700" data-element_type="column">
                                <div class="elementor-column-wrap">
                                  <div class="elementor-widget-wrap">
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-09e15e0 elementor-column elementor-col-33 elementor-inner-column" data-id="09e15e0" data-element_type="column">
                                <div class="elementor-column-wrap">
                                  <div class="elementor-widget-wrap">
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-b514889 elementor-column elementor-col-33 elementor-inner-column" data-id="b514889" data-element_type="column">
                                <div class="elementor-column-wrap">
                                  <div class="elementor-widget-wrap">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div><!-- .entry-content -->
  </article><!-- #post-## -->


</div>
