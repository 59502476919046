<aside class="aside-menu">
  <div class="row mb-4 d-flex align-items-center">
    <div class="col-9">
      <h2 class="font-size-featured text-black mb-0">Menu rápido</h2>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <a title="Fechar" class="close-menu">
        <img src="images/new/aside-menu/icon-close-menu.svg" alt="Fechar" />
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- NAV -->
      <nav>
        <ul>
          <li>
            <a href="#dados-principais" (click)="scrollToSection($event, 'dados-principais')" title="Dados Principais" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-dados-principais.svg" alt="Dados Principais" />
              <span class="font-size-small text-lightgray">Dados Principais</span>
            </a>
          </li>
          <li class="menu-mobile">
            <a href="#informacoes-importantes" (click)="scrollToSection($event, 'informacoes-importantes')" title="Informações Importantes" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-informacoes-importantes.svg" alt="Informações Importantes" />
              <span class="font-size-small text-lightgray">Informações Importantes</span>
            </a>
          </li>
          <li>
            <a href="#o-que-estao-falando" (click)="scrollToSection($event, 'o-que-estao-falando')" title="O que as pessoas estão falando sobre a escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-familia.svg" alt="O que as pessoas estão falando sobre a escola" />
              <span class="font-size-small text-lightgray">O que as pessoas estão falando sobre a escola</span>
            </a>
          </li>
          <li>
            <a href="#principais-caracteristicas" (click)="scrollToSection($event, 'principais-caracteristicas')" title="Principais características da escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-caracteristicas.svg" alt="Principais características da escola" />
              <span class="font-size-small text-lightgray">Principais características da escola</span>
            </a>
          </li>
          <li>
            <a href="#depoimentos" (click)="scrollToSection($event, 'depoimentos')" title="Avaliações" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-avaliacoes.svg" alt="Avaliações" />
              <span class="font-size-small text-lightgray">Avaliações</span>
            </a>
          </li>
          <li *ngIf="escola && escola.premium">
            <a href="#visao-especialista" (click)="scrollToSection($event, 'visao-especialista')" title="Visão do especialista" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-especialista.svg" alt="Visão do especialista" />
              <span class="font-size-small text-lightgray">Visão do especialista</span>
            </a>
          </li>
          <li>
            <a href="#perfil-escola" (click)="scrollToSection($event, 'perfil-escola')" title="Perfil da escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-niveis-ensino.svg" alt="Perfil da escola" />
              <span class="font-size-small text-lightgray">Perfil da escola</span>
            </a>
          </li>
          <li>
            <a href="#qualificacoes-escola" (click)="scrollToSection($event, 'qualificacoes-escola')" title="Confira outras qualificações da escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-qualificacoes.svg" alt="Confira outras qualificações da escola" />
              <span class="font-size-small text-lightgray">Confira outras qualificações da escola</span>
            </a>
          </li>
          <li>
            <a href="#mais-sobre-escola" (click)="scrollToSection($event, 'mais-sobre-escola')" title="Saiba mais sobre a escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-sobre.svg" alt="Saiba mais sobre a escola" />
              <span class="font-size-small text-lightgray">Saiba mais sobre a escola</span>
            </a>
          </li>
          <li>
            <a href="#niveis-ensino-oferecidos" (click)="scrollToSection($event, 'niveis-ensino-oferecidos')" title="Níveis de ensino oferecidos" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-indicadores.svg" alt="Níveis de ensino oferecidos" />
              <span class="font-size-small text-lightgray">Níveis de ensino oferecidos</span>
            </a>
          </li>
          <li>
            <a *ngIf="escola && escola.premium" href="#events" (click)="scrollToSection($event, 'events')" title="Inscreva-se em eventos e processo de ingresso" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-calendario.svg" alt="Inscreva-se em eventos e processo de ingresso" />
              <span class="font-size-small text-lightgray">Inscreva-se em eventos e processo de ingresso</span>
            </a>
          </li>
          <li>
            <a *ngIf="escola && escola.premium" href="#videos-escola" (click)="scrollToSection($event, 'videos-escola')" title="Assista aos vídeos da escola" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-videos.svg" alt="Assista aos vídeos da escola" />
              <span class="font-size-small text-lightgray">Assista aos vídeos da escola</span>
            </a>
          </li>
          <li>
            <a href="#infraestrutura-acessibilidade" (click)="scrollToSection($event, 'infraestrutura-acessibilidade')" title="Infraestrutura e Acessibilidade" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-infraestrutura.svg" alt="Infraestrutura e Acessibilidade" />
              <span class="font-size-small text-lightgray">Infraestrutura e Acessibilidade</span>
            </a>
          </li>
          <li>
            <a href="#professores-time-pedagogico" (click)="scrollToSection($event, 'professores-time-pedagogico')" title="Sobre os professores e time pedagógico" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-corpo-docente.svg" alt="Sobre os professores e time pedagógico" />
              <span class="font-size-small text-lightgray">Sobre os professores e time pedagógico</span>
            </a>
          </li>
          <li>
            <a href="#idiomas" (click)="scrollToSection($event, 'idiomas')" title="Idiomas, bilinguismo e educação internacional" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-idiomas.svg" alt="Idiomas, bilinguismo e educação internacional" />
              <span class="font-size-small text-lightgray">Idiomas, bilinguismo e educação internacional</span>
            </a>
          </li>
          <li>
            <a href="#esportes-cultura" (click)="scrollToSection($event, 'esportes-cultura')" title="Esportes, Cultura e Competições acadêmicas" class="font-size-small text-lightgray">
              <img src="images/new/aside-menu/menu-esportes.svg" alt="Esportes, Cultura e Competições acadêmicas" />
              <span class="font-size-small text-lightgray">Esportes, Cultura e Competições acadêmicas</span>
            </a>
          </li>
        </ul>
      </nav>
      <!-- END NAV -->
    </div>
  </div>
</aside>
