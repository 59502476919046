<div class="infos-adicionais col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-top" *ngIf="escola">
    <ng-template [ngIf]="!editing">
        
        <h2>Reconhecimentos</h2>
        <p><a href="https://blog.schooladvisor.com.br/Reconhecimentos-e-qualificacoes" target="_blank">Saiba mais sobre reconhecimentos</a></p>
        
        <div class="row align-items-baseline mb-0">
            <div class="infos row mb-0 pe-0 ps-0">
              <div *ngIf="familyChoice" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 div-selo-automatico">
                  <img src="images/logo-family-choice.svg" title="Selo do family choice">
              </div>
              <div *ngIf="showIIESA()" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 div-selo-automatico">
                  <img src="images/logo-iesa.png" title="Selo destaque infraestrutura">
              </div>
              <div *ngIf="false && showICDSA()" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 div-selo-automatico">
                  <img src="images/logo-icdsa.png" title="Selo destaque docente">
              </div>
            </div>
        </div>
        <h2>Qualificações</h2>
        <p><a href="https://blog.schooladvisor.com.br/Reconhecimentos-e-qualificacoes" target="_blank">Saiba mais sobre qualificações</a></p>

        <div class="infos row pe-0 ps-0">
            <ng-template [ngIf]="showSelo(escola.prevencao_acidentes, escola.prevencao_acidentes_validade)">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                    <img src="images/selos/logo-prevencao_acidentes.png" alt="Selo escolas pela prevenção de acidentes"/>
                </div>
            </ng-template>
            <ng-template [ngIf]="showSelo(escola.diversidade, escola.diversidade_validade)">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                    <img src="images/selos/logo-diversidade.png" alt="Selo escolas pela diversidade"/>
                </div>
            </ng-template>
            <ng-template [ngIf]="escola.clima">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                    <img src="images/selos/logo-clima.png" alt="Selo escolas pelo clima"/>
                </div>
            </ng-template>
        </div>

        <h2>Principais características da escola</h2>
        <div class="row">
            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="hasTags('school')">
                <h3>Na visão da <strong>escola</strong>, a escola se destaca por</h3>
                <div class="items d-flex flex-wrap">
                    <div class="item" *ngFor="let item of getTags('school') | slice:0:3">
                      <span>{{item}}</span>
                    </div>
                </div>
            </div>
            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="hasTags('family')">
                <h3>Na visão das <strong>famílias</strong>, a escola se destaca por</h3>
                <div class="items d-flex flex-wrap">
                    <div class="item" *ngFor="let item of getTags('family') | slice:0:3">
                      <span>{{item}}</span>
                    </div>
                </div>
            </div>

            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="hasTags('teachers')">
                <h3>Na visão dos <strong>professores</strong>, a escola se destaca por</h3>
                <div class="items d-flex flex-wrap">
                    <div class="item" *ngFor="let item of getTags('teachers') | slice:0:3">
                      <span>{{item}}</span>
                    </div>
                </div>
            </div>
            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="hasTags('students')">
                <h3>Na visão dos <strong>estudantes</strong>, a escola se destaca por</h3>
                <div class="items d-flex flex-wrap">
                    <div class="item" *ngFor="let item of getTags('students') | slice:0:3">
                      <span>{{item}}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="editing">
        <h2 *ngIf="(userService.master == true)">Reconhecimentos e Qualificações</h2>
        <div *ngIf="(userService.master == true)" class="row align-items-baseline">
            <div class="infos col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 row">
                <div class="col-4">
                    <img src="images/logo-family-choice.svg" title="Selo do family choice">
                </div>
                <div class="col-4">
                    <img src="images/logo-iesa.png" title="Selo destaque infraestrutura">
                    <ng-template [ngIf]="userService.master == true">
                        <input maxLength="10" matInput [ngModel]="escola.indices['IIESA']"
                               (ngModelChange)="escola.indices['IIESA'] = $event">
                    </ng-template>
                </div>
                <div class="col-4" *ngIf="false">
                    <img src="images/logo-icdsa.png" title="Selo destaque docente">
                    <ng-template [ngIf]="(userService.master == true)">
                        <input maxLength="10" matInput [ngModel]="escola.indices['ICDSA'] | number:'1.0-0':'pt-BR'"
                               (ngModelChange)="escola.indices['ICDSA'] =  this.decimalParser($event)">
                    </ng-template>
                </div>
            </div>
            <div class="infos col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 row">
                <div class="col-4">
                    <p class="text-center">
                        <img src="images/selos/logo-prevencao_acidentes.png" style="width: 60px !important; max-height: fit-content;" alt="Selo escolas pela prevenção de acidentes"/>
                        <br />
                        <mat-checkbox [(ngModel)]="escola.prevencao_acidentes"></mat-checkbox>
                        <ng-template [ngIf]="escola.prevencao_acidentes">
                            <br />
                            <input type="date" matInput maxLength="100" placeholder="Validade" [(ngModel)]="humanPrevencaoAcidentesValidate">
                        </ng-template>
                    </p>
                </div>
                <div class="col-4">
                    <p class="text-center">
                        <img src="images/selos/logo-diversidade.png" style="width: 60px !important; max-height: fit-content;" alt="Selo escolas pela diversidade"/>
                        <ng-template [ngIf]="userService.master">
                            <br />
                            <mat-checkbox [(ngModel)]="escola.diversidade"></mat-checkbox>
                            <ng-template [ngIf]="escola.diversidade">
                                <br />
                                <input type="date" matInput maxLength="100" placeholder="Validade" [(ngModel)]="humanDiversidadeValidade">
                            </ng-template>
                        </ng-template>
                    </p>
                </div>
                <div class="col-4">
                    <p class="text-center">
                        <img src="images/selos/logo-clima.png" style="width: 60px !important; max-height: fit-content;" alt="Selo escolas pelo clima"/>
                        <ng-template [ngIf]="userService.master">
                            <br />
                            <mat-checkbox [(ngModel)]="escola.clima"></mat-checkbox>
                        </ng-template>
                    </p>
                </div>
            </div>
        </div>


        <h2>Principais características SchoolAdvisor</h2>
        <div class="row">
            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <h3>Na visão da <strong>escola</strong>, a escola se destaca por</h3>
                <mat-form-field floatLabel="always">
                    <mat-chip-list #chipList aria-label="Atributos">
                        <mat-chip
                            *ngFor="let item of getTags('school')"
                            selectable removable
                            (removed)="removeTag('school', item)">
                            {{item}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-hint *ngIf="maxAttributos > 0">Escolha até {{maxAttributos}} Atributos</mat-hint>
                    <mat-select (selectionChange)="selectedTagOption('school', $event)" class="mt-3" *ngIf="(getTags('school').length < this.maxAttributos)">
                        <mat-option *ngFor="let item of filteredTags | async" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="userService.master == true">
                <h3>Na visão da <strong>família</strong>, a escola se destaca por</h3>
                <mat-form-field floatLabel="always">
                    <mat-chip-list #chipList aria-label="Atributos">
                        <mat-chip
                            *ngFor="let item of getTags('family')"
                            selectable removable
                            (removed)="removeTag('family', item)">
                            {{item}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-hint *ngIf="maxAttributos > 0">Escolha até {{maxAttributos}} Atributos</mat-hint>
                    <mat-select (selectionChange)="selectedTagOption('family', $event)" class="mt-3" *ngIf="(getTags('family').length < this.maxAttributos)">
                        <mat-option *ngFor="let item of filteredTags | async" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="userService.master == true">
                <h3>Na visão da <strong>professores</strong>, a escola se destaca por</h3>
                <mat-form-field floatLabel="always">
                    <mat-chip-list #chipList aria-label="Atributos">
                        <mat-chip
                            *ngFor="let item of getTags('teachers')"
                            selectable removable
                            (removed)="removeTag('teachers', item)">
                            {{item}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-hint *ngIf="maxAttributos > 0">Escolha até {{maxAttributos}} Atributos</mat-hint>
                    <mat-select (selectionChange)="selectedTagOption('teachers', $event)" class="mt-3" *ngIf="(getTags('teachers').length < this.maxAttributos)">
                        <mat-option *ngFor="let item of filteredTags | async" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="infos col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="userService.master == true">
                <h3>Na visão da <strong>estudantes</strong>, a escola se destaca por</h3>
                <mat-form-field floatLabel="always">
                    <mat-chip-list #chipList aria-label="Atributos">
                        <mat-chip
                            *ngFor="let item of getTags('students')"
                            selectable removable
                            (removed)="removeTag('students', item)">
                            {{item}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-hint *ngIf="maxAttributos > 0">Escolha até {{maxAttributos}} Atributos</mat-hint>
                    <mat-select (selectionChange)="selectedTagOption('students', $event)" class="mt-3" *ngIf="(getTags('students').length < this.maxAttributos)">
                        <mat-option *ngFor="let item of filteredTags | async" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </ng-template>
</div>