import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-esportes-cultura',
  templateUrl: './escola-esportes-cultura.component.html',
  styleUrls: ['./escola-esportes-cultura.component.css'],
})
export class EscolaEsportesCulturaComponent implements OnInit {
  @Input() escola: Escola;
  isExpanded = false;

  constructor() {}

  ngOnInit(): void {}

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  get hasSobre(): boolean {
    return this.escola.extracurricular_sobre && this.escola.extracurricular_sobre.trim() !== '';
  }

  get hasModalidades(): boolean {
    return this.escola.extracurricular_esporte_atletismo ||
      this.escola.extracurricular_esporte_arquearia ||
      this.escola.extracurricular_esporte_artes_marciais ||
      this.escola.extracurricular_esporte_badminton ||
      this.escola.extracurricular_esporte_ballet ||
      this.escola.extracurricular_esporte_basquete ||
      this.escola.extracurricular_esporte_circo ||
      this.escola.extracurricular_esporte_dancas ||
      this.escola.extracurricular_esporte_esgrima ||
      this.escola.extracurricular_esporte_futebol ||
      this.escola.extracurricular_esporte_ginastica ||
      this.escola.extracurricular_esporte_handebol ||
      this.escola.extracurricular_esporte_hiphop ||
      this.escola.extracurricular_esporte_hoquei ||
      this.escola.extracurricular_esporte_ioga ||
      this.escola.extracurricular_esporte_judo ||
      this.escola.extracurricular_esporte_natacao ||
      this.escola.extracurricular_esporte_patinacao ||
      this.escola.extracurricular_esporte_rugby ||
      this.escola.extracurricular_esporte_skate ||
      this.escola.extracurricular_esporte_tenis ||
      this.escola.extracurricular_esporte_tenis_mesa ||
      this.escola.extracurricular_esporte_voleibol ||
      this.escola.extracurricular_habilidade_logica_catequese ||
      this.escola.extracurricular_habilidade_logica_culinaria ||
      this.escola.extracurricular_habilidade_logica_educ_ambiental ||
      this.escola.extracurricular_habilidade_logica_educ_financeira ||
      this.escola.extracurricular_habilidade_logica_empreendedorismo ||
      this.escola.extracurricular_habilidade_logica_horticultura ||
      this.escola.extracurricular_habilidade_logica_idiomas ||
      this.escola.extracurricular_habilidade_logica_libras ||
      this.escola.extracurricular_habilidade_logica_maker ||
      this.escola.extracurricular_habilidade_logica_orint_vocacional ||
      this.escola.extracurricular_habilidade_logica_perp_vestibular ||
      this.escola.extracurricular_habilidade_logica_prep_enem ||
      this.escola.extracurricular_habilidade_logica_robotica ||
      this.escola.extracurricular_artes_desenho ||
      this.escola.extracurricular_artes_literatura ||
      this.escola.extracurricular_artes_musica ||
      this.escola.extracurricular_artes_prod_artistica ||
      this.escola.extracurricular_artes_teatro;
  }

  get hasCompeticoes(): boolean {
    return this.escola.competicoes && this.escola.competicoes.length > 0;
  }

  get hasData(): boolean {
    return this.hasSobre || this.hasModalidades || this.hasCompeticoes;
  } 

  renderModalidades(): string {
    let modalidades = [];
    if (this.escola.extracurricular_esporte_atletismo == true) {
      modalidades.push('Atletismo');
    } else {
      //modalidades.push('<s>Atletismo</s>');
    }
    if (this.escola.extracurricular_esporte_arquearia == true) {
      modalidades.push('Arquearia');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_artes_marciais == true) {
      modalidades.push('Artes Marciais');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_badminton == true) {
      modalidades.push('Badminton');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_ballet == true) {
      modalidades.push('Ballet');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_basquete == true) {
      modalidades.push('Basquete');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_circo == true) {
      modalidades.push('Circo');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_dancas == true) {
      modalidades.push('Danças');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_esgrima == true) {
      modalidades.push('Esgrima');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_futebol == true) {
      modalidades.push('Futebol');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_ginastica == true) {
      modalidades.push('Ginástica');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_handebol == true) {
      modalidades.push('Handebol');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_hiphop == true) {
      modalidades.push('Hip Hop');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_hoquei == true) {
      modalidades.push('Hoquei');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_ioga == true) {
      modalidades.push('Ioga');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_judo == true) {
      modalidades.push('Judô');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_natacao == true) {
      modalidades.push('Natação');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_patinacao == true) {
      modalidades.push('Patinação');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_rugby == true) {
      modalidades.push('Rugby');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_skate == true) {
      modalidades.push('Skate');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_tenis == true) {
      modalidades.push('Tênis');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_tenis_mesa == true) {
      modalidades.push('Tênis de Mesa');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }
    if (this.escola.extracurricular_esporte_voleibol == true) {
      modalidades.push('Voleibol');
    } else {
      //modalidades.push('<s>Arquearia</s>');
    }

    if (this.escola.extracurricular_habilidade_logica_catequese == true) {
      modalidades.push('Catequese');
    }

    if (this.escola.extracurricular_habilidade_logica_culinaria == true) {
      modalidades.push('Culinária');
    }

    if (this.escola.extracurricular_habilidade_logica_educ_ambiental == true) {
      modalidades.push('Educação Ambiental');
    }

    if (this.escola.extracurricular_habilidade_logica_educ_financeira == true) {
      modalidades.push('Educação Financeira');
    }

    if (this.escola.extracurricular_habilidade_logica_empreendedorismo == true) {
      modalidades.push('Empreendedorismo');
    }

    if (this.escola.extracurricular_habilidade_logica_horticultura == true) {
      modalidades.push('Horticultura');
    }

    if (this.escola.extracurricular_habilidade_logica_idiomas == true) {
      modalidades.push('Idiomas');
    }

    if (this.escola.extracurricular_habilidade_logica_libras == true) {
      modalidades.push('Libras');
    }

    if (this.escola.extracurricular_habilidade_logica_maker == true) {
      modalidades.push('Maker');
    }

    if (this.escola.extracurricular_habilidade_logica_orint_vocacional) {
      modalidades.push('Orientação Vocacional');
    }

    if (this.escola.extracurricular_habilidade_logica_perp_vestibular == true) {
      modalidades.push('Preparatório Vestibular');
    }

    if (this.escola.extracurricular_habilidade_logica_prep_enem == true) {
      modalidades.push('Preparatório Enem');
    }

    if (this.escola.extracurricular_habilidade_logica_robotica == true) {
      modalidades.push('Robótica');
    }

    if (this.escola.extracurricular_artes_desenho == true) {
      modalidades.push('Desenho');
    }

    if (this.escola.extracurricular_artes_literatura == true) {
      modalidades.push('Literatura');
    }

    if (this.escola.extracurricular_artes_musica == true) {
      modalidades.push('Música');
    }

    if (this.escola.extracurricular_artes_prod_artistica == true) {
      modalidades.push('Produção Artística');
    }

    if (this.escola.extracurricular_artes_teatro == true) {
      modalidades.push('Teatro');
    }


    modalidades = modalidades.sort();

    return modalidades.join(' - ');
  }

  renderCompeticoes(): string {
    return this.escola.competicoes ? this.escola.competicoes.toString().replace(/,/g, ' - ') : '-';
  }
}
