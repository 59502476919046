<div class="w-100" fxFlexFill fxLayout="column nowrap">
    <div class="chat-content w-100" #chatcontent [scrollTop]="chatcontent.scrollHeight" fxLayout="column nowrap" fxFlex>
        <div class="message-box" *ngFor="let message of messages" #messageBox>
            <div class="chat-status" text-center
                 *ngIf="message.type==='join'||message.type==='exit';else messageTemplate">
                <span class="chat-date">{{message.date}}</span>
                <span class="chat-content-center">{{message.message}}</span>
            </div>
            <ng-template #messageTemplate>
                <div class="chat-message">
                    <div [ngClass]="{
                         'right-bubble': message.uid === userService.getCurrentUser()?.uid,
                         'middle-bubble': message.uid == null,
                         'left-bubble': (message.uid != null) && (message.uid !== userService.getCurrentUser()?.uid)
                         }">
                        <!--                            <span class="msg-name" *ngIf="message.nickname === auth.currentUser?.uid">Me</span>-->
                        <span class="msg-name">{{message.name}}</span>
                        <span class="msg-date position-absolute top-0 end-0">{{message.uid == null? 'enviando...' : message.date | date:'short' }}</span>
                        <p text-wrap>{{message.message}}</p>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <form class="footer w-100" [formGroup]="chatForm" (ngSubmit)="onFormSubmit(chatForm.value)" fxFlex="nogrow">
        <mat-form-field class="message-form-field">
            <input matInput [placeholder]="this.inputPlaceholder" formControlName="message"
                   [errorStateMatcher]="matcher" [disabled]="this.disableInput" [readonly]="this.disableInput">
            <!--                        <mat-error>-->
            <!--                            <span *ngIf="!chatForm.get('message').valid && chatForm.get('message').touched">Enter your message</span>-->
            <!--                        </mat-error>-->
            <button type="submit" [disabled]="!chatForm.valid" mat-button matSuffix mat-icon-button
                    aria-label="Send">
                <mat-icon>send</mat-icon>
            </button>
        </mat-form-field>
    </form>
</div>
