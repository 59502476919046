import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Escola } from '../../service/Escola';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-escola-sobre-regiao',
  templateUrl: './escola-sobre-regiao.component.html',
  styleUrls: ['./escola-sobre-regiao.component.css'],
})
export class EscolaSobreRegiaoComponent implements OnInit {
  @Input() escola: Escola;
  GOOGLE_MAPS_API_KEY = environment.GOOGLE_MAPS_API_KEY;
  iframeSrc?: SafeResourceUrl = null;
  isExpanded = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const url = `https://www.google.com/maps/embed/v1/place?key=${this.GOOGLE_MAPS_API_KEY}&q=${this.escola.latitude},${this.escola.longitude}`;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }
}
