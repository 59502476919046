<!--<app-escola-score [score]="-->
<!--{score:54.111788317479636,total:80,-->
<!--score_info:[-->
<!--{text:'iiesa',score:6.8,total:10},-->
<!--{text:'icdsa',score:9.466666666666667,total:10},-->
<!--{text:'tags',score:0,total:10},-->
<!--{text:'mensalidade',score:9.952886666666666,total:10},-->
<!--{text:'localizacao',score:7.8922349841463,total:10},-->
<!--{text:'bilingue',score:0,total:10},-->
<!--{text:'total_alunos',score:10,total:10},-->
<!--{text:'continuidade',score:10,total:10}-->
<!--]}"></app-escola-score>-->

<div class="main-calc">
    <div class="main-section w-100" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
        <section fxFlex="80" fxFlex.gt-md="50" fxFlex.lt-sm="90">
            <h1 class="w-100 text-center title-calc">
                Perfil de afinidade família-escola
            </h1>
            <h3 class="w-75 text-center">
                Quer saber quais escolas têm maior afinidade com o que você busca para seu(s) filho(s)? Selecione o que é mais importante para você e veja a lista de escolas por ordem de afinidade.
            </h3>
            <div class="m-auto w-75" fxLayout="column">

                <div class="calc-item" #serieDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil?.serie!== null? 0 : this.bounce">
                            Para qual nível de ensino está buscando?
                        </h2>
                    </div>
                    <form class="w-100">
                        <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                            <label></label>
                            <mat-select [(ngModel)]="this.perfil.serie"
                                        (valueChange)="changedValueNivel($event)">
                                <mat-option [value]="this.item"
                                            *ngFor="let item of nivelEnsinoArray">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                </div>

<!--                <div class="calc-item" #nascimentoDiv>-->
<!--                    <div class="w-100 text-center">-->
<!--                        <h2 [@bounce]="this.perfil.data_nascimento!== null? 0 : this.bounce">-->
<!--                            Data de nascimento do filho-->
<!--                        </h2>-->
<!--                    </div>-->
<!--                    <mat-form-field class="w-100" floatLabel="never" appearance="outline"-->
<!--                                    (click)="datePickerNascimento.open()">-->
<!--                        <input matInput [matDatepicker]="datePickerNascimento"-->
<!--                               autocomplete="off"-->
<!--                               [readonly]="true"-->
<!--                               (click)="datePickerNascimento.open()"-->
<!--                               (dateInput)="this.setDataNascimento($event.value)"-->
<!--                               (dateChange)="this.setDataNascimento($event.value)">-->
<!--                        <mat-datepicker-toggle matSuffix [for]="datePickerNascimento"-->
<!--                                               [disabled]="this.loading ||false"></mat-datepicker-toggle>-->
<!--                        <mat-datepicker [disabled]="this.loading ||false" #datePickerNascimento [startAt]="this.nascimentoStartDate"-->
<!--                                        startView="multi-year">-->
<!--                        </mat-datepicker>-->
<!--                    </mat-form-field>-->
<!--                </div>-->

<!--                <div class="calc-item" #iiesaDiv>-->
<!--                    <div class="w-100 text-center">-->
<!--                        <h2 [@bounce]="this.perfil.iiesa_min!== null? 0 : this.bounce">-->
<!--                            Indicador de Infraestrutura - IIESA-->
<!--                        </h2>-->
<!--                        <h3 class="w-75 text-left">IIESA é ...</h3>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <div class="w-100 text-center">-->
<!--                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">-->
<!--                                <label></label>-->
<!--                                <mat-select [disabled]="this.loading ||disableIIESA.checked"-->
<!--                                            [(ngModel)]="this.perfil.iiesa_min"-->
<!--                                            [placeholder]="disableIIESA.checked? 'Esse critério não é relevante' : null">-->
<!--                                    <mat-option [value]="this.item" *ngFor="let item of this.iiesaArray">-->
<!--                                        Maior ou igual a {{item}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                            <mat-checkbox class="w-100 text-left" #disableIIESA-->
<!--                                          [checked]="this.iiesa_min === null"-->
<!--                                          (click)="!disableIIESA.checked? this.perfil.iiesa_min = undefined : this.perfil.iiesa_min = null">-->
<!--                                Esse critério não é relevante-->
<!--                            </mat-checkbox>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="calc-item" #icdsaDiv>-->
<!--                    <div class="w-100 text-center">-->
<!--                        <h2 [@bounce]="this.perfil.icdsa_min!== null? 0 : this.bounce">-->
<!--                            Indicador de Corpo Docente - ICDSA-->
<!--                        </h2>-->
<!--                        <h3 class="w-75 text-left">ICDSA é ...</h3>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <div class="w-100 text-center">-->
<!--                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">-->
<!--                                <label></label>-->
<!--                                <mat-select [disabled]="this.loading ||disableICDSA.checked"-->
<!--                                            [(ngModel)]="this.perfil.icdsa_min"-->
<!--                                            [placeholder]="disableICDSA.checked? 'Esse critério não é relevante' : null">-->
<!--                                    <mat-option [value]="this.item" *ngFor="let item of this.icdsaArray">-->
<!--                                        Maior ou igual a {{item}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                            <mat-checkbox class="w-100 text-left" #disableICDSA-->
<!--                                          [checked]="this.icdsa_min === null"-->
<!--                                          (click)="!disableICDSA.checked? this.perfil.icdsa_min = undefined : this.perfil.icdsa_min = null">-->
<!--                                Esse critério não é relevante-->
<!--                            </mat-checkbox>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="calc-item" #tagsDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="(this.perfil.tags?.length > 0) || (this.perfil.tags === null) ? 0 : this.bounce">
                            Quero uma escola que se destaque por (selecione até 3)
                        </h2>
<!--                        <h3 class="w-75 text-left">Atributos é ...</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
<!--                            <h3 class="w-100 text-right" *ngIf="maxTags > 0" [class.warn]="warnMax">-->
<!--                                {{disableTags.value ? 'Esse critério não é relevante' : 'Limite de ' + maxTags + ' Atributos'}}-->
<!--                            </h3>-->
                            <mat-chip-list aria-label="Atributos" multiple>
                                <mat-chip style="height: auto"
                                        *ngFor="let item of this.tags"
                                        (click)="toggleSelect(item)"
                                        [disabled]="this.loading || (this.disableTags.checked)"
                                        removable="false"
                                        [selectable]="!this.disableTags.checked"
                                        [selected]="(this.perfil.tags != null) && (this.perfil.tags.indexOf(item) >= 0)">
                                    {{item}}
                                </mat-chip>
                            </mat-chip-list>
                            <mat-checkbox class="w-100 text-left" #disableTags
                                          [checked]="this.perfil?.tags === null"
                                          (change)="this.perfil.tags === null? this.perfil.tags = []: this.perfil.tags = null"
                            >
                                Esse critério não é relevante
                            </mat-checkbox>

                        </div>
                    </div>
                </div>

                <div class="calc-item" #mensalidadeDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil.mensalidade_media !== undefined? 0 : this.bounce">
                            Selecione o valor médio da mensalidade
                        </h2>
<!--                        <h3 class="w-75 text-left">Mensalidade é ...</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <mat-select [disabled]="this.loading ||disableMensalidade.checked"
                                            [(ngModel)]="this.perfil.mensalidade_media"
                                            [placeholder]="disableMensalidade.checked? 'Esse critério não é relevante' : null">
                                    <mat-option [value]="this.item" *ngFor="let item of this.mensalidadeArray">
                                        R$ {{item| number:'1.2-2':'pt-BR'}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox class="w-100 text-left" #disableMensalidade
                                          [checked]="this.perfil.mensalidade_media === null"
                                          (change)="$event.checked? this.perfil.mensalidade_media = null : this.perfil.mensalidade_media = undefined">
                                Esse critério não é relevante
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="calc-item" #placeDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil.place !== undefined? 0 : this.bounce">
                            Em que região está buscando?
                        </h2>
<!--                        <h3 class="w-75 text-left">Localização é ...</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <input maxLength="100" matInput #addressInput
                                       [disabled]="this.loading ||disablePlace.checked"
                                       [placeholder]="disablePlace.checked ? 'Esse critério não é relevante' : null"
                                       [(ngModel)]="this.perfil.endereco"
                                       class="w-100"
                                >
                            </mat-form-field>
                            <mat-error *ngIf="placeErrorMessage">{{placeErrorMessage}}</mat-error>
                            <mat-checkbox class="w-100 text-left" #disablePlace
                                          [checked]="this.perfil.place === null"
                                          (change)="this.toggleDisablePlace(this.perfil.place !== null)">
                                Esse critério não é relevante
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="calc-item" #bilingueDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil.bilingue !== undefined? 0 : this.bounce">
                            É importante que a escola seja bilíngue?
                        </h2>
<!--                        <h3 class="w-75 text-left">Bilinguísmo é ...</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <mat-select [(ngModel)]="this.perfil.bilingue" [disabled]="this.loading ||disableBilingue.checked"
                                            [placeholder]="disableBilingue.checked ? 'Esse critério não é relevante' : null">
                                    <mat-option *ngFor="let item of educacaoBilingue" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox class="w-100 text-left" #disableBilingue
                                          [checked]="this.perfil.bilingue === null"
                                          (change)=" $event.checked? this.perfil.bilingue = null : this.perfil.bilingue = undefined">
                                Esse critério não é relevante
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="calc-item" #totalalunosDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil.total_alunos !== undefined? 0 : this.bounce">
                            Está buscando uma escola de qual tamanho?
                        </h2>
<!--                        <h3 class="w-75 text-left">Total alunos escola é ...</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <mat-select [(ngModel)]="this.perfil.total_alunos"
                                            [compareWith]="this.totalAlunoCompare"
                                            [disabled]="this.loading ||disableTotalAlunos.checked"
                                            [placeholder]="disableTotalAlunos.checked ? 'Esse critério não é relevante' : null">
                                    <mat-option *ngFor="let item of totalAlunosArray" [value]="item">
                                        {{item.text}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox class="w-100 text-left" #disableTotalAlunos
                                          [checked]="this.perfil.total_alunos === null"
                                          (change)="$event.checked? this.perfil.total_alunos = null : this.perfil.total_alunos = undefined">
                                Esse critério não é relevante
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="calc-item"
                     [hidden]="this.nivelEnsinoArray.indexOf(this.perfil.serie) === this.nivelEnsinoArray.length - 1"
                     #continuidadeDiv>
                    <div class="w-100 text-center">
                        <h2 [@bounce]="this.perfil.continuidade !== undefined? 0 : this.bounce">
                            A escola deve ter continuidade para o {{this.nivelEnsinoArray[this.nivelEnsinoArray.indexOf(this.perfil.serie) + 1]}}
                        </h2>
<!--                        <h3 class="w-75 text-left">Continuidade é ...</h3>-->
<!--                        <h3 class="w-75 text-left">Você quer continuidade para o-->
<!--                            nível {{this.nivelEnsinoArray[this.nivelEnsinoArray.indexOf(this.perfil.serie) + 1]}}</h3>-->
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <mat-select [(ngModel)]="this.perfil.continuidade"
                                            [disabled]="this.loading ||disableContinuidade.checked"
                                            [placeholder]="disableContinuidade.checked ? 'Esse critério não é relevante' : null">
                                    <mat-option *ngFor="let item of continuidadeArray" [value]="item.value">
                                        {{item.text}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox class="w-100 text-left" #disableContinuidade
                                          [checked]="this.perfil.continuidade === null"
                                          (change)="$event.checked? this.perfil.continuidade = null : this.perfil.continuidade = undefined">
                                Esse critério não é relevante
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="calc-item" *ngIf="this.showNome">
                    <div class="w-100 text-center">
                        <h2>
                            Nome do perfil
                        </h2>
                        <h3 class="w-75 text-left">Nome do perfil é ...</h3>
                    </div>
                    <div>
                        <div class="w-100 text-center">
                            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                                <label></label>
                                <input matInput [(ngModel)]="this.perfil.nome">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <button mat-raised-button fxFlex class="w-100 calc-button" color="accent" type="submit"
                        [disabled]="this.loading" (click)="this.calcularPerfil($event)">
                    {{this.loading? 'Calculando...' : 'Calcular'}}
                    <mat-progress-bar mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
                </button>
            </div>
        </section>

        <section fxFlex="80" fxFlex.gt-md="50" fxFlex.lt-sm="90" *ngIf="this.escolaList !== undefined">
            <app-escola-list-vertical
                    [showPaginator]="false"
                    [pageSize]="0"
                    [escolaList]="this.escolaList">
            </app-escola-list-vertical>
        </section>
    </div>
</div>
