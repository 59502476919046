import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Gallery, GalleryRef, ImageItemData, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { Escola } from '../../service/Escola';
import { UserService } from '../../service/user.service';
import { AnalyticsService } from '../../service/analytics.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-escola-gallery-new',
  templateUrl: './escola-gallery-new.component.html',
  styleUrls: ['./escola-gallery-new.component.css'],
})
export class EscolaGalleryNewComponent implements OnInit, AfterViewInit {
  _escola: Escola;
  @Input() editing = false;
  galleryId = 'image-gallery';
  hasImage = true;
  imageCount = -1;
  warning: string = null;
  items: Observable<ImageItemData[]>;
  @Output() clickMessageLink = new EventEmitter<void>();
  @ViewChild('itemTemplate') itemTemplate;

  constructor(
    public gallery: Gallery,
    // public lightbox: Lightbox,
    public userService: UserService,
    private analytics: AnalyticsService
  ) {
    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    galleryRef.indexChanged
      .pipe(
        tap((galleryState) => {
          const user = userService.getCurrentUser();
          this.analytics.clickEscolaMidia(this.escola, 'image', galleryState.currIndex, user);
        })
      )
      .subscribe();
    const lightboxRef = this.gallery.ref('lightbox');

    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      itemTemplate: this.itemTemplate,
    });
  }

  ngOnInit(): void {}

  get escola(): Escola {
    return this._escola;
  }

  @Input() set escola(value: Escola) {
    this._escola = value;
    this.loadImages();
  }

  ngAfterViewInit(): void {}

  public loadImages(): void {
    this.imageCount = -1;
    const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
    galleryRef.reset();
    firebase
      .functions()
      .httpsCallable('escola_gallery')({ cod_escola: this.escola.codigo })
      .then((value) => {
        this.imageCount = value.data?.images?.length;

        if (!this.escola.premium && this.imageCount > 1) {
          this.imageCount = 1;
        }

        this.warning = value.data?.message;
        let imagesData: ImageItemData[] = [];
        value.data.images.map((fileItem) => {
          const imageItemData: ImageItemData = { 
            title: fileItem.title,
            src: fileItem.src, 
            thumb: fileItem.thumb };

          if (this.escola.premium || imagesData.length === 0) {
            imagesData.push(imageItemData);
          }
          //galleryRef.addImage(fileItem);
        });

        const first = imagesData[0]
        first.title = ''

        imagesData = imagesData.sort((a, b) => {
          if (a.title === b.title) {
            return 0;
          }

          if (a.title === null) {
            return -1;
          }

          if (b.title === null) {
            return 1;
          }

          return a.title.localeCompare(b.title);
        })

        imagesData.map((fileItem) => {
          galleryRef.addImage(fileItem);
        });

        this.items = of(imagesData);
      });
  }

  removeImage(src: string, index: number): void {
    firebase
      .functions()
      .httpsCallable('escola_gallery_remove')({ cod_escola: this.escola.codigo, src })
      .then(() => {
        this.loadImages();
      });
  }

  setMainImage(src: string, index: number): void {
    firebase
      .functions()
      .httpsCallable('escola_gallery_main')({ cod_escola: this.escola.codigo, src })
      .then(() => {
        this.loadImages();
      });
  }

  openGallery(): void {
    const user = this.userService.getCurrentUser();
    this.analytics.clickEscolaMidia(this.escola, 'image', 0, user);
  }
}
