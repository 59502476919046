<h1 mat-dialog-title>Para uma experiência mais personalizada, nos conte sobre você</h1>
<div mat-dialog-content>
  <mat-error class="termo-text" *ngIf="hintRequired && data.responsavel_crianca !== true && data.gestor_funcionario_escola !== true && data.fornecedor !== true">Campo obrigatório</mat-error>
  <p>
    <mat-checkbox [(ngModel)]="data.responsavel_crianca">Sou responsável por uma criança em idade escolar.</mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="data.gestor_funcionario_escola">Sou gestor ou funcionário de Escola</mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="data.fornecedor">Sou fornecedor de produtos/serviços para escolas</mat-checkbox>
  </p>

  <div *ngIf="data.responsavel_crianca || data.gestor_funcionario_escola || data.fornecedor">
    <mat-error class="termo-text" *ngIf="hintRequired && (!data.telefone)">Campo obrigatório</mat-error>
    <label>Whatsapp</label>
    <mat-form-field class="w-100 mt-0" floatLabel="never" appearance="outline">
      <input class="field" matInput maxLength="100" mask="(00) 0000-0000||(00) 00000-0000" placeholder="Whatsapp" [(ngModel)]="data.telefone">
    </mat-form-field>
  </div>

  <div *ngIf="data.responsavel_crianca" class="mt-3">
    <label>Pretendo realizar uma matrícula</label>
    <mat-form-field class="w-100 mt-0" floatLabel="never" appearance="outline">
      <label></label>
      <mat-select [(ngModel)]="data.data_matricula"
                  placeholder="Pretendo realizar uma matrícula">
          <mat-option [value]="this.item" *ngFor="let item of this.dataMatriculasArray">
            <span *ngIf="item != -1">Em até {{item}} meses</span>
            <span *ngIf="item == -1">Em mais de 6 meses</span>
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <hr>
  <div class="termo-div">
    <!--    <h2 mat-dialog-title class="termo-text">Termos, Privacidade e Conduta</h2>-->
    <p>
      <mat-error class="termo-text" *ngIf="hintRequired && (data.data_aceite !== true)">Campo obrigatório</mat-error>
      <mat-checkbox class="termo-text" [disabled]="disableAgree" [(ngModel)]="data.data_aceite" required>
        Li e estou de acordo com os&nbsp;
        <a href="https://www.blog.schooladvisor.com.br/termos-de-uso" target="_blank">Termos de Uso</a>,&nbsp;
        <a href="https://www.blog.schooladvisor.com.br/privacidade" target="_blank">Política de Privacidade</a><span>&nbsp;e&nbsp;</span>
        <a href="https://www.blog.schooladvisor.com.br/conduta" target="_blank">Regras de Conduta</a>
      </mat-checkbox>
    </p>
<!--    <p>-->
<!--      <mat-checkbox class="termo-text" [(ngModel)]="data.data_optin">Aceito receber emails com comunicações da SchoolAdvisor</mat-checkbox>-->
<!--    </p>-->
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="reject()" *ngIf="this.disableAgree !== true"
          [matTooltip]="'Valorizamos sua privacidade. Ao rejeitar sua conta será PERMANENTEMENTE removida.'">
<!--    <mat-icon [inline]="false" style="color: #ef3f5d;">delete_forever</mat-icon>-->
    Cancelar
  </button>
  <div fxFlex></div>
  <button mat-button (click)="agree()">
    <mat-icon [inline]="false" style="color: #009294;">check</mat-icon>
    Aceitar
  </button>
</div>
