<div *ngIf="_colecao" class="position-relative">
  <h1 class="colecao-nome text-center w-100">{{this.colecao.nome}}</h1>
  <h2 class="colecao-descricao text-center w-100">{{this.colecao.descricao}}</h2>
  <p></p>
  <mat-progress-bar class="w-100 m-auto" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div *ngFor="let diretorio of this.colecao.diretorio; let i = index">
    <app-diretorio [diretorio]="diretorio">
    </app-diretorio>

<!--    <div class="cellep-banner w-100 container align-items-center justify-content-center p-5" *ngIf="i === 0">-->
<!--      <a [routerLink]="['/cellep']" target="_blank" (click)="analytics.clickCellepBannerHome()">-->
<!--        <img class="w-100" src="/images/cellep/banner_cellep.png">-->
<!--      </a>-->
<!--    </div>-->
  </div>
</div>
