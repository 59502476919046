<div class="elementor-widget-container">
  <div class="membership-plans-wrap clearfix three-cols">
    <!-- plan-item -->
    <div class="price-item">
      <div class="price-head op1">
        <h3>Verificada</h3>
        <h4>Informações oficiais sobre a sua Escola.</h4></div>
      <div class="price-content fl-wrap">
        <div class="price-num fl-wrap">

          <span class="price-num-item">Free</span>
          <div class="price-num-desc"></div>

        </div>
        <div class="price-desc fl-wrap">
          <ul>
            <li>Selo Página Verificada</li>
            <li>30 Itens</li>
            <li>X</li>
            <li>X</li>
            <li>X</li>
            <li>X</li>
            <li>X</li>
            <li>X</li>
          </ul>
          <a href="#" class="price-link logreg-modal-open" data-message="You must be logged in to order a membership plan.">Escolher Verificada</a>


        </div>
      </div>
    </div>
    <!-- plan-item end  -->

    <!-- plan-item -->
    <div class="price-item">
      <div class="price-head op1">
        <h3>Premium</h3>
        <h4>Mais Informações, mais visibilidade!</h4></div>
      <div class="price-content fl-wrap">
        <div class="price-num fl-wrap">
          <span class="curen">R$</span>
          <span class="price-num-item">340</span>
          <div class="price-num-desc"><span class="period-per">Por</span> mês</div>

        </div>
        <div class="price-desc fl-wrap">
          <ul>
            <li>Selo Página Verificada</li>
            <li>até 70 itens</li>
            <li>Exibição da sua escola no perfil dos concorrentes</li>
            <li>X</li>
            <li>Exibição de pais embaixadores</li>
            <li>Ocultar escolas concorrentes na do perfil da sua escola</li>
            <li>Botão de agendamento</li>
            <li>X</li>
          </ul>
          <a href="#" class="price-link logreg-modal-open" data-message="You must be logged in to order a membership plan.">Escolher Premium</a>


        </div>
      </div>
    </div>
    <!-- plan-item end  -->

    <!-- plan-item -->
    <div class="price-item">
      <div class="price-head op1">
        <h3>Master</h3>
        <h4>Entenda, explore e divulgue seus diferenciais!</h4></div>
      <div class="price-content fl-wrap">
        <div class="price-num fl-wrap">
          <span class="curen">R$</span>
          <span class="price-num-item">1.160</span>
          <div class="price-num-desc"><span class="period-per">Por</span> mês</div>

        </div>
        <div class="price-desc fl-wrap">
          <ul>
            <li>Selo Página Verificada</li>
            <li>até 70 itens</li>
            <li>Exibição da sua escola no perfil dos concorrentes</li>
            <li>Visitas garantidas do perfil</li>
            <li>Exibição de pais embaixadores</li>
            <li>Ocultar escolas concorrentes na do perfil da sua escola</li>
            <li>Botão de agendamento</li>
            <li>Comparativo com o Mercado</li>
          </ul>
          <p><!--


<li>Price Range</li>




<li>FAQs</li>




<li>Featured In Search Results</li>




<li>24/7 Support</li>


--></p>
          <a href="#" class="price-link logreg-modal-open" data-message="You must be logged in to order a membership plan.">Escolher Master</a>


        </div>
      </div>
    </div>
    <!-- plan-item end  -->


  </div>
</div>
