<div class="diretorio-list">
  <div fxLayoutGap="5px" class="w-100 d-flex">
    <a class="w-100" [routerLink]="['/diretorio', this.diretorio.codigo, this.diretorio.nome | urlNormalizer]" target="_blank">
      <h2 class="diretorio-nome w-100">{{this.diretorio.nome}}</h2>
    </a>
  </div>

  <div *ngIf="(this.loadingEscola) else escolaFound">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-template #escolaFound>
    <div class="w-100 container" fxLayout="row">
      <div class="navigation-arrow navigation-previous" fxLayout="column" fxLayoutAlign="center center">
        <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab (click)="previousClick()">
          <mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_left</mat-icon>
        </button>
      </div>
      <swiper fxFlex="100" #swiperComponent style="padding-bottom: 25px" [config]="this.config">
        <ng-template swiperSlide class="slide-container" *ngFor="let escola of this.diretorio.escola; let i = index;">
          <ng-template [ngIf]="escola">
            <div>
              <span class="position-absolute align-top concorrente-tag" *ngIf="escola.concorrente && this.userService.master">Concorrente</span>
              <app-escola-card [escola]="escola"></app-escola-card>
              <!--            <mat-grid-list class="card-image m-1" cols="1" rowHeight="3:2">-->
              <!--              <mat-grid-tile class="top-0 bottom-0">-->
              <!--                <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" target="_blank" style="display: contents"-->
              <!--                   (click)="analytics.clickEscola(this.escola)">-->
              <!--                  <img class="escola-image m-auto swiper-lazy"-->
              <!--                       data-src="{{(escola.logo_escola == null) ? ' images/thumb-list.jpg' : escola.logo_escola}}"-->
              <!--                       data-srcset="{{escola.logo_escola_set}}"-->
              <!--                       alt="Logo Escola"-->
              <!--                  />-->
              <!--                  &lt;!&ndash; <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>&ndash;&gt;-->
              <!--                </a>-->
              <!--                <div class="stamps" fxLayout="column" fxLayoutGap="3px">-->
              <!--                  <app-button-favorite [escola]="escola"></app-button-favorite>-->
              <!--                  <app-button-rating [escola]="escola"></app-button-rating>-->
              <!--                  <app-button-parceiro [escola]="escola"></app-button-parceiro>-->
              <!--                </div>-->
              <!--              </mat-grid-tile>-->
              <!--            </mat-grid-list>-->
            </div>
            <!--          <div class="escola-data">-->
            <!--            <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" target="_blank"-->
            <!--               (click)="analytics.clickEscola(this.escola)">-->
            <!--              <mat-chip-list class="nivel-ensino-chip-list">-->
            <!--                <mat-chip class="escola-chip" *ngFor="let nivel of escola.nivel_ensino" [selectable]="false">{{shortNivelEnsino(nivel)}}</mat-chip>-->
            <!--              </mat-chip-list>-->
            <!--              <div class="escola-data-text">-->
            <!--                <h3 class="escola-nome" [ngStyle]="{'text-decoration': (escola.data_desativada? 'line-through': '')}">{{escola.nome}}</h3>-->
            <!--                <span class="escola-endereco">{{escola.endereco}}</span>-->
            <!--              </div>-->
            <!--            </a>-->
            <!--          </div>-->
          </ng-template>
        </ng-template>
      </swiper>
      <div class="navigation-arrow navigation-next" fxLayout="column" fxLayoutAlign="center center">
        <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab (click)="nextClick()">
          <mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_right</mat-icon>
        </button>
      </div>
    </div>
      <div class="w-100" fxLayoutAlign="end center" *ngIf="this.diretorio.nome.indexOf('OEBI') > 0">
          <button mat-stroked-button color="primary" class="position-relative end-0" (click)="this.clickOEBI()">
              Quero ser OEBI
          </button>
      </div>
  </ng-template>
</div>
