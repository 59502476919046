<div id="videos-escola" *ngIf="escola.premium && escola.videos && escola.videos.length > 0" class="row tour content-more mb-5" [class.active]="isExpanded">
  <div class="col-12 mb-2">
    <h2 class="text-black font-size-biggest mb-0">Assista aos vídeos da escola</h2>
  </div>
  <div *ngIf="escola.premium" class="col-12 mb-2 content">
    <p class="font-size-regular text-lightgray">{{ this.escola.video_intro }}</p>
  </div>
  <div *ngIf="escola.premium" class="col-12 mb-4">
    <div class="action">
      <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
    </div>
  </div>

  <div class="col-12 mb-2">
    <!-- h2 class="text-black font-size-big text-center mb-3">Rolê na escola</h2 -->

    <!-- GALLERY COMPONENT -->
    <div class="gallery">
      <div class="fotorama" data-width="100%" data-max-width="100%" data-height="100%" data-max-height="360px" data-ratio="16/9" data-nav="thumbs" data-thumbheight="85px">
        <!-- data-caption="Rolê na escola" -->
        <a [href]="video" target="_blank" *ngFor="let video of escola.videos">
          <img [src]="getYouTubeThumbnailUrl(video)" width="144" height="96" />
        </a>
      </div>
    </div>
    <!-- END GALLERY COMPONENT -->

    <div *ngIf="escola.video360">
      <a target="_blank" 
         title="Tour Virtual" 
         class="btn background-green text-white font-size-regular semibold mt-4" 
         href="{{escola.video360}}">Acesse o Tour Virtual da Escola</a>
    </div>
  </div>
</div>
