import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Review } from '../../review/review-edit/review-edit.component';
import { UserService } from '../../service/user.service';
import { tags } from '../../service/Escola';

@Component({
  selector: 'app-escola-modal-avaliar',
  templateUrl: './escola-modal-avaliar.component.html',
  styleUrls: ['./escola-modal-avaliar.component.css'],
})
export class EscolaModalAvaliarComponent implements OnInit, AfterViewInit {
  saving = false;
  maxLength = 1000;
  anonimoFormControl = new FormControl(false, null);
  nomeDeclaradoFormControl = new FormControl(null, [this.customNomeValidator()]);
  emailDeclaradoFormControl = new FormControl(null, [this.customEmailValidator()]);
  descricaoFormControl = new FormControl(null, [
    // Validators.required
    // Validators.minLength(100),
    Validators.maxLength(1000),
  ]);
  tagsFormControl = new FormControl([], null);
  review: Review = new Review();

  reviewControl: FormGroup = new FormGroup({
    nome_declarado: this.nomeDeclaradoFormControl,
    email_declarado: this.emailDeclaradoFormControl,
    descricao: this.descricaoFormControl,
    cod_escola: new FormControl(this.data.cod_escola),
    anonimo: this.anonimoFormControl,
    tags: this.tagsFormControl,
  });
  maxTags = 3;
  warnMax = false;

  get tags(): Array<string> {
    return tags;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private matDialog: MatDialog, private snackbar: MatSnackBar, public dialogRef: MatDialogRef<EscolaModalAvaliarComponent>) {
    this.review.rate_geral = null;
    this.review.rate_pedagogico = null;
    this.review.rate_infraestrutura = null;
    this.review.rate_higiene_limpeza = null;
    this.review.rate_alimentacao = null;
    this.review.rate_seguranca = null;
    this.review.rate_extracurriculares = null;
    this.review.rate_acolhimento = null;
    this.review.rate_diversidade = null;
    this.review.rate_inclusao = null;
  }

  ngOnInit(): void {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.nomeDeclaradoFormControl.setValue(user.displayName);
      this.nomeDeclaradoFormControl.disable();
      this.emailDeclaradoFormControl.setValue(user.email);
      this.emailDeclaradoFormControl.disable();
    } else {
      this.nomeDeclaradoFormControl.enable();
      this.emailDeclaradoFormControl.enable();
    }

    this.anonimoFormControl.valueChanges.subscribe((value) => {
      this.resetNomeEmail(value);
    });
  }

  ngAfterViewInit(): void {
    this.resetNomeEmail(this.anonimoFormControl.value);
  }

  resetNomeEmail(anonimo: boolean): void {
    if (anonimo) {
      this.nomeDeclaradoFormControl.setValue(null);
      this.emailDeclaradoFormControl.setValue(null);
      this.nomeDeclaradoFormControl.disable();
      this.emailDeclaradoFormControl.disable();
    } else {
      const user = this.userService.getCurrentUser();
      if (user != null) {
        this.nomeDeclaradoFormControl.setValue(user.displayName);
        this.nomeDeclaradoFormControl.disable();
        this.emailDeclaradoFormControl.setValue(user.email);
        this.emailDeclaradoFormControl.disable();
      } else {
        this.nomeDeclaradoFormControl.enable();
        this.emailDeclaradoFormControl.enable();
      }
    }
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.anonimoFormControl.value) {
        return {};
      } else {
        return Validators.email(control) || Validators.required(control);
      }
    };
  }

  customNomeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.anonimoFormControl.value) {
        return {};
      } else {
        return Validators.required(control);
      }
    };
  }

  selectTag(tag: string): void {
    this.tagsFormControl.value.push(tag);
  }

  toggleSelect(tag: string): void {
    const selectedTags: Array<string> = this.tagsFormControl.value;
    const index = selectedTags.indexOf(tag);
    this.warnMax = false;

    if (index >= 0) {
      selectedTags.splice(index, 1);
    } else {
      if (selectedTags.length < this.maxTags) {
        selectedTags.push(tag);
      } else {
        this.warnMax = true;
      }
    }
  }

  rate(category: string, value: number) {
    this.review[category] = value;

    this.setRateGeral();
  }

  setRateGeral() {
    const count = 0 + (this.review.rate_pedagogico? 1 : 0) 
                    + (this.review.rate_infraestrutura? 1 : 0) 
                    + (this.review.rate_higiene_limpeza? 1 : 0) 
                    + (this.review.rate_alimentacao? 1 : 0) 
                    + (this.review.rate_seguranca? 1 : 0)
                    + (this.review.rate_extracurriculares? 1 : 0) 
                    + (this.review.rate_acolhimento? 1 : 0) 
                    + (this.review.rate_diversidade? 1 : 0) 
                    + (this.review.rate_inclusao? 1 : 0);

    const v = Math.round((((isNaN(this.review.rate_pedagogico) ? 0 : this.review.rate_pedagogico) + (isNaN(this.review.rate_infraestrutura) ? 0 : this.review.rate_infraestrutura) + (isNaN(this.review.rate_higiene_limpeza) ? 0 : this.review.rate_higiene_limpeza) + (isNaN(this.review.rate_alimentacao) ? 0 : this.review.rate_alimentacao) + (isNaN(this.review.rate_seguranca) ? 0 : this.review.rate_seguranca) + (isNaN(this.review.rate_extracurriculares) ? 0 : this.review.rate_extracurriculares) + (isNaN(this.review.rate_acolhimento) ? 0 : this.review.rate_acolhimento) + (isNaN(this.review.rate_diversidade) ? 0 : this.review.rate_diversidade) + (isNaN(this.review.rate_inclusao) ? 0 : this.review.rate_inclusao)) / count + Number.EPSILON) * 100) / 100;

    this.review.rate_geral = v;
  }

  saveReview(): void {
    if (this.reviewControl.value.descricao == null && this.reviewControl.value.tags?.length == 0 && !this.review.rate_geral) {
      this.closeModal();
      return;
    }

    this.descricaoFormControl.markAsTouched({ onlySelf: true });
    this.nomeDeclaradoFormControl.markAsTouched({ onlySelf: true });
    this.emailDeclaradoFormControl.markAsTouched({ onlySelf: true });
    const data = { ...this.review, ...this.reviewControl.value };

    Object.keys(data).forEach((k) => {
      const c = data[k];
      if (c === null || c === undefined || Number.isNaN(c)) {
        delete data[k];
      }
    });

    this.saving = true;
    firebase
      .functions()
      .httpsCallable('review_add')(data)
      .then((value) => {
        this.saving = false;
        this.dialogRef.close(data);
        if (value.data?.message) {
          this.snackbar.open(value.data.message, 'Ok', {
            duration: 10000,
          });
        }
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
