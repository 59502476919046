<ng-template [ngIf]="imageCount < 0">
  <div style="width: 100%; height: 480px; background: #d1d1d1; overflow: hidden">
    <img style="margin: auto" src="/images/loading.png" class="start-50 translate-middle position-relative top-50 ld ld-fade" />
  </div>
</ng-template>

<ng-template [ngIf]="imageCount == 0">
  <div style="width: 100%; height: 480px; background: #d1d1d1; overflow: hidden">
    <img src="images/escolas/SchoolAdvisor-Escolas0001.jpg" alt="Imagem Escola Principal" />
  </div>
</ng-template>

<ng-template [ngIf]="imageCount > 0">
  <div class="school-gallery mb-4" *ngIf="items | async">
    <div class="item" *ngFor="let item of items | async | slice : 0 : 5 : (imageCount < 3 ? 1 : imageCount < 6 ? 3 : 6); let i = index">
      <figure mat-raised-button mat-ripple [lightbox]="i" gallery="image-gallery">
        <img [src]="i == 0 ? item.src : item.thumb" alt="Escola" />
      </figure>
    </div>

    <!-- BUTTON -->
    <div *ngIf="escola.premium" class="show-more">
      <a mat-ripple title="todas as imagens" (click)="this.openGallery()" class="btn background-pink text-white font-size-regular" [lightbox]="0" gallery="image-gallery"> <img src="images/new/school-gallery/icon-image.svg" alt="todas as imagens" />todas as imagens </a>
    </div>
  </div>
</ng-template>
