<div class="dropzone"
     appDropzone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering">

  <h3>Arraste as fotos aqui...</h3>
  <div class="file">
    <label class="file-label">

      <span class="file-cta">
          <span class="file-label">ou escolha o arquivo...</span>
          <span class="file-icon"><i class="fa fa-upload"></i></span>
      </span>
      <input class="file-input" type="file" (change)="onDrop($event.target.files)">
    </label>
  </div>
</div>

<h3 *ngIf="this.files?.length > 0">Uploads</h3>
<div *ngFor="let file of files">
  <app-upload-task
    (uploadEvent)="uploadFinished($event, file)"
    [hidden]="!showUploadStatus"
    [file]="file" [codEscola]="this.codEscola" [type]="this.type"></app-upload-task>
</div>
