import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { debounceTime, startWith, switchMap, tap, map } from 'rxjs/operators';
import { EscolaService, filterEventType } from '../../service/escola.service';
import { Escola } from '../../service/Escola';
import { UrlNormalizerPipe } from '../../pipe/url-normalizer.pipe';

@Component({
  selector: 'app-buscar-escola',
  templateUrl: './buscar-escola.component.html',
  styleUrls: ['./buscar-escola.component.css'],
})
export class BuscarEscolaComponent implements OnInit {
  placeholderEscola = true;
  selectedEscola: Escola = null;
  loadingMessage: string = null;
  isLoadingEscola = false;
  escolaNome: Escola[] | string[] = [];

  constructor(public escolaService: EscolaService, public router: Router, private urlNormalizerPipe: UrlNormalizerPipe) {}

  ngOnInit(): void {
    this.escolaService.place.valueChanges
      .pipe(
        tap((value) => {
          this.escolaService.nome.setValue(value);
        }),
        debounceTime(1500),
        switchMap((value) => {
          // the value can be a string or a Escola type
          if (typeof value === 'string') {
            this.selectedEscola = null;
            if (value.length < 3) {
              this.loadingMessage = `Digite ao menos 3 letras para pesquisar.`;
            } else {
              this.isLoadingEscola = true;
              const criteria = {
                nome: value,
              };
              this.loadingMessage = `Procurando escolas com nome '${value}'`;
              firebase
                .functions()
                .httpsCallable('nome_escola')(criteria)
                .then((value1) => {
                  if (value1.data?.length > 0) {
                    this.escolaNome = value1.data;
                    this.escolaNome.forEach((value2) => {
                      if (typeof value2 !== 'string') {
                        value2.endereco_json = EscolaService.parseEndereco(value2);
                      }
                    });
                  } else {
                    this.escolaNome = [];
                  }
                  this.isLoadingEscola = false;
                });
            }
          } else {
            this.isLoadingEscola = false;
            this.selectedEscola = value;
            // Traga somente a escola marcada
            this.escolaNome = [value];
          }
          return [];
        })
      )
      .subscribe();
  }

  displayEscola(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  searchTrigger(type: filterEventType): void {
    this.escolaService.filterEvent.next({ type, reset: true });
  }

  public detail(escola: Escola): void {
    this.escolaService.nome.setValue(escola.nome);
    this.router.navigate(['escolas', escola.codigo, this.urlNormalizerPipe.transform(escola.nome)]).then(() => {
      // this.searchTrigger();
    });
  }

  searchEscola(type: filterEventType = 'map'): void {
    const goTo = '/busca-escolas';
    if (this.router.url === goTo) {
      this.searchTrigger('list');
    } else {
      this.router.navigate([goTo]);
    }
  }
}
