<div class="mb-5 background-ice ads-sliders">
    <div class="container" *ngIf="parceiroArray?.length > 0">
        <!--div class="col-12 text-center mb-5">
            <h2 class="text-black font-size-biggest mb-0">Você pode se interessar por</h2>
        </div-->

        <div *ngIf="(this.loadingParceiro) else escolaFound">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #escolaFound>
            <div class="w-100" fxLayout="row">
                <div class="navigation-arrow navigation-previous" fxLayout="column" fxLayoutAlign="center center">
                    <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab
                            (click)="previousClick()">
                        <!-- mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_left</mat-icon -->
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                </div>
                <swiper fxFlex="100" #swiperComponent style="padding-bottom: 25px" [config]="this.config">
                    <ng-template swiperSlide class="slide-container"
                                *ngFor="let parceiro of this.parceiroArray; let i = index;">
                        <ng-template [ngIf]="parceiro">
                            <div class="component-card">
                                <article class="card-school">
                                    <div class="content-image-card">
                                        <a [href]="(parceiro.link_url?.startsWith('http'))? parceiro.link_url : '//'+parceiro.link_url"
                                        (click)="analytics.clickParceiro(parceiro.nome)"
                                        target="_blank" title="veja mais" class="veja-mais">
                                            <mat-grid-list cols="1" rowHeight="5:4" class="mb-1">
                                                <mat-grid-tile class="parceiro-image">
                                                    <img class="parceiro-image"
                                                        src="{{(parceiro.logo_path == null) ? ' images/thumb-list.jpg' : parceiro.logo_path}}"
                                                        alt="Logo Parceiro"
                                                    />
                                                </mat-grid-tile>
                                            </mat-grid-list>
                                            <h3 class="text-black font-size-biggest" *ngIf="!parceiro.editing">{{parceiro.nome}}</h3>
                                        </a>
                                    </div>
                                </article>
                            </div>
                        </ng-template>
                    </ng-template>
                </swiper>
                <div class="navigation-arrow navigation-next" fxLayout="column" fxLayoutAlign="center center">
                    <button style="background-color: transparent;" class="mat-elevation-z0" mat-mini-fab
                            (click)="nextClick()">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <!-- mat-icon color="primary" [inline]="true" style="font-size: 3em">chevron_right</mat-icon-->
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>