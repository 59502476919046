<div id="esportes-cultura" class="row">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Esportes, Cultura e Competições acadêmicas</h2>
  </div>
  <div *ngIf="this.escola.premium && hasSobre" class="row about-school content-more mb-4" [class.active]="isExpanded">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.extracurricular_sobre }}</p>
    </div>
    <div class="col-12">
      <div class="action">
        <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="hasModalidades || hasCompeticoes" class="col-12 mb-4">
    <div class="content-border border-lightgray">
      <div class="row">
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <!-- IMAGE -->
          <figure *ngIf="false" class="feature-image mb-2 mt-1">
            <img src="images/new/school/logo-nba.svg" alt="NBA" />
          </figure>
        </div>
        <div *ngIf="false" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <div *ngIf="hasModalidades" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">Modalidades</h3>
            <p class="font-size-regular text-black mb-2" [innerHTML]="renderModalidades()"></p>
            <!-- <div class="action">
              <a href="" title="Entre em contato para saber mais" class="btn-view-more pink font-size-smallest">Entre em contato para saber mais</a>
            </div> -->
          </div>
          <div *ngIf="hasModalidades && hasCompeticoes" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="hasCompeticoes" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">Competições que a escola participa</h3>
            <p class="font-size-regular text-black mb-0">{{ renderCompeticoes() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!hasData" class="row about-school content-more mb-4">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">
        Não Informado
      </p>
    </div>
  </div>
</div>
