<section class="page-school" style="padding: 0; background: inherit;">
  <div class="container">
    <section class="content-other-infos">
      <div class="row">
        <section class="content-info col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="max-width: 100%">
          <!-- Cursos Extracurriculares -->
          <section id="courses" class="col-12 cards">
            <div class="subtitle">
              <h2>Produtos</h2>
            </div>
            <div class="content">
              <div class="row row-eq-height">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" style="align-items: start" *ngFor="let item of pagseguroService.planos">
                  <div class="item d-flex flex-column" style="height: 100%; align-items: center">
                    <img *ngIf="item.icon" src="{{item.icon}}" alt=""/>
                    <h3>{{item.nome}}</h3>
                    <span style="text-align: initial" *ngFor="let i of item.descricao">{{i}}</span>
                    <br>
                    <span style="text-align: initial; font-size: x-small;" *ngFor="let i of item.nota">{{i}}</span>
                    <div style="height: 40px"></div>
                    <p style="margin: 10px; position: absolute; bottom: 30px;">{{item.valor | currency:'BRL':'symbol':'1.2-2': 'pt-BR'}}</p>
                    <div class="bottom-0 position-absolute">
                      <button mat-raised-button color="primary" *ngIf="item.disabledMessage == null"
                              [disabled]="saving"
                              (click)="checkout(item)">{{item.buttonLabel ? item.buttonLabel : 'Contratar'}}
                        <mat-progress-bar mode="indeterminate" [style.display]="saving? '' : 'none'"></mat-progress-bar>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </section>
  </div>
</section>
