<div class="component-pagination">
    <div class="info">
        <p class="label-small-text text-lightgray">Exibindo {{offset}} - {{limit}} de {{total}} Escolas</p>
    </div>
    <nav aria-label="">
        <ul class="pagination">
            <li class="page-item text-lightgray">
                <a class="page-link small-text border-lightgray text-lightgray" href="#">1</a>
                <span class="more" *ngIf="((total/limit) > 5) &&  ((offset / limit) > 3)">...</span>
            </li>
            <li class="page-item">
                <a class="page-link small-text border-lightgray text-lightgray" href="#">{{(offset / limit) - 1}}</a>
            </li>
            <li class="page-item active" aria-current="page">
                <span class="small-text background-yellow text-white">{{offset / limit}}</span>
            </li>
            <li class="page-item">
                <a class="page-link small-text border-lightgray text-lightgray" href="#">{{(offset / limit) + 1}}</a>
            </li>
            <li class="page-item text-lightgray">
                <span class="more" *ngIf="((total/limit) > 5) && ((total - offset)/ limit > 3) ">...</span>
                <a class="page-link small-text border-lightgray text-lightgray" href="#">
                    {{total / limit}}
                </a>
            </li>
        </ul>
    </nav>
</div>
