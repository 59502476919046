import {Input,
        OnInit,
        Component, 
        ViewChild,
        ElementRef,
  } from '@angular/core'
import * as clone from 'clone'
import {Observable} from 'rxjs'
import firebase from 'firebase/app'
import {FormControl} from '@angular/forms'
import {map, startWith} from 'rxjs/operators'
import {Escola, tags} from '../../../service/Escola'
import {MatSelectChange} from "@angular/material/select"
import {MatChipInputEvent} from '@angular/material/chips'
import {UserService} from '../../../service/user.service'
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete'
import * as moment from 'moment'
  


@Component({
    selector: 'school-qualifications',
    templateUrl: 'school-qualifications.html',
    styleUrls: ['./school-qualifications.scss']

  })
export class SchoolQualificationsComponent implements OnInit {

    @Input() escola: Escola
    @Input() editing: boolean
    @Input() familyChoice: boolean

    @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger
    @ViewChild('tagsInput', {static: false}) tagsInput: ElementRef<HTMLInputElement>
    
    familyReviewTags: string[] = []

    maxAttributos = 3
    tagControl = new FormControl()
    filteredTags: Observable<string[]>

    set humanPrevencaoAcidentesValidate(e) {
      this.escola.prevencao_acidentes_validade = e;
    }

    get humanPrevencaoAcidentesValidate() {
      return this.escola.prevencao_acidentes_validade?.substring(0, 10)
    }

    set humanDiversidadeValidade(e) {
      this.escola.diversidade_validade = e;
    }

    get humanDiversidadeValidade() {
      return this.escola.diversidade_validade?.substring(0, 10)
    }

    constructor(public userService: UserService) {
        this.filteredTags = this.tagControl.valueChanges.pipe(
            startWith(null),
            map((item: string | null) => this._filterTags(item))
        );
    }

    ngOnInit(): void {
        if (this.escola.tags == null) {
            this.escola.tags = {};
            this.escola.tags["school"] = [];
        }

        if (Array.isArray(this.escola.tags)) {
            const current : any = this.escola.tags;
            this.escola.tags = {};
            this.escola.tags["school"] = current;
        }

        if (!("family" in this.escola.tags)
        || this.escola.tags["family"] == null
        || this.escola.tags["family"].length == 0) {
          this.loadFamilyReviewTags()
        }
    }

    async loadFamilyReviewTags(): Promise<void> {
      const result = await firebase.functions().httpsCallable('tags_review_escola')
          ({cod_escola: this.escola.codigo})

        const tagsList = result?.data.map((item: any) => item.tags).flat()

        const frequency: { [tag: string]: number } = {};
        tagsList.forEach((tag) => {
          frequency[tag] = (frequency[tag] || 0) + 1;
        });

        const orderFrequency = Object.keys(frequency).sort((a, b) => {
          return frequency[b] - frequency[a];
      });

      this.familyReviewTags = orderFrequency.slice(0, 3)
    }

    private _filterTags(value: string): string[] {
        const possibleValues = clone(tags);
        if (this.escola?.tags) {
          /*for (const selectedItem of this.escola.tags) {
            const index = possibleValues.indexOf(selectedItem);
            if (index >= 0) {
              possibleValues.splice(index, 1);
            }
          }*/
        }
    
        if (value == null) {
          return possibleValues;
        }
    
        const filterValue = value.toLowerCase();
    
        return possibleValues.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
    }

    hasTags(type: string): boolean {
        if (Array.isArray(this.escola.tags)) {
            const current : any = this.escola.tags;
            this.escola.tags = {};
            this.escola.tags["school"] = current;
        }

        if (type in this.escola.tags &&
            this.escola.tags[type].length > 0) {
            return true;
        }

        return (type === "family" && this.familyReviewTags.length > 0);
    }

    getTags(type: string): string[] {
        if (Array.isArray(this.escola.tags)) {
            const current : any = this.escola.tags;
            this.escola.tags = {};
            this.escola.tags["school"] = current;
        }            

        if (!(type in this.escola.tags))
            this.escola.tags[type] = [];

        if (!this.editing && 
            type === "family" 
            && this.escola.tags[type].length == 0)
          return this.familyReviewTags;

        return this.escola.tags[type];
    }

    removeTag(type: string, tag: string): void {
        const index = this.escola.tags[type].indexOf(tag);
    
        if (index >= 0) {
          this.escola.tags[type].splice(index, 1);
        }
    }

    addTag(type: string, event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        for (const item of this.escola.tags[type]) {
          if (item === value) {
            return;
          }
        }
    
        // Add our tag
        if ((value || '').trim()) {
          this.escola.tags[type].push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
    
        this.tagControl.setValue(null);
    }

    selectedTag(event: MatAutocompleteSelectedEvent): void {
        if (!this.escola.tags)
          this.escola.tags = {};
    
        //this.escola.tags.push(event.option.viewValue);
        this.tagsInput.nativeElement.value = null;
        this.tagControl.setValue('');
    
        requestAnimationFrame(() => {
          this.autoTrigger.openPanel();
        });
    }

    selectedTagOption(type: string, event: MatSelectChange): void {
        if (!this.escola.tags)
          this.escola.tags = {};

        if (!this.escola.tags[type])
            this.escola.tags[type] = [];
    
        if (this.escola.tags[type].indexOf(event.value) >= 0)
          return;
    
        this.escola.tags[type].push(event.value);
        this.tagsInput.nativeElement.value = null;
        this.tagControl.setValue('');
    }

    showIIESA(): boolean {
      const icdsaIndex = this.escola.indices['IIESA']
      return this.showIndice(icdsaIndex)
    }

    showICDSA(): boolean {
      const icdsaIndex = this.escola.indices['ICDSA']
      return this.showIndice(icdsaIndex)
    }

    showSelo(value: boolean, date: string) {
      return value && (!date || moment(date).isAfter(moment()))
    }

    showIndice(indexValue: string) {
      indexValue = indexValue?.toString().trim().toUpperCase() ?? "";

      if (!isNaN(Number(indexValue)))
        return parseFloat(indexValue) >= 75;
      else
        return indexValue === "A" || indexValue === "A+";
    }

    decimalParser(event: any): number {
      if (event !== null) {
        const c = event.toString().replace(/[^\d,]/g, '').replace(',', '.');
        if (c.length > 0) {
          return c;
        }
      }
      return null;
    }
}