import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {educacaoBilingue, Escola, tags} from '../../service/Escola';
import {NivelEnsinoArray, NivelEnsinoAttributes, NivelEnsinoData} from '../../service/NivelEnsino';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {UserService} from '../../service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginDialogComponent} from '../../navigation/auth/login-dialog/login-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckbox} from '@angular/material/checkbox';
import {Observable} from 'rxjs';

export interface Perfil {
  serie: string;
  data_nascimento?: any;
  iiesa_min?: number;
  icdsa_min?: number;
  tags: Array<string>;
  endereco?: any;
  place?: google.maps.places.PlaceResult;
  mensalidade_media?: number;
  bilingue?: string;
  total_alunos?: {
    text: string;
    min: number;
    max: number;
  };
  continuidade?: boolean;
  nome?: string;
}

@Component({
  selector: 'app-perfil-afinidade',
  templateUrl: './perfil-afinidade.component.html',
  styleUrls: ['./perfil-afinidade.component.scss'],
  animations: [
    trigger('bounce', [transition(':increment',
        [
          animate('1000ms ease-in', keyframes([
            style({color: 'red', offset: 0.1}),
            style({transform: 'translate3d(-3px, 0, 0)', offset: 0.1}),
            style({transform: 'translate3d(3px, 0, 0)', offset: 0.2}),
            style({transform: 'translate3d(-6px, 0, 0)', offset: 0.3}),
            style({transform: 'translate3d(6px, 0, 0)', offset: 0.4}),
            style({transform: 'translate3d(-6px, 0, 0)', offset: 0.5}),
            style({transform: 'translate3d(6px, 0, 0)', offset: 0.6}),
            style({transform: 'translate3d(-6px, 0, 0)', offset: 0.7}),
            style({transform: 'translate3d(3px, 0, 0)', offset: 0.8}),
            style({transform: 'translate3d(-3px, 0, 0)', offset: 0.9}),
          ])),
        ]
    )]),
    trigger('Fading', [
      state('false', style({opacity: 0, display: 'none'})),
      state('true', style({opacity: 1, display: ''})),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('100ms ease-in')),
    ])
  ]
})
export class PerfilAfinidadeComponent implements OnInit, AfterViewInit {

  perfil_teste: Perfil = {
    serie: 'Berçário',
    data_nascimento: '2022-06-22T13:07:46.679Z',
    iiesa_min: 100,
    icdsa_min: 100,
    tags: [
      'Tecnologia nos processos de ensino',
      'Vestibular',
      'Acolhimento emocional'
    ],
    endereco: 'R. Augusta - Consolação, São Paulo - SP, Brazil',
    place: null,
    // {
    //   url: 'https://maps.google.com/?q=Jundia%C3%AD+-+State+of+S%C3%A3o+Paulo,+Brazil&ftid=0x94cf24223aa04809:0x9b5da316714a9a7e',
    //   icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
    //   name: 'Jundiaí',
    //   types: ['administrative_area_level_2', 'political'],
    //   photos: [],
    //   website: 'http://www.jundiai.sp.gov.br/',
    //   geometry: {
    //     // location: {
    //     //   lat: {}, lng: {}
    //     // },
    //     // viewport: {
    //     //   Ra: {
    //     //     hi: -46.78643191320362,
    //     //     lo: -47.02889052653374},
    //     //   wb: {
    //     //     hi: -23.07637676883089,
    //     //     lo: -23.32683334870078}
    //     // }
    //   },
    //   place_id: 'ChIJCUigOiIkz5QRfppKcRajXZs',
    //   utc_offset: -180,
    //   adr_address: '<span class=\'locality\'>Jundiaí</span> - <span class=\'region\'>State of São Paulo</span>
    //   , <span class=\'country-name\'>Brazil</span>',
    //   formatted_address: 'Jundiaí - State of São Paulo, Brazil',
    //   html_attributions: [],
    //   address_components: [{
    //     types: ['administrative_area_level_2', 'political'],
    //     long_name: 'Jundiaí',
    //     short_name: 'Jundiaí'
    //   }, {
    //     types: ['administrative_area_level_1', 'political'],
    //     long_name: 'State of São Paulo',
    //     short_name: 'SP'
    //   }, {types: ['country', 'political'], long_name: 'Brazil', short_name: 'BR'}],
    //   icon_mask_base_uri: 'https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet',
    //   utc_offset_minutes: -180,
    //   icon_background_color: '#7B9EB0'
    // },
    bilingue: 'Programa Opcional',
    // localizacao: {
    //   lat: -23.1856699, lng: -46.8892603
    // }
    mensalidade_media: 2000,
    total_alunos: {
      text: 'Pequena - Até 200 alunos',
      min: null,
      max: null
    },
    continuidade: true,
    nome: 'Teste'
  };

  perfil: Perfil = {
    serie: null,
    iiesa_min: 100,
    icdsa_min: 100,
    tags: [],
  };

  warnMax = false;
  maxTags = 3;
  newPerfil = true;
  iiesaArray: Array<number> = [0, 25, 50, 75];
  icdsaArray: Array<number> = [0, 25, 50, 75];
  mensalidadeArray: Array<number> = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];
  totalAlunosArray: Array<{ text: string, min: number, max: number }> = [
    {text: 'Pequena - Até 200 alunos', min: null, max: 200},
    {text: 'Média - Entre 201 e 500 alunos', min: 201, max: 500},
    {text: 'Grande - Mais de 500 alunos', min: 501, max: null},
  ];
  @ViewChild('addressInput') addressInput: ElementRef<HTMLInputElement>;
  placeErrorMessage: string = null;
  continuidadeArray: Array<{ text: string, value: boolean }> = [
    {text: 'Sim', value: true},
    {text: 'Não', value: false}
  ];

  @ViewChild('serieDiv', {static: true}) serieDiv: ElementRef;
  @ViewChild('nascimentoDiv', {static: true}) nascimentoDiv: ElementRef;
  @ViewChild('iiesaDiv', {static: true}) iiesaDiv: ElementRef;
  @ViewChild('icdsaDiv', {static: true}) icdsaDiv: ElementRef;
  @ViewChild('tagsDiv', {static: true}) tagsDiv: ElementRef;
  @ViewChild('mensalidadeDiv', {static: true}) mensalidadeDiv: ElementRef;
  @ViewChild('placeDiv', {static: true}) placeDiv: ElementRef;
  @ViewChild('bilingueDiv', {static: true}) bilingueDiv: ElementRef;
  @ViewChild('totalalunosDiv', {static: true}) totalalunosDiv: ElementRef;
  @ViewChild('continuidadeDiv', {static: false}) continuidadeDiv: ElementRef;
  @ViewChild('disableIIESA', {static: true}) disableIIESA: MatCheckbox;
  @ViewChild('disableICDSA', {static: true}) disableICDSA: MatCheckbox;
  @ViewChild('disableTags', {static: true}) disableTags: MatCheckbox;
  @ViewChild('disableMensalidade', {static: true}) disableMensalidade: MatCheckbox;
  @ViewChild('disablePlace', {static: true}) disablePlace: MatCheckbox;
  @ViewChild('disableBilingue', {static: true}) disableBilingue: MatCheckbox;
  @ViewChild('disableTotalAlunos', {static: true}) disableTotalAlunos: MatCheckbox;
  @ViewChild('disableContinuidade', {static: true}) disableContinuidade: MatCheckbox;

  bounce = 0;
  loading = false;
  escolaList: Array<Escola>;
  nascimentoStartDate = new Date(2000, 0, 1);
  showNome: false;
  returnURL: string = null;
  afterCloseLogin: Observable<any> = null;

  constructor(
      private router: Router,
      private _snackBar: MatSnackBar,
      private _adapter: DateAdapter<any>,
      private userService: UserService,
      private matDialog: MatDialog,
      private route: ActivatedRoute,
      @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    userService.userDataEvent.subscribe(value => {
      if (value) {
        this.refreshData();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initPlaceAutocomplete();
    this.refreshData();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnURL = params["returnURL"];
    });
  }

  private refreshData() {
    if (this.userService.userData?.perfil) {
      this.perfil = this.userService.userData.perfil;
    }
  }

  private initPlaceAutocomplete(): void {
    const nativeInput = this.addressInput.nativeElement;

    // Create the autocomplete object, restricting the search predictions to
    // @ts-ignore
    nativeInput.geolocate = () => {
      // @ts-ignore
      geolocate();
    };
    const autocomplete = new google.maps.places.Autocomplete(
        nativeInput,
        {
          types: ['geocode'], // 'establishment' / 'address' / 'geocode'
          componentRestrictions: {country: 'BR'},
        });
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components:
    // address_component, adr_address, business_status, formatted_address, geometry, icon, name, permanently_closed,
    // photo, place_id, plus_code, type, url, utc_offset, or vicinity
    // source: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#autocomplete
    autocomplete.setFields(['geometry', 'formatted_address']);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.placeSelected(autocomplete.getPlace());
    });
  }

  placeSelected(event: google.maps.places.PlaceResult): void {
    if (event) {
      // console.log('Event: ' + JSON.stringify(event));
      if (event.geometry) {
        // console.log('Location: ' + JSON.stringify(event.geometry.location));
        this.perfil.endereco = event.formatted_address || this.addressInput.nativeElement.value;
        this.perfil.place = JSON.parse(JSON.stringify(event));
        this.placeErrorMessage = null;
      } else {
        this.perfil.endereco = event.name;
        this.perfil.place = null;
        this.placeErrorMessage = 'Selecione uma localização válida.';
      }

      // console.log(`localizacao: ${JSON.stringify(this.perfil.localizacao)}`);
    } else {
      console.log('invalid address');
    }
  }

  totalAlunoCompare(f1: Perfil['total_alunos'], f2: Perfil['total_alunos']): boolean {
    return f1.text === f2.text;
  }

  get tags(): Array<string> {
    return tags;
  }

  get educacaoBilingue() {
    return educacaoBilingue;
  }

  get nivelEnsinoArray() {
    return NivelEnsinoArray;
  }

  selectTag(tag: string): void {
    this.perfil.tags.push(tag);
    // console.log(`Tags: ${JSON.stringify(this.tagControl.value)}`);
  }

  toggleSelect(tag: string): void {
    if (!this.perfil.tags) {
      this.perfil.tags = [];
    }
    const index = this.perfil.tags.indexOf(tag);
    this.warnMax = false;

    if (index >= 0) {
      this.perfil.tags.splice(index, 1);
    } else {
      if (this.perfil.tags.length < this.maxTags) {
        this.perfil.tags.push(tag);
      } else {
        this.warnMax = true;
      }
    }
  }

  changedValueNivel($event: any) {
    if (this.nivelEnsinoArray.indexOf(this.perfil.serie) === this.nivelEnsinoArray.length - 1) {
      this.perfil.continuidade = null;
      // console.log('valueContinuidade: ' + this.valueContinuidade);
    }
  }

  calcularPerfil(event: MouseEvent): void {
    let scroll = Number.MAX_VALUE;
    this.loading = true;

    this.bounce++;
    if (this.perfil.serie == null) {
      scroll = Math.min(this.serieDiv.nativeElement.getBoundingClientRect().top, scroll);
    }
    // if (this.perfil.data_nascimento === null) {
    //   scroll = Math.min(this.nascimentoDiv.nativeElement.getBoundingClientRect().top, scroll);
    // }
    // if (!this.disableIIESA.checked && (this.perfil.icdsa_min === null)) {
    //   scroll = Math.min(this.iiesaDiv.nativeElement.getBoundingClientRect().top, scroll);
    // }
    // if (!this.disableICDSA.checked && (this.perfil.icdsa_min === null)) {
    //   scroll = Math.min(this.icdsaDiv.nativeElement.getBoundingClientRect().top, scroll);
    // }
    if (!this.disableTags.checked && (this.perfil.tags?.length === 0)) {
      scroll = Math.min(this.tagsDiv.nativeElement.getBoundingClientRect().top, scroll);
    }
    if (!this.disableMensalidade.checked && (this.perfil.mensalidade_media == null)) {
      scroll = Math.min(this.mensalidadeDiv.nativeElement.getBoundingClientRect().top, scroll);
    }
    if (!this.disablePlace.checked && (this.perfil.place === undefined)) {
      scroll = Math.min(this.placeDiv.nativeElement.getBoundingClientRect().top, scroll);
    }
    if (!this.disableBilingue.checked && (this.perfil.bilingue == null)) {
      scroll = Math.min(this.bilingueDiv.nativeElement.getBoundingClientRect().top, scroll);
    }
    if (!this.disableContinuidade.checked && this.perfil.continuidade == null) {
      scroll = Math.min(this.continuidadeDiv?.nativeElement.getBoundingClientRect().top, scroll);
    }

    // console.log('scroll:' + scroll);
    if (scroll < Number.MAX_VALUE) {
      window.scrollBy({behavior: 'smooth', top: scroll - 100});
      this.loading = false;
      return;
    }

    if (this.userService.getCurrentUser()) {

      const data = {
        perfil: this.perfil,
        order: 'score',
        fields: 'map'
      };
      // console.log(JSON.stringify(data));

      this.loading = true;
      this.setPerfil(event);

      // firebase.functions().httpsCallable('escola')(data).then(value => {
      //   if (value == null) {
      //     this.escolaList = null;
      //   } else {
      //     this.escolaList = value.data.rows;
      //   }
      //   this.loading = false;
      // }).catch(
      //     ex => {
      //       this._snackBar.open(ex.message, 'Ok', {duration: 5000});
      //       this.loading = false;
      //     }
      // );


    } else {
      if (this.afterCloseLogin === null) {
        this.afterCloseLogin = this.matDialog.open(LoginDialogComponent, {id: 'loginDialog'}).afterClosed();
        this.afterCloseLogin.subscribe(value => {
          this.userService.loadUserData().then(value1 => {
            this.setPerfil(event, false);
          });
        });
      }
    }

    // console.log(`perfil: ${JSON.stringify(this.perfil)}`);
  }

  async setPerfil(event: MouseEvent, goToMap: boolean = true) {
    await firebase.functions().httpsCallable('set_perfil')({perfil: this.perfil})
        .then(value => {
              this.userService.setPerfil(value);
              
              if (this.returnURL) {
                this.router.navigate([this.returnURL]);
              } else {
                this.router.navigate(['/mapa'], {queryParams: {type: 'perfil'}});
              }
              
              /*if (event.shiftKey) {
                console.log(this.userService.userData.perfil);
              } else {
                if (goToMap) {
                  window.scrollTo({top: 0, left: 0, behavior: undefined});
                  this.router.navigate(['mapa'], {queryParams: {type: 'perfil'}});
                }
                // window.scroll({top: 0, left: 0, behavior: 'auto'});
              }*/
              this.loading = false;
            }
        );
  }

  setDataNascimento(value): void {
    this.perfil.data_nascimento = value;
  }

  toggleDisablePlace(disable: boolean) {
    if (disable) {
      this.perfil.place = null;
      this.perfil.endereco = null;
    } else {
      this.perfil.place = undefined;
      this.perfil.endereco = undefined;
    }
  }
}
