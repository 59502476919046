<div *ngIf="this.hasStudentsData || this.hasTeachersData" id="perfil-escola" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Perfil da escola</h2>
  </div>

  <div class="col-12">
    <div *ngIf="this.hasStudentsData" class="content-border border-lightgray">
      <div class="row mb-5">
        <div class="col-12 mb-4">
          <h2 class="text-black font-size-big mb-0">Estudantes</h2>
        </div>
        <div *ngIf="this.hasStudentsGenderData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">
              Gênero 
              <img src="images/new/icon-info.svg" 
                title="Os dados seguem a classificação utilizada no Censo Escolar, que considera somente os gêneros masculino e feminino." 
                class="img-info" 
                data-bs-toggle="tooltip" data-bs-placement="right"  />
            </h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_masculino">{{ this.getStudentsPercentage(this.escola.matriculas_masculino) }}% masculino</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_feminino">{{ this.getStudentsPercentage(this.escola.matriculas_feminino) }}% feminino</li>
            </ul>
          </div>
        </div>
        <div *ngIf="this.hasStudentsGenderData" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="this.hasStudentsRaceData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">Diversidade e inclusão</h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_raca_branca">{{ this.getStudentsPercentage(this.escola.matriculas_raca_branca) }}% brancos</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_raca_preta">{{ this.getStudentsPercentage(this.escola.matriculas_raca_preta) }}% negros</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_raca_indigena">{{ this.getStudentsPercentage(this.escola.matriculas_raca_indigena) }}% indígenas</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.matriculas_neurodivergentes">{{ this.getStudentsPercentage(this.escola.matriculas_neurodivergentes) }}% neurodivergentes</li>
            </ul>
          </div>
        </div>
        <div *ngIf="this.hasStudentsRaceData" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="this.hasStudentsNationalityData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">Nacionalidade</h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li *ngFor="let item of this.getTopThreeValues(this.escola.matriculas_nacionalidades)" 
                class="font-size-regular text-black">
                {{ this.getStudentsPercentage(item.quantidade) }}% {{ item.nacionalidade }}
              </li>
              <li *ngIf="this.getOthersCount(this.escola.matriculas_nacionalidades, 3) > 0" 
                class="font-size-regular text-black">
                + {{ this.getOthersCount(this.escola.matriculas_nacionalidades, 3) }} nacionalidades
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="this.hasStudentsNationalityData" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="this.hasStudentsUniversityData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">Universidades/escolas onde aprova</h3>
            <span class="ms-auto text-yellow font-size-smallest" *ngIf="this.escola.matriculas_ano_referencia">Ano de referência {{ this.escola.matriculas_ano_referencia }}</span>
          </div>
          <div class="list-school-profile">
            <ul>
              <li class="font-size-regular text-black" *ngFor="let universidade of this.escola.matriculas_universidades; let i = index">{{ universidade.quantidade }} estudantes na {{ universidade.title }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.hasTeachersData" class="content-border border-lightgray mt-4">
      <div class="row">
        <div class="col-12 mb-4">
          <h2 class="text-black font-size-big mb-0">Professores</h2>
        </div>
        <div *ngIf="this.hasTeachersGenderData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">
              Gênero 
              <img src="images/new/icon-info.svg" 
              title="Os dados seguem a classificação utilizada no Censo Escolar, que considera somente os gêneros masculino e feminino." 
              class="img-info" data-bs-toggle="tooltip" 
              data-bs-placement="right" />
            </h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_masculino">{{ this.getTeachersPercentage(this.escola.professores_masculino) }}% masculino</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_feminino">{{ this.getTeachersPercentage(this.escola.professores_feminino) }}% feminino</li>
            </ul>
          </div>
        </div>
        <div *ngIf="this.hasTeachersGenderData" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="this.hasTeachersRaceData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">Diversidade e inclusão</h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_raca_branca">{{ this.getTeachersPercentage(this.escola.professores_raca_branca) }}% brancos</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_raca_preta">{{ this.getTeachersPercentage(this.escola.professores_raca_preta) }}% negros</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_raca_indigena">{{ this.getTeachersPercentage(this.escola.professores_raca_indigena) }}% indígenas</li>
              <li class="font-size-regular text-black" *ngIf="this.escola.professores_raca_nd">{{ this.getTeachersPercentage(this.escola.professores_raca_nd) }}% neurodivergentes</li>
            </ul>
          </div>
        </div>
        <div *ngIf="this.hasTeachersRaceData" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="this.hasTeachersNationalityData" class="col-12 d-flex flex-column school-profile">
          <div class="mb-3 header-school-profile">
            <h3 class="text-pink font-size-small mb-0">Nacionalidade</h3>
          </div>
          <div class="list-school-profile">
            <ul>
              <li *ngFor="let item of this.getTopThreeValues(this.escola.professores_nacionalidades)" 
                class="font-size-regular text-black">
                {{ this.getTeachersPercentage(item.quantidade) }}% {{ item.nacionalidade }}
              </li>
              <li *ngIf="this.getOthersCount(this.escola.professores_nacionalidades, 3) > 0" 
                class="font-size-regular text-black">
                + {{ this.getOthersCount(this.escola.professores_nacionalidades, 3) }} nacionalidades
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
