<div fxLayout="column" id="login" style="margin: 10px">

  <div fxLayout="column" fxLayoutAlign="center center" id="login-form-wrapper">

    <div id="login-form">

      <form [formGroup]="registerForm" fxLayout="column" name="loginForm" novalidate>

        <mat-form-field appearance="outline">
          <input placeholder="Nome" formControlName="name" matInput #name />
          <mat-icon matSuffix>person</mat-icon>
          <mat-error>O nome é obrigatório</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input placeholder="e-mail" formControlName="email" matInput autocomplete="username" #email/>
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="registerForm.get('email')?.hasError('required')">O e-mail é obrigatório</mat-error>
          <mat-error *ngIf="registerForm.get('email')?.hasError('email')">O e-mail é inválido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input placeholder="Senha" formControlName="password" matInput type="password" autocomplete="new-password" #password/>
          <mat-icon matSuffix>lock</mat-icon>
          <mat-error *ngIf="registerForm.get('password')?.hasError('required')">A senha é obrigatória</mat-error>
                    <mat-error *ngIf="registerForm.get('password')?.hasError('minlength')">A senha precisa de pelo menos 6 caracateres (letras ou números)</mat-error>
<!--                    <mat-error *ngIf="registerForm.get('password')?.hasError('maxlength')">{{passwordErrorMaxLengthText}}</mat-error>-->
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input placeholder="Confirme Senha" formControlName="passwordConfirm" matInput type="password" autocomplete="new-password" #passwordConfirm/>
          <mat-icon matSuffix>lock</mat-icon>
          <mat-error *ngIf="registerForm.get('passwordConfirm')?.hasError('required')">A senha não confere</mat-error>
          <mat-error
            *ngIf="!registerForm.get('passwordConfirm')?.hasError('required') && registerForm.get('passwordConfirm')?.hasError('passwordsNotMatching')">A senha não confere
          </mat-error>
        </mat-form-field>

        <!--        <mat-checkbox aria-label="Termos e Condições" formControlName="tos" required>-->
        <!--          <span>{{termsAndConditionsText}}</span>-->
        <!--          <a target="_blank" [href]="this.tosUrl">{{termsAndConditionsLinkText}}</a>-->
        <!--        </mat-checkbox>-->
        <!--        <mat-checkbox aria-label="Política de Privacidade" formControlName="privacyPolicy" required>-->
        <!--          <span>{{privacyPolicyText}}</span>-->
        <!--          <a target="_blank" href="this.privacyPolicyUrl">{{privacyPolicyLinkText}}</a>-->
        <!--        </mat-checkbox>-->

        <div fxFlex="100" fxLayout="column" fxLayoutGap="3px" fxLayoutAlign="space-between stretched">
          <button mat-raised-button
                  (click)="userService.signUp(email.value, password.value, name.value)"
                  [disabled]="registerForm.invalid"
                  class="submit-button"
          >Entrar
          </button>
        </div>

      </form>

      <div class="separator">
        <span class="text" fxFlex="100" style="text-align: center">Ou</span>
      </div>

      <!--default icon buttons-->
      <div [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
           fxLayoutGap="5px"
           [fxLayout]="layout"
           fxLayout.xs="column">
        <button (click)="userService.googleAuth()" mat-stroked-button>
          <mat-icon svgIcon="google"></mat-icon>
          Google
        </button>
        <button (click)="userService.facebookAuth()" mat-stroked-button>
          <mat-icon svgIcon="facebook"></mat-icon>
          Facebook
        </button>
        <!--        <button (click)="processLegalSignUP(authProvider.Apple)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                class="apple-filled"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="apple"></mat-icon>-->
        <!--          Apple-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Twitter)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                class="twitter-filled"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="twitter"></mat-icon>-->
        <!--          Twitter-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Github)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="github"></mat-icon>-->
        <!--          GitHub-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Microsoft)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="microsoft"></mat-icon>-->
        <!--          Microsoft-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Yahoo)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="yahoo"></mat-icon>-->
        <!--          Yahoo-->
        <!--        </button>-->
      </div>

    </div>
  </div>
</div>
