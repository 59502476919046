import {Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from '@angular/core';

@Component({
  selector: 'app-text-bound',
  templateUrl: './text-bound.component.html',
  styleUrls: ['./text-bound.component.scss']
})
export class TextBoundComponent implements OnInit {

  @Input() text: string;
  @Output() textChange = new EventEmitter<string>();
  @Input() editing: false;
  collapsed = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  // get text(): string {
  //   return this._text;
  // }
  //
  // set text(v: string) {
  //   this._text = v;
  //   this.textChange.emit(this._text);
  // }

  isOverFlow({clientWidth, clientHeight, scrollWidth, scrollHeight}): boolean {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }

  click(textDiv: HTMLDivElement) {
    if (textDiv.classList.contains('text-full')) {
      textDiv.classList.remove('text-full')
      this.collapsed = true;
    }else {
      textDiv.classList.add('text-full')
      this.collapsed = false;
    }
  }
}
