import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-escola',
  templateUrl: './page-escola.component.html',
  styleUrls: [
    './page-escola.component.css',
    '../../../css/elementor/post-42256.css',
    '../../../css/elementor/global.css',
    '../../../css/elementor/frontend.min.css',
    '../../../css/elementor/style.css',
  ]
})
export class PageEscolaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
