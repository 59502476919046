import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Review} from '../../review/review-edit/review-edit.component';
import firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgbRating} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review-ranking',
  templateUrl: './review-ranking.component.html',
  styleUrls: ['./review-ranking.component.scss']
})
export class ReviewRankingComponent implements OnInit {

  @Input() readonly = true;
  digitsInfo: string;
  saving = false;
  @Input() review: Review;
  @ViewChild('ngbRatingGeral', {static: true}) ngbRatingGeral: NgbRating;
  checkValid = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private matDialog: MatDialog,
              private snackbar: MatSnackBar) {
    if (data != null) {
      if (data.readonly != null) {
        this.readonly = data.readonly;
      }
      if (this.readonly) {
        this.digitsInfo = '1.2-2';
      } else {
        this.digitsInfo = '1.0-0';
      }

      let values;
      const escola = data.escola;
      const review = data.review;
      if (escola) {
        values = escola;
        this.review = new Review();
        this.review.cod_escola = escola.codigo;
        this.review.anonimo = false;
        this.review.rate_geral = values.rate_geral;
        this.review.rate_pedagogico = values.rate_pedagogico;
        this.review.rate_infraestrutura = values.rate_infraestrutura;
        this.review.rate_higiene_limpeza = values.rate_higiene_limpeza;
        this.review.rate_alimentacao = values.rate_alimentacao;
        this.review.rate_seguranca = values.rate_seguranca;
        this.review.rate_extracurriculares = values.rate_extracurriculares;
      } else if (review) {
        this.review = review;
      }
    }
  }

  ngOnInit(): void {
  }

  validate(): boolean {
    this.checkValid = true;
    // Removed in 2021-10-21 SA-509
    // return (this.review.rate_geral > 0);
    return true;
  }

  cancel(): void {
    this.matDialog.closeAll();
  }

  geralChange(rate: number): void {
    if (rate == null) {
      rate = this.review.rate_geral;
    }
    this.review.rate_pedagogico = rate;
    this.review.rate_infraestrutura = rate;
    this.review.rate_higiene_limpeza = rate;
    this.review.rate_alimentacao = rate;
    this.review.rate_seguranca = rate;
    this.review.rate_extracurriculares = rate;
  }

  subRankingChange(attribute: string, newValue: number): void {
    this.review[attribute] = newValue;

    const count = 0
      + (isNaN(this.review.rate_pedagogico) || (this.review.rate_pedagogico === 0) ? 0 : 1)
      + (isNaN(this.review.rate_infraestrutura) || (this.review.rate_infraestrutura === 0) ? 0 : 1)
      + (isNaN(this.review.rate_higiene_limpeza) || (this.review.rate_higiene_limpeza === 0) ? 0 : 1)
      + (isNaN(this.review.rate_alimentacao) || (this.review.rate_alimentacao === 0) ? 0 : 1)
      + (isNaN(this.review.rate_seguranca) || (this.review.rate_seguranca === 0) ? 0 : 1)
      + (isNaN(this.review.rate_extracurriculares) || (this.review.rate_extracurriculares === 0) ? 0 : 1);

    const v = Math.round(
      ((
        (isNaN(this.review.rate_pedagogico) ? 0 : this.review.rate_pedagogico)
        + (isNaN(this.review.rate_infraestrutura) ? 0 : this.review.rate_infraestrutura)
        + (isNaN(this.review.rate_higiene_limpeza) ? 0 : this.review.rate_higiene_limpeza)
        + (isNaN(this.review.rate_alimentacao) ? 0 : this.review.rate_alimentacao)
        + (isNaN(this.review.rate_seguranca) ? 0 : this.review.rate_seguranca)
        + (isNaN(this.review.rate_extracurriculares) ? 0 : this.review.rate_extracurriculares)
        // - (isNaN(oldValue) ? 0 : oldValue) + (isNaN(newValue) ? 0 : newValue)
      ) / count + Number.EPSILON)
      * 100) / 100;

    // console.log(`RateGeral: ${this.rate_geral} ->  ${v}`);
    this.review.rate_geral = v;
  }
}
