import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../service/user.service";

@Component({
  selector: 'app-one-tap',
  templateUrl: './one-tap.component.html',
  styleUrls: ['./one-tap.component.css']
})
export class OneTapComponent implements OnInit {

  visible = false;

  constructor(private userService: UserService) {
    this.userService.userDataEvent.subscribe(value => {
      // console.log('userService.userDataEvent: ' + JSON.stringify(value));
      if (value == null) {
        // @ts-ignore
        google.accounts.id.prompt();
      }
    });
  }

  ngOnInit(): void {
  }

}
