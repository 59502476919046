<!--<h1 mat-dialog-title>Avaliação</h1>-->
<div mat-dialog-content>
  <p>Deseja dar uma nota junto com essa avaliação?</p>
</div>
<div mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="sendOnlyReview()">Não</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Sim</button>
  </div>
</div>
