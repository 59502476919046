import {Injectable, Optional, SkipSelf} from '@angular/core';
import {FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ParceiroService {

  nome = new FormControl(null, []);
  logo_path = new FormControl(null, []);
  site_url = new FormControl(null, []);
  listas = new FormControl(null, []);
  filterOption = new FormControl(null, []);

  constructor(@Optional() @SkipSelf() parentModule?: ParceiroService) {

    if (parentModule) {
      throw new Error('ParceiroService is already loaded. Import it in AppModule only');
    }
  }
}
