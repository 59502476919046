<!--<button style="background-color: #fffa;" mat-mini-fab disabled aria-label="Rating Geral" class="rating-button"-->
<!--        [matTooltip]="escola?.rate_geral > 0 ? 'Avaliação Geral ' + escola?.rate_geral : ''">-->
<!--  &lt;!&ndash;  <span class="fa fa-star-o rating-star"></span>&ndash;&gt;-->
<!--  &lt;!&ndash;  <span style="font-size: smaller">{{escola?.rate_geral}}</span>&ndash;&gt;-->
<!--  <mat-icon style="color: #ff7800" [inline]="true" [style]="'font-size: 2em'">-->
<!--    {{(escola?.rate_geral == null) ? 'star_outline' : 'star'}}-->
<!--    <span class="position-absolute start-0 w-100 rate_geral">{{escola.rate_geral | number:'1.1-1':'pt-BR'}}</span>-->
<!--  </mat-icon>-->
<!--</button>-->

<div class="rating" *ngIf="escola.rate_geral">
    <span class="label-small-text text-black"
          [matTooltip]="escola?.rate_geral > 0 ? 'Avaliação Geral ' + escola?.rate_geral : ''">
        {{escola.rate_geral | number:'1.1-1':'pt-BR'}}
    </span>
</div>
