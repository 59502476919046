import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Escola, EscolaBeneficio } from '../../service/Escola';
import { UserService } from '../../service/user.service';
import { AnalyticsService } from '../../service/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { UrlFixerPipe } from '../../pipe/url-fixer.pipe';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';
import { EscolaModalShareComponent } from '../escola-modal-share/escola-modal-share.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-escola-aside',
  templateUrl: './escola-aside.component.html',
  styleUrls: ['./escola-aside.component.css'],
})
export class EscolaAsideComponent implements OnInit, AfterViewInit {
  @Input() escola: Escola;
  @Input() escolaBeneficio: EscolaBeneficio;
  @Input() requestInfo: (fieldName: string, responseDialog: boolean) => void;

  schoolContactSending = false;
  schoolContactSented = false;

  escolaBeneficioSaving = false;
  escolaBeneficioSented = false;

  requestTuitionLoading = false;
  requestTuitionSented = false;

  showInfo = false;
  showBeneficios = false;

  constructor(public userService: UserService, private analytics: AnalyticsService, private urlFixer: UrlFixerPipe, private matDialog: MatDialog) {}

  get rate() {
    if (!this.score || this.score.total == 0) {
      return -1;
    }

    return this.score.score / this.score.total;
  }

  get score() {
    if (!this.escola.score_info) {
      return {
        score: 0,
        total: 0,
      };
    }

    return this.escola.score_info;
  }

  get linkTelefone(): string {
    return `tel:${this.formatPhone(this.escola.fone).replace(/\D/g, '')}`;
  }

  get linkWhatsapp(): string {
    const fone = this.formatPhone(this.escola.social['whatsapp'], true);
    const nomeUrl = this.escola.nome.replace(/ /g, '%20');
    return `https://api.whatsapp.com/send?phone=${fone}&text=Oi,%20encontrei%20${nomeUrl}%20no%20site%20da%20SchoolAdvisor`;
  }

  formatPhone(telefone: string, toLink: boolean = false): string {
    //let telefone = this.escola.fone;

    //if (this.escola.social['whatsapp']) telefone = this.escola.social['whatsapp'];

    telefone = telefone.replace(/\D/g, '');

    if (!telefone) {
      return '';
    }

    if (toLink && telefone.length >= 14) {
      return telefone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
    } else if (toLink && telefone.length === 13) {
      return telefone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
    } else if (toLink && telefone.length === 12) {
      return telefone.replace(/(\d{2})(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3 $4-$5');
    }
    else if (telefone.length > 11) {
      const telefoneSemDDI = telefone.substring(telefone.length - 11);
      return telefoneSemDDI.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    else if (telefone.length === 11) {
      return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    // Verifica se o telefone tem DDD e 8 dígitos
    else if (telefone.length === 10) {
      return telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      return telefone;
    }
  }


  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  sendPhoneAnalytics(isWhatsapp: boolean): void {
    const fieldName = isWhatsapp ? 'whatsapp' : 'fone';

    this.analytics.linkEscolaField(this.escola, fieldName, this.userService.getCurrentUser());
  }

  calcMesalidadeMedia(): void {
    let c = 0;
    let sum = 0;
    if (this.escola.bercario_mensalidade_media) {
      c++;
      sum += +this.escola.bercario_mensalidade_media;
    }
    if (this.escola.infantil_mensalidade_media) {
      c++;
      sum += +this.escola.infantil_mensalidade_media;
    }
    if (this.escola.fundamental1_mensalidade_media) {
      c++;
      sum += +this.escola.fundamental1_mensalidade_media;
    }
    if (this.escola.fundamental2_mensalidade_media) {
      c++;
      sum += +this.escola.fundamental2_mensalidade_media;
    }
    if (this.escola.ensino_medio_mensalidade_media) {
      c++;
      sum += +this.escola.ensino_medio_mensalidade_media;
    }
    this.escola.mensalidade = Math.round((sum * 100) / c) / 100;
  }

  hasSocialMedia(): boolean {
    if (!this.escola.social) return false;

    for (const key in this.escola.social) {
      if (this.escola.social[key]) {
        return true;
      }
    }

    return false;
  }

  async sendContact(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.schoolContactSending = true;

    const currentUser = firebase.auth().currentUser;
    const data = {
      nome: currentUser.displayName,
      emailUser: currentUser.email,
      schoolName: this.escola.nome,
      emailTo: this.escola.email,
      tipo: 0,
      premium: this.escola.premium,
    };

    try {
      await firebase.functions().httpsCallable('escola_send_beneficios')(data);
      this.analytics.linkEscolaField(this.escola, 'contato', this.userService.getCurrentUser());

      this.schoolContactSending = false;
      this.schoolContactSented = true;
    } catch (error) {
      this.schoolContactSending = false;
      this.schoolContactSented = false;
    }

    if (this.escola.social['whatsapp']) {
      window.open(this.linkWhatsapp, '_blank');
    }
  }

  share() {
    const currentUrl = window.location.href;

    this.analytics.linkEscolaField(this.escola, 'compartilhar', this.userService.getCurrentUser());

    this.matDialog.open(EscolaModalShareComponent, {
      id: 'reviewDialog',
      data: {
        title: this.escola.nome,
        text: this.escola.sobre_escola,
        url: currentUrl,
      },
    });
  }

  proprietario(event: Event): void {
    event.preventDefault();
    this.userService.proprietario(this.escola);
    this.analytics.clickSolicitarProprietario(this.escola);
  }

  getFinalScore(): number {
    let score = this.rate * 100;

    if (score > 100) {
      score = 100;
    }

    return Math.round(score);
  }

  goToSite(): void {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.analytics.linkEscolaField(this.escola, 'site', this.userService.getCurrentUser());
    const url = this.urlFixer.transform(this.escola.site) + '?utm_source=schooladvisor';
    window.open(url, '_blank');
    // href="{{escola.site | urlFixer}}?utm_source=schooladvisor" title="Site" target="_blank"
  }

  async showAllInfo(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.analytics.viewEscolaField(this.escola, 'contato', user);
    this.analytics.sendEventMail(this.escola, user);

    this.showInfo = true;
  }

  async mostrarBeneficios(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.analytics.viewEscolaField(this.escola, 'benefício', user);
    this.analytics.sendEventMail(this.escola, user);

    this.showBeneficios = true;
  }

  async sendBenefits(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.escolaBeneficioSaving = true;

    const currentUser = firebase.auth().currentUser;
    const data = {
      uid: currentUser.uid,
      nome: currentUser.displayName,
      emailUser: currentUser.email,
      schoolName: this.escola.nome,
      emailTo: this.escola.email,
      tipo: this.escolaBeneficio.tipo,
      premium: this.escola.premium,
    };

    try {
      await firebase.functions().httpsCallable('escola_send_beneficios')(data);

      switch (this.escolaBeneficio.tipo) {
        case 0:
          this.analytics.interesseEscolaField(this.escola, 'escola', this.userService.getCurrentUser());
          break;
        case 1:
          this.analytics.interesseEscolaField(this.escola, 'bolsa', this.userService.getCurrentUser());
          break;

        case 2:
          this.analytics.interesseEscolaField(this.escola, 'benefício', this.userService.getCurrentUser());
          break;
      }

      this.escolaBeneficioSaving = false;
      this.escolaBeneficioSented = true;
    } catch (error) {
      this.escolaBeneficioSaving = false;
      this.escolaBeneficioSented = false;
    }
  }

  async sendRequestTuition(): Promise<void> {
    const user = this.userService.getCurrentUser();

    if (user == null) {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
      return;
    }

    this.requestTuitionLoading = true;

    const currentUser = firebase.auth().currentUser;

    const data = {
      nome: currentUser.displayName,
      emailUser: currentUser.email,
      schoolName: this.escola.nome,
      emailTo: this.escola.email,
      premium: this.escola.premium,
    };

    try {
      await firebase.functions().httpsCallable('escola_request_tuition')(data);
      this.requestInfo('mensalidade', false);

      this.requestTuitionLoading = false;
      this.requestTuitionSented = true;
    } catch (error) {
      this.requestTuitionLoading = false;
      this.requestTuitionSented = false;
    }
  }
}
