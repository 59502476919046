<!--<button style="background-color: #fffa" mat-mini-fab aria-label="Favorito" class="fav-button"-->
<!--        [matTooltip]="'Favorita'" color="" (click)="this.userService.setFavorito(escola, !escola.favorito)">-->
<!--  &lt;!&ndash;  <mat-icon fontSet="fas" fontIcon="fa-heart" [color]="escola.favorito? 'accent':''"></mat-icon>&ndash;&gt;-->
<!--  <mat-icon color="accent" *ngIf="!escola.favorito">favorite_outline</mat-icon>-->
<!--  <mat-icon color="accent" *ngIf="escola.favorito">favorite</mat-icon>-->
<!--</button>-->
<!--<div class="fav" [class.active]="escola.favorito">-->
<!--  <a class="btn-fav" (click)="this.userService.setFavorito(escola, !escola.favorito)"></a>-->
<!--</div>-->

<a class="fav" [class.active]="escola.favorito" [matTooltip]="'Favorita'" (click)="this.userService.setFavorito(escola, !escola.favorito)"></a>
