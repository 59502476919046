import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

@Component({
  selector: 'app-nova-escola',
  templateUrl: './nova-escola.component.html'
})
export class NovaEscolaComponent implements OnInit {

  canEdit = false;

  constructor(public userService: UserService, private _router: Router) {
    this.canEdit = userService.master;
  }

  ngOnInit(): void {

    if (this.canEdit)
      this.createSchool();
    else
      this._router.navigate(['/']);
  }

  async createSchool() {
    const schoolResp = await firebase.functions().httpsCallable('escola_add')({})
    console.log('schoolId', schoolResp)

    this._router.navigate(['/escolas', schoolResp.data, 'Nova-Escola']);
  }

}
