import {Component, OnInit} from '@angular/core';
import {EscolaService} from '../../service/escola.service';

@Component({
  selector: 'app-escola-filter-rate',
  templateUrl: './escola-filter-rate.component.html',
  styleUrls: ['./escola-filter-rate.component.css']
})
export class EscolaFilterRateComponent implements OnInit {

  escolaServiceReference = EscolaService;
  public rate: {
    rate_geral: 0,
    rate_pedagogico,
    rate_infraestrutura,
    rate_higiene_limpeza,
    rate_alimentacao,
    rate_seguranca,
    rate_extracurriculares,
  };
  public rateGeral = 0;

  constructor(public escolaServiceInstance: EscolaService) {
  }

  ngOnInit(): void {
  }

  reset(): void {
    this.rateGeral = 0;
  }

}
