import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UploadFileData, UploadTaskComponent} from '../upload-task/upload-task.component';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  isHovering: boolean;
  files: File[] = [];
  @Input() codEscola: number;
  @Input() type: 'images' | 'icons' | 'main' = 'images';
  @Input() showUploadStatus = true;
  @Output() public uploadDone = new EventEmitter<UploadFileData>();

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  onDrop(files: FileList): void {
    // console.log('drop file');
    for (let i = 0; i < files.length; i++) {
      // console.log(`file ${i} of ${files.length}`);
      this.files.push(files.item(i));
    }
  }

  uploadFinished($event: UploadFileData, file: File): void {
    this.uploadDone.emit($event);
    setTimeout(() => {
      this.files.splice(this.files.indexOf(file), 1);
    }, 2000);
  }
}
