<div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
  <!-- SEARCH -->
  <div class="search-field">
    <form>
      <input type="text" id="nomeEscolaInput" [placeholder]="placeholderEscola ? 'Buscar escola...' : ''" class="font-size-smallest" [formControl]="escolaService.place" [matAutocomplete]="autoEscolaNome" (focusin)="this.placeholderEscola = false" (focusout)="this.placeholderEscola = true" />
      <button class="btn-submit" (click)="searchEscola('list')"></button>
      <mat-autocomplete class="escola-autocomplete" #autoEscolaNome="matAutocomplete" [displayWith]="displayEscola" (optionSelected)="detail($event.option.value)" style="padding: 10px 0">
        <mat-option *ngIf="isLoadingEscola">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <span>{{ loadingMessage }}</span>
        </mat-option>
        <ng-container *ngIf="!isLoadingEscola">
          <mat-option *ngFor="let escola of escolaNome" [value]="escola">
            <div *ngIf="!escola?.nome" fxFlex fxLayout="row" class="select-item-box" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <span fxFlex="16px" class="select-nome">{{ escola }}</span>
            </div>
            <div *ngIf="escola?.nome" fxFlex fxLayout="row" class="select-item-box line-bottom" fxLayoutAlign="start center">
              <!-- <mat-icon>place</mat-icon>-->
              <div fxFlex="grow" fxLayout="column" class="select-item-box" fxLayoutAlign="start stretch">
                <span fxFlex="grow" class="select-nome">{{ escola.nome }}</span>
                <span fxFlex="auto" *ngIf="escola.endereco_json == null" class="select-address">{{ escola.endereco }}</span>
                <div fxFlex="30" *ngIf="escola.endereco_json != null && escola.endereco_json.cidade" fxLayout="row">
                  <span fxFlex="noshrink" *ngIf="escola.endereco_json.cidade" class="select-address">{{ escola.endereco_json.cidade + " - " + escola.endereco_json.estado }}</span>
                  <span fxFlex="grow" *ngIf="escola.endereco_json.bairro" class="select-address text-end">{{ escola.endereco_json.bairro }}</span>
                </div>
                <span fxFlex="30" class="select-address" *ngIf="escola.endereco_json.logradouro && escola.endereco_json.numero"> {{ escola.endereco_json.logradouro + ", " + escola.endereco_json.numero }}</span>
              </div>
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </form>
  </div>
  <!-- END SEARCH -->
</div>
