<!--<div fxFlex fxLayout="column" class="main-div">-->
<div mat-dialog-title>
  <label>Filtrado por</label>
  <mat-form-field class="w-100">
    <mat-chip-list #chipList aria-label="Listas/Diretórios de Escolas">
      <!--      <mat-chip *ngFor="let item of escolaService.nivelEnsino.value" (removed)="remove(item)" removable>-->
      <!--        {{_getTag(item)?.title}}-->
      <!--        <mat-icon matChipRemove>cancel</mat-icon>-->
      <!--      </mat-chip>-->
      <mat-chip *ngIf="this.escolaService.rateGeral.value > 0"
                (removed)="this.escolaService.rateGeral.setValue(null)"
                removable>
        {{'Índice +' + this.escolaService.rateGeral.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="this.escolaService.distanciaMax.value > 0"
                (removed)="this.escolaService.distanciaMax.setValue(null)"
                removable>
        {{'até ' + this.escolaService.distanciaMax.value + 'km'}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="false && this.escolaService.mensalidadeMax.value > 0"
                (removed)="this.escolaService.mensalidadeMax.setValue(null)"
                removable>
        {{'até ' + (this.escolaService.mensalidadeMax.value | currency:'BRL':'symbol':'0.2-2')}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="this.escolaService.indicesTotalAlunosMax.value > 0"
                (removed)="this.escolaService.indicesTotalAlunosMax.setValue(null)"
                removable>
        {{'até ' + this.escolaService.indicesTotalAlunosMax.value + ' Alunos'}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="this.escolaService.clima.value?.length > 0"
                (removed)="this.escolaService.clima.setValue([])"
                removable>
        Escolas pelo Clima
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="this.escolaService.cellep.value?.length > 0"
                (removed)="this.escolaService.cellep.setValue([])"
                removable>
        Cel.Lep
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="this.escolaService.oebi.value?.length > 0"
                (removed)="this.escolaService.oebi.setValue([])"
                removable>
        Bilingue OEBI
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      
      <mat-chip *ngIf="this.escolaService.geekie.value?.length > 0"
              (removed)="this.escolaService.geekie.setValue([])"
              removable>
          Geekie
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <mat-chip *ngIf="this.escolaService.supercerebro.value?.length > 0"
                (removed)="this.escolaService.supercerebro.setValue([])"
                removable>
        Super Cérebro
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngFor="let item of this.escolaService.abordagemPedagogica.value; let i = index"
                (removed)="this.escolaService.abordagemPedagogica.value.splice(i, 1)"
                removable>
        {{item}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip *ngFor="let item of escolaService.filter.value" (removed)="remove(item)" removable>
        {{_getTag(item)?.title}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input #listaInput
             placeholder="Características"
             [formControl]="listControl"
             [matAutocomplete]="auto"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of filteredTags | async"
                  [value]="item.name">
        <!--        <mat-icon>{{item.icon ? item.icon : 'add'}}</mat-icon>-->
        <!--        <div fxFlex fxLayout="column" class="select-item-box" fxLayoutAlign="start stretch">-->
        <!--          <span fxFlex="16px" class="select-group">{{item.group}}</span>-->
        <span fxFlex="100" class="select-title">{{item.title}}</span>
        <!--        </div>-->
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<mat-dialog-content class="mat-typography">

  <!--  <form [formGroup]="this.escolaService.filterFormGroup">-->
  <!--    <mat-selection-list formControlName="nivel_ensino">-->
  <!--      <label>Nível de Ensino</label>-->
  <!--      <ng-container *ngFor="let item of escolaTags" class="bottom-margin">-->
  <!--        <mat-list-option checkboxPosition="before" [value]="item.name"-->
  <!--                         *ngIf="item.group === 'Nível de Ensino'"-->
  <!--                         ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
  <!--          {{item.title}}-->
  <!--        </mat-list-option>-->
  <!--      </ng-container>-->
  <!--    </mat-selection-list>-->
  <!--  </form>-->

  <form [formGroup]="this.escolaService.filterFormGroup">
    <!--      <label>Avaliação Geral</label>-->
    <!--      <ngb-rating [formControl]="this.escolaService.rateGeral" max="5">-->
    <!--        <ng-template let-fill="fill" let-index="index">-->
    <!--          <span class="star" [class.filled]="fill === 100">&#9733;</span>-->
    <!--        </ng-template>-->
    <!--      </ngb-rating>-->
    <!--      &lt;!&ndash;        <rating class="star" [readonly]="false" max="5" rate="0" [(ngModel)]="this.escolaService.rateGeral.value"></rating>&ndash;&gt;-->
    <!--      <mat-divider></mat-divider>-->

    <label>Avaliação {{
      (this.escolaService.rateGeral.value > 0) ? '( +' + this.escolaService.rateGeral.value + ')' : '(Todos)'
      }}</label>
    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
      <ngb-rating [formControl]="this.escolaService.rateGeral" max="5">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
      <button mat-button (click)="this.escolaService.rateGeral.setValue(null)">
        Limpar
      </button>
    </div>


    <mat-divider></mat-divider>

    <label>Total Alunos {{
      matSliderTotalAlunos.value == matSliderTotalAlunos.min ? '(Sem Limite)' :
        '(até ' + matSliderTotalAlunos.value + ')'}}</label>
    <mat-slider #matSliderTotalAlunos
                thumbLabel
                [displayWith]="formatLabel"
                [formControl]="this.escolaService.indicesTotalAlunosMax"
                tickInterval="50"
                [step]="alunosStep"
                [min]="indicesTotalAlunosMinValue"
                [value]="indicesTotalAlunosMaxValue"
                [max]="indicesTotalAlunosMaxValue" class="w-100"></mat-slider>
    <mat-divider></mat-divider>

    <mat-selection-list [formControl]="this.escolaService.clima">
      <label>Qualificações</label>
      <mat-list-option checkboxPosition="before" value="clima" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
        Escolas pelo Clima
      </mat-list-option>
    </mat-selection-list>
    <mat-divider></mat-divider>

    <mat-selection-list [formControl]="this.escolaService.abordagemPedagogica">
      <label>Abordagem Pedagógica</label>
      <ng-container *ngFor="let item of abordagemPedagogica" class="bottom-margin">
        <mat-list-option checkboxPosition="before"
                         value="{{item.name}}"
                         ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
          {{item.title}}
        </mat-list-option>
      </ng-container>
      <mat-divider></mat-divider>
    </mat-selection-list>

    <mat-selection-list [formControl]="this.escolaService.oebi">
      <label>Idiomas</label>
<!--      <mat-list-option checkboxPosition="before" value="cellep" [formControl]="this.escolaService.cellep" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
<!--        Cel.Lep-->
<!--      </mat-list-option>-->
      <mat-list-option checkboxPosition="before" value="oebi" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
        Bilíngue OEBI
      </mat-list-option>
    </mat-selection-list>
    <mat-divider></mat-divider>

    <mat-selection-list formControlName="tags">

      <!--      <label>Disciplinas Adicionais</label>-->
      <!--      <ng-container *ngFor="let item of escolaTags">-->
      <!--        <mat-list-option checkboxPosition="before" value="{{item.name}}" *ngIf="item.group === 'Disciplinas Adicionais'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
      <!--          {{item.title}}-->
      <!--        </mat-list-option>-->
      <!--      </ng-container>-->
      <!--      <mat-divider></mat-divider>-->

      <!--      <label>Cursos Extracurriculares</label>-->
      <!--      <ng-container *ngFor="let item of escolaTags">-->
      <!--        <mat-list-option checkboxPosition="before" value="{{item.name}}" *ngIf="item.group === 'Extracurricular'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
      <!--          {{item.title}}-->
      <!--        </mat-list-option>-->
      <!--      </ng-container>-->
      <!--      <mat-divider></mat-divider>-->

      <!--      <label>Recursos</label>-->
      <!--      <ng-container *ngFor="let item of escolaTags">-->
      <!--        <mat-list-option checkboxPosition="before" value="{{item.name}}" *ngIf="item.group === 'Recursos'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
      <!--          {{item.title}}-->
      <!--        </mat-list-option>-->
      <!--      </ng-container>-->
      <!--      <mat-divider></mat-divider>-->

      <mat-selection-list [formControl]="this.escolaService.geekie">
        <label>Material Didático</label>
        <mat-list-option checkboxPosition="before" value="geekie" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">
          Geekie
        </mat-list-option>
      </mat-selection-list>
      <mat-divider></mat-divider>

      <!--      <label>Alimentação</label>-->
      <!--      <ng-container *ngFor="let item of escolaTags">-->
      <!--        <mat-list-option checkboxPosition="before" value="{{item.name}}" *ngIf="item.group === 'Alimentação'" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
      <!--          {{item.title}}-->
      <!--        </mat-list-option>-->
      <!--      </ng-container>-->
      <!--      <mat-divider></mat-divider>-->

      <!--      <label>Outros</label>-->
      <!--      <ng-container *ngFor="let item of escolaTags">-->
      <!--        <mat-list-option checkboxPosition="before" value="{{item.name}}" *ngIf="item.group === null" ngStyle.gt-xs="display:inline-block; width:50%; text-align:center;">-->
      <!--          {{item.title}}-->
      <!--        </mat-list-option>-->
      <!--      </ng-container>-->
      <!--      <mat-divider></mat-divider>-->

    </mat-selection-list>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action-stick-bottom">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxFill>
    <button mat-stroked-button class="btn btn-clear" mat-button mat-dialog-close color="primary"
            (click)="resetFilters()">Limpar
    </button>
    <button mat-stroked-button class="btn btn-apply" mat-button color="primary"
            (click)="escolaService.filterEvent.next()"
            [mat-dialog-close]="true" cdkFocusInitial>Filtrar
    </button>
  </div>
</mat-dialog-actions>
<!--</div>-->
