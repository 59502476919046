import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

@Component({
  selector: 'app-escola-eventos-edit',
  templateUrl: './escola-eventos-edit.component.html',
  styleUrls: ['./escola-eventos-edit.component.css'],
})
export class EscolaEventosEditComponent implements OnInit {
  @Input() escola?: Escola;
  @Input() editing: boolean = false;

  constructor(private storage: AngularFireStorage) {}

  ngOnInit(): void {
    if (!this.escola.eventos) {
      this.escola.eventos = [];
    }
  }

  addItem(): void {
    this.escola.eventos.push({
      data: '',
      link: '',
      thumb: '',
      titulo: '',
    });
  }

  removeItem(index: number): void {
    this.escola.eventos.splice(index, 1);
  }

  saveItems(): void {
    console.log(this.escola);
  }

  previewThumb(event: any, index: number): void {
    const file = event.target.files[0];

    if (file) {
      const filePath = `thumbs/${new Date().getTime()}_${file.name}`;
      const fileRef = this.storage.ref(filePath);

      const task: AngularFireUploadTask = this.storage.upload(filePath, file);

      task.then((a) => {
        fileRef.getDownloadURL().subscribe((url) => {
          console.log('url', url);
          if (this.escola.eventos) {
            this.escola.eventos[index].thumb = url;
            this.escola.eventos[index].thumb_file = file;
          }
        });
      });
    }
  }
}
