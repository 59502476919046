<aside id="informacoes-importantes" class="fixed">
  <!-- CARD COMPONENT -->
  <div class="card mb-4">
    <!-- LIST -->
    <div class="list">
      <!-- ITEM -->
      <div class="list-item mb-3">
        <div *ngIf="this.getFinalScore() >= 0" class="d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <p *ngIf="this.score" class="text-black font-size-title bold mb-0 pt-1">{{ this.getFinalScore() }}%</p>
          </div>
          <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3 class="text-pink font-size-smallest mb-1">
              é a afinidade desta escola com seu perfil familiar. Clique 
              <a href="/perfil-afinidade" target="_blank" title="recalcule sua afinidade" class="btn-text text-green font-size-smallest">aqui</a> 
              para recalcular.
            </h3>
          </div>
        </div>
        <div *ngIf="this.getFinalScore() < 0" class="d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="action ms-auto">
              <a href="/perfil-afinidade" target="_blank" title="recalcule sua afinidade" class="btn-text text-green font-size-smallest">Calcule sua afinidade com esta escola</a>
            </div>
          </div>
        </div>
      </div>
      <!-- END ITEM -->
      <!-- ITEM -->
      <div class="list-item mb-3">
        <div class="subtitle mb-1">
          <h3 class="text-pink font-size-smallest mb-0">Localização</h3>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <p class="text-black font-size-regular semibold ellipsis-two mb-0">{{ this.escola.endereco }}</p>
        </div>
      </div>
      <!-- END ITEM -->
      <!-- ITEM -->
      <div *ngIf="!escola.premium || escola.mensalidade_show" class="list-item mb-3">
        <div class="subtitle mb-1">
          <h3 class="text-pink font-size-smallest mb-0">Mensalidade média</h3>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <p *ngIf="this.showInfo && escola.mensalidade" class="text-black font-size-regular semibold mb-0">
            {{ escola.mensalidade | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }} <small class="text-lightgray font-size-smallest">{{ escola.data_mensalidade_ano ? " (referente a " + escola.data_mensalidade_ano + ")" : "" }}</small>
          </p>
          <p *ngIf="this.showInfo && !escola.mensalidade" class="text-black font-size-regular semibold mb-0">
            <button title="Solicitar mensalidade" *ngIf="!requestTuitionLoading && !requestTuitionSented" class="font-size-small bold text-pink btn-border border-0 bg-transparent text-decoration-underline" (click)="sendRequestTuition()">Solicitar mensalidade</button>
            <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="requestTuitionLoading ? '' : 'none'"></mat-progress-bar>
            <small *ngIf="requestTuitionSented">
              <em *ngIf="escola.premium">Aguarde o contato da escola</em>
              <em *ngIf="!escola.premium">Já solicitamos à escola que atualize esta informação</em>
            </small>
          </p>
          <p *ngIf="!this.showInfo" class="text-black font-size-regular semibold mb-0 blur">R$ #.###,## <small class="text-lightgray font-size-smallest"> (referente a ####)</small></p>
        </div>
      </div>
      <!-- END ITEM -->

      <!-- PARTNER -->
      <div *ngIf="this.escolaBeneficio.tipo === 2" class="list-item mb-3">
        <div class="subtitle mb-1">
          <h3 class="text-pink font-size-smallest mb-0">Esta escola oferece benefício para a SchoolAdvisor</h3>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <p *ngIf="this.showInfo" class="text-black font-size-regular semibold mb-0">
            {{ this.escolaBeneficio.beneficio }}
          </p>
          <p *ngIf="!this.showInfo" class="text-black font-size-regular semibold mb-0 blur">################</p>
        </div>
      </div>

      <!-- END PARTNER -->

      <!-- ITEM -->
       <div class="row">
        <div *ngIf="escola.email" class="list-item col-12 mb-3">
          <div class="subtitle mb-1">
            <h3 class="text-pink font-size-smallest mb-0">E-mail da escola</h3>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p class="text-black font-size-regular semibold mb-0">
              <a *ngIf="this.showInfo" href="mailto:{{ escola.email }}" (click)="this.analytics.linkEscolaField(this.escola, 'email', userService.getCurrentUser())" title="E-mail" target="_blank" class="btn-text text-black font-size-smallest">
                {{ escola.email }}
              </a>
              <a *ngIf="!this.showInfo" class="btn-text text-black font-size-smallest blur"> #######@#######.###.## </a>
            </p>
          </div>
        </div>
        <div *ngIf="escola.fone" class="list-item col-6 mb-3">
          <div class="subtitle mb-1">
            <h3 class="text-pink font-size-smallest mb-0">Telefone</h3>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p class="text-black font-size-regular semibold mb-0">
              <a *ngIf="this.showInfo" href="{{ this.linkTelefone }}" (click)="this.sendPhoneAnalytics(false)" title="Telefone" target="_blank" class="btn-text text-black font-size-smallest" (click)="analytics.linkEscolaField(this.escola, 'fone', userService.getCurrentUser())">
                {{ this.formatPhone(escola.fone) }}
              </a>
              <a *ngIf="!this.showInfo" class="btn-text text-black font-size-smallest blur"> (00) 0000-0000 </a>
            </p>
          </div>
        </div>
        <div *ngIf="escola.social['whatsapp']" class="list-item col-6 mb-3">
          <div class="subtitle mb-1">
            <h3 class="text-pink font-size-smallest mb-0">WhatsApp</h3>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p class="text-black font-size-regular semibold mb-0">
              <a *ngIf="this.showInfo" href="{{ this.linkWhatsapp }}" (click)="this.sendPhoneAnalytics(true)" title="Telefone" target="_blank" class="btn-text text-black font-size-smallest" (click)="analytics.linkEscolaField(this.escola, 'fone', userService.getCurrentUser())">
                {{ this.formatPhone(escola.social['whatsapp']) }}
              </a>
              <a *ngIf="!this.showInfo" class="btn-text text-black font-size-smallest blur"> (00) 0000-0000 </a>
            </p>
          </div>
       </div>
      
      </div>
      <!-- END ITEM -->
      <!-- ITEM -->
      <div *ngIf="escola.site" class="list-item mb-3 d-flex flex-wrap">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mb-3">
          <div class="subtitle mb-1">
            <h3 class="text-pink font-size-smallest mb-2">Canais institucionais</h3>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="action {{ this.showInfo ? '' : 'blur' }}">
              <button type="button" [attr.disabled]="this.showInfo ? null : true" (click)="goToSite()" title="ir para o site" class="btn-text text-black font-size-smallest border-0 bg-transparent">ir para o site</button>
            </div>
          </div>
        </div>
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6 mb-3">
          <div class="subtitle mb-1" style="height: 14.39px"></div>
          <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="this.hasSocialMedia()">
            <div class="social {{ this.showInfo ? '' : 'blur' }}">
              <ul class="d-flex flex-row">
                <li *ngIf="escola.social['instagram']">
                  <a [attr.href]="this.showInfo ? escola.social['instagram'] : null" target="_blank" (click)="this.analytics.linkEscolaField(this.escola, 'instagram', userService.getCurrentUser())" title="Instagram">
                    <img src="images/new/icon-instagram.svg" alt="Instagram" />
                  </a>
                </li>
                <li *ngIf="escola.social['facebook']">
                  <a [attr.href]="this.showInfo ? escola.social['facebook'] : null" title="Facebook" target="_blank" (click)="this.analytics.linkEscolaField(this.escola, 'facebook', userService.getCurrentUser())">
                    <img src="images/new/icon-facebook.svg" alt="Facebook" />
                  </a>
                </li>
                <!-- li *ngIf="escola.social['twitter']">
                  <a [attr.href]="this.showInfo ? escola.social['twitter'] : null" target="_blank" (click)="this.analytics.linkEscolaField(this.escola, 'twitter', userService.getCurrentUser())" title="Twitter">
                    <img src="images/new/icon-twitter.svg" alt="Twitter" />
                  </a>
                </li -->
                <li *ngIf="escola.social['youtube']">
                  <a [attr.href]="this.showInfo ? escola.social['youtube'] : null" target="_blank" (click)="this.analytics.linkEscolaField(this.escola, 'youtube', userService.getCurrentUser())" title="YouTube">
                    <img src="images/new/icon-youtube.svg" alt="YouTube" />
                  </a>
                </li>
                <li *ngIf="escola.social['linkedin']">
                  <a [attr.href]="this.showInfo ? escola.social['linkedin'] : null" target="_blank" (click)="this.analytics.linkEscolaField(this.escola, 'linkedin', userService.getCurrentUser())" title="Linkedin">
                    <img src="images/new/icon-linkedin.svg" alt="Linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- END ITEM -->
    </div>
    <!-- END LIST -->

    <!-- ACTION -->
    <div class="action d-flex align-items-center justify-content-center flex-column mb-4">
      <button *ngIf="!this.showInfo" class="btn btn-border border-pink text-pink font-size-regular semibold w-100 mb-2" (click)="showAllInfo()">Desbloquear todas as informações</button>
      <button class="btn background-pink text-white font-size-regular semibold w-100" 
              (click)="sendContact()" 
              *ngIf="escola.premium && !schoolContactSending && !schoolContactSented">Falar com a escola</button>
      <mat-progress-bar mode="indeterminate" style="display: none" [style.display]="schoolContactSending ? '' : 'none'"> </mat-progress-bar>
      <div class="item school-contact" *ngIf="schoolContactSented">
        <p class="text-black font-size-regular semibold mb-0" *ngIf="!escola.premium">Entre em contato com a escola:</p>
        <p class="text-black font-size-regular semibold mb-0" *ngIf="escola.premium">
          Aguarde o contato da escola.
          <!-- <a class="font-size-small bold text-pink" href="tel:{{ escola.fone }}" *ngIf="escola.fone" title="Telefone" target="_blank" (click)="analytics.linkEscolaField(this.escola, 'fone', userService.getCurrentUser())">
            {{ escola.fone?.length > 10 ? (escola.fone | mask : "(00) 00000-0000") : (escola.fone | mask : "(00) 0000-0000") }}
          </a> -->
        </p>
        <!-- <p class="text-black font-size-regular semibold mb-0">
          <a class="font-size-small bold text-pink" href="mailto:{{ escola.email }}" *ngIf="escola.email" title="E-mail" target="_blank" (click)="analytics.linkEscolaField(this.escola, 'email', userService.getCurrentUser())">
            {{ escola.email }}
          </a>
        </p>
        <div *ngIf="escola.premium" class="item socials social">
          <div class="social" *ngIf="escola.social['whatsapp']">
            <ul class="d-flex flex-row">
              <li>
                <a href="https://api.whatsapp.com/send?phone={{ escola.social['whatsapp'] }}&text=Oi,%20encontrei%20{{ escola.nome }}%20no%20site%20da%20SchoolAdvisor" target="_blank" (click)="analytics.linkEscolaField(this.escola, 'whatsapp', userService.getCurrentUser())" title="WhatsApp">
                  <img src="images/social-whats.svg" alt="WhatsApp" style="width: 30px" />
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
    <!-- END ACTION -->

    <!-- SHARE -->
    <div class="row">
      <div class="col-4" style="text-align: center;">
        <a title="Compartilhar" class="btn-share" style="cursor: pointer" (click)="share()">
          <img src="images/new/btn-share.svg" alt="Compartilhar" />
          <br />
          <span class="font-size-smallest text-lightgray semibold">Compartilhar</span>
        </a>
      </div>
      <div class="col-4" style="text-align: center;">
        <a title="Gostei" class="btn-like" style="cursor: pointer" (click)="this.userService.setFavorito(escola, !escola.favorito)">
          <img src="{{ this.escola.favorito ? 'images/new/btn-like.svg' : 'images/new/btn-like-des.svg' }}" alt="Seguir" />
          <br />
          <span class="font-size-smallest text-lightgray semibold">{{ this.escola.favorito ? "Seguindo" : "Seguir" }}</span>
        </a>
      </div>
      <div *ngIf="!escola?.can_edit" class="col-4" style="text-align: center;">
        <a title="Sou proprietário" class="btn-share" style="cursor: pointer" (click)="proprietario($event)">
          <img src="images/new/btn-owner.svg" alt="Sou proprietário" style="width: 42px; height: 42px;" />
          <br />
          <span class="font-size-smallest text-lightgray semibold">Sou proprietário</span>
        </a>
      </div>
      <div *ngIf="!this.editing && escola && escola?.can_edit" class="col-4" style="text-align: center;">
        <a title="Editar" class="btn-share" style="cursor: pointer" (click)="sendToEdit()">
          <img src="images/new/btn-edit.svg" alt="Sou proprietário" style="width: 42px; height: 42px;" />
          <br />
          <span class="font-size-smallest text-lightgray semibold">Editar</span>
        </a>
      </div>
    </div>
    <!-- END SHARE -->
  </div>
  <!-- END CARD COMPONENT -->

  <!-- <div class="more-info d-flex flex-column align-items-center justify-content-center">
    <a href="#" title="Reportar erro na informação" class="btn-text style-normal font-size-smallest text-lightgray mb-3"><img src="images/new/school/icon-alert.svg" alt="Reportar erro na informação" class="me-2" />Reportar erro na informação</a>
    <a *ngIf="!escola?.can_edit" (click)="proprietario($event)" href="/proprietario" title="Sou proprietário da escola" class="btn-text style-normal font-size-smallest text-lightgray"><img src="images/new/school/icon-owner-school.svg" alt="Sou proprietário da escola" class="me-2" />Sou proprietário da escola</a>
  </div> -->
</aside>
