import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList, AfterViewInit, AfterViewChecked, ChangeDetectorRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {UserService} from '../../service/user.service';
import {Escola} from '../../service/Escola';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ChatService, snapshotToArray} from '../../service/chat.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-chatroom',
  templateUrl: './chatroom.component.html',
  styleUrls: ['./chatroom.component.scss']
})
export class ChatroomComponent implements OnInit {

  @Output() newMessage: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('chatcontent') chatcontent?: ElementRef;
  @ViewChildren('messageBox') messageBox?: QueryList<any>;
  scrolltop: number | null = null;
  chatForm: FormGroup;
  @Input() escola: Escola;
  @Input() roomname = 'public';
  @Input() admin = false;
  message = '';
  disableInput = false;
  users: any[] = [];
  chats: any[] = [];
  messages: any[] = [];
  matcher = new MyErrorStateMatcher();
  checkIfExists = true;
  inputPlaceholder = 'Digite sua mensagem aqui';
  messageRef;

  constructor(
      public userService: UserService,
      private chatService: ChatService,
      private router: Router,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private _snackBar: MatSnackBar,
      private db: AngularFireDatabase,
      private changeDetectorRef: ChangeDetectorRef,
  ) {
    // this.roomname = this.route.snapshot.params.roomId;
    this.chatForm = this.formBuilder.group({
      message: [null, Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.messageRef = this.db.database.ref(`chats/messages/${this.roomname}`);
    this.messageRef.on('value', resp => {
      this.messages = [];
      this.messages = snapshotToArray(resp);
      this.newMessage.emit(this.messages);
      this.changeDetectorRef.detectChanges();
    });
  }

  async onFormSubmit(form: any): Promise<void> {
    try {
      if (this.checkIfExists) {
        const room_title = 'Conversa com ' + this.userService.getCurrentUser().displayName;
        const isNew = await this.chatService.createRoomIfNotExists(this.roomname, {
          cod_escola: this.escola.codigo,
          room_title
        });
        // if (isNew) {
        //   this.messageRef.off();
        //   this.messageRef.on('value', resp => {
        //     this.messages = [];
        //     this.messages = snapshotToArray(resp);
        //     this.newMessage.emit(this.messages);
        //   });
        // }
        this.checkIfExists = false;
      }
      const message = form.message;
      const name = this.userService.getCurrentUser().displayName;
      // console.log('sending message...');
      await this.chatService.sendMessage(this.roomname, {message, name});

      // const message = form;
      // const uid = await this.userService.getCurrentUser()?.uid;
      // const ref = this.db.database.ref(`chats/${this.roomname}`);
      // // message.roomname = this.roomname;
      // message.nickname = await this.getNick();
      // message.date = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
      // message.uid = uid || '';
      // message.type = 'message';
      // ref.update({
      //   uid,
      //   name: this.currentUser?.name || null,
      //   email: this.currentUser?.email || null,
      // });

    } catch (e) {
      if (e.message === 'Permission denied') {
        const message = 'Esse chat está bloqueado.';
        this._snackBar.open(message, 'Ok');
        this.disableInput = true;
        this.inputPlaceholder = message;
      } else {
        console.log(e.message);
      }
    }
    this.chatForm = this.formBuilder.group({
      message: [null, Validators.required]
    });
  }

}
