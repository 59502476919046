import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Escola, EscolaLista} from '../../service/Escola';
import {EscolaService} from '../../service/escola.service';
import {Router} from '@angular/router';
import firebase from 'firebase/app';
import {UserService} from '../../service/user.service';
import {FormControl} from '@angular/forms';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import * as clone from 'clone';
import {DefaultSliderConfig} from '../../navigation/Slider';
import {AnalyticsService} from '../../service/analytics.service';
import {SwiperOptions} from "swiper";

@Component({
  selector: 'app-escola-list-horizontal',
  templateUrl: './escola-list-horizontal.component.html',
  styleUrls: ['./escola-list-horizontal.component.scss']
})
export class EscolaListHorizontalComponent implements OnInit {

  private _escolaLista: EscolaLista;
  @Input() readonly = false;
  escolaFiltered: Escola[] | string[] = [];
  width: number;
  @Input() sliderConfig = DefaultSliderConfig;
  @Input() editing = false;
  canEdit = false;
  saving = false;
  isLoading = false;
  selectedEscola: Escola = null;
  escolaFormControl = new FormControl();
  loadingMessage: string = null;
  @ViewChild('slickModal') slickCarouselComponent: SlickCarouselComponent;
  config: SwiperOptions = {
    breakpoints: {
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      1280: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      },
      1: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      }
    },
    autoHeight: true,
    lazy: true,
    navigation: false,
    slidesPerView: 5,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }
  };

  constructor(private escolaService: EscolaService,
              private route: Router,
              public userService: UserService,
              private analytics: AnalyticsService) {

    userService.userDataEvent.subscribe(value => {
      this.canEdit = (userService.master === true);
    });
  }

  ngOnInit(): void {
    this.escolaFormControl.valueChanges.pipe(
      tap((value) => {
        this.escolaService.nome.setValue(value);
      }),
      debounceTime(1500),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (typeof value === 'string') {
          this.selectedEscola = null;
          if (value.length < 3) {
            this.loadingMessage = `Digite ao menos 3 letras para pesquisar.`;
          } else {
            this.isLoading = true;
            const criteria = {
              nome: value
            };
            this.loadingMessage = `Procurando escolas com nome '${value}'`;
            firebase.functions().httpsCallable('nome_escola')(criteria).then(value1 => {
              if (value1.data?.length > 0) {
                this.escolaFiltered = value1.data;
              } else {
                this.escolaFiltered = [];
              }
              this.isLoading = false;
            });
          }
        } else {
          this.isLoading = false;
          this.selectedEscola = value;
          // Traga somente a escola marcada
          this.escolaFiltered = [value];
        }
        return [];
      })
    ).subscribe();

    if (this.escolaLista?.cod_escola != null) {
      this.refresh();
    }
  }

  @Input()
  set escolaLista(escolaLista: EscolaLista) {
    if (escolaLista) {
      this._escolaLista = escolaLista;
      // console.log('set EscolaLista: ' + escolaLista?.nome_lista);
      const escolaListaArray: EscolaLista[] = [];
      escolaListaArray.push(escolaLista);
      // console.log('Set escolaLista: ' + JSON.stringify(escolaListaArray));
      if (escolaLista.escola_lista) {
        this.analytics.escolaListaPrint(escolaListaArray);
      }
    }
  }

  get escolaLista(): EscolaLista {
    return this._escolaLista;
  }

  private async refresh(): Promise<any> {
    this.escolaLista.escola_lista = null;
    firebase.functions().httpsCallable('listas')(this.escolaLista)
      .then(value => {
        this.escolaLista = value.data[0];
        this.analytics.escolaListaPrint(value.data);
      });
  }

  map(): void {
    this.escolaService.listas.setValue([this.escolaLista.nome_lista]);
    this.escolaService.filterOption.setValue('listaMap');
    this.route.navigate(['mapa']);
  }

  removeLista(): void {
    this.escolaLista.escola_lista = [];
    firebase.functions().httpsCallable('set_lista')(this.escolaLista)
      .then(value => {
        this.editing = false;
        this.refresh().then();
      });
  }

  addEscola(escola: Escola): void {
    this.escolaLista.escola_lista.push(escola);
    this.slickCarouselComponent.slickGoTo(this.slickCarouselComponent.slides.length);
  }

  displayEscola(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  removeEscola(escola: Escola): void {
    this.escolaLista.escola_lista.splice(this.escolaLista.escola_lista.indexOf(escola), 1);
  }

  saveLista(): void {
    this.saving = true;
    firebase.functions().httpsCallable('set_lista')(this.escolaLista)
      .then(value => {
        this.saving = false;
        this.editing = false;
        this.refresh().then();
      });
  }

  cancelEdit(): void {
    this.editing = false;
    this.refresh().then();
  }

  startEdit(): void {
    this.editing = true;
    this.escolaLista.nome_lista_original = this.escolaLista.nome_lista;
  }

  move(fromIndex: number, toIndex: number): void {
    if (fromIndex >= 0 && toIndex >= 0) {
      const cloneArray = clone(this.escolaLista.escola_lista);
      const escola = this.escolaLista.escola_lista[fromIndex];
      this.escolaLista.escola_lista.splice(fromIndex, 1);
      this.escolaLista.escola_lista.splice(toIndex, 0, escola);
      this.escolaLista.escola_lista = cloneArray;
    }
    // let s = '';
    // this.escolaLista.escola_lista.forEach(value => {
    //   s += value.codigo + ' - ';
    // });
    // console.log(s);
  }

  trackEscola(index: number, element: Escola): number {
    return element ? element.codigo : null;
  }
}
