<div (click)="closeModal()" class="modal fade modal-component show" id="modalAvaliacao" tabindex="-1" aria-labelledby="filterModal" aria-hidden="true" style="display: block">
  <div (click)="$event.stopPropagation()" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <!-- HEADER MODAL -->
      <div class="modal-header">
        <!-- MODAL TITLE -->
        <div class="d-flex flex-column">
          <h2 class="text-black font-size-biggest mb-0">Compartilhar</h2>
        </div>

        <button type="button" class="close-modal" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- END HEADER MODAL -->

      <!-- BODY MODAL -->
      <div class="modal-body row">
        <!-- CONTENT MODAL -->
        <form class="content-rating">
          <div class="col-12 d-flex flex-wrap content-rating-stars mt-2 mb-1">
            <share-buttons theme="modern-dark" [include]="['copy', 'facebook', 'email', 'messenger', 'linkedin', 'pinterest', 'print', 'reddit', 'telegram', 'whatsapp']" [showIcon]="true" [showText]="true" [url]="this.data.url" [description]="this.data.text" [title]="this.data.title"> </share-buttons>
          </div>
        </form>
        <!-- END CONTENT MODAL -->
      </div>
      <!-- END BODY MODAL -->
    </div>
  </div>
</div>
