<div class="banner-bottom-fixed w-100" [@flyInOut]="isOpen ? 'open' : 'close'" *ngIf="show">
  <button mat-stroked-button class="banner-bottom-button position-absolute start-50 translate-middle-x" color="primary"
          (click)="isOpen = !isOpen" style="z-index: 5; top: -25px">
    Parceiros
    <mat-icon *ngIf="isOpen">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="!isOpen">keyboard_arrow_up</mat-icon>
  </button>
  <a [routerLink]="['/parceiros']" target="_blank" (click)="clickBannerParceiros()">
    <div class="banner-bottom-image w-100 d-flex justify-content-center">
      <!--  [@fadeInOutAnimation]>-->
      <!--  [@openClose]="isOpen ? 'open' : 'close'-->
    </div>
  </a>
</div>
