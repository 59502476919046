import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import firebase from 'firebase/app';
import { Escola } from '../../service/Escola';
import { EscolaService } from '../../service/escola.service';

@Component({
  selector: 'app-escola-info',
  templateUrl: './escola-info.component.html',
  styleUrls: ['./escola-info.component.css'],
})
export class EscolaInfoComponent implements OnInit, AfterViewInit {
  @Input() isMobile: boolean = false;
  @Input() escola: Escola;
  @Input() familyChoice: boolean = false;
  reviewCount = 0;
  destaque = "";
  destaqueCount = 0;
  totalDestaque = 0;

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadReviews();
  }

  async loadReviews(): Promise<void> {
    const value = await firebase
      .functions()
      .httpsCallable('destaques_escola')({ cod_escola: this.escola.codigo })
      
    this.reviewCount = value.data.total_reviews
    this.destaque = value.data.destaque
    this.destaqueCount = value.data.destaque_qtd
    this.totalDestaque = value.data.destaque_total
  }

  scrollTo(event: Event, divId: string): void {
    event.preventDefault();
    const element = document.querySelector(divId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  destaquePorcentagem(): number {
    return Math.round((this.destaqueCount / this.totalDestaque) * 100);
  }

  obterDestaqueTitulo(): string {
    switch (this.destaque) {
      case "Artes e cultura":
        return "Vivência em Artes e Cultura";

      case "Valores religiosos e espirituais":
        return "Formação Religiosa e Espiritual";

      case "Esportes":
        return "Escola Esportiva";

      case "Envolvimento familiar":
        return "Escola parceira da família";

      case "Socioemocional":
        return "Programa de Habilidades Socioemocionais";

      case "Personalização do ensino":
        return "Trajetórias de Aprendizagem Personalizadas";

      case "Educação ambiental e sustentabilidade":
        return "Escola Consciente";

      case "Aprendizagem experiencial e prática":
        return "Fazer para Aprender";

      case "Educação tecnológica e fluência digital":
        return "Habilidades Digitais para o Futuro";

      case "Cidadania e globalização":
        return "Cidadãos do Mundo";

      case "Exigência acadêmica":
        return "Exigência acadêmica";

      case "Preparação universitária":
        return "Preparação para o Ensino Superior";

      default:
        return this.destaque;
    }
  }

  obterDestaqueDescricao(): string {
    switch (this.destaque) {
      case "Artes e cultura":
        return "das famílias afirmam que a escola tem ênfase em artes e cultura";

      case "Valores religiosos e espirituais":
        return "das famílias afirmam que a escola possui valores religiosos e espirituais";

      case "Esportes":
        return "das famílias afirmam que a escola tem ênfase em esportes";

      case "Envolvimento familiar":
        return "das famílias afirmam que a escola promove envolvimento e participação familiar";

      case "Socioemocional":
        return "das famílias afirmam que a escola tem ênfase socioemocional";

      case "Personalização do ensino":
        return "das famílias afirmam que a escola tem personalização do ensino";

      case "Educação ambiental e sustentabilidade":
        return "das famílias afirmam que a escola oferece educação ambiental e sustentabilidade";

      case "Aprendizagem experiencial e prática":
        return "das famílias afirmam que a escola trabalha com aprendizagem experiencial e prática";

      case "Educação tecnológica e fluência digital":
        return "das famílias afirmam que a escola oferece educação tecnológica e fluência digital";

      case "Cidadania e globalização":
        return "das famílias afirmam que a escola tem foco em cidadania e globalização";

      case "Exigência acadêmica":
        return "das famílias afirmam que a escola tem exigência acadêmica";

      case "Preparação universitária":
        return "das famílias afirmam que a escola tem preparação universitária";

      default:
        return this.destaque;
    }
  }

  obterMelhorNota(): number {
    const infraestrutura = this.escola.rate_infraestrutura ?? 0;
    const seguranca = this.escola.rate_seguranca ?? 0;
    const pedagogico = this.escola.rate_pedagogico ?? 0;
    const acolhimento = this.escola.rate_acolhimento ?? 0;
    const cursos = this.escola.rate_extracurriculares ?? 0;
    const diversidade = this.escola.rate_diversidade ?? 0;
    const inclusao = this.escola.rate_inclusao ?? 0;

    return Math.max(infraestrutura, seguranca, pedagogico, acolhimento, cursos, diversidade, inclusao);
  }

  obterTituloMelhorNota(): string {
    const infraestrutura =  this.escola.rate_infraestrutura ?? 0;
    const seguranca = this.escola.rate_seguranca ?? 0;
    const pedagogico = this.escola.rate_pedagogico ?? 0;
    const acolhimento = this.escola.rate_acolhimento ?? 0;
    const cursos = this.escola.rate_extracurriculares ?? 0;
    const diversidade = this.escola.rate_diversidade ?? 0;
    const inclusao = this.escola.rate_inclusao ?? 0;

    const melhorNota = Math.max(infraestrutura, seguranca, pedagogico, acolhimento, cursos, diversidade, inclusao);

    if (melhorNota === parseFloat(infraestrutura.toString())) {
      return "Infraestrutura de qualidade";
    } else if (melhorNota === parseFloat(seguranca.toString())) {
      return "Escola segura";
    } else if (melhorNota === parseFloat(pedagogico.toString())) {
      return "Ensino de Qualidade";
    } else if (melhorNota === parseFloat(acolhimento.toString())) {
      return "Escola Acolhedora";
    } else if (melhorNota === parseFloat(cursos.toString())) {
      return "Cursos extracurriculares são um diferencial";
    } else if (melhorNota === parseFloat(diversidade.toString())) {
      return "Escola diversa";
    } else if (melhorNota === parseFloat(inclusao.toString())) {
      return "Respeito e Inclusão";
    } else {
      return "Nota";
    }
  }

  obterDescricaoMelhorNota(): string {
    const infraestrutura =  this.escola.rate_infraestrutura ?? 0;
    const seguranca = this.escola.rate_seguranca ?? 0;
    const pedagogico = this.escola.rate_pedagogico ?? 0;
    const acolhimento = this.escola.rate_acolhimento ?? 0;
    const cursos = this.escola.rate_extracurriculares ?? 0;
    const diversidade = this.escola.rate_diversidade ?? 0;
    const inclusao = this.escola.rate_inclusao ?? 0;

    const melhorNota = Math.max(infraestrutura, seguranca, pedagogico, acolhimento, cursos, diversidade, inclusao);

    if (melhorNota === parseFloat(infraestrutura.toString())) {
      return "As famílias avaliaram a infraestrutura desta escola com nota";
    } else if (melhorNota === parseFloat(seguranca.toString())) {
      return "As famílias avaliaram a segurança desta escola com nota";
    } else if (melhorNota === parseFloat(pedagogico.toString())) {
      return "As famílias avaliaram o projeto pedagógico desta escola com nota";
    } else if (melhorNota === parseFloat(acolhimento.toString())) {
      return "As famílias avaliaram o acolhimento desta escola com nota";
    } else if (melhorNota === parseFloat(cursos.toString())) {
      return "As famílias avaliaram a oferta de atividades extracurriculares desta escola com nota";
    } else if (melhorNota === parseFloat(diversidade.toString())) {
      return "As famílias avaliaram a diversidade desta escola com nota";
    } else if (melhorNota === parseFloat(inclusao.toString())) {
      return "As famílias avaliaram o trabalho de inclusão desta escola com nota";
    } else {
      return "Nota";
    }
  }

  showIIESA(): boolean {
    return EscolaService.showIIESA(this.escola);
  }
}
