import {Component, Input, OnInit} from '@angular/core';
import {Escola} from '../../service/Escola';
import {UserService} from '../../service/user.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-button-favorite',
  templateUrl: './button-favorite.component.html',
  styleUrls: ['./button-favorite.component.css']
})
export class ButtonFavoriteComponent implements OnInit {

  @Input()
  escola: Escola;

  constructor(public userService: UserService,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

}
