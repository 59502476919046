import { Component, Input, OnInit } from '@angular/core';
import { Escola, tags } from '../../service/Escola';
import firebase from 'firebase/app';

@Component({
  selector: 'app-escola-caracteristicas',
  templateUrl: './escola-caracteristicas.component.html',
  styleUrls: ['./escola-caracteristicas.component.css'],
})
export class EscolaCaracteristicasComponent implements OnInit {
  @Input() escola: Escola;


  allTags = []

  constructor() {}

  ngOnInit(): void {
    this.loadTags()
  }

  async loadTags() {
    const data: any = {
      cod_escola: this.escola.codigo
    }

    const tagsReview = await firebase.functions().httpsCallable('tags_review_escola')(data)
    this.allTags = tagsReview.data.filter(t => t.tags.length > 0)
    this.allTags = this.allTags.map(t => t.tags)
    /*const allTags = []

    tagsReview.data.forEach(t => {
      t.tags.forEach(tag => {
        allTags.push(tag)
      })
    })

    
    this.allTags = allTags*/
  }

  getHasTags(): boolean {
    return this.allTags.length > 0
  }


  getTagsPercents(tag: string) : number {
    const tagsCount = this.allTags.filter(t => t.includes(tag))

    if (tagsCount.length == 0) {
      return 0
    }

    const total = this.allTags.length

    const percent = (tagsCount.length / total) * 100
    return percent
  }

  getTagsOrdered(): any[] {
    const tagsToOrder = tags

    const tagsOrdered = []

    tagsToOrder.forEach(tag => {
      const percent = Math.trunc(this.getTagsPercents(tag))

      if (percent > 0) {
        tagsOrdered.push({tag, percent})
      }
    })

    tagsOrdered.sort((a, b) => {
      return b.percent - a.percent
    })

    return tagsOrdered
  }
}
