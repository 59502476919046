<div class="main-calc">
  <div class="main-section w-100" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
    <section fxFlex="80" fxFlex.gt-md="50" fxFlex.lt-sm="90">
      <div class="c9">
        <p class="c8"><span class="c24">TERMOS E CONDIÇÕES GERAIS DE USOE DE COMPRA E VENDA&nbsp;DO SITE “SCHOOLADVISOR”</span></p>
        <p class="c11 c21"><span class="c0"></span></p>
        <p class="c8 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c0">Estes termos e condições gerais de uso e de compra e venda aplicam-se aos serviços prestados pela pessoa jurídica&nbsp;</span><span class="c3">SchoolAdvisor Consultoria e Marketing LTDA</span><span
          class="c0">, devidamente registrada sob o CNPJ n. 36.668.115.0001-48, e-mail: contato@schooladvisor.com.br, com sede em:</span></p>
        <p class="c15"><span class="c0">Avenida Jabaquara, 1469 – Bloco B</span></p>
        <p class="c15"><span class="c0">Bairro Mirandópolis</span></p>
        <p class="c15"><span class="c0">São Paulo – SP</span></p>
        <p class="c15"><span class="c0">CEP 04045-002</span></p>
        <p class="c15"><span class="c0">representada por:</span></p>
        <p class="c8"><span class="c3">Fernanda Etlinger</span><span class="c0">, na qualidade de: Diretora de Tecnologia, CPF n. 282.678.118-93, Carteira de Identidade (RG) n. 30.135.097-8, expedida por SSP/SP</span>
        </p>
        <p class="c8"><span class="c0">doravante denominada&nbsp;</span><span class="c3">EDITOR DOSITE</span><span class="c0">,</span></p>
        <p class="c8"><span class="c0">por meio do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">, com o seguinte endereço: </span><span class="c14">
    <a class="c13"
       href="https://www.schooladvisor.com.br">https://www.schooladvisor.com.br</a></span><span
          class="c0">.</span></p>
        <p class="c8 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c3">1. DO OBJETO</span></p>
        <p class="c8"><span class="c0">O site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;caracteriza-se pela prestação dos seguintes serviços, incluindo a venda à distância e por meio eletrônico de produtos e de serviços:</span>
        </p>
        <p class="c8"><span class="c0">– Consultoria especializada&nbsp;&nbsp;&nbsp; &nbsp;<br>– Tratamento de dados&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br>– Pesquisas de mercado e opinião pública &nbsp;<br>– Apoio à educação&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br>– Serviços de propaganda, publicidade e divulgação &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br>– Organização de feiras, eventos, congressos, exposições e festas&nbsp;&nbsp;&nbsp; &nbsp;<br>– Treinamentos &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br>– Produção, fornecimento e curadoria de conteúdos</span>
        </p>
        <p class="c8 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c0">2. DOS SERVIÇOS PRESTADOS</span></p>
        <p class="c11 c19"><span class="c3"></span></p>
        <ol class="c5 lst-kix_list_9-1 start" start="1">
          <li class="c12 li-bullet-0"><span class="c3">PRODUTO PÁGINA VERIFICADA GRATUITA</span></li>
        </ol>
        <ol class="c5 lst-kix_list_4-1 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">Exibição de uma página exclusiva da escola, com aproximadamente 30 itens de consideração, para que os usuários da plataforma conheçam a proposta da instituição. Os demais campos permanecerão fechados e poderão ser comprados pelos usuários do site, sem que haja qualquer tipo de remuneração para a escola.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">1 foto da escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Logotipo</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Exibição de galeria de escolas sugeridas (Você também pode se interessar por...) da página da escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Link para o site, e-mail, redes sociais e WhatsApp da escola, quando disponíveis.</span></li>
        </ol>
        <p class="c18 c11"><span class="c3"></span></p>
        <p class="c18"><span class="c3">Condições da utilização do espaço virtual da plataforma:</span></p>
        <ol class="c5 lst-kix_list_1-0 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">A escola se responsabiliza pelas informações e materiais inseridos por ela, mediante login, na plataforma da SchoolAdvisor;</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A escola se compromete a prestar informações verdadeiras no momento do cadastro;</span></li>
          <li class="c4 li-bullet-0"><span class="c0">O cadastro poderá ser editado pelo representante legal da empresa ou alguém por esse indicado. Caso sua escola deseje ter mais de um representante responsável pelo gerenciamento das informações, deve enviar um e-mail para contato@schooladvisor.com.br solicitando.</span>
          </li>
          <li class="c4 li-bullet-0"><span
            class="c0">As informações alteradas por diferentes representantes da escola serão sobrescritas, sempre priorizando a exibição da última alteração salva na página.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Uma vez alterado o cadastro, não é possível resgatar as informações anteriores.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A responsabilidade&nbsp;por manter os dados atualizados na plataforma é da escola, não havendo qualquer obrigação por parte da SchoolAdvisor em gerenciar a atualização das informações.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">A escola se compromete a revisar seus dados a cada 6 meses, para garantir a veracidade das informações prestadas.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A responsabilidade pelo uso de imagens de terceiros em fotos e vídeos, incluindo&nbsp;menores de idade, é de responsabilidade da escola contratante. A SchoolAdvisor não se responsabiliza pela veiculação de imagens não autorizadas pelos referidos terceiros.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">A página exibirá apenas as informações prestadas pela escola. A responsabilidade por informações faltantes ou incorretas exibidas na página é da contratante.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">As opiniões, avaliações, reviews e conteúdos expressos na plataforma não refletem, necessariamente, a opinião da SchoolAdvisor. A plataforma é apenas um canal de hospedagem, reprodução, distribuição e publicação de informações e conteúdos fornecidos por nossos usuários - pessoas ou instituições. Caso você observe usuários ou instituições que violam a reputação da sua instituição, por favor, envie um e-mail para </span><span
            class="c0"><a class="c13" href="mailto:contato@schooladvisor.com.br">contato@schooladvisor.com.br</a></span><span class="c0">. O time da SchoolAdvisor fará a avaliação da referida denúncia e tomará a decisão de agir de acordo com as Regras de Conduta (</span><span
            class="c14"><a class="c13" href="https://schooladvisor.com.br/regras">https://schooladvisor.com.br/regras</a></span><span
            class="c0">) e Política de Privacidade (https://schooladvisor.com.br/politica).</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A qualquer momento, a SchoolAdvisor se reserva o direito de excluir conteúdos que violam nossos Termos de Uso (https://schooladvisor.com.br/termos), Regras de Conduta da Comunidade (</span><span
            class="c14"><a class="c13" href="https://schooladvisor.com.br/regras">https://schooladvisor.com.br/regras)</a></span><span
            class="c0">&nbsp;e Política de Privacidade (</span><span class="c14">
      <a class="c13" href="https://schooladvisor.com.br/politica">https://schooladvisor.com.br/politica</a></span><span
            class="c0">), sem que haja necessidade de justificativa&nbsp;ou aviso prévio.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Não é possível realizar a inserção de novos campos ou edição dos campos do cadastro.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A SchoolAdvisor se reserva o direito de inserir, excluir ou editar, a qualquer momento e sem aviso prévio, os campos do cadastro.</span></li>
          <li class="c4 c22 li-bullet-0"><span class="c0">Descontos e promoções:&nbsp;não é permitido oferecer quaisquer descontos, bolsas de estudos ou qualquer outra promoção nas imagens, comentários ou outros campos editáveis da plataforma, sem autorização prévia e expressa da SchoolAdvisor</span>
          </li>
        </ol>
        <p class="c19 c11"><span class="c3"></span></p>
        <ol class="c5 lst-kix_list_9-1" start="2">
          <li class="c12 li-bullet-0"><span class="c3">PRODUTO PÁGINA PREMIUM</span></li>
        </ol>
        <ol class="c5 lst-kix_list_12-0 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">Exibição de uma página exclusiva da escola, com Informações completas sobre a instituição, para que os usuários da plataforma conheçam a proposta da instituição.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">Até 10 fotos a serem postadas pela escola1 vídeo a ser postado pela escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Logotipo postado pela escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Exibição da escola no perfil de 02 concorrentes que não tenham página premium patrocinada. A escola pode escolher os concorrentes (sujeito à disponibilidade de espaços na página)</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">Exclusão da galeria de escolas sugeridas (Você também pode se interessar por...) da página da escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Link para o site, e-mail, redes sociais e WhatsApp da escola, quando disponíveis.</span></li>
        </ol>
        <p class="c18 c11"><span class="c3"></span></p>
        <p class="c18"><span class="c3">Condições da locação do espaço virtual da plataforma:</span></p>
        <ol class="c5 lst-kix_list_12-0" start="7">
          <li class="c4 li-bullet-0"><span class="c0">A escola se responsabiliza pelas informações e materiais inseridos por ela, mediante login, na plataforma da SchoolAdvisor;</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A escola se compromete a prestar informações verdadeiras no momento do cadastro;</span></li>
          <li class="c4 li-bullet-0"><span class="c0">O cadastro poderá ser editado pelo representante legal da empresa ou alguém por esse indicado. Caso sua escola deseje ter mais de um representante responsável pelo gerenciamento das informações, deve enviar um e-mail para contato@schooladvisor.com.br solicitando.</span>
          </li>
          <li class="c4 li-bullet-0"><span
            class="c0">As informações alteradas por diferentes representantes da escola serão sobrescritas, sempre priorizando a exibição da última alteração salva na página.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Uma vez alterado o cadastro, não é possível resgatar as informações anteriores.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A responsabilidade&nbsp;por manter os dados atualizados na plataforma é da escola, não havendo qualquer obrigação por parte da SchoolAdvisor em gerenciar a atualização das informações.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">A escola se compromete a revisar seus dados a cada 6 meses, para garantir a veracidade das informações prestadas.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A responsabilidade pelo uso de imagens de terceiros em fotos e vídeos, incluindo&nbsp;menores de idade, é de responsabilidade da escola contratante. A SchoolAdvisor não se responsabiliza pela veiculação de imagens não autorizadas pelos referidos terceiros.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">A página exibirá apenas as informações prestadas pela escola. A responsabilidade por informações faltantes ou incorretas exibidas na página é da contratante.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">As opiniões, avaliações, reviews e conteúdos expressos na plataforma não refletem, necessariamente, a opinião da SchoolAdvisor. A plataforma é apenas um canal de hospedagem, reprodução, distribuição e publicação de informações e conteúdos fornecidos por nossos usuários - pessoas ou instituições. Caso você observe usuários ou instituições que violam a reputação da sua instituição, por favor, envie um e-mail para </span><span
            class="c0"><a class="c13" href="mailto:contato@schooladvisor.com.br">contato@schooladvisor.com.br</a></span><span class="c0">. O time da SchoolAdvisor fará a avaliação da referida denúncia e tomará a decisão de agir de acordo com as Regras de Conduta (</span><span
            class="c14"><a class="c13" href="https://schooladvisor.com.br/regras">https://schooladvisor.com.br/regras</a></span><span
            class="c0">) e Política de Privacidade (https://schooladvisor.com.br/politica).</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A qualquer momento, a SchoolAdvisor se reserva o direito de excluir conteúdos que violam nossos Termos de Uso (https://schooladvisor.com.br/termos), Regras de Conduta da Comunidade ((</span><span
            class="c14"><a class="c13" href="https://schooladvisor.com.br/regras">https://schooladvisor.com.br/regras)</a></span><span
            class="c0">&nbsp;e Política de Privacidade (</span><span class="c14">
      <a class="c13"
         href="https://schooladvisor.com.br/politica">https://schooladvisor.com.br/politica</a></span><span
            class="c0">), sem que haja necessidade de justificativa&nbsp;ou aviso prévio.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">No ato da contratação da Página Premium, a escola deve solicitar as credenciais de acesso à plataforma e realizar o cadastramento das suas informações. Para isso, a SchoolAdvisor fornece acesso para a edição da página a um representante da escola, desde que solicitado através da plataforma.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">Não é possível realizar a inserção de novos campos ou edição dos campos do cadastro.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A SchoolAdvisor se reserva o direito de inserir, excluir ou editar, a qualquer momento e sem aviso prévio, os campos do cadastro.</span></li>
          <li class="c4 c22 li-bullet-0"><span class="c0">Descontos e promoções:&nbsp;não é permitido oferecer quaisquer descontos, bolsas de estudos ou qualquer outra promoção nas imagens, comentários ou outros campos editáveis da plataforma, sem autorização prévia e expressa da SchoolAdvisor</span>
          </li>
        </ol>
        <p class="c17"><span class="c3">Termo de cancelamento</span></p>
        <ol class="c5 lst-kix_list_6-0 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">A escola deve dar um aviso prévio de 30 dias para cancelamento do contrato. </span></li>
          <li class="c4 li-bullet-0"><span
            class="c0">Caso o parcelamento seja superior ao período de veiculação contratado, o cálculo do valor devido será feito com base nos meses de veiculação da página.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Em caso de cancelamento por parte da contratada, será cobrada uma multa rescisória proporcional a um mês de veiculação.</span></li>
        </ol>
        <p class="c11 c23"><span class="c0"></span></p>
        <p class="c19 c11"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_9-1" start="3">
          <li class="c12 li-bullet-0"><span class="c3">PRODUTO ROLÊ NA ESCOLA</span></li>
        </ol>
        <ol class="c5 lst-kix_list_2-0 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">Visita presencial com até 02 horas de duração</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Gravação e edição de um vídeo institucional da escola</span></li>
          <li class="c4 li-bullet-0"><span class="c0">Divulgação do vídeo nos canais digitais da SchoolAdvisor (Facebook, Instagram, YoutTube, e Site - não limitados a esses)</span></li>
        </ol>
        <p class="c18"><span class="c3">Condições da visita</span></p>
        <ol class="c5 lst-kix_list_8-0 start" start="1">
          <li class="c4 li-bullet-0"><span class="c0">A escola é responsável pelas informações e entrevistas fornecidas por seus representantes no momento da visita</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A responsabilidade pelo uso de imagens de terceiros em fotos e vídeos, incluindo menores de idade, é da escola contratante. A SchoolAdvisor não se responsabiliza pela veiculação de imagens não autorizadas pelos referidos terceiros. </span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">O vídeo ficará disponível nos canais da SchoolAdvisor pelo período de 12 meses. Após esse prazo, fica a critério da contratada a exclusão ou permanência do conteúdo.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">Caso a escola deseja excluir o vídeo, deve enviar um e-mail de notificação para </span><span class="c0">
      <a class="c13"
         href="mailto:contato@schooladvisor.com.br">contato@schooladvisor.com.br</a></span><span
            class="c0">. O prazo mínimo para veiculação do vídeo nos canais da SchoolAdvisor é de 1 semana.</span></li>
          <li class="c4 li-bullet-0"><span class="c0">A qualquer momento, a SchoolAdvisor se reserva o direito de excluir conteúdos que violam nossos Termos de Uso ou Regras de Conduta da Comunidade, sem que haja necessidade de justificativa&nbsp;ou aviso prévio.</span>
          </li>
          <li class="c4 li-bullet-0"><span
            class="c0">Os canais, bem como a frequência de divulgação do vídeo, serão acordados mediante aceite da proposta comercial, que é parte integrante do contrato</span></li>
          <li class="c16 c20 li-bullet-0"><span class="c0">Não é possível escolher qual representante da SA comparecerá a visita, que será realizada pela representante disponível na data acordada. A quantidade de representantes da SchoolAdvisor que fará a visita será determinada pela contratada.</span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">A contratante se obriga a manter o espaço físico da escola organizado e alocar um representante da escola para participar da gravação na data da visita. Em caso de chuva ou condições climáticas adversas, a data poderá ser remarcada sem qualquer custo adicional, desde que combinado com pelo menos 4 horas de antecedência. </span>
          </li>
          <li class="c4 li-bullet-0"><span class="c0">Em situações adversas, o reagendamento da visita deve ser feito com, no mínimo,&nbsp;3 dias de antecedência. Após esse período, a visita será cobrada, independente da realização.</span>
          </li>
        </ol>
        <ol class="c5 lst-kix_list_21-0 start" start="3">
          <li class="c1 li-bullet-0"><span class="c3">DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES GERAIS DE USOE DE COMPRA E VENDA</span></li>
        </ol>
        <ol class="c5 lst-kix_list_14-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Todos aqueles que desejarem ter acesso aos serviços ofertados através do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;deverão, primeiramente, se informar sobre as regras que compõem o presente instrumento, as quais ficarão disponíveis para pronta e ampla consulta, em link direto no próprio site.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Ao utilizar o site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">, o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob risco de aplicação das penalidades cabíveis. Antes de iniciar qualquer navegação no site, o usuário deverá cientificar-se de eventuais modificações ou atualizações ocorridas neste termos.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">O usuário poderá ainda ser avisado por e-mail ou diretamente no próprio site sobre eventuais mudanças nas presentes normas.</span></li>
          <li class="c1 li-bullet-0"><span class="c0">Caso não concorde com quaisquer das regras aqui descritas, o usuário deve, imediatamente, abster-se de utilizar o serviço. Se for de seu interesse, poderá, ainda, entrar em contato com o serviço de atendimento ao cliente, para apresentar-lhe as suas ressalvas.</span>
          </li>
        </ol>
        <p class="c8 c10"><span class="c0"></span></p>
        <p class="c8"><span class="c3">4. DA NAVEGAÇÃO</span></p>
        <ol class="c5 lst-kix_list_15-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">O editor do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;se compromete a utilizar todas as soluções técnicas à sua disposição para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. Entretanto, ele poderá, a qualquer momento, interromper, limitar ou suspender o acesso ao site ou a algumas de suas páginas, a fim de realizar atualizações, modificações de conteúdo ou qualquer outra ação julgada necessária para o seu bom funcionamento.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Os presentes termos e condições gerais de uso e de compra e venda se aplicam a todas as extensões do site&nbsp;</span><span
            class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;em redes sociais ou em comunidades, tanto as já existentes, quanto aquelas ainda a serem implementadas.</span></li>
        </ol>
        <p class="c6 c11"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_16-0 start" start="5">
          <li class="c1 li-bullet-0"><span class="c3">&nbsp;DA GESTÃODO SITE</span></li>
        </ol>
        <ol class="c5 lst-kix_list_17-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Para a boa gestão, o editor do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;poderá, a qualquer momento:</span>
          </li>
        </ol>
        <p class="c15 c16"><span class="c0">a) suspender, interromper ou limitar o acesso a todo ou a parte do site a uma categoria específica de internautas;</span></p>
        <p class="c15 c16"><span
          class="c0">b) remover toda informação que possa perturbar o funcionamento do site ou que esteja em conflito com normas de Direito brasileiro ou de Direito internacional;</span></p>
        <p class="c15 c16"><span class="c0">c) suspender o site, a fim de realizar atualizações e modificações.</span></p>
        <p class="c6 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c3">6. DO CADASTRO</span></p>
        <ol class="c5 lst-kix_list_18-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Os serviços disponibilizados no site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;apenas poderão ser acessados por pessoas plenamente capazes, conforme o Direito brasileiro. Todos aqueles que não possuírem plena capacidade civil – menores de 18 anos não emancipados, pródigos, ébrios habituais ou viciados em tóxicos e pessoas que não puderem exprimir sua vontade, por motivo transitório ou permanente – deverão ser devidamente assistidos por seus representantes legais, que se responsabilizarão pelo cumprimento das presentes regras.&nbsp;</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">As pessoas jurídicas também poderão se cadastrar, por meio de seus representantes legais.&nbsp;Ao criar uma conta na SchoolAdvisor para fins comerciais e aceitar o presente contrato em nome de uma empresa, organização ou instituição de ensino, você declara e garante ter autorização e autoridade para representar a referida instituição.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Ao usuário, será permitido manter apenas uma conta junto ao site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">. Contas duplicadas serão automaticamente desativadas pelo editor do site, sem prejuízo de demais penalidades cabíveis.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Para o devido cadastramento junto ao serviço, o usuário deverá fornecer integralmente os dados requeridos. Todas as informações fornecidas pelo usuário devem ser precisas, verdadeiras e atualizadas. Em qualquer caso, o usuário responderá, em âmbito cível e criminal, pela veracidade, exatidão e autenticidade dos dados informados.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todos os contatos necessários. Todas as comunicações enviadas para o referido endereço serão consideradas lidas pelo usuário, que se compromete, assim, a consultar regularmente suas mensagens recebidas e a respondê-las em prazo razoável.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Após a confirmação de seu cadastro, o usuário possuirá um login (apelido) e uma senha pessoais, que deverão ser por ele utilizados para o acesso a sua conta no site&nbsp;</span><span
            class="c3">SCHOOLADVISOR</span><span class="c0">. Estes dados de conexão não poderão ser informados pelo usuário a terceiros, sendo de sua inteira responsabilidade o uso que deles seja feito. O usuário compromete-se a comunicar imediatamente ao editor do site quaisquer atividades suspeitas ou inesperadas em sua conta.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é pessoal e intransferível.</span></li>
          <li class="c1 li-bullet-0"><span class="c0">Será automaticamente descadastrado o usuário que descumprir quaisquer das normas contidas no presente instrumento, sendo-lhe vedado realizar nova inscrição no site.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">O usuário poderá, a qualquer tempo e sem necessidade de justificação, requerer o cancelamento de seu cadastro junto ao site&nbsp;</span><span
            class="c3">SCHOOLADVISOR</span><span class="c0">. O seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto.</span></li>
        </ol>
        <p class="c6 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c3">7. DAS RESPONSABILIDADES</span></p>
        <ol class="c5 lst-kix_list_19-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">O editor se responsabilizará pelos defeitos ou vícios encontrados nos serviços prestados pelo site&nbsp;</span><span
            class="c3">SCHOOLADVISOR</span><span class="c0">, desde que tenha lhes dado causa. Defeitos ou vícios técnicos ou operacionais originados no próprio sistema do usuário não serão de responsabilidade do editor.</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">O editor responsabiliza-se apenas pelas informações que foram por ele diretamente divulgadas. Quaisquer informações incluídas pelos usuários, tais como em comentários e em perfis pessoais, serão de inteira responsabilidade dos próprios.</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">O usuário é responsável, ainda:</span></li>
          <li class="c2 li-bullet-0"><span class="c0">a) pela correta utilização do site e de seus serviços, prezando pela boa convivência, pelo respeito e pela cordialidade no relacionamento com os demais usuários, observando as Regras de Conduta do site;</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">b) pelo cumprimento das regras contidas neste instrumento, bem como normas de Direito nacional e de Direito internacional;</span></li>
          <li class="c2 li-bullet-0"><span class="c0">c) pela proteção dos dados de acesso à sua conta (login e senha).</span></li>
          <li class="c2 li-bullet-0"><span class="c0">O editor não será responsável:</span></li>
        </ol>
        <p class="c15 c16"><span class="c0">a) pelas características intrínsecas da internet, principalmente relativas à confiabilidade e à procedência das informações circulando nesta rede;</span>
        </p>
        <p class="c15 c16"><span class="c0">b) pelos conteúdos ou atividades ilícitas praticadas através de seu site.</span></p>
        <p class="c6 c11"><span class="c0"></span></p>
        <p class="c8"><span class="c3">8. DOS LINKS EXTERNOS</span></p>
        <ol class="c5 lst-kix_list_20-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">O site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;pode conter links externos redirecionando o usuário para outras páginas da internet, sobre os quais o editor não exerce controle. Apesar das verificações prévias e regulares realizadas pelo editor, ele se isenta de qualquer responsabilidade sobre o conteúdo encontrado nestes sites e serviços.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Poderão ser incluídos links nas páginas e nos documentos do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">, desde que não sirvam para fins comerciais ou publicitários. Esta inclusão dependerá de autorização prévia do editor.</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">Não será autorizada a inclusão de páginas que divulguem quaisquer tipos de informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">O editor se reserva o direito de retirar a qualquer momento um link levando ao seu próprio serviço, se a página de origem não estiver conforme a sua política editorial.</span>
          </li>
        </ol>
        <p class="c8 c10"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_22-0 start" start="9">
          <li class="c1 li-bullet-0"><span class="c3">DOS DIREITOS AUTORAIS</span></li>
        </ol>
        <ol class="c5 lst-kix_list_23-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">A estrutura do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">, bem como os textos, os gráficos, as imagens, as fotografias, os sons, os vídeos e as demais aplicações informáticas que o compõem são de propriedade do editor e são protegidas pela legislação brasileira e internacional referente à propriedade intelectual.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Qualquer representação, reprodução, adaptação ou exploração parcial ou total dos conteúdos, marcas e serviços propostos pelo site, por qualquer meio que seja, sem autorização prévia, expressa e escrita do editor, é estritamente vedada, podendo-se recorrer às medidas cíveis e penais cabíveis. Estão excluídos desta previsão apenas os elementos que estejam expressamente designados no site como livres de direitos autorais.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">O acesso não gera para o usuário qualquer direito de propriedade intelectual relativo a elementos do site, os quais restam sob a propriedade exclusiva do editor.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">É vedado ao usuário incluir no site dados que possam modificar o seu conteúdo ou sua aparência.</span></li>
        </ol>
        <h4 class="c7"><span class="c3">&nbsp;</span></h4>
        <ol class="c5 lst-kix_list_22-0" start="10">
          <li class="c1 li-bullet-0"><span class="c3">Permissão e Licença de uso de conteúdos</span></li>
        </ol>
        <ol class="c5 lst-kix_list_24-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Por meio desta licença, você concede à SchoolAdvisor a permissão para que realize o uso e divulgação dos conteúdos produzidos por você, seja usuário ou representante de instituição, sem que haja cobrança de royalties ou qualquer outra taxa relacionada a ela.</span>
          </li>
          <li class="c1 li-bullet-0"><span class="c0">Esta licença permite, por tempo indeterminado, que a SchoolAdvisor:</span></li>
        </ol>
        <p class="c6"><span
          class="c0">– Hospede, reproduza, distribua, publique, utilize e torne acessível publicamente os conteúdos produzidos por você, em nome próprio ou em nome de uma organização,</span></p>
        <p class="c6"><span class="c0">– Hospede, reproduza, distribua, publique, utilize e torne acessível publicamente os conteúdos criativos de terceiros que tenham sido reproduzidos por você na plataforma, sendo você o único responsável por solicitar e receber a autorização expressa para reprodução do referido conteúdo</span>
        </p>
        <p class="c6"><span class="c0">– Altere ou crie derivações e interpretações do seu conteúdo para que se adapte às estruturas funcionais e regras da plataforma&nbsp;</span></p>
        <p class="c6 c11"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_22-0" start="11">
          <li class="c1 li-bullet-0"><span class="c3">DO SERVIÇO DE ATENDIMENTO AO USUÁRIO</span></li>
        </ol>
        <ol class="c5 lst-kix_list_25-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Em caso de dúvidas, sugestões ou problemas com a utilização do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">, o usuário poderá contatar diretamente o seu serviço de atendimento, através do endereço de e-mail: fernanda.etlinger@schooladvisor.com.br, bem como do telefone: (55 11) 96479-5480. Estes serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários:</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">De segunda a sexta-feira, das 9:00 às 16:00</span></li>
          <li class="c1 li-bullet-0"><span class="c0">O usuário poderá, ainda, optar por enviar correspondência ao endereço da sede do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span
            class="c0">, informado no início deste documento.</span>
          </li>
        </ol>
        <p class="c8 c10"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_22-0" start="12">
          <li class="c1 li-bullet-0"><span class="c3">DAS SANÇÕES</span></li>
        </ol>
        <ol class="c5 lst-kix_list_26-0 start" start="1">
          <li class="c1 li-bullet-0"><span class="c0">Sem prejuízo das demais medidas legais cabíveis, o editor do site&nbsp;</span><span class="c3">SCHOOLADVISOR</span><span class="c0">&nbsp;poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário:</span>
          </li>
        </ol>
        <p class="c15 c16"><span class="c0">a) que descumprir quaisquer dos dispositivos contidos no presente instrumento;</span></p>
        <p class="c15 c16"><span class="c0">b) que descumprir os seus deveres de usuário;</span></p>
        <p class="c15 c16"><span class="c0">c) que praticar atos fraudulentos ou dolosos;</span></p>
        <p class="c15 c16"><span class="c0">d) que fornecer qualquer informação incorreta;</span></p>
        <p class="c15 c16"><span class="c0">e) cujo comportamento constitua ou possa vir a importar ofensa ou dano a terceiro ou ao próprio site.</span></p>
        <p class="c6 c11"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_22-0" start="13">
          <li class="c1 li-bullet-0"><span class="c3">DAS ALTERAÇÕES</span></li>
        </ol>
        <ol class="c5 lst-kix_list_27-0 start" start="1">
          <li class="c2 li-bullet-0"><span class="c0">A presente versão dos termos e condições gerais de uso e de compra e venda foi atualizada pela última vez em: 20/08/2021.</span></li>
          <li class="c1 li-bullet-0"><span class="c0">O editor se reserva o direito de modificar, a qualquer momento e sem qualquer aviso prévio, o site e os serviços, bem como as presentes normas, especialmente para adaptá-las às evoluções do site&nbsp;</span><span
            class="c3">SCHOOLADVISOR</span><span class="c0">, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</span></li>
          <li class="c2 li-bullet-0"><span class="c0">Dessa forma, convida-se o usuário a consultar periodicamente esta página para verificar as atualizações.</span></li>
          <li class="c2 li-bullet-0"><span class="c0">O usuário que utilizar o serviço, após realizadas modificações nos termos e condições gerais, demonstra a sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</span>
          </li>
        </ol>
        <p class="c6 c11"><span class="c0"></span></p>
        <ol class="c5 lst-kix_list_22-0" start="14">
          <li class="c1 li-bullet-0"><span class="c3">DO DIREITO APLICÁVEL E DO FORO</span></li>
        </ol>
        <ol class="c5 lst-kix_list_28-0 start" start="1">
          <li class="c2 li-bullet-0"><span class="c0">Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito brasileiro, notadamente a sua legislação consumerista.</span>
          </li>
          <li class="c2 li-bullet-0"><span class="c0">Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do site</span></li>
        </ol>
      </div>
    </section>
  </div>
</div>
