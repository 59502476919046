<div class="modal fade modal-list" id="rankingModal"
     aria-labelledby="rankingModal" aria-hidden="true"
     style="display: contents;">
  <div class="modal-content">
    <div class="review-box w-100 mx-auto text-center">
      <h2>Ranking Geral{{this.review.rate_geral ? ': ' + (this.review.rate_geral| number:'1.2-2':'pt-BR') : ''}}</h2>
      <!--        (rateChange)="this.error = false"-->
      <ngb-rating #ngbRatingGeral
                  [(rate)]="this.review.rate_geral" class="flex-fill" max="5"
                  [readonly]="true"
                  (rateChange)="geralChange($event.valueOf())"
      >
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
      <mat-error *ngIf="!(review.rate_geral > 0) && checkValid">Campo obrigatório</mat-error>
    </div>
    <div class="modal-body">
      <div class="cards">
        <div class="content">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-pedagogico.svg" alt="Ranking Pedagógico"/>
                <h4>Pedagógico</h4>
                <p>&nbsp;{{this.review.rate_pedagogico | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_pedagogico', $event)"
                            [rate]="this.review.rate_pedagogico" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-estrutura.svg" alt="Ranking Infraestrutura"/>
                <h4>Infraestrutura</h4>
                <p>&nbsp;{{this.review.rate_infraestrutura | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_infraestrutura', $event)"
                            [rate]="this.review.rate_infraestrutura" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-higiene.svg" alt="Ranking Higiene"/>
                <h4>Limpeza</h4>
                <p>&nbsp;{{this.review.rate_higiene_limpeza | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_higiene_limpeza', $event)"
                            [rate]="this.review.rate_higiene_limpeza" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-alimentacao.svg" alt="Ranking Alimentação"/>
                <h4>Alimentação</h4>
                <p>&nbsp;{{this.review.rate_alimentacao | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_alimentacao', $event)"
                            [rate]="this.review.rate_alimentacao" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-seguranca.svg" alt="Ranking Segurança"/>
                <h4>Segurança</h4>
                <p>&nbsp;{{this.review.rate_seguranca | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_seguranca', $event)"
                            [rate]="this.review.rate_seguranca" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="review-box item d-flex flex-column align-items-center justify-content-center">
                <img src="images/school/ranking-cursos.svg" alt="Ranking Cursos Extracurriculares"/>
                <h4>Cursos Extracurriculares</h4>
                <p>&nbsp;{{this.review.rate_extracurriculares | number:'1.0-0':'pt-BR'}}</p>
                <ngb-rating (rateChange)="subRankingChange('rate_extracurriculares', $event)"
                            [rate]="this.review.rate_extracurriculares" max="5" [readonly]="this.readonly">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" class="action-stick-bottom" *ngIf="readonly">
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Ok</button>
</div>
