<div fxLayout="column" id="login" style="margin: 10px">

  <div fxLayout="column" fxLayoutAlign="center center" id="login-form-wrapper">

    <div id="login-form">

      <form [formGroup]="loginForm"
            fxLayout="column"
            name="loginForm" novalidate>

        <mat-form-field appearance="outline">
          <input placeholder="e-mail" formControlName="email" matInput autocomplete="username" #email>
          <mat-icon matSuffix color="primary">email</mat-icon>
          <!--          <mat-error *ngIf="loginForm.get('email')?.hasError('required')">-->
          <!--            {{emailErrorRequiredText}}-->
          <!--          </mat-error>-->
          <!--          <mat-error-->
          <!--            *ngIf="!loginForm.get('email')?.hasError('required') &&-->
          <!--                                loginForm.get('email')?.hasError('email')">-->
          <!--            {{emailErrorPatternText}}-->
          <!--          </mat-error>-->
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input placeholder="Senha" formControlName="password" matInput type="password" autocomplete="current-password" #password>
          <mat-icon matSuffix color="primary">lock</mat-icon>
          <!--          <mat-error>-->
          <!--            {{passwordErrorRequiredText}}-->
          <!--          </mat-error>-->
        </mat-form-field>

        <div fxFlex="100"
             class="remember-forgot-password"
             fxLayout="column" fxLayoutGap="3px" fxLayoutAlign="space-between stretched">
          <button (click)="signIn(email.value, password.value)"
                  [disabled]="loginForm.invalid"
                  aria-label="LOG IN"
                  class="submit-button"
                  id="loginButton"
                  mat-raised-button>
            Entrar
          </button>
          <button mat-flat-button (click)="userService.forgotPassword(email.value)"
                  class="forgot-password" mat-button type="button">
            Esqueceu a Senha?
          </button>
        </div>

      </form>

      <mat-divider></mat-divider>

      <div class="separator">
        <span class="text" fxFlex="100" style="text-align: center">Ou</span>
      </div>

      <!--default icon buttons-->
      <div [fxLayoutAlign]="layout == 'row' ? 'space-around center' : 'stretch'"
           fxLayoutGap="5px"
           [fxLayout]="layout"
           fxLayout.xs="column">
        <button (click)="userService.googleAuth()" mat-stroked-button>
          <mat-icon svgIcon="google"></mat-icon>
          Google
        </button>
        <button (click)="userService.facebookAuth()" mat-stroked-button>
          <mat-icon svgIcon="facebook"></mat-icon>
          Facebook
        </button>
        <!--        <button (click)="processLegalSignUP(authProvider.Apple)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                class="apple-filled"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="apple"></mat-icon>-->
        <!--          Apple-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Twitter)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                class="twitter-filled"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="twitter"></mat-icon>-->
        <!--          Twitter-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Github)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="github"></mat-icon>-->
        <!--          GitHub-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Microsoft)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="microsoft"></mat-icon>-->
        <!--          Microsoft-->
        <!--        </button>-->
        <!--        <button (click)="processLegalSignUP(authProvider.Yahoo)"-->
        <!---->
        <!--                [ngClass.xs]="{'space-full-xs':true}"-->
        <!--                mat-button>-->
        <!--          <mat-icon svgIcon="yahoo"></mat-icon>-->
        <!--          Yahoo-->
        <!--        </button>-->
      </div>

    </div>
  </div>
</div>

<!--<div>-->
<!--  <div>-->

<!--    <div>-->
<!--      <h3>Entrar</h3>-->
<!--      <div>-->
<!--        <input type="text" placeholder="e-mail" #login required>-->
<!--      </div>-->

<!--      <div>-->
<!--        <input type="password" placeholder="Senha" #password required>-->
<!--      </div>-->

<!--      &lt;!&ndash; Calling SignIn Api from AuthService &ndash;&gt;-->
<!--      <div>-->
<!--        <input type="button" value="Log in" (click)="authService.signIn(login.value, password.value)">-->
<!--      </div>-->

<!--      <div>-->
<!--        <span>OU</span>-->
<!--      </div>-->

<!--      &lt;!&ndash; Calling GoogleAuth Api from AuthService &ndash;&gt;-->
<!--      <button mat-flat-button type="button" (click)="authService.googleAuth()">-->
<!--        Log in with Google-->
<!--      </button>-->

<!--      <span routerLink="/forgot-password">Forgot Password?</span>-->
<!--    </div>-->

<!--    <div>-->
<!--      <span>Don't have an account?<span routerLink="/register-user"> Sign Up</span></span>-->
<!--    </div>-->

<!--  </div>-->
<!--</div>-->
