import {Component, OnInit} from '@angular/core';
import firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-lista-proprietario',
  templateUrl: './lista-proprietario.component.html',
  styleUrls: ['./lista-proprietario.component.css']
})
export class ListaProprietarioComponent implements OnInit {

  loading = true;
  filteredData: any[];
  displayedColumns = [
    'codigo', 'usuario_nome', 'email', 'escola_nome', 'inep', 'permissao', 'creation_date', 'actions'
  ];

  constructor(private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.loading = true;
    firebase.functions().httpsCallable('usuario_escola')().then(value1 => {
      this.filteredData = value1.data;
      this.loading = false;
    });
  }

  setPermission(row: any, permissao: string): void {
    firebase.functions().httpsCallable('usuario_escola_update')({codigo: row.codigo, permissao}).then(value1 => {
      // this.filteredData = value1.data;
      // (window as any)._prop = value1.data;

      row.permissao = value1.data.permissao;

      // this.filteredData.pipe(map((value) => {
      //   for (const item of value) {
      //     if (item.codigo === value1.data.codigo) {
      //       item.permissao = value1.data.permissao;
      //     }
      //   }
      // }));
      this.snackBar.open(value1.data.message, 'Ok', {duration: 2000});
    });
  }

  removePermission(row: any): void {

    firebase.functions().httpsCallable('usuario_escola_remove')({codigo: row.codigo}).then(value1 => {
      this.snackBar.open(value1.data.message, 'Ok', {duration: 2000});
      if (value1.data?.codigo) {
        // console.log('value1.data?.codigo: ' + value1.data?.codigo);
        // this.filteredData.pipe(map(value => {
        //   console.log('here');
        //   const c = value.indexOf(row);
        //   console.log('c: ' + c);
        //   value.splice(c, 1);
        //   console.log('value: ' + JSON.stringify(value));
        //   this.filteredData = of(value);
        // })).subscribe();
        console.log('filteredData: ' + JSON.stringify(this.filteredData));

        const index = this.filteredData.indexOf(row);
        this.filteredData.splice(index, 1);
        this.filteredData = [...this.filteredData];

        // console.log(JSON.stringify(this.filteredData));
        // console.log(JSON.stringify(this.filteredData.splice(index, 1)));
        // const data = this.filteredData;
        // this.filteredData = null;
        // console.log(JSON.stringify(this.filteredData));
        // this.filteredData = data;
      }
    });
  }
}

// export interface UsuarioEscola {
//   codigo: number;
//   cod_escola: number;
//   usuario_nome?: string;
//   escola_nome?: string;
//   uid: string;
//   permissao: string;
//   creation_date: any;
// }
