<div style="position: relative; top: 100px;">
  <div class="content">
    <ng-template [ngIf]="this.escolaList">
      <div class="container" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
        <!-- VER TODAS -->
        <div class="w-100" fxFlex fxLayout="row">
          <h2 class="w-100">{{this.escolaList.nome_lista}}</h2>
          <button mat-raised-button
                  class="custom-chip truncate"
                  (click)="map()"
          >
            <mat-icon>map</mat-icon>
            Ver no mapa
          </button>
        </div>
        <p></p>
        <app-escola-list-vertical fxFlexFill
                                  [showPaginator]="false"
                                  [pageSize]="0"
                                  [escolaList]="this.list"
                                  [escolaCount]="this.escolaCount">
        </app-escola-list-vertical>
      </div>
    </ng-template>
    <mat-progress-bar class="w-100" mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
  </div>
  <div style="height: 100px"></div>
</div>
