import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Escola} from '../../service/Escola';
import {FormControl} from '@angular/forms';
import {EscolaService} from '../../service/escola.service';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {AnalyticsService} from '../../service/analytics.service';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import firebase from 'firebase';
import {Diretorio} from '../../service/Diretorio';
// import Swiper core and required modules
import SwiperCore, {Lazy, Navigation, Pagination, SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {SwiperEvents} from 'swiper/types';
import analytics = firebase.analytics;

SwiperCore.use([Pagination, Lazy, Navigation]);

@Component({
  selector: 'app-diretorio',
  templateUrl: './diretorio.component.html',
  styleUrls: ['./diretorio.component.scss']
})
export class DiretorioComponent implements OnInit {

  @Input() diretorio: Diretorio;
  @Input() readonly = false;
  @ViewChild('swiperComponent', {static: false}) swiperComponent: SwiperComponent;
  escolaFiltered: Escola[] | string[] = [];
  width: number;
  saving = false;
  loadingEscola = false;
  loadingEscolaFilter = false;
  selectedEscola: Escola = null;
  escolaFormControl = new FormControl();
  loadingMessage: string = null;
  config: SwiperOptions = {
    breakpoints: {
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      1280: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      },
      1: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
        pagination: false
      }
    },
    autoHeight: true,
    lazy: true,
    navigation: false,
    slidesPerView: 5,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }
  };

  constructor(private escolaService: EscolaService,
              private route: Router,
              public userService: UserService,
              private analytics: AnalyticsService) {
    // userService.permissionEvent.subscribe(value => {
    //   this.canEdit = (userService.master === true);
    //   // userService.master && !this.editing && !this.readonly
    // });
  }

  ngOnInit(): void {
    this.escolaFormControl.valueChanges.pipe(
      tap((value) => {
        this.escolaService.nome.setValue(value);
      }),
      debounceTime(1500),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (typeof value === 'string') {
          this.selectedEscola = null;
          if (value.length < 3) {
            this.loadingMessage = `Digite ao menos 3 letras para pesquisar.`;
          } else {
            this.loadingEscolaFilter = true;
            const criteria = {
              nome: value
            };
            this.loadingMessage = `Procurando escolas com nome '${value}'`;
            firebase.functions().httpsCallable('nome_escola')(criteria).then(value1 => {
              if (value1.data?.length > 0) {
                this.escolaFiltered = value1.data;
              } else {
                this.escolaFiltered = [];
              }
              this.loadingEscolaFilter = false;
            });
          }
        } else {
          this.loadingEscolaFilter = false;
          this.selectedEscola = value;
          // Traga somente a escola marcada
          this.escolaFiltered = [value];
        }
        return [];
      })
    ).subscribe();

    // If escola array is diferente from [] then make a refresh
    if (this.diretorio.escola == null) {
      this.loadEscola();
    }
  }

  private async refresh(codDiretorio: number = null): Promise<any> {
    let codigo = codDiretorio;
    if (codigo == null) {
      codigo = this.diretorio?.codigo;
    }
    return firebase.functions().httpsCallable('diretorio')({codigo})
      .then(value => {
        this.diretorio = value.data[0];

        if (this.diretorio.escola == null) {
          this.loadEscola();
        }
      });
  }

  private async loadEscola(): Promise<any> {
    this.loadingEscola = true;
    return firebase.functions().httpsCallable('diretorio_escola')({cod_diretorio: this.diretorio.codigo})
      .then(value => {
        this.loadingEscola = false;
        this.diretorio.escola = value.data; // It could be []
      });
  }

  addEscola(escola: Escola): void {
    this.diretorio.escola.push(escola);
    this.swiperComponent.swiperRef.slideTo(this.diretorio.escola.length);
  }

  displayEscola(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  removeEscola(escola: Escola): void {
    this.diretorio.escola = this.diretorio.escola.filter(value => value.codigo !== escola.codigo);
  }

  map(): void {
    this.escolaService.listas.setValue([this.diretorio.nome]);
    this.escolaService.filterOption.setValue('listaMap');
    this.route.navigate(['mapa']);
  }

  shortNivelEnsino(nivel: string): string {
    switch (nivel) {
      case 'Fundamental I':
        return 'Fund I';
      case 'Fundamental II':
        return 'Fund II';
      default:
        return nivel;
    }
  }

  previousClick(): void {
    this.swiperComponent.swiperRef.slidePrev();
  }

  nextClick(): void {
    this.swiperComponent.swiperRef.slideNext();
  }

  clickOEBI() {
    this.analytics.linkOEBI();
    window.open('https://www.oebi.com.br/quero-ser-um-associado?utm_source=schooladvisor', '_blank');
  }
}
