<div class="example-container mat-elevation-z8">
    <!--    <h3>{{nickname}}-->
    <!--        <button mat-flat-button (click)="logout()">-->
    <!--            <mat-icon>logout</mat-icon>-->
    <!--        </button>-->
    <!--    </h3>-->
    <!--    <h2>Conversas</h2>-->
    <div class="w-100" *ngIf="isLoadingResults; else divChats">
        <mat-progress-spinner class="start-50" *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-spinner>
    </div>
    <!--    <div class="button-row">-->
    <!--        <button mat-flat-button color="primary" [routerLink]="['/addroom']">-->
    <!--            <mat-icon>add</mat-icon>-->
    <!--        </button>-->
    <!--    </div>-->
    <ng-template #divChats>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="rooms" class="example-table">
                <!--                   matSort matSortActive="room_title" matSortDisableClear matSortDirection="asc">-->

                <!-- Room Name Column -->
                <ng-container matColumnDef="room_title">
                    <th mat-header-cell *matHeaderCellDef>Conversas</th>
                    <td class="w-100 d-flex" mat-cell *matCellDef="let element">
                        <span class="w-100">
                            {{element.room_title}}
                        </span>
                        <button class="end-0" mat-stroked-button
                                cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                                (click)="this.toggleChat(element.roomname, element.cod_escola)">
                            {{this.roomname!=null && (this.roomname == element.roomname)? 'Fechar' : 'Abrir'}}
                            <mat-icon>chat</mat-icon>
                        </button>

                        <ng-template cdkConnectedOverlay
                                     [cdkConnectedOverlayWidth]="'50vw'"
                                     [cdkConnectedOverlayHeight]="'50vh'"
                                     [cdkConnectedOverlayOrigin]="trigger"
                                     [cdkConnectedOverlayOpen]="toggleChat"
                        >

                            <app-chatroom *ngIf="this.roomname != null"
                                          [roomname]="this.roomname" [escola]="this.cod_escola"
                                          class="w-100 h-100"></app-chatroom>
                        </ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
        </div>
    </ng-template>


    <!--    <a href="javascript:" (click)="showChat = !showChat" cdkOverlayOrigin #trigger="cdkOverlayOrigin">Conversar com escola</a>-->

</div>
