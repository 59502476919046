<div class="main-calc">
  <div class="main-section w-100" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
    <section fxFlex="80" fxFlex.gt-md="50" fxFlex.lt-sm="90" [@Fading]="!this.showCalc">
      <h1 class="w-100 text-center title-calc">Calculadora de Gastos Escolares</h1>
      <h3 class="w-75 text-center">Calcule o valor aproximado que irá investir na educação do seu filho até o final do Ensino Médio</h3>
      <div class="m-auto w-75" fxLayout="column">

        <div class="calc-item" #nascimentoItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.nascimentoBounce">Data de nascimento do filho</h2>
          </div>
          <mat-form-field class="w-100" floatLabel="never" appearance="outline">
            <input matInput [matDatepicker]="dp" [formControl]="dataNascimentoForm" required
                   placeholder="MM/YYYY" mask="00/0000">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div class="calc-item" #serieItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.serieBounce">Qual série ele está?</h2>
          </div>
          <form [formGroup]="serieForm" class="w-100">
            <mat-form-field class="w-100" floatLabel="never" appearance="outline">
              <input type="text" #serieInput
                     (click)="serieInput.select()"
                     matInput formControlName="serieGroup"
                     placeholder="Berçário, Infantil, Fundamental ..."
                     #trigger="matAutocompleteTrigger"
                     [matAutocomplete]="serieAutoComplete" required>
              <button *ngIf="serieInput.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="$event.stopPropagation(); serieInput.value = ''; serieForm.get('serieGroup').setValue(''); trigger.openPanel()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #serieAutoComplete="matAutocomplete"
                                [displayWith]="displayNivelOptionItem"
                                (optionSelected)="selectNivel($event.option.value)">
                <!--                <div *ngFor="let nivel of nivelEnsinoOptions | async">-->
                <!--                  <mat-option *ngFor="let serie of nivel.series" [value]="serie">-->
                <!--                    {{nivel.nivel + ': ' + serie + 'º ano'}}-->
                <!--                  </mat-option>-->
                <!--                </div>-->
                <mat-option *ngFor="let item of nivelEnsinoOptions | async" [value]="item">
                  {{item.nome}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>

        </div>

        <div class="calc-item" #mensalidadeItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.mensalidadeBounce">Qual o valor da mensalidade?</h2>
          </div>
          <div>
            <div class="w-100 text-center">
              <mat-form-field class="w-100" floatLabel="never" appearance="outline">
                <label></label>
                <input type="text" #mensalidadeInput
                       maxLength="10" matInput placeholder="R$ 0.000,00" required
                       (click)="mensalidadeInput.select()"
                       [ngModel]="valor_mensalidade_input | number:'1.0-2':'pt-BR'"
                       (ngModelChange)="valor_mensalidade_input =  this.decimalParser($event)">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="calc-item" #materialItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.materialBounce">Sobre o material escolar</h2>
          </div>
          <div class="w-100" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px">
            <button mat-raised-button fxFlex (click)="materialIncluso = true" [color]="materialIncluso? 'accent' : ''">Está incluso na mensalidade</button>
            <button mat-raised-button fxFlex (click)="materialIncluso = false" [color]="materialIncluso == false? 'accent' : ''">É pago à parte</button>
          </div>
        </div>

        <div class="calc-item" #transporteItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.transporteBounce">Sobre o transporte escolar</h2>
          </div>
          <div class="w-100" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px">
            <button mat-raised-button fxFlex (click)="transporteIncluso = true" [color]="transporteIncluso? 'accent' : ''">Não utilizo transporte escolar</button>
            <button mat-raised-button fxFlex (click)="transporteIncluso = false" [color]="transporteIncluso == false? 'accent' : ''">Utilizo transporte escolar</button>
          </div>
        </div>

        <div class="calc-item" #idiomaItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.idiomaBounce">Sobre cursos de idiomas</h2>
          </div>
          <div class="w-100" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px">
            <button mat-raised-button fxFlex (click)="idiomaIncluso = 'incluso mensalidade'" [color]="idiomaIncluso === 'incluso mensalidade'? 'accent' : ''">Já está incluso na mensalidade</button>
            <button mat-raised-button fxFlex (click)="idiomaIncluso = 'não incluso'" [color]="idiomaIncluso === 'não incluso'? 'accent' : ''">Não quero adicionar curso de idioma</button>
            <button mat-raised-button fxFlex (click)="idiomaIncluso = 'adicionar'" [color]="idiomaIncluso === 'adicionar'? 'accent' : ''">Quero adicionar curso de idioma</button>
          </div>
        </div>

        <div class="calc-item" #extraItem>
          <div class="w-100 text-center">
            <h2 [@bounce]="this.extraBounce">Sobre cursos extracurriculares</h2>
          </div>
          <div class="w-100" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px">
            <button mat-raised-button fxFlex (click)="extraIncluso = 'incluso mensalidade'" [color]="extraIncluso === 'incluso mensalidade' ? 'accent' : ''">Já está incluso na mensalidade</button>
            <button mat-raised-button fxFlex (click)="extraIncluso = 'não incluso'" [color]="extraIncluso === 'não incluso'? 'accent' : ''">Não quero adicionar curso extracurricular</button>
            <button mat-raised-button fxFlex (click)="extraIncluso = 'adicionar'" [color]="extraIncluso === 'adicionar'? 'accent' : ''">Quero adicionar curso extracurricular</button>
          </div>
        </div>

        <button mat-raised-button fxFlex class="w-100 calc-buttom" color="accent" (click)="calc()">Calcular gastos com educação</button>
      </div>
    </section>

    <section fxFlex="80" fxFlex.gt-md="50" fxFlex.lt-sm="90"
      *ngIf="showCalc" [@Fading]="this.showCalc">
      <div class="m-auto w-75" fxLayout="column">
        <!--        <h3 class="w-100 text-center">-->
        <!--          Você já investiu-->
        <!--          <span class="calc-destac">R$ {{valor_total_passado| number:'1.2-2':'pt-BR'}}</span>-->
        <!--          até-->
        <!--          <span class="calc-destac">{{ano_passado}}</span>-->
        <!--          incluindo mensalidade, material, transporte, curso de idioma, uniforme e curso extracurricular-->
        <!--        </h3>-->
        <h1 class="w-100 text-center">
          A estimativa é que você invista
          <span class="calc-destac">R$ {{valor_total_futuro| number:'1.2-2':'pt-BR'}}</span>
          de
          <span class="calc-destac">{{este_ano}}</span> até o final do Ensino Médio (em <span class="calc-destac">{{ano_formatura}}</span>) distribuídos da seguinte forma:
          <br>
        </h1>
        <div class="separator w-100">

        </div>
        <div class="w-100 m-auto">
          <div class="row row-eq-height">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center">
                <mat-icon fontSet="fas" fontIcon="fa-money-check-alt"></mat-icon>
                <h2>Mensalidade</h2>
                <span class="value_data">R$ {{hash_item_futuro.Mensalidade| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ {{valor_mensalidade_input| number:'1.2-2':'pt-BR'}}/mês</span>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center ">
                <mat-icon fontSet="fas" fontIcon="fa-book"></mat-icon>
                <h2>Material</h2>
                <span class="value_data">R$ {{hash_item_futuro.Material| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ 350,00 a 850,00/semestre </span>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center ">
                <mat-icon fontSet="fas" fontIcon="fa-bus"></mat-icon>
                <h2>Transporte</h2>
                <span class="value_data">R$ {{hash_item_futuro.Transporte| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ 450,00/mês</span>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center ">
                <mat-icon fontSet="fas" fontIcon="fa-globe-europe"></mat-icon>
                <h2>Idioma</h2>
                <span class="value_data">R$ {{hash_item_futuro.Idioma| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ 400,00/mês</span>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center ">
                <!--              <mat-icon fontSet="fa" fontIcon="tshirt">tshirt</mat-icon>-->
                <!--              <mat-icon fontSet="fas" fontIcon="tshirt">tshirt</mat-icon>-->
                <!--              <i class="fas fa-tshirt"></i>-->
                <!--              <fa-icon icon="faTshirt"></fa-icon>-->
                <mat-icon fontSet="fas" fontIcon="fa-tshirt"></mat-icon>
                <h2>Uniforme</h2>
                <span class="value_data">R$ {{hash_item_futuro.Uniforme| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ 500,00/ano </span>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="result-box w-100 d-flex flex-column align-items-center justify-content-center ">
                <mat-icon fontSet="fas" fontIcon="fa-user-graduate"></mat-icon>
                <h2>Cursos</h2>
                <span class="value_data">R$ {{hash_item_futuro.Cursos| number:'1.2-2':'pt-BR'}}</span>
                <br>
                <span class="value_note">Referência: R$ 150,00/mês </span>
              </div>
            </div>
          </div>
        </div>
        <a fxFlex class="w-75 m-auto text-end" style="padding-right: 15px; cursor: pointer;" (click)="this.recalc()">Calcular de Novo</a>
        <!--        <h3 class="w-100 text-center">-->
        <!--          Quanto custa <strong>toda</strong> a educação do seu filho?-->
        <!--        </h3>-->
        <mat-grid-list cols="1" rowHeight="2:1" *ngIf="false">
          <mat-grid-tile>
            <ngx-charts-pie-chart
              [scheme]="colorScheme"
              [results]="graph_item_data"
              [gradient]="gradient"
              [legend]="showLegend"
              [legendPosition]="legendPosition"
              [trimLabels]="false"
              [labels]="showLabels"
              [doughnut]="isDoughnut"
            >
              <ng-template #tooltipTemplate let-model="model">
                <div class="chart-hint">
                  <p>
                    <span class="chart-hint">{{model.name}}</span>
                  </p>
                  <p>
                    <span class="chart-hint">R$ {{model.value| number:'1.2-2':'pt-BR'}}</span>
                  </p>
                </div>
              </ng-template>
            </ngx-charts-pie-chart>
          </mat-grid-tile>
          <!--          <mat-grid-tile>-->
          <!--            <ngx-charts-pie-chart-->
          <!--              [scheme]="colorScheme"-->
          <!--              [results]="graph_nivel_data"-->
          <!--              [gradient]="gradient"-->
          <!--              [legend]="showLegend"-->
          <!--              [legendPosition]="legendPosition"-->
          <!--              [labels]="showLabels"-->
          <!--              [doughnut]="isDoughnut"-->
          <!--            >-->
          <!--              <ng-template #tooltipTemplate let-model="model">-->
          <!--                <div class="chart-hint">-->
          <!--                  <p>-->
          <!--                    <span class="chart-hint">{{model.name}}</span>-->
          <!--                  </p>-->
          <!--                  <p>-->
          <!--                    <span class="chart-hint">R$ {{model.value| number:'1.2-2':'pt-BR'}}</span>-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </ng-template>-->
          <!--            </ngx-charts-pie-chart>-->
          <!--          </mat-grid-tile>-->
        </mat-grid-list>
<!--        <div class="w-100 d-flex flex-column align-items-center justify-content-center">-->
<!--          <a class="action" [routerLink]="['/']">-->
<!--            Agora que já sabe qual será seu investimento total em educação, encontre uma escola-->
<!--          </a>-->
<!--        </div>-->
        <div class="w-75 div-end" fxFlex fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="5px">
          <button mat-raised-button class="find-buttom" fxFlex color="accent"
                  (click)="this.goMap()">
            Encontre uma Escola
          </button>
<!--          <button mat-raised-button fxFlex color="accent"-->
<!--                  (click)="this.recalc()">Calcular de Novo-->
<!--          </button>-->
        </div>
        <div>
          <ul class="reference_note">
            <li>Os valores de referência podem variar conforme nível de ensino.O cálculo de transporte considera uso 5x por semana e distância escola ↔︎ casa de até 10km.O cálculo de Idiomas considera somente 1 curso.O cálculo de Cursos Extracurriculares considera somente 1 curso.O cálculo utiliza alguns valores médios reportados por outras famílias.É considerada correção média de 8% a.a.O corte etário segue as diretrizes do MEC (junho para ingressantes até 2018 e março para ingressantes a partir de 2019)</li>
          </ul>
        </div>
      </div>
    </section>

    <div class="cellep-banner w-100 container align-items-center justify-content-center" style="padding-bottom: 30px;">
      <a  target="_blank" href="https://www.mozper.com/br/?utm_source=schooladvisor" (click)="analytics.clickMozperBannerCalculadora()">
        <img class="w-100" src="/images/mozper/banners-junho-school-advisor-2022-Calculadora-de-Gastos.png">
      </a>
    </div>
  </div>
</div>
