import { Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';

@Component({
  selector: 'app-escola-idiomas',
  templateUrl: './escola-idiomas.component.html',
  styleUrls: ['./escola-idiomas.component.css'],
})
export class EscolaIdiomasComponent implements OnInit {
  @Input() escola: Escola;
  isExpanded = false;

  constructor() {}

  get hasSobre(): boolean {
    return this.escola.idioma_sobre && this.escola.idioma_sobre.trim() !== '';
  }

  get hasSelos(): boolean {
    return this.escola.oebi;
  }

  get hasIdiomas(): boolean {
    const idiomas = this.todosIdiomas();

    return idiomas.length > 0;
  }

  get hasInternacional(): boolean {
    return this.escola?.modalidade_ensino && this.escola.modalidade_ensino.trim() !== '';
  }

  get hasItensOferecidos(): boolean {
    return this.escola.disciplinas_adicionais_duplo_diploma
      || this.escola.intercambio
      || this.escola.competicoes_internacionais
      || this.escola.certificao_outro_idioma
      || this.escola.disciplinas_adicionais_convenio_com_escolas_no_exterior;
  }

  get hasData(): boolean {
    return this.hasSobre
      || this.hasSelos
      || this.hasIdiomas
      || this.hasInternacional
      || this.hasItensOferecidos;
  }

  ngOnInit(): void {}

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  todosIdiomas(): string[] {
    let idiomas = this.escola.idioma_principal;

    if (!Array.isArray(idiomas)) {
      idiomas = [];
    }

    if (Array.isArray(this.escola.outros_idiomas)) {
      idiomas.push(...this.escola.outros_idiomas);
    }
    
    idiomas = idiomas.filter((idioma, index) => idiomas.indexOf(idioma) === index);

    return idiomas;
  }

  get escolaInternacionalLabel(): string {
    const modalidade = this.escola?.modalidade_ensino;

    switch (modalidade) {
      case 'Não bilíngue':
        return 'Esta escola não é bilíngue';

      case 'Bilíngue':
        return 'Esta escola é bilíngue';

      case 'Programa bilíngue opcional':
        return 'Esta escola oferece programa bilíngue opcional';

      case 'Internacional':
        return 'Esta escola é internacional';

      case 'Programa internacional opcional':
        return 'Esta escola oferece programa internacional opcional.';
        
      default:
        return '';
    }
  }

}
