import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EscolaService} from '../../service/escola.service';
import {Escola} from '../../service/Escola';
import {AnalyticsService} from '../../service/analytics.service';

@Component({
  selector: 'app-escola-card',
  templateUrl: './escola-card.component.html',
  styleUrls: ['./escola-card.component.scss']
})
export class EscolaCardComponent implements OnInit {

  @Input() imageSrc: string;
  @Input() escola: Escola;
  logo;
  escolaService = EscolaService;

  constructor(private sanitizer: DomSanitizer, public analytics: AnalyticsService) {
  }

  ngOnInit(): void {
    if (this.escola.logo_escola) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png;base64, ${this.escola.logo_escola}`);
    } else {
      this.logo = './images/icone-site-150x150.png';
    }
  }

}
