<div
        id="g_id_onload"
        data-prompt_parent_id="g_id_onload"
        data-client_id="680287849093-p2qq9tk23aluf9d6r1e86sno9rfafc96.apps.googleusercontent.com"
        data-cancel_on_tap_outside="false"
        data-callback="handleCredentialResponse"
        data-context="use"
        data-auto_prompt="false"
        style="position: fixed; top: 80px; right: 20px; z-index: 9000">
</div>
