<!--  wrapper  -->
<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <article id="post-531" class="post-531 page type-page status-publish hentry">
      <div class="entry-content clearfix">
        <div data-elementor-type="wp-post" data-elementor-id="531" class="elementor elementor-531" data-elementor-settings="[]">
          <div class="elementor-inner">
            <div class="elementor-section-wrap">
              <section
                class="elementor-element elementor-element-eff2c99 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="eff2c99" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-250f548e elementor-column elementor-col-50 elementor-top-column" data-id="250f548e"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-c3e8623 elementor-widget elementor-widget-heading" data-id="c3e8623" data-element_type="widget"
                               data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                              <h2 class="elementor-heading-title elementor-size-default">Uma nova forma de escolher uma escola.</h2></div>
                          </div>
                          <div class="elementor-element elementor-element-e558dac elementor-widget elementor-widget-text-editor" data-id="e558dac"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h5><span style="color: #ffffff; font-size: 20px;">Um canal completo sobre as escolas particulares do Brasil para ajudar as famílias a encontrarem a melhor opção através de informações, recomendações, conexões e serviços.</span>
                              </h5>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-c1e25b0 elementor-column elementor-col-50 elementor-top-column" data-id="c1e25b0"
                         data-element_type="column">
                      <div class="elementor-column-wrap">
                        <div class="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-element elementor-element-fa12020 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="fa12020" data-element_type="section" id="sec2">
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-560596f elementor-column elementor-col-100 elementor-top-column" data-id="560596f"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-eec6523 elementor-widget elementor-widget-image" data-id="eec6523" data-element_type="widget"
                               data-widget_type="image.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-image">
                                <img width="150" height="150" class="attachment-thumbnail size-thumbnail lazy" alt="" loading="lazy"
                                     src="./images/icone-site-150x150.png"
                                     data-src="./images/icone-site-150x150.png"
                                     data-lazy="./images/icone-site-150x150.png"/></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-8ab3068 elementor-widget elementor-widget-text-editor" data-id="8ab3068"
                               data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h3 style="text-align: center;"><span
                                style="font-size: 20px;">O propósito da <strong>SchoolAdvisor</strong> é fornecer informações completas, organizadas e confiáveis sobre as escolas particulares brasileiras, promovendo conexões mais verdadeiras e duradouras entre as famílias e as instituições de ensino.</span>
                              </h3></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-element elementor-element-62e8838 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="62e8838" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-ed96de7 elementor-column elementor-col-100 elementor-top-column" data-id="ed96de7"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-19d20d3 elementor-widget elementor-widget-members_grid" data-id="19d20d3"
                               data-element_type="widget" data-widget_type="members_grid.default">
                            <div class="elementor-widget-container">
                              <div class="items-grid-holder section-team medium-pad three-cols">
                                <!-- team-item -->
                                <div id="member-988" class="items-grid-item team-box post-988 member type-member status-publish has-post-thumbnail hentry">
                                  <div class="team-photo">
                                    <img width="530" height="530" class="respimg wp-post-image lazy" alt="" loading="lazy" src="./images/quem/quem_tavolaro.png"
                                         data-src="./images/quem/quem_tavolaro.png" data-lazy="./images/quem/quem_tavolaro.png"/></div>
                                  <div class="team-info">
                                    <h3 class="entry-title">Fernanda Tavolaro</h3>
                                    <p>Tudo começou na faculdade de Letras da USP e um estágio em uma agência de Search. Desde então, passou por grandes
                                      agências de publicidade e veículos de comunicação digital. Hoje, CMO da SchoolAdvisor Brasil.</p>
                                    <p>Mas o tempo mais bem aproveitado mesmo é quando está se divertindo com seus filhos Maria e Joaquim.</p>
                                    <p>O que ela não consegue fazer de jeito nenhum?? Sair de casa sem rímel.</p>
                                    <ul class="team-social">
                                      <li><a title="Be Friend on Linkedin" href="https://www.linkedin.com/in/fernandatavolaro/" target="_blank"><i
                                        class="fa fa-linkedin"></i></a></li>

                                    </ul>
                                  </div>
                                </div>
                                <!-- team-item end  -->

                                <!-- team-item -->
                                <div id="member-581" class="items-grid-item team-box post-581 member type-member status-publish has-post-thumbnail hentry">
                                  <div class="team-photo">
                                    <img width="530" height="530" class="respimg wp-post-image lazy" alt="" loading="lazy" src="./images/quem/quem_massaini.png"
                                         data-src="./images/quem/quem_massaini.png" data-lazy="./images/quem/quem_massaini.png"/></div>
                                  <div class="team-info">
                                    <h3 class="entry-title">Viviane Massaini</h3>
                                    <p>Além de ser a idealizadora e CEO da SchoolAdvisor Brasil, é empreendedora há 9 anos.</p>
                                    <p>Relações Públicas por formação, já trabalhou em agências de publicidade, teve sua própria agência e hoje está a frente
                                      também de uma empresa de fotografia escolar.</p>
                                    <p>Mãe do Pedro e do Arthur, ela também arrasa com o cabelão sempre impecável.</p>
                                    <ul class="team-social">
                                      <li><a title="Be Friend on Linkedin" href="http://www.linkedin.com/in/vivianemassaini" target="_blank"><i
                                        class="fa fa-linkedin"></i></a></li>

                                    </ul>
                                  </div>
                                </div>
                                <!-- team-item end  -->

                                <!-- team-item -->
                                <div id="member-580" class="items-grid-item team-box post-580 member type-member status-publish has-post-thumbnail hentry">
                                  <div class="team-photo">
                                    <img width="530" height="530" class="respimg wp-post-image lazy" alt="" loading="lazy" src="./images/quem/quem_etlinger.png"
                                         data-src="./images/quem/quem_etlinger.png" data-lazy="./images/quem/quem_etlinger.png"/></div>
                                  <div class="team-info">
                                    <h3 class="entry-title">Fernanda Etlinger</h3>
                                    <p>Falou em excel, planilhas, gráficos e resultados? Chama a Publicitária, Matemática e CTO da SchoolAdvisor, a Fernanda
                                      Etlinger.</p>
                                    <p>Esteve a frente do marketing de grandes marcas e já presenteou o Silvio Santos com uma televisão!<br/>
                                      Fala com fluidez a linguagem da programação e dos números.</p>
                                    <p>É mãe da Leila do Lucas e nas horas vagas ataca de tatuadora.</p>
                                    <ul class="team-social">
                                      <li><a title="Be Friend on Linkedin" href="http://www.linkedin.com/in/fernandaetlinger" target="_blank"><i
                                        class="fa fa-linkedin"></i></a></li>

                                    </ul>
                                  </div>
                                </div>
                                <!-- team-item end  -->


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-element elementor-element-8f7efdc elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="8f7efdc" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-4d57b55 elementor-column elementor-col-100 elementor-top-column" data-id="4d57b55"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-06a393b elementor-widget elementor-widget-heading" data-id="06a393b" data-element_type="widget"
                               data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                              <h2 class="elementor-heading-title elementor-size-default">Quem acredita:<br><br></h2></div>
                          </div>
                          <div class="elementor-element elementor-element-a23cdc2 elementor-widget elementor-widget-image" data-id="a23cdc2" data-element_type="widget"
                               data-widget_type="image.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-image">
                                <img width="647" height="90" class="attachment-large size-large lazy" alt="Apoiadores" loading="lazy"
                                     src="./images/quem/quem_apoia.png"
                                     data-src="./images/quem/quem_apoia.png" data-lazy="./images/quem/quem_apoia.png"/></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-element elementor-element-2056319 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="2056319" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-3cf27b7 elementor-column elementor-col-50 elementor-top-column" data-id="3cf27b7"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-e4d1624 elementor-widget elementor-widget-spacer" data-id="e4d1624" data-element_type="widget"
                               data-widget_type="spacer.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-spacer">
                                <div class="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-20e6751 elementor-widget elementor-widget-image" data-id="20e6751" data-element_type="widget"
                               data-widget_type="image.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-image">
                                <img width="806" height="700" class="attachment-large size-large lazy" alt="" loading="lazy" src="./images/quem/quem.jpg"
                                     data-src="./images/quem/quem.jpg"
                                     data-lazy="./images/quem/quem.jpg"/></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-b5403d2 elementor-widget elementor-widget-text-editor" data-id="b5403d2"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h6>Fundadoras da SchoolAdvisor Brasil</h6></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-056171b elementor-column elementor-col-50 elementor-top-column" data-id="056171b"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-8cb4711 elementor-widget elementor-widget-spacer" data-id="8cb4711" data-element_type="widget"
                               data-widget_type="spacer.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-spacer">
                                <div class="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-6bdec1f elementor-widget elementor-widget-section_title" data-id="6bdec1f"
                               data-element_type="widget" data-widget_type="section_title.default">
                            <div class="elementor-widget-container">
                              <div class="section-title">
                                <h2>Nossa <span>História</span></h2>                                    <span class="section-separator"></span>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-136e4e6 elementor-widget elementor-widget-text-editor" data-id="136e4e6"
                               data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix"><h3><span style="font-size: 16px;">As fundadoras da <strong>SchoolAdvisor</strong>, são três mulheres e mães com mais de 15 anos de experiência no mercado corporativo.<br/>Ao começarem o processo de busca de escolas para os filhos perceberam não haver um canal completo e confiável para otimizar o tempo de busca pelas informações.</span>
                              </h3>
                                <h3><span style="font-size: 16px;">Decidiram criar uma comunidade nas redes sociais para troca entre outras famílias e, a partir de então, iniciaram a cocriação da mais completa plataforma sobre escolas particulares do Brasil.</span>
                                </h3></div>
                            </div>
                          </div>
                          <div class="elementor-element elementor-element-0f1d213 elementor-widget elementor-widget-spacer" data-id="0f1d213" data-element_type="widget"
                               data-widget_type="spacer.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-spacer">
                                <div class="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-element elementor-element-0ff136f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                data-id="0ff136f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                    <div class="elementor-element elementor-element-0a5b7af elementor-column elementor-col-50 elementor-top-column" data-id="0a5b7af"
                         data-element_type="column">
                      <div class="elementor-column-wrap  elementor-element-populated">
                        <div class="elementor-widget-wrap">
                          <div class="elementor-element elementor-element-0567e40 elementor-widget elementor-widget-parallax_content" data-id="0567e40"
                               data-element_type="widget" data-widget_type="parallax_content.default">
                            <div class="elementor-widget-container">
                              <div class="parallax-content">
                                <div class="intro-item fl-wrap">
                                  <h2>Quer saber mais?</h2>
                                  <a class="trs-btn" href="/contato/">Fale com a SchoolAdvisor</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-7fb628b elementor-column elementor-col-50 elementor-top-column" data-id="7fb628b"
                         data-element_type="column">
                      <div class="elementor-column-wrap">
                        <div class="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div><!-- .entry-content -->
    </article><!-- #post-## -->
  </div>
  <!-- Content end -->
</div>
