<!--<form [formGroup]="this.escolaServiceInstance.filterFormGroup">-->
<mat-list>
  <!--    <mat-list-item *ngFor="let item of this.escolaServiceReference.getGrupo('rate', false)">-->
  <!--      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">-->
  <!--        {{escolaServiceReference.getColumnTitle(item)}}-->
  <!--        <rating [readonly]="false" max="5" rate="0" name="{{item}}"></rating>-->
  <!--      </div>-->
  <!--    </mat-list-item>-->
  <mat-list-item>
    <h2>Avaliação</h2>
  </mat-list-item>
  <mat-list-item>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      {{escolaServiceReference.getColumnTitle('rate_geral')}}
      <div style="width: 30px;"></div>
      <rating [readonly]="false" max="5" rate="0" [(ngModel)]="rateGeral"></rating>
    </div>
  </mat-list-item>
</mat-list>
<div fxLayout="row" class="buttonRow" fxLayoutAlign="space-between center" fxLayoutGap="5px">
  <button mat-flat-button color="primary" (click)="reset()">Limpar</button>
  <button mat-button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Filtrar</button>
</div>
<!--</form>-->
