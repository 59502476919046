export interface CellepUnidade {
    codigo?: number;
    cod_parceiro?: number;
    cidade?: string;
    nome?: string;
    endereco?: string;
    horario?: string;
    horario_fds?: string;
    fone?: string[];
    whatsapp?: string[];
    cursos?: string;
    turmas?: string;
    latitude?: number;
    longitude?: number;
    marker?: any;
}

export const cod_parceiro = 10;

/*
export const CellepUnidadeList: Array<CellepUnidade> = [
    {
        cidade: 'Barueri - SP', nome: 'Alphaville', endereco: 'Al. Rio Negro, 967 – Ed Alpha Premium – Pavimento Inferior',
        horario: 'das 7h às 21h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11991901378', '11991583590', '11989634818'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Anália Franco', endereco: 'Rua Prof. João de Oliveira Torres, 520 Jd. Anália Franco – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11991593270', '11992187289', '11991734915'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Brooklin', endereco: 'R. Bernardino de Campos, 202 Brooklin Velho – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11989744744', '11989767432'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'Campinas - SP', nome: 'Campinas', endereco: 'Av. Dr. Heitor Penteado, 572 Taquaral – Campinas',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['19984150040'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Lapa', endereco: 'Av. Brig. Gavião Peixoto, 73 Alto da lapa – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11991753366', '11989957485'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Moema', endereco: 'Av. Cotovia, 759 Moema – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11945107957', '11991858047'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Morumbi', endereco: 'Av. Jorge João Saad, 243-B Morumbi – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11989807995', '11983912334'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Paulista', endereco: 'Av. Paulista, 2006 – 1º andar Cerqueira César – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11989893878', '11991112157'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Perdizes', endereco: 'R. Monte Alegre, 1132 Perdizes – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11991026583', '11991285963'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Pinheiros', endereco: 'Rua Simão Álvares, 93 - Pinheiros – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11989936297'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'Piracicaba - SP', nome: 'Piracicaba', endereco: 'Rua Regente Feijó, 904 Centro – Piracicaba',
        horario: 'das 7h às 22h', horario_fds: 'das 8h às 12h',
        fone: ['1934337444'], whatsapp: ['19996020612'],
        cursos: 'Inglês', turmas: 'Pre Kids, Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Santana', endereco: 'Rua Chemin Del Pra, 47 Santana – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11989795619', '11991034575'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'Santo André - SP', nome: 'Santo André', endereco: 'Av. Portugal, 740 Jd. Bela Vista – Santo André',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11992139517', '11991684651', '11945114024'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Bernardo do Campo - SP', nome: '  São Bernardo do Campo', endereco: '  Rua Continental, 369 Jd. do mar – São Bernardo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11991415726', '11989771698'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'Sorocaba - SP', nome: 'Sorocaba', endereco: 'Rua João Wagner Wey, 860 Jardim América – Sorocaba',
        horario: 'das 7h às 22h', horario_fds: 'das 8h às 13h',
        fone: ['1532028282'], whatsapp: ['15996012017'],
        cursos: 'Inglês', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Tatuapé', endereco: 'Rua Padre Estêvão Pernet, 597 Tatuapé – São Paulo',
        horario: 'das 9h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11992090925', '11989815074'],
        cursos: 'Inglês e Programação', turmas: 'Pre Kids, Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Vila Mariana', endereco: 'Rua Pedro de Toledo, 200 Vila Mariana – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11983911713', '11991444323', '11991305605'],
        cursos: 'Inglês e Programação', turmas: 'Kids, Teens e Adultos'
    },

    {
        cidade: 'São Paulo - SP', nome: 'Vila Olímpia', endereco: 'Rua São Tomé, 86 Vila Olímpia – São Paulo',
        horario: 'das 7h às 22h', horario_fds: 'das 9h às 13h',
        fone: [], whatsapp: ['11977285933', '11945111356'],
        cursos: 'Inglês, Espanhol e Programação', turmas: 'Kids, Teens e Adultos'
    },

];
*/
