<div class="container" fxFlex fxLayout="column" *ngIf="parceiroLista">
  <div class="subtitle" fxFlex fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px">
    <h2 class="w-100" *ngIf="!this.editing else editNome">{{this.parceiroLista.nome_lista}}</h2>
    <ng-template #editNome>
      <mat-form-field class="w-100">
        <mat-label>Nome da Lista</mat-label>
        <input maxLength="30" class="nome-lista-edit" matInput maxLength="50"
               [disabled]="this.saving"
               placeholder="Nome" [(ngModel)]="this.parceiroLista.nome_lista">
      </mat-form-field>
    </ng-template>
    <button mat-mini-fab *ngIf="userService.master && !this.editing && !this.readonly" [disabled]="this.saving"
            matTooltip="Editar Lista/Diretório"
            (click)="startEdit()">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-mini-fab *ngIf="this.editing" [disabled]="this.saving"
            matTooltip="Salvar Lista/Diretório"
            (click)="saveLista()">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-mini-fab *ngIf="this.editing" [disabled]="this.saving"
            matTooltip="Cancelar alterações"
            (click)="cancelEdit()">
      <mat-icon>cancel</mat-icon>
    </button>
    <button mat-mini-fab *ngIf="this.editing" [disabled]="this.saving"
            [matMenuTriggerFor]="menuRemove"
            matTooltip="Remover Lista/Diretório">
      <mat-icon>delete</mat-icon>
    </button>
    <mat-menu #menuRemove="matMenu" class="w-100">
      <button mat-menu-item (click)="removeLista()">
        Remover a lista <span style="font-weight: bold">{{(this.parceiroLista.nome_lista?.length > 0) ? this.parceiroLista.nome_lista : ''}}</span>?
      </button>
    </mat-menu>
    <button mat-mini-fab
            *ngIf="this.editing" [disabled]="this.saving"
            [matMenuTriggerFor]="menuAdd"
            (menuOpened)="nomeParceiroInput.focus(); nomeParceiroInput.select(); nomeParceiroInput.value = null;"
            matTooltip="Adicionar Parceiro">
      <mat-icon>add</mat-icon>
    </button>
    <mat-menu #menuAdd="matMenu" class="w-100">
      <mat-form-field fxFlexFill (click)="$event.stopPropagation()" class="nome-parceiro-input">
        <mat-label class="custom-label">Parceiro</mat-label>
        <input fxFlex matInput #nomeParceiroInput
               [formControl]="this.parceiroFormControl"
               [matAutocomplete]="autoParceiroNome"
               placeholder="Nome do Parceiro"
        >
      </mat-form-field>

      <mat-autocomplete
        class="parceiro-autocomplete"
        #autoParceiroNome="matAutocomplete"
        [displayWith]="displayParceiro"
        (optionSelected)="addParceiro($event.option.value)">
        <mat-option *ngIf="isLoading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <span>{{this.loadingMessage}}</span>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let parceiro of parceiroFiltered" [value]="parceiro">
            <div *ngIf="!parceiro?.nome" fxFlex fxLayout="row" class="select-item-box" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <span fxFlex="16px" class="select-nome">{{parceiro}}</span>
            </div>
            <div *ngIf="parceiro?.nome" fxFlex fxLayout="row" class="select-item-box" fxLayoutAlign="start center">
              <div>
                <p><span class="select-nome position-absolute top-0">{{parceiro.nome}}</span></p>
              </div>
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>

    </mat-menu>
  </div>
  <div *ngIf="(this.parceiroLista.parceiro_lista == null) else parceiroFound">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-template #parceiroFound>
    <ngx-slick-carousel class="slider-responsive" #slickModal="slick-carousel" [config]="sliderConfig" style="border-bottom: 0; padding-bottom: 0;">
      <article ngxSlickItem class="card-slide" *ngFor="let parceiro of this.parceiroLista.parceiro_lista; let i=index;" fxFlex>
        <button mat-raised-button class="parceiro-button position-absolute start-50 translate-middle-x"
                matTooltip="Remover Parceiro da Lista/Diretório"
                (click)="removeParceiro(parceiro)"
                *ngIf="this.editing">
          <mat-icon>delete</mat-icon>
          Remover parceiro
        </button>
        <button mat-mini-fab class="parceiro-button position-absolute start-0 top-50 translate-middle-y"
                matTooltip="Mover o parceiro para esquerda"
                (click)="move(i, (i-1))" [disabled]="this.saving"
                *ngIf="this.editing && (i > 0)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-mini-fab class="parceiro-button position-absolute end-0 top-50 translate-middle-y"
                matTooltip="Mover o parceiro para direita"
                (click)="move(i, (i+1))" [disabled]="this.saving"
                *ngIf="this.editing && (i < this.parceiroLista.parceiro_lista.length - 1)">
          <mat-icon>arrow_forward</mat-icon>
        </button>
        <a [routerLink]="['/parceiros',parceiro.codigo,parceiro.nome | urlNormalizer]" target="_blank" title="veja mais" class="veja-mais">
          <div class="image parceiro-logo-div" style="width: 100%">
            <img class="parceiro-image"
                 src="{{(parceiro.logo_path == null) ? ' images/thumb-list.jpg' : parceiro.logo_path}}" alt="Logo Parceiro" sizes="15.5vw"
            />
          </div>
        </a>
      </article>
    </ngx-slick-carousel>
    <!-- VER TODAS -->
    <div *ngIf="(this.parceiroLista.parceiro_lista != null) && (this.parceiroLista.parceiro_lista.length > 0)"
         style="display: flex; flex-direction: row-reverse; padding-bottom: 5px; margin-bottom: 25px; border-bottom: 1px solid #dadada;">
      <div class="btn-ver-todas">
        <a>
          <span style="cursor: pointer" (click)="map()">ver todas</span>
          <!--        <button mat-button title="ver todas" (click)="map()">ver todas</button>-->
        </a>
        <!--      <a name="ver todas" title="ver todas" (click)="map()">ver todas</a>-->
      </div>
    </div>
  </ng-template>
</div>
