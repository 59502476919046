import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import firebase from 'firebase/app';
import {Observable, of} from 'rxjs';
import {Escola} from '../../service/Escola';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-escola-competitors',
  templateUrl: './escola-competitors.component.html',
  styleUrls: ['./escola-competitors.component.css']
})
export class EscolaCompetitorsComponent implements OnInit, AfterViewInit {

  _escola: Escola;
  newCompetitor: number;
  competitorsList: number[] = [];
  @Input() editing = false;
  @Output() clickMessageLink = new EventEmitter<void>();
  @ViewChild('itemTemplate') itemTemplate;

  constructor() {

  }

  ngOnInit(): void {
  }

  get escola(): Escola {
    return this._escola;
  }

  @Input() set escola(value: Escola) {
    this._escola = value;
    this.loadCompetitors();
  }

  ngAfterViewInit(): void {
  }

  public async loadCompetitors(): Promise<void> {
    const value = await firebase.functions().httpsCallable('escola_competitors')({cod_escola: this.escola.codigo});

    this.competitorsList = value.data[0].lista_concorrentes;

    if (this.competitorsList == null)
      this.competitorsList = [];
  }

  public async saveCompetitors(): Promise<void> {
    const data = {
      cod_escola: this.escola.codigo,
      lista_concorrentes: this.competitorsList
    };

    console.log('data', data);

    await firebase.functions().httpsCallable('escola_competitors_update')(data);
  }

  removeCompetitor(id: number): void {
    const index = this.competitorsList.indexOf(id);

    if (index >= 0) {
      this.competitorsList.splice(index, 1);
    }

    this.saveCompetitors();
  }

  addCompetitor(): void {
    if (this.newCompetitor 
      && this.newCompetitor > 0
      && !this.competitorsList.includes(this.newCompetitor)) {
        this.competitorsList.push(this.newCompetitor)
        this.newCompetitor = null;
        this.saveCompetitors();
    }
  }
}
