import {Component, OnInit} from '@angular/core';
import {UserService} from '../../service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';

export const snapshotToArray = (snapshot: any) => {
  const returnArr: any[] = [];


  return returnArr;
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  chats: any[] = [];

  constructor(public userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private db: AngularFireDatabase,
  ) {
  }

  ngOnInit(): void {
    this.db.database.ref('chats')
        .on('value', resp => {
              const chats: any[] = [];
              resp.forEach((childSnapshot: any) => {
                const item = childSnapshot.val();
                item.key = childSnapshot.key;
                chats.push(
                    {
                      email: item.email,
                      name: item.name,
                      uid: item.uid
                    }
                );
              });

              if (chats.length !== this.chats.length) {
                this.chats = chats;
              }
            }
        );
  }

  chatEvent(uid: string) {
    const c = document.getElementById(uid);
    if (c) {
      c.scrollTop = 5000;
    }
  }

}
