<!--  wrapper  -->
<div id="wrapper">
  <!-- Content-->
  <div class="content">
    <!--section -->
    <section class="gray-section" id="sec1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 display-page nosidebar">
            <div class="list-single-main-wrapper fl-wrap" id="sec2">


              <article id="post-7239" class="post-7239 page type-page status-publish hentry">
                <div class="list-single-main-item-title fl-wrap">
                  <h3 class="entry-title">Dúvidas Frequentes</h3></div><!-- .list-single-main-item-title-->
                <div class="entry-content clearfix">
                  <div data-elementor-type="wp-page" data-elementor-id="7239" class="elementor elementor-7239" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          class="elementor-element elementor-element-215bd0e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="215bd0e1" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-7438cdc7 elementor-column elementor-col-100 elementor-top-column" data-id="7438cdc7"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-da7b545 elementor-widget elementor-widget-spacer" data-id="da7b545"
                                         data-element_type="widget" data-widget_type="spacer.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                          <div class="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-fbf3166 elementor-widget elementor-widget-heading" data-id="fbf3166"
                                         data-element_type="widget" data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h5 class="elementor-heading-title elementor-size-default">O que os pais querem saber:</h5></div>
                                    </div>
                                    <div class="elementor-element elementor-element-423dfe2 elementor-widget elementor-widget-spacer" data-id="423dfe2"
                                         data-element_type="widget" data-widget_type="spacer.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                          <div class="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-01b5dd1 elementor-widget elementor-widget-accordion" data-id="01b5dd1"
                                         data-element_type="widget" data-widget_type="accordion.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-accordion" role="tablist">
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1791" class="elementor-tab-title" data-tab="1" role="tab"
                                                 aria-controls="elementor-tab-content-1791">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Por que existem páginas pagas e outras gratuitas?</div>
                                            </div>
                                            <div id="elementor-tab-content-1791" class="elementor-tab-content elementor-clearfix" data-tab="1"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1791"><p><span style="font-weight: 400;">A SchoolAdvisor apresenta as páginas das escolas de três maneiras diferentes:</span>
                                            </p>
                                              <p><b>Página Padrão:</b><span style="font-weight: 400;"> As informações são coletadas no Censo Escolar e complementadas de forma colaborativa pelas famílias. Exibe até 30 itens de observação. Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola selecionada.</span>
                                              </p>
                                              <p><b>Página Verificada:</b><span style="font-weight: 400;"> As informações são oficiais da escola. O ícone indica que a instituição é uma Página Verificada. Exibe até 30 itens de observação. Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola selecionada.</span>
                                              </p>
                                              <p><b>Página Premium:</b> <span style="font-weight: 400;">As informações são oficiais da escola.  O ícone</span><i><span
                                                style="font-weight: 400;"> </span></i><span style="font-weight: 400;">indica que a instituição é uma Página Premium. Neste modelo, as escolas patrocinam sua visita ao perfil completo da instituição. Assim, você pode acessar 70 itens de observação sem que haja necessidade de realizar nenhuma compra.</span>
                                              </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1792" class="elementor-tab-title" data-tab="2" role="tab"
                                                 aria-controls="elementor-tab-content-1792">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Como faço a compra do perfil de uma escola?</div>
                                            </div>
                                            <div id="elementor-tab-content-1792" class="elementor-tab-content elementor-clearfix" data-tab="2"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1792"><p><span style="font-weight: 400;">Acesse a página da escola que atende aos seus critérios de busca e clicar no box de venda. Você será redirecionado para a página de compra, basta seguir os procedimentos indicados.</span>
                                            </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1793" class="elementor-tab-title" data-tab="3" role="tab"
                                                 aria-controls="elementor-tab-content-1793">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Quais são as formas de pagamento aceitas pela
                                                SchoolAdvisor?</a>
                                            </div>
                                            <div id="elementor-tab-content-1793" class="elementor-tab-content elementor-clearfix" data-tab="3"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1793"><p><span style="font-weight: 400;">Nossas transações financeiras acontecem por meio do Pagseguro. Você pode pagar com segurança utilizando cartão de crédito ou boleto bancário</span><span
                                              style="font-weight: 400;">.</span></p>
                                              <p>Para criar uma conta no Pagseguro, <a href="https://cadastro.pagseguro.uol.com.br/" target="_blank"
                                                                                       rel="noopener">acesse este link</a>.</p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1794" class="elementor-tab-title" data-tab="4" role="tab"
                                                 aria-controls="elementor-tab-content-1794">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Ao realizar a compra do perfil de escola, como é feito o
                                                trabalho de pesquisa?</a>
                                            </div>
                                            <div id="elementor-tab-content-1794" class="elementor-tab-content elementor-clearfix" data-tab="4"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1794"><p><span style="font-weight: 400;">A coleta dos dados é feita por telefone, e-mail e através dos canais de comunicação da escola. Todas as informações são transmitidas pela escola e, por isso, a SchoolAdvisor não se responsabiliza pela veracidade dos dados ou qualquer mudança que possa ter ocorrido nas escolas.</span>
                                            </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1795" class="elementor-tab-title" data-tab="5" role="tab"
                                                 aria-controls="elementor-tab-content-1795">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Como é feita a entrega do perfil de escola?</div>
                                            </div>
                                            <div id="elementor-tab-content-1795" class="elementor-tab-content elementor-clearfix" data-tab="5"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1795"><p><span style="font-weight: 400;">Após a compra, você pode acessar o perfil completo da escola de duas formas:</span>
                                            </p>
                                              <ul>
                                                <li><b><span
                                                  style="font-weight: 400;">Acessando diretamente a página da escola, que estará com as informações completas disponíveis para você;</span></b>
                                                </li>
                                                <li>Acessando, em sua área logada exclusiva para membros, a sessão <em>Minhas Escolas</em>. O link
                                                  para a página completa da escola estará disponível.
                                                </li>
                                              </ul>
                                              <p></p>
                                              <p></p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1796" class="elementor-tab-title" data-tab="6" role="tab"
                                                 aria-controls="elementor-tab-content-1796">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Qual é o prazo de liberação das informações?</div>
                                            </div>
                                            <div id="elementor-tab-content-1796" class="elementor-tab-content elementor-clearfix" data-tab="6"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1796"><p><span style="font-weight: 400;">Caso você esteja comprando informações completas do perfil de uma escola que tenha a <strong>Página Verificada</strong>, a liberação ocorrerá em até 3 dias úteis.</span>
                                            </p>
                                              <p><span
                                                style="font-weight: 400;">Caso sua compra se refira a uma escola com <strong>Página Padrão</strong>, nosso time de consultores educacionais fará a coleta das informações oficiais para você. Neste caso, a entrega pode levar até 10 dias úteis.</span>
                                              </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1797" class="elementor-tab-title" data-tab="7" role="tab"
                                                 aria-controls="elementor-tab-content-1797">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Como posso sugerir a inclusão de uma escola que não está na
                                                plataforma ou alterações nas informações de uma instituição?</a>
                                            </div>
                                            <div id="elementor-tab-content-1797" class="elementor-tab-content elementor-clearfix" data-tab="7"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1797"><p><span style="font-weight: 400;">Estamos trabalhando para que você possa editar colaborativamente as informações das escolas e, por isso, você ainda precisa da nossa ajuda para isso (assim como precisamos da sua para melhorar continuamente nossa plataforma!). </span>
                                            </p>
                                              <p><span
                                                style="font-weight: 400;">Por favor, envie um email para <a href="mailto: contato@schooladvisor.com.br">contato@schooladvisor.com.br</a></span><span
                                                style="font-weight: 400;"> para receber as diretrizes.</span></p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1798" class="elementor-tab-title" data-tab="8" role="tab"
                                                 aria-controls="elementor-tab-content-1798">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Se eu fizer uma avaliação anônima, as escolas poderão
                                                acessar de alguma forma meu comentário?</a>
                                            </div>
                                            <div id="elementor-tab-content-1798" class="elementor-tab-content elementor-clearfix" data-tab="8"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1798"><p><span
                                              style="font-weight: 400;">Nós respeitamos sua privacidade e preferências. </span><span
                                              style="font-weight: 400;">Caso você marque uma avaliação ou review como &#8220;Anônimo&#8221;, seu nome e e-mail não serão atribuídos à postagem. </span>
                                            </p>
                                              <p><span style="font-weight: 400;">Para mais informações sobre Privacidade, acesse nossa <a
                                                href="/politica/" target="_blank" rel="noopener">Política de Privacidade</a> e nossos <a
                                                href="/termos/" target="_blank" rel="noopener">Termos de Uso</a>.</span>
                                              </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-1799" class="elementor-tab-title" data-tab="9" role="tab"
                                                 aria-controls="elementor-tab-content-1799">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Como visualizo as escolas marcadas com a etiqueta "Salvar"
                                                durante o processo de navegação?</a>
                                            </div>
                                            <div id="elementor-tab-content-1799" class="elementor-tab-content elementor-clearfix" data-tab="9"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-1799"><p><span style="font-weight: 400;">Clique no botão &#8220;<em>Entrar</em>&#8220;, localizado no canto superior direito da página. Insira seu login e senha. </span>
                                            </p>
                                              <p><span style="font-weight: 400;">Na área de membro logado, acesse <em>Minhas Escolas</em>. </span></p>
                                            </div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-17910" class="elementor-tab-title" data-tab="10" role="tab"
                                                 aria-controls="elementor-tab-content-17910">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Quais serviços adicionais estão disponíveis para os
                                                pais?</a>
                                            </div>
                                            <div id="elementor-tab-content-17910" class="elementor-tab-content elementor-clearfix" data-tab="10"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-17910"><p><span
                                              style="font-weight: 400;">Além do perfil de escolas, você pode se interessar por:</span>
                                            </p>
                                              <ul>
                                                <li><span style="font-weight: 400;">Diagnóstico de Abordagem: O diagnóstico é uma ferramenta on-line, composta por 23 questões com respostas de múltipla escolhas e elaborado por um time de psicopedagogos. A partir da combinação das respostas, você poderá identificar o perfil pedagógico mais relevante para o perfil da sua família e personalidade da criança.</span>
                                                </li>
                                                <li><span style="font-weight: 400;">Comparativo de 5 escolas: O comparativo de Escolas contempla a curadoria e entrega do perfil de 5 escolas que atendem aos critérios estabelecidos por você, </span><span
                                                  style="font-weight: 400;">a partir de algumas perguntas, que enviamos a você a partir da compra do serviço.</span>
                                                </li>
                                              </ul>
                                              <p><span style="font-weight: 400;">Quer saber mais? Entre em contato conosco: <a
                                                href="mailto: contato@schooladvisor.com.br">contato@schooladvisor.com.br</a></span>
                                              </p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-element elementor-element-0e6e2f9 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="0e6e2f9" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-7b23374 elementor-column elementor-col-100 elementor-top-column" data-id="7b23374"
                                   data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-c088136 elementor-widget elementor-widget-spacer" data-id="c088136"
                                         data-element_type="widget" data-widget_type="spacer.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                          <div class="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4f3639e elementor-widget elementor-widget-heading" data-id="4f3639e"
                                         data-element_type="widget" data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h5 class="elementor-heading-title elementor-size-default">O que as escolas querem saber:</h5></div>
                                    </div>
                                    <div class="elementor-element elementor-element-ed0788c elementor-widget elementor-widget-spacer" data-id="ed0788c"
                                         data-element_type="widget" data-widget_type="spacer.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                          <div class="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-0723774 elementor-widget elementor-widget-accordion" data-id="0723774"
                                         data-element_type="widget" data-widget_type="accordion.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-accordion" role="tablist">
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7481" class="elementor-tab-title" data-tab="1" role="tab"
                                                 aria-controls="elementor-tab-content-7481">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Por que existem páginas pagas e outras gratuitas?</div>
                                            </div>
                                            <div id="elementor-tab-content-7481" class="elementor-tab-content elementor-clearfix" data-tab="1"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7481"><p><span style="font-weight: 400;">A SchoolAdvisor apresenta as páginas das escolas de três maneiras diferentes:</span>
                                            </p>
                                              <p><b>Página Padrão:</b><span style="font-weight: 400;"> As informações são coletadas no Censo Escolar e complementadas de forma colaborativa pelas famílias. Exibe até 30 itens de observação. Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola selecionada.</span>
                                              </p>
                                              <p><b>Página Verificada:</b><span style="font-weight: 400;"> As informações são oficiais da escola. O ícone indica que a instituição é uma Página Verificada. Exibe até 30 itens de observação. Você pode realizar a compra do perfil completo da escola, e nosso time vai coletar as informações oficiais da escola selecionada.</span>
                                              </p>
                                              <p><b>Página Premium:</b> <span style="font-weight: 400;">As informações são oficiais da escola.  O ícone</span><i><span
                                                style="font-weight: 400;"> </span></i><span style="font-weight: 400;">indica que a instituição é uma Página Premium. Neste modelo, as escolas patrocinam sua visita ao perfil completo da instituição. Assim, você pode acessar 70 itens de observação sem que haja necessidade de realizar nenhuma compra.</span>
                                              </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7482" class="elementor-tab-title" data-tab="2" role="tab"
                                                 aria-controls="elementor-tab-content-7482">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Quais são os serviços oferecidos pela SchoolAdvisor?</div>
                                            </div>
                                            <div id="elementor-tab-content-7482" class="elementor-tab-content elementor-clearfix" data-tab="2"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7482"><p><span style="font-weight: 400;">A SchoolAdvisor é um canal completo sobre as escolas particulares do Brasil, que tem o objetivo de ajudar as famílias a encontrarem a melhor opção através de informações, recomendações, conexões e serviços. Ao munir as famílias com informação de qualidade, direcionamos visitas para a escola mais alinhadas com os valores da instituição. </span>
                                            </p>
                                              <p><span
                                                style="font-weight: 400;">Oferecemos duas modalidades de serviços principais para as escolas:</span>
                                              </p>
                                              <ul>
                                                <li style="font-weight: 400;"><b>Publicidade e Divulgação:</b><span style="font-weight: 400;"> sua escola pode patrocinar o acesso irrestrito a todas as informações disponíveis sobre a instituição em nossa plataforma por meio da <strong>Página Verificada Premium.</strong> Desta forma, além de aumentar suas chances de ser considerada no processo de busca pelas famílias,  seu time perde menos tempo com visitas que não estejam alinhadas com sua proposta pedagógica (leads mais qualificados). Acesse </span><span
                                                  style="font-weight: 400;">a página de <a
                                                  href="/familia/" target="_blank"
                                                  rel="noopener">Planos e Vantagens</a> para saber como divulgar sua escola.</span>
                                                </li>
                                                <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Análise de mercado e concorrência:</strong> você sabe o que diferencia sua escola do concorrente, no olhar dos pais? A SchoolAdvisor ajuda você a descobrir por meio de uma consultoria completa, composta por análise qualitativa e visitas in loco. <a
                                                  href="mailto: contato@schooladvisor.com.br" target="_blank" rel="noopener">Entre em contato conosco</a> para ver o detalhamento deste serviço e pedir um orçamento.</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7483" class="elementor-tab-title" data-tab="3" role="tab"
                                                 aria-controls="elementor-tab-content-7483">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <div class="elementor-accordion-title">Como posso incluir minha escola na plataforma?</div>
                                            </div>
                                            <div id="elementor-tab-content-7483" class="elementor-tab-content elementor-clearfix" data-tab="3"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7483"><p><span style="font-weight: 400;">Ter pais bem informados sobre a escola, significa leads mais qualificados, menos tempo investido na realização de visitas e maiores chances de uma relação duradoura com as famílias.</span>
                                            </p>
                                              <p>
                                                <span style="font-weight: 400;">Para fornecer dados oficiais da sua escola e aparecer gratuitamente nas nossas páginas de resultado de busca, por favor, preencha o cadastro da sua escola disponível
                                                  <a href="https://schooladvisor.com.br/contato" target="_blank" rel="noopener">neste link.</a>
<!--                                                  <li><a [routerLink]="['/contato']" title="Fale Conosco">neste link</a></li>-->
                                                </span>
                                              </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7484" class="elementor-tab-title" data-tab="4" role="tab"
                                                 aria-controls="elementor-tab-content-7484">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Quanto tempo demora para as informações da minha escola
                                                estarem disponíveis na plataforma?</a>
                                            </div>
                                            <div id="elementor-tab-content-7484" class="elementor-tab-content elementor-clearfix" data-tab="4"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7484"><p><span style="font-weight: 400;">Após o preenchimento do formulário de cadastro disponível
                                                <a href="https://schooladvisor.com.br/contato"
                                                   target="_blank" rel="noopener">neste link
                                                </a>
<!--                                              <a [routerLink]="['/contato']" title="Fale Conosco">neste link</a>-->
                                              , seus dados são enviados para nosso time editorial realizar a revisão, aprovação e liberação do perfil público da escola. </span>
                                            </p>
                                              <p><span style="font-weight: 400;">Este processo pode levar até 7 dias úteis.</span></p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7485" class="elementor-tab-title" data-tab="5" role="tab"
                                                 aria-controls="elementor-tab-content-7485">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Minha escola já está cadastrada na plataforma como Página
                                                Padrão Gratuita. Qual a origem dessas informações?</a>
                                            </div>
                                            <div id="elementor-tab-content-7485" class="elementor-tab-content elementor-clearfix" data-tab="5"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7485"><p><span style="font-weight: 400;">As informações da página gratuita não verificada são coletadas no Censo Escolar do INEP e complementadas de forma colaborativa pelas famílias.</span>
                                            </p></div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <div id="elementor-tab-title-7486" class="elementor-tab-title" data-tab="6" role="tab"
                                                 aria-controls="elementor-tab-content-7486">
													<span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="fas fa-plus"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="fas fa-minus"></i></span>
														</span>
                                              <a class="elementor-accordion-title" href="">Posso solicitar exclusão de reviews e comentários
                                                relacionados à minha escola?</a>
                                            </div>
                                            <div id="elementor-tab-content-7486" class="elementor-tab-content elementor-clearfix" data-tab="6"
                                                 role="tabpanel" aria-labelledby="elementor-tab-title-7486"><p><span style="font-weight: 400;">Nós da SchoolAdvisor prezamos pela colaboração e pela liberdade de expressão. </span>
                                            </p>
                                              <p><span style="font-weight: 400;">Nosso objetivo com as avaliações e reviews, é fornecer informações para que as famílias possam escolher a escola mais alinhada com seus valores e expectativas. </span>
                                              </p>
                                              <p><span style="font-weight: 400;">Por isso, quanto mais pessoas fornecendo impressões sobre sua escola, maior a chance da instituição ser considerada pelos pais no momento de busca. </span>
                                              </p>
                                              <p><span style="font-weight: 400;">Possuímos um conjunto de Regras de Conduta ao usar a plataforma que pode ser acessado através deste link. Nele, pedimos que as famílias coloquem sua opiniões de maneira construtiva, embasada em um histórico de relacionamento e não em fatos pontuais.</span>
                                              </p>
                                              <p><span style="font-weight: 400;">Mesmo assim, caso você se sinta ofendido por alguma avaliação, entre em contato conosco <a
                                                href="mailto: contato@schooladvisor.com.br">neste e-mail </a>para que o comentário seja analisado e, eventualmente, excluído.</span>
                                              </p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div><!-- .entry-content -->
              </article><!-- #post-## -->

            </div>
            <!-- end list-single-main-wrapper -->
          </div>
          <!-- end display-posts col-md-8 -->


        </div>
        <!-- end row -->
      </div>
      <!-- end container -->

    </section>
    <!-- section end -->


  </div>
  <!-- Content end -->
</div>
<!-- wrapper end -->
