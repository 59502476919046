<div id="idiomas" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Idiomas, bilinguismo e educação internacional</h2>
  </div>
  <div *ngIf="this.escola.premium && hasSobre" class="row about-school content-more mb-4" [class.active]="isExpanded">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.idioma_sobre }}</p>
    </div>
    <div class="col-12">
      <div class="action">
        <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="hasSelos || hasIdiomas || hasInternacional || hasItensOferecidos" class="col-12 mb-4">
    <div class="content-border border-lightgray">
      <div class="row">
        <div *ngIf="hasSelos" class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <!-- IMAGE -->
          <figure *ngIf="this.escola.oebi" class="feature-image mb-2 mt-1">
            <img src="images/oebi/logo-oebi.png" alt="Bilíngue OEBI" />
          </figure>
        </div>
        <div *ngIf="hasSelos" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <div *ngIf="hasIdiomas" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">Idiomas oferecidos</h3>
            <p class="font-size-regular text-black mb-0">
              <span *ngIf="this.escola.idioma_principal">{{ this.todosIdiomas().join(", ") }}</span>
            </p>
          </div>
          <div *ngIf="hasIdiomas" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="hasInternacional" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">{{ escolaInternacionalLabel }}</h3>
            <p class="font-size-regular text-black mb-0">Origem: {{ this.escola.pais }}</p>
          </div>
          <div *ngIf="hasInternacional" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="hasItensOferecidos" class="col-12 d-flex flex-column school-profile">
            <h3 class="text-pink font-size-small mb-2">A escola também oferece</h3>
            <div class="list-school-profile">
              <ul>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_duplo_diploma">Duplo diploma</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.intercambio">Intercâmbio</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.competicoes_internacionais">Competições internacionais</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.certificao_outro_idioma">Certificação em outro idioma</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_convenio_com_escolas_no_exterior">Parcerias com universidades no exterior</li>
                
                <!-- li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_aulas_de_programacao_coding">Aulas de Programação / Coding</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_educacao_empreendedora">Educação Empreendedora</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_ensino_religioso">Ensino Religioso</li>
                <li class="font-size-regular text-black" [class.tachado]="!this.escola.disciplinas_adicionais_oportunidades_de_voluntariado">Oportunidades de Voluntariado</li -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!hasData" class="row about-school content-more mb-4">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">Não Informado</p>
    </div>
  </div>
</div>
