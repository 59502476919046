import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { CdkScrollable } from '@angular/cdk/overlay';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { HeaderComponent } from '../../navigation/header/header.component';
import { environment } from '../../../environments/environment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import firebase from 'firebase/app';
import * as $ from 'jquery';
import { UserService } from '../../service/user.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-template-old',
  templateUrl: './template-old.component.html',
  styleUrls: [
    '../../../custom-theme.scss', 
    '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css', 
    '../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css', 
    '../../../styles.scss', 
    '../../../../node_modules/swiper/swiper-bundle.css', 
    '../../../../node_modules/font-awesome/css/font-awesome.css', 
    '../../../../node_modules/@loadingio/loading.css/dist/loading.css', 
    '../../../../node_modules/@loadingio/loading.css/dist/loading.utils.css', 
    '../../../../node_modules/slick-carousel/slick/slick.scss', 
    '../../../../node_modules/slick-carousel/slick/slick-theme.scss', 
    '../../../css/bootstrap-grid.css', 
    '../../../css/bootstrap-reboot.css', 
    '../../../css/mdb.min.css', 
    '../../../../node_modules/cookieconsent/build/cookieconsent.min.css', 
    '../../../css/custom.css', 
    './template-old.component.css'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateOldComponent implements OnInit, OnDestroy {
  showTopButton = false;
  @ViewChild('headerComponent', { static: true, read: ElementRef }) headerComponentElement: ElementRef;
  @ViewChild('headerComponent', { static: true }) headerComponent: HeaderComponent;
  @ViewChild('content', { static: true }) content: CdkScrollable;
  @ViewChild('container', { static: true }) container: MatSidenavContainer;

  constructor(private renderer: Renderer2, private ccService: NgcCookieConsentService, private meta: Meta, private title: Title, public userService: UserService) {
    if (location.hostname === 'localhost') {
      try {
        console.log('This is localhost. CookieConsent popup will be destroyed after app init.');
        ccService.destroy();
      }
      catch (e) {
        console.error('Error destroying CookieConsent popup: ', e);
      }
    }
    firebase.analytics();
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'mat-typography');
    if (environment.useEmulators) {
      firebase.functions().useEmulator('localhost', 5001);
    }

    this.title.setTitle('SchoolAdvisor | Encontre uma Escola');

    this.meta.addTags([{ name: 'keywords', content: 'SchoolAdvisor | Encontre uma Escola' }, { name: 'description', content: 'Informações completas, organizadas e confiáveis sobre as escolas particulares brasileiras.' }, { name: 'robots', content: 'index, follow' }, { name: 'author', content: 'João Kiipper' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }, { name: 'date', content: '2021-06-01', scheme: 'YYYY-MM-DD' }, { charset: 'UTF-8' }]);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'mat-typography');
  }

  ngAfterViewInit(): void {
    $(document).ready(() => {
      // FAV
      $('.fav').on('click', () => {
        $(this).toggleClass('active');
      });
    });
  }

  private loadScript(url: string, callback): void {
    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';

    script.onload = () => {
      callback();
    };

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  log(toggle: string): void {
    console.log(toggle);
  }
}
