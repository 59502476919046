<!-- ITEM -->
<div class="component-card">
  <article class="card-school">
    <div class="content-image-card" [ngClass]="{'escola-disabled': escola.data_desativada != null}">
      <div class="options-top" fxLayoutGap="5px">
        <app-button-rating [escola]="escola"></app-button-rating>
        <app-button-favorite [escola]="escola"></app-button-favorite>
      </div>
      <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" target="_blank"
         (click)="analytics.clickEscola(escola)"
         title="veja mais" class="veja-mais">
            <mat-grid-list class="mb-1" rowHeight="5:3" cols="1">
                <mat-grid-tile class="escola-image">
                    <img
                         src="{{(escola.logo_escola == null) ? 'images/thumb-list.jpg' : escola.logo_escola}}"
                         alt="Escola {{escola.nome}}"
                         [srcset]="escola.logo_escola_set">
                </mat-grid-tile>
            </mat-grid-list>
      </a>
    </div>
    <div class="content-text-card">
      <div class="school-score flex-row">
        <app-escola-score [score]="escola.score_info" *ngIf="escola.score_info" [small]="true" [canExpand]="false"></app-escola-score>
      </div>
      <div class="post-meta d-flex flex-row">
        <div class="tags">
          <span class="tag text-lightgray border-lightgray" *ngFor="let nivel of escola?.nivel_ensino">{{nivel}}</span>
        </div>
      </div>
      <a [routerLink]="['/escolas',escola.codigo,escola.nome | urlNormalizer]" target="_blank"
         (click)="analytics.clickEscola(escola)"
         title="veja mais" class="veja-mais">
        <div class="premium">
          <app-button-parceiro [escola]="escola"></app-button-parceiro>
          <h3 class="title text-gray escola-card-name" [ngClass]="{'escola-disabled': escola.data_desativada != null}">{{escola.nome}}</h3>
        </div>
        <p class="small-text text-gray clearfix">
          {{escola.endereco}}
        </p>
      </a>
    </div>
  </article>
</div>
