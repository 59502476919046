import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() offset: number;
  @Input() limit: number;
  @Input() total: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
