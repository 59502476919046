import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable, timer} from 'rxjs';
import {debounceTime, finalize, map, switchMap, tap} from 'rxjs/operators';
import {Colecao} from '../../service/Colecao';
import {Diretorio} from '../../service/Diretorio';
import firebase from 'firebase';
import {Escola} from '../../service/Escola';
import {FormControl} from '@angular/forms';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';

interface Summary {
  type: 'Summary' | 'Coleção' | 'Diretório' | 'Escola';
  group?: 'Coleção' | 'Diretório' | 'Escola';
  cod_colecao?: number;
  cod_diretorio?: number;
  prioridade?: number;
  codigo?: number;
  count?: number;
}

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  old_value?: any;
  hideImage?: any;

  constructor(
    public item: Summary,
    public expandable = false,
    public status: 'removing' | 'editing' | 'loading' = null) {
  }
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({providedIn: 'root'})
export class DynamicDatabase {
  // dataMap = new Map<string, string[]>([
  //   ['Fruits', ['Apple', 'Orange', 'Banana']],
  //   ['Vegetables', ['Tomato', 'Potato', 'Onion']],
  //   ['Apple', ['Fuji', 'Macintosh']],
  //   ['Onion', ['Yellow', 'White', 'Purple']]
  // ]);

  // rootLevelNodes: string[] = ['Fruits', 'Vegetables'];
  rootLevelNodes: Array<Summary> = null;

  /** Initial data from database */
  async initialData(): Promise<DynamicFlatNode[]> {

    let ret: DynamicFlatNode[];

    await firebase.functions().httpsCallable('colecao')().then(value => {
      this.rootLevelNodes = value.data;
      // this.loading = false;
      ret = this.rootLevelNodes.map(item => {
        item.type = 'Coleção';
        return new DynamicFlatNode(item, true);
      });
    });
    return ret;
  }

  getChildren(node: Colecao | Diretorio | Escola): Colecao[] | Diretorio[] | Escola[] {
    switch (node.type) {
      case 'Coleção':
        return node.diretorio;
      case 'Diretório':
        return node.escola;
      case 'Escola':
        return null;
    }
  }

  isExpandable(node: Colecao | Diretorio | Escola): boolean {
    switch (node.type) {
      case 'Coleção':
        return node.diretorio !== [];
      case 'Diretório':
        return node.escola !== [];
    }
    return false;
  }
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {

  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
              private _database: DynamicDatabase) {
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>): void {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean): void {
    // const children = this._database.getChildren(node.item);
    const index = this.data.indexOf(node);

    if (expand) {
      node.status = 'loading';
      switch (node.item.type) {
        case 'Coleção':
          const cod_colecao = node.item.codigo;
          firebase.functions().httpsCallable('diretorio')({cod_colecao}).then(value => {
            const nodes = value.data.map(item => {
                item.type = 'Diretório';
                item.cod_colecao = cod_colecao;
                return new DynamicFlatNode(item, true);
              }
            );
            const summary: Summary = {
              type: 'Summary',
              group: 'Coleção',
              cod_colecao,
              count: nodes.length,
            };
            nodes.push(new DynamicFlatNode(summary));
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
            node.status = null;
          });
          break;
        case 'Diretório':
          const cod_diretorio = node.item.codigo;
          firebase.functions().httpsCallable('diretorio_escola')({cod_diretorio}).then(value => {
            const nodes = value.data.map(item => {
              item.type = 'Escola';
              item.cod_diretorio = cod_diretorio;
              console.log(`escola ${item.nome} - cod_diretorio: ${item.cod_diretorio}`);
              return new DynamicFlatNode(item, false);
            });
            const summary: Summary = {
              type: 'Summary',
              group: 'Diretório',
              cod_diretorio,
              count: nodes.length,
            };
            nodes.push(new DynamicFlatNode(summary));
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
            node.status = null;
          });
          break;
      }

    } else {
      let count = 0;
      const nodeLevel = TreeColecaoComponent.getLevel(node);

      for (let i = index + 1;
           i < this.data.length && (TreeColecaoComponent.getLevel(this.data[i]) > nodeLevel);
           i++, count++) {
        // console.log(i + '. ' + this.data[i].item.type);
      }
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.status = null;
    }
  }
}

/**
 * @title Tree with dynamic data
 */
@Component({
  selector: 'app-tree-colecao',
  templateUrl: './tree-colecao.component.html',
  styleUrls: ['tree-colecao.component.scss']
})
export class TreeColecaoComponent {
  // Coleção
  isLoadingColecao = false;
  isAddingColecao = false;
  // Diretório
  isLoadingDiretorio = false;
  diretorioControl = new FormControl();
  loadingDiretorioMessage: string = null;
  diretorios: Diretorio[];
  // Escola
  isLoadingEscola = false;
  escolaControl = new FormControl();
  loadingEscolaMessage: string = null;
  escolas: Escola[];

  constructor(dynamicDatabase: DynamicDatabase, private storage: AngularFireStorage) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(TreeColecaoComponent.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, dynamicDatabase);

    this.isLoadingColecao = true;

    dynamicDatabase.initialData().then(value => {
      this.dataSource.data = value;
      this.isLoadingColecao = false;
    });

    this.diretorioControl.valueChanges.pipe(
      debounceTime(1000),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (value.length < 3) {
          this.loadingDiretorioMessage = `Digite ao menos 3 letras para pesquisar.`;
        } else {
          this.isLoadingDiretorio = true;
          this.loadingDiretorioMessage = `Procurando diretórios com nome '${value}'`;
          let nome: string;
          if (typeof value === 'string') {
            nome = value;
          } else {
            nome = value.nome;
          }
          firebase.functions().httpsCallable('diretorio')({nome}).then(result => {
            this.isLoadingDiretorio = false;
            const diretorioFiltered: Diretorio[] = result.data;
            this.diretorios = [];
            if (diretorioFiltered?.length > 0) {
              diretorioFiltered.forEach(resultItem => {
                const duplicate = false;
                // this.colecao.diretorio.forEach(currentDiretorioList => {
                //   if (resultItem.codigo === currentDiretorioList.codigo) {
                //     duplicate = true;
                //   }
                // });
                if (!duplicate) {
                  this.diretorios.push(resultItem);
                }
              });
            }
          });
        }
        return [];
      })
    ).subscribe();

    this.escolaControl.valueChanges.pipe(
      debounceTime(1000),
      switchMap(value => {
        // the value can be a string or a Escola type
        if (value.length < 3) {
          this.loadingEscolaMessage = `Digite ao menos 3 letras para pesquisar.`;
        } else {
          this.isLoadingEscola = true;
          this.loadingEscolaMessage = `Procurando escolas com nome '${value}'`;
          let nome: string;
          if (typeof value === 'string') {
            nome = value;
          } else {
            nome = value.nome;
          }
          firebase.functions().httpsCallable('nome_escola')({nome}).then(result => {
            this.isLoadingEscola = false;
            const escolaFiltered: Escola[] = result.data;
            this.escolas = [];
            if (escolaFiltered?.length > 0) {
              escolaFiltered.forEach(resultItem => {
                const duplicate = false;
                // this.colecao.escola.forEach(currentEscolaList => {
                //   if (resultItem.codigo === currentEscolaList.codigo) {
                //     duplicate = true;
                //   }
                // });
                if (!duplicate) {
                  this.escolas.push(resultItem);
                }
              });
            }
          });
        }
        return [];
      })
    ).subscribe();
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  public static getLevel(node: DynamicFlatNode): number {
    switch (node.item.type) {
      case 'Coleção':
        return 0;
      case 'Diretório':
        return 1;
      case 'Escola':
        return 2;
      case 'Summary':
        switch (node.item.group) {
          case 'Coleção':
            return 1;
          case 'Diretório':
            return 2;
          case 'Escola':
            return 3;
        }
        return 1;
    }
    return 0;
  }

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (index: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  displayDiretorio(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  addDiretorio(node: DynamicFlatNode, diretorio: Diretorio = null): void {
    // this.menuAddDiretorio.closeMenu();

    if (diretorio == null) {
      diretorio = {
        type: 'Diretório',
        codigo: null,
        nome: 'Novo Diretório',
        escola: [],
        ativo: true,
        randomico: true,
        cod_colecao: node.item.cod_colecao,
        // prioridade: (node.diretorio.length + 1);
      };
    } else {
      diretorio.cod_colecao = node.item.cod_colecao;
      // diretorio.prioridade = (node.diretorio.length + 1);
    }

    firebase.functions().httpsCallable('diretorio_set')(diretorio).then(value => {
      let index: number;
      for (index = 0; index <= this.dataSource.data.length && this.dataSource.data[index] !== node; index++) {
        // Find "this" node index
        // console.log('finding... ' + index + '\t' + this.dataSource.data[index].item.type +
        //   ' (cod: ' + this.dataSource.data[index].item.codigo + ')');
      }
      const nodes = value.data.map(item => {
          item.type = 'Diretório';
          item.cod_colecao = node.item.cod_colecao;
          return new DynamicFlatNode(item, true);
        }
      );
      this.dataSource.data.splice(index, 0, ...nodes);
      // notify the change
      this.dataSource.dataChange.next(this.dataSource.data);
    });
  }

  displayEscola(item: any): string {
    if (item == null) {
      return null;
    } else if (item.nome) {
      return item.nome;
    } else {
      return item;
    }
  }

  addEscola(node: DynamicFlatNode, escola: Escola = null): void {
    firebase.functions().httpsCallable('diretorio_escola_set')
    ({cod_diretorio: node.item.cod_diretorio, cod_escola: escola.codigo}).then(value => {
      let index: number;
      for (index = 0; index <= this.dataSource.data.length && this.dataSource.data[index] !== node; index++) {
        // Find "this" node index
        // console.log('finding... ' + index + '\t' + this.dataSource.data[index].item.type +
        //   ' (cod: ' + this.dataSource.data[index].item.codigo + ')');
      }
      const nodes = value.data.map(item => {
          item = {...escola};
          item.type = 'Escola';
          item.cod_colecao = node.item.codigo;
          return new DynamicFlatNode(item, false);
        }
      );
      this.dataSource.data.splice(index, 0, ...nodes);
      // notify the change
      this.dataSource.dataChange.next(this.dataSource.data);
    });
  }

  addColecao(): void {
    this.isAddingColecao = true;
    firebase.functions().httpsCallable('colecao_add')().then(value => {
      const nodes = value.data.map(item => {
          item.type = 'Coleção';
          return new DynamicFlatNode(item, true);
        }
      );
      this.dataSource.data.splice(0, 0, ...nodes);
      // notify the change
      this.dataSource.dataChange.next(this.dataSource.data);
      this.isAddingColecao = false;
    });
  }

  remove(node: DynamicFlatNode): void {
    node.status = 'removing';
    switch (node.item.type) {
      case 'Coleção':
        node.status = null;
        firebase.functions().httpsCallable('colecao_remove')(node.item).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
      case 'Diretório':
        node.status = null;
        firebase.functions().httpsCallable('colecao_diretorio_remove')
        ({cod_colecao: node.item.cod_colecao, cod_diretorio: node.item.codigo}).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
      case 'Escola':
        node.status = null;
        firebase.functions().httpsCallable('diretorio_escola_remove')({
          cod_diretorio: node.item.cod_diretorio,
          cod_escola: node.item.codigo
        }).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
    }
  }

  delete(node: DynamicFlatNode): void {
    switch (node.item.type) {
      case 'Coleção':
        node.status = null;
        firebase.functions().httpsCallable('colecao_remove')(node.item).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
      case 'Diretório':
        node.status = null;
        firebase.functions().httpsCallable('colecao_diretorio_remove')
        ({cod_colecao: node.item.cod_colecao, cod_diretorio: node.item.codigo}).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
      case 'Escola':
        node.status = null;
        firebase.functions().httpsCallable('diretorio_escola_remove')({
          cod_diretorio: node.item.cod_diretorio,
          cod_escola: node.item.codigo
        }).then(value => {
          if (value.data?.length > 0) {
            this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1);
            this.removeChildren(node);
            node.status = null;
            // notify the change
            this.dataSource.dataChange.next(this.dataSource.data);
          }
        });
        break;
    }
  }

  private removeChildren(node: DynamicFlatNode): void {
    const data = this.dataSource.data;
    let count = 0;
    const nodeLevel = TreeColecaoComponent.getLevel(node);

    const index = data.indexOf(node);
    for (let i = index + 1;
         i < data.length && (TreeColecaoComponent.getLevel(data[i]) > nodeLevel);
         i++, count++) {
    }
    data.splice(index + 1, count);
  }

  edit(node: DynamicFlatNode): void {
    node.status = 'editing';
    node.old_value = {...node.item};
  }

  save(node: DynamicFlatNode): void {
    switch (node.item.type) {
      case 'Coleção':
        node.status = 'loading';
        firebase.functions().httpsCallable('colecao_set')(node.item).then(value => {
          const nodes = value.data.map(item => {
              item.type = 'Coleção';
              return new DynamicFlatNode(item, true);
            }
          );
          this.removeChildren(node);
          this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1, ...nodes);
          node.status = null;
          // notify the change
          this.dataSource.dataChange.next(this.dataSource.data);
        });
        break;
      case 'Diretório':
        node.status = 'loading';
        firebase.functions().httpsCallable('diretorio_set')(node.item).then(value => {
          const nodes = value.data.map(item => {
              item.type = 'Diretório';
              return new DynamicFlatNode(item, true);
            }
          );
          this.removeChildren(node);
          this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1, ...nodes);
          node.status = null;
          // notify the change
          this.dataSource.dataChange.next(this.dataSource.data);
        });
        break;
        break;
      case 'Escola':
        node.status = 'loading';
        firebase.functions().httpsCallable('diretorio_escola_set')
        ({cod_diretorio: node.item.cod_diretorio, cod_escola: node.item.codigo, prioridade: node.item.prioridade}).then(value => {
          node.status = null;
          node.status = null;
          // notify the change
          this.dataSource.dataChange.next(this.dataSource.data);
        });
        break;
    }
  }

  cancel(node: DynamicFlatNode): void {

    // CANCEL
    node.item = node.old_value;
    node.status = null;

    // Refresh
    // switch (node.item.type) {
    //   case 'Coleção':
    //     node.status = 'loading';
    //     firebase.functions().httpsCallable('colecao')({codigo: node.item.codigo}).then(value => {
    //       const nodes = value.data.map(item => {
    //           item.type = 'Coleção';
    //           return new DynamicFlatNode(item, true);
    //         }
    //       );
    //       this.removeChildren(node);
    //       this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1, ...nodes);
    //       node.status = null;
    //       // notify the change
    //       this.dataSource.dataChange.next(this.dataSource.data);
    //     });
    //   case 'Diretório':
    //     node.status = 'loading';
    //     firebase.functions().httpsCallable('diretorio')(node.item).then(value => {
    //       const nodes = value.data.map(item => {
    //           item.type = 'Diretório';
    //           return new DynamicFlatNode(item, true);
    //         }
    //       );
    //       this.removeChildren(node);
    //       this.dataSource.data.splice(this.dataSource.data.indexOf(node), 1, ...nodes);
    //       node.status = null;
    //       // notify the change
    //       this.dataSource.dataChange.next(this.dataSource.data);
    //     });
    //     break;
    //   case 'Escola':
    //     node.status = 'loading';
    //     firebase.functions().httpsCallable('diretorio_escola')
    //     ({cod_diretorio: node.item.cod_diretorio, cod_escola: node.item.codigo, prioridade: node.item.prioridade}).then(value => {
    //       node.status = null;
    //       // notify the change
    //       this.dataSource.dataChange.next(this.dataSource.data);
    //     });
    //     break;
    // }
  }

  getLevel(node: DynamicFlatNode): number {
    if (node.item.type === 'Summary') {
      return 0;
    }
    return TreeColecaoComponent.getLevel(node);
  }

  removeTooltip(node): string {
    if (node.item.type === 'Coleção') {
    }
    switch (node.item.type) {
      case 'Coleção':
        return 'Coleções são removidas permanentemente';
      case 'Diretório':
        return 'Retirar esse diretório da Coleção';
      case 'Escola':
        return 'Retirar essa escola do Diretório';
    }
  }

  imageUpload(node: DynamicFlatNode, target: any): void {
    const file: File = target.files[0];
    // The storage path
    const fileName = file.name;
    const extention = fileName.substr(fileName.lastIndexOf('.'));
    const path = `colecao/thumb_${node.item.codigo}${extention}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    const task: AngularFireUploadTask = this.storage.upload(path, file);

    task.then(a => {
      node.hideImage = true;
      const t = timer(5000);
      t.subscribe(value => {
        console.log('reloadImage');
        node.hideImage = new Date().getDate();
      });
    });
  }

  imageError(node: DynamicFlatNode): void {
    // console.log('image error for:' + node.item.codigo);
    node.hideImage = true;
  }
}
