<div fxFlex fxLayout="column">
  <ng-template [ngIf]="imageCount < 0">
    <div style="width: 100%; height: 100%; background: #d1d1d1">
      <img style="margin: auto"
           src="/images/loading.png"
           class="start-50 translate-middle position-relative top-50 ld ld-fade"/>
    </div>
  </ng-template>

  <ng-template [ngIf]="imageCount == 0">
    <div>
      <img src="images/escolas/SchoolAdvisor-Escolas0001.jpg" alt="Imagem Escola Principal">
    </div>
  </ng-template>

  <span class="w-100 alert-danger" style="padding: 10px;" *ngIf="this.warning">
    Somente a imagem principal é exibida para os pais.
    <br>Para mostrar todas, adquira o plano de <a [routerLink]="['/contato', '0']" (click)="this.clickMessageLink.emit()">Escola Parceira</a>
  </span>

  <div style="display: none">
    <gallery id="image-gallery" [itemTemplate]="itemTemplate"></gallery>
    <!-- Add custom template to image items -->
    <ng-template #itemTemplate
                 let-index="index"
                 let-type="type"
                 let-data="data"
                 let-currIndex="currIndex">
<!--      <span [@slideAnimation] class="item-text">Put a title in here-->
<!--      </span>-->
      <div fxFlex fxLayout="column" fxLayoutGap="5px">
          <div class="position-absolute bottom-0 end-0">
              <button mat-mini-fab *ngIf="editing"
                      tooltip="Remover Imagem"
                      (click)="removeImage(data.src, index)">
                  <mat-icon>delete</mat-icon>
              </button>
              <button mat-mini-fab *ngIf="editing && index > 0"
                      tooltip="Imagem Principal"
                      (click)="setMainImage(data.src, index)">
                  <!--      <mat-icon fontSet="fas" fontIcon="fa-minus-circle"></mat-icon>-->
                  <!--      <mat-icon>highlight_off</mat-icon>-->
                  <mat-icon>wallpaper</mat-icon>
              </button>
          </div>
<!--          <div class="position-absolute start-0 bottom-0" *ngIf="index > 0">-->
<!--              <button mat-mini-fab *ngIf="editing" [disabled]="!(index > 1)"-->
<!--                      tooltip="Mover para anterior"-->
<!--                      (click)="moveThumb(index, +1)"-->
<!--              >-->
<!--                  &lt;!&ndash;      <mat-icon fontSet="fas" fontIcon="fa-minus-circle"></mat-icon>&ndash;&gt;-->
<!--                  &lt;!&ndash;      <mat-icon>highlight_off</mat-icon>&ndash;&gt;-->
<!--                  <mat-icon>navigate_before</mat-icon>-->
<!--              </button>-->
<!--              <button mat-mini-fab *ngIf="editing" [disabled]="index >= imageCount-1"-->
<!--                      tooltip="Mover para próxima"-->
<!--                      (click)="moveThumb(index, -1)"-->
<!--              >-->
<!--                  &lt;!&ndash;      <mat-icon fontSet="fas" fontIcon="fa-minus-circle"></mat-icon>&ndash;&gt;-->
<!--                  &lt;!&ndash;      <mat-icon>highlight_off</mat-icon>&ndash;&gt;-->
<!--                  <mat-icon>navigate_next</mat-icon>-->
<!--              </button>-->
<!--          </div>-->
      </div>
    </ng-template>
  </div>

  <mat-grid-list class="tz-gallery" *ngIf="items | async" [cols]="3" [rowHeight]="(imageCount < 3)? '1:2' : (imageCount < 6)? '1:1': '3:2'">
    <div class="btn-todos" *ngIf="imageCount > 0">
      <a class="agendar" mat-ripple class="grid-item" [lightbox]="0" gallery="image-gallery">Todas as Imagens</a>
    </div>
    <mat-grid-tile
      *ngFor="let item of items | async | slice: 0: ((imageCount < 3)? 1 :(imageCount < 6)? 3: 6); let i = index"
      [colspan]="(imageCount < 3)? 3: (i == 0)? 2: 1"
      [rowspan]="(imageCount < 3)? 1: (i == 0)? 2: 1"
    >
      <button mat-raised-button mat-ripple class="grid-item"
              [lightbox]="i" gallery="image-gallery">
                <span class="grid-image" [style.backgroundImage]="'url(\'' + ((i == 0)? item.src : item.thumb) + '\')'">
                </span>
      </button>
      <button mat-mini-fab *ngIf="editing"
              class="position-absolute top-0 end-0"
              tooltip="Remover Imagem"
              (click)="removeImage(item.src, i)">
        <!--      <mat-icon fontSet="fas" fontIcon="fa-minus-circle"></mat-icon>-->
        <!--      <mat-icon>highlight_off</mat-icon>-->
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-mini-fab *ngIf="editing && i > 0"
              class="position-absolute end-0" style="top: 45px"
              tooltip="Imagem Principal"
              (click)="setMainImage(item.src, i)">
        <!--      <mat-icon fontSet="fas" fontIcon="fa-minus-circle"></mat-icon>-->
        <!--      <mat-icon>highlight_off</mat-icon>-->
        <mat-icon>wallpaper</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
