import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
